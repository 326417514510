import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { useTheme } from "../../../ThemeContext";
import SignIn from "../../SignIn/SignIn";
import ForgotPassword from "../../ForgotPassword/ForgotPassword";
import Dashboard from "../../Home/Dashboard";

import Checklist from "../../Checklist/Checklist";
import Profile from "../../Profile/Profile";
// import CreateChecklist from "../../Checklist/CreateChecklist";
// import EditChecklist from "../../Checklist/EditChecklist";
// import Portfolio from "../../Portfolio/Portfolio";
import Users from "../../Users/Users";
import Drawing from "../../Drawing/Drawing";
import Pnm from "../../PNM/Pnm";
import Planner from "../../Planner/Planner";
import Sites from "../../Sites/Sites";
import Tracker from "../../Home/Tracker";
import TaskTracker from "../../TaskTracker/TaskTracker";
import Calendar from "../../Calender/Calender";
import SiteLevel from "../../Drawing/SiteLevel/SiteLevel";
import ArchitectureToRo from "../../Drawing/ArichtectureToRo/ArchitectureToRo";
import ROtoSiteLevel from "../../Drawing/ROtoSiteLevel/ROtoSiteLevel";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import DwgViewer from "../../DwgViewer/Page";
import AboutUs from "../../../about-us/about-us";
import ContactHeroSection from "../../../contact/Contact";
import Space from "../../Space/Space";
import Notifications from "../../Notifications/index";
import Product from "../../../products/products";
import Partners from "../../../partners/partners";
import WhyUs from "../../../why-us/WhyUs";
import { siteId, TotalEnableModulesCount, userInfo } from "../../../atoms";
import { useRecoilState } from "recoil";
import SystemNotifications from "../../Notifications/SystemGenerated";

import PrivacyPolicy from "../../../legal/PrivacyPolicy/PrivacyPolicy";
import TeamsConditions from "../../../legal/TersConditions/TeamsConditions";
import TablePage from "../../Drawing/Components/TablePage";
import ResetPassword from "../../SignIn/RestPassword/ResetPassword";
import VerifyCode from "../../SignIn/VerifyCode/VerifyCode";
import CreatePassword from "../../SignIn/CreatePassword/CreatePassword";
import PasswordCreated from "../../SignIn/PasswordCreated/PasswordCreated";
import MobileDownload from "../../MobileDownload";
import Chats from "../../Chats/Chats";
import DesignForm from "../../Checklist/Design/DesignForm";

const AppRoutes = () => {
  const { themeColor } = useTheme();

  const { pathname } = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [enablesModuleCount,setEnablesModuleCount] = useRecoilState(TotalEnableModulesCount)
  console.log(enablesModuleCount,"enableModulesCount  in Routes")

  const pathsToHideNavbar = [
    "/",
    "/sign-in",
    "/forgot-password",
    "/products",
    "/why-us",
    "/about-us",
    "/partners",
    "/contact",
    "/terms-and-conditions",
    "/privacy-policy",
    "/reset-password",
    "/verify-code",
    "/password-successful",
    "/dwgviewer",
    "/reset-passwordUser",
    "/TeamsConditions",
  ];

  const pathsToHomeNavbar = [
    "/",
    "/products",
    "/why-us",
    "/about-us",
    "/partners",
    "/contact",
    "/terms-and-conditions",
    "/privacy-policy",
    // "/reset-password",
    // "/reset-passwordUser",
    "/TeamsConditions",
    
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (
      Object.keys(currentUserInfo).length === 0 &&
      !pathsToHideNavbar.includes(pathname)
    ) {
      navigate("/sign-in");
    }
    if (pathname === "/") {
      setCurrentUserInfo({});
    }
  }, []);

  const pathsToHideFooter = [
    "/sign-in",
    "/forgot-password",
    "/contact",
    "/verify-code",
    "/reset-passwordUser",
    "/reset-password",
    "/password-successful",
    "/dwgviewer",
    "/TeamsConditions",
  ];

  const pathsToShowGreyBg = ["/drawing"];

  const showNavbar = !pathsToHideNavbar.includes(pathname);

  const showFooter = !pathsToHideFooter.includes(pathname);

  const showGreyBg = pathsToShowGreyBg.includes(pathname);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
// TO DO After checking the dwg remove it
  // useEffect(() => {
  //   const checkScreenSize = () => {
  //     if (window.innerHeight < 500 || window.innerWidth < 768) {
  //       setIsSmallScreen(true);
  //     } else {
  //       setIsSmallScreen(false);
  //     }
  //   };

  //   checkScreenSize();
  //   window.addEventListener("resize", checkScreenSize);

  //   return () => window.removeEventListener("resize", checkScreenSize);
  // }, []);

  useEffect(() => {
    if (
      Object.keys(currentUserInfo).length === 0 &&
      !pathsToHideNavbar.includes(pathname)
    ) {
      navigate("/sign-in");
    }
    if (pathname === "/") {
      setCurrentUserInfo({});
    }
    console.log(pathname,"pathname")
  }, [pathname]);

  if (isSmallScreen && !pathsToHomeNavbar.includes(pathname)) {
    return <MobileDownload />;
  }


  return (
    <div
      className="flex flex-col min-h-screen"
      style={{ backgroundColor: themeColor }}
    >
      {showNavbar && <Navbar />}
      <main
        style={{ background: showGreyBg ? "#f3f3f3" : "", overflow: "hidden", }}
        className={`flex-1 overflow-y-auto ${
          showNavbar && enablesModuleCount>8
            ? "mt-[104px]"
            : pathsToHomeNavbar.includes(pathname)
            ? isSmallScreen
              ? "mt-[65px]"
              : "mt-[85px]"
            : pathname === "/sign-in" || pathname=== "/forgot-password" || pathname === "/verify-code" || pathname === "/reset-passwordUser"|| pathname === "/reset-password" || pathname === "/password-successful" ?"mt-[0px]":"mt-[50px]"
        }`}
      >
        <Routes>
          TaskTracker
          <Route path="/tracker" exact element={<Tracker />} />
          <Route path="/task" exact element={<TaskTracker />} />
          <Route path="/sign-in" exact element={<SignIn />} />
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/checklist" exact element={<Checklist />} />
          <Route path="/designform" exact element={<DesignForm />} />
          <Route path="/profile" exact element={<Profile />} />
          {/* <Route path="/create-checklist" exact element={<CreateChecklist />} /> */}
          {/* <Route path="/edit-checklist" exact element={<EditChecklist />} /> */}
          <Route path="/forgot-password" exact element={<ForgotPassword />} />
          <Route path="/verify-code" exact element={<VerifyCode />} />
          <Route path="/reset-password" exact element={<CreatePassword />} />
          <Route
            path="/password-successful"
            exact
            element={<PasswordCreated />}
          />
          <Route path="/users" exact element={<Users />} />
          <Route path="/drawing" exact element={<Drawing />} />
          <Route path="/planner" exact element={<Planner />} />
          <Route path="/pnm" exact element={<Pnm />} />
          <Route path="/sites" exact element={<Sites />} />
          <Route path="/sites/:id" exact element={<Sites />} />
          <Route path="/profile" exact element={<Profile />} />
          <Route path="/sites" exact element={<Sites />} />
          <Route path="/profile" exact element={<Profile />} />
          <Route path="/dashboard" exact element={<Calendar />} />
          <Route path="/sitelevel" exact element={<SiteLevel />} />
          <Route
            path="/architectureToRo"
            exact
            element={<ArchitectureToRo />}
          />
          <Route path="/rOtoSiteLevel" exact element={<ROtoSiteLevel />} />
          <Route path="/dwgviewer" exact element={<DwgViewer />} />
          <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/contact" exact element={<ContactHeroSection />} />
          <Route path="/products" exact element={<Product />} />
          <Route path="/partners" exact element={<Partners />} />
          <Route path="/space" exact element={<Space />} />
          <Route path="/tablePage" exact element={<TablePage />} />
          <Route path="/notifications" exact element={<Notifications />} />
          <Route path="/why-us" exact element={<WhyUs />} />
          <Route
            path="/systemnotifications"
            exact
            element={<SystemNotifications />}
          />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/TeamsConditions" exact element={<TeamsConditions />} />
          <Route path="/reset-passwordUser" exact element={<ResetPassword />} />
          <Route path="/chats" exact element={<Chats />} />
        </Routes>
      </main>
      {showFooter && <Footer height={"30px"} />}
    </div>
  );
};

export default AppRoutes;
