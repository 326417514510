import React, { useState, useEffect, useMemo } from "react";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";

import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import Pen from "../IconsSiteHead/penIcon.svg"
import { siteId, userInfo } from "../../../../atoms";
import { useRecoilState } from "recoil";
import { WebApimanager } from "../../../../WebApiManager";
import { calculateDueDays } from "../../../../Utilities/DateUtils";
import { todayDate } from "../../../Utilities/DateUtils";
import { canShowFolder } from "../../ShowFolder";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import ConsultantFilter from "../../ConsultantFilter";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", // Optional
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", // Removes the row borders
          },
        },
      },
    },
  },
});

const IssuedFromR0 = ({ searchQuery, selectedCategory }) => {
  const webApi = new WebApimanager();
  const [site] = useRecoilState(siteId);
  const [issuedData, setIssuedData] = useState([]);
  const [userDetails] = useRecoilState(userInfo);
  const [consultantValue, setConsultantValue] = useState('')

  const [apiProcessing, setApiProcessing] = useState({
    Loader: false,
    message: "Loading...",
  });
  const [errors, setErrors]  = useState({})
  const [tableRowData, setTableRowData] = useState([])
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    folder: "",
    designDrawingConsultant: "",
    submittedDate: "",
    receivedHardCopy: "",
    drawingDWGFileName: null,
    drawingPDFFileName: null,
    revisions: [],
    acceptedRORevisions: [],
    note: "",
    selectedRevision: "",
  })

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const file = files[0];
      setFormData({
        ...formData,
        [name]: files[0], // Assuming you are only uploading one file
      });
      return;
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handlePenClick = (drawingItem) =>{
    console.log(drawingItem,"drawingItem in IssuedFrom Ro")
    setTableRowData(drawingItem)
    setEditDialogOpen(true)
  }
  const handleClose = () =>{
    setEditDialogOpen(false)
    setFormData({
      registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    folder: "",
    designDrawingConsultant: "",
    submittedDate: "",
    receivedHardCopy: "",
    drawingDWGFileName: null,
    drawingPDFFileName: null,
    revisions: "",
    acceptedRORevisions: [],
    note: "",
    selectedRevision: "",
    })
  }
  useEffect(()=>{
    if(Object.keys(tableRowData).length){
      setFormData({
        registerId: tableRowData?.drawingId,
    drawingNumber: tableRowData?.drawingNo,
    drawingName: tableRowData?.drawingTitle,
    category: tableRowData?.category?.category,
    folder:tableRowData?.folderId?.folderName,
    designDrawingConsultant: tableRowData?.designDrawingConsultant?.role,
    submittedDate: "",
    receivedHardCopy: "",
    drawingDWGFileName: null,
    drawingPDFFileName:null,
    revisions: "",
    acceptedArchitectRevisions: [],
    note: "",
    selectedRevision: "",
      })
    }
  },[tableRowData,site])

  const validateForm = (formData) => {
    const newErrors = {};
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      // if (!formData.submittedDate.trim()) {
      //   newErrors.submittedDate = "Submitted Date is required";
      // }
      // if (!formData.selectedRevision) {
      //   newErrors.selectedRevision = "revision is required";
      // }
      if(!formData.revisions.length){
        newErrors.revisions = "revision is required"
      }
      if (!formData.receivedCopies) {
        newErrors.receivedCopies = "receivedCopies are required";
      } else if(tableRowData?.noOfSiteHeadHardCopyRevisions && formData.receivedCopies>tableRowData?.noOfSiteHeadHardCopyRevisions){
        newErrors.receivedCopies = `recieved copies should be less than ${tableRowData?.noOfSiteHeadHardCopyRevisions+1}`
      }

      if (!formData.drawingDWGFileName) {
        newErrors.drawingDWGFileName = "Drawing File is required";
      }
      console.log(newErrors,"errors")
    return newErrors;
  };

  const handleFormSubmit = () =>{
    const errors = validateForm(formData);
    console.log("errors", errors);
    setErrors(errors);
    if(Object.keys(errors).length>0) return;
    submitReceived(formData.registerId);
  }

  const submitReceived = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedSiteHeadHardCopyRevisions: [
          {
            hardCopySubmittedDate: todayDate(),
            receivedCopies: formData.receivedCopies,
            revision: formData.revisions,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/HardCopyRevision/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingDWGFileName) {
        const hardCopyFile = new FormData();
        hardCopyFile.append("hardCopyFile", formData.drawingDWGFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/hardCopyFile/${registerId}`,
          hardCopyFile
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      // window.location.reload();
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);

      // Handle error, show error message, etc.
      alert("Failed to submit the form. Please try again later.");
    }
  };

  useEffect(() => {
    const fetchUploadData = async () => {
      try {
        const response = await webApi.get(
          `api/architect/pendingSiteHead/${site}?filterType=collected`
        );
        console.log("Issued Data:", response.data);
        if(response.status === 200){
        const filterData = response.data.data.siteHeadHardCopyRevisions.filter((item)=>item.acceptedSiteHeadHardCopyRevisions.length===0 && item.acceptedRORevisions.length)
        setIssuedData([...response.data.data.rOHardCopyRevisions,...filterData])
        }
      } catch (error) {
        console.error("Error fetching issued data:", error);
      }
    };

    fetchUploadData();
  }, [site]);

  const uniqueConsultant = useMemo(() => {
    const consultant = issuedData.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [issuedData]);

  const filterSearchData = issuedData.filter((item) => {
    const matchesSearch =
      item.drawingNo?.toLowerCase().includes(searchQuery) ||
      item.designDrawingConsultant?.role?.toLowerCase().includes(searchQuery) ||
      item.category?.category?.toLowerCase().includes(searchQuery) ||
      item.drawingTitle?.toLowerCase().includes(searchQuery);

    const matchesCategory = selectedCategory
      ? item.category?.category === selectedCategory
      : true;

    return matchesSearch && matchesCategory;
  });
  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(userDetails, site);
    setShowAddFolder(showFolder);
  }, [userDetails, site]);

  const updatedRows = issuedData
  .filter((item) =>
    item.designDrawingConsultant?.role.includes(consultantValue)
  )
  .map((row, index) => {
    const dueOverdue = calculateDueDays(
      row.acceptedSiteSubmissionDate,
      todayDate()
    );

    return {
      ...row,
      serialNo: index + 1,
      drawingNo: row.drawingNo || "-",
      designConsultant: row?.designDrawingConsultant?.role || "-",
      drawingCategory: row.category?.category || "-",
      drawingTitle: row.drawingTitle || "-",
      from: row.for === "siteLevel" ? "Site Head" : row.for || "-",
      scheduledDate: row.acceptedSiteSubmissionDate
        ? new Date(row.acceptedSiteSubmissionDate).toLocaleDateString()
        : "N/A",
      dueOverdue, 
    };
  });

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchQuery.trim().toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

const columns = useMemo(() => [
  {
    field: "serialNo",
    headerName: "S.No",
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: "drawingNo",
    headerName: "Drawing No",
    flex: 1,
    filterable: true,
      sortable: true,
  },
  {
    field: "designConsultant",
    headerName: "Design Consultant",
    flex: 1,
    filterable: true,
      sortable: true,
  },
  {
    field: "drawingCategory",
    headerName: "Drawing Category",
    flex: 1.5,
    filterable: true,
      sortable: true,
  },
  {
    field: "drawingTitle",
    headerName: "Drawing Title",
    flex: 2,
    filterable: true,
      sortable: true,
    
  },
  {
    field: "from",
    headerName: "From",
    flex: 1,
    filterable: true,
      sortable: true,
    
  },
  {
    field: "scheduledDate",
    headerName: "Scheduled Date",
    flex: 1.5,
    filterable: true,
      sortable: true,
    
  },
  {
    field: "dueOverdue",
    headerName: "Due/Overdue",
    flex: 1,
    filterable: false,
    renderCell: (params) => (
      <span
        style={{
          color: params.row.dueOverdue.includes("overdue") ? "red" : "green",
        }}
      >
        {params.row.dueOverdue}
      </span>
    ),
  },
  {
    field: "action",
    headerName: "Action",
    flex: 0.5,
    filterable: false,
    renderCell: (params) => {
      const isSiteLevel = params.row.for === "siteLevel";
      return (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" ,marginTop:"24px"}}>
          {isSiteLevel && (
            <img
              src={Pen}
              alt="PenIcon"
              style={{ cursor: "pointer" }}
              onClick={() => handlePenClick(params.row)}
            />
          )}
        </div>
      );
    }, 
  },
], [issuedData]);

  return (
  <>
    <div style={{ marginTop: "10px" }}>
                       
        <Box
          className="dataGridContainer"
        >
          <ThemeProvider theme={theme}>
          <DataGrid
            columns={columns}
            rows={filteredRows}
            getRowId={(row) => row.drawingId}
            rowHeight={60}
            disableColumnMenu={false} // Keep the column menu for filtering
            pagination={false}
            hideFooter={true}
            getRowSpacing={params=>({
              top:params.isFirstVisible ? 0 : 5,
              bottom:params.isLastVisible ? 0 : 5
            })}
            
            getRowClassName={(params) =>
              "customHeader" 
            }
            
          />
          </ThemeProvider>
        </Box>


      </div>
    <Dialog
      open={editDialogOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: " #4D4D4D",
          margin: "0px",
          height: "50px",
          position:"relative"
        }}
      >
        <p style={{ marginTop:"15px",width:"100%", textAlign: "center", color: "white" }}>
          Drawing
        </p>
        <RxCross1
          size={20}
          style={{
          position:"absolute",
          right:"10px",
            cursor: "pointer",
            color: "white",
          }}
          onClick={handleClose}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0",
            border: "1px solid var(--primary-color)",
          }}
        >
         

          
            <button
              
              style={{
                background:
                   "var(--primary-color)"
                    ,
                color:
                 "white" ,
                width: "150px",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Received
            </button>
          
        </div>
      </div>
      <DialogContent
        style={{
          padding: "25px",
          minWidth: "300px",
          minHeight: "300px",
          maxHeight: "70vh",
          overflowY: "auto",
        }}
      >
        <>
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
                width: "80%",
              }}
            >
              <div>
                <label
                  htmlFor="designDrawingConsultant"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Design Consultant
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input type="text" name="designDrawingConsultant" value={formData?.designDrawingConsultant} className="form-control fs-12" style={{width:"100%"}} onChange={handleChange} readOnly/>
                {/* <select
    id="designDrawingConsultant"
    name="designDrawingConsultant"
    value={formData.designDrawingConsultant}
    onChange={handleChange}
    className="form-control fs-12"
    style={{ width: '100%' }}
  >
    <option value="">Select Design Consultant</option>
    {Array.from(new Set(registerList.map(register => register.designDrawingConsultant?.designDrawingConsultant)))
      .filter(Boolean)
      .map((consultant, index) => (
        <option key={index} value={consultant}>{consultant}</option>
    ))}
  </select> */}
                {errors.designDrawingConsultant && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.designDrawingConsultant}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingNumber"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing No
                  <span style={{ color: "red" }}>*</span>
                </label>
                {/* <select
  id="drawingNumber"
  name="drawingNumber"
  value={formData.drawingNumber}
  onChange={handleChange}
  className="form-control fs-12"
  style={{ width: '100%' }}
>
  <option value="">Select Drawing No</option>
  {filteredDrawingOptions.map(register => (
    <option key={register._id} value={register.drawingNo}>{register.drawingNo}</option>
  ))}
</select> */}
                <input className= "form-control fs-12" name="drawingNumber" value={formData.drawingNumber} style={{width:"100%"}} readOnly/>

                {errors.drawingNumber && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingNumber}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Title
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="drawingName"
                  name="drawingName"
                  value={formData.drawingName}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.drawingName && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingName}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="category"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Category
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.category && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.category}
                  </p>
                )}
              </div>
              
                <div>
                  <label
                    htmlFor="receivedCopies"
                    className="fs-10 fw-bold text-start py-2
              "
                  >
                    No of Copies Received
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    id="receivedCopies"
                    name="receivedCopies"
                    placeholder="Enter No of Received Copies"
                    value={formData.receivedCopies}
                    onChange={(e)=>{
                      if( formData.designDrawingConsultant && formData.drawingNumber )
                      handleChange(e)
                    }}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                  {errors.receivedCopies && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.receivedCopies}
                    </p>
                  )}
                </div>
              
              {showAddFolder ? <div>
                <label
                  htmlFor="folder"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Folder
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="folder"
                  name="folder"
                  value={formData.folder}
                  onChange={handleChange}
                  placeholder="Enter your  Folder "
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.folder && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.folder}
                  </p>
                )}
              </div>:""}
              <div>
                <label
                  htmlFor="selectedRevision"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Select Revision<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  name="revisions"
                  value={formData.revisions}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                >
                  { (
                    Object.keys(tableRowData).length > 0 ? (
                      <>
                        <option value="">Select Revision</option>
                        {tableRowData.acceptedRORevisions.map((rev, index) => (
                          <option key={index} value={rev.revision}>
                            {rev.revision}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option value="">No soft copy revisions available</option>
                    )
                  )} 
                </select>
                {errors.revisions && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.revisions}
                  </p>
                )}
              </div>

                <>
                  <div>
                    <label
                      htmlFor="drawingDWGFileName"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Upload File
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      id="drawingDWGFileName"
                      name="drawingDWGFileName"
                      onChange={(e) => 
                          handleChange(e)
                        }
                      placeholder="Select your drawing file"
                      className="form-control fs-12"
                      style={{ width: "100%", height: "43px" }}
                    />
                    {errors.drawingDWGFileName && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.drawingDWGFileName}
                      </p>
                    )}
                  </div>

                  {/* <div>
                  <label
                    htmlFor="drawingPDFFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload PDF file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    id="drawingPDFFileName"
                    name="drawingPDFFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.name.endsWith(".pdf")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .pdf file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: '43px'  }}
                  />
                  {errors.drawingPDFFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingPDFFileName}
                    </p>
                  )}
                </div> */}
                </>
              
                {/* <>
                  <div>
                    <label
                      htmlFor="drawingDWGFileName"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      upload DWG file
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      accept=".dwg"
                      id="drawingDWGFileName"
                      name="drawingDWGFileName"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.name.endsWith(".dwg")) {
                          handleChange(e);
                        } else {
                          alert("Please select a .dwg file.");
                          e.target.value = null; // Reset the file input if the file is not valid
                        }
                      }}
                      placeholder="Select your drawing file"
                      className="form-control fs-12"
                      style={{ width: "100%", height: "43px" }}
                    />
                    {errors.drawingDWGFileName && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.drawingDWGFileName}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="drawingPDFFileName"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      upload PDF file
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      accept=".pdf"
                      id="drawingPDFFileName"
                      name="drawingPDFFileName"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.name.endsWith(".pdf")) {
                          handleChange(e);
                        } else {
                          alert("Please select a .pdf file.");
                          e.target.value = null; // Reset the file input if the file is not valid
                        }
                      }}
                      placeholder="Select your drawing file"
                      className="form-control fs-12"
                      style={{ width: "100%", height: "43px" }}
                    />
                    {errors.drawingPDFFileName && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.drawingPDFFileName}
                      </p>
                    )}
                  </div>
                </> */}
              
            </div>
          </section>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              style={{
                padding: "10px",

                border: "1px solid lightgray",
                backgroundColor: "transparent",
                width: "100px",
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = "lightgray")
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = "transparent")
              }
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              style={{
                marginLeft: "50px",
                padding: "10px",

                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleFormSubmit}
            >
              Submit
            </button>
          </div>
        </>
      </DialogContent>
    </Dialog>
    <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue} />
  </> 
  );
};

export default IssuedFromR0;
