/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId, userInfo } from "../../../atoms";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import { Tab1, Tabs1 } from "../../Tabs/Tabv2";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import filter from "../../../Images/Filter.svg";
import { FaFilter } from "react-icons/fa";
import Gallery from "../../../Images/Gallery.svg"
import Eye from "./IconsSiteHead/Eye.svg";
import Download from "./IconsSiteHead/Download.svg";
import ShowFolder, { canShowFolder } from "../ShowFolder";
import { MdCancel } from "react-icons/md";
import SortByPopup from "../SortByPopup";
import ArrowToggle from "../ArrowToggle";
import { BiSortAlt2 } from "react-icons/bi";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", 
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", 
          },
        },
      },
    },
  },
});

const ReceivedTable = ({ filterCondition, reloadData, setReloadData }) => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();
  const navigate = useNavigate();
  const [currentUserInfo] = useRecoilState(userInfo);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    acceptedArchitectDate: "",
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingFileName: null,
    revisions: [],
    selectedRevision: "",
    receivedCopies: "",
  });

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  // const handleViewOpen = (drawingItem) => {
  //   setSingleDrawingData(drawingItem);
  //   setViewDialogOpen(true);
  // };

  const [filters, setFilters] = useState({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
  });

  const [searchValue, setSearchValue] = useState("");
  // sort by start
  const [sortField, setSortField] = useState(""); // Field to sort by
  const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
  const [activeSortPopup, setActiveSortPopup] = useState(null);

  const handleSortClick = (field) => {
    setFilters({
      drawingNo: "",
      consultant: "",
      category: "",
      drawingTitle: "",
      scheduledDate: "",
      submissionDate: "",
      revision: "",
    });
    setSearchValue("");
    setActiveSortPopup((prev) => (prev === field ? null : field));
  };

  const handleSortSelection = (order) => {
    if (activeSortPopup) {
      setSortField(activeSortPopup);
      setSortOrder(order);
      setActiveSortPopup(null); // Close the popup after selection
    }
  };
  // sort by end
  useEffect(()=>{
    setFilters({
      drawingNo: "",
      consultant: "",
      category: "",
      drawingTitle: "",
      scheduledDate: "",
      submissionDate: "",
      revision: "",
    });
    setSearchValue("");
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
  },[filterCondition])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    if (drawingItem.acceptedROHardCopyRevisions.length > 0) {
      setSelectedTab(drawingItem.acceptedROHardCopyRevisions.length - 1);
    }
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const fetchData = async () => {
    try {
      let response;
      if (filterCondition === "approved") {
        response = await webApi.get(
          `api/architect/upload/${site}?filterType=received`
        );
      } else if (filterCondition === "pending") {
        response = await webApi.get(
          `api/architect/pending/${site}?filterType=received`
        );
      }
      setDrawingList(response.data.data);
      console.log(drawingList, "responsedata of architect", filterCondition);
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    console.log(drawingList, "Updated drawingList");
  }, [drawingList]);

  useEffect(() => {
    fetchData();
    setReloadData(false);
  }, [reloadData === true]);
  
  const [searchActive, setSearchActive] = useState(false);
  const [selectFolder, setSelectFolder] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");

  // const handleSearchChange = useCallback((e) => {
  //   const inputValue = e.target.value.trim().toLowerCase();
  //   setSearchValue(inputValue);
  // }, []);

  // const filteredDrawings = useMemo(() => {
  //   if (!searchValue) return drawingList;

  //   return drawingList.filter((drawing) => {
  //     const matchesSearch = [
  //       drawing.drawingNo,
  //       drawing.drawingTitle,
  //       drawing.designDrawingConsultant?.role,
  //       drawing.category?.category,
  //     ].some((field) => field?.toLowerCase().includes(searchValue));

  //     const matchesCategory = selectedCategory
  //       ? drawing.category?.category === selectedCategory
  //       : true;

  //     const matchesFolder = selectFolder
  //       ? drawing.folderName?.folderName === selectFolder.toLowerCase()
  //       : true;

  //     return matchesSearch && matchesCategory && matchesFolder;
  //   });
  // }, [searchValue, selectedCategory, selectFolder, drawingList]);

  // useEffect(() => {
  //   console.log("Current Search Value:", searchValue);
  //   console.log("Filtered Drawings:", filteredDrawings);
  // }, [searchValue, filteredDrawings]);

 

  const handleTypeSelect = (type, selectedType) => {
    setDrawingList((data) =>
      data.filter((drawingItem) => {
        const category = drawingItem?.category?.category?.toLowerCase() || "";
        const folderName =
          drawingItem?.folderId?.folderName?.toLowerCase() || "";

        let matchesCategory = true;
        if (type === "category") {
          matchesCategory = selectedType
            ? category === selectedType.toLowerCase()
            : true;
        }

        let matchesFolder = true;
        if (type === "folder") {
          matchesFolder = selectedType
            ? folderName === selectedType.toLowerCase()
            : true;
        }

        return matchesCategory && matchesFolder;
      })
    );
  };

  

  

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0], // Assuming you are only uploading one file
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleEdit = (selected) => {
    const combinedRevisions = [
      ...selected.acceptedArchitectRevisions,
      // ...selected.acceptedRORevisions,
    ];

    setSelectedDrawing(selected);
    setFormData({
      registerId: selected._id,
      drawingNumber: selected.drawingNo || "",
      drawingName: selected.drawingTitle || "",
      category: selected.category.category || "", // Ensure the category field is properly accessed
      designDrawingConsultant: `${selected.designDrawingConsultant?.role}`,
      acceptedArchitectDate: selected.acceptedArchitectDate
        ? new Date(selected.acceptedArchitectDate).toLocaleDateString()
        : "",
      revisions: combinedRevisions,
      submittedDate: todayDate(),
      // dueDays: 0,
      // issuedSoftCopy: selected.issuedSoftCopy,
      // receivedSoftCopy: selected.receivedSoftCopy,
      // drawingFileName: selected.drawingFileName
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [currentTab, setCurrentTab] = useState("received");

  const validateForm = (formData, currentTab) => {
    const newErrors = {};
    if (currentTab === "upload") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
      // if (!formData.remarks) errors.remarks = 'This field is required.';
    } else if (currentTab === "received") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      if (!formData.selectedRevision) {
        newErrors.revisions = "revision is required";
      }
      if (!formData.receivedCopies) {
        newErrors.receivedCopies = "receivedCopies are required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
    }
    return newErrors;
  };
  const handleFormSubmit = async () => {
    // Validate the form data
    const errors = validateForm(formData, currentTab);
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    // Perform API call based on the active tab
    if (currentTab === "upload") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitUpload(formData.registerId);
    }
    if (currentTab === "received") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReceived(formData.registerId);
    }
  };

  const submitUpload = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedArchitectRevisions: [
          {
            softCopySubmittedDate: todayDate(),
            issuedSoftCopy: formData.issuedSoftCopy,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/revisions/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("drawingFileName", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/${registerId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      // Handle error, show error message, etc.
      alert("Failed to submit the form. Please try again later.");
    }
  };

  const submitReceived = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedROHardCopyRevisions: [
          {
            hardCopySubmittedDate: todayDate(),
            revision: formData.selectedRevision,
            receivedCopies: formData.receivedCopies,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/HardCopyRevision/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const hardCopyFile = new FormData();
        hardCopyFile.append("hardCopyFile", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/hardCopyFile/${registerId}`,
          hardCopyFile
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      // Handle error, show error message, etc.
      alert("Failed to submit the form. Please try again later.");
    }
  };

  // const filteredDrawingList = drawingList
  //   .filter(
  //     (item) =>
  //       (filterCondition === "approved"
  //         ? item.acceptedROHardCopyRevisions?.length > 0
  //         : item.acceptedROHardCopyRevisions.length === 0) &&
  //       (item.drawingNo?.toLowerCase().includes(searchValue.toLowerCase()) ||
  //         item.drawingTitle
  //           ?.toLowerCase()
  //           .includes(searchValue.toLowerCase()) ||
  //         item.designDrawingConsultant?.role
  //           ?.toLowerCase()
  //           .includes(searchValue.toLowerCase()) ||
  //         (item.category && typeof item.category === "string"
  //           ? item.category.toLowerCase().includes(searchValue.toLowerCase())
  //           : false))
  //     // (item.acceptedArchitectHardCopyRevisions[0]?.hardCopySubmittedDate && typeof item.acceptedArchitectHardCopyRevisions[0].hardCopySubmittedDate === 'string' ? item.acceptedArchitectHardCopyRevisions[0].hardCopySubmittedDate.toLowerCase().includes(searchValue.toLowerCase()) : false) ||
  //     // (item.acceptedArchitectHardCopyRevisions[0]?.receivedCopies && typeof item.acceptedArchitectHardCopyRevisions[0].receivedCopies === 'string' ? item.acceptedArchitectHardCopyRevisions[0].receivedCopies.toLowerCase().includes(searchValue.toLowerCase()) : false) ||
  //     // (item.acceptedArchitectHardCopyRevisions[0]?.revision && typeof item.acceptedArchitectHardCopyRevisions[0].revision === 'string' ? item.acceptedArchitectHardCopyRevisions[0].revision.toLowerCase().includes(searchValue.toLowerCase()) : false)
  //   )
  //   .sort((a, b) => {
  //     const consultantA = a.category?.category
  //       ? a.category?.category.toLowerCase()
  //       : "";
  //     const consultantB = b.category?.category
  //       ? b.category?.category.toLowerCase()
  //       : "";
  //     return consultantA.localeCompare(consultantB); // Sort alphabetically
  //   });

  const handleDownload = async (id, revision) => {
    console.log(id, "id of hard copy");
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/RoHardCopy/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "opening...",
    });
    try {
      // Make the request to get the response based on the updated endpoint
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/RoHardCopy/${revision}`,

        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "view", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);

    // if (!searchInput) {
    //   fetchData();
    //   setSearchActive(false);
    // } else {
    //   const filteredDrawings = initialDrawingList.filter((drawingItem) => {
    //     const drawingNumber = drawingItem.drawingNo?.toLowerCase() || "";
    //     const drawingName = drawingItem.drawingTitle?.toLowerCase() || "";
    //     const consultant =
    //       drawingItem.designDrawingConsultant?.role?.toLowerCase() || "";
    //     const category = drawingItem.category?.category?.toLowerCase() || "";
    //     // const acceptedROSubmissionDate = drawingItem.acceptedROSubmissionDate ? new Date(drawingItem.acceptedROSubmissionDate).toLocaleDateString().toLowerCase() : "";
    //     const submittedROSubmissionDate = drawingItem.submittedROSubmissionDate
    //       ? new Date(drawingItem.submittedROSubmissionDate)
    //           .toLocaleDateString()
    //           .toLowerCase()
    //       : "";
    //     // const dueDays = calculateDueDays(drawingItem.acceptedArchitectDate).toString().toLowerCase(); // Convert dueDays to lowercase for comparison
    //     // const dueStatus = dueDays >= 0 ? ${dueDays} days : ${Math.abs(dueDays)} days overdue;
    //     const revisions =
    //       drawingItem.acceptedArchitectRevisions
    //         ?.map((revisionItem) => revisionItem.revision.toLowerCase())
    //         .join(" ") || "";

    //     // Filter based on the selected filter criteria
    //     switch (selectedFilter) {
    //       case "Drawing No":
    //         return drawingNumber.includes(searchInput);
    //       case "Drawing Consultant":
    //         return consultant.includes(searchInput);
    //       case "Drawing Category":
    //         return category.includes(searchInput);
    //       case "Drawing Title":
    //         return drawingName.includes(searchInput);
    //       // case 'Accepted RO Submission Date':
    //       //   return acceptedROSubmissionDate.includes(searchInput);
    //       case "Final Submission Date":
    //         return submittedROSubmissionDate.includes(searchInput);
    //       // case 'Due/Overdue':
    //       //   return dueStatus.includes(searchInput) || dueDays.includes(searchInput);
    //       case "Revision":
    //         return revisions.includes(searchInput);
    //       default:
    //         return (
    //           drawingNumber.includes(searchInput) ||
    //           drawingName.includes(searchInput) ||
    //           consultant.includes(searchInput) ||
    //           category.includes(searchInput) ||
    //           // acceptedROSubmissionDate.includes(searchInput) ||
    //           submittedROSubmissionDate.includes(searchInput) ||
    //           // dueStatus.includes(searchInput) ||
    //           // dueDays.includes(searchInput) ||
    //           revisions.includes(searchInput)
    //         );
    //     }
    //   });

    //   setDrawingList(filteredDrawings);
    // }
  };
  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const dropdownRef = useRef(null);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isCategoriesDropdownOpen, setCategoriesDropdownOpen] = useState(false);
  const [isFoldersDropdownOpen, setFoldersDropdownOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectedConsultant, setSelectedConsultant] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
    
  };

  const handleType = (type) => {
    if (type === "all") {
      setSelectedCategory("");

      setFilterDropdownOpen(false);
      setCategoriesDropdownOpen(false);
      setFoldersDropdownOpen(false);
    } else {
      setSelectedCategory(type);
      setFilterDropdownOpen(false);
      setFoldersDropdownOpen(false);
    }
  };

  const uniqueFolders = useMemo(() => {
    const folder = drawingList.map((item) => item.folderId?.folderName);
    return [...new Set(folder)];
  }, [drawingList]);

  const uniqueCategories = useMemo(() => {
    const categories = drawingList.map((item) => item.category?.category);
    return [...new Set(categories)];
  }, [drawingList]);

  const uniqueConsultant = useMemo(() => {
    const consultant = drawingList.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [drawingList]);

  console.log(uniqueCategories, uniqueFolders, "SITE Head Upload categories");

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [filteredGoData, setFilteredGoData] = useState(null); 

  const [showFilteredData, setShowFilteredData] = useState(false); 

  const handleConsultantSelect = (consultant) => {
    setSelectedConsultant(consultant)
    setShowFilteredData(false);
      
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(
      (prevSelected) =>
        prevSelected.includes(category)
          ? prevSelected.filter((c) => c !== category) 
          : [...prevSelected, category] 
    );
    setShowFilteredData(false);
  };

  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
    setShowFilteredData(false);
  };

  const handleGoClick = () => {
    console.log("Selected Consultants:", selectedConsultant);
    console.log("Selected Categories:", selectedCategory);
    console.log("Selected Folders:", selectedFolder);
    if(selectedConsultant || selectedCategory.length || selectedFolder){
      setShowFilteredData(true);
      toggleFilterDropdown();
      }
  };

  const filteredDataAndUploadData = useMemo(() => {
    if (!showFilteredData) {
    return drawingList;
  }
    return drawingList.filter((item) =>{ 
      const matchesConsultant = selectedConsultant === "" ||  item?.designDrawingConsultant?.role === selectedConsultant;

    const matchesCategory = selectedCategory.length === 0 ||  selectedCategory.includes(item?.category?.category);

    const matchesFolder = 
      !selectedFolder || item.folderId?.folderName === selectedFolder;

    return matchesConsultant && matchesCategory && matchesFolder; 
  });
  }, [showFilteredData, selectedConsultant, selectedCategory, selectedFolder, drawingList]);

  const handleClearAll = () =>{
    setSelectedCategory([])
    setSelectedFolder("")
    setSelectedConsultant("")
  }
  const handleFilterCancel = () =>{
    toggleFilterDropdown()
    setShowFilteredData(false)

  } 

  const handleRemove = (type, value) => {
    setShowFilteredData(false)

    if (type === "consultant") {
      setSelectedConsultant("");
    } else if (type === "category") {
      setSelectedCategory(selectedCategory.filter((cat) => cat !== value));
    } else if (type === "folder") {
      setSelectedFolder("");
    }
  };
  

  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
    
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    })
    setSortField("");
    setSortOrder("");
    setActiveSortPopup(null);
  }, []);
  
  const handleFilterChange = (column, value) => {
    setSearchValue("")
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
    setFilters((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = ""; 
          return acc;
      }, {}),
      [column]: value, 
  }));
    
  };

  const updatedRows = drawingList.map((row, index) => ({
    ...row,
    serialNo: index + 1,
    drawingNo: row.drawingNo || "",
    consultant: row.designDrawingConsultant?.role || "",
    category: row.category?.category || "",
    drawingTitle: row.drawingTitle || "",
    acceptedROHardCopyRevisions: row.acceptedROHardCopyRevisions || [],
    receivedDate:
      row.acceptedROHardCopyRevisions?.length > 0
        ? formatDate(row.acceptedROHardCopyRevisions[0]?.hardCopySubmittedDate) || "N/A"
        : "N/A",
    receivedCopies:
      row.acceptedROHardCopyRevisions?.length > 0
        ? row.acceptedROHardCopyRevisions[0]?.receivedCopies || "N/A"
        : "N/A",
    revision:
      row.acceptedROHardCopyRevisions?.length > 0
        ? row.acceptedROHardCopyRevisions
            .slice(-1)
            .map((rev) => rev.revision || "NA")
            .join(", ")
        : "-",
    action: {
      handleViewOpen: () => handleViewOpen(row),
      handleDownload: () => {
        const latestRevision =
          row.acceptedROHardCopyRevisions?.length > 0
            ? row.acceptedROHardCopyRevisions.slice(-1)[0].revision
            : null;
        handleDownload(row.drawingId, latestRevision);
      },
    },
  }));
  

 

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = useMemo(
    () => { if (filterCondition === "approved"){
      return [
      {
        field: "serialNo",
        headerName: "S.No",
        flex: 1,
        filterable:false,
        sortable:false,
      },
      {
        field: "drawingNo",
        headerName: "DRWG.No",
        flex: 1,
        filterable:true,
        sortable: true,
        
      },
      {
        field: "consultant",
        headerName: "Consultant",
        flex: 1,
        filterable:true,
        sortable: true,
        
      },
      {
        field: "category",
        headerName: "Drawing Category",
        flex: 1,
        filterable:true,
        sortable: true,
      },
      {
        field: "drawingTitle",
        headerName: "Drawing Title",
        flex: 1,
        filterable:true,
        sortable: true,
       
      },
      {
        field: "receivedDate",
        headerName: "Received Date",
        flex: 1,
        filterable:true,
        sortable: true,
      },
      {
        field: "receivedCopies",
        headerName: "Received Copies",
        flex: 1,
        filterable:true,
        sortable: true,
      },
      {
        field: "revision",
        headerName: "Revision",
        flex: 1,
        filterable:true,
        sortable: true,
      },
      {
        field: "action",
        headerName: "Action",
        flex: 1,
        renderCell: (params) => (
          <div style={{ display: "flex", alignItems: "center",marginTop:"24px" }}>
            <img
              src={Eye}
              alt="view"
              style={{ marginRight: "5px", cursor: "pointer" }}
              onClick={() => handleViewOpen(params.row)}
            />
            <img
              src={Download}
              alt="download"
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => {
                const latestRevision =
                  params.row.acceptedROHardCopyRevisions?.length > 0
                    ? params.row.acceptedROHardCopyRevisions.slice(-1)[0].revision
                    : null;
                handleDownload(params.row.drawingId, latestRevision);
              }}
            />
          </div>
        ),
      },
    ]} else {
      return [
        {
          field: "serialNo",
          headerName: "S.No",
          flex: 0.5,
          sortable:false,
          filterable:false,
          
        },
        {
          field: "drawingNo",
          headerName: "DRWG.No",
          flex: 1,
          filterable:true,
          sortable: true,
          
        },
        {
          field: "consultant",
          headerName: "Consultant",
          flex: 1,
          filterable:true,
          sortable: true,
        },
        {
          field: "category",
          headerName: "Drawing Category",
          flex: 1,
          filterable:true,
          sortable: true,
         
        },
        {
          field: "drawingTitle",
          headerName: "Drawing Title",
          flex: 1,
          filterable:true,
          sortable: true,
          
        },
      ]
    }},
    
    [drawingList] 
  );

  useEffect(()=>{console.log(filters,"filters")},[filters])

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "280px",
            paddingLeft: "20px",
            height: "50px",
            display: "flex",
            marginTop: "-62px",
            marginRight:"50px"
          }}
        >
          <input
            placeholder="Search Drawing Lists"
            onChange={handleSearchChange}
            style={{
              paddingLeft: "20px",
              border: "1px solid var(--primary-color)",
              width: "245px",
              height: "40px",
            }}
          />
          <Icon.Search
            style={{
              position: "absolute",
              right: "30px",
              top: "40%",
              transform: "translateY(-50%)",
              color: "#ACACAC",
              
            }}
          />
        </div>
        {/* {filterCondition === "approved" && (
          <div ref={dropdownRef} style={{ position: "relative",marginTop:"-60px",marginLeft:"15px",marginRight:"10px" }}>
          <div onClick={toggleFilterDropdown}>
            <FaFilter
              size={39}
              style={{
                border: "1px solid var(--primary-color)",
                padding: "5px",
                marginRight: "-10px",
              }}
            />
          </div>
          {isFilterDropdownOpen && (
            <div
              style={{
                position: "absolute",
                background: "white",
                borderRadius: "4px",
                zIndex: 1000,
                textAlign: "left",
                marginTop: "20px",
                marginLeft: "-558px",
                padding: "15px",
                width: "500px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: selectedConsultant || selectedCategory.length || selectedFolder?"space-between":"flex-end",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginTop:"10px"
                }}
              >
                
                <MdCancel style={{position:"absolute",fontSize:"23px" ,right:"-10px",top:"-24px",cursor:"pointer"}}onClick={handleFilterCancel} />
                {(selectedConsultant || selectedCategory.length || selectedFolder) && (
                    <button
                      style={{
                        padding: "6px 10px",
                        backgroundColor: "crimson",
                        color: "white",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearAll}
                    >
                      Clear All
                    </button>
                  )}
                  <button
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                      fontSize: "16px",
                      padding: "5px 15px",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginBottom: "5px",
                    }}
                    onClick={handleGoClick}
                  >
                    Go
                  </button>
                  
                
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginBottom: "15px",
                }}
              >
                {selectedConsultant && (
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("consultant", selectedConsultant)}
                  >
                    {selectedConsultant} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                )}

                {selectedCategory.map((category, index) => (
                  <button
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("category", category)}
                  >
                    {category} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                ))}

                {selectedFolder && (
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("folder", selectedFolder)}
                  >
                    {selectedFolder} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                )}
              </div>

              <div style={{ flex: 3 }}>
                <span style={{ cursor: "pointer", position: "relative" }}>
                  <p style={{ color: "black", fontSize: "20px" }}>Consultant</p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "8px",
                    }}
                  >
                    {uniqueConsultant.map((consultant, index) => (
                      <div
                        key={index}
                        style={{
                          flex: "1 1 calc(25.33% - 15px)",
                          backgroundColor:
                            selectedConsultant === consultant ? "#ffd700" : "#e0f7fa",
                          fontWeight:
                            selectedConsultant === consultant ? "700" : "500",
                          borderRadius: "4px",
                          textAlign: "center",
                          fontSize: "14px",
                          padding: "5px",
                          color: selectedConsultant === consultant ? "white" : "#333",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() => handleConsultantSelect(consultant)}
                      >
                        {consultant}
                      </div>
                    ))}
                  </div>
                </span>

                        <span style={{ cursor: "pointer", position: "relative" }}>
                          <p style={{ color: "black", fontSize: "20px" }}>Categories</p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                            }}
                          >
                            {uniqueCategories.map((category, index) => (
                              <div
                                key={index}
                                style={{
                                  flex: "1 1 calc(25.33% - 15px)",
                                  backgroundColor:  selectedCategory.includes(category)?"#ffd700":"#e0f7fa",
                                  fontWeight: selectedCategory.includes(category)?"700":"500",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: "5px",
                                  color: selectedCategory.includes(category)?"white":"#333",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() => handleCategorySelect(category)}
                              >
                                {category}
                              </div>
                            ))}
                          </div>
                        </span>

                        {showAddFolder?<span style={{ cursor: "pointer", position: "relative" }}>
                          <p
                            style={{
                              color: "black",
                              fontSize: "20px",
                              marginTop: "10px",
                            }}
                          >
                            Folders
                          </p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                            }}
                          >
                            {uniqueFolders.map((folder, index) => (
                              <div
                                key={index}
                                style={{
                                  flex: "1 1 calc(25.33% - 15px)",
                                  backgroundColor: "#e0f7fa",
                                  fontWeight: selectedFolder === folder ? "700" : "500",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: "5px",
                                  color: "#333",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() => handleFolderSelect(folder)}
                              >
                                {folder}
                              </div>
                            ))}
                          </div>
                        </span>:""}
                        <>
                          
                        </>
                      </div>
                    </div>
                  )}
        </div>
        )} */}
      </div>

      {filterCondition === "approved" && (
        <div style={{ marginTop: "10px" }}>
          <Box
            className="dataGridContainer"
          >
            <ThemeProvider theme={theme}>
            <DataGrid
              columns={columns}
              rows={filteredRows}
              getRowId={(row) => row.drawingId}
              rowHeight={60}
              disableColumnMenu={false} 
              pagination={false}
              hideFooter={true}
              getRowSpacing={params=>({
                top:params.isFirstVisible ? 0 : 5,
                bottom:params.isLastVisible ? 0 : 5
              })}
              
              getRowClassName={(params) =>
                "customHeader" 
              }
              
            />
            </ThemeProvider>
          </Box>
        </div>
      )}
      {filterCondition === "pending" && (
        <div style={{ marginTop: "10px" }}>
          <Box
            className="dataGridContainer"
          >
            <ThemeProvider theme={theme}>
            <DataGrid
              columns={columns}
              rows={filteredRows}
              getRowId={(row) => row.drawingId}
              rowHeight={60}
              disableColumnMenu={false} // Keep the column menu for filtering
              pagination={false}
              hideFooter={true}
              getRowSpacing={params=>({
                top:params.isFirstVisible ? 0 : 5,
                bottom:params.isLastVisible ? 0 : 5
              })}
              
              getRowClassName={(params) =>
                "customHeader" 
              }
              
            />
            </ThemeProvider>
          </Box>
        </div>
      )}

      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <p
            style={{ width:"100%", textAlign: "center", color: "white",marginTop:"15px" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div style={{ overflowX: "auto", maxWidth: "230px" }}>
            <div
              style={{
                display: "flex",
                gap: "0",
                border: "1px solid var(--primary-color)",
              }}
            >
              {singleDrawingData?.acceptedROHardCopyRevisions?.length > 0 ? (
                <Tabs1 value={selectedTab} onChange={handleTabChange}>
                  {singleDrawingData.acceptedROHardCopyRevisions.map(
                    (revisionItem, index) => (
                      <Tab1
                        key={index}
                        value={index}
                        label={`${revisionItem.revision}`}
                        style={{
                          backgroundColor:
                            singleDrawingData.acceptedROHardCopyRevisions
                              .length === 1 || selectedTab === index
                              ? "var(--primary-color)"
                              : "transparent",
                          color:
                            singleDrawingData.acceptedROHardCopyRevisions
                              .length === 1 || selectedTab === index
                              ? "white"
                              : "var(--primary-color)",
                          width: "150px",
                          height: "35px",
                          cursor: "pointer",
                          borderRight:
                            index <
                            singleDrawingData.acceptedROHardCopyRevisions
                              .length -
                              1
                              ? "1px solid var(--primary-color)"
                              : "none",
                          // borderRadius: index === 0 && !isSingleRevision ? '5px 0 0 5px' : (index === singleDrawingData.acceptedRORevisions.length - 1 && !isSingleRevision ? '0 5px 5px 0' : 'none'),
                        }}
                      />
                    )
                  )}
                </Tabs1>
              ) : (
                <div>No Revisions</div>
              )}
            </div>
          </div>
        </div>

        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.designDrawingConsultant?.role || "NA"}
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>

        
                {showAddFolder ? (
                  <>
                    {console.log("Add Folder Name is displayed.consultant drawing hard copy")}
                    <div>Folder</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData?.folderId?.folderName || "NA"}
                    </div>
                  </>
                ) : (
                  console.log("Add Folder Name is hidden. consultant drawing hard copy")
                )}
             

              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>

              <div>Submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedSiteSubmissionDate
                  ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              <div>Hard Copy Submitted Date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedROHardCopyRevisions?.length > 0
                  ? new Date(
                      singleDrawingData.acceptedROHardCopyRevisions[0]
                        ?.hardCopySubmittedDate
                    ).toISOString()
                    .split("T")[0] || "-"
                  : "-"}
              </div>

              <div>Received Copies</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedROHardCopyRevisions?.length > 0
                  ? singleDrawingData.acceptedROHardCopyRevisions[0]
                      ?.receivedCopies || "-"
                  : "-"}
              </div>

              {singleDrawingData?.acceptedROHardCopyRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedROHardCopyRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedROHardCopyRevisions[
                          selectedTab
                        ].revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}

              {singleDrawingData?.acceptedROHardCopyRevisions?.length > 0 && (
                <>
                  <div>Drawing File</div>
                  <div>:</div>
                  <div style={{ display: "flex", marginLeft: "-120px" }}>
                    <img src={Gallery}
                      size={20}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        handleViewFile(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedROHardCopyRevisions[0]
                            ?.revision
                        )
                      }
                    />
                    <img src={Download}
                      size={20}
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() =>
                        handleDownload(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedROHardCopyRevisions[0]
                            ?.revision
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {/* <button
            onClick={() => {
              setCurrentTab('upload')
            }}
            style={{
              backgroundColor: currentTab === 'upload' ? 'var(--primary-color)': 'transparent',
              color: currentTab === 'upload' ? 'white' : 'var(--primary-color)',
              width: '150px',
              height: '35px',
              cursor: 'pointer',
              borderRight: '1px solid var(--primary-color)', 
             
            }}
          >
            Upload
          </button> */}

            <button
              onClick={() => setCurrentTab("received")}
              style={{
                background:
                  currentTab === "received"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "received" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Received
            </button>
          </div>
        </div>
        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <section
              style={{
                marginLeft: "80px",
                marginRight: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "50px",
                  width: "80%",
                }}
              >
                <div>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing No
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="drawingNumber"
                    name="drawingNumber"
                    value={formData.drawingNumber}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />

                  {errors.drawingNumber && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingNumber}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="drawingName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Title
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="drawingName"
                    name="drawingName"
                    value={formData.drawingName}
                    onChange={handleChange}
                    placeholder="Enter your drawing title"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.drawingName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingName}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Category
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    placeholder="Enter your Drawing Category"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.category && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.category}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="designDrawingConsultant"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Design Consultant
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="designDrawingConsultant"
                    name="designDrawingConsultant"
                    value={formData.designDrawingConsultant}
                    onChange={handleChange}
                    placeholder="Enter your  Design Consultant"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.designDrawingConsultant && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.designDrawingConsultant}
                    </p>
                  )}
                </div>

                {currentTab !== "received" && (
                  <>
                    <div>
                      <label
                        htmlFor="revisions"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Revisions
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="revisions"
                        name="revisions"
                        value={formData.revisions}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Revision</option>
                        <option value="R0">R0</option>
                      </select>
                      {errors.revisions && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.revisions}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="drawingFileName"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Upload Your Drawing File
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        accept=".dwg"
                        id="drawingFileName"
                        name="drawingFileName"
                        onChange={handleChange}
                        placeholder="Select your drawing file"
                        className="form-control fs-12"
                        style={{ width: "100%", height: "43px" }}
                      />
                      {errors.drawingFileName && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.drawingFileName}
                        </p>
                      )}
                    </div>
                  </>
                )}
                {currentTab === "received" && (
                  <>
                    <div>
                      <label
                        htmlFor="revisions"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Revisions
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="selectedRevision"
                        name="selectedRevision"
                        value={formData.selectedRevision}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Revision</option>
                        {formData.revisions.map((revision, index) => (
                          <option key={index} value={revision.revision}>
                            {revision.revision}
                          </option>
                        ))}
                      </select>
                      {errors.revisions && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.revisions}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="receivedCopies"
                        className="fs-10 fw-bold text-start py-2
              "
                      >
                        No of Copies Received
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        id="receivedCopies"
                        name="receivedCopies"
                        placeholder="Enter No of Received Copies"
                        value={formData.receivedCopies}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="drawingFileName"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Upload Your Drawing File
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg, .png"
                        id="drawingFileName"
                        name="drawingFileName"
                        onChange={handleChange}
                        placeholder="Select your drawing file"
                        className="form-control fs-12"
                        style={{ width: "100%", height: "43px" }}
                      />
                      {errors.drawingFileName && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.drawingFileName}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
            </section>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "45px",
              }}
            >
              <button
                style={{
                  padding: "10px",
                  border: "1px solid lightgray",
                  backgroundColor: "transparent",
                  width: "100px",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "lightgray")
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
                onClose={handleClose}
              >
                Cancel
              </button>
              <button
                style={{
                  marginLeft: "50px",
                  padding: "10px",
                  border: "1px solid var(--primary-color)",
                  width: "100px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReceivedTable;
