import logo from "../../Images/TMLOGO.png";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo, profileImageUrl, siteId ,enableModulesCount, TotalEnableModulesCount} from "../../atoms";
import NavbarTile from "./NavbarTile";
import AdminNavbarTile from "./AdminNavbarTile";
import { WebApimanager } from "../../WebApiManager";
import { useNavigate } from "react-router-dom";
import Notification from "../../Images/notification.svg";
import User from "../../Images/User.svg";
import SystemNotificationActiveIcon from "../../Images/SystemNotificationActiveIcon.svg";
import { BACKEND_URL } from "../../Utilities/Constants";
import { FaUserEdit } from "react-icons/fa";
import Settings from "../../Images/Settings.svg";
import Logout from "../../Images/LogOut.svg";
const Navbar = () => {
  let webApi = new WebApimanager();
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [siteID, setSiteID] = useRecoilState(siteId);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [enableModulesCount, setEnableModulesCount] = useRecoilState(TotalEnableModulesCount);
  const [navbarEnableModules, setNavbarEnableModules] = useState([]);
  const [adminEnableModules, setAdminEnableModules] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await webApi.get("api/notification");
        if (response.data && Array.isArray(response.data.data.notifications)) {
          const unreadNotifications = response.data.data.notifications.filter(
            (notification) => !notification.isRead
          );
          setUnreadCount(unreadNotifications.length);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchNotifications();
  }, []);

  useEffect(() => {
    const pageName = location.pathname.substring(1);
    setActiveItem(pageName);
  }, [location.pathname]);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName === activeItem ? null : itemName);
  };

  const handleProfileImageClick = () => {
    setShowDropdown(true);
  };

  const handleNotificationClick = () => {
    navigate("/systemnotifications");
  };

  const handleLogout = () => {
    setTimeout(() => {
      localStorage.removeItem("recoil-persist:userInfo");
      navigate("/");
    }, 100);
  };

  useEffect(() => {
    console.log("User Info State:", currentUserInfo);
    const pageName = location.pathname.substring(1);
    setActiveItem(pageName);
  }, [location.pathname]);

  useEffect(() => {}, []);

  useEffect(() => {
    console.log(siteID, "Navbar siteID");
  }, [siteID]);

  const matchedSite = useMemo(() => {
    return currentUserInfo.permittedSites?.find(
      (siteObj) => siteObj.siteId === siteID
    );
  }, [currentUserInfo, siteID]);

  const combinedModules = useMemo(() => {
    if (matchedSite) {
      return Object.keys(matchedSite.enableModules).filter(
        (module) => matchedSite.enableModules[module] === true
      );
    }

    // if (
    //   matchedSite.enableModules.communication &&
    //   matchedSite.enableModules.communicationDetails
    // ) {
    //   const { chat, mail } = matchedSite.enableModules.communicationDetails;

    //   if (chat) module.push("chat");
    //   if (mail) module.push("mail");
    // }

    return [];
  }, [matchedSite]);

  useEffect(() => {
    console.log("Combined Modules:", combinedModules);
  }, [combinedModules]);

  useEffect(() => {
    console.log("Combined matchedSite:", matchedSite);
  }, [matchedSite]);

  const [profilePic, setProfilePic] = useState(null);
  const [navUploadLogo, setNavUploadLogo] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await webApi.get("api/users/profilePicBanner");
        console.log("Response Navbar responce:", response);
        const { profilePic, uploadLogo } = response.data.data;
        setProfilePic(profilePic);
        setNavUploadLogo(uploadLogo);
      } catch (error) {
        console.log("Server error:", error);
      }
    };

    fetchProfileData();
  }, []);

  const navbarItems = [
    {
      title: "Dashboard",
      path: "dashboard",
      condition: combinedModules.includes("dashBoard"),
    },
    {
      title: "Task",
      path: "task",
      condition: combinedModules.includes("task"),
    },
    {
      title: "WorkStatus",
      path: "tracker",
      condition: combinedModules.includes("workStatus"),
    },
    {
      title: "Space",
      path: "space",
      condition: combinedModules.includes("space"),
    },
    {
      title: "User",
      path: "users",
      condition: combinedModules.includes("user"),
    },
    {
      title: "Issue",
      path: "issues",
      condition: combinedModules.includes("user"),
    },
    {
      title: "Mails",
      path: "notifications",
      condition: combinedModules.includes("communication"),
    },
    {
      title: "Chats",
      path: "chats",
      condition: combinedModules.includes("communication"),
    },
  ];

  const adminNavbarItems = [
    {
      title: "Company",
      path: "sites",
      condition: combinedModules.includes("company"),
    },
    {
      title: "Forms",
      path: "checklist",
      condition: combinedModules.includes("checkList"),
    },
    {
      title: "Drawing",
      path: "drawing",
      condition: combinedModules.includes("drawings"),
    },
    {
      title: "Planner",
      path: "planner",
      condition: combinedModules.includes("planner"),
    },
    { title: "P&M", path: "pnm", condition: combinedModules.includes("pAndM") },
    { title: "HR", path: "hr", condition: combinedModules.includes("hr") },
    { title: "EHS", path: "ehs", condition: combinedModules.includes("ehs") },
    { title: "QS", path: "qs", condition: combinedModules.includes("qs") },
    {
      title: "QA/QC",
      path: "QA/QC",
      condition: combinedModules.includes("qaAndQc"),
    },
    {
      title: "Admin",
      path: "Admin",
      condition: combinedModules.includes("admin"),
    },
    {
      title: "Store",
      path: "Store",
      condition: combinedModules.includes("store"),
    },
  ];

  useEffect(() => {
    if (
      combinedModules.length &&
      (!navbarEnableModules.length || !adminEnableModules.length)
    ) {
      const filteredAdminNavbarItems = adminNavbarItems.filter(
        (item) => item?.condition
      );
      console.log(filteredAdminNavbarItems, "filteredAdminNavbarItems");

      const filterAdmin = navbarItems.filter(
        ({ title, path, condition }) => condition
      );
      console.log(filterAdmin, "filterAdmin");
      if (filteredAdminNavbarItems.length + filterAdmin.length < 9) {
        filteredAdminNavbarItems.forEach((items) => {
          navbarItems.push(items);
        });
      }
      console.log(navbarItems, "navbarItems");
      console.log(filteredAdminNavbarItems.length + filterAdmin.length);
      setEnableModulesCount(
        filteredAdminNavbarItems.length + filterAdmin.length
      );
      if (filteredAdminNavbarItems.length + filterAdmin.length < 9)
        setNavbarEnableModules([...new Set(navbarItems)]);
      else {
        setNavbarEnableModules([...new Set(navbarItems)]);
        setAdminEnableModules([...new Set(adminNavbarItems)]);
      }
    }
  }, [currentUserInfo]);

  useEffect(()=>{
  },[currentUserInfo,siteID])

  return (
    <>
      {enableModulesCount > 8 ? (
        <nav
          style={{
            background: "#4D4D4D",
            position: "fixed",
            zIndex: "10",
            width: "100%",
            height: "50px",
            padding: "12px",
            display: "flex",
            justifyContent: "end",
            gap: "30px",
            paddingLeft: "60px",
            paddingRight: "60px",
          }}
        >
          {enableModulesCount > 8
            ? adminEnableModules.map(({ title, path, condition }) =>
                condition ? (
                  <AdminNavbarTile
                    key={title}
                    title={title}
                    path={path}
                    activeItem={activeItem}
                    handleItemClick={handleItemClick}
                  />
                ) : null
              )
            : ""}
        </nav>
      ) : (
        ""
      )}
      <nav
        className="flex justify-between items-center"
        style={{
          background: "white",
          position: "fixed",
          zIndex: "10",
          width: "100%",
          top: enableModulesCount > 8 ? "50px" : "0px",
          padding: "6px 60px",
          height:"70px",
        }}
      >
        <a
          href="dashboard"
          id="dashboard"
          className="flex gap-2 items-center no-underline focus:outline-none"
        >
          {navUploadLogo ? (
            <img
              src={`${BACKEND_URL}${navUploadLogo}`}
              className="h-12 object-cover max-w-18 max-h-12"
              alt="logo"
            />
          ) : (
            <img
              src={logo}
              className="h-12 object-cover max-w-18 max-h-12"
              alt="logo"
            />
          )}
        </a>

        <div
          id="nav-menu"
          className="lg:flex gap-[40px] no-underline focus:outline-none f-flex items-center"
        >
          {navbarEnableModules.map(({ title, path, condition }) =>
            condition ? (
              <NavbarTile
                key={title}
                title={title}
                path={path}
                activeItem={activeItem}
                handleItemClick={handleItemClick}
              />
            ) : null
          )}

          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                alt="notification"
                src={
                  activeItem === "systemnotifications"
                    ? SystemNotificationActiveIcon
                    : Notification
                }
                onClick={handleNotificationClick}
              />
              {unreadCount > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-10px",
                    background: "var(--primary-color)",
                    color: "white",
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                  }}
                >
                  {unreadCount}
                </div>
              )}
            </div>
            <div
              className="relative"
              style={{ position: "relative", cursor: "pointer" }}
              onClick={handleProfileImageClick}
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
              activeItem={activeItem}
            >
              {profilePic ? (
                <img
                  src={`${BACKEND_URL}${profilePic}`}
                  alt="Profile"
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "cover",
                    marginTop: "-5px",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <img
                  src={User}
                  alt="Profile"
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "cover",
                    marginTop: "-5px",
                    borderRadius: "50%",
                  }}
                />
              )}

              {showDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "-250px",
                    backgroundColor: "#fff",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    width: "280px",
                    height:'320px',
                    padding:'10px',
                   
                  }}
                >

                
                  <div
  style={{
    padding: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center", 
    justifyContent: "center",
    gap: "20px",
    cursor: "default",
    borderBottom: "1px solid #F2F2F2",
    height:'150px'
  }}
>
  <div>
    {profilePic ? (
      <img
        src={`${BACKEND_URL}${profilePic}`}
        alt="Profile"
        style={{
          width: "70px",
          height: "70px",
          objectFit: "cover",
          borderRadius: "20%",
        }}
      />
    ) : (
      <img
        src={User}
        alt="Profile"
        style={{
          width: "70px",
          height: "70px",
          objectFit: "cover",
          borderRadius: "20%",
        }}
      />
    )}
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center", 
    }}
  >
    <p style={{ color: "#4D4D4D", margin: 0,fontSize:'16px',fontWeight:'500' }}>
      {currentUserInfo.firstName} {currentUserInfo.lastName}
    </p>
    <p style={{ margin: 0,fontSize:'12px',fontWeight:'400',color: "#4D4D4D", }}>ID:{currentUserInfo.empId}</p>
    <p style={{ margin: 0 ,fontSize:'12px',fontWeight:'400',color: "#4D4D4D", }}>Dept:{currentUserInfo.department}</p>
  </div>
</div>

                  <div
                    style={{ padding: "8px", cursor: "pointer" ,display: "flex",
                      alignItems: "center",
                      justifyContent: "center",borderBottom: "1px solid #F2F2F2",
                    height:'70px',gap:'20px'}}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#f5f5f5")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = "")
                    }
                    onClick={() => setIsEditingProfile(true)}
                  >
                   <img src={Settings}/>      
                   <Link
                      to="/profile"
                      style={{ textDecoration: "none",color: "#4D4D4D",fontSize:'16px',fontWeight:'400'}}
                    >  Settings</Link>
                  </div>
                  <div
                    style={{ padding: "8px", cursor: "pointer" ,display: "flex",
                      // flexDirection:'column',
                      alignItems: "center",
                      gap:'20px',
                      justifyContent: "center",height:'70px',color: "#4D4D4D",fontSize:'16px',fontWeight:'400'}}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#f5f5f5")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = "")
                    }
                    onClick={handleLogout}
                  >
                   <img src={Logout}/>  Logout
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;