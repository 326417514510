/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import "../users.css";
import { WebApimanager } from "../../../WebApiManager";
import { RxCross2 } from "react-icons/rx";

const ProfileModal = ({ show, onHide, user, refetchUserData }) => {
  const departmentData = {
    Management: {
      roles: [
        "Company Managing Director",
        "Director",
        "Technical President",
        "Senior Vice President",
        "Vice President",
        "Assistant Vice President",
      ],

      workModels: [""],
    },
    "Design Consultant": {
      roles: [
        "Archiectural",
        "Structural",
        "MEP",
        "Facade",
        "Landscape and Lighting",
        "Vertical Transportation",
        "Traffic and Parking",
      ],
      workModels: [""],
    },

    Execution: {
      roles: [
        "Deputy Project Manager",
        "Assistant Project Manager",
        "Assistant Manager",
        "Sr.Project Engineer",
        "Project Engineer",
        "Jr.Project Engineer",
        "Senior Engineer",
        "Engineer",
        "Junior Engineer",
        "GTE",
      ],
      workModels: ["Structural", "Finishes"],
    },
    Planning: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: [""],
    },
    SiteManagement: {
      role: [
        "General Manager",
        "Assistant General Manager",
        "Senior Project Manager",
        "Project Manager",
      ],
      workModels: [""],
    },
    QualitySurveyorBilling: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: ["BBS", "Billing", "Contractor", "QS"],
    },
    MEP: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Supervisors",
      ],
      workModels: ["Electrical", "Plumbing", "Fire Fighting", "Ele & HVAC"],
    },
    EHS: {
      roles: [
        "Head",
        "Manager",
        "Assistant Manager",
        "Safety Incharge",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Steve Ward",
        "Male Nurse",
      ],
      workModels: [""],
    },
    CRMTECH: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: [""],
    },
    Store: {
      roles: [
        "Head",
        "Store Manager",
        "Assistant Store Manager",
        "Store Officer",
        "Store Incharge",
        "Sr.Store Keeper",
        "Store Keeper/ Executive",
        "Sr.Store Asst",
        "Store Asst",
        "Trainee Store",
      ],
      workModels: [""],
    },
    Survey: {
      roles: ["Sr.Engineer", "Engineer", "Jr.Engineer", "GTE", "Helper"],
    },
    Accounts: {
      roles: [
        "Head",
        "Manager",
        "Sr.Accountant",
        "Accountant",
        "Jr.Accountant",
      ],
      workModels: [""],
    },
    Admin: {
      roles: [
        "Head",
        "Project Admin",
        "Ass Admin",
        "Time Keeper",
        "Colony Admin",
      ],
      workModels: [""],
    },
    PNM: {
      roles: [
        "Head",
        "Project Admin",
        "Ass Admin",
        "Time Keeper",
        "Colony Admin",
      ],
      workModels: [""],
    },
  };

  const webApi = new WebApimanager();
  const [roles, setRoles] = useState([]);
  const [workModels, setWorkModels] = useState([]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    bloodGroup: "",
    emergencyContact: "",
    employeeId: "",
    site: "",
    role: "",
    department: "",
    workModel: "",
  });

  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [dataSites, setDataSites] = useState([]);

  useEffect(() => {
    webApi
      .get("api/sites/AllSites")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          const sitesList = response.data.sites.map((permittedSite) => ({
            id: permittedSite._id,
            name: permittedSite.siteName,
          }));
          setDataSites(sitesList);
          console.log("All sites data:", response.data.sites);
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const handleSiteSelection = (e, siteId) => {
    if (e.target.checked) {
      setSelectedSiteIds((prevSelected) => [...prevSelected, siteId]);
    } else {
      setSelectedSiteIds((prevSelected) =>
        prevSelected.filter((id) => id !== siteId)
      );
    }
  };

  useEffect(() => {
    if (!user) {
      console.log("Profile modal User DATA:", user);
      return;
    }

    if (!user.permittedSites || user.permittedSites.length === 0) {
      console.log("Permitted Sites are empty or undefined.");
    } else {
      user.permittedSites.forEach((site, index) => {
        console.log(`Permitted Site ${index + 1}:`, site);
        console.log(
          `Site Name: ${site.siteId?.siteName || "No Site Name Available"}`
        );
      });

      const siteName =
        user.permittedSites[0]?.siteName || "No Site Name Available";
      console.log("Site Name from user data:", siteName);

      setFormData({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        contactNumber: user.contactNumber || "",
        email: user.email || "",
        bloodGroup: user.bloodGroup || "",
        emergencyContact: user.emergencyContact || "",
        reportingEmpIdName: user.reportingEmpIdName || "",
        employeeId: user.empId || "",
        site: siteName,
        role: user.role || "",
        department: user.department || "",
        workModel: user.workModel || "",
      });

      setSelectedSiteIds(user.siteIds || []);
    }

    dataSites.forEach((site) => {
      console.log("Site Name from API:", site.name);
    });
  }, [user, dataSites]);

  const permittedSites = user?.permittedSites || [];

  useEffect(() => {
    console.log("user  formData", formData);
  }, [formData]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log(`Field ID: ${id}, Value: ${value}`);

    setFormData((prev) => ({ ...prev, [id]: value }));

    if (id === "department") {
      const selectedDepartment = departmentData[value];
      if (selectedDepartment) {
        setRoles(selectedDepartment.roles || []);
        setWorkModels(selectedDepartment.workModels || []);
      } else {
        setRoles([]);
        setWorkModels([]);
      }
    }
  };

  const [errors, setError] = useState({});

  const validationForm = () => {
    const errors = {};

    if (formData.contactNumber && formData.contactNumber.length !== 10) {
      errors.contactNumber = "Contact number must be 10 digits";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validationForm()) return;

    try {
      const response = await webApi.put(
        `api/users/patch/${user._id}`,
        formData
      );
      if (response.status === 200) {
        refetchUserData();
        onHide();
      } else {
        console.log("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleToggleDropdown = () => setDropdownOpen((prev) => !prev);

  // const handleSiteSelection = (siteId) => {
  //   let updatedSelection;
  //   if (selectedSiteIds.includes(siteId)) {
  //     updatedSelection = selectedSiteIds.filter((id) => id !== siteId);
  //   } else {
  //     updatedSelection = [...selectedSiteIds, siteId];
  //   }
  //   setSelectedSiteIds(updatedSelection);
  //   onSelectionChange(updatedSelection);
  // };

  // const filteredSites = dataSites.filter((site) =>
  //   site.siteName.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  useEffect(() => {
    console.log(dataSites, "siteData");
  });

  return (
    <>
      <div className={`modal ${show ? "d-block" : "d-none"}`} tabIndex="-1">
        <div
          className="modal-dialog"
          style={{ maxWidth: "70%", margin: "40px auto", padding: "15px 50px" }}
        >
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                position: "relative",
                height: "50px",
                backgroundColor: "#4D4D4D",
              }}
            >
              <p
                className="modal-title"
                style={{
                  textAlign: "center",
                  width: "100%",
                  margin: "0",
                  color: "var(--neturals-background-neturals-base-white, #FFF)",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
              >
                Update Profile
              </p>
              <button
                type="button"
                onClick={onHide}
                style={{
                  position: "absolute",
                  right: "45px",
                  top: "13px",
                  color: "white",
                }}
              >
                <RxCross2 size={25} />
              </button>
            </div>
            <div className="modal-body" style={{ backgroundColor: "white" }}>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="firstName" className="lableProgileModal">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="Enter first name"
                        readOnly
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="contactNumber"
                        className="lableProgileModal"
                      >
                        Contact Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        placeholder="Enter contact number"
                        style={{ padding: "10px" }}
                      />
                      {errors.contactNumber && (
                        <div className="text-danger">
                          {errors.contactNumber}
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="bloodGroup" className="lableProgileModal">
                        Blood Group
                      </label>
                      <select
                        id="bloodGroup"
                        className="form-control"
                        value={formData.bloodGroup}
                        onChange={handleChange}
                      >
                        <option value="">Select Blood Group</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="lastName" className="lableProgileModal">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Enter last name"
                        readOnly
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="lableProgileModal">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter email address"
                        style={{ padding: "10px", width: "100%" }}
                      />
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="emergencyContact"
                        className="lableProgileModal"
                      >
                        Emergency Contact Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="emergencyContact"
                        value={formData.emergencyContact}
                        onChange={handleChange}
                        placeholder="Enter emergency contact number"
                        style={{ padding: "10px" }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginLeft: "80%" }}>
                  <div className="mb-3">
                    <label
                      htmlFor="reportingEmpIdName"
                      className="lableProgileModal"
                    >
                      Reporting Employee ID / Name
                    </label>
                    <input
                      type="text"
                      id="reportingEmpIdName"
                      value={formData.reportingEmpIdName}
                      onChange={handleChange}
                      placeholder="Enter reportingEmpIdName"
                      className="form-control"
                      style={{ width: "100%", marginTop: "20px" }}
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-3">
                    <label htmlFor="employeeId" className="lableProgileModal">
                      Employee ID
                    </label>
                    <input
                      type="text"
                      id="employeeId"
                      value={formData.employeeId}
                      onChange={handleChange}
                      placeholder="Enter Employee ID"
                      className="form-control"
                      readOnly
                      style={{ width: "100%", marginTop: "20px" }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="dropdown-container">
                      <label className="labelProfileModal">Select Sites</label>
                      <div className="dropdown" onClick={handleToggleDropdown}>
                        <div className="dropdown-selected">
                          {selectedSiteIds.length > 0 ? (
                            selectedSiteIds.map((id) => (
                              <span key={id} className="selected-tag">
                                {dataSites.find((site) => site.id === id)?.name}
                                <button
                                  className="remove-tag"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSiteSelection(id);
                                  }}
                                >
                                  ×
                                </button>
                              </span>
                            ))
                          ) : (
                            <span>Select sites...</span>
                          )}
                          <span className="dropdown-icon">▼</span>
                        </div>
                        {dropdownOpen && (
                          <div className="dropdown-menu">
                            <input
                              type="text"
                              className="dropdown-search"
                              placeholder="Search sites..."
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <div className="dropdown-options">
                              {dataSites.map((site) => {
                                const isPermittedSite = permittedSites.some(
                                  (permittedSite) =>
                                    permittedSite.siteId === site.id
                                );

                                return (
                                  <div
                                    key={site.id}
                                    className={`dropdown-option ${
                                      isPermittedSite ? "permitted" : ""
                                    }`}
                                    onClick={() => handleSiteSelection(site.id)}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedSiteIds.includes(
                                        site.id
                                      )}
                                      readOnly
                                    />
                                    {site.siteName}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div></div>

                    <div className="mb-3">
                      <label htmlFor="role" className="lableProgileModal">
                        Role
                      </label>
                      <select
                        id="role"
                        className="form-control"
                        value={formData.role}
                        onChange={handleChange}
                      >
                        <option value="">Select Role</option>
                        {roles.map((role, index) => (
                          <option key={index} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="department" className="lableProgileModal">
                        Department
                      </label>
                      <select
                        id="department"
                        className="form-control"
                        value={formData.department}
                        onChange={handleChange}
                      >
                        <option value="">Select Department</option>
                        {Object.keys(departmentData).map(
                          (department, index) => (
                            <option key={index} value={department}>
                              {department}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="workModel" className="lableProgileModal">
                        Work Model
                      </label>
                      <select
                        id="workModel"
                        className="form-control"
                        value={formData.workModel}
                        onChange={handleChange}
                      >
                        <option value="">Select Work Model</option>
                        {workModels.map((workModel, index) => (
                          <option key={index} value={workModel}>
                            {workModel}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <button type="submit" className="profileModalSave">
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileModal;
