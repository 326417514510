import React from 'react'
import Styles from "./PasswordCreated.module.css"
import { Link } from 'react-router-dom'
import MobileLogo from "../Images/MobileLogo.png"
import LoginSuccessImg from "../Images/LoginSuccessImg.png"
import TickImg from "../Images/TickImg.png"
const PasswordCreated = () => {
  return (
    <div className={Styles.LoginContainer}>
        <div className={Styles.LeftContainer}>
         
          <form  className={Styles.FormContainer}>
          <div className={Styles.ImageContainer}>
                  <img className={Styles.Logo} src="logo/logo3.png" />
                </div>
            <div className={Styles.SuccessContainer}>
                <img src={TickImg} alt="" />
                <h5 className={Styles.LoginText}>Password Changed Successfully</h5>
                <p className={Styles.SuccessText} style={{fontSize:"14px"}}>Please login to your email account again</p>
            </div>
                
                <div className={Styles.SubmitBtnContainer}>
                <button type="submit" className={Styles.SubmitBtn}>
                  <Link to="/sign-in" style={{textDecoration:"none",color:"white"}}>Login Now</Link>
                </button>
                </div>
          </form>
        </div>
        <div className={Styles.RightContainer} >
        <img src={LoginSuccessImg} alt="" className={Styles.RightContainerImage} />
        </div>
        {/* <div className={Styles.MobileContainer}>
        <div className={Styles.MobileView}>
            <div className={Styles.MobileViewLogo}>
              <img src={MobileLogo} alt="" />
              <p className={Styles.HoldOn}>Hold On!</p>
            </div> 
        </div>
        <div className={Styles.MobileMsgContainer}>
              <p className={Styles.MobileMsg}>We are currently not supporting Mobile Devices please click the below button to Download mobile application</p>
              <div className={Styles.BtnContainer}>
        <button className={Styles.DownloadBtn}>
            <Link to="https://www.google.com/" style={{textDecoration:"none",color:"white"}}>Download</Link>
      </button>

        </div>
        </div>
        
        </div> */}
      </div>
  )
}

export default PasswordCreated