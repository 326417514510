import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import AddDrawingDialog from "./Components/AddDrawingDialog";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId, userInfo } from "../../../atoms";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { RxCross1 } from "react-icons/rx";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableWrapper from "../../../Utilities/TableWrapper";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import ReceivedTable from "./ReceivedTable";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import { processFile, updateRegister } from "../BackendUtils";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import { Tab1, Tabs1 } from "../../Tabs/Tabv2";
import { HiPlusCircle } from "react-icons/hi";
import { FaCircleMinus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import Gallery from "../../../Images/Gallery.svg";
import Eye from "./IconsSiteHead/Eye.svg";
import Download from "./IconsSiteHead/Download.svg";
import ShowFolder, { canShowFolder } from "../ShowFolder";
import { MdCancel } from "react-icons/md";
import ArrowToggle from "../ArrowToggle";
import { BiSortAlt2 } from "react-icons/bi";
import SortByPopup from "../SortByPopup";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
import grid from "../../../Images/grid.png"
import menu from "../../../Images/menu.png"
import FolderView from "./FolderView/FolderView";
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", 
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", 
          },
        },
      },
    },
  },
});

const ApprovedTable = () => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();

  const [currentUserInfo] = useRecoilState(userInfo);
  const [open, setOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [drawingList, setDrawingList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const userDetails = useRecoilState(userInfo);
  const companyID = userDetails[0]?.companyId;
  const [showAddFolder, setShowAddFolder] = useState(false)
  const [presentView, setPresentView ] = useState('table')
  const [showHardCopy, setShowHardCopy] = useState(false)
  const [consultantNotSubmitHardCopy, setConsultantNotSubmitHardCopy] = useState([])
  const [editData, setEditData] = useState({
    drawingId: "",
    drawingNumber: "",
    expectedDate: "",
    requestedDate: todayDate(),

    drawingFileName: null,
    revision: [],
    natureofrequestedInfo: "",
  });
  const [requestReason, setRequestReason] = useState([{ NOR: "", reason: "" }]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [site, setSite] = useRecoilState(siteId);
  const [selectedRevision, setSelectedRevision] = useState("");

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const [filters, setFilters] = useState({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    dueStatus:""
  });
  
  // sort by start
  const [sortField, setSortField] = useState(""); // Field to sort by
  const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
  const [activeSortPopup, setActiveSortPopup] = useState(null);

 
  console.log(currentUserInfo,"currentUserInfo")
  const handleSortClick = (field) => {
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    dueStatus:""
    })
    setSearchValue("");
    setActiveSortPopup((prev) => (prev === field ? null : field)); // Toggle the popup
    
  };

  const handleSortSelection = (order) => {
    if (activeSortPopup) {
      setSortField(activeSortPopup);
      setSortOrder(order);
      setActiveSortPopup(null); // Close the popup after selection
    }
  };
  // sort by end

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    fetchData();
  };

  const handleRevisionSelection = (event) => {
    setSelectedRevision(event.target.value);
  };

  const handleAddReason = () => {
    setRequestReason([...requestReason, { NOR: "", reason: "" }]);
  };

  const handleRemoveReason = (indexToRemove) => {
    const updatedReasons = requestReason.filter(
      (_, index) => index !== indexToRemove
    );
    setRequestReason(updatedReasons);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditData({
      drawingId: "",
      drawingNumber: "",
      expectedDate: "",
      remarks: "",
      drawingFileName: null,
      revision: [],
      natureofrequestedInfo: [],
    });
  };
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    console.log(drawingItem, "see single drawing data");
    if (drawingItem.acceptedArchitectRevisions?.length > 0) {
      setSelectedTab(drawingItem.acceptedArchitectRevisions?.length - 1);
    }
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setApiProcessing({ loader: false });
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  useEffect(() => {
    setReloadData(true);
  }, [drawingList]);

  const selectFolder = useState("")
  const  setTypeSelected = useState("");;

  const fetchData = async () => {
    try {
      let response = await webApi.get(
        `api/architect/upload/${site}?filterType=upload`
      );
      setDrawingList(response.data.data);
      console.log(drawingList, "responsedata of architect upload");
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [site]);

  

  // const filteredDrawings = useMemo(() => {
  //   if (!searchValue) return drawingList;

  //   return drawingList.filter((drawing) => {
  //     const matchesSearch = [
  //       drawing.drawingNo,
  //       drawing.drawingTitle,
  //       drawing.designDrawingConsultant?.role,
  //       drawing.category?.category,
  //     ].some((field) => field?.toLowerCase().includes(searchValue));

  //     const matchesCategory = selectedCategory
  //       ? drawing.category?.category === selectedCategory
  //       : true;

  //     const matchesFolder = selectFolder
  //       ? drawing.folderName?.folderName === selectFolder.toLowerCase()
  //       : true;

  //     return matchesSearch && matchesCategory && matchesFolder;
  //   });
  // }, [searchValue, selectedCategory, selectFolder, drawingList]);

  // useEffect(() => {
  //   console.log("Current Search Value:", searchValue);
  //   console.log("Filtered Drawings:", filteredDrawings);
  // }, [searchValue, filteredDrawings]);



  const handleTypeSelect = (type, selectedType) => {
    setDrawingList((data) =>
      data.filter((drawingItem) => {
        const category = drawingItem?.category?.category?.toLowerCase() || "";
        const folderName =
          drawingItem?.folderId?.folderName?.toLowerCase() || "";

        let matchesCategory = true;
        if (type === "category") {
          matchesCategory = selectedType
            ? category === selectedType.toLowerCase()
            : true;
        }

        let matchesFolder = true;
        if (type === "folder") {
          matchesFolder = selectedType
            ? folderName === selectedType.toLowerCase()
            : true;
        }

        return matchesCategory && matchesFolder;
      })
    );
  };

  const getDueOverdueStatus = (scheduledDate, submissionDate) => {
    const currentDate = new Date();
    const dueDate = new Date(scheduledDate);
    const submission = submissionDate ? new Date(submissionDate) : null;
  
    // Calculate Due or Overdue status
    const dueDays = calculateDueDays(scheduledDate, submissionDate); // Your existing logic for due days
  
    let status = "OnTime"; // Default status
    let message = "OnTime"; // Default message
    let color = "green"; // Default color
  
    if (dueDays === "-") {
      status = "OnTime"; // No due date
    } else if (dueDays > 0) {
      status = "Due"; // Scheduled but not yet submitted (in the future)
      message = `Due in ${dueDays} day(s)`;
      color = "blue";
    } else if (dueDays < 0) {
      status = "Overdue"; // Late submission
      message = `Overdue by ${Math.abs(dueDays)} day(s)`;
      color = "red";
    }
  }

  

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setEditData({
        ...editData,
        [name]: files[0], 
      });
    } else {
      setEditData({
        ...editData,
        [name]: value,
      });
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    let formErrors = {};
    if (!editData.drawingNumber)
      formErrors.drawingNumber = "Drawing Number is required";
    if (!editData.expectedDate)
      formErrors.expectedDate = "Expected Date is required";

    if (!editData.drawingFileName) {
      formErrors.drawingFileName = "Drawing File is required";
    }
    if (!editData.natureofrequestedInfo)
      formErrors.natureofrequestedInfo = "this field is requires";
    requestReason.forEach((reason, index) => {
      if (!reason.NOR) {
        formErrors[`NOR_${index}`] = "Nature of Request is required.";
      }
      if (!reason.reason) {
        formErrors[`reason_${index}`] = "Reason is required.";
      }
    });

    setError(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });
    try {
      const editFormData = {
        drawingId: editData.drawingId,
        drawingNo: editData.drawingNumber,
        siteId: site,
        requestedDate: todayDate(),
        expectedDate: editData.expectedDate,
        remarks: requestReason,
        revision: selectedRevision,
        natureOfRequestedInformation: editData.natureofrequestedInfo,
      };

      console.log("Submitting edit data:", editFormData);

      const response = await webApi.post(
        "api/architectureToRoRequested/postRequest",
        editFormData
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.status === "error") {
          setErrorMessage(responseData.message);
          console.log("Response data:", responseData.message);
          setApiProcessing({ loader: false, message: "" });
          return; 
        }

        const id = response.data.data._id;

        if (editData.drawingFileName) {
          const drawingFileName = new FormData();
          drawingFileName.append("drawingFileName", editData.drawingFileName);
          const uploadResponse = await webApi.imagePut(
            `api/architectureToRoRequested/${id}`,
            drawingFileName
          );
          console.log("Upload response:", uploadResponse);
        }
        setApiProcessing({ loader: false, message: "" });
        handleEditClose();
        fetchData();
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
          console.log("Response data:", response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };

  const handleDownload = async (id, revision) => {
    console.log(id, "id of drawing");
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/revisions/${revision}`,
        {
          responseType: "arraybuffer",
        }
      );

      processFile(response, "download", navigate); 
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision,data) => {
    try {
      setApiProcessing({ loader: true, message: "Viewing" });
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/revisions/${revision}`,
        {
          responseType: "arraybuffer",
        }
      );
      console.log("Image fot APPROVED", response);

      processFile(response, "view", navigate,revision,data,"Approval"); 
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handlePDFDownload = async (filename) => {
    console.log(filename, "fiename");
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      const response = await webApi.getImage(
        `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
        {
          responseType: "arraybuffer", 
        }
      );

      processFile(response, "download", navigate);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewPDFFile = async (filename) => {
    console.log(filename, "getting inside");
    try {
      setApiProcessing({ loader: true, message: "Viewing" });
      const response = await webApi.getImage(
        `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
        {
          responseType: "arraybuffer",
        }
      );

      processFile(response, "view", navigate); 
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const listItems = [
    { id: "A - Missing Information", name: "A - Missing Information" },
    { id: "B - Clarification", name: "B - Clarification" },
    { id: "C - Additional Information", name: "C - Additional Information" },
    { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
  ];

  const getNameFromId = (id) => {
    const item = listItems.find((item) => item.id === id);
    return item ? item.name : "";
  };

  const [currentView, setCurrentView] = useState("upload");

  const handleTabViewChange = (event, newValue) => {
    console.log(newValue);
    setCurrentView(newValue);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");

  const handleDropdownClick = (filter) => {
    setSelectedFilter(filter);
    setShowDropdown(false);
  };
  const placeholderText = selectedFilter
    ? `Search by ${selectedFilter}`
    : "Search Drawing Lists";

  const calculateDueDays = (
    acceptedROSubmissionDate,
    softCopySubmittedDate = null
  ) => {
    if (!acceptedROSubmissionDate) return "-";

    const dateToCompare = softCopySubmittedDate
      ? new Date(softCopySubmittedDate)
      : new Date();
    const acceptedDate = new Date(acceptedROSubmissionDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  };
  useEffect(() => {
    console.log(editData, "editData");
    console.log(requestReason, "requestReason");
  }, [editData, requestReason]);

  const handleReasonChange = (e, index) => {
    console.log("jksdnfks");
    const { name, value } = e.target;
    const updatedReasons = [...requestReason];

    updatedReasons[index] = {
      ...updatedReasons[index],
      [name]: value,
    };

    setRequestReason(updatedReasons);
  };
  

  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const dropdownRef = useRef(null);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isCategoriesDropdownOpen, setCategoriesDropdownOpen] = useState(false);
  const [isFoldersDropdownOpen, setFoldersDropdownOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectedConsultant, setSelectedConsultant] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
    
  };

  const handleType = (type) => {
    if (type === "all") {
      setSelectedCategory("");

      setFilterDropdownOpen(false);
      setCategoriesDropdownOpen(false);
      setFoldersDropdownOpen(false);
    } else {
      setSelectedCategory(type);
      setFilterDropdownOpen(false);
      setFoldersDropdownOpen(false);
    }
  };

  const uniqueFolders = useMemo(() => {
    const folder = drawingList.map((item) => item.folderId?.folderName);
    return [...new Set(folder)];
  }, [drawingList]);

  const uniqueCategories = useMemo(() => {
    const categories = drawingList.map((item) => item.category?.category);
    return [...new Set(categories)];
  }, [drawingList]);

  const uniqueConsultant = useMemo(() => {
    const consultant = drawingList.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [drawingList]);

  console.log(uniqueCategories, uniqueFolders, "SITE Head Upload categories");

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [filteredGoData, setFilteredGoData] = useState(null); 

  const [showFilteredData, setShowFilteredData] = useState(false); 

  const handleConsultantSelect = (consultant) => {
    setSelectedConsultant(consultant)
    setShowFilteredData(false);
      
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(
      (prevSelected) =>
        prevSelected.includes(category)
          ? prevSelected.filter((c) => c !== category) 
          : [...prevSelected, category] 
    );
    setShowFilteredData(false);
  };

  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
    setShowFilteredData(false);
  };

  const handleGoClick = () => {
    console.log("Selected Consultants:", selectedConsultant);
    console.log("Selected Categories:", selectedCategory);
    console.log("Selected Folders:", selectedFolder);
    if(selectedConsultant || selectedCategory.length || selectedFolder){
      setShowFilteredData(true);
      toggleFilterDropdown();
    }
  };

  const filteredDataAndUploadData = useMemo(() => {
    if (!showFilteredData) {
    return drawingList;
  }
    return drawingList.filter((item) =>{ 
      const matchesConsultant = selectedConsultant === "" ||  item?.designDrawingConsultant?.role === selectedConsultant;

    const matchesCategory = selectedCategory.length === 0 ||  selectedCategory.includes(item?.category?.category);

    const matchesFolder = 
      !selectedFolder || item.folderId?.folderName === selectedFolder;

    return matchesConsultant && matchesCategory && matchesFolder; 
  });
  }, [showFilteredData, selectedConsultant, selectedCategory, selectedFolder, drawingList]);

  const handleClearAll = () =>{
    setSelectedCategory([])
    setSelectedFolder("")
    setSelectedConsultant("")
  }
  const handleFilterCancel = () =>{
    toggleFilterDropdown()
    setShowFilteredData(false)

  } 
  const handleRemove = (type, value) => {
    setShowFilteredData(false)

    if (type === "consultant") {
      setSelectedConsultant("");
    } else if (type === "category") {
      setSelectedCategory(selectedCategory.filter((cat) => cat !== value));
    } else if (type === "folder") {
      setSelectedFolder("");
    }
  };
  

  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    dueStatus:""
    })
    setSortField("");
    setSortOrder("");
    setActiveSortPopup(null);
  }, []);
  
  const handleFilterChange = (column, value) => {
    setSearchValue("")
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
    setFilters((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = ""; 
          return acc;
      }, {}),
      [column]: value, 
  })); 
  };

   const fetchNoOfDays = async() =>{
     try {
       const response = await webApi.get("api/rfiTimeStamp/byCompanyId")
       console.log(response.data.data.customizedView ,"response in the rfi timeStamp")
        if (response.status === 200) {
         const recievedHardCopies = response?.data?.data?.areYouReceivingHardCopiesFromAllConsultants       
         setShowHardCopy(recievedHardCopies)
         if(!recievedHardCopies){
          setConsultantNotSubmitHardCopy(response?.data?.data?.whichConsultantsHaveNotSubmittedHardCopies)
         }
         console.log(recievedHardCopies,"recievedHardCopies")
        } 
     } catch (error) {
       console.log(error.message)
     }
   }
   
   useEffect(()=>{
     fetchNoOfDays()
   },[])

  const updatedRows = drawingList.map((row,index) => ({
    ...row,
    consultant: row?.designDrawingConsultant?.role || "-",
    drawingCategory: row?.category?.category || "-",
    drawingTitle: row.drawingTitle || "-",
    scheduledDateFormatted: formatDate(row?.acceptedROSubmissionDate || ""),
    submissionDateFormatted: formatDate(
      row?.acceptedArchitectRevisions?.slice(-1)[0]?.softCopySubmittedDate || ""
    ),
    revision: row?.acceptedArchitectRevisions?.slice(-1)[0]?.revision || "-",
    dueStatus: (() => {
      const lastRevision = row?.acceptedArchitectRevisions?.slice(-1)[0] || {};
      const submissionDate = lastRevision?.softCopySubmittedDate;
      const dueDays = calculateDueDays(row.acceptedROSubmissionDate, submissionDate);
      return dueDays >= 0
        ? { text: `${dueDays} days due`, color: "green" }
        : { text: `${Math.abs(dueDays)} days overdue`, color: "red" };
    })(),
    serialNo: index + 1
  }));
  

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = useMemo(
    () => [
      {
        field: "serialNo",
        headerName: "S.No",
        flex: 0.5,
        filterable: false,
        sortable: false,
      },
      {
        field: "drawingNo",
        headerName: "Drawing No",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "consultant",
        headerName: "Consultant",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "drawingCategory",
        headerName: "Drawing Category",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "drawingTitle",
        headerName: "Drawing Title",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "scheduledDateFormatted",
        headerName: "Scheduled Date",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "submissionDateFormatted",
        headerName: "Submission Date",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "dueStatus",
        headerName: "Due/Overdue",
        flex: 1,
        filterable: true,
        sortable: true,
        renderCell: (params) => (
          <span style={{ color: params.value.color }}>{params.value.text}</span>
        ),
      },
      {
        field: "revision",
        headerName: "Revision",
        flex: 0.5,
        filterable: true,
        sortable: true,
      },
      {
        field: "action",
        headerName: "Action",
        flex: 1,
        filterable: false,
        sortable: false,
        renderCell: (params) => (
          <div style={{ display: "flex", gap: "10px", marginTop: "24px" }}>
            <img
              src={Eye}
              alt="view"
              style={{ cursor: "pointer" }}
              onClick={() => handleViewOpen(params.row)}
            />
            <img
              src={Download}
              alt="download"
              style={{ cursor: "pointer" }}
              onClick={() => {
                const latestRevision = params.row?.acceptedArchitectRevisions?.slice(-1)[0]?.revision || null;
                handleDownload(params.row.drawingId, latestRevision);
              }}
            />
          </div>
        ),
      },
    ],
    [drawingList]
  );
  
  

  
  useEffect(()=>{console.log(filters,"filters")},[filters])

  return (
    <>
      <TableWrapper
        children={
          <>
            {presentView === "table" ?<>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <Tabs value={currentView} onChange={handleTabViewChange}>
                  <Tab label="Soft Copy" value="upload" />
                  {(showHardCopy || !(consultantNotSubmitHardCopy.includes(currentUserInfo?._id))) && <Tab label="Hard Copy" value="received" />}
                </Tabs>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {currentView === "upload" && (
                  <div
                    style={{
                      position: "relative",
                      width: "250px",
                      height: "50px",
                    }}
                  >
                    <input
                      placeholder={placeholderText}
                      value={searchValue}
                      // onChange={handleSearchChange}
                      onChange={(e)=>setSearchValue(e.target.value)}
                      style={{
                        paddingLeft: "20px",
                        border: "1px solid var(--primary-color)",
                        width: "250px",
                        height: "40px",
                      }}
                    />
                    <Icon.Search
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "40%",
                        transform: "translateY(-50%)",
                        color: "#ACACAC",
                      }}
                    />
                    {/* <div ref={dropdownRef} style={{ position: "absolute",top:"0px",right:"-54px"}}>
                      <div onClick={toggleFilterDropdown}>
                        <FaFilter
                          size={40}
                          style={{
                            border: "1px solid var(--primary-color)",
                            padding: "5px",
                            marginRight: "-10px",
                          }}
                        />
                      </div>
                      {isFilterDropdownOpen && (
                        <div
                          style={{
                            position: "absolute",
                            top:"42px",
                            right:"0px",
                            background: "white",
                            borderRadius: "4px",
                            zIndex: 1000,
                            textAlign: "left",
                            marginTop: "20px",
                            marginLeft: "-558px",
                            padding: "15px",
                            width: "500px",
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              justifyContent: selectedConsultant || selectedCategory.length || selectedFolder?"space-between":"flex-end",
                              alignItems: "center",
                              marginBottom: "10px",
                              marginTop:"10px"
                            }}
                          >
                            
                            <MdCancel style={{position:"absolute",fontSize:"23px" ,right:"-10px",top:"-24px",cursor:"pointer"}}onClick={handleFilterCancel} />
                            {(selectedConsultant || selectedCategory.length || selectedFolder) && (
                                <button
                                  style={{
                                    padding: "6px 10px",
                                    backgroundColor: "crimson",
                                    color: "white",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClearAll}
                                >
                                  Clear All
                                </button>
                              )}
                              <button
                                style={{
                                  backgroundColor: "var(--primary-color)",
                                  color: "white",
                                  fontSize: "16px",
                                  padding: "5px 15px",
                                  border: "none",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                  marginBottom: "5px",
                                }}
                                onClick={handleGoClick}
                              >
                                Go
                              </button>
                              
                            
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "10px",
                              marginBottom: "15px",
                            }}
                          >
                            {selectedConsultant && (
                              <button
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "#ffd700",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemove("consultant", selectedConsultant)}
                              >
                                {selectedConsultant} <MdCancel style={{ marginLeft: "5px" }} />
                              </button>
                            )}

                            {selectedCategory.map((category, index) => (
                              <button
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "#ffd700",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemove("category", category)}
                              >
                                {category} <MdCancel style={{ marginLeft: "5px" }} />
                              </button>
                            ))}

                            {selectedFolder && (
                              <button
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "#ffd700",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  padding: "5px 10px",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemove("folder", selectedFolder)}
                              >
                                {selectedFolder} <MdCancel style={{ marginLeft: "5px" }} />
                              </button>
                            )}
                          </div>

                          <div style={{ flex: 3 }}>
                            <span style={{ cursor: "pointer", position: "relative" }}>
                              <p style={{ color: "black", fontSize: "20px" }}>Consultant</p>
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "5px",
                                  backgroundColor: "#f5f5f5",
                                  borderRadius: "8px",
                                }}
                              >
                                {uniqueConsultant.map((consultant, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      flex: "1 1 calc(25.33% - 15px)",
                                      backgroundColor:
                                        selectedConsultant === consultant ? "#ffd700" : "#e0f7fa",
                                      fontWeight:
                                        selectedConsultant === consultant ? "700" : "500",
                                      borderRadius: "4px",
                                      textAlign: "center",
                                      fontSize: "14px",
                                      padding: "5px",
                                      color: selectedConsultant === consultant ? "white" : "#333",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                    onClick={() => handleConsultantSelect(consultant)}
                                  >
                                    {consultant}
                                  </div>
                                ))}
                              </div>
                            </span>

                                    <span style={{ cursor: "pointer", position: "relative" }}>
                                      <p style={{ color: "black", fontSize: "20px" }}>Categories</p>

                                      <div
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: "5px",
                                          backgroundColor: "#f5f5f5",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {uniqueCategories.map((category, index) => (
                                          <div
                                            key={index}
                                            style={{
                                              flex: "1 1 calc(25.33% - 15px)",
                                              backgroundColor:  selectedCategory.includes(category)?"#ffd700":"#e0f7fa",
                                              fontWeight: selectedCategory.includes(category)?"700":"500",
                                              borderRadius: "4px",
                                              textAlign: "center",
                                              fontSize: "14px",
                                              padding: "5px",
                                              color: selectedCategory.includes(category)?"white":"#333",
                                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            }}
                                            onClick={() => handleCategorySelect(category)}
                                          >
                                            {category}
                                          </div>
                                        ))}
                                      </div>
                                    </span>

                                    {showAddFolder?<span style={{ cursor: "pointer", position: "relative" }}>
                                      <p
                                        style={{
                                          color: "black",
                                          fontSize: "20px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        Folders
                                      </p>

                                      <div
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: "5px",
                                          backgroundColor: "#f5f5f5",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {uniqueFolders.map((folder, index) => (
                                          <div
                                            key={index}
                                            style={{
                                              flex: "1 1 calc(25.33% - 15px)",
                                              backgroundColor: "#e0f7fa",
                                              fontWeight: selectedFolder === folder ? "700" : "500",
                                              borderRadius: "4px",
                                              textAlign: "center",
                                              fontSize: "14px",
                                              padding: "5px",
                                              color: "#333",
                                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            }}
                                            onClick={() => handleFolderSelect(folder)}
                                          >
                                            {folder}
                                          </div>
                                        ))}
                                      </div>
                                    </span>:""}
                                    <>
                                      
                                    </>
                                  </div>
                                </div>
                              )}
                    </div> */}
                
                  </div>
                )}

                {currentView === "upload" ? (
                  <>
                  
                  <Button
                    style={{
                      height: "40px",
                      marginRight: "0px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "var(--primary-color)",
                      border: "1px solid var(--primary-color)",
                      borderRadius: "0px",
                      marginTop: "-10px",
                    }}
                    onClick={handleClickOpen}
                  >
                    <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
                    Add New
                  </Button>
                  </>
                ) : (
                 ""
                )
                }
                <div style={{display:"flex",alignItems:"center",height:"40px",border:"1px solid #e47331",marginTop:"-10px",borderRadius:"5px"}}>
                  <button style={{padding:"4px",height:"40px",borderRight:"1px solid #4D4D4D33"}} onClick={()=>setPresentView("table")}>
                    <img src={menu} style={{width:"20px"}}  />
                  </button>
                  <button style={{padding:"4px",height:"40px"}} onClick={()=>setPresentView("folder")}>
                    <img src={grid} style={{width:"20px"}} />
                  </button>

                </div>
              </div>
            </div>
            <div>
              {showDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "350px",
                    right: "50px", // Align with the filter icon
                    border: "1px solid var(--primary-color)",
                    borderRadius: "6px",
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    padding: "5px",
                    zIndex: 1000,
                    width: "150px", // Adjust width for compact design
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Drawing No")}
                    >
                      DRWG.NO
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick(" Consultant")}
                    >
                      Consultant
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Category")}
                    >
                      Drawing Category
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Title")}
                    >
                      Drawing Title
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick(" R0 Submission Date")}
                    >
                      Accepted R0 Submission Date
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() =>
                        handleDropdownClick("Final Submission Date")
                      }
                    >
                      Final Submission Date
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Due/Overdue")}
                    >
                      Due/Overdue
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Revision")}
                    >
                      Revision
                    </li>
                  </ul>
                </div>
              )}

              {currentView === "upload" ? (
                <div style={{ marginTop: "10px" }}>
                  <Box
                    className="dataGridContainer"
                  >
                    <ThemeProvider theme={theme}>
                    <DataGrid
                      columns={columns}
                      rows={filteredRows}
                      getRowId={(row) => row.drawingId}
                      rowHeight={60}
                      disableColumnMenu={false} 
                      pagination={false}
                      hideFooter={true}
                      getRowSpacing={params=>({
                        top:params.isFirstVisible ? 0 : 5,
                        bottom:params.isLastVisible ? 0 : 5
                      })}
                      
                      getRowClassName={(params) =>
                        "customHeader" 
                      }
                      
                    />
                    </ThemeProvider>
                  </Box>
                </div>
              ) : (
                <div style={{ marginTop: "10px" }}>
                  <ReceivedTable
                    selectedFilter={selectedFilter}
                    searchValue={searchValue}
                    searchActive={searchActive}
                    setSearchValue={setSearchValue}
                    setSearchActive={setSearchActive}
                    reloadData={reloadData}
                    setReloadData={setReloadData}
                    filterCondition="approved"
                  />
                </div>
              )}
            </div>
            </>:
            <FolderView setPresentView ={setPresentView} site={site}/>
           }
          </>
        }
      />
      <AddDrawingDialog
        handleDialogClose={handleDialogClose}
        open={open}
        site={site}
      />
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            position:"relative",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{width:"100%", marginTop:"15px", textAlign: "center", color: "white" }}
          >
            RFI
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleEditClose}
          />
        </div>
        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <form onSubmit={handleEditSubmit}>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10  text-start py-2"
                  >
                    Drawing Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="drawingNumber"
                    placeholder="Enter your Drawing Number"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.drawingNumber}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {error.drawingNumber && (
                    <div className="text-danger">{error.drawingNumber}</div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="requestedDate"
                    className="fs-10  text-start py-2"
                  >
                    Requested Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="expectedDate"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.requestedDate}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {error.requestedDate && (
                    <div className="text-danger">{error.requestedDate}</div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="expectedDate"
                    className="fs-10  text-start py-2"
                  >
                    Expected Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    name="expectedDate"
                    className="form-control fs-12"
                    style={{ width: "100%", height: "45px" }}
                    value={editData.expectedDate}
                    onChange={handleInputChange}
                    min={tomorrowDateString}
                  />
                  {error.expectedDate && (
                    <div className="text-danger">{error.expectedDate}</div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <label htmlFor="revision" className="fs-10  text-start py-2">
                    Revisions
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </label>
                  <SearchDropdownIdVal
                    placeholder={"Select Revision"}
                    backgroundColor={"var(--table-evenrow-color)"}
                    // Check if editData.revision is an array and get the latest revision
                    options={
                      Array.isArray(editData.revision) &&
                      editData.revision.length > 0
                        ? [
                            {
                              name: editData.revision.slice(-1)[0], // Get the latest revision
                              value: editData.revision.slice(-1)[0], // Use the latest revision for value
                            },
                          ]
                        : []
                    }
                    onOptionSelect={(option) =>
                      handleRevisionSelection({
                        target: {
                          name: "selectedRevision",
                          value: option.value,
                        },
                      })
                    }
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />

                  {error.revision && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {error.revision}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <MultiSelectSearch
                    mandatory={true}
                    heading={"Nature of Requested Information"}
                    listItems={listItems}
                    selectedIds={editData.natureofrequestedInfo || []} // Ensure this is an array
                    setSelectedIds={(ids) =>
                      setEditData({ ...editData, natureofrequestedInfo: ids })
                    }
                    handleSelectedPropertyOption={(data) => {
                      if (editData.natureofrequestedInfo.includes(data.id)) {
                        setEditData({
                          ...editData,
                          natureofrequestedInfo:
                            editData.natureofrequestedInfo.filter(
                              (id) => id !== data.id
                            ),
                        });
                      } else {
                        setEditData({
                          ...editData,
                          natureofrequestedInfo: [
                            ...editData.natureofrequestedInfo,
                            data.id,
                          ],
                        });
                      }
                    }}
                    clearAll={() =>
                      setEditData({ ...editData, natureofrequestedInfo: [] })
                    }
                    addAll={() =>
                      setEditData({
                        ...editData,
                        natureofrequestedInfo: listItems.map((item) => item.id),
                      })
                    }
                  />

                  {/* Display selected fields */}
                  <div style={{ marginTop: "20px" }}>
                    <p className="fs-10  text-start py-2">Selected Fields:</p>
                    <ul>
                      {editData.natureofrequestedInfo.length > 0 ? (
                        editData.natureofrequestedInfo.map((id) => (
                          <li key={id}>{getNameFromId(id)}</li>
                        ))
                      ) : (
                        <li>No fields selected</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="remarks"
                    className="fs-10  text-start py-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Request Reason *
                    <span style={{ display: "flex", alignItems: "center" }}>
                      &nbsp; &nbsp; &nbsp;{" "}
                      <HiPlusCircle
                        style={{
                          marginLeft: "20px",
                          color: "#e47331",
                          fontSize: "24px",
                        }}
                        onClick={handleAddReason}
                      />
                    </span>
                  </label>
                  {requestReason.map((reqReason, reqIndex) => (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        marginTop: "30px",
                      }}
                    >
                      <select
                        style={{ width: "15%", marginRight: "20px" }}
                        name="NOR"
                        value={reqReason.NOR}
                        onChange={(e) => handleReasonChange(e, reqIndex)}
                      >
                        <option vlaue="">Select</option>
                        {editData.natureofrequestedInfo.length &&
                          editData.natureofrequestedInfo.map(
                            (requestInfo, index) => (
                              <option key={index} value={requestInfo}>
                                {requestInfo}{" "}
                              </option>
                            )
                          )}
                      </select>

                      <input
                        type="text"
                        style={{ width: "82%" }}
                        name="reason"
                        value={reqReason.reason}
                        onChange={(e) => handleReasonChange(e, reqIndex)}
                      />
                      {reqIndex > 0 ? (
                        <FaCircleMinus
                          style={{
                            fontSize: "24px",
                            position: "absolute",
                            right: "-21px",
                          }}
                          onClick={() => handleRemoveReason(reqIndex)}
                        />
                      ) : (
                        ""
                      )}
                      {error[`NOR_${reqIndex}`] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "50px",
                          }}
                        >
                          {error[`NOR_${reqIndex}`]}
                        </p>
                      ) : error[`reason_${reqIndex}`] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "50px",
                            right: "50px",
                          }}
                        >
                          {error[`reason_${reqIndex}`]}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <label
                  htmlFor="drawingFileName"
                  className="fs-10  text-start py-2"
                >
                  Upload Your File <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  accept=".pdf, .jpg, .jpeg, .png, .dwg"
                  id="drawingFileName"
                  name="drawingFileName"
                  className="form-control fs-12"
                  style={{ width: "100%", height: "43px" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.name.endsWith(".dwg")) {
                      handleInputChange(e);
                    } else {
                      alert("Please select a .dwg file.");
                      e.target.value = null; // Reset the file input if the file is not valid
                    }
                  }}
                />

                {error.drawingFileName && (
                  <div className="text-danger">{error.drawingFileName}</div>
                )}
              </div>
              {errorMessage && (
                <div style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  type="submit"
                  style={{
                    background: "blue",
                    color: "white",
                    width: "100px",
                    height: "35px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            fontFamily: "Poppins",
            borderRadius: "10px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <div style={{width:"100%"}}>
            <p style={{ width:"100%", color: "white",marginTop:"15px",textAlign:"center"}}>View</p>
          </div>
          <div>
            <RxCross1
              size={20}
              style={{
                cursor: "pointer",
                color: "white",
                position:"absolute",
                right:"10px",
                top:"15px"
              }}
              onClick={handleViewClose}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              border: "1px solid var(--primary-color)",
            }}
          >
            {singleDrawingData?.acceptedArchitectRevisions?.length > 0 ? (
              <Tabs1 value={selectedTab} onChange={handleTabChange}>
                {singleDrawingData.acceptedArchitectRevisions.map(
                  (revisionItem, index) => (
                    <Tab1
                      key={index}
                      value={index}
                      label={`${revisionItem.revision}`}
                      selected={selectedTab === index}
                      style={{
                        backgroundColor:
                          singleDrawingData.acceptedArchitectRevisions
                            .length === 1 || selectedTab === index
                            ? "var(--primary-color)"
                            : "transparent",
                        color:
                          singleDrawingData.acceptedArchitectRevisions
                            .length === 1 || selectedTab === index
                            ? "white"
                            : "var(--primary-color)",
                        width: `${
                          100 /
                          singleDrawingData.acceptedArchitectRevisions.length
                        }%`,
                        height: "30px",
                        minWidth: "20px",
                        cursor: "pointer",
                        borderRight:
                          index <
                          singleDrawingData.acceptedArchitectRevisions.length -
                            1
                            ? "1px solid var(--primary-color)"
                            : "none",
                      }}
                    />
                  )
                )}
              </Tabs1>
            ) : (
              <div>No Revisions</div>
            )}
          </div>
        </div>
        {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
                selectedTab <=
                  singleDrawingData.acceptedArchitectRevisions.length - 1 && (
                  <>
                    {(() => {
                      const selectedRevision =
                        singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ];
                      const hardCopyRevision =
                        singleDrawingData?.acceptedROHardCopyRevisions[
                          singleDrawingData?.acceptedROHardCopyRevisions
                            ?.length - 1
                        ]?.revision;

                      console.log(hardCopyRevision, "hardCopyRevisionsssss");

                      if (
                        singleDrawingData?.acceptedArchitectRevisions[
                          singleDrawingData?.acceptedArchitectRevisions
                            ?.length - 1
                        ]?.revision === hardCopyRevision
                      ) {
                        if (selectedRevision?.rfiStatus === "Not Raised") {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                                color: "green",
                              }}
                            >
                              Good For Construction
                            </div>
                          );
                        } else {
                          return (
                            <p
                              style={{
                                color: "#FFB200",
                                textAlign: "center",
                                fontSize: "16px",
                              }}
                            >
                              RFI has been raised
                            </p>
                          );
                        }
                      } else if (selectedRevision?.rfiStatus === "Raised") {
                        return (
                          <p
                            style={{
                              color: "#FFB200",
                              textAlign: "center",
                              fontSize: "16px",
                            }}
                          >
                            RFI has been raised, Hardcopy file not received
                          </p>
                        );
                      } else if (
                        hardCopyRevision === selectedRevision?.revision
                      ) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              color: "red",
                            }}
                          >
                            Superseeded
                          </div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              color: "red",
                            }}
                          >
                            No hardcopy file received
                          </div>
                        );
                      }
                    })()}
                  </>
                )}
            </div>
          </>
        )}
        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "70vh",
            overflowY: "auto",
          }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "100px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.designDrawingConsultant?.role}{" "}
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>
              <>
                {showAddFolder &&(
                  <>
                    <div>Folder</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData?.folderId?.folderName || "NA"}
                    </div>
                  </>
                )}
              </>

              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>

              <div>Scheduled submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedROSubmissionDate
                  ? new Date(singleDrawingData.acceptedROSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Actual Final Submission Date</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ].softCopySubmittedDate
                          ? new Date(
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].softCopySubmittedDate
                            ).toISOString()
                            .split("T")[0]
                          : "No Submitted Date"}
                      </div>
                    </>
                  )}
                </>
              )}

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ].revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}

              <div>Due / Overdue</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                <span
                  style={{
                    color:
                      singleDrawingData &&
                      singleDrawingData.acceptedArchitectRevisions &&
                      singleDrawingData.acceptedArchitectRevisions.length > 0
                        ? calculateDueDays(
                            singleDrawingData.acceptedROSubmissionDate,
                            singleDrawingData.acceptedArchitectRevisions[0]
                              ?.softCopySubmittedDate
                          ) >= 0
                          ? "green"
                          : "red"
                        : "black",
                  }}
                >
                  {singleDrawingData &&
                  singleDrawingData.acceptedArchitectRevisions &&
                  singleDrawingData.acceptedArchitectRevisions.length > 0
                    ? calculateDueDays(
                        singleDrawingData.acceptedROSubmissionDate,
                        singleDrawingData.acceptedArchitectRevisions[0]
                          ?.softCopySubmittedDate
                      ) === "-"
                      ? "NA"
                      : calculateDueDays(
                          singleDrawingData.acceptedROSubmissionDate,
                          singleDrawingData.acceptedArchitectRevisions[0]
                            ?.softCopySubmittedDate
                        ) >= 0
                      ? `${calculateDueDays(
                          singleDrawingData.acceptedROSubmissionDate,
                          singleDrawingData.acceptedArchitectRevisions[0]
                            ?.softCopySubmittedDate
                        )} days due`
                      : `${Math.abs(
                          calculateDueDays(
                            singleDrawingData.acceptedROSubmissionDate,
                            singleDrawingData.acceptedArchitectRevisions[0]
                              ?.softCopySubmittedDate
                          )
                        )} days overdue`
                    : "-"}
                </span>
              </div>

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
                singleDrawingData?.acceptedArchitectRevisions[selectedTab]
                  ?.changes &&
                singleDrawingData.acceptedArchitectRevisions[selectedTab]
                  ?.changes !== "undefined" && (
                  <>
                    <div>Changes in Revsion</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData.acceptedArchitectRevisions[selectedTab]
                        .changes || "NA"}
                    </div>
                  </>
                )}

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
                singleDrawingData?.acceptedArchitectRevisions[selectedTab]
                  ?.issuesInRevision?.length > 0 && (
                  <>
                    <div>Issues In Revision</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData.acceptedArchitectRevisions[
                        selectedTab
                      ].issuesInRevision.join(", ") || "NA"}
                    </div>
                  </>
                )}

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Drawing File </div>
                      <div>:</div>
                      <div style={{ display: "flex", marginLeft: "-120px" }}>
                        <img
                          src={Gallery}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              singleDrawingData.drawingId,
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].revision,
                              singleDrawingData
                            )
                          }
                        />
                        <img
                          src={Download}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownload(
                              singleDrawingData.drawingId,
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].revision
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>PDF File </div>
                      <div>:</div>
                      <div style={{ display: "flex", marginLeft: "-120px" }}>
                        <img
                          src={Gallery}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewPDFFile(
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ]?.pdfDrawingFileName
                            )
                          }
                        />
                        <img
                          src={Download}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handlePDFDownload(
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ]?.pdfDrawingFileName
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ApprovedTable;
