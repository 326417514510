/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import * as Icon from "react-bootstrap-icons";
import AddCompanyDialog from "./AddCompanyDialog";
import AddSiteDialog from "./AddSiteDialog";
import { WebApimanager } from "../../WebApiManager";
import EmptyLogo from "../../Images/emptylogo.png";
import UploadLogoDialog from "./UploadLogoDialog";
import AddSiteDetailsDialog from "./AddSiteDetailsDialog";
import { BACKEND_URL } from "../../Utilities/Constants";
import { BsThreeDotsVertical } from "react-icons/bs";
import ViewComapnyDialog from "./ViewCompanyDialog";
import ViewSiteDialog from "./ViewSiteDialog";
import { useNavigate, useParams } from "react-router-dom";
import { userInfo } from "../../atoms";
import { useRecoilState } from "recoil";
import SiteDetailsModal from "./SiteDetailsModal";

const Sites = () => {
  let webApi = new WebApimanager();
  const [companies, setCompanies] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [SelectedSite, setSelectedSite] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [showAddSiteModal, setShowAddSiteModal] = useState(false);
  const [showAddSiteDetailsModal, setShowAddSiteDetailsModal] = useState(false);
  const [showUploadLogoDialog, setShowUploadLogoDialog] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [showSiteModal, setShowSiteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dialogType, setDialogType] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [openSiteDialog, setOpenSiteDialog] = useState(false);
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [selectedSiteData, setSelectedSiteData] = useState(null);
  const [filteredSites, setFilteredSites] = useState([]);
  const [compKeyword, setCompKeyword] = useState('')
  const navigate = useNavigate();
  let { id } = useParams();
  // console.log("id", id)

  const [siteViewModal, setSiteViewModal] = useState(false);
  const [viewSite, setViewSite] = useState(null);

  useEffect(() => {
    if (id) {
      webApi
        .get(`api/sites/sites/${id}`)
        .then((response) => {
          console.log("Full response:", response); // Logs the entire response object
          console.log("Response data:", response.data); // Logs the actual data returned
          console.log("Status code:", response.status); // Logs the status code (e.g., 200)
          setFilteredSites(response.data.data.sites);
        })
        .catch((error) => {
          console.error("There was an error fetching the data!", error);
        });
    }
  }, [id]);

  useEffect(() => {
    let tempCompany = companies.find((company) => company._id === id);
    console.log(tempCompany);
    setSelectedCompany(tempCompany);
  }, [companies, id]);

  useEffect(() => {
    webApi
      .get("api/company")
      .then((response) => {
        console.log("company Detailes checking", response);
        setCompanies(response.data.data.companies);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  useEffect(() => {
    console.log(selectedCompany);
    console.log(SelectedSite);
  }, [selectedCompany, SelectedSite]);

  const handleCompanyClick = (company) => {
    console.log(company,"company")
    setFilteredSites([]);
    setSelectedCompany(company);
    setSelectedCompanyId(company._id);
    setCompKeyword(company.companyKeyWord)
    // console.log(selectedCompanyId);
    navigate(`/sites/${company._id}`,{
      state: {company}
    });
  };

  const handleSiteClick = (site) => {
    setSelectedSite(site);
    setSelectedSiteId(site._id);
    // console.log(selectedSiteId);
    setSelectedCompany(selectedCompany);
    setSelectedCompanyId(selectedCompany?._id);
  };

  const handleBackToCompanies = () => {
    setSelectedCompany(null);
    setSelectedCompanyId("");
    navigate("/sites");
  };
  const handleBackToSites = () => {
    setSelectedSite(null);
    setSelectedSiteId("");
    setSelectedCompany(selectedCompany);
    setSelectedCompanyId(selectedCompany?._id);
  };

  const handleMenuClick = (event, data, type) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(data);
    setDialogType(type);
  };

  const handleUploadLogoClick = () => {
    if (dialogType === "company") {
      setSelectedCompanyId(selectedId);
    } else if (dialogType === "site") {
      setSelectedSiteId(selectedId);
    }

    setShowUploadLogoDialog(true);
    handleMenuClose();
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewClick = () => {
    if (dialogType === "company") {
      setSelectedCompanyData(selectedId);
      setOpenCompanyDialog(true);
    } else if (dialogType === "site") {
      setSelectedSiteData(selectedId);
      setOpenSiteDialog(true);
    }

    handleMenuClose();
  };

  const handleCloseCompanyDialog = () => {
    setOpenCompanyDialog(false);
  };
  const handleCloseSiteDialog = () => {
    setOpenSiteDialog(false);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedCompany(selectedCompany);
    setSelectedCompanyId(selectedCompany?._id);
  };

  const handleAddSiteClick = (companyId, companyName) => {
    setSelectedCompanyId(companyId);
    setSelectedCompanyName(companyName);
    setShowAddSiteModal(true);
  };

  const handleAddSiteDetailsClick = () => {
    setShowAddSiteDetailsModal(true);
    handleCloseMenu();
  };

  const handleAddCompany = () => setShowAddCompanyModal(true);

  const handleAddCompanyClose = () => setShowAddCompanyModal(false);

  const handleSiteUpload = () => setShowSiteModal(true);

  const handleAddSiteClose = () => setShowAddSiteModal(false);

  const handleAddSiteDetailsClose = () => setShowAddSiteDetailsModal(false);
  const handleUploadLogoClose = () => {
    setShowUploadLogoDialog(false);
    setDialogType("");
    setSelectedCompanyId("");
    setSelectedSiteId("");
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredCompanies = companies.filter((company) =>
    (company.companyDetails.companyName || "")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  // const filteredSites = sites
  //   .filter((site) => site.companyId === selectedCompanyId)
  //   .filter((site) =>
  //     site.siteName.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const handleSearchInputChangeForSites = (event) => {
    setSearchTerm(event.target.value);
    let tempFilteredSites1 = filteredSites;
    let tempFilteredSites = tempFilteredSites1.filter((site) =>
      site.siteName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSites(tempFilteredSites);
  };

  const [currentUserInfo] = useRecoilState(userInfo);

  // Fetch all sites from the API
  const fetchSites = async () => {
    try {
      const siteDataResponse = await webApi.get("api/sites/AllSites");
      console.log("Fetched Sites Response:", siteDataResponse); // Logs the full response
      setSites(siteDataResponse.data.sites);
    } catch (error) {
      console.error("Error fetching sites:", error);
    }
  };

  useEffect(() => {
    fetchSites();
  }, []);

  useEffect(() => {
    if (sites.length > 0 && currentUserInfo) {
      console.log("Current User Info:", currentUserInfo);

      const permittedSites = currentUserInfo.permittedSites || [];
      const permittedSiteIds = permittedSites.map((site) => site.siteId);

      console.log("Permitted Site IDs:", permittedSiteIds);
      console.log("All Sites Data:", sites);

      // Check if permittedSiteIds is empty
      if (permittedSiteIds.length === 0) {
        console.log("No permitted site IDs found.");
      }

      const matchingSites = sites.filter((site) => {
        const isSiteIdMatched = permittedSiteIds.includes(site._id);
        const isIdMatched = permittedSiteIds.some(
          (permittedSite) => permittedSite === site._id
        );

        // Log for each site to see what's being compared
        console.log(`Checking Site ID: ${site._id}`);
        console.log(`Is Site ID Matched: ${isSiteIdMatched}`);
        console.log(`Is ID Matched: ${isIdMatched}`);

        return isSiteIdMatched || isIdMatched;
      });

      console.log("Matching Sites:", matchingSites);
      setFilteredSites(matchingSites);
    }
  }, [sites, currentUserInfo]);

  const [showModal, setShowModal] = useState(false); // State for modal visibility

  // Handle card click to show the modal
  const handleCardClick = (site) => {
    setViewSite(site); // Set the clicked site
    setShowModal(true); // Open the modal
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
    setViewSite(null); // Clear the selected site
  };

  return (
    <div
      style={{
        backgroundColor: "#f3f3f3",
        height: "100%",
        width: "100%",
        paddingTop: "25px",
      }}
    >
      {currentUserInfo.role === "MEP" ? (
        <div
          style={{
            margin: "50px auto",
            boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "16px",
            padding: "20px",
            maxWidth: "1200px",
            position: "relative",
          }}
        >
          {SelectedSite ? (
            <>
              <div
                style={{
                  display: "flex",

                  alignItems: "center",
                  paddingBottom: "20px",
                }}
              >
                <Button
                  onClick={handleBackToSites}
                  style={{
                    marginTop: "-10px",
                    color: "black",
                    cursor: "pointer",
                    border: "none",
                  }}
                >
                  <Icon.ArrowLeft
                    size={30}
                    style={{ color: "var(--primary-color)" }}
                  />
                </Button>
                <h4 style={{ color: "#E47331" }}>
                  ADD SITE DETAILS OF {SelectedSite.siteName}
                </h4>
              </div>
              <AddSiteDetailsDialog id={selectedSiteId} />
            </>
          ) : id ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: "20px",
                }}
              >
                <Button
                  onClick={handleBackToCompanies}
                  style={{
                    marginTop: "-10px",
                    color: "black",
                    cursor: "pointer",
                    border: "none",
                  }}
                >
                  <Icon.ArrowLeft
                    size={30}
                    style={{ color: "var(--primary-color)" }}
                  />
                </Button>
                <h4 style={{ color: "#E47331" }}>
                  {selectedCompany?.companyDetails?.companyName}
                </h4>
                <div
                  style={{
                    position: "relative",
                    width: "250px",
                    marginLeft: "auto",
                    marginRight: "20px",
                    height: "40px",
                  }}
                >
                  <input
                    placeholder="Search Sites"
                    value={searchTerm}
                    onChange={handleSearchInputChangeForSites}
                    style={{
                      paddingLeft: "20px",
                      border: "1px solid #E47331",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />
                </div>
                <Button
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#E47331",
                    borderColor: "#E47331",
                    color: "white",
                    borderRadius: "0px",
                  }}
                  onClick={() =>
                    handleAddSiteClick(
                      selectedCompany._id,
                      selectedCompany.companyDetails.companyName
                    )
                  }
                >
                  <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
                  Add Site
                </Button>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "20px",
                }}
              >
                {filteredSites.map((site) => (
                  <div
                    key={site._id}
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      overflow: "hidden",
                    }}
                  >
                    <div>
                      <img
                        src={
                          site.siteImage
                            ? `${BACKEND_URL}${site.siteImage}`
                            : EmptyLogo
                        }
                        alt="Not Found"
                        style={{
                          width: "100%",
                          height: "200px",
                          cursor:
                            site.enableModules.qaAndQc === true ||
                            site.enableModules.qs === true
                              ? "pointer"
                              : "default",
                          transition: "transform 0.3s ease-in-out",
                        }}
                        onMouseEnter={(e) => {
                          if (
                            site.enableModules.qaAndQc === true ||
                            site.enableModules.qs === true
                          ) {
                            e.currentTarget.style.transform = "scale(1.1)";
                          }
                        }}
                        onMouseLeave={(e) => {
                          if (
                            site.enableModules.qaAndQc === true ||
                            site.enableModules.qs === true
                          ) {
                            e.currentTarget.style.transform = "scale(1)";
                          }
                        }}
                        onClick={
                          site.enableModules.qaAndQc === true ||
                          site.enableModules.qs === true
                            ? () => handleSiteClick(site)
                            : null
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          background: "#F3F4F6",
                          padding: "10px 10px 10px",
                        }}
                      >
                        <h5>{site.siteName || "Unnamed Site"}</h5>
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={(event) =>
                            handleMenuClick(event, site, "site")
                          }
                        >
                          <BsThreeDotsVertical size={25} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: "20px",
                }}
              >
                <h3 style={{ color: "var(--primary-color)", fontSize: "20px" }}>
                  Companies
                </h3>
                <div
                  style={{
                    position: "relative",
                    width: "250px",
                    marginLeft: "auto",
                    marginRight: "20px",
                    height: "40px",
                  }}
                >
                  <input
                    placeholder="Search Companies"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    style={{
                      paddingLeft: "20px",

                      border: "1px solid #E47331",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />
                </div>
                <Button
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#E47331",
                    borderColor: "#E47331",
                    color: "white",
                    borderRadius: "0px",
                  }}
                  onClick={handleAddCompany}
                >
                  <Icon.Plus
                    size={20}
                    style={{ marginRight: "5px", color: "white" }}
                  />{" "}
                  Add Company
                </Button>
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "20px",
                }}
              >
                {filteredCompanies.map((company) => (
                  <div
                    key={company._id}
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      overflow: "hidden",
                    }}
                  >
                    <div>
                      <img
                        src={
                          company.uploadLogo
                            ? `${BACKEND_URL}${company.uploadLogo}`
                            : EmptyLogo
                        }
                        alt="Not Found"
                        style={{
                          width: "100%",
                          height: "200px",
                          cursor: "pointer",
                          transition: "transform 0.3s ease-in-out",
                        }}
                        onClick={() => handleCompanyClick(company)}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "scale(1.1)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "0px",

                          background: "#F3F4F6",

                          padding: "10px 10px 10px",
                        }}
                      >
                        <h5>
                          {company.companyDetails.companyName ||
                            "Unnamed Company"}
                        </h5>
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={(event) =>
                            handleMenuClick(event, company, "company")
                          }
                        >
                          <BsThreeDotsVertical size={25} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                width: "200px",
              },
            }}
          >
            <MenuItem
              // onClick={() => handleMenuItemClick("edit")} handleViewClick
              onClick={(e) => handleViewClick(e, selectedId, dialogType)}
              handleViewClick
            >
              View {dialogType === "company" ? "Company" : "Site"}
            </MenuItem>

            <MenuItem
              onClick={(e) => handleUploadLogoClick(e, selectedId, dialogType)}
            >
              Upload {dialogType === "company" ? "Company" : "Site"} Logo
            </MenuItem>
          </Menu>

          {/* Upload Company Dialog */}
          <AddCompanyDialog
            close={handleAddCompanyClose}
            open={showAddCompanyModal}
            setCompKeyword={setCompKeyword}
          />

          <AddSiteDialog
            close={handleAddSiteClose}
            open={showAddSiteModal}
            id={selectedCompanyId}
            companyName={selectedCompanyName}
            onSiteAdded={fetchSites}
            compKeyword={compKeyword}
          />
          <ViewComapnyDialog
            open={openCompanyDialog}
            onClose={handleCloseCompanyDialog}
            type={dialogType}
            companyData={selectedCompanyData}
          />
          <ViewSiteDialog
            open={openSiteDialog}
            onClose={handleCloseSiteDialog}
            type={dialogType}
            companyData={selectedSiteData}
          />
          {/* Upload Sites Dialog */}
          <UploadLogoDialog
            open={showUploadLogoDialog}
            close={handleUploadLogoClose}
            type={dialogType}
            id={
              dialogType === "company"
                ? selectedCompanyId._id
                : selectedSiteId._id
            }
          />
        </div>
      ) : (
        <>
          <div
            className=""
            style={{ height: "auto", width: "85%", margin: "0 auto" }}
          >
            {filteredSites.length > 0 ? (
              <div className="row" style={{ height: "73vh" }}>
                {filteredSites.map((site) => (
                  <div
                    className="col-md-4 mb-4"
                    key={site._id}
                    onClick={() => handleCardClick(site)}
                  >
                    <div className="card">
                      <img
                        src={
                          site.uploadLogo
                            ? `${BACKEND_URL}${site.uploadLogo}`
                            : EmptyLogo
                        }
                        alt="Not Found"
                        style={{
                          width: "100%",
                          height: "200px",
                          cursor: "pointer",
                          transition: "transform 0.3s ease-in-out",
                        }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{site.siteName}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No matching sites available.</p>
            )}
          </div>
        </>
      )}
      <SiteDetailsModal
        show={showModal}
        onHide={handleCloseModal}
        site={viewSite}
      />
    </div>
  );
};

export default Sites;
