import React, { useState, useEffect } from "react";
import ApprovedTable from "./ApprovedTable";
import RequestedTable from "./RequestedTable";
import PendingTable from "./PendingTable";
import RegisterTable from "./RegisterTable";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { allUsersInformations, isRoForwardAccessTrue, isRoRfiRaisedAccess, roTab, siteId, userId, userInfo } from "../../../atoms";
import SelectSite from "../../../Utilities/SelectSite";
import "./ROtoSiteLevel.css";
import Analysis from "../Components/Analysis";

const ROtoSiteLevel = () => {
  const webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(roTab);
  const [site] = useRecoilState(siteId);
  const [currentUserId] = useRecoilState(userId)
  console.log(currentUserId,"currentUserId")
  const [currentUserInfo, setCurrentUserInfo] = useState({});
  const [allUsersInfo, setAllUsersInfo] = useRecoilState(allUsersInformations)
  // console.log(allUsersInfo,"checkAllUsers")
  const [filteredUserList,setFilteredUserList] = useState([])
  const [tabEnableModules, setTabEnableModules] = useState({
    drawing: false,
    rfi: false,
    pending: false,
    register: false,
    analysis: false,
  });
  const [forwardAccess, setForwardAccess] = useState(false)
  const [rfiRaisedAccess, setRfiRaisedAccess] = useState(false)

const getUserById = async(id) =>{
  try {
    const response = await webApi.get(`/api/users/getUser/${id}`)
    if(response.status === 200){
      console.log(response?.data?.data?.user,"activeUserDetails")
      setCurrentUserInfo(response?.data?.data?.user)
    }
  } catch (error) {
    
  }
}

useEffect(()=>{
  if(currentUserId){
    getUserById(currentUserId)
  }
},[currentUserId,site])

useEffect(()=>{
    if(Object.keys(currentUserInfo).length){
      const permittedSite = currentUserInfo?.permittedSites?.find((item) => item?.siteId === site);
      if(permittedSite){
      setForwardAccess(permittedSite?.enableModules.drawingDetails.roDetails?.forwardAccess)
      setRfiRaisedAccess(permittedSite?.enableModules?.drawingDetails?.roDetails?.rfiRaisedAccess)
    }}
  },[currentUserInfo,site])

  
  

  useEffect(() => {
    console.log("RO site Enable Modules", currentUserInfo);

    if (currentUserInfo?.permittedSites) {
      let enabledTabs = {
        drawing: false,
        rfi: false,
        pending: false,
        register: false,
        analysis: false,
      };

      currentUserInfo.permittedSites.forEach((site) => {
        const roToSiteDetails = site.enableModules?.drawingDetails?.roDetails;

        if (roToSiteDetails) {
          enabledTabs = {
            drawing: enabledTabs.drawing || roToSiteDetails.drawing,
            rfi: enabledTabs.rfi || roToSiteDetails.rfi,
            pending: enabledTabs.pending || roToSiteDetails.pending,
            register: enabledTabs.register || roToSiteDetails.register,
            analysis: enabledTabs.analysis || roToSiteDetails.analysis,
          };
        }
      });

      setTabEnableModules(enabledTabs);
    }
  }, [currentUserInfo]);

  // useEffect(()=>{
  //   // console.log(allUsersInfo,"allUserInfoINROToSite")
  //   // const currFilteredUser = allUsersInfo.find((userInfo)=>userInfo._id === currentUserInfo._id)
  //   //     console.log(currFilteredUser,"checkingfilteredUser")
  //   //     setFilteredUserList(currFilteredUser?.permittedSites)
  //       const permittedSite = currentUserInfo?.permittedSites?.find((item) => item?.siteId === site);
  //       console.log(permittedSite,"checkingPerm")
  //       if(permittedSite){
  //         console.log(permittedSite?.enableModules?.drawingDetails?.roDetails?.forwardAccess,"checkingInside1")
  //         console.log(permittedSite?.enableModules?.drawingDetails?.roDetails?.rfiRaisedAccess,"checkingInside2")
  //         setForwardAccess(permittedSite?.enableModules?.drawingDetails?.roDetails?.forwardAccess)
  //         setRfiRaisedAccess(permittedSite?.enableModules?.drawingDetails?.roDetails?.rfiRaisedAccess)
  //       }
  // },[site,currentUserInfo])

  // useEffect(()=>{
  //   console.log(filteredUserList,"filteredUserList")
  //   if(filteredUserList?.length){
  //     const permittedSite = filteredUserList?.find((item) => item?.siteId === site);
  //       console.log(permittedSite,"checkingPerm")
  //       if(permittedSite){
  //         console.log(permittedSite?.enableModules?.drawingDetails?.roDetails?.forwardAccess,"checkingInside1")
  //         console.log(permittedSite?.enableModules?.drawingDetails?.roDetails?.rfiRaisedAccess,"checkingInside2")
  //         setForwardAccess(permittedSite?.enableModules?.drawingDetails?.roDetails?.forwardAccess)
  //         setRfiRaisedAccess(permittedSite?.enableModules?.drawingDetails?.roDetails?.rfiRaisedAccess)
  //       }
  //   }
  // },[filteredUserList,allUsersInfo])

  useEffect(()=>{
    console.log(forwardAccess,"=>",rfiRaisedAccess,"checkingAccess")
  },[forwardAccess,rfiRaisedAccess,filteredUserList])

  

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  if (!site) {
    return <SelectSite />;
  }

  const anyTabEnabled = Object.values(tabEnableModules).some((val) => val);
  console.log(forwardAccess,"for1")
  console.log(rfiRaisedAccess,"for2")
  const allTabs = [
    {
      label: "Drawing",
      index: 0,
      component: (
        <ApprovedTable
          forwardAccess={forwardAccess}
          rfiRaisedAccess={rfiRaisedAccess}
        />
      ),
    },
    {
      label: "RFI",
      index: 1,
      component: <RequestedTable 
      rfiRaisedAccess={rfiRaisedAccess}
      />,
    },
    { label: "Pending", index: 2, component: <PendingTable 
      forwardAccess={forwardAccess}
      /> },
    { label: "Register", index: 3, component: <RegisterTable/>  },
    { label: "Analysis", index: 4, component: <Analysis type="RO" /> },
  ];

  const enabledTabs = allTabs.filter(
    (tab) => tabEnableModules[tab.label.toLowerCase()]
  );

  return (
    <>
      {anyTabEnabled ? (
        <>
          <div className="containerDrawingPagesRotoSite">
            <ul className="tabsRotoSite" role="tablist">
              {enabledTabs.map((tab) => (
                <li
                  key={tab.index}
                  className={`tabRotoSite ${
                    value === tab.index ? "active" : ""
                  }`}
                  role="tab"
                  aria-selected={value === tab.index}
                  aria-controls={`tabpanel-${tab.index}`}
                  id={`tab-${tab.index}`}
                  onClick={() => handleChange(tab.index)}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
          </div>

          {enabledTabs.map((tab) => (
            <TabPanel key={tab.index} value={value} index={tab.index}>
              {tab.component}
            </TabPanel>
          ))}
        </>
      ) : (
        <div className="no-tabs-message">
          <p
            style={{
              margin: "0 auto",
              color: "#E47331",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            You do not have the necessary permissions to view any ROtoSiteLevel
            tabs.
          </p>
        </div>
      )}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className="tabpanel"
    >
      {value === index && <div className="tabpanel-content">{children}</div>}
    </div>
  );
}

export default ROtoSiteLevel;
