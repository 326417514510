import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { todayDate } from "../../../../Utilities/DateUtils";
import { WebApimanager } from "../../../../WebApiManager";
import SitesDropdown from "../../../../Utilities/SitesDropdown";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import SearchDropdownIdVal from "../../../../Widgets/SearchDropdownIdVal";
import { updateRegister } from "../../BackendUtils";
import { registerAtom } from "../../../../atoms";
import { useRecoilState } from "recoil";
import { SUCESS_STATUS } from "../../../../Utilities/Constants";
import { Dialog, DialogContent } from "../../../Dialog/Dialog";

const AddDrawingDialog = (props) => {
  const { open, handleDialogClose, site,currentView } = props;

  // setRegisterListBy fetching from .. ( status condition not required siteid query required )
  const [registerList, setRegisterList] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState("");
  const [filteredDrawingOptions, setFilteredDrawingOptions] = useState([]);

  let webApi = new WebApimanager();

  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    folder:"",
    designDrawingConsultant: "",
    submittedDate: "",
    receivedHardCopy: "",
    drawingDWGFileName: null,
    drawingPDFFileName:null,
    revisions: [],
    acceptedArchitectRevisions: [],
    note: "",
    selectedRevision: "",
  });

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [errors, setErrors] = useState({});

  const handleClose = () => {
    setFormData({
      regiterId: "",
      drawingNumber: "",
      drawingName: "",
      category: "",
      designDrawingConsultant: "",
      // acceptedScheduledsubmissionDate: '',
      // actualSubmissionDate: '',
      // dueDays: '',
      // remarks:'',
      submittedDate: "",
      // issuedSoftCopy: '',
      receivedHardCopy: "",
      drawingDWGFileName: null,
      drawingPDFFileName:null,
      revisions: "",
      acceptedArchitectRevisions: [],
      note: "",
    });
    handleDialogClose();
    setErrors({});
  };

  const [architectselectedRevision, setArchitectSelectedRevision] =
    useState("");
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    if (name === "designDrawingConsultant") {
      const selectedConsultant = value;
      setSelectedConsultant(selectedConsultant);

      // Filter drawing numbers based on the selected consultant
      const filteredDrawings = registerList.filter(
        (register) =>
          register.designDrawingConsultant?.role === selectedConsultant
      );

      setFilteredDrawingOptions(filteredDrawings);

      // Clear the selected drawing number if the consultant changes
      setFormData((prevFormData) => ({
        ...prevFormData,
        designDrawingConsultant: selectedConsultant,
        drawingNumber: "",
        category: "",
        drawingName: "",
        revisions: [],
        selectedRevision: "",
      }));
    }

    if (name === "drawingNumber") {
      const selectedDrawing = registerList.find(
        (register) => register.drawingNo === value
      );

      if (selectedDrawing) {
        
        const combinedRevisions =  Array.isArray(
          selectedDrawing.acceptedRORevisions
        )
          ? selectedDrawing.acceptedRORevisions.flatMap(
              (rev) => rev.revision || []
            )
          : [];
           
        const acceptedArchitectRevisions = Array.isArray(
          selectedDrawing.acceptedArchitectRevisions
        )
          ? selectedDrawing.acceptedArchitectRevisions.flatMap(
              (rev) => rev.revision || []
            )
          : [];
        setFormData((prevFormData) => ({
          ...prevFormData,
          registerId: selectedDrawing._id,
          drawingName: selectedDrawing.drawingTitle,
          drawingNumber: selectedDrawing.drawingNo,
          folder:selectedDrawing.folderId?.folderName || "",
          category: selectedDrawing.category?.category || "",
          revisions: currentView !== "collected" ? combinedRevisions : acceptedArchitectRevisions,
          acceptedArchitectRevisions:acceptedArchitectRevisions,
          submittedDate: todayDate(),
          selectedRevision: "",
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          registerId: "",
          drawingName: "",
          folder:"",
          selectedRevision: "",
          category: "",
          revisions: [],
          submittedDate: todayDate(),
        }));
      }
    }

    if (name === "selectedRevision" || name === "note") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleArchitechRevisionSelection = (event) => {
    setArchitectSelectedRevision(event.target.value);
  };
  const [currentTab, setCurrentTab] = useState(currentView);

  const handleTabChange = (tabKey) => {
    setCurrentTab(tabKey);
    

    // Reset form data when switching to the 'received' tab
    
  };
  useEffect(()=>{
    if (currentView === "upload") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        registerId: "",
        folder:"",
        drawingNumber: "",
        drawingName: "",
        category: "",
        designDrawingConsultant: "",
        submittedDate: "",
        drawingDWGFileName: null,
        drawingPDFFileName:null,
        receivedCopies: "",
        revisions: [],
        note: "",
        selectedRevision: "", // Add this if you're using it in the received tab
      }));
    }
    if (currentView === "collected") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        registerId: "",
        folder:"",
        drawingNumber: "",
        drawingName: "",
        category: "",
        designDrawingConsultant: "",
        submittedDate: "",
        drawingDWGFileName: null,
        receivedCopies: "",
        revisions: [],
        note: "",
        selectedRevision: "", // Add this if you're using it in the received tab
      }));
    }

  },[currentView])

  // Example validation function
  const validateForm = (formData, currentTab) => {
    const newErrors = {};
    if (currentView === "upload") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.folder.trim()) {
        newErrors.folder =
          "Folder Name is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }

      if (!formData.drawingDWGFileName) {
        newErrors.drawingDWGFileName = "Drawing File is required";
      }
      else if (formData.drawingDWGFileName instanceof File) {
        // Check if the file name ends with '.dwg'
        if (!formData.drawingDWGFileName.name.endsWith(".dwg")) {
          newErrors.drawingDWGFileName = "Drawing File must be a .dwg file";
        }
      } else if (typeof formData.drawingDWGFileName === "string") {
        // Check if the string ends with '.dwg'
        if (!formData.drawingDWGFileName.endsWith(".dwg")) {
          newErrors.drawingDWGFileName = "Drawing File must be a .dwg file";
        }
      }
      if (!formData.drawingPDFFileName) {
        newErrors.drawingPDFFileName = "Drawing PDF File is required";
      } else if (formData.drawingPDFFileName instanceof File) {
        // Check if the file name ends with '.dwg'
        if (!formData.drawingPDFFileName.name.endsWith(".pdf")) {
          newErrors.drawingPDFFileName = "Drawing File must be a .pdf file";
        }
      } else if (typeof formData.drawingPDFFileName === "string") {
        // Check if the string ends with '.dwg'
        if (!formData.drawingPDFFileName.endsWith(".pdf")) {
          newErrors.drawingPDFFileName = "Drawing File must be a .pdf file";
        }
      }
      // if (!formData.remarks) errors.remarks = 'This field is required.';
    } else if (currentView === "collected") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      if (!formData.selectedRevision.trim()) {
        newErrors.selectedRevision = "revision is required";
      }

      if (!formData.receivedCopies) {
        newErrors.receivedCopies = "Received Copies is required";
      }
      if (!formData.drawingDWGFileName) {
        newErrors.drawingDWGFileName = "Drawing File is required";
      }
      
      
    }
    return newErrors;
  };

  const handleFormSubmit = async () => {
    console.log("ckicking");
    // Validate the form data
    const errors = validateForm(formData, currentView);
    console.log("errors", errors);
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    // Perform API call based on the active tab
    if (currentView === "upload") {
      console.log("iploadckicking");
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitUpload(formData.registerId);
    }
    if (currentView === "collected") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReceived(formData.registerId);
    }
  };

  const submitUpload = async (registerId) => {
    console.log("registerId", registerId);
  
    try {
      // Basic form data
      const ApprovalFormData = new FormData();
      ApprovalFormData.append("softCopySubmittedDate", todayDate());
      ApprovalFormData.append("changes", formData.note);
  
      // Conditionally add files if they exist
      if (formData.drawingDWGFileName) {
        ApprovalFormData.append("drawingFileName", formData.drawingDWGFileName);
      }
  
      if (formData.drawingPDFFileName) {
        ApprovalFormData.append("pdfDrawingFileName", formData.drawingPDFFileName);
      }
  
      console.log("Submitting Approval data:", ApprovalFormData);
  
      // Submit the approval form data
      const response = await webApi.imagePut(
        `api/architectureToRoRegister/revisions/${registerId}?revisionType=acceptedRORevisions`,
        ApprovalFormData,
      );
      if(response.status === 200){
        // Clear loading state and close the form
        setApiProcessing({ loader: false, message: "" });
        handleClose();
        window.location.reload();
    }
      } catch (error) {
        console.error("Error submitting form:", error);
        setApiProcessing({ loader: false, message: "" });
        alert("Failed to submit the form. Please try again later.");
      }
    };

  const submitReceived = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedROHardCopyRevisions: [
          {
            hardCopySubmittedDate: todayDate(),
            receivedCopies: formData.receivedCopies,
            revision: formData.selectedRevision,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/HardCopyRevision/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingDWGFileName) {
        const hardCopyFile = new FormData();
        hardCopyFile.append("hardCopyFile", formData.drawingDWGFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/RoHardCopyFile/${registerId}`,
          hardCopyFile
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);

      // Handle error, show error message, etc.
      alert("Failed to submit the form. Please try again later.");
    }
  };

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInRegisterData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setRegisterList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setRegisterList(response.registers);
        }
      });
    }
  };

  const fetchRegisterData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setRegisterList(response.registers);
      }
    });
  };

  useEffect(() => {
    fetchInRegisterData();
  fetchRegisterData() ;

  }, []);

 

  // Helper function to calculate the next revision
  const getNextRevision = (revisions) => {
    const lastRevision = revisions[revisions.length - 1];
    const numberPartStr = lastRevision.replace("R", "");
    const numberPart = parseInt(numberPartStr, 10);

    if (!isNaN(numberPart)) {
      return `R${numberPart + 1}`;
    } else {
      console.error(
        `Invalid revision format or parsing error: ${lastRevision}`
      );
      return lastRevision; // Return the last valid revision if there's a parsing error
    }
  };

  useEffect(()=>{
    console.log(registerList,"registerList")
  },[registerList])

  useEffect(()=>{
    console.log(formData,"formData")
  },[formData])
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: " #4D4D4D",
          margin: "0px",
          height:'50px',
          position:"relative"
        }}
      >
        <p style={{width:"100%",marginTop:"15px", textAlign: "center", color: "white" }}>
          Drawing
        </p>
        <RxCross1
          size={20}
          style={{
            position:"absolute",
            marginLeft: "auto",
            cursor: "pointer",
            marginRight: "20px",
            color: "white",
          }}
          onClick={handleClose}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0",
            border: "1px solid var(--primary-color)",
          }}
        >
          {currentView === "upload" && <button
            onClick={() => handleTabChange("upload")}
            style={{
              backgroundColor:
                currentView === "upload"
                  ? "var(--primary-color)"
                  : "transparent",
              color: currentView === "upload" ? "white" : "var(--primary-color)",
              width: "150px",
              height: "35px",
              cursor: "pointer",
              borderRight: "1px solid var(--primary-color)",
            }}
          >
            Upload
          </button>}

          {currentView === "collected" && <button
            onClick={() => handleTabChange("collected")}
            style={{
              background:
                currentView === "collected"
                  ? "var(--primary-color)"
                  : "transparent",
              color:
                currentView === "collected" ? "white" : "var(--primary-color)",
              width: "150px",
              height: "35px",
              cursor: "pointer",
            }}
          >
            Received
          </button>}
        </div>
      </div>
      <DialogContent
        style={{ padding: "25px", minWidth: "300px", minHeight: "300px",maxHeight: "70vh", overflowY: "auto", }}
      >
        <>
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
                width: "80%",
              }}
            >
              <div>
                <label
                  htmlFor="designDrawingConsultant"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Design Consultant
                  <span style={{ color: "red" }}>*</span>
                </label>
                <SearchDropdownIdVal
                  placeholder="Select Design Consultant"
                  options={Array.from(
                    new Set(
                      registerList.map(
                        (register) => register.designDrawingConsultant?.role
                      )
                    )
                  )
                    .filter(Boolean)
                    .map((consultant, index) => ({
                      name: consultant,
                      value: consultant,
                    }))}
                  onOptionSelect={(option) =>
                    handleChange({
                      target: {
                        name: "designDrawingConsultant",
                        value: option.value,
                      },
                    })
                  }
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  value={formData.designDrawingConsultant}
                />
                {/* <select
    id="designDrawingConsultant"
    name="designDrawingConsultant"
    value={formData.designDrawingConsultant}
    onChange={handleChange}
    className="form-control fs-12"
    style={{ width: '100%' }}
  >
    <option value="">Select Design Consultant</option>
    {Array.from(new Set(registerList.map(register => register.designDrawingConsultant?.designDrawingConsultant)))
      .filter(Boolean)
      .map((consultant, index) => (
        <option key={index} value={consultant}>{consultant}</option>
    ))}
  </select> */}
                {errors.designDrawingConsultant && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.designDrawingConsultant}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingNumber"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing No
                  <span style={{ color: "red" }}>*</span>
                </label>
                {/* <select
  id="drawingNumber"
  name="drawingNumber"
  value={formData.drawingNumber}
  onChange={handleChange}
  className="form-control fs-12"
  style={{ width: '100%' }}
>
  <option value="">Select Drawing No</option>
  {filteredDrawingOptions.map(register => (
    <option key={register._id} value={register.drawingNo}>{register.drawingNo}</option>
  ))}
</select> */}
                <SearchDropdownIdVal
                  placeholder="Select Drawing No"
                  options={filteredDrawingOptions.map((register) => ({
                    name: register.drawingNo,
                    value: register.drawingNo,
                  }))}
                  onOptionSelect={(option) =>
                    handleChange({
                      target: { name: "drawingNumber", value: option.value },
                    })
                  }
                  value={formData.drawingNumber}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />

                {errors.drawingNumber && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingNumber}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Title
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="drawingName"
                  name="drawingName"
                  value={formData.drawingName}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.drawingName && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingName}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="category"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Category
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.category && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.category}
                  </p>
                )}
              </div>
              {currentView === "collected" && (
                <div>
                  <label
                    htmlFor="receivedCopies"
                    className="fs-10 fw-bold text-start py-2
              "
                  >
                    No of Copies Received
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    id="receivedCopies"
                    name="receivedCopies"
                    placeholder="Enter No of Received Copies"
                    value={formData.receivedCopies}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                  {errors.receivedCopies && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.receivedCopies}
                    </p>
                  )}
                </div>
              )}
   <div>
                      <label
                        htmlFor="folder"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Folder
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                    type="text"
                    id="folder"
                    name="folder"
                    value={formData.folder}
                    onChange={handleChange}
                    placeholder="Enter your  Folder "
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                      {errors.folder && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.folder}
                        </p>
                      )}
                    </div>
              <div>
                <label
                  htmlFor="selectedRevision"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Select Revision<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="selectedRevision"
                  name="selectedRevision"
                  value={
                    currentView !== "collected"
                      ? formData.revisions.length === 0
                        ? "R0"
                        : getNextRevision(formData.revisions)
                      : formData.selectedRevision
                  }
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly={currentView !== "collected"}
                >
                  {currentView === "collected" ? (
                    formData.revisions.length > 0 ? (
                      <>
                        <option value="">Select Revision</option>
                        {formData.revisions.map((rev, index) => (
                          <option key={index} value={rev}>
                            {rev}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option value="">No soft copy revisions available</option>
                    )
                  ) : (
                    <option
                      value={
                        formData.revisions.length === 0
                          ? "R0"
                          : getNextRevision(formData.revisions)
                      }
                    >
                      {formData.revisions.length === 0
                        ? "R0"
                        : getNextRevision(formData.revisions)}
                    </option>
                  )}
                </select>
                {errors.selectedRevision && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.selectedRevision}
                  </p>
                )}
              </div>

              {currentView != "collected" && (
                <>
                  <div>
                    <label
                      htmlFor="architectselectedRevision"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Architect Revision Ref
                      {/* <span style={{ color: 'red' }}>*</span> */}
                    </label>
                    <select
                      id="architectselectedRevision"
                      name="architectselectedRevision"
                      onChange={handleArchitechRevisionSelection}
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                    >
                      <option value="">Select Revision</option>{" "}
                      {/* Default option */}
                      {formData.acceptedArchitectRevisions.length > 0 ? (
                        formData.acceptedArchitectRevisions.map(
                          (revision, index) => (
                            <option key={index} value={revision}>
                              {revision}
                            </option>
                          )
                        )
                      ) : (
                        <option value="" disabled>
                          No revisions available
                        </option>
                      )}
                    </select>
                    {errors.architectselectedRevision && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.architectselectedRevision}
                      </p>
                    )}
                  </div>

                  {formData.revisions.length > 0 && (
                    <div>
                      <label
                        htmlFor="note"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Changes in Revsion
                      </label>
                      <textarea
                        type="text"
                        id="note"
                        name="note"
                        value={formData.note}
                        onChange={handleChange}
                        placeholder="Enter Changes note"
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      />
                      {errors.note && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.note}
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}

              {/* 
          </>
        )} */}

              {currentView === "collected" && (
                <>
                <div>
                  <label
                    htmlFor="drawingDWGFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.pdf,.png"
                    id="drawingDWGFileName"
                    name="drawingDWGFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && !file.name.endsWith(".dwg")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .jpg,.jpeg,.pdf,.png file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: '43px' }}
                  />
                  {errors.drawingDWGFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingDWGFileName}
                    </p>
                  )}
                </div>

                {/* <div>
                  <label
                    htmlFor="drawingPDFFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload PDF file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    id="drawingPDFFileName"
                    name="drawingPDFFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.name.endsWith(".pdf")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .pdf file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: '43px'  }}
                  />
                  {errors.drawingPDFFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingPDFFileName}
                    </p>
                  )}
                </div> */}
                </>
              )}
              {currentView === "upload" && (
                <>
                <div>
                  <label
                    htmlFor="drawingDWGFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload DWG file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".dwg"
                    id="drawingDWGFileName"
                    name="drawingDWGFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.name.endsWith(".dwg")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .dwg file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: '43px'  }}
                  />
                  {errors.drawingDWGFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingDWGFileName}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="drawingPDFFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload PDF file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    id="drawingPDFFileName"
                    name="drawingPDFFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.name.endsWith(".pdf")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .pdf file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: '43px'  }}
                  />
                  {errors.drawingPDFFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingPDFFileName}
                    </p>
                  )}
                </div>

                </>
              )}
            </div>
          </section>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              style={{
                padding: "10px",
            
                border: "1px solid lightgray",
                backgroundColor: "transparent",
                width: "100px",
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = "lightgray")
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = "transparent")
              }
              onClick={handleClose}
            >
              Cancel
            </button>
            <button

              style={{
                marginLeft: "50px",
                padding: "10px",
              
                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleFormSubmit}
            >
              Submit
            </button>
          </div>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default AddDrawingDialog;
