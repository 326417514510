/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId, userInfo } from "../../../atoms";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import {Tabs, Tab} from '../../Tabs/Tabv1'
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import { canShowFolder } from "../ShowFolder";
import Pen from "../SiteLevel/IconsSiteHead/penIcon.svg"
import AddDrawingDialog from "./Components/AddDrawingDialog";
import ConsultantFilter from "../ConsultantFilter";
import ArrowToggle from "../ArrowToggle";
import SortByPopup from "../SortByPopup";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", 
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", 
          },
        },
      },
    },
  },
});

const PendingRecievedTable = ({filterCondition,
    reloadData,
    setReloadData,
    searchValue,
    setSearchValue}) => {
    const navigate = useNavigate();
    const formatDate = (dateString) => {
      if (!dateString) return "-";
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });
    };
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDateString = tomorrow.toISOString().split("T")[0];
  
    let webApi = new WebApimanager();
  
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [singleDrawingData, setSingleDrawingData] = useState(null);
    const [error, setError] = useState({});
    const [drawingList, setDrawingList] = useState([]);
    const [initialDrawingList, setInitialDrawingList] = useState([]);
    const [site, setSite] = useRecoilState(siteId);
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [selectedDrawing, setSelectedDrawing] = useState(null);
    const [currentUserInfo] = useRecoilState(userInfo);
    const [consultantValue, setConsultantValue] = useState('')
    const [formData, setFormData] = useState({
      registerId: "",
      drawingNumber: "",
      drawingName: "",
      category: "",
      designDrawingConsultant: "",
      acceptedArchitectDate: "",
      // actualSubmissionDate: todayDate(),
      // dueDays: '',
      // remarks:'',
      submittedDate: "",
      issuedSoftCopy: "",
      receivedSoftCopy: "",
      drawingDWGFileName: null,
      receivedCopies: "",
      revisions: [],
      selectedRevision: "",
    });
  
    const [drawingTableList,setDrawingTableList] = useState([])
  
    const [apiProcessing, setApiProcessing] = useState({
      loader: false,
      message: "Loading...",
    });
    const [selectedTab, setSelectedTab] = useState(0);

    const [filters, setFilters] = useState({
      drawingNo: "",
      consultant: "",
      category: "",
      drawingTitle: "",
      scheduledDate: "",
      submissionDate: "",
      revision: "",
    });
  
    // sort by start
    const [sortField, setSortField] = useState(""); // Field to sort by
    const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
    const [activeSortPopup, setActiveSortPopup] = useState(null);
  
    const handleSortClick = (field) => {
      setFilters({
        drawingNo: "",
        consultant: "",
        category: "",
        drawingTitle: "",
        scheduledDate: "",
        submissionDate: "",
        revision: "",
      });
      setSearchValue("");
      setActiveSortPopup((prev) => (prev === field ? null : field));
    };
  
    const handleSortSelection = (order) => {
      if (activeSortPopup) {
        setSortField(activeSortPopup);
        setSortOrder(order);
        setActiveSortPopup(null); // Close the popup after selection
      }
    };
  
    const handleSearchChange = useCallback((e) => {
      const inputValue = e.target.value.trim().toLowerCase();
      setSearchValue(inputValue);
      
      setFilters({
        drawingNo: "",
      consultant: "",
      category: "",
      drawingTitle: "",
      scheduledDate: "",
      submissionDate: "",
      revision: "",
      })
      setSortField("");
      setSortOrder("");
      setActiveSortPopup(null);
    }, []);
    
    const handleFilterChange = (column, value) => {
      setSearchValue("")
      setSortField("")
      setSortOrder("")
      setActiveSortPopup(null)
      setFilters((prev) => ({
        ...Object.keys(prev).reduce((acc, key) => {
            acc[key] = ""; 
            return acc;
        }, {}),
        [column]: value, 
    }));
  }
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const [currentTab, setCurrentTab] = useState("received");
  
    // const handleTabChange = (tabKey) => {
    //   setCurrentTab(tabKey);
    // };
    // const currentTab = 'received';
  
    // Example validation function
    const validateForm = (formData) => {
      const newErrors = {};
        if (!formData.drawingNumber.trim()) {
          newErrors.drawingNumber = "DrawingNumber  is required";
        }
        if (!formData.drawingName.trim()) {
          newErrors.drawingName = "Drawing Title is required";
        }
        if (!formData.designDrawingConsultant.trim()) {
          newErrors.designDrawingConsultant =
            "DesignDrawingConsultant is required";
        }
        if (!formData.category.trim()) {
          newErrors.category = "Category is required";
        }
        // if (!formData.submittedDate.trim()) {
        //   newErrors.submittedDate = "Submitted Date is required";
        // }
        // if (!formData.selectedRevision) {
        //   newErrors.selectedRevision = "revision is required";
        // }
        if(!formData.revisions){
          newErrors.revisions = "revision is required"
        }
        if (!formData.receivedCopies) {
          newErrors.receivedCopies = "receivedCopies are required";
        } else if(tableRowData?.noOfRoHardCopyRevisions && formData.receivedCopies>tableRowData?.noOfRoHardCopyRevisions){
          newErrors.receivedCopies = `recieved copies should be less than ${tableRowData?.noOfRoHardCopyRevisions+1}`
        }
  
        if (!formData.drawingDWGFileName) {
          newErrors.drawingDWGFileName = "Drawing File is required";
        }
        console.log(newErrors,"errors")
      return newErrors;
    };
  
    const handleFormSubmit = async () => {
      // Validate the form data
      const errors = validateForm(formData );
      setErrors(errors);
  
      // If there are errors, return early
      if(Object.keys(errors).length) return
  
      if (Object.keys(tableRowData).length) {
        setApiProcessing({
          loader: true,
          message: "Submitting...",
        });
        await submitReceived(formData.registerId);
      }
    };
  
    const submitReceived = async (drawingItemId) => {
      try {
        const ApprovalFormData = {
          acceptedROHardCopyRevisions: [
            {
              hardCopySubmittedDate: todayDate(),
              revision: formData.revisions,
              receivedCopies: formData.receivedCopies,
            },
          ],
        };
  
        console.log("Submitting Approval data:", ApprovalFormData);
  
        const response = await webApi.put(
          `api/architectureToRoRegister/HardCopyRevision/${drawingItemId}`,
          ApprovalFormData
        );
        const id = response.data.data._id;
  
        // If there's a file to upload, initiate PUT request to upload file
        if (formData.drawingDWGFileName) {
          const hardCopyFile = new FormData();
          hardCopyFile.append("hardCopyFile", formData.drawingDWGFileName);
          await webApi.imagePut(
            `api/architectureToRoRegister/roHardCopyFile/${drawingItemId}`,
            hardCopyFile
          );
        }
        setApiProcessing({ loader: false, message: "" });
        handleClose();
        window.location.reload();
      } catch (error) {
        setApiProcessing({ loader: false, message: "" });
        console.error("Error submitting form:", error);
        // Handle error, show error message, etc.
      }
    };
  
    const handleEdit = (drawingId) => {
      const selected = drawingList.find((drawing) => drawing._id === drawingId);
      if (selected) {
        const combinedRevisions = [
          // ...selected.acceptedArchitectRevisions,
          ...(selected.acceptedRORevisions || []),
        ];
  
        setSelectedDrawing(selected);
        setFormData({
          registerId: selected._id,
          drawingNumber: selected.drawingNo || "",
          drawingName: selected.drawingTitle || "",
          category: selected.category.category || "", // Ensure the category field is properly accessed
          designDrawingConsultant: `${selected.designDrawingConsultant?.role}`,
          acceptedArchitectDate: selected.acceptedArchitectDate
            ? new Date(selected.acceptedArchitectDate).toLocaleDateString()
            : "",
          revisions: combinedRevisions,
          submittedDate: todayDate(),
          // dueDays: 0,
          // issuedSoftCopy: selected.issuedSoftCopy,
          // receivedSoftCopy: selected.receivedSoftCopy,
          // drawingFileName: selected.drawingFileName
        });
        setOpen(true);
      }
    };
    const handleChange = (e) => {
      const { name, value, type, files } = e.target;
  
      if (type === "file") {
        const file = files[0];
        setFormData({
          ...formData,
          [name]: files[0], // Assuming you are only uploading one file
        });
        return;
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    };
  
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };
  
    const handleViewOpen = (drawingItem) => {
      console.log(drawingItem,"drawingItem in Recieved pending table")
      setSingleDrawingData(drawingItem);
      // if (drawingItem.acceptedSiteHeadHardCopyRevisions.length > 0) {
      //   setSelectedTab(drawingItem.acceptedSiteHeadHardCopyRevisions.length - 1);
      // }
      setViewDialogOpen(true);
    };
  
    // const handleViewOpen = (drawingItem) => {
    //   setSingleDrawingData(drawingItem);
    //   setViewDialogOpen(true);
    // };
  
    const handleViewClose = () => {
      setSingleDrawingData(null);
      setViewDialogOpen(false);
    };
  
    const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);
  
    const fetchInData = async () => {
      if (registerInfo && registerInfo.length > 0) {
        setDrawingList(registerInfo);
        setInitialDrawingList(registerInfo);
        console.log("fetched from recoil", registerInfo);
      } else {
        await updateRegister(setRegisterInfo, site).then((response) => {
          if (response.status === SUCESS_STATUS) {
            setDrawingList(response.registers);
            setInitialDrawingList(response.registers);
          }
        });
      }
    };
  
    const fetchData = async () => {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    };
  
    useEffect(() => {
      fetchInData();
    }, []);
  
    useEffect(() => {
      fetchData();
      setReloadData(false);
    }, [reloadData === true]);
  
    const handleDownload = async (id, revision) => {
      setApiProcessing({
        loader: true,
        message: "Downloading...",
      });
      try {
        // Request the file with binary response handling
        const response = await webApi.getImage(
          `api/architectureToRoRegister/${id}/RoHardCopy/${revision}`,
          {
            responseType: "arraybuffer", // Expect binary data
          }
        );
  
        processFile(response, "download", navigate); // Process file for downloading
      } catch (error) {
        console.error("Error downloading file:", error);
      } finally {
        setApiProcessing({ loader: false, message: "" });
      }
    };
  
    const handleViewFile = async (id, revision) => {
      setApiProcessing({
        loader: true,
        message: "opening...",
      });
      try {
        // Make the request to get the response based on the updated endpoint
        const response = await webApi.getImage(
          `api/architectureToRoRegister/${id}/RoHardCopy/${revision}`,
          {
            responseType: "arraybuffer", // Expect binary data
          }
        );
  
        processFile(response, "view", navigate); // Process file for downloading
      } catch (error) {
        console.error("Error downloading file:", error);
      } finally {
        setApiProcessing({ loader: false, message: "" });
      }
    };
  
    const filteredDrawingList = drawingList
      .filter(
        (item) =>
          (filterCondition === "approved"
            ? item.acceptedSiteHeadHardCopyRevisions.length > 0
            : item.acceptedSiteHeadHardCopyRevisions.length === 0) &&
          (item.drawingNo?.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.drawingTitle
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.designDrawingConsultant?.role
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            (item.category && typeof item.category === "string"
              ? item.category.toLowerCase().includes(searchValue.toLowerCase())
              : false))
        // (item.acceptedSiteHeadHardCopyRevisions[0]?.hardCopySubmittedDate && typeof item.acceptedSiteHeadHardCopyRevisions[0].hardCopySubmittedDate === 'string' ? item.acceptedSiteHeadHardCopyRevisions[0].hardCopySubmittedDate.toLowerCase().includes(searchValue.toLowerCase()) : false) ||
        // (item.acceptedSiteHeadHardCopyRevisions[0]?.receivedCopies && typeof item.acceptedSiteHeadHardCopyRevisions[0].receivedCopies === 'string' ? item.acceptedSiteHeadHardCopyRevisions[0].receivedCopies.toLowerCase().includes(searchValue.toLowerCase()) : false) ||
        // (item.acceptedSiteHeadHardCopyRevisions[0]?.revision && typeof item.acceptedSiteHeadHardCopyRevisions[0].revision === 'string' ? item.acceptedSiteHeadHardCopyRevisions[0].revision.toLowerCase().includes(searchValue.toLowerCase()) : false)
      )
      .sort((a, b) => {
        const consultantA = a.designDrawingConsultant?.role
          ? a.designDrawingConsultant?.role.toLowerCase()
          : "";
        const consultantB = b.designDrawingConsultant?.role
          ? b.designDrawingConsultant?.role.toLowerCase()
          : "";
        return consultantA.localeCompare(consultantB); // Sort alphabetically
      });
  
      useEffect(()=>{
        const fetchDrawingTableData =async (site)=>{
            try {
              const response = await webApi.get(`api/architect/pendingRo/${site}?filterType=received`)
              if(response.status === 200){
                const filterData =response.data.data.rOHardCopyRevisions.filter((item)=>item?.acceptedArchitectRevisions.length && item?.acceptedROHardCopyRevisions.length === 0)
                setDrawingTableList([...filterData,...response.data.data.siteHeadHardCopyRevisions])
              }
              console.log(response,"RO tabs tableData")
            } catch (error) {
              console.log(error.message)
            }
        
        
        }
    
        fetchDrawingTableData(site)
      },[site])
    
      useEffect(()=>{
        console.log(drawingTableList,"drawingTableList")
        
      
      },[drawingTableList])

      const uniqueConsultant = useMemo(() => {
        const consultant = drawingTableList.map((item) => item.designDrawingConsultant?.role);
        return [...new Set(consultant)];
      }, [drawingTableList]);

      const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const [tableRowData, setTableRowData] = useState([])
  const [editDialogOpen, setEditDialogOpen] = useState(false)

  const handlePenClick = (drawingItem) =>{
    setTableRowData(drawingItem)
    setEditDialogOpen(true)
  }
  const handleDialogClose = () =>{
    setEditDialogOpen(false)
    setFormData({
      registerId: "",
      drawingNumber: "",
      drawingName: "",
      category: "",
      designDrawingConsultant: "",
      acceptedArchitectDate: "",
      // actualSubmissionDate: todayDate(),
      // dueDays: '',
      // remarks:'',
      submittedDate: "",
      issuedSoftCopy: "",
      receivedSoftCopy: "",
      drawingDWGFileName: null,
      receivedCopies: "",
      revisions: [],
      selectedRevision: "",
    })
  }
  useEffect(()=>{
    if(Object.keys(tableRowData).length){
      setFormData({
        registerId: tableRowData?.drawingId,
    drawingNumber: tableRowData?.drawingNo,
    drawingName: tableRowData?.drawingTitle,
    category: tableRowData?.category?.category,
    folder:tableRowData?.folderId?.folderName,
    designDrawingConsultant: tableRowData?.designDrawingConsultant?.role,
    submittedDate: "",
    receivedHardCopy: "",
    drawingDWGFileName: null,
    drawingPDFFileName:null,
    revisions: '',
    acceptedArchitectRevisions: [],
    note: "",
    selectedRevision: "",
      })
    }
    
  },[tableRowData,site])

  const updatedRows = drawingTableList.filter((item)=>item.designDrawingConsultant?.role.includes(consultantValue)).map((row, index) => ({
    ...row,
    serialNo: index + 1, 
    drawingNo: row.drawingNo,
    consultant: row.designDrawingConsultant?.role || "",
    from:row?.for === "architect" ? "RO" : row?.for,
    category: row.category?.category || "",
    drawingTitle: row?.drawingTitle || "",
  }));

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = useMemo(()=>[
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 1,
    },
    {
      field: "drawingNo",
      headerName: "DRWG.No",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "consultant",
      headerName: "Consultant",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "from",
      headerName: "From",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "category",
      headerName: "Drawing Category",
      flex: 1,
      filterable: true,
      sortable: true, 
    },
    {
      field: "drawingTitle",
      headerName: "Drawing Title",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start",marginTop:"24px" }}>
          {params.row.for === "architect" && (
            <img
              src={Pen}
              alt="PenIcon"
              style={{ cursor: "pointer" }}
              onClick={() => handlePenClick(params.row)}
            />
          )}
        </div>
      ),
    },
  ],[drawingTableList]);



  

  return (
    <div>
      <Box
        className="dataGridContainer"
      >
        <ThemeProvider theme={theme}>
        <DataGrid
          columns={columns}
          rows={filteredRows}
          getRowId={(row) => row.serialNo}
          rowHeight={60}
          disableColumnMenu={false} 
          pagination={false}
          hideFooter={true}
          getRowSpacing={params=>({
            top:params.isFirstVisible ? 0 : 5,
            bottom:params.isLastVisible ? 0 : 5
          })}
          
          getRowClassName={(params) =>
            "customHeader" 
          }
          
        />
        </ThemeProvider>
      </Box>

      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 10,
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height:'50px'
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0 ? (
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="transparent" // Hides the default indicator
                textColor="inherit" // Ensures text color is handled manually
                style={{ backgroundColor: "transparent" }} // Ensures background is transparent
              >
                {singleDrawingData.acceptedSiteHeadHardCopyRevisions.map(
                  (revisionItem, index) => (
                    <Tab
                      key={index}
                      label={`${revisionItem.revision}`}
                      style={{
                        backgroundColor:
                          singleDrawingData.acceptedSiteHeadHardCopyRevisions
                            .length === 1 || selectedTab === index
                            ? "var(--primary-color)"
                            : "transparent",
                        color:
                          singleDrawingData.acceptedSiteHeadHardCopyRevisions
                            .length === 1 || selectedTab === index
                            ? "white"
                            : "var(--primary-color)",
                        width: "150px",
                        height: "35px",
                        cursor: "pointer",
                        borderRight:
                          index <
                          singleDrawingData.acceptedSiteHeadHardCopyRevisions.length -
                            1
                            ? "1px solid var(--primary-color)"
                            : "none",
                        // borderRadius: index === 0 && !isSingleRevision ? '5px 0 0 5px' : (index === singleDrawingData.acceptedRORevisions.length - 1 && !isSingleRevision ? '0 5px 5px 0' : 'none'),
                      }}
                    />
                  )
                )}
              </Tabs>
            ) : (
              <div>No Revisions</div>
            )}
          </div>
        </div>
        {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedTab ===
                  singleDrawingData.acceptedSiteHeadHardCopyRevisions.length - 1 ? (
                    <div style={{ color: "green" }}>Good For Construction</div>
                  ) : (
                    <div style={{ color: "red" }}>Superseeded</div>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px",maxHeight: "80vh", overflowY: "auto", }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.designDrawingConsultant?.role || "NA"}
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>

              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>

              <div>Submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.acceptedSiteSubmissionDate
                  ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              <div>Hard Copy Submitted Date</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0
                  ? formatDate(
                      singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]
                        ?.hardCopySubmittedDate
                    ) || "-"
                  : "-"}
              </div>

              <div>Received Copies</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0
                  ? singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]
                      ?.receivedCopies || "-"
                  : "-"}
              </div>

              {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedSiteHeadHardCopyRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-130px" }}>
                        {singleDrawingData.acceptedSiteHeadHardCopyRevisions[
                          selectedTab
                        ].revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}

              {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0 && (
                <>
                  <div>Drawing File</div>
                  <div>:</div>
                  <div style={{ display: "flex", marginLeft: "-130px" }}>
                    <Icon.Image
                      size={20}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        handleViewFile(
                          singleDrawingData._id,
                          singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]
                            ?.revision
                        )
                      }
                    />
                    <Icon.Download
                      size={20}
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() =>
                        handleDownload(
                          singleDrawingData._id,
                          singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]
                            ?.revision
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>

      {/* <Dialog open={viewDialogOpen} onClose={handleViewClose} maxWidth="md" fullWidth PaperProps={{style: {borderRadius: 20, fontFamily: 'Poppins', background: '#FFF', boxShadow: '0px 0px 10px 0px #F3F3F3'}}}>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#4D4D4D', margin: '0px' }}>
    <p style={{ margin: '5px 400px', textAlign: 'center', color: 'white' }}>View</p>
    <RxCross1
      size={20}
      style={{
        marginLeft: "auto",
        cursor: "pointer",
        marginRight: "20px",
        color: 'white',
      }}
      onClick={handleViewClose}
    />
  </div>

  <DialogContent style={{ padding: '25px', minWidth: '300px', minHeight: '300px' }}>
  <Loader open={apiProcessing.loader} message={apiProcessing.message} />
    <div style={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '20px',
      padding: '20px',
      color: '#4D4D4D',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '28px',
      justifyContent: 'center',
      marginLeft: '80px'
    }}>
      <div>Drawing Number</div>
      <div>:</div>
      <div>{singleDrawingData?.drawingNo || 'NA'}</div>

      <div>Design Consultant</div>
      <div>:</div>
      <div>{singleDrawingData?.designDrawingConsultant?.designDrawingConsultant || 'NA'}</div>

      <div>Category</div>
      <div>:</div>
      <div>{singleDrawingData?.category?.category || 'NA'}</div>

      <div>Drawing Title</div>
      <div>:</div>
      <div>{singleDrawingData?.drawingTitle || 'NA'}</div>

      <div>Submission date</div>
      <div>:</div>
      <div>{singleDrawingData?.acceptedSiteSubmissionDate ? new Date(singleDrawingData.acceptedSiteSubmissionDate).toISOString().split('T')[0] : 'NA'}</div>

      <div>Hard Copy Submitted Date</div>
      <div>:</div>
      <div>
        {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0
          ? formatDate(singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]?.hardCopySubmittedDate) || 'N/A'
          : 'N/A'}
      </div>

      <div>Received Copies</div>
      <div>:</div>
      <div>
        {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0
          ? singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]?.receivedCopies || 'N/A'
          : 'N/A'}
      </div>

      {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0 && (
        <>
          <div>Revision</div>
          <div>:</div>
          <div>{singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]?.revision || 'NA'}</div>
        </>
      )}

      {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length > 0 && (
        <>
          <div>Drawing File</div>
          <div>:</div>
          <div style={{ display: 'flex' }}>
            <Icon.Image size={20} style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleViewFile(singleDrawingData._id, singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]?.revision)} />
            <Icon.Download size={20} style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleDownload(singleDrawingData._id, singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]?.revision)} />
          </div>
        </>
      )}
    </div>
  </DialogContent>
</Dialog> */}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {/* <button
            onClick={() => {
              setCurrentTab('upload')
            }}
            style={{
              backgroundColor: currentTab === 'upload' ? 'var(--primary-color)': 'transparent',
              color: currentTab === 'upload' ? 'white' : 'var(--primary-color)',
              width: '150px',
              height: '35px',
              cursor: 'pointer',
              borderRight: '1px solid var(--primary-color)', 
             
            }}
          >
            Upload
          </button> */}

            <button
              onClick={() => setCurrentTab("received")}
              style={{
                background:
                  currentTab === "received"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "received" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Received
            </button>
          </div>
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px",maxHeight: "80vh", overflowY: "auto", }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <section
              style={{
                marginLeft: "80px",
                marginRight: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "50px",
                  width: "80%",
                }}
              >
                <div>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing No
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="drawingNumber"
                    name="drawingNumber"
                    value={formData.drawingNumber}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />

                  {errors.drawingNumber && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingNumber}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="drawingName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Title
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="drawingName"
                    name="drawingName"
                    value={formData.drawingName}
                    onChange={handleChange}
                    placeholder="Enter your drawing title"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.drawingName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingName}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Category
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    placeholder="Enter your Drawing Category"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.category && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.category}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="designDrawingConsultant"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Design Consultant
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="designDrawingConsultant"
                    name="designDrawingConsultant"
                    value={formData.designDrawingConsultant}
                    onChange={handleChange}
                    placeholder="Enter your  Design Consultant"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.designDrawingConsultant && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.designDrawingConsultant}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="revisions"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Revisions
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    id="revisions"
                    name="revisions"
                    value={formData.revisions}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  >
                    <option value="">Select Revision</option>
                    {Object.keys(tableRowData).length && tableRowData?.acceptedArchitectRevisions.map((revision, index) => (
                      <option key={index} value={revision.revision}>
                        {revision.revision}
                      </option>
                    ))}
                  </select>
                  {errors.revisions && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.revisions}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="receivedCopies"
                    className="fs-10 fw-bold text-start py-2
              "
                  >
                    No of Copies Received
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    id="receivedCopies"
                    name="receivedCopies"
                    placeholder="Enter No of Received Copies"
                    value={formData.receivedCopies}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                </div>

                <div>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Upload Your Drawing File
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg, .png"
                    id="drawingFileName"
                    name="drawingFileName"
                    onChange={handleChange}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: '43px'  }}
                  />
                  {errors.drawingFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingFileName}
                    </p>
                  )}
                </div>
              </div>
            </section>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "45px",
              }}
            >
              <button
                style={{
                  padding: "10px",

                  border: "1px solid lightgray",
                  backgroundColor: "transparent",
                  width: "100px",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "lightgray")
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
                onClose={handleDialogClose}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                style={{
                  marginLeft: "50px",
                  padding: "10px",
                 
                  border: "1px solid var(--primary-color)",
                  width: "100px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </>
        </DialogContent>
      </Dialog>
      <Dialog
      open={editDialogOpen}
      onClose={handleDialogClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: " #4D4D4D",
          margin: "0px",
          height:'50px',
          position:"relative"
        }}
      >
        <p style={{width:"100%",marginTop:"15px", textAlign: "center", color: "white" }}>
          Drawing
        </p>
        <RxCross1
          size={20}
          style={{
            position:"absolute",
            right:"10px",
            cursor: "pointer",
            color: "white",
          }}
          onClick={handleDialogClose}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0",
            border: "1px solid var(--primary-color)",
          }}
        >
           

           <button
            
            style={{
              background:
                 "var(--primary-color)"
                  ,
              color:
                 "white",
              width: "150px",
              height: "35px",
              cursor: "pointer",
            }}
          >
            Received
          </button>
        </div>
      </div>
      <DialogContent
        style={{ padding: "25px", minWidth: "300px", minHeight: "300px",maxHeight: "70vh", overflowY: "auto", }}
      >
        <>
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
                width: "80%",
              }}
            >
              <div>
                <label
                  htmlFor="designDrawingConsultant"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Design Consultant
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input type="text" name="designDrawingConsultant" value={formData?.designDrawingConsultant} className="form-control fs-12" style={{width:"100%"}} onChange={handleChange} readOnly/>
                
                {/* <select
    id="designDrawingConsultant"
    name="designDrawingConsultant"
    value={formData.designDrawingConsultant}
    onChange={handleChange}
    className="form-control fs-12"
    style={{ width: '100%' }}
  >
    <option value="">Select Design Consultant</option>
    {Array.from(new Set(registerList.map(register => register.designDrawingConsultant?.designDrawingConsultant)))
      .filter(Boolean)
      .map((consultant, index) => (
        <option key={index} value={consultant}>{consultant}</option>
    ))}
  </select> */}
                {errors.designDrawingConsultant && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.designDrawingConsultant}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingNumber"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing No
                  <span style={{ color: "red" }}>*</span>
                </label>
                {/* <select
  id="drawingNumber"
  name="drawingNumber"
  value={formData.drawingNumber}
  onChange={handleChange}
  className="form-control fs-12"
  style={{ width: '100%' }}
>
  <option value="">Select Drawing No</option>
  {filteredDrawingOptions.map(register => (
    <option key={register._id} value={register.drawingNo}>{register.drawingNo}</option>
  ))}
</select> */}
                <input className= "form-control fs-12" name="drawingNumber" value={formData.drawingNumber} style={{width:"100%"}} readOnly/>

                {errors.drawingNumber && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingNumber}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Title
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="drawingName"
                  name="drawingName"
                  value={formData.drawingName}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.drawingName && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingName}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="category"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Category
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.category && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.category}
                  </p>
                )}
              </div>
              
                <div>
                  <label
                    htmlFor="receivedCopies"
                    className="fs-10 fw-bold text-start py-2
              "
                  >
                    No of Copies Received
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    id="receivedCopies"
                    name="receivedCopies"
                    placeholder="Enter No of Received Copies"
                    value={formData.receivedCopies}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                  {errors.receivedCopies && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.receivedCopies}
                    </p>
                  )}
                </div>
              
                {showAddFolder && <div>
                      <label
                        htmlFor="folder"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Folder
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                    type="text"
                    id="folder"
                    name="folder"
                    value={formData.folder}
                    onChange={handleChange}
                    placeholder="Enter your  Folder "
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                      {errors.folder && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.folder}
                        </p>
                      )}
                </div>}
              <div>
                <label
                  htmlFor="revisions"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Select Revisions<span style={{ color: "red" }}>*</span>
                </label>

                <select
                  
                  name="revisions"
                  value={formData.revisions}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                >
                 
                    {Object.keys(tableRowData).length ? (
                      <>
                        <option value="">Select Revision</option>
                        {tableRowData.acceptedArchitectRevisions.map((rev, index) => (
                          <option key={index} value={rev?.revision}>
                            {rev?.revision}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option value="">No soft copy revisions available</option>
                    )}
                  
                </select>
                {errors.revisions && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.revisions}
                  </p>
                )}
              </div>

              

              

             
                <>
                <div>
                  <label
                    htmlFor="drawingDWGFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    id="drawingDWGFileName"
                    name="drawingDWGFileName"
                    onChange={(e) => 
                        handleChange(e)
                      }
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: '43px' }}
                  />
                  {errors.drawingDWGFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingDWGFileName}
                    </p>
                  )}
                </div>

                {/* <div>
                  <label
                    htmlFor="drawingPDFFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload PDF file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    id="drawingPDFFileName"
                    name="drawingPDFFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.name.endsWith(".pdf")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .pdf file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: '43px'  }}
                  />
                  {errors.drawingPDFFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingPDFFileName}
                    </p>
                  )}
                </div> */}
                </>
              
              
            </div>
          </section>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              style={{
                padding: "10px",
            
                border: "1px solid lightgray",
                backgroundColor: "transparent",
                width: "100px",
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = "lightgray")
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = "transparent")
              }
              onClick={handleDialogClose}
            >
              Cancel
            </button>
            <button

              style={{
                marginLeft: "50px",
                padding: "10px",
              
                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleFormSubmit}
            >
              Submit
            </button>
          </div>
        </>
      </DialogContent>
    </Dialog>
    <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue}/>
    </div>
  )
}

export default PendingRecievedTable