import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import styles from "./Design.module.css";
import CheckListTable from "../ChecklistTable";
import { Dialog, DialogContent } from "../../Dialog/Dialog";

const ViewDialog = (props) => {
  const { open, handleDialogClose, formData } = props;
  console.log(formData,"formData in view")
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const handleClose = () => {
    handleDialogClose();
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 550px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "500px" }}
        >
          <div className={styles.overall}>
            <div>
              <p className={styles.heading}>
                {formData?.companyId ? ` ${formData.companyId.companyDetails.companyName}` : 'No Company Data'}
              </p>
            </div>
            <div className={styles.second}>
              <div className={styles.grid}>
                <p style={{fontSize:'16px',fontWeight:'500'}}>Activity Name</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.activity?.activity || 'No Activity Data'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Checklist Type</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.checklistType || 'No Checklist Type'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Form No</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.formNo || 'No Form No'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Departments</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.department?.join(', ') || 'No Departments'}</p>
              </div>
            </div>
{  formData?.descriptionDetails?.length > 0 && (
            <div>
              <CheckListTable
                CheckListTableHead={
                  <tr className="HeaderTableRow">
                    <th className="CheckListTableTableHead" style={{ width: "5%" }}>
                      S.No
                    </th>
                    <th className="CheckListTableTableHead" style={{ width: "95%" }}>
                      Description
                    </th>
                  </tr>
                }
                CheckListTableBody={
                  formData?.descriptionDetails?.map((desc, index) => (
                    <tr
                      key={index}
                      className="ValueTableRow"
                      style={{
                        borderBottom: "1px dotted #97979740",
                        paddingBottom: "8px",
                      }}
                    >
                      <td className="CheckListTableTableData" style={{ width: "5%" }}>
                        {index + 1}
                      </td>
                      <td className="CheckListTableTableData" style={{ width: "95%" }}>
                        {desc.description || 'No Description'}
                      </td>
                    </tr>
                  )) || (
                    <tr>
                      <td colSpan="2">No Description Details Available</td>
                    </tr>
                  )
                }
              />
            </div>
)}
            <div className={styles.last}>
  {formData?.assigningIncharge?.length > 0 && (
    <>
      <p>Signature Of</p>
      <div className={styles.assignednames}>
        {formData.assigningIncharge.map((incharge, index) => (
          <p key={index}>
            {incharge.department ? incharge.department : 'No Department'} -{" "}
            {incharge.role ? incharge.role : 'No Role'}
          </p>
        ))}
      </div>
    </>
  )}
</div>

          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewDialog;
