/* eslint-disable react/no-children-prop */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import SelectSite from "../../../Utilities/SelectSite";
import { RxCross1 } from "react-icons/rx";
import TableWrapper from "../../../Utilities/TableWrapper";
import {
  userInfo,
  siteId,
  registerAtom,
  atororegisterTab,
} from "../../../atoms";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import { canShowFolder } from "../ShowFolder";
import SortByPopup from "../SortByPopup";
import ArrowToggle from "../ArrowToggle";
import { BiSortAlt2 } from "react-icons/bi";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", 
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", 
          },
        },
      },
    },
  },
});

const RegisterTable = () => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  let webApi = new WebApimanager();
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo, setUserInfo] = useRecoilState(userInfo);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const [filters, setFilters] = useState({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
  });

  // sort by start
  const [sortField, setSortField] = useState(""); // Field to sort by
  const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
  const [activeSortPopup, setActiveSortPopup] = useState(null);

  const handleSortClick = (field) => {
    setFilters({
      drawingNo: "",
      consultant: "",
      category: "",
      drawingTitle: "",
      scheduledDate: "",
      submissionDate: "",
      revision: "",
    });
    setSearchValue("");
    setActiveSortPopup((prev) => (prev === field ? null : field));
  };

  const handleSortSelection = (order) => {
    if (activeSortPopup) {
      setSortField(activeSortPopup);
      setSortOrder(order);
      setActiveSortPopup(null); // Close the popup after selection
    }
  };

  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
    
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    })
    setSortField("");
    setSortOrder("");
    setActiveSortPopup(null);
  }, []);
  
  const handleFilterChange = (column, value) => {
    setSearchValue("")
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
    setFilters((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = ""; 
          return acc;
      }, {}),
      [column]: value, 
  }));
    
  };

  const fetchData = async () => {
    try {
      let response = await webApi.get(`api/architect/register/${site}`);
      setInitialDrawingList(response.data.data.filteredData);
      setDrawingList(response.data.data.filteredData);
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (!searchActive) {
      fetchData();
    }
  }, [searchActive]);

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [existingCategories, setExistingCategories] = useState([]);
  const [existingConsultants, setExistingConsultants] = useState([]);
  const [currentDrawingId, setCurrentDrawingId] = useState("");
  const [formData, setFormData] = useState({
    DrawingNumber: "",
    DrawingTitle: "",
    DesignConsultant: "",
    DrawingCategory: "",
    AcceptedROSubmissionDate: "",
    AcceptedSiteSubmissionDate: "",
  });

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const getAll = () => {
    webApi.get("api/category/getAll").then((data) => {
      if (data) {
        setExistingCategories(data.data.data.categories);
      }
    });

    webApi
      .get(`api/designDrawingConsultant/getAll?siteId=${site}`)
      .then((data) => {
        if (data) {
          setExistingConsultants(data.data.data.consultants);
        }
      });
  };

  useEffect(() => {
    if (
      existingCategories &&
      existingCategories.length === 0 &&
      existingConsultants.length === 0
    ) {
      getAll();
    }
  }, []);

  const handleEdit = (drawingItem) => {
    setCurrentDrawingId(drawingItem._id);
    setFormData({
      DrawingNumber: drawingItem.drawingNo,
      DrawingTitle: drawingItem.drawingTitle,
      DrawingCategory: drawingItem.category
        ? drawingItem?.category?.category
        : "", // Ensure this is the correct property
      DesignConsultant: `${drawingItem.designDrawingConsultant.role}`,
      AcceptedROSubmissionDate: drawingItem.acceptedROSubmissionDate
        ? drawingItem.acceptedROSubmissionDate.split("T")[0]
        : "",
      AcceptedSiteSubmissionDate: drawingItem.acceptedSiteSubmissionDate
        ? drawingItem.acceptedSiteSubmissionDate.split("T")[0]
        : "",
    });
    handleOpen();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    const tempFormData = {
      siteId: site,
      // drawingNo:  formData.DrawingNumber,
      drawingTitle: formData.DrawingTitle,
      // designDrawingConsultant: formData.DesignConsultant,
      // category: formData.DrawingCategory,
      acceptedROSubmissionDate: formData.AcceptedROSubmissionDate,
      acceptedSiteSubmissionDate: formData.AcceptedSiteSubmissionDate,
    };
    try {
      const response = await webApi.put(
        `api/architectureToRoRegister/update/${currentDrawingId}`,
        tempFormData
      );
      if (response.status === 200) {
        fetchData();
        handleClose();
        setApiProcessing({ loader: false, message: "" });
        // window.location.reload();
      } else if (response.status === 500 || response.status === 400) {
        setApiProcessing({ loader: false, message: "" });
        // Check if the response contains an error message array
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          // If the specific message is not found, set a generic error message
          setErrorMessage("Please Try After Some Time");
        }
      } else {
        // If the specific message is not found, set a generic error message
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      // Handle errors that occur during the request
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data) &&
        error.response.data.length > 0
      ) {
        setErrorMessage(error.response.data[0]); // Set the first error message
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [trashOpen, setTrashOpen] = useState(false);
  const [trashData, setTrashData] = useState({
    uniquePassword: "",
  });
  const handleTrashOpen = (drawingItem) => {
    setTrashOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTrashData({
      ...trashData,
      [name]: value,
    });
  };
  const handleTrashClose = () => {
    setTrashOpen(false);
  };
  const handleTrashSubmit = async (id) => {
    try {
      const response = await webApi.delete(``);
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };

  useEffect(() => {
    if (searchValue === "") {
      fetchData(); // This fetches all drawings again
      setSearchActive(false);
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        if (drawingItem) {
          const drawingNumber = drawingItem.drawingNo
            ? drawingItem.drawingNo.toLowerCase()
            : "";
          const drawingName = drawingItem.drawingTitle
            ? drawingItem.drawingTitle.toLowerCase()
            : "";
          const consultant =
            drawingItem.designDrawingConsultant?.role?.toLowerCase() || "";
          const category =
            drawingItem.category && drawingItem.category.category
              ? drawingItem.category.category.toLowerCase()
              : "";
          const acceptedROSubmissionDate = drawingItem.acceptedROSubmissionDate
            ? new Date(
                drawingItem.acceptedROSubmissionDate
              ).toLocaleDateString()
            : "";
          const acceptedSiteSubmissionDate =
            drawingItem.acceptedSiteSubmissionDate
              ? new Date(drawingItem.acceptedSiteSubmissionDate).toDateString()
              : "";

          return (
            drawingNumber.includes(searchValue) ||
            drawingName.includes(searchValue) ||
            consultant.includes(searchValue) ||
            category.includes(searchValue) ||
            acceptedROSubmissionDate.includes(searchValue) ||
            acceptedSiteSubmissionDate.includes(searchValue)
          );
        } else {
          return false;
        }
      });
      setDrawingList(filteredDrawings);
    }
  }, [searchValue]);

  // const searchResult = (e) => {
  //   const searchInput = e.target.value.trim().toLowerCase();
  //   setSearchValue(searchInput);
  //   setSearchActive(true);

  //   if (searchInput === "") {
  //     setDrawingList(initialDrawingList);
  //     setSearchActive(false);
  //   } else {
  //     const filteredDrawings = initialDrawingList.filter((drawingItem) => {
  //       if (drawingItem) {
  //         const drawingNumber = drawingItem.drawingNo
  //           ? drawingItem.drawingNo.toLowerCase()
  //           : "";
  //         const drawingName = drawingItem.drawingTitle
  //           ? drawingItem.drawingTitle.toLowerCase()
  //           : "";
  //         const consultant =
  //           drawingItem.designDrawingConsultant?.role?.toLowerCase() || "";
  //         const category = drawingItem.category?.category?.toLowerCase() || "";
  //         const acceptedROSubmissionDate = drawingItem.acceptedROSubmissionDate
  //           ? new Date(drawingItem.acceptedROSubmissionDate)
  //               .toLocaleDateString()
  //               .toLowerCase()
  //           : "";
  //         const acceptedSiteSubmissionDate =
  //           drawingItem.acceptedSiteSubmissionDate
  //             ? new Date(drawingItem.acceptedSiteSubmissionDate)
  //                 .toLocaleDateString()
  //                 .toLowerCase()
  //             : "";

  //         return (
  //           drawingNumber.includes(searchInput) ||
  //           drawingName.includes(searchInput) ||
  //           consultant.includes(searchInput) ||
  //           category.includes(searchInput) ||
  //           acceptedROSubmissionDate.includes(searchInput) ||
  //           acceptedSiteSubmissionDate.includes(searchInput)
  //         );
  //       }
  //       return false;
  //     });
  //     setDrawingList(filteredDrawings);
  //   }
  // };
  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const rowsWithSerialNumbers = drawingList.map((row, index) => ({
    ...row,
    serialNo: index + 1, 
    drawingNo: row.drawingNo,
    consultant: row.designDrawingConsultant?.role,
    category: row.category?.category ,
    drawingTitle: row.drawingTitle ,
    createdDate: formatDate(row.creationDate),
    scheduledDate: formatDate(row.acceptedROSubmissionDate),
    acceptedSiteSubmissionDate: formatDate(row.acceptedSiteSubmissionDate)
  }));

  const filteredRows = rowsWithSerialNumbers.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = useMemo(
    () => [
      {
        field: "serialNo",
        headerName: "S.No",
        flex: 0.5,
        filterable: false,
        sortable: false,
      },
      {
        field: "drawingNo",
        headerName: "Drawing No",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "consultant",
        headerName: "Consultant",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "category",
        headerName: "Drawing Category",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "drawingTitle",
        headerName: "Drawing Title",
        flex: 2,
        filterable: true,
        sortable: true,
        
      },
      {
        field: "createdDate",
        headerName: "Created Date",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "scheduledDate",
        headerName: "Scheduled Date",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "acceptedSiteSubmissionDate",
        headerName: "Accepted site submission date",
        flex: 1,
        filterable: true,
        sortable: true,
      },
    ],
    [drawingList]
  );
  
  if (site === "") {
    return <SelectSite />;
  }
  
  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#131313",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                Drawing Register
              </p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "280px",
                      paddingLeft: "5px",
                      height: "50px",
                    }}
                  >
                    <input
                      placeholder="Search Drawings"
                      onChange={handleSearchChange}
                      value={searchValue}
                      style={{
                        paddingLeft: "20px",
                        border: "1px solid var(--primary-color)",
                        width: "250px",
                        height: "40px",
                      }}
                    />
                    <Icon.Search
                      style={{
                        position: "absolute",
                        right: "25px",
                        top: "40%",
                        transform: "translateY(-50%)",
                        color: "#ACACAC",
                      }}
                    />
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <Box
                className="dataGridContainer"
              >
                <ThemeProvider theme={theme}>
                <DataGrid
                  columns={columns}
                  rows={filteredRows}
                  getRowId={(row) => row.serialNo}
                  rowHeight={60}
                  disableColumnMenu={false} 
                  pagination={false}
                  hideFooter={true}
                  getRowSpacing={params=>({
                    top:params.isFirstVisible ? 0 : 5,
                    bottom:params.isLastVisible ? 0 : 5
                  })}
                  
                  getRowClassName={(params) =>
                    "customHeader" 
                  }
                  
                  
                />
                </ThemeProvider>
              </Box>
            </div>
          </>
        }
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
                width: "80%",
              }}
            >
              {errorMessage && (
                <div style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </div>
              )}
              <div>
                <label
                  htmlFor="DrawingNumber"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing No
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="DrawingNumber"
                  name="DrawingNumber"
                  value={formData.DrawingNumber}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />

                {errors.DrawingNumber && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DrawingNumber}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="DrawingTitle"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Title
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="DrawingTitle"
                  name="DrawingTitle"
                  value={formData.DrawingTitle}
                  onChange={handleChange}
                  placeholder="Enter your drawing title"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                {errors.DrawingTitle && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DrawingTitle}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="DrawingCategory"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Category
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="DrawingCategory"
                  name="DrawingCategory"
                  value={formData.DrawingCategory}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />

                {/* <select
                  id="DrawingCategory"
                  name="DrawingCategory"
                  value={formData.DrawingCategory}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: '100%' }}
                >
                  <option value="">Select Category</option>
                  {existingCategories.map((category) => (
                    <option key={category._id} value={category._id}>{category.category}</option>
                  ))}
                </select> */}
                {errors.DrawingCategory && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DrawingCategory}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="DesignConsultant"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Design Consultant <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="DesignConsultant"
                  name="DesignConsultant"
                  value={formData.DesignConsultant}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {/* <select
                  id="DesignConsultant"
                  name="DesignConsultant"
                  value={formData.DesignConsultant}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: '100%' }}
                  readOnly
                >
                  <option value="">Select Consultant</option>
                  {existingConsultants.map((consultant) => (
                    <option key={consultant._id} value={consultant._id}>{consultant.designDrawingConsultant}</option>
                  ))}
                </select> */}
                {errors.DesignConsultant && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DesignConsultant}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="AcceptedROSubmissionDate"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Accepted RO Submission Date
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="AcceptedROSubmissionDate"
                  value={formData.AcceptedROSubmissionDate}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />

                {errors.AcceptedROSubmissionDate && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.AcceptedROSubmissionDate}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor=" AcceptedSiteSubmissionDate"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Accepted Site SubmissionDate
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="AcceptedSiteSubmissionDate"
                  value={formData.AcceptedSiteSubmissionDate}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                {errors.AcceptedSiteSubmissionDate && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.AcceptedSiteSubmissionDate}
                  </p>
                )}
              </div>
            </div>
          </section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              style={{
                marginLeft: "50px",
                padding: "10px",
                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={trashOpen}
        onClose={handleTrashClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{ margin: "5px 250px", textAlign: "center", color: "white" }}
          >
            Delete
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleTrashClose}
          />
        </div>

        <DialogContent
          style={{
            padding: "25px",
            minWidth: "auto",
            minHeight: "auto",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <label
                htmlFor="uniquePassword"
                className="fs-10 fw-bold text-start py-2"
              >
                Password
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                id="uniquePassword"
                name="uniquePassword"
                value={trashData.uniquePassword}
                onChange={handleInputChange}
                type="password"
                className="form-control fs-12"
                style={{ width: "100%" }}
              />

              {errors.uniquePassword && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {errors.uniquePassword}
                </p>
              )}
            </div>
          </section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "50px",
                padding: "10px",

                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleTrashSubmit}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegisterTable;
