

import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { RxCross1 } from "react-icons/rx";
import styles from "./Design.module.css";
import Add from "../../../Images/Add.svg";
import Sub from "../../../Images/subtration.svg";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import { Dialog, DialogContent } from "../../Dialog/Dialog";

const Create = ({open, handleDialogClose, existingData,showEditForm=false}) => {
  
  console.log(existingData,"existingData");
  let webApi = new WebApimanager();
  
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [departments, setDepartments] = useState([{ id: 1 }]);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    activity: "",
    department: [],
    checklistType: "",
    statusType: ""
  });
  const [existingActivities, setExistingActivities] = useState([]);
  useEffect(() => {
    if ( existingData && showEditForm) {
      console.log("existingData: ", existingData);
      console.log("existingData activity _id: ", existingData?.activity?._id);
      setFormData({
        activity: existingData.activity?._id || "",
        department: existingData.department || [],
        checklistType: existingData.checklistType || "",
        statusType: existingData.statusType || ""
      });
    
      setDepartments(existingData.department.map((dept, index) => ({
        id: index + 1,
        ...dept
      })));
    }
  }, [ existingData,showEditForm]);


  
  const handleAddDepartment = () => {
    setDepartments([...departments, { id: departments.length + 1 }]);
  };

  const handleRemoveDepartment = (id) => {
    const updatedDepartments = departments.filter((dept) => dept.id !== id);
    setDepartments(updatedDepartments);
    const updatedFormDataDepartments = formData.department.filter((_, index) => index !== id - 1); 
    setFormData({
      ...formData,
      department: updatedFormDataDepartments,
    });
  };
  
const emptyformdata ={
  activity: "",
    department: [],
    checklistType: "",
    statusType: ""
}
  const handleClose = () => {
    handleDialogClose();
    setErrors({});
    setFormData(emptyformdata);
  }; 
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? undefined : prevErrors[name],
    }));
  };

  const handleDepartmentChange = (e, index) => {
    const newDepartments = [...formData.department];
    newDepartments[index] = e.target.value;

    setFormData({
      ...formData,
      department: newDepartments,
    });
    if (e.target.value) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[`department${index}`];
        return updatedErrors;
      });
    }
  };

  const validateForm = () => {
    let validationErrors = {};

    if (!formData.activity) {
      validationErrors.activity = "Activity is required";
    }
    const departmentErrors = departments.map((dept, index) => {
        if (!formData.department[index]) {
          return `Department ${index + 1} is required`;
        }
        return null;
      });
      departmentErrors.forEach((error, index) => {
        if (error) {
          validationErrors[`department${index}`] = error;
        }
      });

    if (!formData.checklistType) {
      validationErrors.checklistType = "Checklist type is required";
    }
if(formData.checklistType === 'Checklist') {
    if (!formData.statusType) {
      validationErrors.statusType = "Status type is required";
    }
  }
    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0; 
  };

  const handleFormSubmit = async () => {
    if (validateForm()) {
      setApiProcessing({ loader: true, message: "Submitting..." });
  
      let designchecklistdata = {
        activity: formData.activity,
        department: formData.department,
        checklistType: formData.checklistType,
        ...(formData.statusType && { statusType: formData.statusType }),
      };
  
      try {

        let response;
        if (existingData && existingData._id && showEditForm) {
          response = await webApi.put(`api/checklistDesign/update/${existingData._id}`, designchecklistdata);
        } else {
          response = await webApi.post('api/checklistDesign/create', designchecklistdata);
        }
        if (response.status === 200) {
          setApiProcessing({ loader: false, message: "" });
          handleClose();  
          window.location.reload();
        } else {
          setApiProcessing({ loader: false, message: "" });
          setErrorMessage("Failed to submit. Please try again.");
        }
      } catch (error) {
        setApiProcessing({ loader: false, message: "" });
        setErrorMessage("An error occurred while submitting the form.");
        console.error(error);
      }
    }
  };
  
  const getAll = () => {
    webApi.get("api/activity/getAll").then((data) => {
      if (data && data.data && data.data.data) {
        console.log('Fetched activities:', data.data.data.activities); 
        setExistingActivities(data.data.data.activities);
      }
    });
  };
  
  useEffect(() => {
    getAll();
  }, []);
  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <Dialog
        open={open?open:showEditForm}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: '50px',
          }}
        >
          <p
            style={{ margin: "5px 170px", textAlign: "center", color: "white" }}
          >
            Create
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "300px" ,maxHeight: "80vh", overflowY: "auto",}}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <div className={styles.entireField}>
              <label className={styles.labelName}>Activity</label>
              <select
                className={styles.dropDown}
                name="activity"
                value={formData.activity}
                onChange={handleInputChange}
              >
                <option value="">Select Activity</option>
                {existingActivities && existingActivities.length > 0 ? (
        existingActivities.map((activity) => (
          <option key={activity._id} value={activity._id}>
            {activity.activity}
          </option>
        ))
      ) : (
        <option value="" disabled>
          No activities available
        </option>
      )}
              </select>
              {errors.activity && <p className={styles.error}>{errors.activity}</p>}
            </div> */}
<div className={styles.entireField}>
  <label className={styles.labelName}>Activity</label>

 

  <select
  name="activity"
  value={formData.activity || ""}
  onChange={(e) => handleInputChange({ target: { name: "activity", value: e.target.value } })}
  className={styles.dropDown}
  style={{ width: "100%" }}
>
  <option value="" disabled>
    Select Activity
  </option>
  {existingActivities.map((activity) => (
    <option key={activity._id} value={activity._id}>
      {activity.activity}
    </option>
  ))}
</select>



  {errors.activity && <p className={styles.error}>{errors.activity}</p>}
</div>

            <div>
            {departments.map((dept, index) => (
                <div key={index} className={styles.entireField}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label className={styles.labelName}>Department {index + 1}</label>
                    {index === 0 && (
                      <img
                        src={Add}
                        loading="lazy"
                        alt="add"
                        onClick={handleAddDepartment}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <select
                      className={styles.dropDown}
                      style={{ flex: 1,borderRadius:'5px'}}
                      name={`department${index}`}
                      value={formData.department[index] || ""}
                      onChange={(e) => handleDepartmentChange(e, index)}
                    >
                      <option value="">Select Department</option>
                      <option value="EHS">EHS</option>
                      <option value="PNM">PNM</option>
                      <option value="QC">QC</option>
                      <option value="Admin">Admin</option>
                      <option value="MEP">MEP</option>
                    </select>
                    {index !== 0 && (
                      <img
                        src={Sub}
                        loading="lazy"
                        alt="remove"
                        onClick={() => handleRemoveDepartment(dept.id)}
                        style={{ cursor: 'pointer',marginLeft:'10px' }}
                      />
                    )}
                  </div>
                  {errors[`department${index}`] && (
                      <p className={styles.error}>{errors[`department${index}`]}</p>
                    )}
                </div>
              ))}
            </div>




            <div className={styles.entireField}>
              <label className={styles.labelName}>Checklist Type</label>
              <select
                className={styles.dropDown}
                style={{borderRadius:'5px'}}
                name="checklistType"
                value={formData.checklistType}
                onChange={handleInputChange}
              >
                <option value="">Select Checklist Type</option>
                <option value="Checklist">Checklist</option>
                <option value="Inspection">Inspection</option>
                <option value="Permits">Permits</option>
              </select>
              {errors.checklistType && <p className={styles.error}>{errors.checklistType}</p>}
            </div>


{formData.checklistType === 'Checklist' && 
            <div className={styles.entireField}>
              <label className={styles.labelName}>Status Type</label>
              <select
                className={styles.dropDown}
                style={{borderRadius:'5px'}}
                name="statusType"
                value={formData.statusType}
                onChange={handleInputChange}
              >
                <option value="">Select Status Type</option>
                <option value="Before">Before</option>
                <option value="After">After</option>
                <option value="During">During</option>
                <option value="Testing">Testing</option>
              </select>
              {errors.statusType && <p className={styles.error}>{errors.statusType}</p>}
            </div>

}
          </div>

          {errorMessage && (
            <p className={styles.errorMessage}>
              {errorMessage}
            </p>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              onClick={handleFormSubmit}
              className={styles.submitButton}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Create;
