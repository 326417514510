import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import 'bootstrap/dist/css/bootstrap.min.css';
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { registerAtom, siteId } from '../../../atoms';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { getRegisterData, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";

Chart.register(ArcElement, Tooltip, Legend);

// Custom plugin to draw the text between inner and outer edges
const centerTextPlugin = {
  id: 'centerText',
  afterDatasetDraw: (chart) => {
    const { ctx, chartArea: { width, height } } = chart;

    const total = chart.data.datasets[0].data.reduce((acc, value) => acc + value, 0);
    const [issuedDrawings, pendingDrawings] = chart.data.datasets[0].data;
    const issuedPercentage = ((issuedDrawings / total) * 100).toFixed(1);
    const pendingPercentage = ((pendingDrawings / total) * 100).toFixed(1);

    const meta = chart.getDatasetMeta(0);
    const totalAngle = Math.PI * 2;
    const startAngle = meta.data[0].startAngle;
    const endAngle = meta.data[0].endAngle;
    const middleAngle = (endAngle + startAngle) / 2;
    
    // Calculate the position
    const innerRadius = meta.data[0].innerRadius;
    const outerRadius = meta.data[0].outerRadius;
    const textRadius = innerRadius + (outerRadius - innerRadius) / 2;

    ctx.font = 'bold 16px poppins';
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FFF';  // White text

    // Position for issued drawings percentage
    const issuedX = width / 2 + textRadius * Math.cos(startAngle + (issuedDrawings / total) * totalAngle / 2);
    const issuedY = height / 2 + textRadius * Math.sin(startAngle + (issuedDrawings / total) * totalAngle / 2);
    ctx.fillText(`${issuedPercentage}%`, issuedX, issuedY);

    // Position for pending drawings percentage
    const pendingX = width / 2 + textRadius * Math.cos(startAngle + (issuedDrawings / total) * totalAngle + (pendingDrawings / total) * totalAngle / 2);
    const pendingY = height / 2 + textRadius * Math.sin(startAngle + (issuedDrawings / total) * totalAngle + (pendingDrawings / total) * totalAngle / 2);
    ctx.fillText(`${pendingPercentage}%`, pendingX, pendingY);

        // Draw inner circle
        ctx.beginPath();
        ctx.arc(width / 2, height / 2, innerRadius, 0, 2 * Math.PI);
        ctx.fillStyle = '#FFF'; // White inner circle
        ctx.fill();
    
        // Draw "Total" text
        ctx.font = '20px poppins';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#000';
        ctx.fillText('Total', width / 2, height / 2 - 20);
    
        // Draw "Registered Drawing" text
        ctx.font = '16px poppins';
        ctx.fillText(' Registered Drawing', width / 2, height / 2 );
        ctx.save();

            // Draw total number of registered drawings
    ctx.font = '16px poppins';
    ctx.fillText(total, width / 2, height / 2 + 20);
        ctx.save();

  }
};

const DrawingStatus = (props) => {
console.log(props,"props data")
  let webApi = new WebApimanager();
  const [site, setSite] = useRecoilState(siteId);
  const [drawingList, setDrawingList] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  // const fetchData = async () => {
  //   try {
  //     // API endpoint based on type, month, and year
  //     const endpoint =
  //       props.type === "architectToRo"
  //         ? props.selectedMonth && props.selectedYear && props.selctedTimeperiod
  //           ? `api/analysis/report/${site}?selectTimePeriod=${props.selctedTimeperiod}&month=${props.selectedMonth}&year=${props.selectedYear}`
  //           :  `api/architect/upload/${site}?filterType=all`
  //         : props.selectedMonth && props.selectedYear
  //         ? `api/roToSiteLevelRequested/site?siteId=${site}&month=${props.selectedMonth}&year=${props.selectedYear}`
  //         : `api/roToSiteLevelRequested/site?siteId=${site}`;

  //     const response = await webApi.get(endpoint);
  //     console.log("Fetched drawing data based on site:", response.data.data);
  //     setDrawingList(response.data.data || []);
  //   } catch (error) {
  //     console.error("Error fetching drawing data:", error);
  //   }
  // };

  // Call fetchData whenever site, selectedMonth, or selectedYear change
  const fetchData = async () => {
    try {
        let endpoint;

        if (props.type === "architect") {
            if (props.selctedTimeperiod === "yearly") {
                endpoint = `api/analysis/report/${site}?selectTimePeriod=${props.selctedTimeperiod}&year=${props.selectedYear}`;
            } else if (props.selectedMonth && props.selectedYear) {
                endpoint = `api/analysis/report/${site}?selectTimePeriod=${props.selctedTimeperiod}&month=${props.selectedMonth}&year=${props.selectedYear}`;
            } else {
                endpoint = `api/architect/register/${site}`;
            }
            
        } else if (props.type === "RO") {
            if (props.selctedTimeperiod === "yearly") {
                endpoint = `api/analysis/roReport/${site}?selectTimePeriod=${props.selctedTimeperiod}&year=${props.selectedYear}`;
            } else if (props.selectedMonth && props.selectedYear) {
                endpoint = `api/analysis/roReport/${site}?selectTimePeriod=${props.selctedTimeperiod}&month=${props.selectedMonth}&year=${props.selectedYear}`;
            } else {
                endpoint = `api/architect/registerRo/${site}`;
            }
        }else{
          if (props.selctedTimeperiod === "yearly") {
            endpoint = `api/analysis/report/${site}?selectTimePeriod=${props.selctedTimeperiod}&year=${props.selectedYear}`;
        } else if (props.selectedMonth && props.selectedYear) {
            endpoint = `api/analysis/report/${site}?selectTimePeriod=${props.selctedTimeperiod}&month=${props.selectedMonth}&year=${props.selectedYear}`;
        } else {
            endpoint = `api/architect/registerSiteHead/${site}`;
        }
        }

        const response = await webApi.get(endpoint);
        console.log("Fetched drawing data based on site:", response.data.data);
        if(props.selctedTimeperiod==="" &&props.selectedMonth === "" && props.selectedYear ===""){
          if(props.type === "architect"){
          setDrawingList(Array.isArray(response.data.data.filteredData) ? response.data.data.filteredData : []);
          }else if(props.type === "RO" || props.type === "SiteHead"){
            setDrawingList(Array.isArray(response.data.registers) ? response.data.registers : []);
          }
        }else{
        setDrawingList(Array.isArray(response.data.data) ? response.data.data : []);
        }
    } catch (error) {
        console.error("Error fetching drawing data:", error);
    }
};

  useEffect(() => {
    fetchData();
    console.log(props.selctedTimeperiod, props.selectedMonth, props.selectedYear,"drawing status ")
  }, [site,props.selctedTimeperiod, props.selectedMonth, props.selectedYear]);
  const issuedDrawings =  drawingList.filter(item => props.type === 'architect'&& item.acceptedArchitectRevisions?.length > 0||props.type === 'RO'&& item.acceptedRORevisions?.length>0 || props.type === 'SiteHead' && item.acceptedSiteHeadRevisions?.length>0 ).length;
  const pendingDrawings = drawingList.filter(item => props.type === 'architect'&& item.acceptedArchitectRevisions?.length === 0 || props.type === 'RO'&& item.acceptedRORevisions?.length===0 || props.type === 'SiteHead'&& item.acceptedSiteHeadRevisions?.length===0).length;

  const data = {
    labels: ['Issued Drawings', 'Pending Drawings'],
    datasets: [
      {
        data: [issuedDrawings, pendingDrawings],
        backgroundColor: ['#72ADCF', '#0075A4'], 
        hoverOffset: 4,
        borderWidth: 2,
        borderColor: '#FFF'
      },
    ],
  };

  const options = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw}`;
          },
        },
      },
    },
  };

  return (
    <>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', gap: '30px' }}>
      <div style={{color:'var(--primary-color)', marginBottom:'10px'}}>Drawing Status</div>
     
        <div style={{ width: '25vw', height: '25vw' }}>
          <Doughnut data={data} options={options} plugins={[centerTextPlugin]} />
        </div>
   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#72ADCF', marginRight: '5px' }}></div>
          <span>Issued Drawing</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#0075A4', marginRight: '5px' }}></div>
          <span>Pending Drawing</span>
        </div>
      </div>
      </div>
    </>
  );
};

export default DrawingStatus;
