import React from 'react'
import { RxCross1 } from 'react-icons/rx'
import "./ViewAssignedWork.css"
import { Dialog, DialogContent } from '../../Dialog/Dialog'


const ViewAssignedWork = ({showViewAssignedWork,handleClose,worksData}) => {
  return (
    <Dialog
        open={showViewAssignedWork}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 500px", textAlign: "center", color: "white" }}
          >
            View Task
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "300px" }}
        >
         <div className="VAWFormContainer">
            <div className="VAWMainInputContainer">
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Type</p>
                    <p>:</p>
                    <p>{worksData.type}</p>
                </div>
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Equipment Type</p>
                    <p>:</p>
                    <p>{worksData.equipmentType}</p>
                </div>
                
            </div>
            <div className="VAWMainInputContainer">
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Asset Code</p>
                    <p>:</p>
                    <p>{worksData.assetCode?.assetCode}</p>
                </div>
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Make Name</p>
                    <p>:</p>
                    <p>{worksData.makeName}</p>
                </div>
                
            </div>

            <div className="VAWMainInputContainer">
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Model Name</p>
                    <p>:</p>
                    <p>{worksData.modelName}</p>
                </div>
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Fuel/Not</p>
                    <p>:</p>
                    <p>{worksData.fuelRnot}</p>
                </div>
                
            </div>
            <div className="VAWMainInputContainer">
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Inspection</p>
                    <p>:</p>
                    <p>{worksData.inspections}</p>
                </div>
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Services</p>
                    <p>:</p>
                    <p>{worksData.services}</p>
                </div>
                
            </div>
            <div className="VAWMainInputContainer">
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Engine/Motor</p>
                    <p>:</p>
                    <p>{worksData.engineRmotor}</p>
                </div>
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Select No</p>
                    <p>:</p>
                    <p>{worksData.selectNo}</p>
                </div>
                
            </div>
            <div className="VAWMainInputContainer">
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Created Date</p>
                    <p>:</p>
                    <p>{worksData?.createdDate?.splice(0,10)}</p>
                </div>
                <div className="VAWShowContainer">
                    <p className="VAWFieldName">Created By</p>
                    <p>:</p>
                    <p>{worksData?.createdBy?.firstName}</p>
                </div>
                
            </div>
            
            
            
        </div> 
        </DialogContent>
    </Dialog>
  )
}

export default ViewAssignedWork