/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-children-prop */
import React, { useState, useEffect, useMemo } from "react";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { RxCross1 } from "react-icons/rx";
import { useRecoilState } from "recoil";
import { registerAtom, siteId, userInfo } from "../../../atoms";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import ReceivedTable from "./ReceivedTable";
import TableWrapper from "../../../Utilities/TableWrapper";
import { updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import { canShowFolder } from "../ShowFolder";
import ConsultantFilter from "../ConsultantFilter";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./ApprovalTable.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", // Optional
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", // Removes the row borders
          },
        },
      },
    },
  },
});
const PendingTable = () => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  let webApi = new WebApimanager();
  const todayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  const navigate = useNavigate();
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(true);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [site, setSite] = useRecoilState(siteId);
  const [errorMessage, setErrorMessage] = useState({});
  const [currentUserInfo] = useRecoilState(userInfo);
  const [consultantValue, setConsultantValue] = useState('')
  const [drawingList, setDrawingList] = useState([]);

  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    acceptedArchitectDate: "",
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingFileName: null,
    revisions: [],
    receivedCopies: "",
  });
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [currentView, setCurrentView] = useState("upload");

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [currentTab, setCurrentTab] = useState("upload");

  const handleTabChange = (tabKey) => {
    setCurrentTab(tabKey);
  };

  // Example validation function
  const validateForm = (formData, currentTab) => {
    const newErrors = {};
    if (currentTab === "upload") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      } else if (!formData.drawingFileName.endsWith(".dwg")) {
        newErrors.drawingFileName = "Drawing File must be a .dwg file";
      }
      // if (!formData.remarks) errors.remarks = 'This field is required.';
    } else if (currentTab === "received") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      if (!formData.revisions) {
        newErrors.revisions = "revision is required";
      }
      if (!formData.receivedCopies) {
        newErrors.receivedCopies = "receivedCopies are required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      } else if (!formData.drawingFileName.endsWith(".dwg")) {
        newErrors.drawingFileName = "Drawing File must be a .dwg file";
      }
    }
    return newErrors;
  };

  const handleFormSubmit = async () => {
    // Validate the form data
    const errors = validateForm(formData, currentTab);
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    // Perform API call based on the active tab
    if (currentTab === "upload") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitUpload(formData.registerId);
    }
    if (currentTab === "received") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReceived(formData.registerId);
    }
  };

  const submitUpload = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedSiteRevisions: [
          {
            softCopySubmittedDate: todayDate(),
            issuedSoftCopy: formData.issuedSoftCopy,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/revisions/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("drawingFileName", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/${registerId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      fetchInData();
      // window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      // Handle error, show error message, etc.
      alert("Failed to submit the form. Please try again later.");
    }
  };

  const submitReceived = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedSiteRevisions: [
          {
            hardCopySubmittedDate: todayDate(),
            receivedHardCopy: formData.issuedHardtCopy,
            revision: formData.revisions,
            receivedCopies: formData.receivedCopies,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/hardCopy/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const hardCopyFile = new FormData();
        hardCopyFile.append("hardCopyFile", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/hardCopeFile/${registerId}`,
          hardCopyFile
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      fetchInData();
      // window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      // Handle error, show error message, etc.
      alert("Failed to submit the form. Please try again later.");
    }
  };

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  // const [combinedData, setCombinedData] = useState([]);


  const fetchInData = async () => {
    try {
      const response = await webApi.get(
        `api/architect/pendingSiteLevel/${site}?filterType=upload`
      );

      const { rORevisions = [], siteHeadRevisions = [] } =
        response.data.data || {};

      console.log(response.data.data, "response.data.data");
      console.log(rORevisions, "rORevisions");
      console.log(siteHeadRevisions, "siteHeadRevisions");

      const combined = [
        ...(Array.isArray(rORevisions)
          ? rORevisions.map((item) => ({ ...item, source: "RO" }))
          : []),

        ...(Array.isArray(siteHeadRevisions)
          ? siteHeadRevisions.map((item) => ({ ...item, source: "Site Head" }))
          : []),
      ];
      console.log(response.data.data, "response.data.data ... 2");

      console.log("Combined data before setting state:", combined);

      setDrawingList(combined);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    console.log("Updated drawingList:", drawingList);
  }, [drawingList]);

  useEffect(() => {
    fetchInData(); // Fetch data when the component mounts
  }, []);

  useEffect(() => {
    console.log("Updated drawingList:", drawingList);
  }, [drawingList]);

  const handleEdit = (drawingId) => {
    const selected = drawingList.find((drawing) => drawing._id === drawingId);
    if (selected) {
      const combinedRevisions = [
        ...selected.acceptedSiteRevisions,
        // ...selected.acceptedRORevisions,
      ];

      setSelectedDrawing(selected);
      setFormData({
        registerId: selected._id,
        drawingNumber: selected.drawingNo || "",
        drawingName: selected.drawingTitle || "",
        category: selected.category?.category || "", // Ensure the category field is properly accessed
        designDrawingConsultant:
          selected.designDrawingConsultant.designDrawingConsultant || "",
        acceptedArchitectDate: selected.acceptedArchitectDate
          ? new Date(selected.acceptedArchitectDate).toLocaleDateString()
          : "",
        revisions: combinedRevisions,
        submittedDate: todayDate(),
        // dueDays: 0,
        // issuedSoftCopy: selected.issuedSoftCopy,
        // receivedSoftCopy: selected.receivedSoftCopy,
        // drawingFileName: selected.drawingFileName
      });
      setOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0], // Assuming you are only uploading one file
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const calculateDueDays = (acceptedRoDate, submittedDate = null) => {
    if (!acceptedRoDate) return "-";

    const dateToCompare = submittedDate ? new Date(submittedDate) : new Date();
    const acceptedDate = new Date(acceptedRoDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // convert time difference to days

    return differenceInDays;
  };
  const uniqueConsultant = useMemo(() => {
    const consultant = drawingList.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [drawingList]);

  const searchResult = (e) => {
    const searchInput = e.target.value.toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);
   
  };



  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const filteredDrawingList = useMemo(() => {
    if (searchValue.trim() === "") {
      // Return the full list when the searchValue is empty
      return drawingList;
    }
  
    const lowerCaseSearchTerm = searchValue.trim().toLowerCase();
  
    // Filter the drawingList based on the search term
    return drawingList.filter((drawingItem) => {
      return (
        (drawingItem?.drawingNo || "")
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        (drawingItem.designDrawingConsultant?.role || "")
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        (drawingItem.category?.category || "")
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        (drawingItem.drawingTitle || "")
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        (drawingItem?.for === "siteLevel" ? "Site Head" : "")
          .toLowerCase()
          .includes(lowerCaseSearchTerm)
      );
    });
  }, [searchValue, drawingList]);
  
  const updatedRows = useMemo(() =>
    filteredDrawingList
      .filter(
        (item) =>
          item.acceptedSiteHeadRevisions &&
          item.acceptedSiteHeadRevisions.length === 0
      )
      .map((row, index) => {
        const today = new Date();
        const dueDate = new Date(row.acceptedSiteSubmissionDate);
        const daysDifference = Math.ceil(
          (dueDate - today) / (1000 * 60 * 60 * 24)
        );
  
        const dueOverdue = {
          text:
            daysDifference >= 0
              ? `${daysDifference} days due`
              : `${Math.abs(daysDifference)} days overdue`,
          color: daysDifference >= 0 ? "green" : "red",
        };
  
        return {
          ...row,
          serialNo: index + 1,
          drawingNo: row.drawingNo,
          consultant: row?.designDrawingConsultant?.role || "-",
          category: row.category?.category || "-",
          drawingTitle: row.drawingTitle || "-",
          from: row.for === "siteLevel" ? "Site Head" : "" || "-",
          revision: row.archRevision || "-",
          dueOverdue, 
        };
      }),
    [filteredDrawingList]
  );


  

  const columns = useMemo(() => [
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 0.5,
      filterable: false, 
      sortable: false, 
    },
    {
      field: "drawingNo",
      headerName: "DRWG.No",
      flex: 1,
      filterable: true,
      sortable: false, 
    },
    {
      field: "consultant",
      headerName: "Consultant",
      flex: 1,
      filterable: true,
      sortable: false,
    },
    {
      field: "category",
      headerName: "Drawing Category",
      flex: 1.5,
      filterable: true,
      sortable: false,
    },
    {
      field: "drawingTitle",
      headerName: "Drawing Title",
      flex: 2,
      filterable: true,
      sortable: false,
    },
    
    {
      field: "from",
      headerName: "From",
      flex: 1,
      filterable: true,
      sortable: false,
      
    },
    {
      field: "due/overdue",
      headerName: "Due/Overdue",
      flex: 1.5,
      filterable: false, 
      renderCell: (params) => (
        <span style={{ color: params.row.dueOverdue.color }}>
          {params.row.dueOverdue.text}
        </span>
      ),
    },

    {
      field: "revision",
      headerName: "Revision",
      flex: 1,
      filterable: true,
      sortable: false,
    },
   
  ], [drawingList]);
  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <h3 style={{ color: 'var(--primary-color)',fontSize: '20px' }}>Pending</h3> */}
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex" }}>
                  <h3
                    style={{ color: "var(--primary-color)", fontSize: "20px" }}
                  >
                    Pending Lists
                  </h3>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {/* <select
                  id="siteName"
                  name="siteName"
                  value={siteId}
                  onChange={handleSiteChange}
                  style={{ marginLeft: '10px', width: '240px', padding: '4px' }}
                >
                  <option value="">All Sites</option>
                  { sites && sites.map(site => (
                    <option key={site._id} value={site._id}>{site.siteName}</option>
                  ))}
                </select> */}
                <div
                  style={{
                    position: "relative",
                    width: "280px",
                    paddingLeft: "5px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder="Search Drawings"
                    onChange={(e)=>setSearchValue(e.target.value)}
                    style={{
                      paddingLeft: "20px",

                      border: "1px solid var(--primary-color)",
                      width: "250px",
                      height: "40px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "35px",
                      top: "40%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />
                </div>
                {/* <Icon.FunnelFill size={25} style={{width: '40px',

height: "40px",marginLeft: "10px", alignItems: "center", border: "1px solid var(--primary-color)",}}/> */}
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
                              
                            <Box
                              className="dataGridContainer"
                            >
                              <ThemeProvider theme={theme}>
                              <DataGrid
                                columns={columns}
                                rows={updatedRows}
                                getRowId={(row) => row.drawingId}
                                rowHeight={60}
                                disableColumnMenu={false} // Keep the column menu for filtering
                                pagination={false}
                                hideFooter={true}
                                getRowSpacing={params=>({
                                  top:params.isFirstVisible ? 0 : 5,
                                  bottom:params.isLastVisible ? 0 : 5
                                })}
                                
                                getRowClassName={(params) =>
                                  "customHeader" 
                                }
                                
                              />
                              </ThemeProvider>
                            </Box>
            
            
                            </div>
          </>
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            <button
              onClick={() => {
                setCurrentTab("upload");
              }}
              style={{
                backgroundColor:
                  currentTab === "upload"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "upload" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
                borderRight: "1px solid var(--primary-color)",
              }}
            >
              Upload
            </button>

            <button
              onClick={() => setCurrentTab("received")}
              style={{
                background:
                  currentTab === "received"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "received" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Received
            </button>
          </div>
        </div>
        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        > 
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <section
              style={{
                marginLeft: "80px",
                marginRight: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "50px",
                  width: "80%",
                }}
              >
                <div>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing No
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="drawingNumber"
                    name="drawingNumber"
                    value={formData.drawingNumber}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />

                  {errors.drawingNumber && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingNumber}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="drawingName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Title
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="drawingName"
                    name="drawingName"
                    value={formData.drawingName}
                    onChange={handleChange}
                    placeholder="Enter your drawing title"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.drawingName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingName}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Category
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    placeholder="Enter your Drawing Category"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.category && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.category}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="designDrawingConsultant"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Design Consultant
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="designDrawingConsultant"
                    name="designDrawingConsultant"
                    value={formData.designDrawingConsultant}
                    onChange={handleChange}
                    placeholder="Enter your  Design Consultant"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.designDrawingConsultant && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.designDrawingConsultant}
                    </p>
                  )}
                </div>

                {currentTab !== "received" && (
                  <div>
                    <label
                      htmlFor="revisions"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Revisions
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="revisions"
                      name="revisions"
                      value={formData.revisions}
                      onChange={handleChange}
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                    >
                      <option value="">Select Revision</option>
                      <option value="R0">R0</option>
                    </select>
                    {errors.revisions && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.revisions}
                      </p>
                    )}
                  </div>
                )}
                {currentTab === "received" && (
                  <>
                    <div>
                      <label
                        htmlFor="revisions"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Revisions
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="revisions"
                        name="revisions"
                        value={formData.revisions}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Revision</option>
                        {formData.revisions &&
                          formData.revisions.map((revision, index) => (
                            <option key={index} value={revision.revision}>
                              {revision.revision}
                            </option>
                          ))}
                      </select>
                      {errors.revisions && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.revisions}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="receivedCopies"
                        className="fs-10 fw-bold text-start py-2
              "
                      >
                        No of Copies Received
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        id="receivedCopies"
                        name="receivedCopies"
                        placeholder="Enter No of Received Copies"
                        value={formData.receivedCopies}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </>
                )}

                <div>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Upload Your Drawing File
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".dwg"
                    id="drawingFileName"
                    name="drawingFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.name.endsWith(".dwg")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .dwg file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: "43px" }}
                  />
                  {errors.drawingFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingFileName}
                    </p>
                  )}
                </div>
              </div>
            </section>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "45px",
              }}
            >
              <button
                style={{
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid lightgray",
                  backgroundColor: "transparent",
                  width: "100px",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "lightgray")
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
                onClose={handleClose}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                style={{
                  marginLeft: "50px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid var(--primary-color)",
                  width: "100px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </>
        </DialogContent>
      </Dialog>
      <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue}/>
    </>
  );
};

export default PendingTable;
