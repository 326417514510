

import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { RxCross1 } from "react-icons/rx";
import styles from "./Form.module.css";
import Add from "../../../Images/Add.svg";
import Sub from "../../../Images/subtration.svg";
import FolderfileViewDialog from "./Component/FolderfileViewDialog";
import Download from "../../../Images/Download.svg"
import Pen from "../../../Images/Pen.svg"
import Eye from "../../../Images/Eye.svg"
import FolderfileEditDialog from "./Component/FolderfileEditDialog";
import { Tabs, Tab } from '@mui/material';
const FolderFile = (props) => {
  const { formData } = props;
  console.log(formData,"activityforms")
  let webApi = new WebApimanager();
  
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const handleRowClick = (item) => {
    setSelectedRowData(item);
    setShowDetails(true);
    setShowEditPage(false);
  };
  const [showEditPage, setShowEditPage] = useState(false); 

  const handlePenClick = () => {
    setShowEditPage(true);
    setShowDetails(false); 
  };
  const [selectedTab, setSelectedTab] = useState(0); 
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleDownloadClick = () => {
    // Assuming `fileUrl` is part of `selectedRowData`
    const fileUrl = selectedRowData?.fileUrl;
    
    if (fileUrl) {
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = selectedRowData.fileName || 'file'; // Set the file name dynamically if available
      link.click();
    } else {
      console.error("File URL not found");
    }
  };
  
  
  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <div className={styles.entireContainer} >
        <div className={styles.leftsideContainer}>
          {/* leftUpperTable */}
          <div>
            <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #979797' }}>
              <tr style={{height:'50px',color:'white'}}>
                <th>FormNo</th>
                <th>ChecklistType</th>
              </tr>
              <tbody>
              {formData.map((item, index) => (
                <tr onClick={() => handleRowClick(item)} style={{cursor:'pointer',borderTop:'1px solid  #979797'}}  key={index}>

                    <td style={{ borderLeft: '1px solid  #979797', padding: '8px',textAlign:"center" }}>{item.formNo}</td>
              <td style={{ borderRight: '1px solid  #979797', padding: '8px' ,textAlign:"center"}}>{item.checklistType}</td>
            </tr>
          ))}
              </tbody>
            </table>
          </div>
            {/* leftDownTable */}
            {(showDetails && selectedRowData) && (
          <div style={{marginTop:'15px', border: '1px solid #979797'}}>
            <div style={{height:'50px',borderRadius:'2px'}}>
              <p style={{background: '#4D4D4D',color:'white',textAlign:'center',padding:'15px'}}>{selectedRowData.formNo}</p>
              </div>
              {selectedRowData.revisions && selectedRowData.revisions.length > 0 ? (
  <Tabs
    value={selectedTab}
    onChange={handleTabChange}
    indicatorColor="transparent" 
    textColor="inherit" 
    style={{ backgroundColor: "transparent",padding:'15px' }}
  >
    {selectedRowData.revisions
      .slice(-2) 
      .map((revisionItem, index) => (
        <Tab
          key={index}
          label={`${revisionItem.revision}`}
          style={{
            backgroundColor:
              selectedRowData.revisions.length === 1 || selectedTab === index
                ? "var(--primary-color)"
                : "transparent",
            color:
              selectedRowData.revisions.length === 1 || selectedTab === index
                ? "white"
                : "var(--primary-color)",
            width: `${100 / Math.min(2, selectedRowData.revisions.length)}%`, 
            height: "30px",
            minWidth: "20px",
            cursor: "pointer",
            borderRight: index < 1 ? "1px solid var(--primary-color)" : "none", 
          }}
        />
      ))}
  </Tabs>
) : (
  <div>No Revisions</div>
)}
<div className={styles.Revisionsgrid}>
                <p style={{fontSize:'14px',fontWeight:'500'}}>Requested By</p>
                <p style={{fontSize:'14px',fontWeight:'500'}}>:</p>
                <p>{selectedRowData.revisions[selectedTab]?.requestedBy.role || 'No Data'} </p>

                <p style={{fontSize:'14px',fontWeight:'500'}}>Requested Date</p>
                <p style={{fontSize:'14px',fontWeight:'500'}}>:</p>
                <p>{new Date(selectedRowData.revisions[selectedTab]?.requestedDate).toLocaleDateString() || 'No Data'}</p>

                <p style={{fontSize:'14px',fontWeight:'500'}}>Approved By</p>
                <p style={{fontSize:'14px',fontWeight:'500'}}>:</p>
                <p>{selectedRowData.revisions[selectedTab]?.approvedBy.role || 'No Data'} </p>

                <p style={{fontSize:'14px',fontWeight:'500'}}>Approved Date</p>
                <p style={{fontSize:'14px',fontWeight:'500'}}>:</p>
                <p>{new Date(selectedRowData.revisions[selectedTab]?.approvedDate).toLocaleDateString() || 'No Data'}</p>

                                  {selectedRowData.revisions[selectedTab]?.reDoReason.length > 0 && (
                    <>
                      <p style={{ fontSize: '14px', fontWeight: '500' }}>Redo Reason</p>
                      <p style={{ fontSize: '14px', fontWeight: '500' }}>:</p>
                      <p>{selectedRowData.revisions[selectedTab]?.reDoReason.join(', ')}</p>
                    </>
                  )}

                  {selectedRowData.revisions[selectedTab]?.rejectReason.length > 0 && (
                    <>
                      <p style={{ fontSize: '14px', fontWeight: '500' }}>Reject Reason</p>
                      <p style={{ fontSize: '14px', fontWeight: '500' }}>:</p>
                      <p>{selectedRowData.revisions[selectedTab]?.rejectReason.join(', ')}</p>
                    </>
                  )}

              </div>
          </div>
            )}
        </div>
        <div className={styles.rightsideContainer}>
           {showDetails && (
            <>
          <div className={styles.actionsContainer}>
            <img
            src={Pen}
            loading="eager"
            alt="pen"
            className={styles.actions}
            style={{cursor:'pointer'}}
            onClick={handlePenClick}
            />
            <img
            src={Download}
            loading="eager"
            alt="download"
            className={styles.actions}
            style={{cursor:'pointer'}}
            onClick={handleDownloadClick}
            />
          </div>
          <div className={styles.rightsideContainerTable}>
            <FolderfileViewDialog data={selectedRowData}/>
            </div>
            </>
           )}

{showEditPage &&(
  <>
  
          <div className={styles.rightsideContainerTable}>
            <FolderfileEditDialog data={selectedRowData}/>
  </div>
  </>
)}
        </div>

      </div>
    </>
  );
};

export default FolderFile;
