import React, { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav, Tab } from "react-bootstrap";
import Navbar from "../Navbar/Header";
import PnmTable from "./PnmTable";
import * as Icon from "react-bootstrap-icons";
import { WebApimanager } from "../../WebApiManager";
import { RxCross1 } from "react-icons/rx";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import AssetCodeCreation from "./AssetCodeCreation";
import Tabs from "./Tabs";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddnewPnm from "./AddnewPnm/AddnewPnm";
import { useRecoilState } from "recoil";
import { pnm, siteId } from "../../atoms";
import SelectSite from "../../Utilities/SelectSite";
import Footer from "../Footer/Footer";
import CustomTabs from "./Tabs";
import TabButton from "./PNMAssets/TabButtons";
import styles from "../PNM/PnmTable.module.css";
import DistributionBox from "./DailyLogReport/DistributionBox";

const Pnm = () => {
  const webApi = new WebApimanager();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMachineryDetails, setShowMachineryDetails] = useState(false);
  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const [showPowerToolsDetails, setShowPowerToolsDetails] = useState(false);
  const [showDistributionBoxDetails, setShowDistributionBoxDetails] =
    useState(false);
  const [site, setSite] = useRecoilState(siteId);

 

  useEffect(() => {
    webApi
      .get("api/pnm")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.machinary)) {
          setData(response.data.data.machinary);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
 
  const [selectedTab, setSelectedTab] = useState("own");
  
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (newValue) => {
    console.log("New Tab Value:", newValue);
    setCurrentTab(newValue); 
  };
  useEffect (()=>{

  },[currentTab])
  return (
    <>
      <div className="bg-[#F3F3F3]">
        {site === "" ? (
          <>
            <SelectSite />
          </>
        ) : (
          <>
            <Tabs onTabChange={handleTabChange} />
            {/* Own-hire */}
            {currentTab == "Daily Log Report" && (
              <div style={{ marginTop: "30px" }}>
                <div></div>
                <div>
                  <Tab.Container id="tabs-example" defaultActiveKey="first">
                    <div>
                      <div className={styles.selectTabs}>
                        <div>
                          <TabButton
                            buttonText="Own"
                            selected={selectedTab === "own"}
                            onClick={() => {setSelectedTab("own")
                              setShowMachineryDetails(false)
                              setShowVehicleDetails(false)
                              setShowPowerToolsDetails(false)
                              setShowDistributionBoxDetails(false)
                            }}
                          />
                          <TabButton
                            buttonText="Hire"
                            selected={selectedTab === "hire"}
                            onClick={() => {setSelectedTab("hire")
                              setShowMachineryDetails(false)
                              setShowVehicleDetails(false)
                              setShowPowerToolsDetails(false)
                              setShowDistributionBoxDetails(false)}
                            }
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className={styles.tablesContainer}>
                      <section className={styles.container}>
                        <header className={styles.header}>
                          <h3 className={styles.title } onClick={() =>{
                              setShowMachineryDetails((prev) => !prev)
                              setShowVehicleDetails(false)
                              setShowPowerToolsDetails(false)
                              setShowDistributionBoxDetails(false)}
                              }>Machinery</h3>
                          {/* <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/015433747a12472c8a1b493d80bd60f61e5268ebf5cd9df94f229c4bf46e7e4b?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20"
                            className={styles.icon}
                            alt=""
                          /> */}
                          {!showMachineryDetails ? (
                            <FaAngleDown
                              className=" cursor-pointer"
                              onClick={() =>{
                                setShowMachineryDetails((prev) => !prev)
                                
                              setShowVehicleDetails(false)
                              setShowPowerToolsDetails(false)
                              setShowDistributionBoxDetails(false)}
                              }
                            />
                          ) : (
                            <FaAngleUp
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowMachineryDetails((prev) => !prev)
                              }
                            />
                          )}
                        </header>
                        {showMachineryDetails && (
                          <PnmTable selectedTab={selectedTab}  equipmentType="Machinery" />
                        )}
                      </section>
                      <section className={styles.container}>
                        <header className={styles.header}>
                          <h3 className={styles.title} onClick={() =>{
                                setShowVehicleDetails((prev) => !prev)
                                setShowMachineryDetails(false)
                              
                              setShowPowerToolsDetails(false)
                              setShowDistributionBoxDetails(false)}
                              }>Vehicle</h3>
                          {/* <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/015433747a12472c8a1b493d80bd60f61e5268ebf5cd9df94f229c4bf46e7e4b?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20"
                            className={styles.icon}
                            alt=""
                          /> */}
                          {!showVehicleDetails ? (
                            <FaAngleDown
                              className=" cursor-pointer"
                              onClick={() =>{
                                setShowVehicleDetails((prev) => !prev)
                                setShowMachineryDetails(false)
                              
                              setShowPowerToolsDetails(false)
                              setShowDistributionBoxDetails(false)}
                              }
                            />
                          ) : (
                            <FaAngleUp
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowVehicleDetails((prev) => !prev)
                              }
                            />
                          )}
                        </header>
                        {showVehicleDetails && (
                          <PnmTable selectedTab={selectedTab}  equipmentType="Vehicle"/>
                        )}
                      </section>
                      <section className={styles.container}>
                        <header className={styles.header}>
                          <h3 className={styles.title} onClick={() =>{
                                setShowPowerToolsDetails((prev) => !prev)
                                setShowMachineryDetails(false)
                              setShowVehicleDetails(false)
                              
                              setShowDistributionBoxDetails(false)}
                              }>Power Tools</h3>
                          {/* <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/015433747a12472c8a1b493d80bd60f61e5268ebf5cd9df94f229c4bf46e7e4b?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20"
                            className={styles.icon}
                            alt=""
                          /> */}

                          {!showPowerToolsDetails ? (
                            <FaAngleDown
                              className=" cursor-pointer"
                              onClick={() =>{
                                setShowPowerToolsDetails((prev) => !prev)
                                setShowMachineryDetails(false)
                              setShowVehicleDetails(false)
                              
                              setShowDistributionBoxDetails(false)}
                              }
                            />
                          ) : (
                            <FaAngleUp
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowPowerToolsDetails((prev) => !prev)
                              }
                            />
                          )}
                        </header>
                        {showPowerToolsDetails && (
                          <PnmTable selectedTab={selectedTab}  equipmentType="Power Tools" />
                        )}
                      </section>
                      <section className={styles.container}>
                        <header className={styles.header}>
                          <h3 className={styles.title} onClick={() =>{
                                setShowDistributionBoxDetails((prev) => !prev)
                                setShowMachineryDetails(false)
                              setShowVehicleDetails(false)
                              setShowPowerToolsDetails(false)
                              }
                              }>Distribution Board</h3>
                          {/* <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/015433747a12472c8a1b493d80bd60f61e5268ebf5cd9df94f229c4bf46e7e4b?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20"
                            className={styles.icon}
                            alt=""
                          /> */}
                          {!showDistributionBoxDetails ? (
                            <FaAngleDown
                              className=" cursor-pointer"
                              onClick={() =>{
                                setShowDistributionBoxDetails((prev) => !prev)
                                setShowMachineryDetails(false)
                                setShowVehicleDetails(false)
                                setShowPowerToolsDetails(false)
                              }
                              }
                            />
                          ) : (
                            <FaAngleUp
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowDistributionBoxDetails((prev) => !prev)
                              }
                            />
                          )}
                        </header>
                        {showDistributionBoxDetails && (
                          <PnmTable selectedTab={selectedTab}  equipmentType="Distribution Board" />
                        )}
                      </section>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Pnm;
