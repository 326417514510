/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types"; // Import prop-types
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import { usePDF } from "react-to-pdf";
import { calculateDueDays, formatDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { siteId, userInfo } from "../../../atoms";
import "./TablePage.css";
import { Dialog, DialogContent } from "../../Dialog/Dialog";

const TablePage = (props) => {
  const { isOpen, handleDialogClose, popupcontent } = props;
  const todayDate = new Date();
  useEffect(() => {
  }, [popupcontent]);

  const { toPDF, targetRef } = usePDF({ filename: "report.pdf" });

  const webApi = new WebApimanager();

  const [tableData, setTableData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [site, setSite] = useRecoilState(siteId);
  const [siteData, setSiteData] = useState([]);
  const [issuedRo, setIssuedRo] = useState([]);
  const [data, setData] = useState([]);
  //for Architect

  const [architectscrevisions, setArchitectSCRevisions] = useState([]);
  const [architectrohardcopyrevisions, setArchitectRoHardCopyRevisions] =
    useState([]);
  const [architectpendingscrevisions, setArchitectPendingSCRevisions] =
    useState([]);
  const [
    architectpendinghardcopyrevisions,
    setArchitectPendingHardcopyRevisions,
  ] = useState([]);

  //for RO
  const [roscrevisions, setROSCRevisions] = useState([]);
  const [rohardcopyrevisions, setRoHardCopyRevisions] = useState([]);
  const [ropendingscrevisions, setRoPendingSCRevisions] = useState([]);
  const [ropendinghardcopyrevisions, setRoPendingHardcopyRevisions] = useState(
    []
  );
  const [rfiraised, setRfiRaised] = useState([]);
  const [rfirequested, setRfiRequested] = useState([]);
 


  //for sitehead drawing
  const [acceptedRoHardCopyRevisions, setAcceptedRoHardCopyRevisions] =useState([]);
  const [pendingacceptedRoHardCopyRevisions,setPendingAcceptedRoHardCopyRevisions,] = useState([]);
  const [acceptedRORevisions, setAcceptedRORevisionsInternal] = useState([]);
  const [pendingAcceptedRORevisions, setPendingAcceptedRORevisionsInternal] =useState([]);

  //for architect and sitehead
  const [rfiData, setRfiData] = useState([]);
  //for all
  const [registerdata, setRegisterData] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1000;

  // Calculate total pages
  const totalPages = useMemo(
    () =>
      Math.ceil(
        (Array.isArray(tableData) ? tableData.length : 0) / itemsPerPage
      ),
    [tableData.length]
  );

  const tableType = popupcontent?.reportType || "defaultType";

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const siteResponse = await webApi.get("api/sites/getAllSitesInfo");
        setSiteData(siteResponse.data.sites);
        console.log("Site Name Response:", siteResponse.data.sites);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSiteData();
  }, []);

  const matchedSite = siteData.find((siteItem) => siteItem._id === site);

  const now = new Date();

  const day = now.toLocaleDateString("en-US", { day: "numeric" });
  const month = now.toLocaleDateString("en-US", { month: "long" });
  const year = now.toLocaleDateString("en-US", { year: "numeric" });

  const time = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
  useEffect(() => {

    if (
      (popupcontent.reportType &&
        popupcontent.designConsultant &&
        popupcontent.fromDate &&
        popupcontent.toDate) ||
      (popupcontent.month && popupcontent.year) ||
      popupcontent.timePeriod
    ) {
      const fetchData = async () => {
        try {
          let apiEndpoint;
          if (props.type === "architect") {
            apiEndpoint = "report";
          } else if (props.type === "SiteHead") {
            if (popupcontent.designConsultant !== "All") {
              apiEndpoint = "SiteHeadReport";
            } else {
              apiEndpoint = "SiteHeadReportsOfAllConsultants";
            }
          }else{
            if (popupcontent.designConsultant !== "All") {
              apiEndpoint = "RoReport";
            } else {
              apiEndpoint = "RoReportsOfAllConsultants?";
            }
          }
          

          let apiUrl = `api/pdf/${apiEndpoint}`;
          if (popupcontent.designConsultant !== "All" || props.type === "SiteHead") {
            apiUrl += `?reportType=${popupcontent.reportType}&designDrawingConsultantId=${popupcontent.designConsultant}`;
          }else{
            apiUrl += `reportType=${popupcontent.reportType}&designDrawingConsultantId=${popupcontent.designConsultant}`;
          }

          if (site) {
            apiUrl += `&siteId=${site}`;
          }

          if (popupcontent.fromDate && popupcontent.toDate) {
            apiUrl += `&selectTimePeriod=byDate&fromDate=${popupcontent.fromDate}&toDate=${popupcontent.toDate}`;
          } else if (popupcontent.timePeriod === "fromBeginningToTillDate") {
            apiUrl += `&selectTimePeriod=${popupcontent.timePeriod}`;
          } else {
            apiUrl += `&selectTimePeriod=byMonth&month=${popupcontent.month}&year=${popupcontent.year}`;
          }

          const response = await webApi.get(apiUrl);

          console.log(response, "responce data");
          const data = await response.data;
          if (props.type === "architect") {
            if (popupcontent.reportType === "drawing") {
              const acceptedArchitectRevisions = data.cleanedData;
              setArchitectSCRevisions(acceptedArchitectRevisions);
              setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
              const acceptedROHardCopyRevisions = data.cleanedData;
              setArchitectRoHardCopyRevisions(acceptedROHardCopyRevisions);
            } else if (popupcontent.reportType === "pending") {
              const acceptedArchitectRevisions = data.cleanedData;
              setArchitectPendingSCRevisions(
                Array.isArray(acceptedArchitectRevisions)
                  ? acceptedArchitectRevisions.filter((item) => item.acceptedArchitectRevisions?.length === 0)
                  : []
              );
              setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
              const acceptedROHardCopyRevisions = data.cleanedData;
              setArchitectPendingHardcopyRevisions(acceptedROHardCopyRevisions);
            } else if (popupcontent.reportType === "RFI") {
              const acceptedArchitectRevisions = data.cleanedData;
              setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setRfiData(acceptedArchitectRevisions);
              setTableData(data.cleanedData);
            } else if (popupcontent.reportType === "register") {
              const registerdata = data.cleanedData;
              setRegisterData(registerdata);
              setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
            }
          } else if (props.type === "RO") {
           
            if (popupcontent.reportType === "drawing") {
              if (popupcontent.fromtoType === "architect") {
                const acceptedRoHardCopyRevisions = data.cleanedData;
                setRoHardCopyRevisions(acceptedRoHardCopyRevisions);
                setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
                const acceptedArchitectRevisions =  data.cleanedData;
                setROSCRevisions(acceptedArchitectRevisions);
              } else {
                const acceptedRORevisions = data.cleanedData;
                setROSCRevisions(acceptedRORevisions);
                setStartDate(formatDate(data.startDate));
                setTableData(data.cleanedData);
              setEndDate(formatDate(data.endDate));
                const acceptedSiteHeadHardCopyRevisions = data.cleanedData;
                setRoHardCopyRevisions(acceptedSiteHeadHardCopyRevisions);
                setTableData(data.cleanedData);
              }
            } else if (popupcontent.reportType === "pending") {
              if (popupcontent.fromtoType === "architect") {
                const acceptedRoHardCopyRevisions = data.cleanedData;
                setRoPendingHardcopyRevisions(acceptedRoHardCopyRevisions);
                setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
                const acceptedArchitectRevisions = data.cleanedData;
                setRoPendingSCRevisions(acceptedArchitectRevisions);
              } else {
                const acceptedRORevisions = data.cleanedData;
                setRoPendingSCRevisions(acceptedRORevisions);
                setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
                const acceptedSiteHeadHardCopyRevisions = data.cleanedData;
                setRoPendingHardcopyRevisions(acceptedSiteHeadHardCopyRevisions);
              }
            } else if (popupcontent.reportType === "RFI") {
              if (popupcontent.fromtoType === "architect") {
                const acceptedArchitectRevisions = data.architectureRequests;
                setRfiRaised(acceptedArchitectRevisions);
                setTableData(data.architectureRequests);
                setStartDate(formatDate(data.architectStartDate));
              setEndDate(formatDate(data.architectEndDate));
              } else {
                const acceptedRORevisions = data.siteLevelRequests;
                setRfiRequested(acceptedRORevisions);
                setStartDate(formatDate(data.siteStartDate));
              setEndDate(formatDate(data.siteEndDate));
              setTableData(data.siteLevelRequests);
              }
             
            } else if (popupcontent.reportType === "register") {
              const registerdata = data.cleanedData;
              setRegisterData(registerdata);
              setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
            }
          } else {
            if (props.type === "SiteHead") {
              if (popupcontent.reportType === "drawing") {
                if (popupcontent.fromtoType === "ro") {
                  const acceptedRoHardCopyRevisionsInternal = data.cleanedData;
                  setAcceptedRoHardCopyRevisions(
                    acceptedRoHardCopyRevisionsInternal
                  );
                  setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
                  const acceptedRORevisionsInternal = data.cleanedData;
                  setAcceptedRORevisionsInternal(acceptedRORevisionsInternal);
                  setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
                } else {
                  const acceptedRORevisionsInternal = data.cleanedData;
                  setAcceptedRORevisionsInternal(acceptedRORevisionsInternal);
                  setTableData(data.cleanedData);
                }
              } else if (popupcontent.reportType === "pending") {
                if (popupcontent.fromtoType === "ro") {
                  const acceptedRoHardCopyRevisionsInternal = data.cleanedData;
                  setPendingAcceptedRoHardCopyRevisions(
                    acceptedRoHardCopyRevisionsInternal
                  );
                  setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
                  const acceptedRORevisionsInternal = data;
                  setPendingAcceptedRORevisionsInternal(
                    acceptedRORevisionsInternal
                  );
                } else {
                  const acceptedRORevisionsInternal = data.cleanedData;
                  setPendingAcceptedRoHardCopyRevisions(
                    acceptedRORevisionsInternal
                  );
                  setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
                }
              } else if (popupcontent.reportType === "RFI") {
                const acceptedArchitectRevisions = data.cleanedData;
                setRfiData(acceptedArchitectRevisions);
                setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
              } else if (popupcontent.reportType === "register") {
                const registerdata = data.cleanedData;
                setRegisterData(registerdata);
                setStartDate(formatDate(data.startDate));
              setEndDate(formatDate(data.endDate));
              setTableData(data.cleanedData);
              }

              setLoaded(true);
            } else {
            }
          }
          setLoaded(true);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [popupcontent.reportType, props.type]);

  useEffect(() => {
  }, [
    architectscrevisions,
    architectrohardcopyrevisions,
    rfiData,
    roscrevisions,
    rohardcopyrevisions,
    rfiraised,
    rfirequested,
  ]);
 
const [startDate,setStartDate ]= useState("")
const [endDate,setEndDate ]= useState("")
  useEffect(() => {
    if (
      popupcontent.selectViewDownload === "Download" &&
      tableData.length > 0
    ) {
      switch (popupcontent.fileFormat) {
        case "PDF":
          toPDF().then(() => {
            handleDialogClose();
          });
          break;

        case "XLS":
          downloadXLS();
          break;

        case "CSV":
          downloadCSV();
          break;

        default:
          break;
      }
    }
  }, [tableData.length > 0]);

  const downloadXLS = () => {
    const xlsData = tableData.map((row, index) => ({
      "S.No": index + 1,
      "Drawing No": row.drawingNo,
      "Drawing Consultant": row.designDrawingConsultant.role,
      "Drawing Category": row.category?.category || "",
      "Drawing Title": row.drawingTitle,
      [props.type === "architect"
        ? "Accepted RO Submission Date"
        : "Accepted Site Submission Date"]:
        props.type === "architect"
          ? formatDate(row.acceptedROSubmissionDate)
          : formatDate(row.acceptedSiteSubmissionDate),
      "Final Submission Date":
        (props.type === "architect"
          ? formatDate(row.acceptedArchitectRevisions[0]?.softCopySubmittedDate)
          : formatDate(row.acceptedRORevisions[0]?.softCopySubmittedDate)) ||
        "N/A",
      "Due/Overdue": calculateDueDays(
        row.acceptedSiteSubmissionDate,
        row.acceptedRORevisions[0]?.softCopySubmittedDate
      ),
      Revision:
        props.type === "architect"
          ? row.acceptedArchitectRevisions.length > 0
            ? row.acceptedArchitectRevisions.slice(-1)[0].revision
            : "NA"
          : row.acceptedRORevisions.length > 0
          ? row.acceptedRORevisions.slice(-1)[0].revision
          : "NA",
    }));
    // Implement XLS download logic here, e.g., using a library like SheetJS (xlsx)
    // Example:
    const xlsx = require("xlsx");
    const worksheet = xlsx.utils.json_to_sheet(xlsData);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Report");
    xlsx.writeFile(workbook, "report.xlsx");
    handleDialogClose();
  };

  const downloadCSV = () => {
    const csvData = tableData.map((row, index) => ({
      "S.No": index + 1,
      "Drawing No": row.drawingNo,
      "Drawing Consultant": row.designDrawingConsultant.role,
      "Drawing Category": row.category?.category || "",
      "Drawing Title": row.drawingTitle,
      [props.type === "architect"
        ? "Accepted Ro Submission Date"
        : "Accepted Site Submission Date"]:
        props.type === "architect"
          ? formatDate(row.acceptedROSubmissionDate)
          : formatDate(row.acceptedSiteSubmissionDate),
      "Final Submission Date":
        (props.type === "architect"
          ? formatDate(row.acceptedArchitectRevisions[0]?.softCopySubmittedDate)
          : formatDate(row.acceptedRORevisions[0]?.softCopySubmittedDate)) ||
        "N/A",
      "Due/Overdue": calculateDueDays(
        row.acceptedSiteSubmissionDate,
        row.acceptedRORevisions[0]?.softCopySubmittedDate
      ),
      Revision:
        props.type === "architect"
          ? row.acceptedArchitectRevisions.length > 0
            ? row.acceptedArchitectRevisions.slice(-1)[0].revision
            : "NA"
          : row.acceptedRORevisions.length > 0
          ? row.acceptedRORevisions.slice(-1)[0].revision
          : "NA",
    }));
    // Implement CSV download logic here
    // Example:
    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(csvData[0]).join(",") +
      "\n" +
      csvData.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    handleDialogClose();
  };


  const renderTableHeaderData = (type) => {
    if (props.type === "architect") {
      switch (popupcontent.reportType) {
        case "drawing":
          switch (type) {
            case "architectscrevisions":
              return (
                <>
                     {/* <p style={{color:'black'}}>Upload To RO </p> */}
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px', width: "5%", paddingLeft: "10px" ,backgroundColor:"white"}}>S.No</th>
                    <th style={{height:'40px', width: "10%" ,backgroundColor:"white"}}>DRWG No</th>
                    <th style={{height:'40px', width: "10%",backgroundColor:"white" }}>Consultant</th>
                    <th style={{height:'40px', width: "10%",backgroundColor:"white" }}>Drawing Category</th>
                    <th style={{height:'40px', width: "10%",backgroundColor:"white" }}>Drawing Title</th>
                    <th style={{height:'40px', width: "13%", paddingLeft: "10px" ,backgroundColor:"white"}}>
                      Accepted RO Submission Date{" "}
                    </th>
                    <th style={{height:'40px', width: "10%",backgroundColor:"white" }}>Revision</th>
                  </tr>
                </>
              );
            case "architectrohardcopyrevisions":
              return (
                <>
                  {/* <p style={{ margin: "15px", display: "inline-block" ,color:'black'}}>
                    Received From RO 
                  </p> */}
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                      Received Date{" "}
                    </th>
                    <th style={{height:'40px',backgroundColor:"white", width: "13%" }}> Received Copies</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                  </tr>
                </>
              );
            default:
              return null;
          }
          break;
        case "RFI":
          if (type === "rfiData") {
            return (
              <tr style={{border:"1px solid black"}}>
                <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Number</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Design Consultant</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                <th style={{height:'40px',backgroundColor:"white", width: "13%" }}>Requested Date</th>
                <th style={{height:'40px',backgroundColor:"white", width: "13%" }}>Expected Date</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Due/OverDue</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Status</th>
              </tr>
            );
          }
          break;
        case "pending":
          switch (type) {
            case "architectpendingscrevisions":
              return (
                <>
                  {/* <p style={{color:'black'}}>Upload To RO </p> */}
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>DRWG No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                      Accepted RO Submission Date
                    </th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Due/OverDue</th>
                  </tr>
                </>
              );
            case "architectpendinghardcopyrevisions":
              return (
                <>
                  <p style={{ margin: "15px", display: "inline-block" , color:'black'}}>
                    Received From RO  
                  </p>
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                  </tr>
                </>
              );
            
          }
          break;
          case "register":
            switch (type) {
              case "registerdata":
                return (
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Drawing No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Design Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Created Date</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Accepted RO Submission Date</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>
                      Accepted Site Submission Date
                    </th>
                  </tr>
                  
                );
              default:
                return null;
            }
            break;
          default:
            return null;
        
      }
    } else if (props.type === "RO") {
    
      switch (popupcontent.reportType) {
        case "drawing":
          switch(popupcontent.fromtoType ){
            case "architect" :
              switch (type) {
                case "roscrevisions":
                  return (
                    <>
                      <p style={{color:'black'}}>Issued From Architect</p>
                      <tr style={{border:"1px solid black"}}>
                        <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                        <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>DRWG No</th>
                        <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                        <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                        <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                        <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                          Architect soft Submission Date
                        </th>
                        <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                      </tr>
                    </>
                  );
                  case "rohardcopyrevisions":
                    return (
                      <>
                        <p style={{ margin: "15px", display: "inline-block" ,color:'black'}}>
                          Collected from Architect
                        </p>
                        <tr style={{border:"1px solid black"}}>
                          <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                          <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing No</th>
                          <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                          <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                          <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                          <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                          Architect Hard Copy Received Date
                          </th>
                          <th>No of Received Copies</th>
                          <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                        </tr>
                      </>
                    );
          }
          case "siteHead" :
            switch (type) {
         
            case "roscrevisions":
              return (
                <>
                  <p style={{color:'black'}}>Upload To SiteHead</p>

                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>DRWG No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                    Accepted Site Submission Date
                    </th>
                    <th>Final Submission Date</th>
                    <th>Due/Overdue</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                  </tr>
                </>
              );

           
              case "rohardcopyrevisions":
              return (
                <>
                  <p style={{ margin: "15px", display: "inline-block",color:'black' }}>
                    Received from SiteHead
                  </p>
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                    Site Recieved Date
                    </th>
                    <th>Recieved Copies</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                  </tr>
                </>
              );
            default:
              return null;
          }
        }
          break;
        case "RFI":
          if (type === "rfirequested" ) {
            return (
              <>
              <p style={{color:'black'}}>Requested From SiteHead</p>
              <tr style={{border:"1px solid black"}}>
                <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Number</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Design Consultant</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                <th style={{height:'40px',backgroundColor:"white", width: "13%" }}>Requested Date</th>
                <th style={{height:'40px',backgroundColor:"white", width: "13%" }}>Expected Date</th>
                <th>Due/Overdue</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Status</th>
              </tr>
              </>
            );
          }
          else{
            return (
              <>
              <p style={{color:'black'}}>Raised To Architect</p>
              <tr style={{border:"1px solid black"}}>
                <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Number</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Design Consultant</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                <th style={{height:'40px',backgroundColor:"white", width: "13%" }}>Raised Date</th>
                <th style={{height:'40px',backgroundColor:"white", width: "13%" }}>Expected Date</th>
                <th>Due/Overdue</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Status</th>
              </tr>
              </>
            );
          }
          break;
        case "pending":
          switch(popupcontent.fromtoType ){
            case "architect" :
              switch (type) {
           
                case "ropendingscrevisions":
                  return (
                    <>
                      <p style={{color:'black'}}>Issued From Architect</p>
    
                      <tr style={{border:"1px solid black"}}>
                        <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                        <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>DRWG No</th>
                        <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                        <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                        <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                        <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                        Accepted RO Submission Date
                        </th>
                      </tr>
                    </>
                  );
                  case "ropendinghardcopyrevisions":
                    return (
                      <>
                        <p style={{ margin: "15px", display: "inline-block" ,color:'black'}}>
                          Collected from Architect
                        </p>
                        <tr style={{border:"1px solid black"}}>
                          <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                          <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing No</th>
                          <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                          <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                          <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                        </tr>
                      </>
                    );
          }
          case "siteHead" :
            switch (type) {
         
            case "ropendingscrevisions":
              return (
                <>
                  <p style={{color:'black'}}>Upload To SiteHead</p>

                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>DRWG No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                    Accepted Site Submission Date
                    </th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Due/Overdue</th>
                  </tr>
                </>
              );

           
              case "ropendinghardcopyrevisions":
              return (
                <>
                  <p style={{ margin: "15px", display: "inline-block",color:'black' }}>
                    Received from SiteHead
                  </p>
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                  
                  </tr>
                </>
              );
            default:
              return null;
          }
        }
          break;
          case "register":
            switch (type) {
              case "registerdata":
                return (
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Drawing No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Design Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Created Date</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Accepted RO Submission Date</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>
                      Accepted Site Submission Date
                    </th>
                  </tr>
                  
                );
              default:
                return null;
            }
            break;
          default:
            return null;
        
      }
    } else if (props.type === "SiteHead") {
      
      switch (popupcontent.reportType) {
        case "drawing":
          switch(popupcontent.fromtoType ){
            case "ro" :
          switch (type) {
            case "acceptedRORevisions":
              return (
                <>
                  <p style={{color:'black'}}>Issued From RO</p>
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>DRWG No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                      RO Soft Copy Submission Date
                    </th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                  </tr>
                </>
              );
            case "acceptedRoHardCopyRevisions":
              return (
                <>
                  <p style={{ margin: "15px", display: "inline-block",color:'black' }}>
                    Collected from RO
                  </p>
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                      RO Hard Copy Submission Date
                    </th>
                    <th>Received Copies</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                  </tr>
                </>
              );
            default:
              return null;
          }
          case "siteLevel" :
            switch (type) {
              case "acceptedRORevisions":
                return (
                  <>
                    <p style={{color:'black'}}>Upload to Sitelevel</p>
                    <tr style={{border:"1px solid black"}}>
                      <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>DRWG No</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                      Accepted Site Submission Date	
                      </th>
                      <th>Due/OverDue</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                    </tr>
                  </>
                );
             
              default:
                return null;
            }
        }
          break;
        case "RFI":
          if (type === "rfiData") {
            return (
              <tr style={{border:"1px solid black"}}>
                <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Number</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Design Consultant</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                <th style={{height:'40px',backgroundColor:"white", width: "13%" }}>Raised Date</th>
                <th style={{height:'40px',backgroundColor:"white", width: "13%" }}>Expected Date</th>
                <th>Due/OverDue</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Revision</th>
                <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Status</th>
              </tr>
            );
          }
          break;
        case "pending":
          switch(popupcontent.fromtoType ){
            case "ro" :
          switch (type) {
            case "pendingAcceptedRORevisions":
              return (
                <>
                  <p style={{color:'black'}}>Issued From RO</p>
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>DRWG No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                    Accepted RO Submission Date
                    </th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Due/OverDue</th>
                  </tr>
                </>
              );
            case "pendingacceptedRoHardCopyRevisions":
              return (
                <>
                  <p style={{ margin: "15px", display: "inline-block",color:'black'}}>
                    Collected from RO
                  </p>
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                   
                  </tr>
                </>
              );
            default:
              return null;
          }
          case "siteLevel" :
            switch (type) {
              case "pendingAcceptedRORevisions":
                return (
                  <>
                    <p style={{color:'black'}}>Upload to Sitelevel</p>
                    <tr style={{border:"1px solid black"}}>
                      <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>DRWG No</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Consultant</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Category</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Drawing Title</th>
                      <th style={{height:'40px',backgroundColor:"white", width: "13%", paddingLeft: "10px" }}>
                      Accepted Site Submission Date	
                      </th>
                      <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Due/Overdue</th>
                    </tr>
                  </>
                );
             
              default:
                return null;
            }
        }
          break;
          case "register":
            switch (type) {
              case "registerdata":
                return (
                  <tr style={{border:"1px solid black"}}>
                    <th style={{height:'40px',backgroundColor:"white", width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Drawing No</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "10%" }}>Design Consultant</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Drawing Category</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Drawing Title</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Created Date</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>Accepted RO Submission Date</th>
                    <th style={{height:'40px',backgroundColor:"white", width: "15%" }}>
                      Accepted Site Submission Date
                    </th>
                  </tr>
                  
                );
              default:
                return null;
            }
            break;
          default:
            return null;
       
      }
    } 


  };
  const renderTableBodyData = (type, data) => {
  
    
    if (props.type === "architect") {
      return data?.map((item, index) => (
        <tr key={item.id} >
          <td style={{paddingLeft:'10px'}}>{index + 1}</td>
          {popupcontent.reportType === "drawing" &&
            type === "architectscrevisions" && (
              <>
                <td style={{ padding: '10px' }}>{item?.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item?.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item?.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item?.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>
                  {item?.acceptedArchitectRevisions?.length > 0 &&
                  item.acceptedArchitectRevisions[0].softCopySubmittedDate
                    ? new Date(
                        item.acceptedArchitectRevisions[0].softCopySubmittedDate
                      ).toLocaleDateString()
                    : "N/A"}
                </td>

                <td style={{ padding: '10px' }}>
                  {item?.acceptedArchitectRevisions?.length > 0 &&
                  item.acceptedArchitectRevisions[0].revision
                    ? item.acceptedArchitectRevisions[0].revision
                    : "N/A"}
                </td>
              </>
            )}

          {popupcontent.reportType === "drawing" &&
            type === "architectrohardcopyrevisions" && (
              <>
                {console.log(item, "item")}
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>

                <td style={{ padding: '10px' }}>
                  {item?.acceptedROHardCopyRevisions?.length > 0 &&
                  item.acceptedROHardCopyRevisions[0].hardCopySubmittedDate
                    ? new Date(
                        item.acceptedROHardCopyRevisions[0].hardCopySubmittedDate
                      ).toLocaleDateString()
                    : "N/A"}
                </td>

                <td style={{ padding: '10px' }}>
                  {item.acceptedROHardCopyRevisions?.length > 0 &&
                  item.acceptedROHardCopyRevisions[0].receivedCopies
                    ? item.acceptedROHardCopyRevisions[0].receivedCopies
                    : "N/A"}
                </td>

                <td style={{ padding: '10px' }}>
                  {item?.acceptedROHardCopyRevisions?.length > 0 &&
                  item.acceptedROHardCopyRevisions[0].revision
                    ? item.acceptedROHardCopyRevisions[0].revision
                    : "N/A"}
                </td>
              </>
            )}

          
          {popupcontent.reportType === "RFI" && type === "rfiData" && (
            <>
              <td style={{ padding: '10px' }}>{item?.drawingNo }</td>
              <td style={{ padding: '10px' }}>{item.drawingId.designDrawingConsultant?.role || "N/A"}</td>
              <td style={{ padding: '10px' }}>{item.drawingId.category?.category || "N/A"}</td>
              <td style={{ padding: '10px' }}>{item.drawingId.drawingTitle || "N/A"}</td>
              <td style={{ padding: '10px' }}>{formatDate(item.requestedDate) || "N/A"}</td>
              <td style={{ padding: '10px' }}>{formatDate(item.expectedDate) || "N/A"}</td>
              <td style={{ padding: '10px' }}>
  {calculateDueDays(item.requestedDate, item.expectedDate)}
</td>
              <td style={{ padding: '10px' }}>{item.revision || "N/A"}</td>
              <td style={{ padding: '10px' }}>{item.status || "N/A"}</td>
            </>
          )}
          {popupcontent.reportType === "pending" &&
            type === "architectpendingscrevisions" && (
                <>           
        <td style={{ padding: '10px' }}>{item.drawingNo}</td>
        <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
        <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
        <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
        <td style={{ padding: '10px' }}>
          {item.acceptedROSubmissionDate
            ? new Date(
                item?.acceptedROSubmissionDate
              ).toLocaleDateString()
            : "N/A"}
        </td>
          <td style={{ padding: '10px' }}>
            {calculateDueDays(item.requestedDate, todayDate)}
          </td>


                </>  
            )
            }
          {popupcontent.reportType === "pending" &&
            type === "architectpendinghardcopyrevisions" && (
              
                  <>
                    <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                    <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                    <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                    <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
               
              </>
            )}

          {/* Register Report Type */}
          {popupcontent.reportType === "register" &&
            type === "registerdata" && (
              <>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.creationDate)}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.acceptedROSubmissionDate)}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.acceptedSiteSubmissionDate)}</td>
              </>
            )}
        </tr>
      ));
    } else if (props.type === "RO") {
      return data.map((item, index) => (
        <>
          {popupcontent.reportType === "drawing" && type === "roscrevisions" &&  popupcontent.fromtoType === "architect" &&  item.acceptedArchitectRevisions?.length > 0 &&(
      <>
       <tr key={item.id} >
       <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>
                {item.acceptedArchitectRevisions?.length > 0 &&
 item.acceptedArchitectRevisions[item.acceptedArchitectRevisions.length - 1]
    ?.softCopySubmittedDate
  ? new Date(
      item.acceptedArchitectRevisions[item.acceptedArchitectRevisions.length - 1]
        .softCopySubmittedDate
    ).toLocaleDateString()
  : "N/A"}

                </td>
                <td style={{ padding: '10px' }}>
                   {item.acceptedArchitectRevisions.length >
                                  0 ? (
                                    <div>
                                      {item.acceptedArchitectRevisions
                                        .map(
                                          (revisionItem, idx) =>
                                            `${revisionItem.revision}` || "NA"
                                        )
                                        .join(", ")}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                </td>
                </tr>
          
      </>
    )}
    {popupcontent.reportType === "drawing" && type === "rohardcopyrevisions" && popupcontent.fromtoType === "architect" && item.acceptedROHardCopyRevisions?.length >0 && (
      <>
       <tr key={item.id}>
       <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>
                  {item.acceptedROHardCopyRevisions?.[0]?.hardCopySubmittedDate
                    ? new Date(
                        item.acceptedROHardCopyRevisions[0].hardCopySubmittedDate
                      ).toLocaleDateString()
                    : "N/A"}
                </td>
                <td style={{ padding: '10px' }}> {item.acceptedROHardCopyRevisions.length >
                                  0 ? (
                                    <div>
                                      {item.acceptedROHardCopyRevisions
                                        .map(
                                          (revisionItem, idx) =>
                                            `${revisionItem.receivedCopies}` || "NA"
                                        )
                                        .join(", ")}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                  </td>
                <td style={{ padding: '10px' }}> {item.acceptedROHardCopyRevisions.length >
                                  0 ? (
                                    <div>
                                      {item.acceptedROHardCopyRevisions
                                        .map(
                                          (revisionItem, idx) =>
                                            `${revisionItem.revision}` || "NA"
                                        )
                                        .join(", ")}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                  </td>
              </tr>
      </>
    )}
 {popupcontent.reportType === "drawing" && type === "roscrevisions" &&  popupcontent.fromtoType === "siteHead" && item.acceptedRORevisions?.length >0 && (
      <>
     
                <tr key={item.id}>
                  <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item?.acceptedSiteSubmissionDate || "N/A")}</td>
                <td style={{ padding: '10px' }}>
                  {item.acceptedRORevisions?.[0]?.softCopySubmittedDate
                    ? new Date(
                        item.acceptedRORevisions[0].softCopySubmittedDate
                      ).toLocaleDateString()
                    : "N/A"}
                </td>
               
                <td style={{ padding: '10px' }}>{calculateDueDays(item?.acceptedSiteSubmissionDate,todayDate)}</td>
                <td style={{ padding: '10px' }}>{item.acceptedRORevisions.length >
                                  0 ? (
                                    <div>
                                      {item.acceptedRORevisions
                                        .map(
                                          (revisionItem, idx) =>
                                            `${revisionItem.revision}` || "NA"
                                        )
                                        .join(", ")}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                  
                </td>
          </tr>
      </>
    )}
    {popupcontent.reportType === "drawing" && type === "rohardcopyrevisions" && popupcontent.fromtoType === "siteHead" && item.acceptedSiteHeadHardCopyRevisions.length >0 &&  (
      <>
       <tr key={item.id}>
       <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>
                  {item.acceptedSiteHeadHardCopyRevisions?.[0]?.hardCopySubmittedDate
                    ? new Date(
                        item.acceptedSiteHeadHardCopyRevisions[0].hardCopySubmittedDate
                      ).toLocaleDateString()
                    : "N/A"}
                </td>
                <td style={{ padding: '10px' }}> {item.acceptedSiteHeadHardCopyRevisions.length >
                                  0 ? (
                                    <div>
                                      {item.acceptedSiteHeadHardCopyRevisions
                                        .map(
                                          (revisionItem, idx) =>
                                            `${revisionItem.receivedCopies}` || "NA"
                                        )
                                        .join(", ")}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                  </td>
                <td style={{ padding: '10px' }}>{item.acceptedSiteHeadHardCopyRevisions.length >
                                  0 ? (
                                    <div>
                                      {item.acceptedSiteHeadHardCopyRevisions
                                        .map(
                                          (revisionItem, idx) =>
                                            `${revisionItem.revision}` || "NA"
                                        )
                                        .join(", ")}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}</td>
              </tr>
      </>
    )}

       
          {popupcontent.reportType === "RFI" &&
            type === "rfiraised" &&
            popupcontent.fromtoType === "architect" && (
              <>
                   <tr key={item.id}>
                   <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.drawingId.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingId.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingId.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.requestedDate) || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.expectedDate) || "N/A"}</td>
                <td style={{ padding: '10px' }}>{calculateDueDays(item.requestedDate,item.expectedDate)}</td>
                <td style={{ padding: '10px' }}>{item.revision || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.status || "N/A"}</td>
                </tr>
              </>
            )}
          {popupcontent.reportType === "RFI" &&
            type === "rfirequested" &&
            popupcontent.fromtoType === "siteHead" && (
              <>
                   <tr key={item.id}>
                   <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.drawingId.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingId.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingId.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.requestedDate) || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.expectedDate) || "N/A"}</td>
                <td style={{ padding: '10px' }}>{calculateDueDays(item.requestedDate,item.expectedDate)}</td>
                <td style={{ padding: '10px' }}>{item.revision || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.status || "N/A"}</td>
                </tr>
              </>
            )}
        
        {popupcontent.reportType === "pending" && type === "ropendingscrevisions" &&  popupcontent.fromtoType === "architect" &&  item.acceptedArchitectRevisions?.length === 0 &&(
      <>
       <tr key={item.id}>
       <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item?.acceptedROSubmissionDate)}</td>
                </tr>
          
      </>
    )}
    {popupcontent.reportType === "pending" && type === "ropendinghardcopyrevisions" && popupcontent.fromtoType === "architect" && item.acceptedROHardCopyRevisions?.length === 0 && (
      <>
       <tr key={item.id}>
        <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                
            
              </tr>
      </>
    )}
 {popupcontent.reportType === "pending" && type === "ropendingscrevisions" &&  popupcontent.fromtoType === "siteHead" && item.acceptedRORevisions?.length === 0 && (
      <>
     
                <tr key={item.id}>
                  <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item?.acceptedSiteSubmissionDate || "N/A"}</td>
                <td style={{ padding: '10px' }}>{calculateDueDays(item?.acceptedSiteSubmissionDate,todayDate)}</td>
              
          </tr>
      </>
    )}
    {popupcontent.reportType === "pending" && type === "ropendinghardcopyrevisions" && popupcontent.fromtoType === "siteHead" && item.acceptedSiteHeadHardCopyRevisions?.length === 0 &&  (
      <>
     
       <tr key={item.id}>
        <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
              </tr>
      </>
    )}

{popupcontent.reportType === "register" &&
            type === "registerdata" && (
              <>
                   <tr key={item.id}>
                    <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.creationDate)}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.acceptedROSubmissionDate)}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.acceptedSiteSubmissionDate)}</td>
                </tr>
              </>
            )}
        
        </>
      ));
    
    } else if (props.type === "SiteHead") {
      return data.map((item, index) => (
        <>
                
          {popupcontent.reportType === "drawing" && type === "acceptedRORevisions" &&  popupcontent.fromtoType === "ro" &&  item.acceptedRORevisions?.length > 0 &&(
      <>
       <tr key={item.id}>
        <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>
                  {item.acceptedRORevisions?.[0]?.softCopySubmittedDate
                    ? new Date(
                        item.acceptedRORevisions[0].softCopySubmittedDate
                      ).toLocaleDateString()
                    : "N/A"}
                </td>
                <td style={{ padding: '10px' }}>
                   {item.acceptedRORevisions.length >
                                  0 ? (
                                    <div>
                                      {item.acceptedRORevisions
                                        .map(
                                          (revisionItem, idx) =>
                                            `${revisionItem.revision}` || "NA"
                                        )
                                        .join(", ")}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                </td>
                </tr>
          
      </>
    )}
    {popupcontent.reportType === "drawing" && type === "acceptedRoHardCopyRevisions" && popupcontent.fromtoType === "ro" && item.acceptedSiteHeadHardCopyRevisions?.length >0 && (
      <>
       <tr key={item.id}>
        <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>
                  {item.acceptedSiteHeadHardCopyRevisions
?.[0]?.hardCopySubmittedDate
                    ? new Date(
                        item.acceptedSiteHeadHardCopyRevisions
                        [0].hardCopySubmittedDate
                      ).toLocaleDateString()
                    : "N/A"}
                </td>
                <td style={{ padding: '10px' }}> {item.acceptedSiteHeadHardCopyRevisions
.length >
                                  0 ? (
                                    <div>
                                      {item.acceptedSiteHeadHardCopyRevisions

                                        .map(
                                          (revisionItem, idx) =>
                                            `${revisionItem.receivedCopies}` || "NA"
                                        )
                                        .join(", ")}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                  </td>
                  <td style={{ padding: '10px' }}> {item.acceptedSiteHeadHardCopyRevisions
.length >
                                  0 ? (
                                    <div>
                                      {item.acceptedSiteHeadHardCopyRevisions

                                        .map(
                                          (revisionItem, idx) =>
                                            `${revisionItem.revision}` || "NA"
                                        )
                                        .join(", ")}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                  </td>
              </tr>
      </>
    )}
 {popupcontent.reportType === "drawing" && type === "acceptedRORevisions" &&  popupcontent.fromtoType === "siteLevel" && item.acceptedSiteHeadRevisions?.length >0 && (
      <>
     
                <tr key={item.id}>
                 <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item?.acceptedSiteSubmissionDate || "N/A")}</td>
                <td style={{ padding: '10px' }}>{calculateDueDays(item?.acceptedSiteSubmissionDate,todayDate)}</td>
                <td style={{ padding: '10px' }}>
                   {item.acceptedSiteHeadRevisions.length >
                                  0 ? (
                                    <div>
                                      {item.acceptedSiteHeadRevisions
                                        .map(
                                          (revisionItem, idx) =>
                                            `${revisionItem.revision}` || "NA"
                                        )
                                        .join(", ")}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                </td>
                
          </tr>
      </>
    )}
  

       
          {popupcontent.reportType === "RFI" &&
            type === "rfiData" &&
         
              <>
                   <tr key={item.id}>
                    <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.drawingId.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingId.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingId.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.requestedDate) || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.expectedDate) || "N/A"}</td>
                <td style={{ padding: '10px' }}>{calculateDueDays(item.requestedDate,item.expectedDate)}</td>
                <td style={{ padding: '10px' }}>{item.revision || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.status || "N/A"}</td>
                </tr>
              </>
            }
         
        
          {popupcontent.reportType === "pending" && type === "pendingAcceptedRORevisions" &&  popupcontent.fromtoType === "ro" &&  item.acceptedRORevisions?.length === 0 &&(
      <>
       <tr key={item.id}>
        <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.acceptedROSubmissionDate || "N/A"}</td>
                </tr>
          
      </>
    )}
    {popupcontent.reportType === "pending" && type === "pendingacceptedRoHardCopyRevisions" && popupcontent.fromtoType === "ro" && item.acceptedSiteHeadHardCopyRevisions?.length ===0 && (
      <>
       <tr key={item.id}>
        <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
              
               
              </tr>
      </>
    )}
 {popupcontent.reportType === "pending" && type === "pendingAcceptedRORevisions" &&  popupcontent.fromtoType === "siteLevel" && item.acceptedSiteHeadRevisions?.length ===0 && (
      <>
     
                <tr key={item.id}>
                   <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item?.acceptedSiteSubmissionDate || "N/A")}</td>
                <td style={{ padding: '10px' }}>{calculateDueDays(item?.acceptedSiteSubmissionDate,todayDate)}</td>
                
          </tr>
      </>
    )}

{popupcontent.reportType === "register" &&
            type === "registerdata" && (
              <>
                   <tr key={item.id}>
                    <td style={{paddingLeft:'10px'}}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{item.drawingNo}</td>
                <td style={{ padding: '10px' }}>{item.designDrawingConsultant?.role || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.category?.category || "N/A"}</td>
                <td style={{ padding: '10px' }}>{item.drawingTitle || "N/A"}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.creationDate)}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.acceptedROSubmissionDate)}</td>
                <td style={{ padding: '10px' }}>{formatDate(item.acceptedSiteSubmissionDate)}</td>
                </tr>
              </>
            )}
        
        </>
      ));
    
    }
    }
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleDialogClose(); 
      }}
      
      maxWidth="xxl"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
          fontFamily: "Poppins",
          border: "1px solid",
          padding: "10px",
        },
      }}
    >
      <DialogContent style={{ maxHeight: "70vh", overflowY: "auto" }}>
        <div ref={targetRef}>
          {popupcontent.selectViewDownload === "View" && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p></p>
              <RxCross1
                size={15}
                onClick={handleDialogClose}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "5px 55px",
              gap: "20px",
            }}
          >
            <div>
              <p className="HeadingStatus">
                {popupcontent.reportType} - {popupcontent?.consultantRole || "All Consultants"}
              </p>
            </div>
            <div className="project">
              {matchedSite ? (
                <p className="projectTitle">
                  Project:
                  <span
                    className="siteNameProject"
                    style={{ fontWeight: "400" }}
                  >
                    {matchedSite.siteName}
                  </span>
                </p>
              ) : (
                <p>No site Name</p>
              )}
            </div>
            <div className="date-range-container">
              <p className="PeriodTime">Period :</p>
              {(popupcontent.timePeriod === "By Date" ||
                popupcontent.timePeriod === "Last 6 Months") && (
                <>
                  <p style={{ fontWeight: "400" }}>From..</p>
                  <p style={{ fontWeight: "400" }}>{popupcontent.fromDate}</p>
                  <p style={{ fontWeight: "400" }}>To..</p>
                  <p style={{ fontWeight: "400" }}>{popupcontent.toDate}</p>
                </>
              )}
              {popupcontent.timePeriod === "fromBeginningToTillDate" && (
                <>
                  <p style={{ fontWeight: "400" }}>From..</p>
                  <p style={{ fontWeight: "400" }}>{startDate}</p>
                  <p style={{ fontWeight: "400" }}>To..</p>
                  <p style={{ fontWeight: "400" }}>{endDate}</p>
                </>
              )}

              {popupcontent.timePeriod === "By Month" && (
                <>
                  <p style={{ fontWeight: "400" }}>
                    {popupcontent.month}th month
                  </p>
                  <p style={{ fontWeight: "400" }}>{popupcontent.year} year</p>
                </>
              )}
            </div>
          </div>
          <>
          <>
         
            {props.type === "architect" && (
              <>
                {popupcontent.reportType === "drawing" && (
                  <>
                     <p style={{color:'black',width:"100%",textAlign:"center"}}>Upload To RO </p>

                    <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                      <thead style={{color:'black',height:'40px'}}> 
                        {renderTableHeaderData("architectscrevisions")}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "architectscrevisions",
                          architectscrevisions
                        )}
                      </tbody>
                    </table>
                       
                    <p style={{ textAlign:'center',color:'black',marginTop:"50px"}}>
                    Received From RO 
                  </p>
                    <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                      <thead style={{color:'black',height:'40px'}}> 
                        {renderTableHeaderData("architectrohardcopyrevisions")}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "architectrohardcopyrevisions",
                          architectrohardcopyrevisions
                        )}
                      </tbody>
                    </table>
                  </>
                )}
                {popupcontent.reportType === "RFI" && (
                  <>
                    <table style={{textAlign:'center'}}>
                                               <thead style={{color:'black',height:'40px'}}> {renderTableHeaderData("rfiData")}</thead>
                      <tbody>{renderTableBodyData("rfiData", rfiData)}</tbody>
                    </table>
                  </>
                )}
               
                {popupcontent.reportType === "register" && (
                  <>
                    <table style={{textAlign:'center'}}>
                       <thead style={{color:'black',height:'40px'}}> {renderTableHeaderData("registerdata")}</thead>
                      <tbody>
                        {renderTableBodyData("registerdata", registerdata)}
                      </tbody>
                    </table>
                  </>
                )}
              
                {popupcontent.reportType === "pending" && (
                  <>
                     <p style={{color:'black',width:"100%",textAlign:"center"}}>Upload To RO </p>
                    <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                                               <thead style={{color:'black',height:'40px'}}> 
                        {renderTableHeaderData("architectpendingscrevisions")}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "architectpendingscrevisions",
                          architectpendingscrevisions
                        )}
                      </tbody>
                    </table>

                    <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                                               <thead style={{color:'black',height:'40px'}}> 
                        {renderTableHeaderData(
                          "architectpendinghardcopyrevisions"
                        )}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "architectpendinghardcopyrevisions",
                          architectpendinghardcopyrevisions
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}
            {/* RO TAB DATA */}
            {props.type === "RO" && (
              <>
                {popupcontent.reportType === "drawing" && (
                  <>
                    <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                      <thead style={{color:'white'}}> 
                        {renderTableHeaderData("roscrevisions")}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "roscrevisions",
                          roscrevisions
                        )}
                      </tbody>
                    </table>
                    <div style={{ pageBreakBefore: "always" }} />
                    <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                      <thead style={{color:'white'}}> 
                        {renderTableHeaderData("rohardcopyrevisions")}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "rohardcopyrevisions",
                          rohardcopyrevisions
                        )}
                      </tbody>
                    </table>

                  </>
                )}
              
                {popupcontent.reportType === "pending" && (
                  <>
                    <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                        <thead style={{color:'white'}}> 
                        {renderTableHeaderData("ropendingscrevisions")}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "ropendingscrevisions",
                          ropendingscrevisions
                        )}
                      </tbody>
                    </table>
                    <div style={{ pageBreakBefore: "always" }} />
                    <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                                               <thead style={{color:'white'}}> 
                        {renderTableHeaderData("ropendinghardcopyrevisions")}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "ropendinghardcopyrevisions",
                          ropendinghardcopyrevisions
                        )}
                      </tbody>
                    </table>
                  </>
                )}
                {popupcontent.reportType === "RFI" && (
                  <>
                    {popupcontent.fromtoType === "architect" && (
                      <>
                        <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                                                   <thead style={{color:'white'}}> {renderTableHeaderData("rfiraised")}</thead>
                          <tbody>
                            {renderTableBodyData("rfiraised", rfiraised)}
                          </tbody>
                        </table>
                      </>
                    )}
                    {popupcontent.fromtoType === "siteHead" && (
                      <>
                        <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                                                   <thead style={{color:'white'}}> {renderTableHeaderData("rfirequested")}</thead>
                          <tbody>
                            {renderTableBodyData("rfirequested", rfirequested)}
                          </tbody>
                        </table>
                      </>
                    )}
                  </>
                )}
                 {popupcontent.reportType === "register" && (
                  <>
                    <table style={{textAlign:'center',border:"1px solid black",marginTop:"20px"}}>
                                               <thead style={{color:'white'}}> {renderTableHeaderData("registerdata")}</thead>
                      <tbody>
                        {renderTableBodyData("registerdata", registerdata)}
                      </tbody>
                    </table>
                  </>
                )}
              
              </>
            )}
            {/* SITEHEAD TABLE DATA */}
          

            {props.type === "SiteHead" && (
              <>
                {popupcontent.reportType === "drawing" && (
                  <>
                  {popupcontent.fromtoType === 'ro' &&
                  <>
                    <table style={{textAlign:'center'}}>
                                               <thead style={{color:'white'}}> 
                        {renderTableHeaderData(
                          "acceptedRORevisions"
                        )}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "acceptedRORevisions",
                          acceptedRORevisions
                        )}
                      </tbody>
                    </table>

                    <table style={{textAlign:'center'}}>
                                               <thead style={{color:'white'}}> 
                        {renderTableHeaderData("acceptedRoHardCopyRevisions")}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "acceptedRoHardCopyRevisions",
                          acceptedRoHardCopyRevisions
                        )}
                      </tbody>
                    </table>
                    </>}
                    {popupcontent.fromtoType === 'siteLevel' &&
                  <>
                    <table style={{textAlign:'center'}}>
                                               <thead style={{color:'white'}}> 
                        {renderTableHeaderData(
                          "acceptedRORevisions"
                        )}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "acceptedRORevisions",
                          acceptedRORevisions
                        )}
                      </tbody>
                    </table>
                    </>}
                  </>
                )}
               
                {popupcontent.reportType === "RFI" && (
                  <>
                    <table style={{textAlign:'center'}}>
                                               <thead style={{color:'white'}}> {renderTableHeaderData("rfiData")}</thead>
                      <tbody>{renderTableBodyData("rfiData", rfiData)}</tbody>
                    </table>
                  </>
                )}
                 {popupcontent.reportType === "register" && (
                  <>
                    <table style={{textAlign:'center'}}>
                                               <thead style={{color:'white'}}> {renderTableHeaderData("registerdata")}</thead>
                      <tbody>
                        {renderTableBodyData("registerdata", registerdata)}
                      </tbody>
                    </table>
                  </>
                )}
              
                {popupcontent.reportType === "pending" && (
                  <>
                   {popupcontent.fromtoType === 'ro' &&
                  <>
                    <table style={{textAlign:'center'}}>
                                             <thead style={{color:'white'}}> 
                        {renderTableHeaderData(
                          "pendingAcceptedRORevisions"
                        )}
                      </thead>
                      <tbody style={{color:'white',padding:'10px'}}>
                        {renderTableBodyData(
                          "pendingAcceptedRORevisions",
                          pendingAcceptedRORevisions
                        )}
                      </tbody>
                    </table>

                    <table style={{textAlign:'center'}}>
                                               <thead style={{color:'white'}}> 
                        {renderTableHeaderData("pendingacceptedRoHardCopyRevisions")}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "pendingacceptedRoHardCopyRevisions",
                          pendingacceptedRoHardCopyRevisions
                        )}
                      </tbody>
                    </table>
                    </>}
                    {popupcontent.fromtoType === 'siteLevel' &&
                  <>
                    <table style={{textAlign:'center'}}>
                                               <thead style={{color:'white'}}> 
                        {renderTableHeaderData(
                          "pendingAcceptedRORevisions"
                        )}
                      </thead>
                      <tbody>
                        {renderTableBodyData(
                          "pendingAcceptedRORevisions",
                          pendingAcceptedRORevisions
                        )}
                      </tbody>
                    </table>
                    </>}
                  </>
                )}
              </>
            )}
           
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "20px",
                padding: "5px 55px",
              }}
            >
              <div>
                {popupcontent.selectViewDownload !== "View" && (
                  <p className="downloadBy">
                    Download by {currentUserInfo.firstName}
                    <span>{currentUserInfo.lastName}</span>
                  </p>
                )}
              </div>

              <div>
                {/* <p>
              <div>
                {/* <p>
                    Page {currentPage} of {totalPages}
                  </p> */}
              </div>
              <div className="date-time-container">
                <div className="date-container">
                  <p>{day}</p>
                  <p>{month}</p>
                  <p>{year}</p>
                </div>
                <div>
                <p className="time">{time}</p>
                </div>
              </div>

              <div>
                <p className="footerTitle">RCON SPACE</p>
              </div>
              </div>

             
          </>
            
          </>
     
        </div>
      </DialogContent>
    </Dialog>
  );
};

TablePage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  popupcontent: PropTypes.shape({
    reportType: PropTypes.string,
    consultantId: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }),
};

export default TablePage;
