import React from 'react';
import ValueCard from './ValueCard';
import styles from './ValuesSection.module.css';

function ValuesSection() {
  const values = [
    { id: 1, iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/d18591659a7687a99f10009950c48bcdeed47cf37c048769755c03bd472a1112?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e", description: "Embracing new technologies and creative solutions to develop cutting-edge software that meets the evolving needs of the construction industry.",heading:"Innovation" },
    { id: 2, iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/d18591659a7687a99f10009950c48bcdeed47cf37c048769755c03bd472a1112?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e", description: "Conducting business with honesty and transparency, building trust with clients, partners, and employees through ethical practices.",heading:"Integrity" },
    { id: 3, iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/d18591659a7687a99f10009950c48bcdeed47cf37c048769755c03bd472a1112?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e", description: "Being adaptable and responsive to changes in technology and client needs, allowing for quick iterations and improvements in our software solutions.",heading:"Agility" },
    { id: 4, iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/d18591659a7687a99f10009950c48bcdeed47cf37c048769755c03bd472a1112?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e", description: "Committing to high standards in software development, ensuring that our products are reliable, user-friendly, and meet industry requirements.",heading:"Quality" }
  ];

  return (
    <section className={styles.valuesSection}>
      <h2 className={styles.sectionTitle}>Our Values</h2>
      <div className={styles.valuesGrid}>
        {values.map(value => (
          <>
          <ValueCard key={value.id} iconSrc={value.iconSrc} description={value.description} heading={value.heading}/>
          </>
        ))}
      </div>
    </section>
  );
}

export default ValuesSection;