import { WebApimanager } from "../../WebApiManager";
import { SUCESS_STATUS, FAILED_STATUS } from "../../Utilities/Constants";
// import state from "sweetalert/typings/modules/state";

export const getRegisterData = async (site) => {
    let webApi = new WebApimanager();
    let response = await webApi.get(`api/architectureToRoRegister/getRegisterBySiteId?siteId=${site}`)
    return response
}

export const updateRegister = async (setRegister, site) => {
    const response = await getRegisterData(site)
    if(response.data && response.data.data && response.data.data.registers){
      setRegister(response.data.data.registers)
      return {
        status: SUCESS_STATUS,
        registers: response.data.data.registers
      }
    }else{
      return {
        status: FAILED_STATUS,
        registers: []
      }
    }
}

export const processFile = async (response, action, navigate,revision,data,filter) => {
  console.log(response, action, navigate,data,filter, "processfile parameters");
  
  const contentType = response.headers["content-type"];
  console.log(response.data, "response data");

  // Create a Blob from the response data
  const fileBlob = new Blob([response.data], { type: contentType });
  
  if (contentType.includes("application/json")) {
    // Handle JSON response
    try {
      const text = await fileBlob.text();
      const jsonData = JSON.parse(text);

      // DWG case: Check for DWG data in JSON response
      const urn = jsonData.data?.urn;
      const token = jsonData.data?.token;
      
      if (urn && token && action === "view") {
        navigate(`/dwgviewer?urn=${urn}&token=${token}`,{ state: { data,filter,revision } });
        return; // Exit after navigation for DWG view case
      }

      if (action === "download" && urn && token) {
        // If action is download and content indicates a DWG, proceed with download logic
        downloadFile(fileBlob, "dwg_file.dwg");
        return;
      }
    } catch (error) {
      console.error("Failed to parse JSON from Blob:", error);
    }
  } else if (
    contentType.startsWith("application/octet-stream") ||
    contentType.startsWith("image/") ||
    contentType.startsWith("application/pdf") ||
    contentType.startsWith("application/json; charset=utf-8")
  ) {
    // Non-JSON file handling based on action
    if (action === "view") {
      if (contentType.startsWith("image/")) {
        // Handle image view
        openBlobInNewTab(fileBlob);
      } else if (contentType.startsWith("application/pdf")) {
        // Handle PDF view
        openBlobInNewTab(fileBlob);
      }
    } else if (action === "download") {
      // Handle file download
      initiateFileDownload(response, fileBlob, contentType);
    }
  } else {
    console.error("Unexpected content type:", contentType);
  }
};

// Helper function to download file with specified filename and Blob
const downloadFile = (fileBlob, fileName) => {
  const url = window.URL.createObjectURL(fileBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};

// Helper function to open Blob in new tab (for images, PDFs, etc.)
const openBlobInNewTab = (fileBlob) => {
  const url = window.URL.createObjectURL(fileBlob);
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};

// Helper function to initiate download based on response headers
const initiateFileDownload = (response, fileBlob, contentType) => {
  const contentDisposition = response.headers["content-disposition"];
  let fileName = "downloaded_file";
  const mimeTypeToExtension = {
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/gif": "gif",
    "image/vnd.dwg": "dwg",
    "application/vnd.dwg": "dwg",
    "application/x-autocad": "dwg",
    "application/pdf": "pdf",
    "text/plain": "txt",
    "application/zip": "zip",
  };

  const fileExtension = mimeTypeToExtension[contentType] || "bin";
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (fileNameMatch) {
      fileName = fileNameMatch[1];
    }
  }

  if (!fileName.toLowerCase().endsWith(`.${fileExtension}`)) {
    fileName = `${fileName}.${fileExtension}`;
  }

  downloadFile(fileBlob, fileName);
};
