import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import AddDrawingDialog from "./Components/AddDrawingDialog";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId } from "../../../atoms";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { RxCross1 } from "react-icons/rx";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableWrapper from "../../../Utilities/TableWrapper";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import ReceivedTable from "./ReceivedTable";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import { processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import { Tab1, Tabs1 } from "../../Tabs/Tabv2";
import { HiPlusCircle } from "react-icons/hi";
import { FaCircleMinus } from "react-icons/fa6";
import { Dialog, DialogContent } from "../../Dialog/Dialog";

const RFIEditReopenDialog = ({showEditPopup, setShowEditPopup, tableRowData={},currentView}) => {
  console.log(tableRowData,"all data")

    const formatDate = (dateString) => {
        if (!dateString) return "-";
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        });
      };
    
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowDateString = tomorrow.toISOString().split("T")[0];
    console.log(tableRowData,"tableRowData in RFI Edit Reopen")
    let webApi = new WebApimanager();
    const [editDialogOpen, setEditDialogOpen] = useState(showEditPopup);
    const [natureOfRequestedInformationReasons, setNatureOfRequestedInformationReasons] = useState([
      { natureOfRequest: '', reason: '' } // Initialize with an object containing a reason array
    ]);
    const [editData, setEditData] = useState({
        drawingId: "",
        drawingNumber: "",
        expectedDate: "",
        requestedDate: "",
        drawingFileName: null,
        revision: "",
        natureofrequestedInfo: "",
      });
    // const [requestReason,setRequestReason] = useState([{NOR:"",reason:""}])
    const [requestReason, setRequestReason] = useState([
      { natureOfRequest: '', reason: [''] } // Initialize with an object containing a reason array
    ]);


    
    const [error, setError] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [apiProcessing, setApiProcessing] = useState({
        loader: false,
        message: "Loading...",
        });
    const [site, setSite] = useRecoilState(siteId);
    const [selectedRevision, setSelectedRevision] = useState("");

    const handleRevisionSelection = (event) => {
        setSelectedRevision(event.target.value);
      };
    
      // const handleAddReason =()=>{
      //   setRequestReason([...requestReason, { natureOfRequest: '', reason: [''] }]);
      // }
      const handleAddReason = () => {
        setNatureOfRequestedInformationReasons((prev) => [...prev, { natureOfRequest: '', reason: '' }]);
      };
      
      const handleRemoveReason = (index) => {
        setNatureOfRequestedInformationReasons((prev) =>
          prev.filter((_, i) => i !== index)
        );
      };

      const handleReasonChange = (e, index) => {
        const { name, value } = e.target;
      
        setNatureOfRequestedInformationReasons((prev) => {
          const updatedReasons = [...prev];
      
          if (!updatedReasons[index]) {
            console.warn(`No entry found for index: ${index}`);
            return prev;
          }
      
          // Update either 'natureOfRequest' or 'reason' based on the input name
          updatedReasons[index][name] = value;
      
          return updatedReasons;
        });
      };
      

      
      
    
      const handleEditClose = () => {
        setEditDialogOpen(false);
        setEditData({
          drawingId: "",
          drawingNumber: "",
          expectedDate: "",
          remarks: "",
          drawingFileName: null,
          revision: "",
          natureofrequestedInfo: [],
        });
        setShowEditPopup(false)
      };

      const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === "file") {
          setEditData({
            ...editData,
            [name]: files[0],
          });
        } else {
          setEditData({
            ...editData,
            [name]: value,
          });
        }
      };
      const handleEditSubmit = async (e) => {
        console.log("on inside")
        e.preventDefault();
    
        let formErrors = {};
        if (!editData.drawingNumber)
          formErrors.drawingNumber = "Drawing Number is required";
        if (!editData.expectedDate)
          formErrors.expectedDate = "Expected Date is required";
        if (!editData.natureofrequestedInfo)
          formErrors.natureofrequestedInfo = "this field is requires";
        if (!editData.drawingFileName)
          formErrors.drawingFileName = "this field is requires";
        if (!editData.revision) formErrors.revision = 'Please select a revision.';
        
        
          natureOfRequestedInformationReasons.forEach((info, index) => {
            if (!info.natureOfRequest) {
              formErrors[`natureOfRequest_${index}`] = "Nature of request is required.";
            }
            if (!info.reason) {
              formErrors[`reason_${index}`] = "Please provide a reason.";
            }
          });
        
          

        console.log(formErrors,"formErrors")
    
        setError(formErrors);
    
        if (Object.keys(formErrors).length > 0) {
          return;
        }
    
        setApiProcessing({
          loader: true,
          message: "Requesting...",
        });
        try {
          const editFormData = {
            drawingId: editData.drawingId,
        drawingNo: editData.drawingNumber,
        siteId: site,
        requestedDate: todayDate(),
        expectedDate: editData.expectedDate,
        revision: editData.revision,
        // drawingFileName:editData.drawingFileName,
        natureOfRequestedInformation: editData.natureofrequestedInfo,
        natureOfRequestedInformationReasons: editData.natureofrequestedInfo.map((info) => {
          const filteredReasons = natureOfRequestedInformationReasons.filter((req) => req.natureOfRequest === info);
          return filteredReasons.map((req) => ({
            natureOfRequest: info,
            reason: req.reason,
          }));
        }).flat(),
          };
          

    
          console.log("Submitting edit data:", editFormData);
    if(tableRowData.status === "Requested"){
          const response = await webApi.put(
            `api/architectureToRoRequested/${tableRowData._id}`,
            editFormData
          );
        
          if (response.status === 200) {
            const responseData = response.data;
            if (responseData.status === "error") {

              setErrorMessage(responseData.message);
              console.log("Response data:", responseData.message);
              setApiProcessing({ loader: false, message: "" });
              return; 
            }
    
            const id = response.data.data._id;
    
            if (editData.drawingFileName) {
              const drawingFileName = new FormData();
              drawingFileName.append("drawingFileName", editData.drawingFileName);
              const uploadResponse = await webApi.imagePut(
                `api/architectureToRoRequested/${id}`,
                drawingFileName
              );
              console.log("Upload response:", uploadResponse);
              if(uploadResponse.status === 200) {
                setApiProcessing({ loader: false, message: "" });
                handleEditClose();
                window.location.reload();
              }

            }
           
            // fetchData();
          } else if (response.status === 400) {
            if (response.data && response.data.message) {
              setErrorMessage(response.data.message);
              console.log("Response data:", response.data.message);
            }
            setApiProcessing({ loader: false, message: "" });
          } else {
            setErrorMessage("An unexpected error occurred.");
            setApiProcessing({ loader: false, message: "" });
          }


        }else{
          const response = await webApi.put(
            `api/architectureToRoRequested/reOpen/${tableRowData._id}`,
            editFormData
          );
          if (response.status === 200) {
            const id = response.data.data._id;
    
            if (editData.drawingFileName) {
              console.log(editData.drawingFileName,"editData")
              const drawingFileName = new FormData();
              drawingFileName.append("drawingFileName", editData.drawingFileName);
              const uploadResponse = await webApi.imagePut(
                `api/architectureToRoRequested/${id}`,
                drawingFileName
              );
              console.log("Upload response:", uploadResponse);
              if(uploadResponse.status === 200) {
                window.location.reload();
              }

            }
            setApiProcessing({ loader: false, message: "" });
            const responseData = response.data;
            if (responseData.status === "error") {

              setErrorMessage(responseData.message);
              console.log("Response data:", responseData.message);
              setApiProcessing({ loader: false, message: "" });
              return; 
            }
          } else if (response.status === 400) {
            if (response.data && response.data.message) {
              setErrorMessage(response.data.message);
              console.log("Response data:", response.data.message);
            }
            setApiProcessing({ loader: false, message: "" });
          } else {
            setErrorMessage("An unexpected error occurred.");
            setApiProcessing({ loader: false, message: "" });
          }
        }
        } catch (error) {
          setApiProcessing({ loader: false, message: "" });
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage("Failed to submit data.");
          }
        }
      };

      const listItems = [
        { id: "A - Missing Information", name: "A - Missing Information" },
        { id: "B - Clarification", name: "B - Clarification" },
        { id: "C - Additional Information", name: "C - Additional Information" },
        { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
      ];
    
      // Function to get the name from id
      const getNameFromId = (id) => {
        const item = listItems.find((item) => item.id === id);
        return item ? item.name : "";
      };
      
      const navigate = useNavigate();
      const handleViewFile = async (id) => {
        setApiProcessing({
          loader: true,
          message: "Viewing...",
        });
        try {
          // Request the file with binary response handling
          const response = await webApi.getImage(
            tableRowData.status === "Rejected"
              ? `api/architectureToRoRequested/rejectFile/${id}`
              : `api/architectureToRoRequested/request/${id}`,
            {
              responseType: "arraybuffer", // Expect binary data
            }
          );
    
          processFile(response, "view", navigate); // Process file for downloading
        } catch (error) {
          console.error("Error viewing file:", error);
        } finally {
          setApiProcessing({ loader: false, message: "" });
        }
      };
      useEffect(() => {
        // Load or set the initial state as needed
        if (tableRowData?.natureOfRequestedInformationReasons) {
          setNatureOfRequestedInformationReasons(tableRowData.natureOfRequestedInformationReasons);
        }
      }, []);
      
      useEffect(()=>{
        if(Object.keys(tableRowData).length){
            
            setEditData({
                drawingId: tableRowData.drawingId?._id,
                drawingNumber: tableRowData.drawingNo,
                expectedDate: tableRowData.expectedDate?.slice(0,10),
                requestedDate: tableRowData.requestedDate?.slice(0,10),
                drawingFileName: tableRowData.drawingFileName,
                revision: tableRowData?.revision,
                natureofrequestedInfo: tableRowData?.natureOfRequestedInformation,
            })
            
            
        }
      },[])
      useEffect(()=>{console.log(editData,"editData")},[editData])
    
  return (
    <Dialog
    open={editDialogOpen}
    onClose={handleEditClose}
    maxWidth="md"
    fullWidth
    PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: " #4D4D4D",
        margin: "0px",
        height: "50px",
      }}
    >
      <p
        style={{ width:"100%", textAlign: "center", color: "white",marginTop:"15px" }}
      >
       {tableRowData.status === "Requested" ?  "EDIT RFI" : "ReOpen RFI"}

      </p>
      <RxCross1
        size={20}
        style={{
          marginLeft: "auto",
          cursor: "pointer",
          marginRight: "20px",
          color: "white",
        }}
        onClick={handleEditClose}
      />
    </div>
    <DialogContent
      style={{ padding: "25px", minWidth: "300px", minHeight: "300px" ,maxHeight: "80vh", overflowY: "auto",}}
    >
      <form onSubmit={handleEditSubmit}>
        <Loader
          open={apiProcessing.loader}
          message={apiProcessing.message}
        />
        <div
          style={{ display: "flex", flexDirection: "column", gap: "15px" }}
        >
          <div style={{ display: "flex", gap: "15px" }}>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="drawingNumber"
                className="fs-10  text-start py-2"
              >
                Drawing Number <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="drawingNumber"
                placeholder="Enter your Drawing Number"
                className="form-control fs-12"
                style={{ width: "100%" }}
                value={editData.drawingNumber}
                onChange={handleInputChange}
                readOnly
              />
              {error.drawingNumber && (
                <div className="text-danger">{error.drawingNumber}</div>
              )}
            </div>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="requestedDate"
                className="fs-10  text-start py-2"
              >
                Requested Date <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="expectedDate"
                className="form-control fs-12"
                style={{ width: "100%" }}
                value={editData.requestedDate}
                onChange={handleInputChange}
                readOnly
              />
              {error.requestedDate && (
                <div className="text-danger">{error.requestedDate}</div>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px" }}>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="expectedDate"
                className="fs-10  text-start py-2"
              >
                Expected Date <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="date"
                name="expectedDate"
                className="form-control fs-12"
                style={{ width: "100%", height: "45px" }}
                value={editData.expectedDate}
                onChange={handleInputChange}
                min={tomorrowDateString}
              />
              {error.expectedDate && (
                <div className="text-danger">{error.expectedDate}</div>
              )}
            </div>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="revision"
                className="fs-10  text-start py-2"
              >
                Revisions
                <span style={{ color: 'red' }}>*</span>
              </label>
              <select
                className="form-control fs-12"
                style={{ width: "100%", backgroundColor: "var(--table-evenrow-color)" ,height:"45px"}}
                value={editData?.revision || ""}
                onChange={(e) =>
                  handleRevisionSelection({
                    target: {
                      name: "selectedRevision",
                      value: e.target.value,
                    },
                  })
                }
                disabled={!editData.revision} 
              >
                <option value="" disabled>
                  Select Revision
                </option>
                {editData.revision && (
                  <option value={editData.revision}>{editData.revision}</option>
                )}
              </select>
              {/* <SearchDropdownIdVal
  placeholder={"Select Revision"}
  backgroundColor={"var(--table-evenrow-color)"}
  options={
    editData.revision ? [{ name: editData.revision, value: editData.revision }] : []
  }
  value={{ name: editData.revision, value: editData.revision }} 
  onOptionSelect={() => {
    handleRevisionSelection({
      target: {
        name: "selectedRevision",
        value: editData.revision,
      },
    })
  }} 
  className="form-control fs-12"
  style={{ width: "100%" }}
  readOnly 
  disabled 
/> */}

              {error.revision && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {error.revision}
                </p>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px" }}>
            <div style={{ flex: 1 }}>
              
              <MultiSelectSearch
                mandatory={true}
                heading={"Nature of Requested Information"}
                listItems={listItems}
                selectedIds={editData.natureofrequestedInfo || []} // Ensure this is an array
                setSelectedIds={(ids) =>
                  setEditData({ ...editData, natureofrequestedInfo: ids })
                }
                handleSelectedPropertyOption={(data) => {
                  if (editData.natureofrequestedInfo.includes(data.id)) {
                    setEditData({
                      ...editData,
                      natureofrequestedInfo:
                        editData.natureofrequestedInfo.filter(
                          (id) => id !== data.id
                        ),
                    });
                  } else {
                    setEditData({
                      ...editData,
                      natureofrequestedInfo: [
                        ...editData.natureofrequestedInfo,
                        data.id,
                      ],
                    });
                  }
                }}
                clearAll={() =>
                  setEditData({ ...editData, natureofrequestedInfo: [] })
                }
                addAll={() =>
                  setEditData({
                    ...editData,
                    natureofrequestedInfo: listItems.map((item) => item.id),
                  })
                }
              />

              {/* Display selected fields */}
              <div style={{ marginTop: "20px" }}>
                <p className="fs-10  text-start py-2">
                  Selected Fields:
                </p>
                <ul>
                  {editData.natureofrequestedInfo.length > 0 ? (
                    editData.natureofrequestedInfo.map((id) => (
                      <li key={id}>{getNameFromId(id)}</li>
                    ))
                  ) : (
                    <li>No fields selected</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
    <div style={{ display: "flex", gap: "15px" }}>
  <div style={{ flex: 1 }}>
    <label
      htmlFor="remarks"
      className="fs-10 text-start py-2"
      style={{ display: "flex", alignItems: "center" }}
    >
      Request Reason *
      <span style={{ display: "flex", alignItems: "center" }}>
       
        <HiPlusCircle
          style={{ marginLeft: "20px", color: "#e47331", fontSize: "24px" }}
          onClick={handleAddReason}
        />
      </span>
    </label>

    {natureOfRequestedInformationReasons?.map((info, index) => (
      <div key={info?._id} style={{ marginTop: "30px" }}>
          <div
            key={`${info._id}_${index}`}
            style={{ width: "100%", display: "flex", alignItems: "center", position: "relative", marginBottom: "10px" }}
          >
            <select
              style={{ width: "15%", marginRight: "20px" }}
              name="natureOfRequest"
              value={info.natureOfRequest}
              onChange={(e) => handleReasonChange(e, index)}
            
            >
              <option value="">Select</option>
              {editData.natureofrequestedInfo.length > 0 &&
                editData.natureofrequestedInfo.map((requestInfo, reqIndex) => (
                  <option key={reqIndex} value={requestInfo}>
                    {requestInfo.slice(0,2)}
                  </option>
                ))}
            </select>

            <input
              type="text"
              style={{ width: "82%" }}
              name="reason"
              value={info.reason}
              onChange={(e) => handleReasonChange(e, index)} 
            />
           
              <FaCircleMinus
                style={{ fontSize: "24px", position: "absolute", right: "-21px" }}
                onClick={() => handleRemoveReason(index)}
              />
            {error[`natureOfRequest_${index}`] ? (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            position: "absolute",
            top: "50px",
          }}
        >
          {error[`natureOfRequest_${index}`]}
        </p>
      ) : error[`reason_${index}`] ? (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            position: "absolute",
            top: "50px",
            left: "18%",
          }}
        >
          {error[`reason_${index}`]}
        </p>
      ) : null}
          </div>
        
      </div>
    ))}
  </div>
</div>

          {/* <div>
            <label
              htmlFor="drawingFileName"
              className="fs-10  text-start py-2"
            >
              Upload Your File <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="file"
              accept=".pdf, .jpg, .jpeg, .png, .dwg"
              id="drawingFileName"
              name="drawingFileName"
              className="form-control fs-12"
              style={{ width: "100%", height: "43px" }}
              
              onChange={(e) => {
                const file = e.target.files[0];
                if (file && file.name.endsWith(".dwg")) {
                  handleInputChange(e);
                } else {
                  alert("Please select a .dwg file.");
                  e.target.value = null; 
                }
              }}
            />

            {error.drawingFileName && (
              <div className="text-danger">{error.drawingFileName}</div>
            )}
          </div> */}
          <div>
  <label
    htmlFor="drawingFileName"
    className="fs-10 text-start py-2"
  >
    Upload Your File <span style={{ color: "red" }}>*</span>
  </label>
  
  {/* Display existing file name and button to open it */}
  {editData?.drawingFileName ? (
    <div style={{ display: "flex", justifyContent:'space-between', marginBottom: "10px" }}>
      <span className="fs-12">
      {editData.drawingFileName && typeof editData.drawingFileName === 'object' 
    ? editData.drawingFileName.name 
    : editData.drawingFileName}
      </span>
      <button
        type="button"
        style={{
          marginLeft: "10px",
          backgroundColor: "#e47331",
          color: "#fff",
          border: "none",
          padding: "5px 10px",
          cursor: "pointer",
          borderRadius: "4px",
        }}
        onClick={() => handleViewFile(tableRowData?._id, "")}
      >
        View File
      </button>
    </div>
  ) : null}
  <input
    type="file"
    id="drawingFileName"
    name="drawingFileName"
    className="form-control fs-12"
    style={{ width: "100%", height: "43px" }}
    onChange={(e) => handleInputChange(e)}
  />

  {/* Error message */}
  {error.drawingFileName && (
    <div className="text-danger">{error.drawingFileName}</div>
  )}
</div>

          {errorMessage && (
            <div style={{ color: "red", fontSize: "15px" }}>
              {errorMessage}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              type="submit"
              style={{
                background: "blue",
                color: "white",
                width: "100px",
                height: "35px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </DialogContent>
  </Dialog>
  )
}

export default RFIEditReopenDialog