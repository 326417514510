import React, { useState } from "react";
import { IoMdArrowDropright, IoMdArrowDropleft } from "react-icons/io";

const ConsultantFilter = ({ uniqueConsultant, setConsultantValue }) => {
  const [activeButton, setActiveButton] = useState(0); 
  const [visibleStartIndex, setVisibleStartIndex] = useState(0); 
  const visibleCount = 4; 

  const handleButtonClick = (consultant, index) => {
    setActiveButton(index); 
    setConsultantValue(consultant === "ALL" ? "" : consultant); 
  };

  const handleNext = () => {
    if (visibleStartIndex + visibleCount < uniqueConsultant.length) {
      setVisibleStartIndex(visibleStartIndex + 1); 
    }
  };

  const handlePrev = () => {
    if (visibleStartIndex > 0) {
      setVisibleStartIndex(visibleStartIndex - 1); 
    }
  };

  const visibleConsultants = uniqueConsultant.slice(
    visibleStartIndex,
    visibleStartIndex + visibleCount
  );

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        bottom: "2px",
        left: "15px",
        backgroundColor: "#e0e0e0",
        width: "98%",
        alignItems: "center",
      }}
    >
      <IoMdArrowDropleft
        style={{
          cursor: visibleStartIndex > 0 ? "pointer" : "not-allowed",
          fontSize: "24px",
          color: visibleStartIndex > 0 ? "#000" : "#b4b4b4",
        }}
        onClick={handlePrev}
      />

      {/* Consultant Buttons */}
      {visibleConsultants.length > 0 &&
        visibleConsultants.map((consultant, index) => {
          const isActive = activeButton === index + visibleStartIndex; 
          return (
            <button
              key={index + visibleStartIndex}
              style={{
                padding: "6px 10px",
                borderBottom: isActive ? "2px solid white" : "none", 
                cursor: "pointer",
                fontWeight: isActive ? "600" : "400", 
                color: isActive ? "#FFFFFF" : "#4D4D4D", 
                backgroundColor: isActive ? "#e47331" : "#F3F3F3", 
                height: "100%",
                position: "relative",
                transition: "all 0.3s ease", 
              }}
              onClick={() => handleButtonClick(consultant, index + visibleStartIndex)}
            >
              {consultant}
              {isActive && (
                <span
                  style={{
                    position: "absolute",
                    bottom: "4px",
                    left: "0px",
                    right: "0px",
                    width: "88%",
                    margin: "0 auto",
                    height: "2px",
                    backgroundColor: "white", 
                  }}
                ></span>
              )}
            </button>
          );
        })}

      
      <IoMdArrowDropright
        style={{
          cursor:
            visibleStartIndex + visibleCount < uniqueConsultant.length
              ? "pointer"
              : "not-allowed",
          fontSize: "24px",
          color:
            visibleStartIndex + visibleCount < uniqueConsultant.length
              ? "#000"
              : "#b4b4b4",
        }}
        onClick={handleNext}
      />
    </div>
  );
};

export default ConsultantFilter;
