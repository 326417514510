/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useMemo } from "react";
import * as Icon from "react-bootstrap-icons";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import filter from "../../../../Images/taskFilter.svg";
import { useNavigate } from "react-router-dom";
import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import pen from "../IconsSiteHead/penIcon.svg";
import { WebApimanager } from "../../../../WebApiManager";
import { siteId, userInfo } from "../../../../atoms";
import { useRecoilState } from "recoil";
import RfiViweModal from "./RfiViewModal";
import { calculateDueDays } from "../../../../Utilities/DateUtils";
import { processFile } from "../../BackendUtils";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent } from "../../../Dialog/Dialog";
import { FaFilter } from "react-icons/fa";
import { canShowFolder } from "../../ShowFolder";
import ConsultantFilter from "../../ConsultantFilter";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", // Optional
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", // Removes the row borders
          },
        },
      },
    },
  },
});

const SiteHeadRFI = (rfiRaisedAccess) => {
  const webApi = new WebApimanager();
  console.log(rfiRaisedAccess, "rfi access for the sitehead");
  const navigate = useNavigate();
  const [rfiData, setRfiData] = useState([]);
  const [site] = useRecoilState(siteId);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const dropdownRef = useRef(null);
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [currentView, setCurrentView] = useState("raised");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [consultantValue, setConsultantValue] = useState('')
  const [currentUserInfo] = useRecoilState(userInfo);
  const [selectedDrawingId, setSelectedDrawingId] = useState(null);
  useEffect(() => {
    const fetchRfiData = async () => {
      try {
        const response = await webApi.get(
          `api/roToSiteLevelRequested/site?siteId=${site}`
        );
        console.log("Rfi data response for siteHead", response.data);
        setRfiData(response.data.data);

        const statuses = response.data.data.map((item) => item.status);
        const unique = [...new Set(statuses)];
        setUniqueStatuses(["All", ...unique]);
      } catch (error) {
        console.log("Server error", error);
      }
    };

    fetchRfiData();
  }, [site]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };
  const handleDownload = async (id, status) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/roToSiteLevelRequested/rejectFile/${id}`
          : `api/roToSiteLevelRequested/request/${id}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const handleConfirmDialogOpen = (drawingItem) => {
    setSelectedItem(drawingItem);
    setSelectedDrawingId(drawingItem._id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  const handleConfirmSubmit = () => {
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    if (selectedDrawingId) {
      console.log("Selected Drawing ID:", selectedDrawingId);
      webApi
        .put(`api/roToSiteLevelRequested/closed/${selectedDrawingId}`)
        .then((response) => {
          const data = response.data;
          console.log("Server Response:", response);
          console.log("Data:", data);
          setApiProcessing({ loader: false, message: "" });
          setConfirmDialogOpen(false);
        })
        .catch((error) => {
          setApiProcessing({ loader: false, message: "" });
          console.error("There was an error submitting the form!", error);
        });
    }
  };
  const DueDateDisplay = ({ item }) => {
    const today = new Date();
    const daysRemaining = calculateDueDays(today, item.expectedDate);
    return (
      <span
        style={{
          color: new Date(item.expectedDate) >= today ? "green" : "red",
        }}
      >
        {daysRemaining}
      </span>
    );
  };

  const handleDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setShowDropdown(false);
  };

  const filteredRfiData = rfiData?.filter((item) => {
    const isStatusMatch =
      selectedStatus === "All" || item.status === selectedStatus;
    const isSearchMatch =
      item.drawingNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.drawingId?.drawingTitle
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.designDrawingConsultant?.role
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.category?.category.toLowerCase().includes(searchTerm.toLowerCase());

    return isStatusMatch && isSearchMatch;
  });
  const uniqueConsultant = useMemo(() => {
    const consultant = rfiData.map((item) =>item?.drawingId?.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [rfiData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleViewOpen = (drawing) => {
    setSelectedDrawing(drawing);
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setViewDialogOpen(false);
    setSelectedDrawing(null);
  };
  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const updatedRows = rfiData
  ?.filter((item) =>
    item?.drawingId?.designDrawingConsultant?.role.includes(consultantValue)
  )
  .map((row, index) => {
    // Calculate Due/Overdue
    const requestedDate = row.requestedDate ? new Date(row.requestedDate) : null;
    const expectedDate = row.expectedDate ? new Date(row.expectedDate) : null;
    const currentDate = new Date();

    let dueOverdueText = "N/A";
    let color = "black";

    if (requestedDate && expectedDate) {
      const differenceInDays =
        Math.ceil((expectedDate - currentDate) / (1000 * 60 * 60 * 24));
      if (differenceInDays >= 0) {
        dueOverdueText = `${differenceInDays} days due`;
        color = "green";
      } else {
        dueOverdueText = `${Math.abs(differenceInDays)} days overdue`;
        color = "red";
      }
    }

    return {
      ...row,
      serialNo: index + 1,
      drawingNo: row.drawingNo || "-",
      designConsultant: row?.drawingId?.designDrawingConsultant?.role || "N/A",
      drawingCategory: row?.drawingId?.category?.category || "NA",
      drawingTitle: row?.drawingId?.drawingTitle || "-",
      requestedDate: row.requestedDate
        ? new Date(row.requestedDate).toLocaleDateString()
        : "N/A",
      expectedDate: row.expectedDate
        ? new Date(row.expectedDate).toLocaleDateString()
        : "N/A",
      revision: row.revision || "N/A",
      status: row.status || "N/A",
      dueOverdue: { text: dueOverdueText, color },
    };
  });

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.drawingId?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.drawingId?.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingId?.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = useMemo(() => [
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 0.5,
      filterable: false,
      sortable: false,
    },
    {
      field: "drawingNo",
      headerName: "Drawing No",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "designConsultant",
      headerName: "Design Consultant",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "drawingCategory",
      headerName: "Drawing Category",
      flex: 1.5,
      filterable: true,
      sortable: true,
    },
    {
      field: "drawingTitle",
      headerName: "Drawing Title",
      flex: 2,
      filterable: true,
      sortable: true,
      
      
    },
    {
      field: "requestedDate",
      headerName: "Requested Date",
      flex: 1,
      filterable: true,
      sortable: true,
      
    },
    {
      field: "expectedDate",
      headerName: "Expected Date",
      flex: 1,
      filterable: true,
      sortable: true,
      
    },
    {
      field: "dueOverdue",
      headerName: "Due/Overdue",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        const { text, color } = params.row.dueOverdue || { text: "N/A", color: "black" };
        return <span style={{ color }}>{text}</span>;
      },
    },
    {
      field: "revision",
      headerName: "Revision",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      filterable: false,
      renderCell: (params) => (
        <div style={{ display: "flex", gap: "10px", alignItems: "center",marginTop:"24px" }}>
          <img
            src={Eye}
            alt="view"
            onClick={() => handleViewOpen(params.row)}
            style={{ cursor: "pointer" }}
          />
          {params.row.status === "Accepted" && (
            <>
              <img
                src={pen}
                alt="edit"
                onClick={() => handleConfirmDialogOpen(params.row)}
                style={{ cursor: "pointer" }}
              />
              <img
                src={Download}
                alt="download"
                onClick={() => handleDownload(params.row.drawingId)}
                style={{ cursor: "pointer" }}
              />
            </>
          )}
        </div>
      ),
    },
  ], [ rfiData]);
  

  return (
    <div style={{ margin: "5px", backgroundColor: "white", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <p
            style={{
              color: "#303030",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Raised
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            placeholder="Search Drawing Lists"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              paddingLeft: "20px",
              border: "1px solid var(--primary-color)",
              width: "250px",
              height: "40px",
              marginRight: "15px",
            }}
          />
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon.Search
              style={{
                position: "absolute",
                right: "25px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#ACACAC",
              }}
            />
          </div>

          {/* <div style={{ position: "relative" }}>
            <FaFilter
              size={39}
              style={{
                border: "1px solid var(--primary-color)",
                padding: "5px",
              }}
              onClick={handleDropdownToggle}
            />
            {showDropdown && (
              <div ref={dropdownRef} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "45px",
                    right: "0px",
                    borderRadius: "6px",
                    padding: "10px",
                    zIndex: 1000,
                    width: "150px",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {uniqueStatuses.map((status) => (
                    <button
                      key={status}
                      onClick={() => handleStatusChange(status)}
                      style={{
                        display: "block",
                        width: "100%",
                        border: "none",
                        marginBottom: "5px",
                        background: "white",
                        textAlign: "left",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color:
                          selectedStatus === status
                            ? "var(--primary-color)"
                            : "#303030",
                      }}
                    >
                      {status}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
      <Box
          className="dataGridContainer"
        >
          <ThemeProvider theme={theme}>
          <DataGrid
            columns={columns}
            rows={filteredRows}
            getRowId={(row) => row._id}
            rowHeight={60}
            disableColumnMenu={false} 
            pagination={false}
            hideFooter={true}
            getRowSpacing={params=>({
              top:params.isFirstVisible ? 0 : 5,
              bottom:params.isLastVisible ? 0 : 5
            })}
            
            getRowClassName={(params) =>
              "customHeader" 
            }
            
          />
          </ThemeProvider>
        </Box>
      </div>
      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogContent style={{ minHeight: "100px" }}>
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <p style={{ textAlign: "center" }}> Are you sure want to close ?</p>
          </>
        </DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <button
            style={{
              background: "var(--primary-color)",
              color: "white",
              width: "100px",
              height: "35px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleConfirmDialogClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "var(--primary-color)",
              color: "white",
              width: "100px",
              height: "35px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleConfirmSubmit}
          >
            Submit
          </button>
        </div>
      </Dialog>
      <RfiViweModal
        open={viewDialogOpen}
        onClose={handleViewClose}
        drawingData={selectedDrawing}
        rfiRaisedAccess={rfiRaisedAccess}
      />
      <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue}/>
    </div>
  );
};

export default SiteHeadRFI;
