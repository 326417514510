import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { allUsersInformations, drawingoptionsTab, siteId, userId, userInfo } from "../../../atoms";
import { WebApimanager } from "../../../WebApiManager";
import SelectSite from "../../../Utilities/SelectSite";
import SiteHeadDrawing from "./Drawing/SiteHeadDrawing";
import SiteHeadRFI from "./RFI/SiteHeadRFI";
import UploadtoSitelevel from "./Pending/UploadtoSitelevel";
import Analysis from "../Components/Analysis";
import RegisterPage from "../RegisterPage";
import RegisterTable from "./RegisterTable";

const Sitehead = () => {
  const webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(drawingoptionsTab);
  const [site] = useRecoilState(siteId);
  const [currentUserInfo, setCurrentUserInfo] = useState({});
  const [currentUserId] = useRecoilState(userId)
  const [forwardAccess, setForwardAccess] = useState(false);
  const [rfiRaisedAccess, setRfiRaisedAccess] = useState(false);
  const [allUsersInfo, setAllUserInfo] = useRecoilState(allUsersInformations)
  const [tabEnableModules, setTabEnableModules] = useState({
    drawing: false,
    rfi: false,
    pending: false,
    register: false,
    analysis: false,
  });
  
  const getUserById = async(id) =>{
    try {
      const response = await webApi.get(`/api/users/getUser/${id}`)
      if(response.status === 200){
        console.log(response?.data?.data?.user,"activeUserDetails")
        setCurrentUserInfo(response?.data?.data?.user)
      }
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    if(currentUserId){
      getUserById(currentUserId)
    }
  },[currentUserId,site])


  useEffect(() => {
    console.log("SiteHead site Enable Modules", currentUserInfo);

    if (currentUserInfo?.permittedSites) {
      let enabledTabs = {
        drawing: false,
        rfi: false,
        pending: false,
        register: false,
        analysis: false,
      };

      currentUserInfo.permittedSites.forEach((site) => {
        const SiteHeadDetails = site.enableModules?.drawingDetails?.siteHeadDetails;

        if (SiteHeadDetails) {
          enabledTabs = {
            drawing: enabledTabs.drawing || SiteHeadDetails.drawing,
            rfi: enabledTabs.rfi || SiteHeadDetails.rfi,
            pending: enabledTabs.pending || SiteHeadDetails.pending,
            register: enabledTabs.register || SiteHeadDetails.register,
            analysis: enabledTabs.analysis || SiteHeadDetails.analysis,
          };
        }
      });

      setTabEnableModules(enabledTabs);
    }
  }, [currentUserInfo,site]);

  // useEffect(()=>{
  //   console.log(allUsersInfo,"allUserInfoINROToSite")
  //   const filteredUser = allUsersInfo.find((userInfo)=>userInfo._id === currentUserInfo._id)
  //       console.log(filteredUser,"filteredUser")
  //       const permittedSite = filteredUser?.permittedSites?.find((item) => item?.siteId?._id === site);
  //       console.log(permittedSite,"checkingPermSiteHead")
  //       if(permittedSite){
  //         console.log(permittedSite?.enableModules?.drawingDetails?.siteHeadDetails?.forwardAccess,"checkingInside1SiteHead")
  //         console.log(permittedSite?.enableModules?.drawingDetails?.siteHeadDetails?.rfiRaisedAccess,"checkingInside2SiteHead")
  //         setForwardAccess(permittedSite?.enableModules?.drawingDetails?.siteHeadDetails?.forwardAccess)
  //         setRfiRaisedAccess(permittedSite?.enableModules?.drawingDetails?.siteHeadDetails?.rfiRaisedAccess)
  //       }
  // },[allUsersInfo,site,currentUserInfo])

  


  useEffect(()=>{
    if(Object.keys(currentUserInfo).length){
      const permittedSite = currentUserInfo?.permittedSites?.find((item) => item?.siteId === site);
      if(permittedSite){
      setForwardAccess(permittedSite?.enableModules.drawingDetails.siteHeadDetails?.forwardAccess)
      setRfiRaisedAccess(permittedSite?.enableModules?.drawingDetails?.siteHeadDetails?.rfiRaisedAccess)
    }}
  },[currentUserInfo,site])

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  useEffect(()=>{
    console.log(forwardAccess,"=>",rfiRaisedAccess,"checkingAccess")
  },[forwardAccess,rfiRaisedAccess])

  if (site === "") {
    return <SelectSite />;
  }

  const anyTabEnabled = Object.values(tabEnableModules).some((val) => val);

  const allTabs = [
    { label: "Drawing", index: 0, component: <SiteHeadDrawing 
      forwardAccess={forwardAccess}
      rfiRaisedAccess={rfiRaisedAccess}/> },
    { label: "RFI", index: 1, component: <SiteHeadRFI rfiRaisedAccess={rfiRaisedAccess}/> },
    { label: "Pending", index: 2, component: <UploadtoSitelevel forwardAccess={forwardAccess}/> },
    { label: "Register", index: 3, component: <RegisterTable/> },
    { label: "Analysis", index: 4, component: <Analysis type="SiteHead" /> },
  ];
  return (
    <>
      <>
        <div className="containerDrawingPagesArchitecture">
          <ul className="tabsArchitectureTabs" role="tablist">
            {allTabs.map((tab) => (
              <li
                key={tab.index}
                className={`tabArchitectureTab ${
                  value === tab.index ? "active" : ""
                }`}
                role="tab"
                aria-selected={value === tab.index}
                aria-controls={`tabpanel-${tab.index}`}
                id={`tab-${tab.index}`}
                onClick={() => handleChange(tab.index)}
              >
                {tab.label}
              </li>
            ))}
          </ul>
        </div>

        {allTabs.map((tab) => (
          <TabPanel key={tab.index} value={value} index={tab.index}>
            {tab.component}
          </TabPanel>
        ))}
      </>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className="tabpanelArchitecture"
    >
      {value === index && <div className="tabpanel-content">{children}</div>}
    </div>
  );
}

export default Sitehead;
