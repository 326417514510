import React from 'react';
import styles from './AboutUs.module.css';
import Header from '../Components/Navbar/Header';
import ValuesSection from './ValuesSection/ValuesSection';
import VisionSection from './VisionSection/VisionSection';
import FloatingImageContainer from '../Widgets/FloatingImageContainer';
import PagesHeroSection from '../Components/constants-comp/pagesHerosection';

function AboutUs() {
  return (
    <div className={styles.aboutUsContainer}>
      <Header/>
      <main>
      <PagesHeroSection
        imageUrl={
          "https://cdn.builder.io/api/v1/image/assets/TEMP/4e265835733c8d6a6eea6998a16b66ad872c731fcb9c8299c01f76cb0d5bfde9?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e"
        }
        title={"About Us"}
      />
        <FloatingImageContainer title={"About Us"} description={`
          At RCON Space, we bring together architects, project managers, site supervisors, and contractors on one unified platform, designed to simplify and enhance every phase of construction. From the initial architectural designs to the final site execution, our platform provides essential tools and modules for efficient project tracking, streamlined communication, and effective resource management. With a focus on innovation, transparency, and quality, RCON Space empowers construction teams to work smarter, stay connected, and deliver projects that exceed expectations. Join us in building a stronger, more efficient future for construction.

        `}/>
        <ValuesSection />
        <VisionSection/>
      </main>
      {/* <Footer/> */}
    </div>
  );
}

export default AboutUs;