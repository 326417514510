import React from 'react';
import styles from './VisionSection.module.css';
import { NavLink, useNavigate } from 'react-router-dom';

function VisionSection() {

  const navigate = useNavigate();

  const handelButtonClick = () => {
    navigate("/contact")
  }

  return (
    <section className={styles.visionSection}>
      <h2 className={styles.sectionTitle}>Vision</h2>
      <p className={styles.visionDescription}>
      "To lead the construction industry through sustainable practices, cutting-edge technology, and a commitment to building a better, more connected world."

      </p>
      <div className={styles.callToAction}>
        <h3 className={styles.ctaTitle}>Have questions or ready to start your next project? <NavLink to="/contact" style={{color:"#e47331",textDecoration:"none"}}> Contact Us.</NavLink> 
        </h3>
        <div className={styles.ctaButtons}>
          <button className={styles.chatButton} onClick={handelButtonClick}>Chat with Sales Team</button>
          
        </div>
      </div>
    </section>
  );
}

export default VisionSection;