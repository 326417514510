import React, { useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import styles from "./Dialog.module.css"; // Your custom styles
import { Loader } from "../../Widgets/notificationFeedbacks";

export const Dialog = ({
  open,
  onClose,
  maxWidth = "sm",
  fullWidth = true,
  fullScreen = false,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  PaperProps = { style: { borderRadius: 10, fontFamily: "Poppins" } },
  children,
  loaderState = { loader: false, message: "Loading..." },
  showLoader = false,
  title = "Dialog Title",
  titleStyles = {},
}) => {
  useEffect(() => {
    if (open) {
      // Disable background scrolling when dialog is open
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable background scrolling when dialog is closed
      document.body.style.overflow = "";
    }

    // Cleanup to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);

  const handleDialogClose = (event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") return;
    if (disableEscapeKeyDown && reason === "escapeKeyDown") return;
    onClose(event, reason);
  };

  const handleOverlayClick = (event) => {
    if (!disableBackdropClick) {
      onClose(event, "backdropClick");
    }
  };

  // Function to handle different maxWidth breakpoints similar to MUI
  const getMaxWidthClass = (maxWidth) => {
    switch (maxWidth) {
      case "xs":
        return styles.maxWidthXs;
      case "sm":
        return styles.maxWidthSm;
      case "md":
        return styles.maxWidthMd;
      case "lg":
        return styles.maxWidthLg;
      case "xl":
        return styles.maxWidthXl;
      case false:
        return ""; // No max-width, it can expand to the full container width
      default:
        return ""; // Custom string values can be handled separately
    }
  };

  return (
    <>
      {open && (
        <div className={styles.dialogOverlay} onClick={handleOverlayClick}>
          <div
            className={`${styles.dialogContainer} ${
              fullScreen ? styles.fullScreen : getMaxWidthClass(maxWidth)
            }`}
            style={{
              width: fullWidth ? "100%" : "auto",
              ...PaperProps.style,
            }}
            onClick={(e) => e.stopPropagation()} // Prevent click on dialog from closing it
          >
            {/* Dialog Header 
            <div
              className={styles.dialogHeader}
              style={{ backgroundColor: "#4D4D4D", height: "50px" }}
            >
              <p
                className={styles.dialogTitle}
                style={{ ...titleStyles, color: "white" }}
              >
                {title}
              </p>
              <RxCross1
                size={20}
                style={{
                  marginLeft: "auto",
                  cursor: "pointer",
                  marginRight: "20px",
                  color: "white",
                }}
                onClick={(event) => handleDialogClose(event, "escapeKeyDown")}
              />
            </div>*/}

            {/* Dialog Content */}
            <div className={styles.dialogContent}>
              {/* Loader */}
              {showLoader && (
                <Loader
                  open={loaderState.loader}
                  message={loaderState.message}
                />
              )}

              {/* Children content */}
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const DialogContent = ({ children, dividers = false, style = {} }) => {
  return (
    <div
      className={
        dividers
          ? styles.dialogContentWithDividers
          : styles.dialogContentWithoutDividers
      }
      style={style}
    >
      {children}
    </div>
  );
};