import React, { useState, useEffect } from "react";
import "./Calender.css";
import Navbar from "../Navbar/Header";
import { Calendar as Calender, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../WebApiManager";
import { userInfo, siteId } from "../../atoms";
import { useRecoilState } from "recoil";
import SitesDropdown from "../../Utilities/SitesDropdown";
import SelectSite from "../../Utilities/SelectSite";
import Footer from "../Footer/Footer";

const localizer = momentLocalizer(moment);

const Calendar = () => {
  let webApi = new WebApimanager();
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [site, setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [events, setEvents] = useState({});
  const [selectedFilter, setSelectedFilter] = useState("toDay");
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
  
    if (currentUserInfo && site && !sessionStorage.getItem("pageReloaded")) {
      sessionStorage.setItem("pageReloaded", "true");
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
    
  }, [currentUserInfo, site]);
  

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const fetchData = async () => {
    if (site) {
      // Fetch data only if 'site' has a value
      try {
        let response = await webApi.get(
          `api/webDashBoard/getAllTasks?siteId=${site}`
        );
        if (response.status === 200) {
          setEvents(response.data.data);
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  const handleSiteChange = (e) => {
    setSite(e.target.value);
  };

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (!site) {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  // Fetch data when 'site' or 'selectedFilter' changes
  useEffect(() => {
    fetchData();
  }, [site, selectedFilter]);

  // Update filteredEvents based on selectedFilter and available events
  useEffect(() => {
    if (Object.keys(events).length) {
      switch (selectedFilter) {
        case "toDay":
          setFilteredEvents(events.toDay);
          break;
        case "delayed":
          setFilteredEvents(events.delayed);
          break;
        case "redo":
          setFilteredEvents(events.redo);
          break;
        case "inProgress":
          setFilteredEvents(events.inProgress);
          break;
        case "completed":
          setFilteredEvents(events.completed);
          break;
        default:
          setFilteredEvents(events.toDay);
          break;
      }
    }
  }, [selectedFilter, events]);

  useEffect(() => {
    console.log(filteredEvents, "filteredEvents");
    console.log(events, "events");
  }, [filteredEvents, events]);

  return (
    <>
      <div>
        {!currentUserInfo._id ? (
          <>
            <SelectSite />
          </>
        ) : (
          <>
            <div style={{ fontFamily: "Poppins", backgroundColor: "#f3f3f3" }}>
              {/* <div style={{ margin: '16px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
                    <h4>Select Sitegg</h4>
                    <SitesDropdown siteId={site} handleSiteChange={handleSiteChange} sites={sites} defaultValue={'Select Site'} />
                </div> */}
              <div
                style={{
                  margin: "20px",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "12px",
                  }}
                >
                  <h3>Tasks</h3>
                  <select
                    style={{ width: "180px" }}
                    className="form-select w-5"
                    aria-label="Default select example"
                    onChange={handleFilterChange}
                    value={selectedFilter}
                  >
                    <option value="ToDay">Today</option>
                    <option value="delayed">Delayed</option>
                    <option value="redo">Redo</option>
                    <option value="inProgress">Inprogress</option>
                    <option value="completed">Completed</option>
                    {/* <option value="Upcoming">Upcoming</option> */}
                    {/* <option value="Next 7 Days">Next 7 Days</option>
                            <option value="Next 30 Days">Next 30 Days</option>
                            <option value="Next 3 Months">Next 3 Months</option>
                            <option value="Next 6 Months">Next 6 Months</option> */}
                  </select>
                </div>
                <ul className="list-group">
                  {filteredEvents?.length
                    ? filteredEvents?.map((event, index) => (
                        <li key={index} className="list-group-item">
                          <p>
                            {" "}
                            {selectedFilter !== "redo"
                              ? event.title
                              : event.daysRemaining > 0
                              ? `${event.title} Due in ${event.daysRemaining} days`
                              : event.title}
                          </p>
                          {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><h5><b>Work:</b> {event.task.workSequenceId.name}</h5><button className='btn btn-primary'>Submit</button></div>
                                <p><strong>Unit Number:</strong> {event.task.unitId.name}</p>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '40px' }}>
                                    <p><strong>Start Date:</strong> {moment(event.task.startDate).format('MMMM Do YYYY')}</p>
                                    <p><strong>End Date:</strong> {moment(event.task.endDate).format('MMMM Do YYYY')}</p>
                                </div> */}
                        </li>
                      ))
                    : ""}
                  {filteredEvents?.length === 0 && (
                    <li className="list-group-item">No tasks found.</li>
                  )}
                </ul>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  margin: "20px",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <div style={{ padding: "20px" }}>
                  <Calender
                    localizer={localizer}
                    // events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    views={["month"]}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Calendar;
