/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import ROtoSiteLevel from "./ROtoSiteLevel/ROtoSiteLevel";
import ArchitectureToRo from "./ArichtectureToRo/ArchitectureToRo";
import SiteLevel from "./SiteLevel/SiteLevel";
import { useRecoilState, useSetRecoilState } from "recoil";
import { allUsersInformations, drawingTab, registerAtom, siteId, userInfo } from "../../atoms";
import SitesDropdown from "../../Utilities/SitesDropdown";
import { WebApimanager } from "../../WebApiManager";
import Options from "./Options/Options";
import Sitehead from "./SITEHEAD/Sitehead";
import { Menu, MenuItem, Button } from "@mui/material";

function Drawing() {
  let webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(drawingTab);
  const [site, setSite] = useRecoilState(siteId);
  const [register, setRegister] = useRecoilState(registerAtom);
  const [sites, setDatasites] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const setAllUserDetails = useSetRecoilState(allUsersInformations)
  
  const [tabEnableModules, setTabEnableModules] = useState({
    architectureToRo: false,
    ro: false,
    siteHead: false,
    siteToSite: false,
  });
  const tabNames = [
    "CONSULTANT >",
    "RO >",
    "SITEHEAD >",
    "SITELEVEL >",
    "OPTIONS >",
  ];
  // useEffect(() => {
  //   if (!currentUserInfo || !site) return;

  //   console.log("currentUserInfo in Drawing.js:", currentUserInfo);

  //   const enabledTabs = {
  //     architectureToRo: false,
  //     ro: false,
  //     siteHead: false,
  //     options: false,
  //     siteToSite: false,
  //   };

  //   // Match the selected siteId with permittedSites
  //   const matchedSite = currentUserInfo.permittedSites?.find(
  //     (siteObj) => siteObj.siteId === site
  //   );

  //   if (matchedSite?.enableModules?.drawingDetails) {
  //     const { architectureToRo, ro, siteHead, options, siteToSite } =
  //       matchedSite.enableModules.drawingDetails;

  //     enabledTabs.architectureToRo = !!architectureToRo;
  //     enabledTabs.ro = !!ro;
  //     enabledTabs.siteHead = !!siteHead;
  //     enabledTabs.options = !!options;
  //     enabledTabs.siteToSite = !!siteToSite;
  //   }

  //   console.log("Enabled Tabs for the matched site:", enabledTabs);
  //   setTabEnableModules(enabledTabs);
  // }, [currentUserInfo, site]);
  // useEffect(() => {
  //   if (!currentUserInfo || !site) return;
  
  //   console.log("currentUserInfo in Drawing.js:", currentUserInfo);
  
  //   const enabledTabs = {
  //     architectureToRo: false,
  //     ro: false,
  //     siteHead: false,
  //     options: false,
  //     siteToSite: false,
  //   };
  
  //   // Match the selected siteId with permittedSites
  //   const matchedSite = currentUserInfo.permittedSites?.find(
  //     (siteObj) => siteObj.siteId === site
  //   );
  
  //   if (matchedSite?.enableModules?.drawingDetails) {
  //     const { architectureToRo, ro, siteHead, options, siteToSite } =
  //       matchedSite.enableModules.drawingDetails;
  
  //     enabledTabs.architectureToRo = !!architectureToRo;
  //     enabledTabs.ro = !!ro;
  //     enabledTabs.siteHead = !!siteHead;
  //     enabledTabs.options = !!options;
  //     enabledTabs.siteToSite = !!siteToSite;
  //   }
  
  //   console.log("Enabled Tabs for the matched site:", enabledTabs);
  //   setTabEnableModules(enabledTabs);
  //   const enabledTabIndex = [
  //     enabledTabs.architectureToRo,
  //     enabledTabs.ro,
  //     enabledTabs.siteHead,
  //     enabledTabs.siteToSite,
  //     enabledTabs.options,
  //   ].findIndex((isEnabled) => isEnabled);
  
  //   setValue(enabledTabIndex === -1 ? 0 : value); 
  // }, [currentUserInfo, site]);
  useEffect(() => {
    if (!currentUserInfo || !site) return;
  
    console.log("currentUserInfo in Drawing.js:", currentUserInfo);
  
    const enabledTabs = {
      architectureToRo: false,
      ro: false,
      siteHead: false,
      options: false,
      siteToSite: false,
    };
  
    // Match the selected siteId with permittedSites
    const matchedSite = currentUserInfo.permittedSites?.find(
      (siteObj) => siteObj.siteId === site
    );
  
    if (matchedSite?.enableModules?.drawingDetails) {
      const { architectureToRo, ro, siteHead, options, siteToSite } =
        matchedSite.enableModules.drawingDetails;
  
      enabledTabs.architectureToRo = !!architectureToRo;
      enabledTabs.ro = !!ro;
      enabledTabs.siteHead = !!siteHead;
      enabledTabs.options = !!options;
      enabledTabs.siteToSite = !!siteToSite;
    } else {
      console.warn("No drawingDetails found, using default tab configuration.");
    }
  
    console.log("Enabled Tabs for the matched site:", enabledTabs);
    setTabEnableModules(enabledTabs);
  
    const enabledTabIndex = [
      enabledTabs.architectureToRo,
      enabledTabs.ro,
      enabledTabs.siteHead,
      enabledTabs.siteToSite,
      enabledTabs.options,
    ].findIndex((isEnabled) => isEnabled);
  
    setValue(enabledTabIndex === -1 ? 0 : enabledTabIndex);
  }, [ site]);
  
  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    console.log("Selected Site ID:", site);
    setRegister([]);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
      window.location.reload();
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  };

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const allTabsDisabled =
    !tabEnableModules.architectureToRo &&
    !tabEnableModules.ro &&
    !tabEnableModules.siteHead &&
    !tabEnableModules.siteToSite;

  useEffect(() => {
    console.log("Enabled Tabs State:", allTabsDisabled);
  }, [allTabsDisabled]);


  const fetchAllUsersInfo = async(id) => {
    try {
      const allUserResponse = await webApi.get("api/users")
      console.log(allUserResponse.data.data.allUsers,"allUsersInfo")
      
      if(allUserResponse.status === 200){
        setAllUserDetails(allUserResponse?.data?.data?.allUsers)
        
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  // useEffect(()=>{
  //   fetchAllUsersInfo()
  // },[site])

  // useEffect(()=>{},[forwardAccess,rfiRaisedAccess])

  return (
    <div style={{ margin: "20px", position: "relative" }}>
      {/* Dropdown button and SitesDropdown section */}
      <div style={{ position: "absolute", paddingTop: "25px", top: "5px" }}>
        <Button
          onClick={handleMenuOpen}
          style={{
            color: "#131313",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Poppins",
          }}
        >
          {tabNames[value]}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "tab-menu",
          }}
        >
          {tabEnableModules.architectureToRo && (
            <MenuItem onClick={(e) => handleChange(e, 0, "CONSULTANT >")}>
              CONSULTANT
            </MenuItem>
          )}
          {tabEnableModules.ro && (
            <MenuItem onClick={(e) => handleChange(e, 1, "RO >")}>RO</MenuItem>
          )}
          {tabEnableModules.siteHead && (
            <MenuItem onClick={(e) => handleChange(e, 2, "SITEHEAD >")}>
              SITEHEAD
            </MenuItem>
          )}
          {tabEnableModules.siteToSite && (
            <MenuItem onClick={(e) => handleChange(e, 3, "SITELEVEL >")}>
              SITELEVEL
            </MenuItem>
          )}
          {tabEnableModules.options && (
            <MenuItem onClick={(e) => handleChange(e, 4, "OPTIONS >")}>
              OPTIONS
            </MenuItem>
          )}
        </Menu>
      </div>

      <div style={{ position: "absolute", paddingTop: "25px", right: 0 }}>
        {!allTabsDisabled && value !== 4 && (
        <div style={{ marginLeft: "auto" }}>
          <SitesDropdown
            siteId={site}
            handleSiteChange={handleSiteChange}
            sites={sites}
            defaultValue={"Select Site"}
          />
        </div>
         )} 
      </div>

      <div style={{ marginTop: "20px" }}>
        {allTabsDisabled ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#E47331",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            You do not have the necessary permissions to view any drawing tabs.
            <br />
            Please contact your administrator for access rights.
          </div>
        ) : (
          <>
            {tabEnableModules.architectureToRo && (
              <TabPanel value={value} index={0}>
                <ArchitectureToRo />
              </TabPanel>
            )}
            {tabEnableModules.ro && (
              <TabPanel value={value} index={1}>
                <ROtoSiteLevel />
              </TabPanel>
            )}
            {tabEnableModules.siteHead && (
              <TabPanel value={value} index={2}>
                <Sitehead />
              </TabPanel>
            )}
            {tabEnableModules.siteToSite && (
              <TabPanel value={value} index={3}>
                <SiteLevel />
              </TabPanel>
            )}
            
            {tabEnableModules.options && (
              <TabPanel value={value} index={4}>
                <Options />
              </TabPanel>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tabpanel"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ display: value === index ? "block" : "none" }}
    >
      {value === index && <div style={{ width: "100%" }}>{children}</div>}
    </div>
  );
}

export default Drawing;
