import React, { useState, useEffect, useRef } from "react";
import Pen from "../SITEHEAD/IconsSiteHead/penIcon.svg"
import { WebApimanager } from "../../../WebApiManager";
import { allUsersInformations, siteId } from "../../../atoms";
import ToggleSwitch from "../../Sites/ToggleSwitch";
import { useRecoilState } from "recoil";
import MultiSelectDropdown from "../../Utilities/MultiSelectDropdown";
export const RFIAccess = ({setCustomizedView}) => {

  let webApi = new WebApimanager();

  const [formData, setFormData] = useState({
    customizedView:"",
    ronofdays: "",
    siteHeadnofdays:"",
    rfiRaised:"",
    isAddFolder:"",
    areYouReceivingHardCopiesFromAllConsultants:"",
    drawingAddFolder:false,
  });
  const [notSelectedHardCopy, setNotSelectedHardCopy] = useState([])
  const [isEditing, setIsEditing] = useState({
    cancustomizedViewEdit:false,
    canRoEdit:false,
    canSiteHeadEdit:false,
    canisAddFolderEdit:false,
    candrawingAddFolder:false,
    canrfiRaisedEdit:false,
    isEditRecievedHardCopiesFromAllConsultants:false
  });
  const [selectedValues, setSelectedValues] = useState([]);
  const [allConsultants, setAllConsultants] = useState([])
  const [dataFetched, setDataFetched] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [responseData,setResponseData] = useState([]);
  const [site,setSite] = useRecoilState(siteId);
  const [allUsersInfo, setAllUsersInfo] = useRecoilState(allUsersInformations)
  const customizedViewEditRef = useRef()
  const roEditRef = useRef()
  const siteHeadRef = useRef()
  const isAddFolderEditRef = useRef()
  const drawingAddFolderRef = useRef()
 const rfiRaisedEditRef =useRef()
 const hardCopyConsultantRef = useRef()

 const handleSelectionChange = (newSelectedValues) => {
  setSelectedValues(newSelectedValues);
};
 const fetchAllConsultants = async() => {
  try {
    const res = await webApi.get(`api/designDrawingConsultant/getAll?siteId=${site}`)
    console.log(res?.data.status ,"res1")
    console.log(res?.data?.data?.users,"res2")
    if(res?.data.status === "success"){
      const consultantList =[]
      res?.data?.data?.users.forEach((consultant)=>{
        consultantList.push({
          _id: consultant._id, 
          role: consultant.role
        })
      })
      setAllConsultants(consultantList)
    }
  } catch (error) {
    console.log(error.message)
  }
 }

  const fetchNoOfDays = async() =>{
    try {
      const response = await webApi.get("api/rfiTimeStamp/byCompanyId")
      console.log(response.data.data.customizedView ,"response in the rfi timeStamp")
       if (response.status === 200) {
        setResponseData(response.data.data)
        setCustomizedView(response.data.data.customizedView)
        const customizedView = response.data.data.customizedView ? "true":"false";
        const roRfiTimeStampDays = response.data.data.roRfiTimeStampDays || "";
        const siteHeadRfiTimeStampDays = response.data.data.siteHeadRfiTimeStampDays || "";
        const isDrawingAddFolder = response.data.data.isDrawingAddFolder || "";
        const drawingAddFolder = response.data.data.drawingAddFolder || "";
        const rfiRaised = response.data.data.rfiRaised ? "true":"false" || "";
        const recievedHardCopies = response.data.data.areYouReceivingHardCopiesFromAllConsultants?"true":"false" || ""
        setNotSelectedHardCopy(response?.data?.data?.whichConsultantsHaveNotSubmittedHardCopies)
        setFormData({
          customizedView:customizedView,
    ronofdays: roRfiTimeStampDays,
    siteHeadnofdays:siteHeadRfiTimeStampDays,
    isAddFolder:isDrawingAddFolder,
    drawingAddFolder:drawingAddFolder,
    rfiRaised:rfiRaised,
    areYouReceivingHardCopiesFromAllConsultants:recievedHardCopies
        });
      
         
        setIsEditing({
          cancustomizedViewEdit:false,
    canRoEdit:false,
    canSiteHeadEdit:false,
    canisAddFolderEdit:false,
    candrawingAddFolder:false,
    canrfiRaised:false,
    isEditRecievedHardCopiesFromAllConsultants:false,
        });
        if(roRfiTimeStampDays || siteHeadRfiTimeStampDays || customizedView || isDrawingAddFolder || drawingAddFolder || rfiRaised || recievedHardCopies) 
          setDataFetched(true);
        setShowSubmit(false );
      }
    } catch (error) {
      console.log(error.message)
      setShowSubmit(true)
    }
  }
  
  useEffect(()=>{
    fetchNoOfDays()
  },[])
  useEffect(()=>{
    // if(formData.areYouReceivingHardCopiesFromAllConsultants === "No")
    fetchAllConsultants()
  },[site])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRoEditClick = () => {
    setIsEditing((prev) => ({ ...prev, canRoEdit: true }));
    setShowSubmit(true); 
    roEditRef.current.focus();
  };
 
  const  handleisAddFolderEditClick = () => {
    setIsEditing((prev) => ({ ...prev, canisAddFolderEdit: true }));
    setShowSubmit(true); 
    isAddFolderEditRef.current.focus();
  };
  const handlecustomizesEditClick = () => {
    setIsEditing((prev) => ({ ...prev, cancustomizedViewEdit: true }));
    setShowSubmit(true); 
    customizedViewEditRef.current.focus();
  };

  const handlerfiRaisedEditClick = () => {
    setIsEditing((prev) => ({ ...prev, canrfiRaisedEdit: true }));
    setShowSubmit(true); 
    rfiRaisedEditRef.current.focus();
  };
  const handleRecievedHardCopies = () => {
    setIsEditing((prev) => ({ ...prev, isEditRecievedHardCopiesFromAllConsultants: true }));
    setShowSubmit(true); 
    hardCopyConsultantRef.current.focus();
  };
  const handleSubmit = async(e) => {
    if(formData.ronofdays && formData.siteHeadnofdays){
    const payload ={
      siteId:site,
      customizedView:formData.customizedView === "true"?true:false,
      roRfiTimeStampDays:formData.ronofdays,
      siteHeadRfiTimeStampDays:formData.ronofdays,
      isDrawingAddFolder:formData.isAddFolder,
      rfiRaised:formData.rfiRaised === "true"? true : false,
      drawingAddFolder : formData.isAddFolder === "Yes" ? true : false,
      areYouReceivingHardCopiesFromAllConsultants:formData.areYouReceivingHardCopiesFromAllConsultants === "true" ? true :false,
      ...(formData.areYouReceivingHardCopiesFromAllConsultants === "false" && {
        whichConsultantsHaveNotSubmittedHardCopies: selectedValues.map((value)=>value._id),
      })
    }
    try {
      const response = await webApi.post("api/rfiTimeStamp",payload)
      if (response.status === 200){
        setCustomizedView(formData.customizedView === "true"?true:false)
        const updatedAllUserInfo = allUsersInfo.map((item) => ({
          ...item,
          permittedSites: item.permittedSites?.map((siteItem) => ({
            ...siteItem,
            enableModules: {
              ...siteItem.enableModules,
              rfiRaised:formData.rfiRaised === "true"? true : false
            },
          })),
        }));
        console.log(updatedAllUserInfo,"allUsersInfoInRfiAccess")
        setAllUsersInfo(updatedAllUserInfo)
        setSelectedValues([])
        window.location.reload()
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  };

  useEffect(()=>{
    const NotRecivedHardCopy =[]
    allConsultants.forEach((consultant)=>{
      if(notSelectedHardCopy.includes(consultant?._id)){
        NotRecivedHardCopy.push({
          _id:consultant._id,
          role:consultant.role
        })
      }
    })
    console.log(NotRecivedHardCopy,"NotRecievedHardCopy")
    setSelectedValues(NotRecivedHardCopy)
  },[notSelectedHardCopy,allConsultants])


  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "15px" }}>
        <div style={{ display: "flex", justifyContent:"space-between", gap: "10px",marginTop:'15px' }}>
        <label style={{ marginTop: "8px" }}>
        Q) Do you want a customized view?
        </label>
        <div style={{ display: "flex", alignItems: "center", }}>
         
                       <select
                       name="customizedView"
                    value={formData.customizedView }
                    onChange={handleInputChange}
                    style={{
                      paddingLeft: "15px",
                      borderRadius: "6px",
                      border: isEditing.cancustomizedViewEdit ?"1px solid var(--primary-color)" :"none",
                      width: "250px",
                      height: "40px",
                      backgroundColor: isEditing.cancustomizedViewEdit? "white" : "#f0f0f0",
                    }}
                    ref={customizedViewEditRef}
                    disabled={!isEditing.cancustomizedViewEdit}
                  >
                    <option value="">Select Yes/No</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
          {dataFetched && formData.customizedView  && (
            <img
            src={Pen}
            onClick={()=>handlecustomizesEditClick()}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
              fontSize: "16px",
            }}
            />
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent:"space-between", gap: "10px" ,marginTop:'15px'}}>
        <label style={{ marginTop: "10px" }}>  
       Q) Is the drawing register being saved in a folder-based
        structure?</label>
        <div style={{ display: "flex", alignItems: "center"}}>
                      <select
                      name="isAddFolder"
                    value={formData.isAddFolder}
                    onChange={handleInputChange}
                    style={{
                      paddingLeft: "15px",
                      borderRadius: "6px",
                      border: isEditing.canisAddFolderEdit ?"1px solid var(--primary-color)" :"none",
                      width: "250px",
                      height: "40px",
                      backgroundColor: isEditing.canisAddFolderEdit ? "white" : "#f0f0f0",
                    }}
                    ref={isAddFolderEditRef}
                    disabled={!isEditing.canisAddFolderEdit}
                  >
                   
                    <>
                    <option value="">Select Yes/No</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    </>
     </select>
          {dataFetched && formData.isAddFolder  && (
            <img
            src={Pen}
            onClick={()=>handleisAddFolderEditClick()}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
              fontSize: "16px",
            }}
            />
          )}
        </div>
      </div>
    
      <div style={{ display: "flex", justifyContent:"space-between", gap: "10px" ,marginTop:'15px'}}>
        <label style={{ marginTop: "10px" }}>  
       Q) Should RFI access be granted only after receiving both soft copy and hard copy?
       </label>
        <div style={{ display: "flex", alignItems: "center",}}>
                      <select
                      name="rfiRaised"
                    value={formData.rfiRaised}
                    onChange={handleInputChange}
                    style={{
                      paddingLeft: "15px",
                      borderRadius: "6px",
                      border: isEditing.canrfiRaisedEdit ?"1px solid var(--primary-color)" :"none",
                      width: "250px",
                      height: "40px",
                      backgroundColor: isEditing.canrfiRaisedEdit ? "white" : "#f0f0f0",
                    }}
                    ref={rfiRaisedEditRef}
                    disabled={!isEditing.canrfiRaisedEdit}
                  >
                   
                    <>
                    <option value="">Select Yes/No</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                    </>
     </select>
          {dataFetched && formData.rfiRaised  && (
            <img
            src={Pen}
            onClick={()=>handlerfiRaisedEditClick()}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
              fontSize: "16px",
            }}
            />
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent:"space-between", gap: "10px",marginTop:'15px' }}>
        <label style={{ marginTop: "10px" }}>
         Q) How many days does the consultant have to upload the softcopy file
          after an RFI has been raised?
        </label>
        <div style={{ display: "flex", alignItems: "center",  }}>
          <input
            placeholder="Enter The Number OF Days"
            name="ronofdays"
            value={formData.ronofdays}
            onChange={handleInputChange}
            style={{
              paddingLeft: "15px",
              borderRadius: "6px",
              border: isEditing.canRoEdit ?"1px solid var(--primary-color)" :"none",
              width: "250px",
              height: "40px",
              backgroundColor: isEditing.canRoEdit ? "white" : "#f0f0f0",
            }}
            ref={roEditRef}
            readOnly={!isEditing.canRoEdit}

          />
          {dataFetched && formData.ronofdays  && (
            <img
            src={Pen}
            onClick={()=>handleRoEditClick()}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
              fontSize: "16px",
            }}
            />
          )}
        </div>
      </div>

      <div style={{ display: "flex", justifyContent:"space-between", gap: "10px",marginTop:'15px' }}>
        <label style={{ marginTop: "10px" }}>
         Q) Are you recieving hard copies from all consultants?
        </label>
        <div style={{ display: "flex", alignItems: "center",  }}>
          <select
            placeholder="Enter The Number OF Days"
            name="areYouReceivingHardCopiesFromAllConsultants"
            value={formData.areYouReceivingHardCopiesFromAllConsultants}
            onChange={handleInputChange}
            style={{
              paddingLeft: "15px",
              borderRadius: "6px",
              border: isEditing.isEditRecievedHardCopiesFromAllConsultants ?"1px solid var(--primary-color)" :"none",
              width: "250px",
              height: "40px",
              backgroundColor: isEditing.isEditRecievedHardCopiesFromAllConsultants ? "white" : "#f0f0f0",
            }}
            ref={hardCopyConsultantRef}
            disabled={!isEditing.isEditRecievedHardCopiesFromAllConsultants}

          >
            <option value="">Select Yes/No</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          {dataFetched && formData.areYouReceivingHardCopiesFromAllConsultants  && (
            <img
            src={Pen}
            onClick={()=>handleRecievedHardCopies()}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
              fontSize: "16px",
            }}
            />
          )}
        </div>
      </div>

      {formData.areYouReceivingHardCopiesFromAllConsultants === "false" && (
        <div style={{ display: "flex", justifyContent:"space-between", gap: "10px",marginTop:'15px' }}>
        <label style={{ marginTop: "10px" }}>
         {"Q) Which Consultants have not submitted Hard Copies?"}
        </label>
        <div style={{ display: "flex", alignItems: "center",  }}>
         <MultiSelectDropdown options={allConsultants} selectedValues={selectedValues}
        onChange={handleSelectionChange} isEditing={isEditing} />
        </div>
      </div>
      )}


      {showSubmit && (
        <button
          onClick={handleSubmit}
          style={{
            marginTop: "15px",
            padding: "10px 20px",
            borderRadius: "6px",
            border: "none",
            backgroundColor: "var(--primary-color)",
            color: "white",
            cursor: "pointer",
            width:"max-content",
            marginInline:"auto"
          }}
        >
          Submit
        </button>
      )}
    </div>
  );
};