import React from 'react'
import { RxCross1 } from 'react-icons/rx'
import styles from "./AddnewPnm.module.css"
import { Dialog } from '../../Dialog/Dialog'

const AddNewPnMViewForm = ({open,handleClose,tableRowData}) => {
    console.log(tableRowData,"viewForm")
  return (
    <Dialog
          open={open}
          disableEnforceFocus
          onClose={handleClose}
          maxWidth="md" 
          fullWidth 
          PaperProps={{
            style: {
              height: "1553px", 
              width: "90vw", 
              maxWidth: "none",
            },
          }}
        >
          <div className={styles.AddNewPnMFormDailogHeadingContainer}>
            <h3 className={styles.AddNewPnMFormDailogHeading}> View </h3>
            <RxCross1
              size={20}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "25px",
                color: "white",
              }}
              onClick={handleClose}
            />
          </div>
          <>
          <section className={styles.AddNewPnMFormViewContainer}>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Type</strong>
                    </p>
                    :<p>{tableRowData.type}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Fuel/Not</strong>
                    </p>
                    :<p>{tableRowData.fuelRnot}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Name</strong>
                    </p>
                    :<p>{tableRowData.assetCode.name}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Make Name</strong>
                    </p>
                    :<p>{tableRowData.makeName}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Model</strong>{" "}
                    </p>
                    :<p>{tableRowData.modelName}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Inspection</strong>
                    </p>
                    :<p>{tableRowData.inspections}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>
                        select Engine / Motor
                      </strong>
                    </p>
                    :<p>{tableRowData.engineRmotor}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Subcode</strong>
                    </p>
                    :<p>{tableRowData.assetCode.subCode}</p>  
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Insurance</strong>
                    </p>
                    :<p>{tableRowData?.oldInsurance?tableRowData.oldInsurance.daysCount+" "+tableRowData.oldInsurance.daysType:tableRowData.newInsurance?tableRowData.newInsurance.daysCount+" "+tableRowData.newInsurance.daysType:tableRowData.hireInsurance?tableRowData.hireInsurance.daysCount+" "+tableRowData.hireInsurance.daysType:"No"
                    }</p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>New / Old</strong>
                    </p>
                    :<p>{tableRowData.own}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>
                        Purchase Date
                      </strong>
                    </p>
                    :
                    <p>
                      {tableRowData.own === "New"
                        ? tableRowData.newPurchaseDate.slice(0,10)
                        : tableRowData.own === "Old"?tableRowData.oldPurchaseDate.slice(0,10):tableRowData.hirePreviousServiceDate.slice(0,10)}
                    </p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>
                        Purchase Price
                      </strong>
                    </p>
                    :
                    <p>
                      {tableRowData.own === "New"
                        ? tableRowData.newPurchasePrice
                        : tableRowData.own === "Old"?tableRowData.oldPreviousPurchasePrice:tableRowData.hirePrice}
                    </p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>


            </section>
                  {tableRowData?.documents.length ? tableRowData?.documents.map((doc)=>(
                    <section className={styles.AddNewPnMFormViewContainer}>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>{doc.documentName}:</strong>
                    </p>
                    :<p>{doc.documentNo || "N/A"}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Date of Regn:</strong>
                    </p>
                    :<p>{doc.regDate.slice(0,10) || "N/A"}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Regn Validity:</strong>
                    </p>
                    :<p>{doc.expDate.slice(0,10) || "N/A"}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Chosen File:</strong>
                    </p>
                    :<p>{doc?.documentFile?doc?.documentFile.split("-",2)[1] : "N/A"}</p>
                    
                    

                  </section>
                  )) :<p style={{padding:"16px"}}>No Document Available</p>}
          </>

                
          

        </Dialog>
  )
}

export default AddNewPnMViewForm