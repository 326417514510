import React, {  useState, useEffect } from "react";
import Styles from "./ForgotPassword.module.css"
import PasswordImg from "./Images/PasswordImg.png"
import MobileLogo from "../SignIn/Images/MobileLogo.png"


import { Link,useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";

function ForgotPassword() {
  let webApi = new WebApimanager();
  const [email, setEmail] = useState("");
const [emailError, setEmailError] = useState("");
const [isTrue,setIsTrue] = useState(false)
const navigate = useNavigate();
const [apiProcessing, setApiProcessing] = useState({
  loader: false,
  message: "Loading...",
})
const [errorMessage, setErrorMessage] = useState("");
const handleSubmit = async (e) => {
  e.preventDefault();

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const phoneRegex = /^(\+91[\-\s]?)?[6-9]\d{9}$/;
  if (!email.trim()) {
    setEmailError("Email  is required");
    return; 
  } else if(email && (!emailRegex.test(email) && !phoneRegex.test(email))){
    setEmailError("Enter a valid Email or Phone Number")
    return;
  } else {
    setApiProcessing({
      loader: true,
      message: "Sending...",
    });
    try {
      const mail ={
email:email
      };
      const response = await webApi.post(
        "api/users/requestForPassword",
        mail
      );
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.status === "error") {
          setErrorMessage(responseData.message);
          console.log("Response data:", responseData.message);
          setApiProcessing({ loader: false, message: "" });
          return;
        }
        setIsTrue((prev)=>!prev)
        navigate("/verify-code",{state:email})
      }

    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
   
    }
  
  
   
    
  }
};

useEffect(() => {
  document.body.style.overflow = 'hidden';

  return () => {
    document.body.style.overflow = '';
  };
}, []);

return (
  
  <>
  <div className={Styles.LoginContainer}>
        <div className={Styles.LeftContainer}>
         
          <form onSubmit={handleSubmit} className={Styles.FormContainer}>
          <div className={Styles.ImageContainer}>
                  <img className={Styles.Logo} src="logo/logo3.png" />
                </div>
                 <h5 className={Styles.LoginText}>Forget Your Password</h5>
                 <p className={Styles.FormDescription}>
               Don't worry, happens to all of us. <br />
              Enter your email ID or phone number below to recover your password
               </p>
                 <div className={Styles.InputContainer}>
                  <div className={Styles.EmailContent}>
                    <label className={Styles.InputLabel}>Enter Your Email or Phone number*</label>
                    <input
                   type="text"
                   id="emailOrPhone"
                   className={Styles.InputFeild}
                   placeholder="support@mrchams.com"
                   aria-label="Enter your email or phone number"
                   name="email"
                   onChange={(e)=>setEmail(e.target.value)}
                 />
                    {emailError && (
                <p  className={Styles.Error}>
                  {emailError}
                </p>
              )}
                    
                  </div>
                  

                </div>
                <div className={Styles.SubmitBtnContainer}>
                <button type="submit" className={Styles.SubmitBtn} >
                  Continue
                </button>
                </div>
                {errorMessage && (
                <p style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </p>
              )}
          </form>
        </div>
        <div className={Styles.RightContainer} >
        <img src={PasswordImg} alt="" className={Styles.RightContainerImage} />
        </div>
        {/* <div className={Styles.MobileContainer}>
        <div className={Styles.MobileView}>
            <div className={Styles.MobileViewLogo}>
              <img src={MobileLogo} alt="" />
              <p className={Styles.HoldOn}>Hold On!</p>
            </div> 
        </div>
        <div className={Styles.MobileMsgContainer}>
              <p className={Styles.MobileMsg}>We are currently not supporting Mobile Devices please click the below button to Download mobile application</p>
              
              <div className={Styles.BtnContainer}>
        <button className={Styles.DownloadBtn}>
            <Link to="https://www.google.com/" style={{textDecoration:"none",color:"white"}}>Download</Link>
      </button>

        </div>
        </div>
        
        </div> */}
      </div>
      </>
);
}

export default ForgotPassword;
