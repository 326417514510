import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useRecoilState } from "recoil";
import { RxCross1 } from "react-icons/rx";
import SelectSite from "../../Utilities/SelectSite";
import { updateRegister } from "./BackendUtils";
import { atororegisterTab, folderId, registerAtom, siteId, userInfo } from "../../atoms";
import { WebApimanager } from "../../WebApiManager";
import TableWrapper from "../../Utilities/TableWrapper";
import DrawingTableWrapper from "./Components/DrawingTableWrapper";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { SUCESS_STATUS } from "../../Utilities/Constants";
import { Dialog, DialogContent } from "../Dialog/Dialog";
import { canShowFolder } from "./ShowFolder";

const RegisterPage = (props) => {
  const { filter,selectedRows,setSelectedRows } = props;
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  let webApi = new WebApimanager();
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [folder, setFolder] = useRecoilState(folderId);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };
  useEffect(() => {
    if (filter === "AddRegister") {
      webApi
        .get(`api/architectureToRoRegister/getRegisterBySiteId?siteId=${site}&folderId=${folder}`)
        .then((response) => {
          
          if (response && response.data && response.data.data && response.data.data.registers) {
            setDrawingList(response.data.data.registers);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
     
      fetchInData();
    }
  }, [filter, site, folder]);
  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
      }
    });
  };

  useEffect(() => {
    if (!searchActive) {
      fetchInData();
    }
  }, [searchActive]);

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [existingCategories, setExistingCategories] = useState([]);
  const [existingConsultants, setExistingConsultants] = useState([]);
  const [currentDrawingId, setCurrentDrawingId] = useState("");
  const [currentUserInfo] = useRecoilState(userInfo);

  const [formData, setFormData] = useState({
    DrawingNumber: "",
    DrawingTitle: "",
    DesignConsultant: "",
    DrawingCategory: "",
    AcceptedROSubmissionDate: "",
    AcceptedSiteSubmissionDate: "",
    RohardCopy:"",
    SiteHeadhardCopy:"",
  });
  const [currentView, setCurrentView] = useRecoilState(atororegisterTab);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const getAll = () => {
    webApi.get("api/category/getAll").then((data) => {
      if (data) {
        setExistingCategories(data.data.data.categories);
      }
    });

    webApi
      .get(`api/designDrawingConsultant/getAll?siteId=${site}`)
      .then((data) => {
        if (data) {
          setExistingConsultants(data.data.data.consultants);
        }
      });
  };

  useEffect(() => {
    if (
      existingCategories &&
      existingCategories.length === 0 &&
      existingConsultants.length === 0
    ) {
      getAll();
    }
  }, []);

  const handleEdit = (drawingItem) => {
    setCurrentDrawingId(drawingItem._id);
    setFormData({
      DrawingNumber: drawingItem.drawingNo,
      DrawingTitle: drawingItem.drawingTitle,
      DrawingCategory: drawingItem.category
        ? drawingItem?.category?.category
        : "", // Ensure this is the correct property
      DesignConsultant: `${drawingItem.designDrawingConsultant.role}`,
      AcceptedROSubmissionDate: drawingItem.acceptedROSubmissionDate
        ? drawingItem.acceptedROSubmissionDate.split("T")[0]
        : "",
      AcceptedSiteSubmissionDate: drawingItem.acceptedSiteSubmissionDate
        ? drawingItem.acceptedSiteSubmissionDate.split("T")[0]
        : "",
        RohardCopy:drawingItem.noOfRoHardCopyRevisions,
        SiteHeadhardCopy:drawingItem.noOfSiteHeadHardCopyRevisions,
    });
    handleOpen();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    const tempFormData = {
      siteId: site,
      drawingTitle: formData.DrawingTitle,
      noOfRoHardCopyRevisions: formData.RohardCopy,
      noOfSiteHeadHardCopyRevisions: formData.SiteHeadhardCopy,
      acceptedROSubmissionDate: formData.AcceptedROSubmissionDate,
      acceptedSiteSubmissionDate: formData.AcceptedSiteSubmissionDate,
    };
    try {
      const response = await webApi.put(
        `api/architectureToRoRegister/update/${currentDrawingId}`,
        tempFormData
      );
      if (response.status === 200) {
        fetchData();
        handleClose();
        setApiProcessing({ loader: false, message: "" });
        // window.location.reload();
      } else if (response.status === 500 || response.status === 400) {
        setApiProcessing({ loader: false, message: "" });
        // Check if the response contains an error message array
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          // If the specific message is not found, set a generic error message
          setErrorMessage("Please Try After Some Time");
        }
      } else {
        // If the specific message is not found, set a generic error message
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      // Handle errors that occur during the request
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data) &&
        error.response.data.length > 0
      ) {
        setErrorMessage(error.response.data[0]); // Set the first error message
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Apply two-digit restriction only for specific fields
    if (name === "RohardCopy" || name === "SiteHeadhardCopy") {
      if (/^\d{0,2}$/.test(value)) {
        // Set the formData state based on field name
        setFormData((prevState) => {
          // If the field is SiteHeadhardCopy, ensure it's less than or equal to RohardCopy
          if (name === "SiteHeadhardCopy" && Number(value) > Number(prevState.RohardCopy)) {
            return {
              ...prevState,
              [name]: prevState.RohardCopy, 
            };
          }
          return {
            ...prevState,
            [name]: value, // Update the value for the field
          };
        });
      }
    } else {
      // For other fields, update state normally
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  
  const [trashOpen, setTrashOpen] = useState(false);
  const [trashData, setTrashData] = useState({
    uniquePassword: "",
  });
  const handleTrashOpen = (drawingItem) => {
    setTrashOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTrashData({
      ...trashData,
      [name]: value,
    });
  };
  const handleTrashClose = () => {
    setTrashOpen(false);
  };
  const handleTrashSubmit = async (id) => {
    try {
      const response = await webApi.delete(``);
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    if (searchValue === "") {
      fetchData(); 
      setSearchActive(false);
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        if (drawingItem) {
          const drawingNumber = drawingItem.drawingNo
            ? drawingItem.drawingNo.toLowerCase()
            : "";
          const drawingName = drawingItem.drawingTitle
            ? drawingItem.drawingTitle.toLowerCase()
            : "";
          const consultant =
            drawingItem.designDrawingConsultant?.role?.toLowerCase() || "";
          const category =
            drawingItem.category && drawingItem.category.category
              ? drawingItem.category.category.toLowerCase()
              : "";
          const acceptedROSubmissionDate = drawingItem.acceptedROSubmissionDate
            ? new Date(
                drawingItem.acceptedROSubmissionDate
              ).toLocaleDateString()
            : "";
          const acceptedSiteSubmissionDate =
            drawingItem.acceptedSiteSubmissionDate
              ? new Date(drawingItem.acceptedSiteSubmissionDate).toDateString()
              : "";

          return (
            drawingNumber.includes(searchValue) ||
            drawingName.includes(searchValue) ||
            consultant.includes(searchValue) ||
            category.includes(searchValue) ||
            acceptedROSubmissionDate.includes(searchValue) ||
            acceptedSiteSubmissionDate.includes(searchValue)
          );
        } else {
          return false;
        }
      });
      setDrawingList(filteredDrawings);
    }
  }, [searchValue]);

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);
  };
  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);
  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedRows((prevSelected) => {
        const updatedSelected = [...prevSelected, id];
        console.log("Selected Ids", updatedSelected);
        return updatedSelected;
      });
    } else {
      setSelectedRows((prevSelected) => {
        const updatedSelected = prevSelected.filter((item) => item !== id);
        console.log("Deselected Ids", updatedSelected);
        return updatedSelected;
      });
    }
  };
  
  


  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
 <div style={{ marginTop: "10px" ,position:'relative'}}>
    <DrawingTableWrapper
      tableHead={
        <tr>
           {showAddFolder &&<th style={{ width: "5%" }}>Select</th> }
          <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
          <th style={{ width:showAddFolder?"10%": "12%" }}>DRWG.No</th>
          <th style={{ width:showAddFolder?"8%": "10%" }}>Consultant </th>
          {showAddFolder && <th style={{ width: "10%" }}>Folder</th> }
          <th style={{ width:showAddFolder?"8%": "10%" }}>Drawing Category</th>
          <th style={{ width:showAddFolder?"18%": "20%" }}>Drawing Title</th>
          <th style={{ width:showAddFolder?"8%": "10%" }}>Created Date</th>
          <th style={{ width:showAddFolder?"10%": "12%" }}>Accepted R0 Submission Date</th>
          <th style={{ width:showAddFolder?"10%": "12%" }}>Accepted site submission date</th>
            <th style={{ width:showAddFolder?"8%": "9%" }}> Action </th>
        </tr>
      }
      tableBody={
        <tbody>
          {drawingList
            .filter((item) => item && item.designDrawingConsultant)
            .sort((a, b) => {
              const consultantA = a.category?.category
                ? a.category?.category.toLowerCase()
                : "";
              const consultantB = b.category?.category
                ? b.category?.category.toLowerCase()
                : "";
              return consultantA.localeCompare(consultantB);
            })
            .map((drawingItem, index) => (
              <tr
                key={index}
                style={{
                  height: "60px",
                  backgroundColor:
                    index % 2 === 0
                      ? "var(--table-oddrow-color)"
                      : "var(--table-evenrow-color)",
                }}
              >
                 {showAddFolder &&
            <td>
            <input
  type="checkbox"
  style={{
    width: "16px",
    height: "16px",
    appearance: "none",
    border: "1px solid #ccc",
    borderRadius: "3px",
    outline: "none",
    cursor: "pointer",
    backgroundColor: "white",
    boxShadow: "none",
    display: "inline-block",
    verticalAlign: "middle",
  }}
  onChange={(e) => handleCheckboxChange(e, drawingItem._id)}
  onClick={(e) => {
    const target = e.target;
    target.style.backgroundColor = target.checked ? "black" : "white";
    target.style.borderColor = target.checked ? "black" : "#ccc";
    target.style.backgroundImage = target.checked
      ? "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23fff' stroke-width='2' d='M4 8l3 3 5-5'/%3E%3C/svg%3E\")"
      : "none";
    target.style.backgroundRepeat = "no-repeat";
    target.style.backgroundPosition = "center";
    target.style.backgroundSize = "75%";
  }}
/>

            </td>
      }
                <td>{index + 1}</td>
                <td>{drawingItem?.drawingNo || "na"}</td>
                <td>{drawingItem.designDrawingConsultant.role}</td>
                {showAddFolder &&<td>{drawingItem?.folderId?.folderName}</td>}
                <td>{drawingItem.category?.category || ""}</td>
                <td
                              style={{
                                width: "9%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                cursor: "default",
                             
                              }}
                            >
                              <span
                                title={drawingItem.drawingTitle}
                                style={{
                                  display: "inline-block",
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {drawingItem.drawingTitle
                                  .split(" ")
                                  .slice(0, 2)
                                  .join(" ")}
                                ...
                              </span>
                            </td>
                <td>{formatDate(drawingItem.creationDate)}</td>
                <td>{formatDate(drawingItem.acceptedROSubmissionDate)}</td>
                <td>
                  {formatDate(drawingItem.acceptedSiteSubmissionDate)}
                </td>
                {filter === "AddRegister" && (
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "18px",
                    }}
                  >
                    {drawingItem.acceptedArchitectRevisions.length === 0 &&
                    drawingItem.acceptedRORevisions.length === 0 ? (
                      <>
                        <Icon.Pen
                          size={20}
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleEdit(drawingItem)}
                        />
                        {/* <Icon.Trash size={20} style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleTrashOpen(drawingItem._id)} /> */}
                      </>
                    ) : (
                      <div>No Action</div>
                    )}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      }
    />
    </div>
    
      

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px",maxHeight: "80vh", overflowY: "auto", }}
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
                width: "80%",
              }}
            >
              {errorMessage && (
                <div style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </div>
              )}
              <div>
                <label
                  htmlFor="DrawingNumber"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing No
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="DrawingNumber"
                  name="DrawingNumber"
                  value={formData.DrawingNumber}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />

                {errors.DrawingNumber && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DrawingNumber}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="DrawingTitle"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Title
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="DrawingTitle"
                  name="DrawingTitle"
                  value={formData.DrawingTitle}
                  onChange={handleChange}
                  placeholder="Enter your drawing title"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                {errors.DrawingTitle && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DrawingTitle}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="DrawingCategory"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Category
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="DrawingCategory"
                  name="DrawingCategory"
                  value={formData.DrawingCategory}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.DrawingCategory && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DrawingCategory}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="DesignConsultant"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Design Consultant <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="DesignConsultant"
                  name="DesignConsultant"
                  value={formData.DesignConsultant}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.DesignConsultant && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DesignConsultant}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="RohardCopy"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Number Of Hard Copies For RO
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="RohardCopy"
                  value={formData.RohardCopy}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />

                {errors.RohardCopy && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.RohardCopy}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="SiteHeadhardCopy"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Number Of Hard Copies For SiteHead
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="SiteHeadhardCopy"
                  value={formData.SiteHeadhardCopy}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />

                {errors.SiteHeadhardCopy && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.SiteHeadhardCopy}
                  </p>
                )}
              </div>
              
              <div>
                <label
                  htmlFor="AcceptedROSubmissionDate"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Accepted RO Submission Date
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="AcceptedROSubmissionDate"
                  value={formData.AcceptedROSubmissionDate}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />

                {errors.AcceptedROSubmissionDate && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.AcceptedROSubmissionDate}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor=" AcceptedSiteSubmissionDate"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Accepted Site SubmissionDate
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="AcceptedSiteSubmissionDate"
                  value={formData.AcceptedSiteSubmissionDate}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                {errors.AcceptedSiteSubmissionDate && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.AcceptedSiteSubmissionDate}
                  </p>
                )}
              </div>
            </div>
          </section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              style={{
                marginLeft: "50px",
                padding: "10px",
                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={trashOpen}
        onClose={handleTrashClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{ margin: "5px 250px", textAlign: "center", color: "white" }}
          >
            Delete
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleTrashClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "auto", minHeight: "auto" ,maxHeight: "80vh", overflowY: "auto",}}
        >
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <label
                htmlFor="uniquePassword"
                className="fs-10 fw-bold text-start py-2"
              >
                Password
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                id="uniquePassword"
                name="uniquePassword"
                value={trashData.uniquePassword}
                onChange={handleInputChange}
                type="password"
                className="form-control fs-12"
                style={{ width: "100%" }}
              />

              {errors.uniquePassword && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {errors.uniquePassword}
                </p>
              )}
            </div>
          </section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "50px",
                padding: "10px",

                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleTrashSubmit}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegisterPage;
