import React, { useState, useEffect } from "react";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import pen from "../IconsSiteHead/penIcon.svg";
import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import { siteId } from "../../../../atoms";
import { useRecoilState } from "recoil";
import { WebApimanager } from "../../../../WebApiManager";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent } from "../../../Dialog/Dialog";
import { RxCross1 } from "react-icons/rx";

const CollectedfromR0 = ({ searchQuery, selectedCategory,forwardAccess }) => {

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  const todayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  const webApi = new WebApimanager();
  const [site] = useRecoilState(siteId);
  const [collectedData, setCollectedData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    acceptedArchitectDate: "",
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingFileName: null,
    receivedCopies: "",
    revisions: [],
    selectedRevision: "",
  })
  const [architectselectedRevision, setArchitectSelectedRevision] =
    useState("");


    const [apiProcessing, setApiProcessing] = useState({
      loader: false,
      message: "Loading...",
    });
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleArchitechRevisionSelection = (event) => {
      setArchitectSelectedRevision(event.target.value);
    };

    const validateForm = (formData) => {
      const newErrors = {};
      
      
        if (!formData.drawingNumber.trim()) {
          newErrors.drawingNumber = "DrawingNumber  is required";
        }
        if (!formData.drawingName.trim()) {
          newErrors.drawingName = "Drawing Title is required";
        }
        if (!formData.designDrawingConsultant.trim()) {
          newErrors.designDrawingConsultant =
            "DesignDrawingConsultant is required";
        }
        if (!formData.category.trim()) {
          newErrors.category = "Category is required";
        }
        // if (!formData.submittedDate.trim()) {
        //   newErrors.submittedDate = "Submitted Date is required";
        // }
        if (!formData.selectedRevision) {
          newErrors.selectedRevision = "revision is required";
        }
        if (!formData.receivedCopies) {
          newErrors.receivedCopies = "receivedCopies are required";
        }
  
        if (!formData.drawingFileName) {
          newErrors.drawingFileName = "Drawing File is required";
        }
      
      
      return newErrors;
    };
  
    const handleFormSubmit = async () => {
      // Validate the form data
      const errors = validateForm(formData);
      console.log(errors,"errors")
      setErrors(errors);  
  
      // If there are errors, return early
      if (Object.keys(errors).length > 0) return;
  
      // Perform API call based on the active tab
      
      
        setApiProcessing({
          loader: true,
          message: "Submitting...",
        });
        submitReceived(formData.registerId);
      
    };
  
    
  
    const submitReceived = async (registerId) => {
      try {
        const ApprovalFormData = {
          acceptedSiteHeadHardCopyRevisions: [
            {
              revision: formData.selectedRevision,
              hardCopySubmittedDate: todayDate(),
              receivedCopies: formData.receivedCopies,
            },
          ],
        };
        console.log("Submitting Approval data:", ApprovalFormData);
        const response = await webApi.put(
          `api/architectureToRoRegister/HardCopyRevision/${registerId}`,
          ApprovalFormData
        );
  
        // const response = await webApi.put(`api/architectureToRoRequested/updateRevision/${drawingItemId}`, ApprovalFormData);
        // const id = response.data.data._id;
  
        // If there's a file to upload, initiate PUT request to upload file
        if (formData.drawingFileName) {
          const hardCopyFile = new FormData();
          hardCopyFile.append("hardCopyFile", formData.drawingFileName);
          await webApi.imagePut(
            `api/architectureToRoRegister/hardCopyFile/${registerId}`,
            hardCopyFile
          );
        }
        setApiProcessing({ loader: false, message: "" });
        handleClose();
      } catch (error) {
        setApiProcessing({ loader: false, message: "" });
        console.error("Error submitting form:", error);
  
        // Handle error, show error message, etc.
        alert("Failed to submit the form. Please try again later.");
      }
    };

    const handleEdit = (drawingItem) => {
      console.log(drawingItem,"drawingItem in Collected Pending")
      if(Object.keys(drawingItem).length){
        // const acceptedRoRevisions = Array.isArray(
        //   drawingItem.acceptedRoRevisions
        // )
        //   ? drawingItem.acceptedRoRevisions.flatMap(
        //       (rev) => rev.revision || []
        //     )
        //   : [];
        setFormData({
          registerId: drawingItem.drawingId,
      drawingNumber:drawingItem.drawingNo ,
      drawingName: drawingItem.drawingTitle,
      category: drawingItem.category?.category,
      designDrawingConsultant: drawingItem.designDrawingConsultant?.role,
      acceptedArchitectDate: "",
      submittedDate: "",
      issuedSoftCopy: "",
      receivedSoftCopy: "",
      drawingFileName: null,
      receivedCopies: "",
      revisions: drawingItem.acceptedRoRevisions,
      selectedRevision: "",
        })
      }
      // const selected = drawingList.find((drawing) => drawing._id === drawingId);
      // if (selected) {
      //   const combinedRevisions = [
      //     // ...selected.acceptedArchitectRevisions,
      //     ...(selected.acceptedRORevisions || []),
      //   ];
  
      //   setSelectedDrawing(selected);
      //   setFormData({
      //     registerId: selected._id,
      //     drawingNumber: selected.drawingNo || "",
      //     drawingName: selected.drawingTitle || "",
      //     category: selected.category.category || "", // Ensure the category field is properly accessed
      //     designDrawingConsultant: `${selected.designDrawingConsultant?.role}`,
      //     acceptedArchitectDate: selected.acceptedArchitectDate
      //       ? new Date(selected.acceptedArchitectDate).toLocaleDateString()
      //       : "",
      //     revisions: combinedRevisions,
      //     submittedDate: todayDate(),
      //     // dueDays: 0,
      //     // issuedSoftCopy: selected.issuedSoftCopy,
      //     // receivedSoftCopy: selected.receivedSoftCopy,
      //     // drawingFileName: selected.drawingFileName
      //   });
        setOpen(true);
      // }
    };
    const handleChange = (e) => {
      const { name, value, type, files } = e.target;
  
      if (type === "file") {
        const file = files[0];
        setFormData({
          ...formData,
          [name]: files[0], // Assuming you are only uploading one file
        });
        return;
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    };
  

  useEffect(() => {
    const fetchCollectedData = async () => {
      try {
        const response = await webApi.get(
          `api/architect/pendingSiteHead/${site}?filterType=collected`
        );
        console.log("Collected Data:", response.data);
        setCollectedData(response.data.data);
      } catch (error) {
        console.error("Error fetching collected data:", error);
      }
    };

    fetchCollectedData();
  }, [site]);

  const filterSearchData = collectedData.filter((item) => {
    const matchesSearch =
      item.drawingNo?.toLowerCase().includes(searchQuery) ||
      item.designDrawingConsultant?.role?.toLowerCase().includes(searchQuery) ||
      item.category?.category?.toLowerCase().includes(searchQuery) ||
      item.drawingTitle?.toLowerCase().includes(searchQuery);

    const matchesCategory = selectedCategory
      ? item.category?.category === selectedCategory
      : true;

    return matchesSearch && matchesCategory;
  });

  return (
    <>
    <DrawingTableWrapper
      tableHead={
        <tr>
          <th style={{ width: "5%" }}>S.No</th>
          <th style={{ width: "15%" }}>Drawing No</th>
          <th style={{ width: "15%" }}>Design Consultant</th>
          <th style={{ width: "15%" }}>Drawing Category</th>
          <th style={{ width: "25%" }}>Drawing Title</th>
          <th style={{ width: "15%",padding:'5px' }}>RO Hard Copy Submission Date</th>
          {forwardAccess && 
          <th style={{ width: "10%" }}>Action</th>
      }
        </tr>
      }
      tableBody={
        filterSearchData.length > 0 ? (
          filterSearchData.map((collected, index) => (
            <tr
              key={collected._id}
              style={{
                backgroundColor: index % 2 === 0 ? "#FDF8F4" : "#F3F6F8",
              }}
            >
              <td style={{ width: "5%" }}>
                {(index + 1).toString().padStart(3, "0")}
              </td>
              <td style={{ width: "10%", padding: "10px" }}>
                {collected?.drawingNo}
              </td>
              <td style={{ width: "10%", padding: "10px" }}>
                {collected?.designDrawingConsultant?.role}
              </td>
              <td style={{ width: "10%", padding: "10px" }}>
                {collected?.category?.category}
              </td>
              <td style={{ width: "10%", padding: "10px" }}>
                {collected?.drawingTitle}
              </td>
              {collected?.acceptedROSubmissionDate
                    ? new Date(
                      collected?.acceptedROSubmissionDate
                      ).toLocaleDateString()
                    : "N/A"}
                    {forwardAccess &&
              <td style={{ width: "7%", padding: "10px" }}>
              <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent:"center",
                          gap: "10px",
                          // marginLeft: "30px",
                        }}
                      >
                        <img src={pen} alt="edit"
                          onClick={() => handleEdit(collected)}
                        />
                        
                      </div>
              </td>
      }
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="10" style={{ textAlign: "center" }}>
              No data found
            </td>
          </tr>
        )
      }
    />
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: " #4D4D4D",
          margin: "0px",
        }}
      >
        <p
          style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
        >
          Drawing
        </p>
        <RxCross1
          size={20}
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            marginRight: "15px",
            color: "white",
          }}
          onClick={handleClose}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0",
            border: "1px solid var(--primary-color)",
          }}
        >
          {/* <button
          onClick={() => {
            setCurrentTab('upload')
          }}
          style={{
            backgroundColor: currentTab === 'upload' ? 'var(--primary-color)': 'transparent',
            color: currentTab === 'upload' ? 'white' : 'var(--primary-color)',
            width: '150px',
            height: '35px',
            cursor: 'pointer',
            borderRight: '1px solid var(--primary-color)', 
           
          }}
        >
          Upload
        </button> */}

          <button
            
            style={{
              background:
                 "var(--primary-color)",
                  
              color:
                 "white" ,
              width: "150px",
              height: "35px",
              cursor: "pointer",
            }}
          >
            Received
          </button>
        </div>
      </div>
      <DialogContent
        style={{ padding: "25px", minWidth: "300px", minHeight: "300px",maxHeight: "80vh", overflowY: "auto", }}
      >
        <>
          <Loader
            open={apiProcessing.loader}
            message={apiProcessing.message}
          />
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
                width: "80%",
              }}
            >
              <div>
                <label
                  htmlFor="drawingNumber"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing No
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="drawingNumber"
                  name="drawingNumber"
                  value={formData.drawingNumber}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />

                {errors.drawingNumber && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingNumber}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Title
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="drawingName"
                  name="drawingName"
                  value={formData.drawingName}
                  onChange={handleChange}
                  placeholder="Enter your drawing title"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.drawingName && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingName}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="category"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Category
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  placeholder="Enter your Drawing Category"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.category && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.category}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="designDrawingConsultant"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Design Consultant
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="designDrawingConsultant"
                  name="designDrawingConsultant"
                  value={formData.designDrawingConsultant}
                  onChange={handleChange}
                  placeholder="Enter your  Design Consultant"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.designDrawingConsultant && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.designDrawingConsultant}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="revisions"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Revisions
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="revisions"
                  name="selectedRevision"
                  value={formData.selectedRevision}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                >
                  <option value="">Select Revision</option>
                  {formData.revisions.map((revision, index) => (
                    <option key={index} value={revision.revision}>
                      {revision.revision}
                    </option>
                  ))}
                </select>
                {errors.selectedRevision && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.selectedRevision}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="receivedCopies"
                  className="fs-10 fw-bold text-start py-2
            "
                >
                  No of Copies Received
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  id="receivedCopies"
                  name="receivedCopies"
                  placeholder="Enter No of Received Copies"
                  value={formData.receivedCopies}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
              </div>

              <div>
                <label
                  htmlFor="drawingFileName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Upload Your Drawing File
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  accept=".pdf, .jpg, .jpeg, .png"
                  id="drawingFileName"
                  name="drawingFileName"
                  onChange={handleChange}
                  placeholder="Select your drawing file"
                  className="form-control fs-12"
                  style={{ width: "100%", height: '43px'  }}
                />
                {errors.drawingFileName && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingFileName}
                  </p>
                )}
              </div>
            </div>
          </section>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              style={{
                padding: "10px",

                border: "1px solid lightgray",
                backgroundColor: "transparent",
                width: "100px",
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = "lightgray")
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = "transparent")
              }
              onClose={handleClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "50px",
                padding: "10px",
               
                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleFormSubmit}
            >
              Submit
            </button>
          </div>
        </>
      </DialogContent>
    </Dialog>
    </>
  );
};

export default CollectedfromR0;
