/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useRef, useMemo } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../../Tabs/Tabv1";
import TableWrapper from "../../../../Utilities/TableWrapper";
import IssuedFromR0 from "./IssuedFromR0";
import CollectedfromR0 from "./CollectedfromR0";
import { siteId, userInfo } from "../../../../atoms";
import { useRecoilState } from "recoil";
import { WebApimanager } from "../../../../WebApiManager";
import { Dialog, DialogContent } from "../../../Dialog/Dialog";
import { RxCross1 } from "react-icons/rx";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import { calculateDueDays } from "../../../../Utilities/DateUtils";
import { canShowFolder } from "../../ShowFolder";
import ConsultantFilter from "../../ConsultantFilter";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", 
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", 
          },
        },
      },
    },
  },
});

const UploadtoSitelevel = (forwardAccess) => {
  const webApi = new WebApimanager();

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  const todayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const [currentView, setCurrentView] = useState("Upload to Sitelevel");
  const [site] = useRecoilState(siteId);
  const [userDetails] = useRecoilState(userInfo)
  const [uploadData, setUploadData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [consultantValue, setConsultantValue] = useState('')
  const [architectselectedRevision, setArchitectSelectedRevision] =
    useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    folder: "",
    category: "",
    designDrawingConsultant: "",
    acceptedArchitectDate: "",
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingDWGFileName: null,
    drawingPDFFileName: null,
    receivedCopies: "",
    acceptedArchitectRevisions: [],
    note: "",
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleArchitechRevisionSelection = (event) => {
    setArchitectSelectedRevision(event.target.value);
  };

  useEffect(() => {
    const handleClickOutSide = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutSide);

    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [dropdownRef]);

  const handleType = (type) => {
    console.log("Folder Click");
    setTypeSelected(type);
  };

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  const handleEdit = (drawingItem) => {
    console.log(drawingItem, "drawing in Pending upload");
    const latestRevision = drawingItem.acceptedRORevisions?.[0]?.revision || "";
    if (Object.keys(drawingItem).length) {
      setFormData({
        registerId: drawingItem.drawingId,
        folder: drawingItem.folderId?.folderName || "NA",
        drawingNumber: drawingItem.drawingNo,
        drawingName: drawingItem.drawingTitle,
        category: drawingItem.category?.category,
        designDrawingConsultant: drawingItem.designDrawingConsultant?.role,
        latestRevision: latestRevision,
        acceptedArchitectDate: "",
        // actualSubmissionDate: todayDate(),
        // dueDays: '',
        // remarks:'',
        submittedDate: "",
        issuedSoftCopy: "",
        receivedSoftCopy: "",
        drawingFileName: null,
        receivedCopies: "",
        acceptedArchitectRevisions: [],
        note: "",
      });
    }
    console.log("Latest Revision:", latestRevision);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateForm = (formData) => {
    const newErrors = {};

    if (!formData.drawingNumber.trim()) {
      newErrors.drawingNumber = "DrawingNumber  is required";
    }
    if (!formData.drawingName.trim()) {
      newErrors.drawingName = "Drawing Title is required";
    }
    if (!formData.designDrawingConsultant.trim()) {
      newErrors.designDrawingConsultant = "DesignDrawingConsultant is required";
    }
    if (!formData.category.trim()) {
      newErrors.category = "Category is required";
    }
    if (!formData.drawingFileName) {
      newErrors.drawingFileName = "Drawing File is required";
    }
    if (!formData.drawingPDFFileName) {
      newErrors.drawingPDFFileName = "Drawing PDF File is required";
    } else if (formData.drawingPDFFileName instanceof File) {
      if (!formData.drawingPDFFileName.name.endsWith(".pdf")) {
        newErrors.drawingPDFFileName = "Drawing File must be a .pdf file";
      }
    } else if (typeof formData.drawingPDFFileName === "string") {
      if (!formData.drawingPDFFileName.endsWith(".pdf")) {
        newErrors.drawingPDFFileName = "Drawing File must be a .pdf file";
      }
    }

    return newErrors;
  };

  const handleFormSubmit = async () => {
    const errors = validateForm(formData);
    console.log(errors, "errors");
    setErrors(errors);
    if (Object.keys(errors).length > 0) return;


    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    submitUpload(formData.registerId);
  };

  const submitUpload = async (registerId) => {
    console.log("registerId", registerId);

    try {
      // Basic form data
      const ApprovalFormData = new FormData();
      ApprovalFormData.append("softCopySubmittedDate", todayDate());
      ApprovalFormData.append("changes", formData.note);

      // Conditionally add files if they exist
      if (formData.drawingDWGFileName) {
        ApprovalFormData.append("drawingFileName", formData.drawingDWGFileName);
      }

      if (formData.drawingPDFFileName) {
        ApprovalFormData.append(
          "pdfDrawingFileName",
          formData.drawingPDFFileName
        );
      }

      console.log("Submitting Approval data:", ApprovalFormData);
      const response = await webApi.imagePut(
        `api/architectureToRoRegister/revisions/${registerId}?revisionType=acceptedSiteHeadRevisions`,
        ApprovalFormData
      );
      if (response.status === 200) {
        setApiProcessing({ loader: false, message: "" });
        handleClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      alert("Failed to submit the form. Please try again later.");
    }
  };

  useEffect(() => {
    const fetchUploadData = async () => {
      try {
        const response = await webApi.get(
          `api/architect/pendingSiteHead/${site}?filterType=upload`
        );
        setUploadData([...response.data.data.rORevisions,...response.data.data.siteHeadRevisions])
        console.log("upload Data:", response.data);
        // setUploadData(response.data.data);

        console.log(uploadData, "Site head upload data");
      } catch (error) {
        console.error("Error fetching issued data:", error);
      }
    };

    fetchUploadData();
  }, [site]);

  const searchResult = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setFilterDropdownOpen(false);
  };

  const [categories, setCategories] = useState([]);
  const [folderNames, setFolderNames] = useState([]);

  const uniqueConsultant = useMemo(() => {
    const consultant = uploadData.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [uploadData]);


  if (Array.isArray(uploadData)) {
    const categories = [
      ...new Set(
        uploadData.map((data) => data.category?.category).filter(Boolean)
      ),
    ];

    const folderNames = [
      ...new Set(
        uploadData.map((data) => data.folderId?.folderName).filter(Boolean)
      ),
    ];

    console.log("Categories:", categories);
    console.log("Folder Names:", folderNames);
  } else {
    console.error("uploadData is not an array:", uploadData);
  }

  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(userDetails, site);
    setShowAddFolder(showFolder);
  }, [userDetails, site]);

  const updatedRows = uploadData
  .filter((item) =>
    item.designDrawingConsultant?.role.includes(consultantValue)
  )
  .map((row, index) => {
    // Calculate Due/Overdue status
    const dueOverdue = calculateDueDays(row.acceptedSiteSubmissionDate, todayDate());

    return {
      ...row,
      serialNo: index + 1,
      drawingNo: row.drawingNo || "NA",
      consultant: row.designDrawingConsultant?.role || "-",
      category: row.category?.category || "-",
      drawingTitle: row.drawingTitle || "-",
      from: row.for === "siteLevel" ? "Site Head" : row.for?.toUpperCase() || "-",
      acceptedSiteSubmissionDate: formatDate(row.acceptedSiteSubmissionDate) || "-",
      revisions: row.archRevision || "-",
      dueOverdue, 
    };
  });

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchQuery.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = useMemo(
    () => [
      {
        field: "serialNo",
        headerName: "S.No",
        flex: 0.5,
        filterable: false,
        sortable: false,
      },
      {
        field: "drawingNo",
        headerName: "Drawing No",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "consultant",
        headerName: "Consultant",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "category",
        headerName: "Drawing Category",
        flex: 1,
        filterable: true,
        sortable: false,
      },
      {
        field: "drawingTitle",
        headerName: "Drawing Title",
        flex: 2,
        filterable: true,
        sortable: true,
        
      },
      {
        field: "from",
        headerName: "From",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "acceptedSiteSubmissionDate",
        headerName: "Accepted Site Submission Date",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "dueOverdue",
        headerName: "Due/Overdue",
        flex: 1,
        filterable: false,
        sortable: false,
        renderCell: (params) => (
          <span
            style={{
              color: params.row.dueOverdue.includes("overdue") ? "red" : "green",
            }}
          >
            {params.row.dueOverdue}
          </span>
        ),
      },
      {
        field: "revisions",
        headerName: "Revisions",
        flex: 1,
        filterable: true,
        sortable: true,
      },
    ],
    [uploadData]
  );
  
  return (
    <>
      <TableWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={currentView}
            onChange={handleTabViewChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "var(--primary-color)",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                color: "#131313",
                fontWeight: "bold",
              },
              "& .Mui-selected": { color: "#131313 !important" },
            }}
          >
            <Tab label="Soft Copy" value="Upload to Sitelevel" />
            <Tab label="Hard Copy" value="Issued From R0" />
          </Tabs>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <input
              placeholder="Search Drawing Lists"
              // onChange={searchResult}
              onChange={(e)=>setSearchQuery(e.target.value)}
              style={{
                paddingLeft: "20px",
                border: "1px solid var(--primary-color)",
                width: "250px",
                height: "40px",
                marginRight:"15px"
              }}
            />
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon.Search
                style={{
                  position: "absolute",
                  right: "25px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#ACACAC",
                }}
              />
            </div>

            {/* <div style={{ position: "relative" }}>
              <div onClick={toggleFilterDropdown} style={{ cursor: "pointer" }}>
              </div>
              {isFilterDropdownOpen && (
                <div
                  ref={dropdownRef}
                  style={{
                    position: "absolute",
                    background: "white",
                    borderRadius: "4px",
                    zIndex: 1000,
                    textAlign: "left",
                    marginTop: "20px",
                    marginLeft: "-30px",
                    padding: "15px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <span style={{ marginTop: "0px", display: "block" }}>
                    <span
                      style={{
                        cursor: "pointer",
                        padding: "5px 0",
                        position: "relative",
                      }}
                    >
                      <div
                        onClick={() => {
                          setTypeSelected("All");
                          setSelectedCategory("");
                          setFilterDropdownOpen(false);
                        }}
                      >
                        All
                      </div>
                    </span>
                  </span>

                  <span
                    style={{
                      cursor: "pointer",
                      padding: "5px 0",
                      position: "relative",
                    }}
                  >
                    <div onClick={() => handleType("categories")}>
                      Categories
                    </div>
                    {typeSelected === "categories" && (
                      <div
                        style={{
                          position: "absolute",
                          background: "white",
                          borderRadius: "4px",
                          zIndex: 1000,
                          marginLeft: "-120px",
                          padding: "20px 15px",
                          left: 0,
                          width: "100%",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {categories.map((category) => (
                            <span
                              key={category}
                              onClick={() => {
                                handleCategorySelect(category);
                                setFilterDropdownOpen(false); // Close dropdown
                              }}
                              onMouseEnter={() => setHoveredCategory(category)}
                              onMouseLeave={() => setHoveredCategory(null)}
                              style={{
                                cursor: "pointer",
                                padding: "5px 0",
                                transition: "background-color 0.2s",
                                backgroundColor:
                                  selectedCategory === category
                                    ? "#d0e1ff"
                                    : hoveredCategory === category
                                    ? "#b0c4ff"
                                    : "white",
                              }}
                            >
                              {category}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </span>

                  <span style={{ marginTop: "10px", display: "block" }}>
                    <span
                      style={{
                        cursor: "pointer",
                        padding: "5px 0",
                        position: "relative",
                      }}
                    >
                      <div onClick={() => handleType("folders")}>Folders</div>
                      {typeSelected === "folders" && (
                        <div
                          style={{
                            position: "absolute",
                            background: "white",
                            borderRadius: "4px",
                            zIndex: 1000,
                            marginLeft: "-120px",
                            padding: "20px 15px",
                            left: 0,
                            width: "100%",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                        >
                          {folderNames.map((folder) => (
                            <span
                              key={folder}
                              onClick={() => {
                                handleCategorySelect(folder);
                                setFilterDropdownOpen(false);
                              }}
                              style={{
                                cursor: "pointer",
                                padding: "5px 0",
                                transition: "background-color 0.2s",
                                backgroundColor:
                                  selectedCategory === folder
                                    ? "#d0e1ff"
                                    : hoveredCategory === folder
                                    ? "#b0c4ff"
                                    : "white",
                              }}
                              onMouseEnter={() => setHoveredCategory(folder)}
                              onMouseLeave={() => setHoveredCategory(null)}
                            >
                              {folder}
                            </span>
                          ))}
                        </div>
                      )}
                    </span>
                  </span>
                </div>
              )}
            </div> */}
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          {currentView === "Upload to Sitelevel" && (
            <Box
              className="dataGridContainer"
            >
              <ThemeProvider theme={theme}>
              <DataGrid
                columns={columns}
                rows={filteredRows}
                getRowId={(row) => row.serialNo}
                rowHeight={60}
                disableColumnMenu={false} 
                pagination={false}
                hideFooter={true}
                getRowSpacing={params=>({
                  top:params.isFirstVisible ? 0 : 5,
                  bottom:params.isLastVisible ? 0 : 5
                })}
                
                getRowClassName={(params) =>
                  "customHeader" 
                }
                
              />
              </ThemeProvider>
            </Box>
          )}
          {currentView === "Issued From R0" && (
            <IssuedFromR0
              searchQuery={searchQuery}
              selectedCategory={selectedCategory}
            />
          )}
          {currentView === "Collected from R0" && (
            <CollectedfromR0
              searchQuery={searchQuery}
              selectedCategory={selectedCategory}
              forwardAccess={forwardAccess}
            />
          )}
        </div>
      </TableWrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            <button
              style={{
                backgroundColor: "var(--primary-color)",
                color: "white",
                width: "150px",
                height: "35px",
                cursor: "pointer",
                borderRight: "1px solid var(--primary-color)",
              }}
            >
              Upload
            </button>
          </div>
        </div>
        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <>
            {" "}
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <section
              style={{
                marginLeft: "80px",
                marginRight: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "50px",
                  width: "80%",
                }}
              >
                <div>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing No
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="drawingNumber"
                    name="drawingNumber"
                    value={formData.drawingNumber}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />

                  {errors.drawingNumber && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingNumber}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="drawingName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Title
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="drawingName"
                    name="drawingName"
                    value={formData.drawingName}
                    onChange={handleChange}
                    placeholder="Enter your drawing title"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.drawingName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingName}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Category
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    placeholder="Enter your Drawing Category"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.category && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.category}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="designDrawingConsultant"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Design Consultant
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="designDrawingConsultant"
                    name="designDrawingConsultant"
                    value={formData.designDrawingConsultant}
                    onChange={handleChange}
                    placeholder="Enter your  Design Consultant"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.designDrawingConsultant && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.designDrawingConsultant}
                    </p>
                  )}
                </div>
                {showAddFolder &&
                <div>
                  <label
                    htmlFor="folder"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Folder
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="folder"
                    name="folder"
                    value={formData.folder}
                    onChange={handleChange}
                    placeholder="Enter your  Folder "
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.folder && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.folder}
                    </p>
                  )}
                </div>
}
                <div>
                  <label
                    htmlFor="folder"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    RO Revisions Ref
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="revisions"
                    name="revisions"
                    value={formData.latestRevision}
                    onChange={handleChange}
                    placeholder="Enter your  Folder "
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.revisions && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.revisions}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="drawingPDFFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload PDF file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    id="drawingPDFFileName"
                    name="drawingPDFFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.name.endsWith(".pdf")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .pdf file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: "43px" }}
                  />
                  {errors.drawingPDFFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingPDFFileName}
                    </p>
                  )}
                </div>
              </div>
            </section>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "45px",
              }}
            >
              <button
                style={{
                  padding: "10px",

                  border: "1px solid lightgray",
                  backgroundColor: "transparent",
                  width: "100px",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "lightgray")
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
                onClose={handleClose}
              >
                Cancel
              </button>
              <button
                style={{
                  marginLeft: "50px",
                  padding: "10px",

                  border: "1px solid var(--primary-color)",
                  width: "100px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </>
        </DialogContent>
      </Dialog>
      {currentView === "Upload to Sitelevel" && <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue}/>}
    </>
  );
};

export default UploadtoSitelevel;
