import React, { useEffect, useState } from 'react'
import grid from "../../../../Images/grid.png"
import menu from "../../../../Images/menu.png"
import folderImg from "../../../../Images/Folder_Icons__Logos__Symbols___Free_Download_PNG__SVG-removebg-preview 1.png"
import { WebApimanager } from '../../../../WebApiManager'
import { Tab, Tabs } from '../../../Tabs/Tabv1'
const FolderView = (props) => {
    const {setPresentView,site} = props
    let webApi = new WebApimanager()
    const [allConsultants, setAllConsultants] = useState([])
    const [showCategoryFolder, setShowCategoryFolder] = useState(false)
    const [currentView, setCurrentView] = useState("upload");
    const [consultantId, setConsultantId ] = useState("");
    const [ConsultantRole, setConsultantRole] = useState('');


    const fetchAllConsultants = async()=>{
      if(site){
        try {
          const ConsultantResponse = await webApi.get(`api/architectureToRoSelectionRegister/consultants/${site}`)
          console.log(ConsultantResponse?.data?.data?.users,"consultantResponse")
          if(ConsultantResponse.status === 200){
            setAllConsultants(ConsultantResponse?.data?.data?.users)
          }
        } catch (error) {
          console.log(error.message)
        }
      }
    }

    useEffect(()=>{
      fetchAllConsultants()
    },[site])

    const handleFolderClick = (id,role) => {
      setShowCategoryFolder(true)
      setConsultantId(id)
      setConsultantRole(role)
    }
    const handleTabViewChange = (event, newValue) => {
      setCurrentView(newValue);
    };

    const fetchCategory = async() => {
      try {
        const categoryResponse = await webApi.get(`api/drawingWorkFlow/drawings/${site}?consultantId=${consultantId}&filterType=${currentView}`)
      } catch (error) {
        console.log(error.message)
      }
    }

    useEffect(()=>{
      if(showCategoryFolder){
        fetchCategory()
      }
    },[showCategoryFolder,currentView])



  return (
    <>
     <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
      <div style={{width:"100%",display:"flex",justifyContent:"space-between",gap:"10px",alignItems:"center"}}>
        <p style={{fontSize:"20px",color:"#e47331"}}>Drawing Lists</p>
        <div style={{display:"flex",alignItems:"center",height:"40px",border:"1px solid #e47331",marginTop:"-10px",borderRadius:"5px"}}>
          <button style={{padding:"4px",height:"40px",borderRight:"1px solid #4D4D4D33"}} onClick={()=>setPresentView("table")}>
            <img src={menu} style={{width:"20px"}}  />
          </button>
          <button style={{padding:"4px",height:"40px"}} onClick={()=>setPresentView("folder")}>
            <img src={grid} style={{width:"20px"}} />
          </button>
        </div>
      </div>
      <div style={{width:"100%",display:"flex",flexDirection:"column",gap:"20px"}}>
      {!showCategoryFolder ? <div style={{width:"100%",gap:"20px",flexWrap:"wrap",display:"flex"}}>
        {allConsultants.length ? allConsultants.map((consult,ind)=>(<div style={{width:"200px",height:"200px",backgroundColor:ind & 1?"#f3f6f8":"#fdf8f4",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} key={consult._id}>
            <img src={folderImg} alt="folder" style={{height:"120px",width:"120px",cursor:"pointer"}} onClick={()=>handleFolderClick(consult._id,consult?.role)}/>
            <p>{consult?.role}</p>
        </div>)):""}

      </div>:
      <>
        <div style={{width:"100%",display:"flex"}}>
          <Tabs value={currentView} onChange={handleTabViewChange}>
             <Tab label="Soft Copy" value="upload" />
             <Tab label="Hard Copy" value="received" />
          </Tabs>
        </div>
        <p style={{width:"100%",textAlign:"left",marginTop:"20px",marginBottom:"0px",fontSize:"20px"}}>{ConsultantRole}</p>
        <div style={{border:"1px dotted black"}}></div>
        <div style={{width:"100%",gap:"20px",flexWrap:"wrap",display:"flex"}}>
        {/* {allConsultants.length ? allConsultants.map((consult,ind)=>(<div style={{width:"200px",height:"200px",backgroundColor:ind & 1?"#f3f6f8":"#fdf8f4",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} key={consult._id}>
            <img src={folderImg} alt="folder" style={{height:"120px",width:"120px",cursor:"pointer"}} onClick={()=>handleFolderClick(consult._id,consult?.role)}/>
            <p>{consult?.role}</p>
        </div>)):""} */}

      </div>

      </>}


      </div>
    </div>
    </>
  )
}

export default FolderView