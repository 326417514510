/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import {drawingoptionsTab, siteId, userInfo,  } from "../../../atoms";
import { WebApimanager } from "../../../WebApiManager";
import SelectSite from "../../../Utilities/SelectSite";
import AddCategory from "./AddCategory";
import AssignCategory from "./AssignCategory";
import AddRegister from "./AddRegister";
import WorkFlow from "./WorkFlow";
import SitesDropdown from "../../../Utilities/SitesDropdown";

const Options = () => {
  const webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(drawingoptionsTab);
  const [site,setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [currentUserInfo] = useRecoilState(userInfo);
  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    console.log("Selected Site ID:", site);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
      window.location.reload();
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  };

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);
//   const [tabEnableModules, setTabEnableModules] = useState({
//     drawing: false,
//     rfi: false,
//     pending: false,
//     register: false,
//     analysis: false,
//   });

//   useEffect(() => {
//     if (currentUserInfo?.permittedSites) {
//       let enabledTabs = {
//         drawing: false,
//         rfi: false,
//         pending: false,
//         register: false,
//         analysis: false,
//       };

//       currentUserInfo.permittedSites.forEach((site) => {
//         const architectureToRoDetails =
//           site.enableModules.drawingDetails?.architectureToRoDetails;

//         if (architectureToRoDetails) {
//           enabledTabs = {
//             drawing: enabledTabs.drawing || architectureToRoDetails.drawing,
//             rfi: enabledTabs.rfi || architectureToRoDetails.rfi,
//             pending: enabledTabs.pending || architectureToRoDetails.pending,
//             register: enabledTabs.register || architectureToRoDetails.register,
//             analysis: enabledTabs.analysis || architectureToRoDetails.analysis,
//           };
//         }
//       });

//       setTabEnableModules(enabledTabs);
//     }
//   }, [currentUserInfo]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  if (site === "") {
    return <SelectSite />;
  }

//   const anyTabEnabled = Object.values(tabEnableModules).some((val) => val);

  const allTabs = [
    { label: "Category Register", index: 0, 
        component: <AddCategory/>
     },
    { label: "Assign Category", index: 1,
         component: <AssignCategory /> 
        },
    { label: "Add Register", index: 2, 
        component: <AddRegister /> 
    },
    { label: "Work Flow", index: 3, 
      component: <WorkFlow/>
  },
 
  ];
  <div style={{  paddingTop: "25px", right: 0 }}>
       
        <div style={{ marginLeft: "auto" }}>
          <SitesDropdown
            siteId={site}
            handleSiteChange={handleSiteChange}
            sites={sites}
            defaultValue={"Select Site"}
          />
        </div>
      </div>
  // Filter enabled tabs based on user permissions
//   const enabledTabs = allTabs.filter(
//     (tab) => tabEnableModules[tab.label.toLowerCase()]
//   );

  return (
    <>
     
        <>
          <div className="containerDrawingPagesArchitecture">
            <ul className="tabsArchitectureTabs" role="tablist">
               {allTabs.map((tab) => (
                <li
                  key={tab.index}
                  className={`tabArchitectureTab ${
                    value === tab.index ? "active" : ""
                  }`}
                  role="tab"
                  aria-selected={value === tab.index}
                  aria-controls={`tabpanel-${tab.index}`}
                  id={`tab-${tab.index}`}
                  onClick={() => handleChange(tab.index)}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
          </div>
<div style={{display:'flex',justifyContent:'space-around'}}>
          {allTabs.map((tab) => (
            <TabPanel key={tab.index} value={value} index={tab.index}>
              {tab.component}
            </TabPanel>
          ))}

{(value === 3 || value === 2) && (
  <div style={{position:'absolute',right:'0px',top:'24px'}}>
          <SitesDropdown
            siteId={site}
            handleSiteChange={handleSiteChange}
            sites={sites}
            defaultValue={"Select Site"}
          />
          </div>
        )}
          </div>
        </>
    
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className="tabpanelArchitecture"
    >
      {value === index && <div className="tabpanel-content">{children}</div>}
    </div>
  );
}

export default Options;
