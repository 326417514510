/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Nav, Tab } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import BreakdownReport from "./BreakdownReport";
import DailyLogReport from "./DailyLogReport/DailyLogReport";
import Reports from "./Reports/Reports";
import { pnm, registerAtom, siteId } from "../../atoms";
import { useRecoilState } from "recoil";
import MiscellaneousReport from "./Reports/MiscellaneousReport";
import Options from "./Options/Options";
import SitesDropdown from "../../Utilities/SitesDropdown";
import { WebApimanager } from "../../WebApiManager";
import Workflow from "./Workflow/Workflow";

const CustomTabs = ({ onTabChange }) => {
  let webApi = new WebApimanager();
  const [register, setRegister] = useRecoilState(registerAtom);
  const [sites, setDatasites] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [value, setValue] =useRecoilState(pnm);

  const handleChange = (event, newValue,name) => {
    setValue(newValue);
    onTabChange(name);

    // Show dropdown if Options tab is clicked
  };
  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    setRegister([]);
    // window.location.reload();
  };
  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);
//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           padding: "20px",
//         }}
//       >
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           sx={{
//             "& .MuiTabs-indicator": {
//               backgroundColor: "var(--primary-color)", // Custom color for active tab indicator
//             },
//             "& .MuiTab-root": {
//               textTransform: "none", // Prevents text from being transformed to uppercase
//               color: "#131313", // Text color for all tabs
//               borderBottom: "none",
//               fontWeight: "bold",
//             },
//             "& .Mui-selected": {
//               color: "#131313 !important", // Text color for selected tab
//             },
//             "& .MuiTab-root:not(.Mui-selected)": {
//               color: "#131313", // Text color for non-selected tabs
//             },
//           }}
//         >
//           <Tab label="Daily Log Report" />
//           <Tab label="Breakdown Report" />
//           <Tab label="Miscellaneous" />
//           <Tab label=" Reports" />
//           <Tab label="Options" />
//           <Tab  label={<div style={{ position: "relative" }}>Workflow</div>}/>
//         </Tabs>
//         {/* {dropdownVisible && (
//           <ul
//             className="dropdown-menu show"
//             style={{
//               position: "absolute",
//               top: "20%", // Position below the tab label
//               right: "20%", // Align to the right edge of the tab container
//               zIndex: 1000, // Ensure dropdown is above other elements
//               marginTop: "10px",
//               padding: "0",
//               borderRadius: "0.25rem",
//               boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,0.075)",
//             }}
//             aria-labelledby="dropdownMenuButton"
//           >
//             <li>
//               <a className="dropdown-item text-center " href="#">
//                 <AssetCodeCreation />
//               </a>
//             </li>
//             <li>
//               <a className="dropdown-item text-center" href="#">
//                 <AddnewPnm />
//               </a>
//             </li>
//             <li>
//               <a className="dropdown-item text-center" href="#">
//                 Transfer
//               </a>
//             </li>
//           </ul>
//         )} */}
//         <div className="absolute right-[100px] w-[220px] h-10">
//           <SitesDropdown
//             siteId={site}
//             handleSiteChange={handleSiteChange}
//             sites={sites}
//             defaultValue={"Select Site"}
//           />
//         </div>
//       </div>
    
    
//       <TabPanel value={value} index={0}>
//         <DailyLogReport />
//       </TabPanel>
//       <TabPanel value={value} index={1}>
//         <BreakdownReport />
//       </TabPanel>
//       <TabPanel value={value} index={2}>
//         <MiscellaneousReport />
//       </TabPanel>
//       <TabPanel value={value} index={3}>
//         <Reports />
//       </TabPanel>
//       <TabPanel value={value} index={4}>
//         <Options />
//       </TabPanel>
//       <TabPanel value={value} index={5}>
//         <Workflow />
//       </TabPanel>
//     </>
//   );
// };
// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`tabpanel-${index}`}
//       aria-labelledby={`tab-${index}`}
//       {...other}
//       style={{
//         width: "100%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       {value === index && (
//         <Box p={3} style={{ textAlign: "center" }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }
return (
  <>
    <div
      className="drawing-header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "-11px",
        padding: "50px 0px 0px 50px",
        position: "relative",
      }}
    >
      <div
        className="drawing-tabs-container"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
        }}
      >
          <div className="tabs" style={{ gap: "28px", display: "flex" }}>
           
              <button
                className={`tab-button ${value === 0 ? "active" : ""}`}
                onClick={() => handleChange(null, 0,"Daily Log Report")}
                style={{
                  borderBottom: value === 0 ? "3px solid #E47331" : "none",
                  color: "#131313",
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
              Daily Log Report
              </button>
              <button
                className={`tab-button ${value === 1 ? "active" : ""}`}
                onClick={() => handleChange(null, 1,"Break Down Report")}
                style={{
                  borderBottom: value === 1 ? "3px solid #E47331" : "none",
                  color: "#131313",
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                Break Down Report
              </button>
           
              <button
                className={`tab-button ${value === 2 ? "active" : ""}`}
                onClick={() => handleChange(null, 2,"Miscellaneous")}
                style={{
                  borderBottom: value === 2 ? "3px solid #E47331" : "none",
                  color: "#131313",
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                 Miscellaneous
              </button>
              <button
                className={`tab-button ${value === 3 ? "active" : ""}`}
                onClick={() => handleChange(null, 3,"Reports")}
                style={{
                  borderBottom: value === 3 ? "3px solid #E47331" : "none",
                  color: "#131313",
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                   Reports
              </button>
              <button
                className={`tab-button ${value === 4 ? "active" : ""}`}
                onClick={() => handleChange(null, 4,"Options")}
                style={{
                  borderBottom: value === 4 ? "3px solid #E47331" : "none",
                  color: "#131313",
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                Options
              </button>
              <button
                className={`tab-button ${value === 5 ? "active" : ""}`}
                onClick={() => handleChange(null, 5,"Workflow")}
                style={{
                  borderBottom: value === 5 ? "3px solid #E47331" : "none",
                  color: "#131313",
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
               Workflow
              </button>
          </div>
      </div>
        <div
          className="site-dropdown-container"
          style={{ marginLeft: "auto", marginRight: "50px",}}
        >
          <SitesDropdown
            siteId={site}
            handleSiteChange={handleSiteChange}
            sites={sites}
            defaultValue={"Select Site"}
          />
        </div>
     
    </div>


    <TabPanel value={value} index={0}>
            <DailyLogReport />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BreakdownReport />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MiscellaneousReport />
          </TabPanel> 
          <TabPanel value={value} index={3}>
            <Reports />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Options />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Workflow />
          </TabPanel>
  </>
);
}

function TabPanel(props) {
const { children, value, index, ...other } = props;

return (
  <div
    className="tabpanel"
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && <div className="tabpanel-content">{children}</div>}
  </div>
);
}
export default CustomTabs;
