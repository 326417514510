import React from 'react'
import style from './AdminNavbar.module.css'

const AdminNavbarTile = (props) => {
  console.log(props.activeItem,"=>",props.path)
  const isActive = props.activeItem === props.path;

  return (
    <a  className={`${style.navTile} ${isActive ? style.navTileActive : ''}`}  href={`/${props.path}`}>{props.title}</a>
  )
}

export default AdminNavbarTile