import React, { useState } from "react";
import PropTypes from "prop-types";

const ApprovalDropdown = ({
  label,
  options,
  selectedValue,
  onChange,
  placeholder = "Select a Revision",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const containerStyle = {
    position: "relative",
    width: "250px",
    fontFamily: "Arial, sans-serif",
    marginBottom: "16px",
  };

  const labelStyle = {
    fontSize: "14px",
    color: "#333",
    marginBottom: "8px",
    display: "block",
  };

  const headerStyle = {
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    padding: "10px 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "#fff",
    boxShadow: isOpen ? "0 2px 6px rgba(0,0,0,0.15)" : "none",
    transition: "box-shadow 0.3s ease",
  };

  const listStyle = {
    position: "absolute",
    top: "100%",
    left: 0,
    width: "100%",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    zIndex: 10,
    marginTop: "4px",
  };

  const itemStyle = {
    padding: "10px 15px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "background-color 0.2s",
  };

  const revisionStyle = {
    fontWeight: "bold",
    color: "#333",
  };

  const additionalContentStyle = {
    fontSize: "12px",
    color: "#757575",
    marginLeft: "15px",
  };

  return (
    <div style={containerStyle}>
      {/* {label && <label style={labelStyle}>{label}</label>} */}
      <div
        style={headerStyle}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <span>
          {selectedValue
            ? options.find((option) => option.revision === selectedValue)
                ?.revision
            : placeholder}
        </span>
        <span>{isOpen ? "▲" : "▼"}</span>
      </div>
      {isOpen && (
        <div style={listStyle}>
          {options.map((option, index) => (
            <div
              key={index}
              style={{
                ...itemStyle,
                display: "flex",
                alignItems: "flex-start",
                cursor: "pointer",
                gap: "10px",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#f5f5f5";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#fff";
              }}
              onClick={() => {
                onChange(option.revision);
                setIsOpen(false);
              }}
            >
              <span style={revisionStyle}>{option.revision}</span>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span style={additionalContentStyle}>
                  {option.additionalContent
                    ? option.additionalContent.split(" ")[0]
                    : ""}
                </span>

                <span
                  style={{
                    ...additionalContentStyle,
                    marginTop: "5px",
                    fontSize: "10px",
                  }}
                >
                  {/* {option.additionalContent
                    ? (() => {
                        const date = new Date(
                          option.additionalContent.split(" ")[1]
                        );
                        const formattedDate = new Intl.DateTimeFormat("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }).format(date);

                        const formattedTime = new Intl.DateTimeFormat("en-GB", {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }).format(date);

                        return `${formattedDate} ${formattedTime}`;
                      })()
                    : ""} */}
                    {option.additionalContent
  ? (() => {
      const parts = option.additionalContent.split(" ");
      const dateString = parts[1] || ""; 
      const date = new Date(dateString);
      if (isNaN(date)) {
        console.error("Invalid date extracted:", dateString);
        return "Invalid Date"; // Return a fallback message
      }

      // Format the date and time if valid
      const formattedDate = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).format(date);

      const formattedTime = new Intl.DateTimeFormat("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(date);

      return `${formattedDate} ${formattedTime}`;
    })()
  : ""}

                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ApprovalDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      revision: PropTypes.string.isRequired,
      additionalContent: PropTypes.string,
    })
  ).isRequired,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default ApprovalDropdown;
