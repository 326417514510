import React, { useState } from 'react'
import "./Addtemporary.css"
import { WebApimanager } from '../../../WebApiManager';
import { Loader } from '../../../Widgets/notificationFeedbacks';
import { RxCross1 } from 'react-icons/rx';
import { Dialog, DialogContent } from '../../Dialog/Dialog';

const AddTempView = (props) => {
    const { open, handleDialogClose, existingData } = props;
    console.log(existingData,"existingData")
    let webApi = new WebApimanager();
    
    const [apiProcessing, setApiProcessing] = useState({
      loader: false,
      message: "Loading...",
    });
    const [formData,setFormData] = useState({
      department:"",
      user:"",
      fromDate:"",
      endDate:"",
      otherEmployee:""
    })
    const handleClose = () => {
      handleDialogClose();
      // setErrors({});
      // setFormData(emptyformdata);
    }; 
  
    return (
      <>
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="lg"
          fullWidth
          PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: " #4D4D4D",
              margin: "0px",
              height: '50px',
            }}
          >
            <p
              style={{width:"100%",marginTop:"8px", textAlign: "center", color: "white" }}
            >
              View
            </p>
            <RxCross1
              size={20}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "20px",
                color: "white",
              }}
              onClick={handleClose}
            />
          </div>
  
          <DialogContent
            style={{ padding: "25px", minWidth: "400px", minHeight: "300px" }}
          >
            <form>
              <p>Q : Who is the Person taking Leave?</p>
              <div className="MainContainer">
  
                <div className="TempMainInputFieldContainer">
                  <p>Department :</p>
                  <div className="tempInputContainer">
                  </div>
                </div>
                <div className="TempMainInputFieldContainer">
                  <p>User :</p>
                  <div className="tempInputContainer">
                  </div>
                </div>
              </div>
              <p>Q : Cite the duration of the leave?</p>
              <div className="MainContainer">
  
                <div className="TempMainInputFieldContainer">
                  <p>fromDate :</p>
                  <div className="tempInputContainer">
                  </div>
                </div>
                <div className="TempMainInputFieldContainer">
                  <p>ToDate :</p>
                  <div className="tempInputContainer">
                  </div>
                </div>
              </div>
  
              <p style={{fontWeight:"600"}}>Tasks:</p>
              <p>1. &nbsp;&nbsp; Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
              <p>2. &nbsp;&nbsp; Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
              <p>3. &nbsp;&nbsp; Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
              <p>Allocate this work to other employees:</p>
              <div className="MainContainer">
  
                <div className="TempMainInputFieldContainer">
                  <p>Department :</p>
                  <div className="tempInputContainer">
                  </div>
                </div>
                <div className="TempMainInputFieldContainer">
                  <p>User :</p>
                  <div className="tempInputContainer">
                  </div>
                </div>
              </div>
              
            </form>
           
          </DialogContent>
        </Dialog>
      </>
    );
  };

export default AddTempView