import React, { useState, useEffect, useRef,useMemo, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import AddDrawingDialog from "./Components/AddDrawingDialog";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import {
  isRoForwardAccessTrue,
  isRoRfiRaisedAccess,
  registerAtom,
  roDrawing,
  siteId,
  userInfo,
} from "../../../atoms";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { RxCross1 } from "react-icons/rx";
import ReceivedTable from "./ReceivedTable";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import TableWrapper from "../../../Utilities/TableWrapper";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import { getRegisterData, processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import { useNavigate } from "react-router-dom";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import { Tab1, Tabs1 } from "../../Tabs/Tabv2";
import { FaFilter } from "react-icons/fa";
import Pen from "../SITEHEAD/IconsSiteHead/penIcon.svg";
import Eye from "../SITEHEAD/IconsSiteHead/Eye.svg";
import Download from "../SITEHEAD/IconsSiteHead/Download.svg";
import { HiPlusCircle } from "react-icons/hi";
import { RiShareForwardLine } from "react-icons/ri";
import { FaCircleMinus } from "react-icons/fa6";
import Gallery from "../../../Images/Gallery.svg";
import { canrfiRaised, canShowFolder } from "../ShowFolder";
import { MdCancel } from "react-icons/md";
import ConsultantFilter from "../ConsultantFilter"
import ArrowToggle from "../ArrowToggle";
import SortByPopup from "../SortByPopup";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", 
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", 
          },
        },
      },
    },
  },
});
const ApprovedTable = ({ forwardAccess,rfiRaisedAccess}) => {
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();

  const [open, setOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [consultantValue, setConsultantValue] = useState("")
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [editData, setEditData] = useState({
    drawingId: "",
    drawingNumber: "",
    expectedDate: "",
    requestedDate: todayDate(),
    remarks: "",
    drawingFileName: null,
    revision: [],
    natureofrequestedInfo: "",
  });
  const [drawingTableList, setDrawingTableList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userDetails] = useRecoilState(userInfo);
  const companyID = userDetails[0]?.companyId;
  const [showCategoryFolderDropdown, setShowCategoryFolderDropdown] =
    useState(false);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  console.log(forwardAccess,"forwardAccess")
  console.log(rfiRaisedAccess,"rfiRaisedAccess")
  const [showHardCopy, setShowHardCopy] = useState(false)
  const [consultantNotSubmitHardCopy, setConsultantNotSubmitHardCopy] = useState([])
  const [currentView, setCurrentView] = useState("upload");
  const [roHardCopyRevisionList, setRoHardCopyRevisionList] = useState([]);

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  const [site, setSite] = useRecoilState(siteId);
  const [selectedRevision, setSelectedRevision] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [filters, setFilters] = useState({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
  });

  // sort by start
  const [sortField, setSortField] = useState(""); // Field to sort by
  const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
  const [activeSortPopup, setActiveSortPopup] = useState(null);

  const handleSortClick = (field) => {
    setFilters({
      drawingNo: "",
      consultant: "",
      category: "",
      drawingTitle: "",
      scheduledDate: "",
      submissionDate: "",
      revision: "",
    });
    setSearchValue("");
    setActiveSortPopup((prev) => (prev === field ? null : field));
  };

  const handleSortSelection = (order) => {
    if (activeSortPopup) {
      setSortField(activeSortPopup);
      setSortOrder(order);
      setActiveSortPopup(null); // Close the popup after selection
    }
  };

  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
    
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    })
    setSortField("");
    setSortOrder("");
    setActiveSortPopup(null);
  }, []);
  
  const handleFilterChange = (column, value) => {
    setSearchValue("")
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
    setFilters((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = ""; 
          return acc;
      }, {}),
      [column]: value, 
  }));
}

useEffect(()=>{
  setSortField("");
  setSortOrder("");
  setActiveSortPopup(null);
  setSearchValue("")
  setFilters({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
  });
},[currentView])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    fetchData();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
      setShowCategoryFolderDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      // Clean up the timeout on component unmount
    };
  }, []);

  const handleRevisionSelection = (event) => {
    setSelectedRevision(event.target.value);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditData({
      drawingId: "",
      drawingNumber: "",
      expectedDate: "",
      remarks: "",
      drawingFileName: null,
      revision: [],
      natureofrequestedInfo: [],
    });
  };
  const [selectedTab, setSelectedTab] = useState(0);

  const handleForwardClick = async (singledrawingdata, revisionNo) => {
    console.log(singledrawingdata, revisionNo, "handleForwardClick");
    const registerId = singledrawingdata.drawingId;

    // Find the specific revision data in acceptedArchitectRevisions
    const selectedRevision = singledrawingdata.acceptedArchitectRevisions.find(
      (revision) => revision.revision === `R${revisionNo}`
    );
    console.log(selectedRevision, "revisionssssssssssssssssssssssss");

    if (!selectedRevision) {
      alert("Revision not found");
      return;
    }
    setApiProcessing({ loader: true, message: "Forwarding" });

    try {
      const ApprovalFormData = new FormData();
      ApprovalFormData.append("softCopySubmittedDate", todayDate());
      ApprovalFormData.append("architectRef", selectedRevision._id);
      ApprovalFormData.append("roType", "Forwarded");
      console.log("Submitting Approval data:", ApprovalFormData);
      const response = await webApi.imagePut(
        `api/architectureToRoRegister/revisions/${registerId}?revisionType=acceptedRORevisions`,
        ApprovalFormData
      );

      if (response.status === 200) {
        setApiProcessing({ loader: false, message: "" });
        handleViewClose();
        window.location.reload();
      } else {
        setApiProcessing({ loader: false, message: "" });

        console.error("Failed to submit the form:", response);
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      alert("An error occurred. Please try again later.");
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);

    const selectedRevision =
      singleDrawingData?.acceptedArchitectRevisions?.[newValue];
    console.log("Selected Tab Index:", newValue);
    console.log("Selected Revision Data:", selectedRevision);
  };

  const handleViewOpen = (drawingItem) => {
    console.log(drawingItem, "drawingItemm");
    setSingleDrawingData(drawingItem);
    if (drawingItem.acceptedArchitectRevisions.length > 0) {
      setSelectedTab(drawingItem?.acceptedArchitectRevisions?.length - 1);
    }
    const allHardCopyRev = [];

    if (drawingItem?.acceptedROHardCopyRevisions?.length) {
      drawingItem?.acceptedROHardCopyRevisions?.forEach((item) => {
        if (!allHardCopyRev.includes(item?.revision)) {
          allHardCopyRev.push(item.revision);
        }
      });
    }
    console.log(allHardCopyRev, "allllllllllHardCopyRev");
    setRoHardCopyRevisionList(allHardCopyRev);
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };

  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
      }
    });
  };

  useEffect(() => {
    fetchInData();
  }, []);

  useEffect(() => {
    setReloadData(true);
  }, [drawingList]);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setEditData({
        ...editData,
        [name]: files[0],
      });
    } else {
      setEditData({
        ...editData,
        [name]: value,
      });
    }
  };
  const handleEditSubmit = async (e) => {
    console.log("rfi data", editData);
    e.preventDefault();

    let formErrors = {};
    if (!editData.drawingNumber)
      formErrors.drawingNumber = "Drawing Number is required";
    if (!editData.expectedDate)
      formErrors.expectedDate = "Expected Date is required";

    if (!editData.drawingFileName) {
      formErrors.drawingFileName = "Drawing File is required";
    }
    if (!editData.natureofrequestedInfo)
      formErrors.natureofrequestedInfo = "this field is requires";
    // if (!editData.revision) formErrors.revision = 'Please select a revision.';
    requestReason.forEach((reason, index) => {
      if (!reason.NOR) {
        formErrors[`NOR_${index}`] = "Nature of Request is required.";
      }
      if (!reason.reason) {
        formErrors[`reason_${index}`] = "Reason is required.";
      }
    });

    setError(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });
    try {
      const editFormData = {
        drawingId: editData.drawingId,
        drawingNo: editData.drawingNumber,
        siteId: site,
        requestedDate: todayDate(),
        expectedDate: editData.expectedDate,
        revision: selectedRevision,
        natureOfRequestedInformation: editData.natureofrequestedInfo,

        natureOfRequestedInformationReasons: editData.natureofrequestedInfo
          .map((info) => {
            const filteredReasons = requestReason.filter(
              (req) => req.NOR === info
            );

            // Ensure each natureOfRequest is paired with a single reason.
            return filteredReasons.map((req) => ({
              natureOfRequest: info,
              reason: req.reason,
            }));
          })
          .flat(),
      };

      const response = await webApi.post(
        "api/architectureToRoRequested/postRequest",
        editFormData
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.status === "error") {
          // Handle error from response data
          setErrorMessage(responseData.message);
          console.log("Response data:", responseData.message);
          setApiProcessing({ loader: false, message: "" });
          return; // Stop further processing
        }

        const id = response.data.data._id;

        if (editData.drawingFileName) {
          const drawingFileName = new FormData();
          drawingFileName.append("drawingFileName", editData.drawingFileName);
          const uploadResponse = await webApi.imagePut(
            `api/architectureToRoRequested/${id}`,
            drawingFileName
          );
          console.log("Upload response:", uploadResponse);
        }
        setApiProcessing({ loader: false, message: "" });
        handleEditClose();
        fetchData();
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
          console.log("Response data:", response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };
  const handleDownload = async (item, revision) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    const id = item.drawingId;

    console.log(item, "Download in Drawing Items");

    try {
      

      const  apiEndpoint = `api/architectureToRoRegister/${id}/revisions/${revision}`;
      

      const response = await webApi.getImage(apiEndpoint, {
        responseType: "arraybuffer",
      });

      processFile(response, "download", navigate);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handlePDFDownload = async (filename) => {
    console.log(filename, "fiename");
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      const response = await webApi.getImage(
        `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
        {
          responseType: "arraybuffer", 
        }
      );
      processFile(response, "download", navigate); 
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (item, revision) => {
    setApiProcessing({
      loader: true,
      message: "Viewing...",
    });
    const id = item.drawingId;

    console.log(item, "Download in Drawing Items");

    try {

      const apiEndpoint = `api/architectureToRoRegister/${id}/revisions/${revision}`;

      const response = await webApi.getImage(apiEndpoint, {
        responseType: "arraybuffer",
      });

      processFile(response, "view", navigate,revision,item,"Approval");
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewPDFFile = async (filename) => {
    console.log(filename, "getting inside");
    try {
      setApiProcessing({ loader: true, message: "Viewing" });
      const response = await webApi.getImage(
        `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
        {
          responseType: "arraybuffer", 
        }
      );
      console.log("Image fot APPROVED", response);

      processFile(response, "view", navigate); 
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const listItems = [
    { id: "A - Missing Information", name: "A - Missing Information" },
    { id: "B - Clarification", name: "B - Clarification" },
    { id: "C - Additional Information", name: "C - Additional Information" },
    { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
  ];

  const getNameFromId = (id) => {
    const item = listItems.find((item) => item.id === id);
    return item ? item.name : "";
  };

  const calculateDueDays = (
    acceptedSiteSubmissionDate,
    softCopySubmittedDate = null
  ) => {
    if (!acceptedSiteSubmissionDate) return "-";

    const dateToCompare = softCopySubmittedDate
      ? new Date(softCopySubmittedDate)
      : new Date();
    const acceptedDate = new Date(acceptedSiteSubmissionDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); 
    return differenceInDays;
  };

  const [drawingIds, setDrawingIds] = useState([]); 
  useEffect(() => {
    const fetchDrawingTableData = async (site) => {
      try {
        const response = await webApi.get(
          `api/architect/drawingRo/${site}?filterType=upload`
        );
        if (response.status === 200) {
          setDrawingTableList(response.data.data);
          setInitialDrawingList(response.data.data);
        }
        console.log(drawingTableList, "RO tabs tableData dawing UPLOAD");

        const ids = response.data.data.map((drawing) => drawing.drawingId);
        setDrawingIds(ids);

        console.log(ids, "Drawing");
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchDrawingTableData(site);
  }, [site]);

  const drawingID = drawingTableList?.drawingId;

  console.log(drawingID, "Drawing ID");

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);

    
  };

  const handleTypeSelect = (type, selectedType) => {
    setDrawingTableList((data) =>
      data.filter((drawingItem) => {
        const category = drawingItem?.category?.category?.toLowerCase() || "";
        const folderName =
          drawingItem?.folderId?.folderName?.toLowerCase() || "";

        let matchesCategory = true;
        if (type === "category") {
          matchesCategory = selectedType
            ? category === selectedType.toLowerCase()
            : true;
        }

        let matchesFolder = true;
        if (type === "folder") {
          matchesFolder = selectedType
            ? folderName === selectedType.toLowerCase()
            : true;
        }

        return matchesCategory && matchesFolder;
      })
    );
  };

 
  const handleEditOpen = (drawingDatas) => {
    console.log(drawingDatas, "drawingDatas onclick");
    const drawingItem = drawingTableList.find(
      (item) => item.drawingId === drawingDatas.drawingId
    );
    if (drawingItem) {
      const combinedRevisions = Array.isArray(
        drawingItem.acceptedArchitectRevisions
      )
        ? drawingItem.acceptedArchitectRevisions.flatMap((rev) => rev.revision)
        : [];

      console.log(drawingDatas, "drawingDatas");
      console.log(drawingItem, "drawingDatas");
      setEditData({
        drawingId: drawingDatas.drawingId,
        drawingNumber: drawingItem.drawingNo,
        expectedDate: "",
        requestedDate: todayDate(),
        drawingFileName: null,
        revision: combinedRevisions,
        natureofrequestedInfo: "",
      });

      setOpen(false);
      setEditDialogOpen(true);
      setViewDialogOpen(false);
    }
  };
  const handleAddReason = () => {
    setRequestReason([...requestReason, { NOR: "", reason: "" }]);
  };

  const handleRemoveReason = (indexToRemove) => {
    const updatedReasons = requestReason.filter(
      (_, index) => index !== indexToRemove
    );
    setRequestReason(updatedReasons);
  };
  const [requestReason, setRequestReason] = useState([{ NOR: "", reason: "" }]);
  const handleReasonChange = (e, index) => {
    console.log("jksdnfks");
    const { name, value } = e.target;
    const updatedReasons = [...requestReason];

    updatedReasons[index] = {
      ...updatedReasons[index],
      [name]: value,
    };

    setRequestReason(updatedReasons);
  };


  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showEdit,setShowEdit] = useState(false);

  useEffect(() => {
    const showFolder = canShowFolder(userDetails, site);
    setShowAddFolder(showFolder);
    console.log(showAddFolder,"rfiRaisedAccess111 && ");
    const showEdit = canrfiRaised(userDetails, site);
    setShowEdit(showEdit);
    console.log(showEdit,"rfiRaisedAccess && 222");
  }, [userDetails, site]);
  console.log(userDetails,"checking details");

  const dropdownRef = useRef(null);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isCategoriesDropdownOpen, setCategoriesDropdownOpen] = useState(false);
  const [isFoldersDropdownOpen, setFoldersDropdownOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectedConsultant, setSelectedConsultant] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
    
  };

  const handleType = (type) => {
    if (type === "all") {
      setSelectedCategory("");

      setFilterDropdownOpen(false);
      setCategoriesDropdownOpen(false);
      setFoldersDropdownOpen(false);
    } else {
      setSelectedCategory(type);
      setFilterDropdownOpen(false);
      setFoldersDropdownOpen(false);
    }
  };

  const uniqueFolders = useMemo(() => {
    const folder = drawingTableList.map((item) => item.folderId?.folderName);
    return [...new Set(folder)];
  }, [drawingTableList]);

  const uniqueCategories = useMemo(() => {
    const categories = drawingTableList.map((item) => item.category?.category);
    return [...new Set(categories)];
  }, [drawingTableList]);

  const uniqueConsultant = useMemo(() => {
    const consultant = drawingTableList.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [drawingTableList]);

  console.log(uniqueCategories, uniqueFolders, "SITE Head Upload categories");

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [filteredGoData, setFilteredGoData] = useState(null); 

  const [showFilteredData, setShowFilteredData] = useState(false); 

  const handleConsultantSelect = (consultant) => {
    setSelectedConsultant(consultant)
    setShowFilteredData(false);
      
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(
      (prevSelected) =>
        prevSelected.includes(category)
          ? prevSelected.filter((c) => c !== category) 
          : [...prevSelected, category] 
    );
    setShowFilteredData(false);
  };

  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
    setShowFilteredData(false);
  };

  const handleGoClick = () => {
    console.log("Selected Consultants:", selectedConsultant);
    console.log("Selected Categories:", selectedCategory);
    console.log("Selected Folders:", selectedFolder);
    if(selectedConsultant || selectedCategory.length || selectedFolder){
      setShowFilteredData(true);
      toggleFilterDropdown();
      }
    };

  const filteredDataAndUploadData = useMemo(() => {
    if (!showFilteredData) {
    return drawingTableList;
  }
    return drawingTableList .filter((item) =>{ 
      const matchesConsultant = selectedConsultant === "" ||  item?.designDrawingConsultant?.role === selectedConsultant;

    const matchesCategory = selectedCategory.length === 0 ||  selectedCategory.includes(item?.category?.category);
    const matchesFolder = 
      !selectedFolder || item.folderId?.folderName === selectedFolder;

    return matchesConsultant && matchesCategory && matchesFolder; 

  });
  }, [showFilteredData, selectedConsultant, selectedCategory, selectedFolder, drawingTableList]);

  const handleClearAll = () =>{
    setSelectedCategory([])
    setSelectedFolder("")
    setSelectedConsultant("")
  }
  const handleFilterCancel = () =>{
    toggleFilterDropdown()
    setShowFilteredData(false)

  }    

  const handleRemove = (type, value) => {
    setShowFilteredData(false)

    if (type === "consultant") {
      setSelectedConsultant("");
    } else if (type === "category") {
      setSelectedCategory(selectedCategory.filter((cat) => cat !== value));
    } else if (type === "folder") {
      setSelectedFolder("");
    }
  };

  const fetchNoOfDays = async() =>{
       try {
         const response = await webApi.get("api/rfiTimeStamp/byCompanyId")
         console.log(response.data.data.customizedView ,"response in the rfi timeStamp")
          if (response.status === 200) {
           const recievedHardCopies = response?.data?.data?.areYouReceivingHardCopiesFromAllConsultants       
           setShowHardCopy(recievedHardCopies)
           if(!recievedHardCopies){
            setConsultantNotSubmitHardCopy(response?.data?.data?.whichConsultantsHaveNotSubmittedHardCopies)
           }
           console.log(recievedHardCopies,"recievedHardCopies")
          } 
       } catch (error) {
         console.log(error.message)
       }
     }
     
     useEffect(()=>{
       fetchNoOfDays()
     },[])

  const updatedRows = drawingTableList.filter((item)=>item.designDrawingConsultant?.role.includes(consultantValue)).map((row, index) => {
    const latestSoftCopySubmittedDate =
      row.acceptedRORevisions?.length > 0
        ? row.acceptedRORevisions[row.acceptedRORevisions.length - 1]
            ?.softCopySubmittedDate
        : null;

    const calculateDueDays = (siteSubmissionDate, softCopySubmittedDate) => {
      if (!siteSubmissionDate || !softCopySubmittedDate) return null;
      const startDate = new Date(siteSubmissionDate);
      const endDate = new Date(softCopySubmittedDate);
      return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
    };

    const dueDays = calculateDueDays(
      row.acceptedSiteSubmissionDate,
      latestSoftCopySubmittedDate
    );

    const dueStatus = {
      text:
        dueDays === null
          ? "-"
          : dueDays >= 0
          ? `${dueDays} days due`
          : `${Math.abs(dueDays)} days overdue`,
      color:
        dueDays === null
          ? "black"
          : dueDays >= 0
          ? "green"
          : "red",
    };
    return {
    ...row,
    serialNo: index + 1,
    drawingNo: row.drawingNo || "-",
    designConsultant: row?.designDrawingConsultant?.role || "-",
    drawingCategory: row.category?.category || "-",
    drawingTitle: row.drawingTitle || "-",
    acceptedSiteSubmissionDate: formatDate(row.acceptedSiteSubmissionDate),
    finalSubmissionDate:row?.acceptedRORevisions?.length > 0
      ? formatDate(
          row.acceptedRORevisions[
            row.acceptedRORevisions.length - 1
          ]?.softCopySubmittedDate
        ):"-",
    revision:row?.acceptedArchitectRevisions?.length > 0
      ? row.acceptedArchitectRevisions
          .slice(-1)
          .map((rev) => rev.revision)
          .join(", ")
      : "-",
    forwardedRevision: row?.acceptedRORevisions?.length > 0
      ? row.acceptedRORevisions
          .slice(-1)
          .map((rev) => rev.revision)
          .join(", ")
      : "-",
      dueStatus:dueStatus,
      }
  });

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = useMemo(
    () => [
      {
        field: "serialNo",
        headerName: "S.No",
        flex: 0.5,
        filterable: false,
        sortable: false,
      },
      {
        field: "drawingNo",
        headerName: "Drawing No",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "designConsultant",
        headerName: "Consultant",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "drawingCategory",
        headerName: "Drawing Category",
        flex: 1.5,
        filterable: true,
        sortable: true,
      },
      {
        field: "drawingTitle",
        headerName: "Drawing Title",
        flex: 2,
        filterable: true,
        sortable: true,
        
      },
      {
        field: "acceptedSiteSubmissionDate",
        headerName: "Accepted Site Submission Date",
        flex: 1.5,
        filterable: true,
        sortable: true,
        
      },
      {
        field: "finalSubmissionDate",
        headerName: "Final Submission Date",
        flex: 1.5,
        filterable: true,
        sortable: true,
       
      },
      {
        field: "dueStatus",
        headerName: "Due/Overdue",
        flex: 1,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          const dueStatus = params.row?.dueStatus || { text: "-", color: "black" }; // Fallback if dueStatus is undefined
          return (
            <span style={{ color: dueStatus.color }}>
              {dueStatus.text}
            </span>
          );
        },
        
      },
      
      {
        field: "revision",
        headerName: "Revision",
        flex: 1,
        filterable: true,
        sortable: true,
       
      },
      {
        field: "forwardedRevision",
        headerName: "Forwarded Revision",
        flex: 1,
        filterable: true,
        sortable: true,
        
      },
      {
        field: "action",
        headerName: "Action",
        flex: 1,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          const drawingItem = params.row;
          
          
          // Get the latest revision
          const latestRevision =
            drawingItem.acceptedArchitectRevisions?.length > 0
              ? drawingItem.acceptedArchitectRevisions[
                  drawingItem.acceptedArchitectRevisions.length - 1
                ]
              : null;
      
          const softCopyReceived = latestRevision?.issuedSoftCopy === "Yes";
          const hardCopyReceived = drawingItem.acceptedROHardCopyRevisions?.some(
            (hardCopyRevision) => hardCopyRevision.revision === latestRevision?.revision
          );
      
          const softCopySubmittedDate = latestRevision?.softCopySubmittedDate
            ? new Date(latestRevision?.softCopySubmittedDate)
            : null;
      
          const currentDate = new Date();
          const roRfiTimeStampDays = drawingItem.roRfiTimeStampDays || 0;
      
          const diffInDays =
            softCopySubmittedDate &&
            Math.floor((currentDate - softCopySubmittedDate) / (1000 * 60 * 60 * 24));
      
          const isFilesReceived = softCopyReceived && hardCopyReceived;
      
          const showEditButton =
            isFilesReceived && diffInDays >= roRfiTimeStampDays;
      
          return (
            <div style={{ display: "flex", gap: "10px", alignItems: "center",marginTop:"24px" }}>
              {/* Edit Icon */}
              {rfiRaisedAccess && latestRevision && (
                showEditButton && (
                  <img
                    src={Pen}
                    alt="edit"
                    style={{ cursor: "pointer", marginRight: "5px" }}
                    onClick={() => handleEditOpen(drawingItem)}
                  />
                ) 
              )}
      
              {/* View Icon */}
              <img
                src={Eye}
                alt="view"
                style={{ cursor: "pointer", marginRight: "5px" }}
                onClick={() => handleViewOpen(drawingItem)}
              />
      
              {/* Download Icon */}
              <img
                src={Download}
                alt="download"
                style={{ cursor: "pointer", marginLeft: "5px" }}
                onClick={() => {
                  const latestRORevision =
                    drawingItem.acceptedRORevisions?.length > 0
                      ? drawingItem.acceptedRORevisions[
                          drawingItem.acceptedRORevisions.length - 1
                        ].revision
                      : null;
                  handleDownload(drawingItem, latestRORevision);
                }}
              />
            </div>
          );
        },
      }
      
    ],
    [drawingTableList] 
  );
  


  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <Tabs value={currentView} onChange={handleTabViewChange}>
                  <Tab label="Soft Copy" value="upload" />
                  {(showHardCopy || !(consultantNotSubmitHardCopy.includes(userDetails?._id))) && <Tab label="Hard Copy" value="received" />}
                </Tabs>
              </div>
              <div style={{ display: "flex",marginTop:"15px"}}>
                {currentView === "upload" && (
                  <>
                    <div
                      style={{
                        position: "relative",
                        paddingLeft: "20px",
                        height: "50px",
                        display: "flex",
                      }}
                    >
                      <input
                        placeholder="Search Drawing Lists"
                        // onChange={handleSearchChange}
                        onChange={(e)=>setSearchValue(e.target.value)}
                        style={{
                          paddingLeft: "20px",
                          border: "1px solid var(--primary-color)",
                          width: "250px",
                          height: "40px",
                          marginRight:"15px"
                        }}
                      />
                      <Icon.Search
                        style={{
                          position: "absolute",
                          right: "25px",
                          top: "40%",
                          transform: "translateY(-50%)",
                          color: "#ACACAC",
                        }}
                      />
                    </div>
                  </>
                )}

                {/* {currentView === "upload" && (
                  <div ref={dropdownRef} style={{ position: "relative" }}>
                  <div onClick={toggleFilterDropdown}>
                    <FaFilter
                      size={40}
                      style={{
                        border: "1px solid var(--primary-color)",
                        padding: "5px",
                      }}
                    />
                  </div>
                  {isFilterDropdownOpen && (
                    <div
                      style={{
                        position: "absolute",
                        right:"0px",
                        top:"43px",
                        background: "white",
                        borderRadius: "4px",
                        zIndex: 1000,
                        textAlign: "left",
                        marginTop: "20px",
                        marginLeft: "-558px",
                        padding: "15px",
                        width: "500px",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: selectedConsultant || selectedCategory.length || selectedFolder?"space-between":"flex-end",
                          alignItems: "center",
                          marginBottom: "10px",
                          marginTop:"10px"
                        }}
                      >
                        
                        <MdCancel style={{position:"absolute",fontSize:"23px" ,right:"-10px",top:"-24px",cursor:"pointer"}}onClick={handleFilterCancel} />
                        {(selectedConsultant || selectedCategory.length || selectedFolder) && (
                            <button
                              style={{
                                padding: "6px 10px",
                                backgroundColor: "crimson",
                                color: "white",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                              onClick={handleClearAll}
                            >
                              Clear All
                            </button>
                          )}
                          <button
                            style={{
                              backgroundColor: "var(--primary-color)",
                              color: "white",
                              fontSize: "16px",
                              padding: "5px 15px",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                            onClick={handleGoClick}
                          >
                            Go
                          </button>
                          
                        
                      </div>
        
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        {selectedConsultant && (
                          <button
                            style={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#ffd700",
                              color: "white",
                              border: "none",
                              borderRadius: "4px",
                              padding: "5px 10px",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemove("consultant", selectedConsultant)}
                          >
                            {selectedConsultant} <MdCancel style={{ marginLeft: "5px" }} />
                          </button>
                        )}
        
                        {selectedCategory.map((category, index) => (
                          <button
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#ffd700",
                              color: "white",
                              border: "none",
                              borderRadius: "4px",
                              padding: "5px 10px",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemove("category", category)}
                          >
                            {category} <MdCancel style={{ marginLeft: "5px" }} />
                          </button>
                        ))}
        
                        {selectedFolder && (
                          <button
                            style={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#ffd700",
                              color: "white",
                              border: "none",
                              borderRadius: "4px",
                              padding: "5px 10px",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemove("folder", selectedFolder)}
                          >
                            {selectedFolder} <MdCancel style={{ marginLeft: "5px" }} />
                          </button>
                        )}
                      </div>
        
                      <div style={{ flex: 3 }}>
                        <span style={{ cursor: "pointer", position: "relative" }}>
                          <p style={{ color: "black", fontSize: "20px" }}>Consultant</p>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                            }}
                          >
                            {uniqueConsultant.map((consultant, index) => (
                              <div
                                key={index}
                                style={{
                                  flex: "1 1 calc(25.33% - 15px)",
                                  backgroundColor:
                                    selectedConsultant === consultant ? "#ffd700" : "#e0f7fa",
                                  fontWeight:
                                    selectedConsultant === consultant ? "700" : "500",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: "5px",
                                  color: selectedConsultant === consultant ? "white" : "#333",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() => handleConsultantSelect(consultant)}
                              >
                                {consultant}
                              </div>
                            ))}
                          </div>
                        </span>
        
                                <span style={{ cursor: "pointer", position: "relative" }}>
                                  <p style={{ color: "black", fontSize: "20px" }}>Categories</p>
        
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "5px",
                                      backgroundColor: "#f5f5f5",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    {uniqueCategories.map((category, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          flex: "1 1 calc(25.33% - 15px)",
                                          backgroundColor:  selectedCategory.includes(category)?"#ffd700":"#e0f7fa",
                                          fontWeight: selectedCategory.includes(category)?"700":"500",
                                          borderRadius: "4px",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "5px",
                                          color: selectedCategory.includes(category)?"white":"#333",
                                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        }}
                                        onClick={() => handleCategorySelect(category)}
                                      >
                                        {category}
                                      </div>
                                    ))}
                                  </div>
                                </span>
        
                                {showAddFolder?<span style={{ cursor: "pointer", position: "relative" }}>
                                  <p
                                    style={{
                                      color: "black",
                                      fontSize: "20px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Folders
                                  </p>
        
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "5px",
                                      backgroundColor: "#f5f5f5",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    {uniqueFolders.map((folder, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          flex: "1 1 calc(25.33% - 15px)",
                                          backgroundColor: "#e0f7fa",
                                          fontWeight: selectedFolder === folder ? "700" : "500",
                                          borderRadius: "4px",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "5px",
                                          color: "#333",
                                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        }}
                                        onClick={() => handleFolderSelect(folder)}
                                      >
                                        {folder}
                                      </div>
                                    ))}
                                  </div>
                                </span>:""}
                                <>
                                  
                                </>
                              </div>
                            </div>
                          )}
                </div>
                )} */}

              </div>
            </div>
            <div>
              {currentView === "upload" && (
                <div style={{ marginTop: "10px" }}>
                  <Box
                    className="dataGridContainer"
                  >
                    <ThemeProvider theme={theme}>
                    <DataGrid
                      columns={columns}
                      rows={filteredRows}
                      getRowId={(row) => row.drawingId}
                      rowHeight={60}
                      disableColumnMenu={false} // Keep the column menu for filtering
                      pagination={false}
                      hideFooter={true}
                      getRowSpacing={params=>({
                        top:params.isFirstVisible ? 0 : 5,
                        bottom:params.isLastVisible ? 0 : 5
                      })}
                      
                      getRowClassName={(params) =>
                        "customHeader" 
                      }
                      
                    />
                    </ThemeProvider>
                  </Box>
                </div>
              )}
              {currentView === "received" && (
                <div style={{ marginTop: "10px" }}>
                  <ReceivedTable
                    selectedFilter={""}
                    searchValue={searchValue}
                    searchActive={searchActive}
                    setSearchValue={setSearchValue}
                    setSearchActive={setSearchActive}
                    reloadData={reloadData}
                    setReloadData={setReloadData}
                    filterCondition="approved"
                    drawingTableList={drawingTableList}
                    forwardAccess={forwardAccess}
                  />
                </div>
              )}
            </div>
          </>
        }
      />
      <AddDrawingDialog
        handleDialogClose={handleDialogClose}
        open={open}
        site={site}
        currentView={currentView}
      />
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <p
            style={{
              width:"100%",
              textAlign:"center",
              color: "white",
              marginTop:"15px"
            }}
          >
            RFI
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              cursor: "pointer",
              color: "white",
            }}
            onClick={handleEditClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <form onSubmit={handleEditSubmit}>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10  text-start py-2"
                  >
                    Drawing Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="drawingNumber"
                    placeholder="Enter your Drawing Number"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.drawingNumber}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {error.drawingNumber && (
                    <div className="text-danger">{error.drawingNumber}</div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="requestedDate"
                    className="fs-10  text-start py-2"
                  >
                    Requested Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="expectedDate"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.requestedDate}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {error.requestedDate && (
                    <div className="text-danger">{error.requestedDate}</div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="expectedDate"
                    className="fs-10  text-start py-2"
                  >
                    Expected Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    name="expectedDate"
                    className="form-control fs-12"
                    style={{ width: "100%", height: "45px" }}
                    value={editData.expectedDate}
                    onChange={handleInputChange}
                    min={tomorrowDateString}
                  />
                  {error.expectedDate && (
                    <div className="text-danger">{error.expectedDate}</div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <label htmlFor="revision" className="fs-10  text-start py-2">
                    Revisions
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </label>
                  <SearchDropdownIdVal
                    placeholder={"Select Revision"}
                    backgroundColor={"var(--table-evenrow-color)"}
                    options={
                      Array.isArray(editData.revision) &&
                      editData.revision.length > 0
                        ? [
                            {
                              name: editData.revision.slice(-1)[0], // Get the latest revision
                              value: editData.revision.slice(-1)[0], // Use the latest revision for value
                            },
                          ]
                        : []
                    }
                    onOptionSelect={(option) =>
                      handleRevisionSelection({
                        target: {
                          name: "selectedRevision",
                          value: option.value,
                        },
                      })
                    }
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />

                  {error.revision && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {error.revision}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <MultiSelectSearch
                    mandatory={true}
                    heading={"Nature of Requested Information"}
                    listItems={listItems}
                    selectedIds={editData.natureofrequestedInfo || []} // Ensure this is an array
                    setSelectedIds={(ids) =>
                      setEditData({ ...editData, natureofrequestedInfo: ids })
                    }
                    handleSelectedPropertyOption={(data) => {
                      if (editData.natureofrequestedInfo.includes(data.id)) {
                        setEditData({
                          ...editData,
                          natureofrequestedInfo:
                            editData.natureofrequestedInfo.filter(
                              (id) => id !== data.id
                            ),
                        });
                      } else {
                        setEditData({
                          ...editData,
                          natureofrequestedInfo: [
                            ...editData.natureofrequestedInfo,
                            data.id,
                          ],
                        });
                      }
                    }}
                    clearAll={() =>
                      setEditData({ ...editData, natureofrequestedInfo: [] })
                    }
                    addAll={() =>
                      setEditData({
                        ...editData,
                        natureofrequestedInfo: listItems.map((item) => item.id),
                      })
                    }
                  />
                  {error.natureofrequestedInfo && (
                    <div className="text-danger">
                      {error.natureofrequestedInfo}
                    </div>
                  )}
                  <div style={{ marginTop: "20px" }}>
                    <p className="fs-10  text-start py-2">Selected Fields:</p>
                    <ul>
                      {editData.natureofrequestedInfo.length > 0 ? (
                        editData.natureofrequestedInfo.map((id) => (
                          <li key={id}>{getNameFromId(id)}</li>
                        ))
                      ) : (
                        <li>No fields selected</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="remarks"
                    className="fs-10  text-start py-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Request Reason *
                    <span style={{ display: "flex", alignItems: "center" }}>
                      &nbsp; &nbsp; &nbsp;{" "}
                      <HiPlusCircle
                        style={{
                          marginLeft: "20px",
                          color: "#e47331",
                          fontSize: "24px",
                        }}
                        onClick={handleAddReason}
                      />
                    </span>
                  </label>
                  {requestReason.map((reqReason, reqIndex) => (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        marginTop: "30px",
                      }}
                    >
                      <select
                        style={{ width: "15%", marginRight: "20px" }}
                        name="NOR"
                        value={reqReason.NOR}
                        onChange={(e) => handleReasonChange(e, reqIndex)}
                      >
                        <option vlaue="">Select</option>
                        {editData.natureofrequestedInfo.length &&
                          editData.natureofrequestedInfo.map(
                            (requestInfo, index) => (
                              <option key={index} value={requestInfo}>
                                {requestInfo?requestInfo?.slice(0,2):""}
                              </option>
                            )
                          )}
                      </select>

                      <input
                        type="text"
                        style={{ width: "82%" }}
                        name="reason"
                        value={reqReason.reason}
                        onChange={(e) => handleReasonChange(e, reqIndex)}
                      />
                      {reqIndex > 0 ? (
                        <FaCircleMinus
                          style={{
                            fontSize: "24px",
                            position: "absolute",
                            right: "-21px",
                          }}
                          onClick={() => handleRemoveReason(reqIndex)}
                        />
                      ) : (
                        ""
                      )}
                      {error[`NOR_${reqIndex}`] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "50px",
                          }}
                        >
                          {error[`NOR_${reqIndex}`]}
                        </p>
                      ) : error[`reason_${reqIndex}`] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "50px",
                            right: "50px",
                          }}
                        >
                          {error[`reason_${reqIndex}`]}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <label
                  htmlFor="drawingFileName"
                  className="fs-10  text-start py-2"
                >
                  Upload Your File <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  id="drawingFileName"
                  name="drawingFileName"
                  className="form-control fs-12"
                  style={{ width: "100%", height: "43px" }}
                  onChange={(e) => 
                      handleInputChange(e)
                    }
                />

                {error.drawingFileName && (
                  <div className="text-danger">{error.drawingFileName}</div>
                )}
              </div>
              {errorMessage && (
                <div style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  type="submit"
                  style={{
                    background: "blue",
                    color: "white",
                    width: "100px",
                    height: "35px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <p
            style={{width:"100%",marginTop:"15px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
            <div
              style={{
                display: "flex",
                gap: "0",
                border: "1px solid var(--primary-color)",
              }}
            >
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 ? (
                <Tabs1 value={selectedTab} onChange={handleTabChange}>
                  {singleDrawingData.acceptedArchitectRevisions.map(
                    (revisionItem, index) => {
                      console.log("Revision:", revisionItem.revision);

                      const label = revisionItem.revision;

                      console.log(label, "lable for revision");

                      return (
                        <Tab1
                          key={index}
                          value={index}
                          label={label}
                          selected={selectedTab === index}
                          style={{
                            backgroundColor:
                              singleDrawingData.acceptedArchitectRevisions
                                .length === 1 || selectedTab === index
                                ? "var(--primary-color)"
                                : "transparent",
                            color:
                              singleDrawingData.acceptedArchitectRevisions
                                .length === 1 || selectedTab === index
                                ? "white"
                                : "var(--primary-color)",
                            width: "150px",
                            height: "35px",
                            cursor: "pointer",
                            borderRight:
                              index <
                              singleDrawingData.acceptedArchitectRevisions
                                .length -
                                1
                                ? "1px solid var(--primary-color)"
                                : "none",
                          }}
                        />
                      );
                    }
                  )}
                </Tabs1>
              ) : (
                <div>No Revisions</div>
              )}
            </div>
          
        </div>
        {singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
          selectedTab <=
            singleDrawingData.acceptedArchitectRevisions.length - 1 && (
            <>
              {(() => {
                const selectedRevision =
                  singleDrawingData.acceptedArchitectRevisions[selectedTab];
                const hardCopyRevision =
                  singleDrawingData?.acceptedROHardCopyRevisions[
                    singleDrawingData?.acceptedROHardCopyRevisions?.length - 1
                  ]?.revision;

                console.log(hardCopyRevision, "hardCopyRevisionsssss");

                if (
                  singleDrawingData?.acceptedArchitectRevisions[
                    singleDrawingData?.acceptedArchitectRevisions?.length - 1
                  ]?.revision === hardCopyRevision
                ) {
                  if (selectedRevision?.rfiStatus === "Not Raised") {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                          color: "green",
                        }}
                      >
                        Good For Construction
                      </div>
                    );
                  } else {
                    return (
                      <p
                        style={{
                          color: "orange",
                          textAlign: "center",
                          fontSize: "16px",
                        }}
                      >
                        RFI has been raised
                      </p>
                    );
                  }
                } else if (selectedRevision?.rfiStatus === "Raised") {
                  return (
                    <p
                      style={{
                        color: "orange",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      RFI has been raised. Hardcopy file not received
                    </p>
                  );
                } else if (hardCopyRevision === selectedRevision?.revision) {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        color: "red",
                      }}
                    >
                      Superseeded
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        color: "black",
                      }}
                    >
                      No hardcopy file received
                    </div>
                  );
                }
              })()}
            </>
          )}

        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.designDrawingConsultant?.role}
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>
              {showAddFolder &&
              <>
              <div>Folder</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.folderId?.folderName || "NA"}
              </div>
              </>
              }
              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>

              <div>Scheduled submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedSiteSubmissionDate
                  ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Actual Final Submission Date</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ].softCopySubmittedDate
                          ? new Date(
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].softCopySubmittedDate
                            ).toISOString()
                            .split("T")[0]
                          : "No Submitted Date"}
                      </div>
                    </>
                  )}
                </>
              )}
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ]?.revision
                          ?  `${singleDrawingData.acceptedArchitectRevisions[selectedTab].revision}`
                          : `${
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ]?.revision || "NA"
                            }`}
                      </div>
                    </>
                  )}
                </>
              )}
              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedRORevisions[selectedTab] && (
                    <>
                      <div>Forward Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData?.acceptedRORevisions[selectedTab]
                          ?.revision
                          ? `${singleDrawingData?.acceptedRORevisions[selectedTab].revision}`
                          : `${
                              singleDrawingData?.acceptedRORevisions[
                                selectedTab
                              ]?.revision || "NA"
                            }`}
                      </div>
                    </>
                  )}
                </>
              )}
              {forwardAccess &&
              singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
              singleDrawingData?.acceptedArchitectRevisions[selectedTab]
                .rfiStatus !== "Raised"  && roHardCopyRevisionList.length && roHardCopyRevisionList.includes(singleDrawingData?.acceptedArchitectRevisions[selectedTab]?.revision)?
             (
                <>
                  <div>Issued Soft Copy</div>
                  <div>:</div>
                  <div style={{ marginLeft: "-120px" }}>
                    {singleDrawingData.acceptedArchitectRevisions.length &&
                    singleDrawingData.acceptedArchitectRevisions[
                      singleDrawingData.acceptedArchitectRevisions.length - 1
                    ].architectRevisionStatus === "Not Forwarded" ? (
                      <button
                        style={{
                          display: "flex",
                          backgroundColor: "#F3F3F3",
                          border: "1px solid #97979740",
                          color: "#4d4d4d",
                          padding: "6px 10px",
                          alignItems: "center",
                          gap: "10px",
                        }}
                        onClick={() =>
                          handleForwardClick(
                            singleDrawingData,
                            singleDrawingData.acceptedArchitectRevisions
                              ?.length - 1
                          )
                        }
                      >
                        Forward <RiShareForwardLine />
                      </button>
                    ) : (
                      <p>Forwarded</p>
                    )}
                  </div>
                </>
             
              ):""}
              <div>Due / Overdue</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                <span
                  style={{
                    color:
                      singleDrawingData &&
                      singleDrawingData.acceptedArchitectRevisions &&
                      singleDrawingData.acceptedArchitectRevisions.length > 0
                        ? calculateDueDays(
                            singleDrawingData.acceptedSiteSubmissionDate,
                            singleDrawingData.acceptedArchitectRevisions[0]
                              ?.softCopySubmittedDate
                          ) >= 0
                          ? "green"
                          : "red"
                        : "black",
                  }}
                >
                  {singleDrawingData &&
                  singleDrawingData.acceptedArchitectRevisions &&
                  singleDrawingData.acceptedArchitectRevisions.length > 0
                    ? calculateDueDays(
                        singleDrawingData.acceptedSiteSubmissionDate,
                        singleDrawingData.acceptedArchitectRevisions[0]
                          ?.softCopySubmittedDate
                      ) === "-"
                      ? "NA"
                      : calculateDueDays(
                          singleDrawingData.acceptedSiteSubmissionDate,
                          singleDrawingData.acceptedArchitectRevisions[0]
                            ?.softCopySubmittedDate
                        ) >= 0
                      ? `${calculateDueDays(
                          singleDrawingData.acceptedSiteSubmissionDate,
                          singleDrawingData.acceptedArchitectRevisions[0]
                            ?.softCopySubmittedDate
                        )} days due`
                      : `${Math.abs(
                          calculateDueDays(
                            singleDrawingData.acceptedSiteSubmissionDate,
                            singleDrawingData.acceptedArchitectRevisions[0]
                              ?.softCopySubmittedDate
                          )
                        )} days overdue`
                    : "-"}
                </span>
              </div>
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
                singleDrawingData?.acceptedArchitectRevisions[selectedTab]
                  ?.changes && (
                  <>
                    <div>Note For Revision</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData.acceptedArchitectRevisions[selectedTab]
                        .changes || "NA"}
                    </div>
                  </>
                )}

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
                singleDrawingData?.acceptedArchitectRevisions[selectedTab]
                  ?.issuesInRevision?.length > 0 && (
                  <>
                    <div>Issues In Revision</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData.acceptedArchitectRevisions[
                        selectedTab
                      ].issuesInRevision.join(", ") || "NA"}
                    </div>
                  </>
                )}
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Drawing File</div>
                      <div>:</div>
                      <div style={{ display: "flex", marginLeft: "-120px" }}>
                        <img src={Gallery}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              singleDrawingData,
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].revision,
                              
                            )
                          }
                        />
                        <img
                          src={Download}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownload(
                              singleDrawingData,
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].revision,
                              
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>PDF File</div>
                      <div>:</div>
                      <div style={{ display: "flex", marginLeft: "-120px" }}>
                        <img 
                        src={Gallery}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewPDFFile(
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ]?.pdfDrawingFileName
                            )
                          }
                        />
                        <img
                          src={Download}
                          size={20}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handlePDFDownload(
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ]?.pdfDrawingFileName
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>
      {currentView !== "received" &&<ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue}/>}
    </>
  );
};

export default ApprovedTable;