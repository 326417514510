/* eslint-disable react/no-children-prop */
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { Button_COLOUR } from "../../../Utilities/Constants";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { siteId, userInfo } from "../../../atoms";
import { RxCross1 } from "react-icons/rx";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import TableWrapper from "../../../Utilities/TableWrapper";
import { processFile } from "../BackendUtils";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { IoDownloadOutline, IoImageOutline } from "react-icons/io5";
import "./ROtoSiteLevel.css";
import { HiPlusCircle } from "react-icons/hi";
import { FaCircleMinus } from "react-icons/fa6";
import RFIEditReopenDialog from "./RFIEditReopenDialog";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import { FaFilter } from "react-icons/fa";
import Pen from "../SITEHEAD/IconsSiteHead/penIcon.svg";
import Eye from "../SITEHEAD/IconsSiteHead/Eye.svg";
import Download from "../SITEHEAD/IconsSiteHead/Download.svg"
import Gallery from "../../../Images/Gallery.svg";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { canShowFolder } from "../ShowFolder";
import ConsultantFilter from "../ConsultantFilter";
import ArrowToggle from "../ArrowToggle";
import SortByPopup from "../SortByPopup";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", // Optional
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", // Removes the row borders
          },
        },
      },
    },
  },
});
const RequestedTable = (rfiRaisedAccess) => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  let webApi = new WebApimanager();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState("requested");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedDrawingId, setSelectedDrawingId] = useState(null);
  const [site, setSite] = useRecoilState(siteId);
  const [tableRowData, setTableRowData] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [rowData,setRowData] = useState({})
  const [currentUserInfo] = useRecoilState(userInfo);
  const [consultantValue, setConsultantValue] = useState('')
  const [formData, setFormData] = useState({
    issuedSoftCopy: "",
    issuedHardCopy: "",
    submittedDate: todayDate(),
    reason: "",
    drawingFileName: null,
    response: "",
  });
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [searchValue, setSearchValue] = useState('')

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [currentTab, setCurrentTab] = useState("");
  const [currentCloseTab, setCurrentCloseTab] = useState("");

  const [filters, setFilters] = useState({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
  });

  // sort by start
  const [sortField, setSortField] = useState(""); // Field to sort by
  const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
  const [activeSortPopup, setActiveSortPopup] = useState(null);

  const handleSortClick = (field) => {
    setFilters({
      drawingNo: "",
      consultant: "",
      category: "",
      drawingTitle: "",
      scheduledDate: "",
      submissionDate: "",
      revision: "",
    });
    setSearchValue("");
    setActiveSortPopup((prev) => (prev === field ? null : field));
  };

  const handleSortSelection = (order) => {
    if (activeSortPopup) {
      setSortField(activeSortPopup);
      setSortOrder(order);
      setActiveSortPopup(null); // Close the popup after selection
    }
  };

  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
    
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    })
    setSortField("");
    setSortOrder("");
    setActiveSortPopup(null);
  }, []);
  
  const handleFilterChange = (column, value) => {
    setSearchValue("")
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
    setFilters((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = ""; 
          return acc;
      }, {}),
      [column]: value, 
  }));
}


  const handleEditPopup = () => {
    setShowEditPopup(true);
    setViewDialogOpen(false);
  };

  const validateForm = (formData, currentTab) => {
    let errors = {};
    if (currentTab === "accepted") {
    } else if (currentTab === "submitted") {
      if (!formData.issuedSoftCopy)
        errors.issuedSoftCopy = "This field is required.";
      if (!formData.receivedHardCopy)
        errors.receivedHardCopy = "This field is required.";
      if (!formData.drawingFileName)
        errors.drawingFileName = "File upload is required.";
      if (!formData.submittedDate)
        errors.submittedDate = "submitted date is required";
    } else if (currentTab === "reject") {
      if (!formData.reason) errors.reason = "This field is required.";
      if (!formData.drawingFileName)
        errors.drawingFileName = "File upload is required.";
    }
    return errors;
  };

  const handleFormSubmit = async () => {
    const errors = validateForm(formData, currentTab);
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    // Perform API call based on the active tab
    if (currentTab === "accepted") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitAaccept(selectedDrawingId);
    }
    if (currentTab === "submitted") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitApproval(selectedDrawingId);
    } else if (currentTab === "reject") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReject(selectedDrawingId);
    }

    // Handle successful submission or any additional logic
  };

  const submitAaccept = async () => {
    console.log("aksdka")
    let ApiFlag=false
    try {

      // const response = await webApi.put(
      //   `api/roToSiteLevelRequested/accept/${drawingItemId}`
      // );
      console.log(rowData,"rowData")
      const payload={
        drawingId: rowData?.drawingId?._id,
        drawingNo: rowData.drawingNo,
        siteId: site,
        requestedDate: rowData.requestedDate,
        expectedDate: rowData.expectedDate,
        revision: rowData.architectRevision,
        natureOfRequestedInformation: rowData.natureOfRequestedInformation,
        
        natureOfRequestedInformationReasons: rowData.natureOfRequestedInformationReasons.map(({ natureOfRequest, reason }) => ({
            natureOfRequest,
            reason,
          })),
        drawingFileName:  rowData.drawingFileName,
       rfiType: "Forwarded" ,
roRfiId:rowData._id,
      }
      if(rowData.status === "ReOpened" && rowData.note === "Architect RFI created"){
        const postResponse = await webApi.put(`api/architectureToRoRequested/reOpen/${rowData?.architectRfiId}`,payload)
        if (postResponse.status === 200) ApiFlag=true
      }else if(rowData.status === "Requested" && rowData.note === "Architect RFI created"){
        const postResponse = await webApi.put(`api/architectureToRoRequested/reOpen/${rowData?.architectRfiId}`,payload)
        if (postResponse.status === 200) ApiFlag=true
      }
      else if(rowData.status === "ReOpened" && rowData.note === "Architect RFI not created"){
        const postResponse = await webApi.post("api/architectureToRoRequested/postRequest",payload);
        if (postResponse.status === 200) ApiFlag=true
      } else if(rowData.status === "ReOpened"){
        const postResponse = await webApi.put(`api/architectureToRoRequested/reOpen/${rowData?.architectRfiId}`,payload)
        if (postResponse.status === 200) ApiFlag=true
      }
      else{
          
            const postResponse = await webApi.post(
              "api/architectureToRoRequested/postRequest",
              payload
            );
            if(postResponse.status === 200) ApiFlag = true
          }
      if(ApiFlag){
      setApiProcessing({ loader: false, message: "" });
      handleEditClose();
      // fetchData();
      window.location.reload();
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);
    }
  };
  const submitApproval = async (drawingItemId) => {
    try {
      const ApprovalFormData = {
        remarks: formData.remarks,
        issuedSoftCopy: formData.issuedSoftCopy,
        receivedHardCopy: formData.receivedHardCopy,
        submittedDate: todayDate(),
      };

      const response = await webApi.put(
        `api/architectureToRoRequested/updateRevision/${drawingItemId}`,
        ApprovalFormData
      );
      const id = response.data.data._id;

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("drawingFileName", formData.drawingFileName);
        const uploadResponse = await webApi.imagePut(
          `api/roToSiteLevelRequested/drawing/${drawingItemId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleEditClose();
      fetchData();
      window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const submitReject = async (drawingItemId) => {
    try {
      const RejectFormData = {
        reason: formData.reason,
        siteId:site,
      };

      const response = await webApi.put(
        `api/roToSiteLevelRequested/reject/${drawingItemId}`,
        RejectFormData
      );
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("rejectedFile", formData.drawingFileName);
        const uploadResponse = await webApi.imagePut(
          `api/roToSiteLevelRequested/rejectFile/${drawingItemId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleEditClose();
      fetchData();
      window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };

  const handleConfirmDialogOpen = (drawingItem) => {
    console.log(drawingItem,"drawingItem")
    setSelectedItem(drawingItem);
    setSelectedDrawingId(drawingItem._id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
    setEditData({
      drawingId: "",
      drawingNumber: "",
      expectedDate: "",
      requestedDate: "",
      drawingFileName: null,
      revision: "",
      natureofrequestedInfo: "",
    });
    setCurrentCloseTab("");
    setNatureOfRequestedInformationReasons( { natureOfRequest: '', reason: '' } );
  };
  const [errors, setErrors] = useState({});
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editData, setEditData] = useState({
    drawingId: "",
    drawingNumber: "",
    expectedDate: "",
    requestedDate: "",
    drawingFileName: null,
    revision: "",
    natureofrequestedInfo: "",
  });
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];
const [natureOfRequestedInformationReasons, setNatureOfRequestedInformationReasons] = useState([
  { natureOfRequest: '', reason: '' } // Initialize with an object containing a reason array
]);
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRevision, setSelectedRevision] = useState("");
  const handleRevisionSelection = (event) => {
        setSelectedRevision(event.target.value);
      };
  const handleAddReason = () => {
        setNatureOfRequestedInformationReasons((prev) => [...prev, { natureOfRequest: '', reason: '' }]);
      };
      
  const handleRemoveReason = (index) => {
        setNatureOfRequestedInformationReasons((prev) =>
          prev.filter((_, i) => i !== index)
        );
      };

  const handleReasonChange = (e, index) => {
        const { name, value } = e.target;
      
  setNatureOfRequestedInformationReasons((prev) => {
          const updatedReasons = [...prev];
      
          if (!updatedReasons[index]) {
            console.warn(`No entry found for index: ${index}`);
            return prev;
          }
      
          // Update either 'natureOfRequest' or 'reason' based on the input name
          updatedReasons[index][name] = value;
      
          return updatedReasons;
        });
      };
  const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === "file") {
          setEditData({
            ...editData,
            [name]: files[0],
          });
        } else {
          setEditData({
            ...editData,
            [name]: value,
          });
        }
      };
      const listItems = [
        { id: "A - Missing Information", name: "A - Missing Information" },
        { id: "B - Clarification", name: "B - Clarification" },
        { id: "C - Additional Information", name: "C - Additional Information" },
        { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
      ];
    
      // Function to get the name from id
      const getNameFromId = (id) => {
        const item = listItems.find((item) => item.id === id);
        return item ? item.name : "";
      };
      useEffect(() => {
        if (selectedItem?.natureOfRequestedInformationReasons && confirmDialogOpen) {
          setNatureOfRequestedInformationReasons(selectedItem.natureOfRequestedInformationReasons);
        }
      }, [selectedItem,confirmDialogOpen]);
      
      useEffect(()=>{
        if(selectedItem && confirmDialogOpen){
            console.log(selectedItem,"checking selctedItem data")
             const latestRevision = `${parseInt(selectedItem.revision.slice(1)) + 1}`
            setEditData({
                drawingId: selectedItem.drawingId?._id,
                drawingNumber: selectedItem.drawingNo,
                expectedDate: selectedItem.expectedDate?.slice(0,10),
                requestedDate: selectedItem.requestedDate?.slice(0,10),
                drawingFileName: selectedItem.drawingFileName,
                revision: `R${latestRevision}`,
                natureofrequestedInfo: selectedItem?.natureOfRequestedInformation,
            })
            setSelectedRevision(`R${latestRevision}`);
            
            
        }
      },[selectedItem,confirmDialogOpen])
     
    

  const handleConfirmSubmit = async (view,e) => {
    e.preventDefault();
    console.log(currentCloseTab,selectedDrawingId,"checking handleConfirmSubmit")
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    
if(view === 'yes') {
    if (selectedDrawingId) {
      webApi
        .put(`api/architectureToRoRequested/closed/${selectedDrawingId}`)
        .then((response) => {
          const data = response.data;
          setApiProcessing({ loader: false, message: "" });
          setConfirmDialogOpen(false);
          fetchData();
          window.location.reload()
        })
        .catch((error) => {
          setApiProcessing({ loader: false, message: "" });
          console.error("There was an error submitting the form!", error);
        });
    }
  }else{
    try {
      webApi.put(`api/architectureToRoRequested/closed/${selectedDrawingId}`)
      const editFormData = {
        drawingId: editData.drawingId,
        drawingNo: editData.drawingNumber,
        siteId: site,
        requestedDate: todayDate(),
        expectedDate: editData.expectedDate,
        revision: selectedRevision,
        natureOfRequestedInformation: editData.natureofrequestedInfo,
        natureOfRequestedInformationReasons: editData.natureofrequestedInfo.map((info) => {
          const filteredReasons = natureOfRequestedInformationReasons.filter((req) => req.natureOfRequest === info);
          return filteredReasons.map((req) => ({
            natureOfRequest: info,
            reason: req.reason,
          }));
          })
          .flat(),
      };
console.log(editFormData,"checking data")
      const response = await webApi.post(
        "api/architectureToRoRequested/postRequest",
        editFormData
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.status === "error") {
          
          setErrorMessage(responseData.message);
          console.log("Response data:", responseData.message);
          setApiProcessing({ loader: false, message: "" });
          return; 
        }

        const id = response.data.data._id;

        if (editData.drawingFileName) {
          const drawingFileName = new FormData();
          drawingFileName.append("drawingFileName", editData.drawingFileName);
          const uploadResponse = await webApi.imagePut(
            `api/architectureToRoRequested/${id}`,
            drawingFileName
          );
          console.log("Upload response:", uploadResponse);
        }
        setApiProcessing({ loader: false, message: "" });
        handleEditClose();
        window.location.reload();
        // fetchData();
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
          console.log("Response data:", response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  }
  };
 

 

  const handleEditOpen = (drawingItem) => {
    console.log(drawingItem,"drawingItem")
    setRowData(drawingItem)
    setSelectedItem(drawingItem);
    setSelectedDrawingId(drawingItem._id);
    setEditDialogOpen(true);
    setErrors({});
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setFormData({});
    fetchData();
    setCurrentTab("");
  };
const [combinedData, setCombinedData] = useState([]);
const fetchData = async () => {
  try {
    let response = await webApi.get(
      `api/architect/rfiRo?siteId=${site}`
    );
    const { architectureRfiData = [], roRfiData = [] } = response.data.data || {};
    const combined = [
      ...architectureRfiData.map((item) => ({ ...item, source: "Architect" })),
      ...roRfiData.map((item) => ({ ...item, source: "RO" })),
    ];
    setDrawingList(response.data.data);
    setCombinedData(combined);
  } catch (error) {
    console.log(error.message);
  }
};

useEffect(() => {
  if (site) {
    fetchData();
  }
}, [site]);
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0], 
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleViewFile = async (id, status,rfiType,selectedRowData,revision) => {
    console.log(rfiType,"rfiType",selectedRowData,"selectedRowData")
    setApiProcessing({
      loader: true,
      message: "Viewing...",
    });
    try {
      if(selectedRowData === "architect"){
      if(rfiType === "Forwarded"){
        console.log(selectedRowData?.roRfiId ,"selectedRowData?.roRfiId")
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/roToSiteLevelRequested/rejectFile/${id}`
          : `api/roToSiteLevelRequested/request/${selectedRowData?.roRfiId}`,
        {
          responseType: "arraybuffer", 
        }
      );
      processFile(response, "view", navigate,revision,"","RFI"); 
    }
    if(rfiType === "Created"){
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/architectureToRoRequested/rejectFile/${id}`
          : `api/architectureToRoRequested/request/${id}`,
        {
          responseType: "arraybuffer", 
        }
      );
      processFile(response, "view", navigate); 
    }
  } else {
    const response = await webApi.getImage(
      status === "Rejected"
        ? `api/roToSiteLevelRequested/rejectFile/${id}`
        : `api/roToSiteLevelRequested/request/${id}`,
      {
        responseType: "arraybuffer", 
      }
    );
    processFile(response, "view", navigate);
  }

    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleDownload = async (id, status,rfiType,singleRowData) => {
    console.log(rfiType,"rfiType")
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      if(singleRowData === "architect"){
        console.log(singleRowData.roRfiId,"roRfiId")
      if(rfiType === "Forwarded"){
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/roToSiteLevelRequested/rejectFile/${id}`
          : `api/roToSiteLevelRequested/request/${singleRowData?.roRfiId}`,
        {
          responseType: "arraybuffer", 
        }
      );
      processFile(response, "download", navigate); 
    }
    if(rfiType === "Created"){
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/architectureToRoRequested/rejectFile/${id}`
          : `api/architectureToRoRequested/request/${id}`,
        {
          responseType: "arraybuffer", 
        }
      );
      processFile(response, "download", navigate); 
    }
  } else {
    const response = await webApi.getImage(
      status === "Rejected"
        ? `api/roToSiteLevelRequested/rejectFile/${id}`
        : `api/roToSiteLevelRequested/request/${id}`,
      {
        responseType: "arraybuffer", 
      }
    );
    processFile(response, "download", navigate);
  }

    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const handleViewOpen = (drawingItem) => {
    setTableRowData(drawingItem);
   console.log("drawingItem data",drawingItem)
    setSingleDrawingData(drawingItem);
    setViewDialogOpen(true);
    // if (
    //   drawingItem.for === "architect" &&
    //   (drawingItem.status === "Requested" || drawingItem.status === "Rejected")
    // ) {
    //   setTableRowData(drawingItem);
    // }
  };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const uniqueConsultant = useMemo(() => {
    const consultant = combinedData.map((item) => item?.drawingId?.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [combinedData]);

  const truncateText = (text, maxWords = 2) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > maxWords
      ? `${words.slice(0, maxWords).join(" ")}...`
      : text;
  };

  const latestRevisionedit =
    selectedItem?.drawingId?.acceptedRORevisions?.length > 0
      ? selectedItem.drawingId.acceptedRORevisions[
          selectedItem.drawingId.acceptedRORevisions.length - 1
        ]
      : null;

  const latestRevision =
    singleDrawingData?.drawingId?.acceptedRORevisions?.length > 0
      ? singleDrawingData.drawingId.acceptedRORevisions[
          singleDrawingData.drawingId.acceptedRORevisions.length - 1
        ]
      : null;

  console.log(singleDrawingData, "RO RFI DATA");

  const searchResult = (e) => {
    const searchValue = e.target.value ? e.target.value.toLowerCase() : "";
    setSearchValue(e.target.value)
  };
  const getString = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
    if (typeof value === "object") {
      return value.toString && typeof value.toString === "function"
        ? value.toString().toLowerCase()
        : "";
    }
    return typeof value === "string" ? value.toLowerCase() : "";
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const handleStatusChange = (status) => {
    if (status === "All") {
      // Ensure status is not undefined
      setSelectedStatus("");
      // Handle filter logic here
    } else {
      setSelectedStatus(status)
    }
      setShowDropdown(false);

  };

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clear the timeout if user clicks outside
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const calculateDueDays = (startDate, endDate) => {
    if (!startDate || !endDate) return "-";

    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDiff = end - start; 
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); 

    return dayDiff; 
  };

  const statusOrder = ["Requested", "Rejected", "Accepted", "Closed"];
  const sortedDrawingList = (Array.isArray(drawingList) ? [...drawingList] : [])
    .filter(
      (item) => item && item.drawingId && item.drawingId.designDrawingConsultant
    )
    .sort((a, b) => {
      // Check if selectedStatus is valid
      if (selectedStatus && statusOrder.includes(selectedStatus)) {
        if (a.status === selectedStatus && b.status !== selectedStatus)
          return -1;
        if (a.status !== selectedStatus && b.status === selectedStatus)
          return 1;
      }
      const statusComparison =
        statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
      if (statusComparison !== 0) return statusComparison;

      const consultantA = a.drawingId?.category?.category.toLowerCase() || "";
      const consultantB = b.drawingId?.category?.category.toLowerCase() || "";
      return consultantA.localeCompare(consultantB);
    });
    const [showAddFolder, setShowAddFolder] = useState(false);
    useEffect(() => {
      const showFolder = canShowFolder(currentUserInfo, site);
      setShowAddFolder(showFolder);
    }, [currentUserInfo, site]);

    const updatedRows = combinedData.filter((item)=>item.drawingId?.designDrawingConsultant?.role.includes(consultantValue)).map((row, index) => {
      // Calculate due/overdue
      const calculateDueDays = (currentDate, expectedDate) => {
        if (!expectedDate) return "-";
        const startDate = new Date(currentDate);
        const endDate = new Date(expectedDate);
        return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
      };
  
      const dueDays = calculateDueDays(new Date(), row.expectedDate);
      const dueOverdue = {
        text: dueDays === "-"
          ? "-"
          : dueDays >= 0
          ? `${dueDays} days due`
          : `${Math.abs(dueDays)} days overdue`,
        color: dueDays === "-"
          ? "black"
          : dueDays >= 0
          ? "green"
          : "red",
      };
  
      return {
        ...row,
        serialNo: index + 1,
        drawingNo: row.drawingNo,
        designConsultant: row?.drawingId?.designDrawingConsultant?.role || "-",
        drawingCategory: row.drawingId?.category?.category || "-",
        drawingTitle: row?.drawingId?.drawingTitle || "-",
        requestedDate: formatDate(row.requestedDate) || "-",
        expectedDate: formatDate(row.expectedDate) || "-",
        revision: row.revision || "-",
        from: row?.for === "ro" ? "SiteHead" : "RO",
        status: row.status || "-",
        dueOverdue, 
      };
    });
  
    const filteredRows = updatedRows.filter((row) => {
      const searchTermLower = searchValue.toLowerCase();
      return (
        row.drawingNo?.toLowerCase().includes(searchTermLower) ||
        row?.drawingId?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
        row.drawingId?.category?.category?.toLowerCase().includes(searchTermLower) ||
        row.drawingId?.drawingTitle?.toLowerCase().includes(searchTermLower)
      );
    });

    const columns = useMemo(
      () => [
        {
          field: "serialNo",
          headerName: "S.No",
          flex: 0.5,
          filterable: false,
          sortable: false,
        },
        {
          field: "drawingNo",
          headerName: "Drawing No",
          flex: 1,
          filterable: true,
          sortable: true,
        },
        {
          field: "designConsultant",
          headerName: "Consultant",
          flex: 1,
          filterable: true,
          sortable: true,
        },
        {
          field: "drawingCategory",
          headerName: "Drawing Category",
          flex: 1.5,
          filterable: true,
          sortable: true,
          
        },
        {
          field: "drawingTitle",
          headerName: "Drawing Title",
          flex: 2,
          filterable: true,
          sortable: true,
          
        },
        {
          field: "requestedDate",
          headerName: "Requested Date",
          flex: 1,
          filterable: true,
          sortable: true,
        },
        {
          field: "expectedDate",
          headerName: "Expected Date",
          flex: 1,
          filterable: true,
          sortable: true,
        },
        {
          field: "dueOverdue",
          headerName: "Due/Overdue",
          flex: 1,
          filterable: false,
          sortable: false,
          renderCell: (params) => {
            const dueOverdue = params.row.dueOverdue || { text: "-", color: "black" };
            return (
              <span style={{ color: dueOverdue.color }}>
                {dueOverdue.text}
              </span>
            );
          },
        },
        {
          field: "revision",
          headerName: "Revision",
          flex: 1,
          filterable: true,
          sortable: true,
          
        },
        {
          field: "from",
          headerName: "From",
          flex: 1,
          filterable: true,
          sortable: true,
        },
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          filterable: true,
          sortable: true,
        },
        {
          field: "action",
          headerName: "Action",
          flex: 1,
          filterable: false,
          sortable: false,
          renderCell: (params) => {
            const drawingItem = params.row;
        
            // Determine what status the drawing item has
            const isRo = drawingItem?.for === "ro";
            const isRequested = drawingItem.status === "Requested";
            const isReopened = drawingItem.status === "ReOpened";
            const isAccepted = drawingItem.status === "Accepted";
        
            // Action rendering
            return (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: "18px",
                }}
              >
                {isRo ? (
                  <>
                    {isRequested || isReopened ? (
                      <>
                        {rfiRaisedAccess && (
                          <img
                            src={Pen}
                            size={20}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleEditOpen(drawingItem)}
                          />
                        )}
                        <img
                          src={Eye}
                          size={20}
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleViewOpen(drawingItem, drawingItem?.for)
                          }
                        />
                        <img
                          src={Download}
                          size={20}
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleDownload(drawingItem?._id, "", drawingItem?.rfiType, drawingItem?.for)
                          }
                        />
                      </>
                    ) : (
                      <img
                        src={Eye}
                        size={20}
                        style={{
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleViewOpen(drawingItem)}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {isRequested ? (
                      <>
                        {rfiRaisedAccess && (
                          <img
                            src={Eye}
                            size={20}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedStatus(drawingItem.status);
                              handleViewOpen(drawingItem);
                            }}
                          />
                        )}
                      </>
                    ) : isAccepted ? (
                      <>
                        {rfiRaisedAccess && (
                          <img
                            src={Pen}
                            size={20}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleConfirmDialogOpen(drawingItem)
                            }
                          />
                        )}
                        <img
                          src={Eye}
                          size={20}
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedStatus(drawingItem.status);
                            handleViewOpen(drawingItem);
                          }}
                        />
                        <img
                          src={Download}
                          size={20}
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDownload(drawingItem?._id, "", drawingItem?.rfiType, drawingItem?.for)}
                        />
                      </>
                    ) : (
                      <img
                        src={Eye}
                        size={20}
                        style={{
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedStatus(drawingItem.status);
                          handleViewOpen(drawingItem, currentView);
                        }}
                      />
                    )}
                  </>
                )}
              </span>
            );
          },
        }
        
      ],
      [combinedData]
    );
    
  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>RFI Drawing Lists</p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    position: "relative",
                    width: "280px",
                    paddingLeft: "5px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder="Search Drawings"
                    onChange={handleSearchChange}
                    style={{
                      paddingLeft: "20px",
                      border: "1px solid var(--primary-color)",
                      width: "240px",
                      height: "40px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "35px",
                      top: "40%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />
                </div>
                {/* <div style={{ position: "relative" }}>
                <FaFilter
                          size={40}
                          style={{
                            border: "1px solid var(--primary-color)",
                            padding: "5px",
                            marginLeft:'10px'
                          }}
                          onClick={() => setShowDropdown(!showDropdown)}
                        />
                  <div style={{ display: "inline-block" }}>
                    <button
                      onClick={() => setShowDropdown(!showDropdown)}
                      style={{
                        padding: "10px",
                        border: "1px solid var(--primary-color)",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        cursor: "pointer",
                        color: "var(--bs-dropdown-link-color)",
                        display: "none",
                      }}
                    >
                      {selectedStatus}
                    </button>
                    {showDropdown && (
                      <div
                        ref={dropdownRef}
                        style={{
                          position: "absolute",
                          top: "40px", 
                          right: "0",
                          borderRadius: "6px",
                          padding: "5px",
                          zIndex: 1000,
                          width: "150px",
                          background: "white",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", 
                        }}
                      >
                        {[
                          "All",
                          "Requested",
                          "Rejected",
                          "Accepted",
                          "Closed",
                        ].map((status) => (
                          <button
                            key={status}
                            onClick={() => handleStatusChange(status)}
                            style={{
                              display: "block",
                              width: "100%",
                              padding: "10px",
                              border: "none",
                              background: "white",
                              textAlign: "left",
                              color:
                                selectedStatus === status
                                  ? "var(--primary-color)"
                                  : "var(--bs-dropdown-link-color)",
                            }}
                            className="DrawingFilterStatus"
                          >
                            {status}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          
              <div style={{ marginTop: "10px" }}>
                                <Box
                                  className="dataGridContainer"
                                >
                                  <ThemeProvider theme={theme}>
                                  <DataGrid
                                    columns={columns}
                                    rows={filteredRows}
                                    getRowId={(row) => row._id}
                                    rowHeight={60}
                                    disableColumnMenu={false} // Keep the column menu for filtering
                                    pagination={false}
                                    hideFooter={true}
                                    getRowSpacing={params=>({
                                      top:params.isFirstVisible ? 0 : 5,
                                      bottom:params.isLastVisible ? 0 : 5
                                    })}
                                    
                                    getRowClassName={(params) =>
                                      "customHeader" 
                                    }
                                    
                                  />
                                  </ThemeProvider>
                                </Box>
                              </div>
           
          </>
        }
      />
      {showEditPopup && (
        <RFIEditReopenDialog
          showEditPopup={showEditPopup}
          setShowEditPopup={setShowEditPopup}
          tableRowData={tableRowData}
          currentView={currentView}
        />
      )}
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            fontFamily: "Poppins",
            borderRadius: "10px",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <p
            style={{ marginTop:"15px",width:"100%", textAlign: "center", color: "white" }}
          >
            Approval/Reject Request
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleEditClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {selectedItem && selectedItem.status !== "Accepted" && (
              <button
                onClick={() => {
                  setCurrentTab("accepted");
                }}
                style={{
                  backgroundColor:
                    currentTab === "accepted"
                      ? "var(--primary-color)"
                      : "transparent",
                  color:
                    currentTab === "accepted"
                      ? "white"
                      : "var(--primary-color)",
                      width: "max-content",
                      height: "35px",
                  paddingInline:"6px",
                  cursor: "pointer",
                  borderRight: "1px solid var(--primary-color)",
                }}
              >
                Forward to Consultant
              </button>
            )}
            <button
              onClick={() => setCurrentTab("reject")}
              style={{
                background:
                  currentTab === "reject"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "reject" ? "white" : "var(--primary-color)",
                width: "100px",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Reject
            </button>
          </div>
        </div>

        {currentTab === "accepted" && (
          <DialogContent
            style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
          >
            <>
              {" "}
              <Loader
                open={apiProcessing.loader}
                message={apiProcessing.message}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  rowGap: "20px",
                  columnGap: "10px",
                  padding: "0 30px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <div>Request Reason</div>
                <div>:</div>
                <div>
                  {selectedItem?.natureOfRequestedInformationReasons?.length ? selectedItem.natureOfRequestedInformationReasons.map((reason,index)=>(
                    <div key={index} style={{ display: "inline" }}>
                              {reason?.reason}
                              <br />
                            </div>
                  )):""}          </div> */}

                <div>Nature Of Requested Information Reasons</div>
                <div>:</div>
                <div>
                  {selectedItem
                    ? selectedItem?.natureOfRequestedInformationReasons &&
                      selectedItem?.natureOfRequestedInformationReasons?.length > 0
                      ? selectedItem?.natureOfRequestedInformationReasons?.map(
                          (item, index) => (
                            <div key={index} style={{ display: "inline" }}>
                              {item?.natureOfRequest} - {item?.reason}
                              
                            </div>
                          )
                        )
                      : "-"
                    : "-"}
                </div>
                <div>Revision</div>
                <div>:</div>
                <div>
                  {selectedItem ? selectedItem?.architectRevision : "-"}
                </div>

                <div>Requested Date</div>
                <div>:</div>
                <div>
                  {selectedItem?.requestedDate
                    ? formatDate(selectedItem.requestedDate)
                    : "-"}
                </div>

                <div>Expected Date</div>
                <div>:</div>
                <div>
                  {selectedItem?.expectedDate
                    ? formatDate(selectedItem.expectedDate)
                    : "-"}
                </div>
              </div>
              <div style={{ padding: "0 30px" }}>
                {/* <div style={{flex:'1'}}>
                <label htmlFor="response" className="fs-10 fw-bold text-start py-2">
                   Response
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  name="response"
                  value={formData.response}
                  onChange={handleChange}
                  placeholder="Enter your Response"
                  className="form-control fs-12"
                  style={{ width: '100%' }}
                />
                {errors.response && <span style={{ color: 'red', fontSize: '12px' }}>{errors.response}</span>}
                </div> */}
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={handleFormSubmit}
                    style={{
                      background: "var(--primary-color)",
                      color: "white",
                      width: "100px",
                      height: "35px",
                      border: "none",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          </DialogContent>
        )}

        {/* {currentTab === 'submitted' && (
            <DialogContent style={{ padding: '25px', minWidth: '300px', minHeight: '300px' }}>
            <>  <Loader open={apiProcessing.loader} message={apiProcessing.message} />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <div>
                <div>
                  <label htmlFor="issuedSoftCopy" className="fs-10 fw-bold text-start py-2">
                    Issued Soft Copy
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <select
                    name="issuedSoftCopy"
                    value={formData.issuedSoftCopy}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: '100%' }}
                  >
                    <option value="">Select Yes/No</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errors.issuedSoftCopy && (
                    <p style={{ color: 'red', fontSize: '12px' }}>{errors.issuedSoftCopy}</p>
                  )}
                </div>
                <div>
                  <label htmlFor="receivedHardCopy" className="fs-10 fw-bold text-start py-2">
                    Received Hard Copy
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <select
                    name="receivedHardCopy"
                    value={formData.receivedHardCopy}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: '100%' }}
                  >
                    <option value="">Select Yes/No</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errors.receivedHardCopy && (
                    <p style={{ color: 'red', fontSize: '12px' }}>{errors.receivedHardCopy}</p>
                  )}
                </div>
                </div> */}
        {/* <div>
                <label htmlFor="remarks" className="fs-10 fw-bold text-start py-2">
                  Enter your Remarks
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  placeholder="Enter your Remarks"
                  className="form-control fs-12"
                  style={{ width: '100%' }}
                />
                {errors.remarks && <span style={{ color: 'red', fontSize: '12px' }}>{errors.remarks}</span>}
              </div> */}
        {/* <div >
          <label htmlFor="drawingFileName" className="fs-10 fw-bold text-start py-2">
            Upload Your File
            <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="file"
            accept=".dwg"
            name="drawingFileName"
            onChange={handleChange}
            className="form-control fs-12"
            style={{ width: '100%' }}
          />
          {errors.drawingFileName && (
            <span style={{ color: 'red', fontSize: '12px' }}>{errors.drawingFileName}</span>
          )}
        </div>
        <div
          style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
              <button
            onClick={handleFormSubmit}
            style={{
              background: 'var(--primary-color)',
              color: 'white',
              width: '100px',
              height: '35px',
              border: 'none',
              borderRadius: '5px'
            }}
          >
            Submit
          </button>
          </div>
              </div>
              </>
            </DialogContent>
          )}
       */}

        {currentTab === "reject" && (
          <DialogContent
            style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
          >
            <>
              <Loader
                open={apiProcessing.loader}
                message={apiProcessing.message}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  rowGap: "20px",
                  columnGap: "10px",
                  padding: "0 30px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <div>Request Reason</div>
                <div>:</div>
                <div>{selectedItem ? selectedItem.remarks : "-"}</div> */}

                <div>Nature Of Requested Information Reasons</div>
                <div>:</div>
                <div>
                  {selectedItem
                    ? selectedItem?.natureOfRequestedInformationReasons
                    &&
                      selectedItem?.natureOfRequestedInformationReasons
                      ?.length > 0
                      ? selectedItem?.natureOfRequestedInformationReasons
                      .map(
                          (item, index) => (
                            <div key={index} style={{ display: "inline" }}>
                              {item?.natureOfRequest} -  {item?.reason}
                              
                            </div>
                          )
                        )
                      : "-"
                    : "-"}
                </div>
              </div>
              <div style={{ padding: "10px 30px" }}>
                <div style={{ flex: "1" }}>
                  <label
                    htmlFor="reason"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Enter your Reason
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                    placeholder="Could you please provide the reason for rejecting this request?"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                  {errors.reason && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.reason}
                    </span>
                  )}
                </div>
                <div style={{ flex: "1" }}>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Upload Your File
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    name="drawingFileName"
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%", height: "43px" }}
                  />
                  {errors.drawingFileName && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingFileName}
                    </span>
                  )}
                </div>
              </div>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={handleFormSubmit}
                    style={{
                      background: "var(--primary-color)",
                      color: "white",
                      width: "100px",
                      height: "35px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          </DialogContent>
        )}
      </Dialog>

   
      <Dialog
         open={confirmDialogOpen} onClose={handleConfirmDialogClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            fontFamily: "Poppins",
            borderRadius: "10px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <p
            style={{width:'100%',marginTop:'15px', textAlign: "center", color: "white" }}
          >
      RFI Close
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleConfirmDialogClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
           
              <button
                onClick={() => {
                  setCurrentCloseTab("yes");
                }}
                style={{
                  backgroundColor:
                    currentCloseTab === "yes"
                      ? "var(--primary-color)"
                      : "transparent",
                  color:
                  currentCloseTab === "yes"
                      ? "white"
                      : "var(--primary-color)",
                      width: "100px",
                      height: "35px",
                  paddingInline:"6px",
                  cursor: "pointer",
                  borderRight: "1px solid var(--primary-color)",
                }}
              >
                Yes
              </button>
        
            <button
              onClick={() => setCurrentCloseTab("no")}
              style={{
                background:
                currentCloseTab === "no"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                currentCloseTab === "no" ? "white" : "var(--primary-color)",
                width: "100px",
                height: "35px",
                cursor: "pointer",
              }}
            >
            No
            </button>
          </div>
        </div>

        {currentCloseTab === 'yes' &&
        <DialogContent style={{ minHeight: "100px" }}>
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <p style={{ textAlign: "center" }}> Are you sure want to close ?</p>
            {currentCloseTab &&
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <button
            style={{
              background: "var(--primary-color)",
              color: "white",
              width: "100px",
              height: "35px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleConfirmDialogClose}
          >
            Cancel
          </button>

                  <button
                     onClick={(e) => handleConfirmSubmit(currentCloseTab,e)}
                    style={{
                      background: "var(--primary-color)",
                      color: "white",
                      width: "100px",
                      height: "35px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Submit
                  </button>
                </div>
}
          </>
        </DialogContent>
        }


       

        {currentCloseTab === "no" && (
        <DialogContent
        style={{ padding: "25px", minWidth: "300px", minHeight: "400px" ,maxHeight: "50vh", overflowY: "auto",}}
      >
        <form >
          <Loader
            open={apiProcessing.loader}
            message={apiProcessing.message}
          />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <div style={{ display: "flex", gap: "15px" }}>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="drawingNumber"
                  className="fs-10  text-start py-2"
                >
                  Drawing Number <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="drawingNumber"
                  placeholder="Enter your Drawing Number"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  value={editData.drawingNumber}
                  onChange={handleInputChange}
                  readOnly
                />
                {error.drawingNumber && (
                  <div className="text-danger">{error.drawingNumber}</div>
                )}
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="requestedDate"
                  className="fs-10  text-start py-2"
                >
                  Requested Date <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="expectedDate"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  value={editData.requestedDate}
                  onChange={handleInputChange}
                  readOnly
                />
                {error.requestedDate && (
                  <div className="text-danger">{error.requestedDate}</div>
                )}
              </div>
            </div>
            <div style={{ display: "flex", gap: "15px" }}>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="expectedDate"
                  className="fs-10  text-start py-2"
                >
                  Expected Date <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="expectedDate"
                  className="form-control fs-12"
                  style={{ width: "100%", height: "45px" }}
                  value={editData.expectedDate}
                  onChange={handleInputChange}
                  min={tomorrowDateString}
                />
                {error.expectedDate && (
                  <div className="text-danger">{error.expectedDate}</div>
                )}
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="revision"
                  className="fs-10  text-start py-2"
                >
                  Revisions
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  className="form-control fs-12"
                  style={{ width: "100%", backgroundColor: "var(--table-evenrow-color)" ,height:"45px"}}
                  value={editData?.revision || ""}
                  onChange={(e) =>
                    handleRevisionSelection({
                      target: {
                        name: "selectedRevision",
                        value: e.target.value,
                      },

                    })
                  }
                  disabled={!editData.revision} 
                >
                  <option value="" disabled>
                    Select Revision
                  </option>
                  {editData.revision && (
                    <option value={editData.revision}>{editData.revision}</option>
                  )}
                </select>
  
                {error.revision && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {error.revision}
                  </p>
                )}
              </div>
            </div>
            <div style={{ display: "flex", gap: "15px" }}>
              <div style={{ flex: 1 }}>
                
                <MultiSelectSearch
                  mandatory={true}
                  heading={"Nature of Requested Information"}
                  listItems={listItems}
                  selectedIds={editData.natureofrequestedInfo || []} // Ensure this is an array
                  setSelectedIds={(ids) =>
                    setEditData({ ...editData, natureofrequestedInfo: ids })
                  }
                  handleSelectedPropertyOption={(data) => {
                    if (editData.natureofrequestedInfo.includes(data.id)) {
                      setEditData({
                        ...editData,
                        natureofrequestedInfo:
                          editData.natureofrequestedInfo.filter(
                            (id) => id !== data.id
                          ),
                      });
                    } else {
                      setEditData({
                        ...editData,
                        natureofrequestedInfo: [
                          ...editData.natureofrequestedInfo,
                          data.id,
                        ],
                      });
                    }
                  }}
                  clearAll={() =>
                    setEditData({ ...editData, natureofrequestedInfo: [] })
                  }
                  addAll={() =>
                    setEditData({
                      ...editData,
                      natureofrequestedInfo: listItems.map((item) => item.id),
                    })
                  }
                />
  
                {/* Display selected fields */}
                <div style={{ marginTop: "20px" }}>
                  <p className="fs-10  text-start py-2">
                    Selected Fields:
                  </p>
                  <ul>
                    {editData?.natureofrequestedInfo?.length > 0 ? (
                      editData?.natureofrequestedInfo.map((id) => (
                        <li key={id}>{getNameFromId(id)}</li>
                      ))
                    ) : (
                      <li>No fields selected</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
      <div style={{ display: "flex", gap: "15px" }}>
    <div style={{ flex: 1 }}>
      <label
        htmlFor="remarks"
        className="fs-10 text-start py-2"
        style={{ display: "flex", alignItems: "center" }}
      >
        Request Reason *
        <span style={{ display: "flex", alignItems: "center" }}>
         
          <HiPlusCircle
            style={{ marginLeft: "20px", color: "#e47331", fontSize: "24px" }}
            onClick={handleAddReason}
          />
        </span>
      </label>
  
      {natureOfRequestedInformationReasons?.length > 0 && natureOfRequestedInformationReasons?.map((info, index) => (
        <div key={info?._id} style={{ marginTop: "30px" }}>
            <div
              key={`${info._id}_${index}`}
              style={{ width: "100%", display: "flex", alignItems: "center", position: "relative", marginBottom: "10px" }}
            >
              <select
                style={{ width: "15%", marginRight: "20px" }}
                name="natureOfRequest"
                value={info.natureOfRequest}
                onChange={(e) => handleReasonChange(e, index)}
              
              >
                <option value="">Select</option>
                {editData?.natureofrequestedInfo?.length > 0 &&
                  editData?.natureofrequestedInfo?.map((requestInfo, reqIndex) => (
                    <option key={reqIndex} value={requestInfo}>
                      {requestInfo}
                    </option>
                  ))}
              </select>
  
              <input
                type="text"
                style={{ width: "82%" }}
                name="reason"
                value={info.reason}
                onChange={(e) => handleReasonChange(e, index)} 
              />
             
                <FaCircleMinus
                  style={{ fontSize: "24px", position: "absolute", right: "-21px" }}
                  onClick={() => handleRemoveReason(index)}
                />
              {error[`natureOfRequest_${index}`] ? (
          <p
            style={{
              color: "red",
              fontSize: "12px",
              position: "absolute",
              top: "50px",
            }}
          >
            {error[`natureOfRequest_${index}`]}
          </p>
        ) : error[`reason_${index}`] ? (
          <p
            style={{
              color: "red",
              fontSize: "12px",
              position: "absolute",
              top: "50px",
              left: "18%",
            }}
          >
            {error[`reason_${index}`]}
          </p>
        ) : null}
            </div>
          
        </div>
      ))}
    </div>
  </div>
            <div>
    <label
      htmlFor="drawingFileName"
      className="fs-10 text-start py-2"
    >
      Upload Your File <span style={{ color: "red" }}>*</span>
    </label>
    
    {/* Display existing file name and button to open it */}
    {editData?.drawingFileName ? (
      <div style={{ display: "flex", justifyContent:'space-between', marginBottom: "10px" }}>
        <span className="fs-12">
        {editData.drawingFileName && typeof editData.drawingFileName === 'object' 
      ? editData.drawingFileName.name 
      : editData.drawingFileName}
        </span>
        <button
          type="button"
          style={{
            marginLeft: "10px",
            backgroundColor: "#e47331",
            color: "#fff",
            border: "none",
            padding: "5px 10px",
            cursor: "pointer",
            borderRadius: "4px",
          }}
          onClick={() => handleViewFile(tableRowData?._id, "")}
        >
          View File
        </button>
      </div>
    ) : null}
    <input
      type="file"
      accept=".dwg"
      id="drawingFileName"
      name="drawingFileName"
      className="form-control fs-12"
      style={{ width: "100%", height: "43px" }}
      onChange={(e) => {
        const file = e.target.files[0];
        if (file && file.name.endsWith(".dwg")) {
          handleInputChange(e);
        } else {
          alert("Please select a .dwg file.");
          e.target.value = null;
        }
      }}
    />
  
 
    {error.drawingFileName && (
      <div className="text-danger">{error.drawingFileName}</div>
    )}
  </div>
  
            {errorMessage && (
              <div style={{ color: "red", fontSize: "15px" }}>
                {errorMessage}
              </div>
            )}
          </div>
          {currentCloseTab &&
        <div
          style={{
            marginTop:'20px',
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <button
            style={{
              background: "var(--primary-color)",
              color: "white",
              width: "100px",
              height: "35px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleConfirmDialogClose}
          >
            Cancel
          </button>

                  <button
                     onClick={(e) => handleConfirmSubmit(currentCloseTab,e)}
                    style={{
                      background: "var(--primary-color)",
                      color: "white",
                      width: "100px",
                      height: "35px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Submit
                  </button>
                </div>
}
        </form>
      </DialogContent>
        )}
      
      </Dialog>
      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "10px",
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#4D4D4D",
            position:'relative', 
          }}
        >
          <p  style={{width:"100%",marginTop:"15px", textAlign: "center", color: "white" }}>View</p>
          <RxCross1
            size={20}
            style={{ cursor: "pointer", color: "white",position:"absolute" ,right:"10px"}}
            onClick={handleViewClose}
          />
        </div>
        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "80vh",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          {singleDrawingData?.for === "architect" && (
            <>
              {(selectedStatus === "Requested" || selectedStatus === "ReOpened" ) && (
                <>
                  {rfiRaisedAccess &&   (
                    <button
                      style={{
                        backgroundColor: "#e47331",
                        color: "white",
                        padding: "6px 20px",
                        position: "absolute",
                        right: "60px",
                      }}
                      onClick={() => handleEditPopup()}
                    >
                      Edit
                    </button>
                  )}
                </>
              )}
              {selectedStatus === "Rejected" &&   (
                <>
                  {rfiRaisedAccess && (
                    <button
                      style={{
                        backgroundColor: "#e47331",
                        color: "white",
                        padding: "6px 20px",
                        position: "absolute",
                        right: "60px",
                      }}
                      onClick={() => handleEditPopup()}
                    >
                      ReOpen
                    </button>
                  )}
                </>
              )}
            </>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "20px",
              padding: "20px",
              color: "#4D4D4D",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "28px",
              justifyContent: "center",
              marginLeft:"80px"
            }}
          >
            <div>Drawing Number</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.drawingNo || "NA"}
            </div>

            <div>Design Consultant</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.drawingId?.designDrawingConsultant?.role ||
                "NA"}
            </div>

            <div>Design Category</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.drawingId?.category?.category || "NA"}
            </div>
            {showAddFolder &&
            <>
            <div>Folder</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.drawingId?.folderId?.folderName || "NA"}
            </div>
            </>
            }
            <div>Drawing Title</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.drawingId?.drawingTitle || "NA"}
            </div>

            <div>Requested Date</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.requestedDate
                ? new Date(singleDrawingData.requestedDate)
                    .toISOString()
                    .split("T")[0]
                : "NA"}
            </div>

            <div>Expected Date</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.expectedDate
                ? new Date(singleDrawingData.expectedDate)
                    .toISOString()
                    .split("T")[0]
                : "NA"}
            </div>

            <div>Nature of Request Information</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.natureOfRequestedInformationReasons?.length >
              0 ? (
                singleDrawingData?.natureOfRequestedInformationReasons.map(
                  (item) => (
                    <div key={item._id} style={{ marginTop: "10px" }}>
                      <div>{item?.natureOfRequest} - {item?.reason}</div>
                      {/* <ul style={{ margin: 0 }}>
                        {Array.isArray(item?.reason) &&
                          item?.reason.length &&
                          item?.reason?.map((reason, index) => (
                            <li key={index} style={{ color: "#4D4D4D" }}>
                              {reason}
                            </li>
                          ))}
                      </ul> */}
                    </div>
                  )
                )
              ) : (
                <div>No nature of requested information available.</div>
              )}
            </div>
            {singleDrawingData?.remarks &&
            <>
            <div>Reason</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.remarks || "NA"}
            </div>
            </>}

            <div>Status</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.status || "NA"}
            </div>

            {singleDrawingData && singleDrawingData?.status === "Rejected" && (
              <>
                <div>Rejected Reason</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.reason || "NA"}
                </div>
              </>
            )}

            <div>Revision</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
            {singleDrawingData?.revision || "NA"}
              {/* {latestRevision ? latestRevision.revision : "-"} */}
            </div>

            <div>Requested Drawing File</div>
            <div>:</div>
            <div style={{ display: "flex", marginLeft: "-130px" }}>
              <img src={Gallery}
                size={20}
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => handleViewFile(singleDrawingData?._id, "",singleDrawingData?.rfiType,singleDrawingData?.for,singleDrawingData?.revision)}
              />
              <img src={Download}
                size={20}
                style={{ marginRight: "5px", cursor: "pointer" }}
                onClick={() => handleDownload(singleDrawingData?._id, "",singleDrawingData?.rfiType,singleDrawingData?.for)}
              />
            </div>
            {singleDrawingData && singleDrawingData?.status === "Rejected" && (
              <>
                <div>Rejected Drawing File </div>
                <div>:</div>
                <div style={{ display: "flex", marginLeft: "-130px" }}>
                  <img src={Gallery}
                    size={20}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() =>
                      handleViewFile(singleDrawingData?._id, "Rejected",singleDrawingData?.rfiType,singleDrawingData?.for,singleDrawingData?.revision)
                    }
                  />
                  <img src={Download}
                    size={20}
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    onClick={() =>
                      handleDownload(singleDrawingData?._id, "Rejected",singleDrawingData?.rfiType,singleDrawingData?.for)
                    }
                  />
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue}/>
    </>
  );
};

export default RequestedTable;
