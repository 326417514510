/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import * as Icon from "react-bootstrap-icons";
import { WebApimanager } from '../../WebApiManager';
import { Dialog, DialogContent } from '../Dialog/Dialog';

const AssignTasksDialog = ({ openAssignTaskDialog, onClose }) => {
  const webApi = new WebApimanager();
  const [datasites, setDatasites] = useState([]);
  const [formData, setFormData] = useState({
    siteName: '',
    floorName: '',
    unitName: '',
    taskName: '',
    assignedTo: ''
  });
  const [floors, setFloors] = useState([]);
  const [units, setUnits] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    siteName: '',
    floorName: '',
    unitName: '',
    taskName: '',
    assignedTo: ''
  });

  useEffect(() => {
    webApi.get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  useEffect(() => {
    webApi.get("api/users")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.allUsers)) {
          setAssignedTo(response.data.data.allUsers);
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Reset errors when user changes selection
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));

    if (name === 'siteName') {
      const selectedSite = datasites.find(site => site.siteName === value);
      if (selectedSite) {
        setFloors(selectedSite.towers.reduce((acc, tower) => {
          acc.push(...tower.floors.map(floor => ({
            _id: floor._id,
            name: floor.name
          })));
          return acc;
        }, []));
        setUnits([]);
        setTasks([]);

        // Fetch task titles
        webApi.get(`api/Task/getTask/${selectedSite._id}`)
          .then((response) => {
            if (response.data && Array.isArray(response.data.data.tasks)) {
              setTasks(response.data.data.tasks);
            } else {
              console.error("Unexpected data format:", response.data.data.tasks);
            }
          })
          .catch((error) => {
            console.error("There was an error fetching the tasks!", error);
          });
      } else {
        setFloors([]);
        setUnits([]);
        setTasks([]);
      }
    } else if (name === 'floorName') {
      const selectedTower = datasites.find(site => site.siteName === formData.siteName)?.towers[0];
      if (selectedTower) {
        const selectedUnits = selectedTower.floors.find(floor => floor.name === value)?.units || [];
        setUnits(selectedUnits.map(unit => unit.name));
      } else {
        setUnits([]);
      }
    }
  };

  const handleSubmit = () => {
    let formIsValid = true;
    const newErrors = {
      siteName: '',
      floorName: '',
      unitName: '',
      taskName: ''
    };

    if (!formData.siteName) {
      newErrors.siteName = 'Please select a site';
      formIsValid = false;
    }
    if (!formData.floorName) {
      newErrors.floorName = 'Please select a floor';
      formIsValid = false;
    }
    if (!formData.unitName) {
      newErrors.unitName = 'Please select a unit';
      formIsValid = false;
    }
    if (!formData.taskName) {
      newErrors.taskName = 'Please select a task';
      formIsValid = false;
    }

    if (formIsValid) {
      // Proceed with form submission
      updateTask(formData.taskName, formData.assignedTo);
      onClose();
    } else {
      // Update errors state to display error messages
      setErrors(newErrors);
    }
  };

  const updateTask = (taskId, assignedToId) => {
    const body = {
      assignedTo: assignedToId
    };

    webApi.put(`api/Task/${taskId}`, body)
      .then((response) => {
        // Handle success response if needed
        console.log("Task updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating task:", error);
      });
  };

  return (
    <Dialog open={openAssignTaskDialog} onClose={onClose} maxWidth="sm" fullWidth>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#7C1034', margin: '0px' }}>
        <p style={{ color: 'white', textAlign: 'center', margin: '5px 200px' }}>Assign Task</p>
        <Icon.XCircle size={20} style={{ marginLeft: 'auto', cursor: 'pointer', color: 'white', marginRight: '10px' }} onClick={onClose} />
      </div>
      <DialogContent style={{ padding: '25px', minWidth: '300px', minHeight: '300px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="siteName" className="fs-10 fw-bold text-start py-2">
              Site Name
              <span style={{ color: 'red' }}>*</span>
            </label>
            <select
              id="siteName"
              name="siteName"
              value={formData.siteName}
              onChange={handleChange}
              className="form-control fs-12"
              style={{ width: '100%', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}
            >
              <option value="">Select a Site</option>
              {datasites.map(site => (
                <option key={site._id} value={site.siteName}>{site.siteName}</option>
              ))}
            </select>
            {errors.siteName && <p style={{ color: 'red', fontSize: '12px' }}>{errors.siteName}</p>}

            {formData.siteName && (
              <div>
                <label htmlFor="floorName" className="fs-10 fw-bold text-start py-2">
                  Floor Name
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  id="floorName"
                  name="floorName"
                  className="form-control fs-12"
                  style={{ width: '100%', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}
                  value={formData.floorName}
                  onChange={handleChange}
                >
                  <option value="">Select a Floor</option>
                  {floors.map(floor => (
                    <option key={floor._id} value={floor.name}>{floor.name}</option>
                  ))}
                </select>
                {errors.floorName && <p style={{ color: 'red', fontSize: '12px' }}>{errors.floorName}</p>}
              </div>
            )}

            {formData.floorName && (
              <div>
                <label htmlFor="unitName" className="fs-10 fw-bold text-start py-2">
                  Unit Name
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  id="unitName"
                  name="unitName"
                  className="form-control fs-12"
                  style={{ width: '100%', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}
                  value={formData.unitName}
                  onChange={handleChange}
                >
                  <option value="">Select a Unit</option>
                  {units.map(unit => (
                    <option key={unit} value={unit}>{unit}</option>
                  ))}
                </select>
                {errors.unitName && <p style={{ color: 'red', fontSize: '12px' }}>{errors.unitName}</p>}
              </div>
            )}

            {formData.unitName && (
              <div>
                <label htmlFor="taskName" className="fs-10 fw-bold text-start py-2">
                  Task Name
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  id="taskName"
                  name="taskName"
                  className="form-control fs-12"
                  style={{ width: '100%', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}
                  value={formData.taskName}
                  onChange={handleChange}
                >
                  <option value="">Select a Task</option>
                  {Array.isArray(tasks) && tasks.map(task => (
                    <option key={task._id} value={task._id}>{task.workSequenceId.name}</option>
                  ))}
                </select>
                {errors.taskName && <p style={{ color: 'red', fontSize: '12px' }}>{errors.taskName}</p>}
              </div>
            )}

            {/* {formData.taskName && ( */}
              <div>
                <label htmlFor="assignedTo" className="fs-10 fw-bold text-start py-2">
                  Assigned To
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  id="assignedTo"
                  name="assignedTo"
                  className="form-control fs-12"
                  style={{ width: '100%', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}
                  value={formData.assignedTo}
                  onChange={handleChange}
                >
                  <option value="">Select User</option>
                  {assignedTo.map((user) => (
                                  <option key={user._id} value={user._id}>
                                    {user.firstName} {user.lastName}
                                  </option>
                                ))}
                </select>
                {errors.assignedTo && <p style={{ color: 'red', fontSize: '12px' }}>{errors.assignedTo}</p>}
              </div>
            {/* )} */}
          </div>
        </div>
        <div style={{ marginTop: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <button
            style={{ padding: '8px', borderRadius: '5px', border: '1px solid lightgray', backgroundColor: 'transparent', width: '100px' }}
            onMouseOver={(e) => e.target.style.backgroundColor = 'lightgray'}
            onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            style={{ marginLeft: '50px', padding: '10px', borderRadius: '5px', border: '1px solid lightgray', backgroundColor: 'brown', width: '100px' }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AssignTasksDialog;
