/* eslint-disable react/prop-types */
import React from "react";
import "./ToggleSwitch.css";
import { IoIosArrowForward } from "react-icons/io";

const ToggleSubModuleSwitch = ({
  label,
  isChecked,
  onToggle,
  onIconClick,
  setWidth = false,
  showArrow=true
}) => {
  const noArrowLabels = [
    "employee",
    "organizationChart",
    "approval",
    "design",
    "workflow",
    "forms",
    "isCodes",
    "constructionNeeds",
    "chat",
    "mail",
  ];

  console.log("labels for checking", label);

  return (
    <div
      className="toggle-submodule-switch row"
      style={{
        boxSizing: "border-box",
        backgroundColor: "transparent",
      }}
    >
      <div className="col-sm-4" style={{ marginLeft: "-20px", width: "100px" }}>
        <span className="toggle-submodule-switch-label">{label}</span>
      </div>

      <div
        className={setWidth ? "ToggleForRo" : `col-sm-4`}
        style={{ marginTop: "10px" }}
      >
        <label
          className="switch"
          // style={{ margin: "0 -10px", marginLeft: "50px" }}
        >
          <input type="checkbox" checked={isChecked} onChange={onToggle} />
          <span className="slider round"></span>
        </label>
      </div>

      <div
        className="col-sm-4"
        style={{ marginLeft: "200px", marginTop: "-30px" }}
      >
        {!noArrowLabels.includes(label) && isChecked && (
          <div onClick={onIconClick} style={{ cursor: "pointer" }}>
            {!setWidth && showArrow && <IoIosArrowForward />}
          </div>
        )}
      </div>
    </div>
  );
};

export default ToggleSubModuleSwitch;
