import React, { useState, useRef, useEffect } from "react";
import styles from "./MultiSelectDropdown.module.css"; // Import CSS module

const MultiSelectDropdown = ({ options, selectedValues, onChange, label ,isEditing}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Handle selection
  const handleSelect = (option) => {
    if (selectedValues.includes(option)) {
      onChange(selectedValues.filter((item) => item !== option)); // Remove item
    } else {
      onChange([...selectedValues, option]); // Add item
    }
  };

  // Handle removing a chip
  const handleRemove = (option) => {
    onChange(selectedValues.filter((item) => item._id !== option));
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={styles["multi-select-container"]} ref={dropdownRef}>
      <label className={styles["label"]}>{label}</label>
      <div className={styles["dropdown"]} onClick={() => setIsOpen(!isOpen)}>
        <div className={styles["selected-items"]}>
          {selectedValues.length > 0 ? (
            selectedValues.map((value) => (
              <div key={value._id} className={styles["chip"]}>
                {value.role}
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    if(isEditing?.isEditRecievedHardCopiesFromAllConsultants)
                    handleRemove(value._id);
                  }}
                >
                  &times;
                </span>
              </div>
            ))
          ) : (
            <span className={styles["placeholder"]}>Select options...</span>
          )}
        </div>
        <span className={styles["arrow"]}>&#9662;</span>
      </div>

      {isOpen && (
        <div className={styles["dropdown-menu"]}>
          {options.map((option) => (
            <div
              key={option._id}
              className={`${styles["dropdown-item"]} ${
                selectedValues.includes(option) ? styles["selected"] : ""
              }`}
              onClick={() => handleSelect(option)}
            >
              {option.role}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
