import React, { useState, useEffect, useMemo } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import {  siteId, userInfo } from "../../../atoms";
import SelectSite from "../../../Utilities/SelectSite";
import "bootstrap/dist/css/bootstrap.min.css";
import TableWrapper from "../../../Utilities/TableWrapper";
import { updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { canShowFolder } from "../ShowFolder";
import ConsultantFilter from "../ConsultantFilter";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./ApprovalTable.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", // Optional
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", // Removes the row borders
          },
        },
      },
    },
  },
});

const RegisterTable = () => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  const webApi = new WebApimanager();
  const [drawingList, setDrawingList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [site, setSite] = useRecoilState(siteId);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [consultantValue, setConsultantValue] = useState('')
  const [currentUserInfo] = useRecoilState(userInfo);
  const [filteredDrawingList, setFilteredDrawingList] = useState([]);
  useEffect(() => {
      webApi.get( `api/architect/registerSiteLevel/${site}`)
      .then((response) => {
        
        if (response && response.data && response.data.registers) {
          setDrawingList(response.data.registers);
          setInitialDrawingList(response.data.registers);
          console.log("registers Data of ro",drawingList);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [site]);
  // useEffect(() => {
  //   if (!searchActive) {
  //     drawingList();
  //   }
  // }, [searchActive]);
  const uniqueConsultant = useMemo(() => {
    const consultant = drawingList.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [drawingList]);

 
  useEffect(() => {
    const filteredList = drawingList.filter((item) => {
      const searchText = searchValue.toLowerCase();
      return (
        item.drawingNo?.toLowerCase().includes(searchText) || // Search by drawing number
        item.drawingTitle?.toLowerCase().includes(searchText) || // Search by drawing title
        item.designDrawingConsultant?.role?.toLowerCase().includes(searchText) || // Search by consultant role
        item.category?.category?.toLowerCase().includes(searchText) // Search by category
      );
    });
    setFilteredDrawingList(filteredList);
  }, [searchValue, drawingList]);
  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const updatedRows = filteredDrawingList.map((row, index) => ({
    ...row,
    serialNo: index + 1,
    drawingNo: row.drawingNo || "NA",
    consultant: row.designDrawingConsultant?.role || "-",
    category: row.category?.category || "-",
    drawingTitle: row.drawingTitle || "NA",
    creationDate: row.creationDate
    ? new Date(row.creationDate).toLocaleDateString()
    : "NA",
  }));

  const columns = useMemo(() => [
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 0.5,
      filterable: false, 
      sortable: false, 
    },
    {
      field: "drawingNo",
      headerName: "DRWG.No",
      flex: 1,
      filterable: true, 
      sortable: true, 
    },
    {
      field: "consultant",
      headerName: "Consultant",
      flex: 1,
      filterable: true, 
      sortable: true, 
    },
    {
      field: "category",
      headerName: "Drawing Category",
      flex: 1.5,
      filterable: true, 
      sortable: true, 
    },
    {
      field: "drawingTitle",
      headerName: "Drawing Title",
      flex: 2,
      filterable: true, 
      sortable: true, 
      
    },
    {
      field: "creationDate",
      headerName: "Created Date",
      flex: 1,
      filterable: false,
      sortable: true,  
      
    },
  ], [filteredDrawingList]);

  if (site === "") {
    return <SelectSite />;
  }


  

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#131313",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                Drawing Register
              </p>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "280px",
                      paddingLeft: "5px",
                      height: "50px",
                    }}
                  >
                    <input
                      placeholder="Search Drawings"
                      onChange={(e) => setSearchValue(e.target.value)}
                      value={searchValue}
                      style={{
                        paddingLeft: "20px",
              
                        border: "1px solid var(--primary-color)",
                        width: "250px",
                        height: "40px",
                      }}
                    />
                    <Icon.Search
                      style={{
                        position: "absolute",
                        right: "35px",
                        top: "40%",
                        transform: "translateY(-50%)",
                        color:'#ACACAC'
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
                              
                            <Box
                              className="dataGridContainer"
                            >
                              <ThemeProvider theme={theme}>
                              <DataGrid
                                columns={columns}
                                rows={updatedRows}
                                getRowId={(row) => row._id}
                                rowHeight={60}
                                disableColumnMenu={false} // Keep the column menu for filtering
                                pagination={false}
                                hideFooter={true}
                                getRowSpacing={params=>({
                                  top:params.isFirstVisible ? 0 : 5,
                                  bottom:params.isLastVisible ? 0 : 5
                                })}
                                
                                getRowClassName={(params) =>
                                  "customHeader" 
                                }
                                
                              />
                              </ThemeProvider>
                            </Box>
            
            
                            </div>
   
      <ConsultantFilter uniqueConsultant={["ALL",uniqueConsultant]} setConsultantValue={setConsultantValue} />   
          </>
        }
      />
    </>
  );
};

export default RegisterTable;
