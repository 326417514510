/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-children-prop */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { RxCross1 } from "react-icons/rx";
import { useRecoilState } from "recoil";
import { registerAtom, siteId, userInfo } from "../../../atoms";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import TableWrapper from "../../../Utilities/TableWrapper";
import { updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import PendingRecievedTable from "./PendingRecievedTable";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import { canShowFolder } from "../ShowFolder";
import ConsultantFilter from "../ConsultantFilter";
import ArrowToggle from "../ArrowToggle";
import SortByPopup from "../SortByPopup";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", // Optional
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", // Removes the row borders
          },
        },
      },
    },
  },
});

const PendingTable = (forwardAccess) => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  let webApi = new WebApimanager();
  const todayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [site, setSite] = useRecoilState(siteId);
  const [errorMessage, setErrorMessage] = useState({});
  const [currentUserInfo] = useRecoilState(userInfo);
  const [consultantValue, setConsultantValue] = useState('')
  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    folder: "",
    category: "",
    designDrawingConsultant: "",
    acceptedArchitectDate: "",
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingFileName: null,
    drawingPDFFileName: null,
    receivedCopies: "",
    acceptedArchitectRevisions: [],
    note: "",
  });

  const [currentView, setCurrentView] = useState("upload");
  const [reloadData, setReloadData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [drawingTableList, setDrawingTableList] = useState([]);
  const [filterCondition, setFilterCondition] = useState("upload");

  const [filters, setFilters] = useState({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
  });

  // sort by start
  const [sortField, setSortField] = useState(""); // Field to sort by
  const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
  const [activeSortPopup, setActiveSortPopup] = useState(null);

  const handleSortClick = (field) => {
    setFilters({
      drawingNo: "",
      consultant: "",
      category: "",
      drawingTitle: "",
      scheduledDate: "",
      submissionDate: "",
      revision: "",
    });
    setSearchValue("");
    setActiveSortPopup((prev) => (prev === field ? null : field));
  };

  const handleSortSelection = (order) => {
    if (activeSortPopup) {
      setSortField(activeSortPopup);
      setSortOrder(order);
      setActiveSortPopup(null); // Close the popup after selection
    }
  };

  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
    
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    })
    setSortField("");
    setSortOrder("");
    setActiveSortPopup(null);
  }, []);
  
  const handleFilterChange = (column, value) => {
    setSearchValue("")
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
    setFilters((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = ""; 
          return acc;
      }, {}),
      [column]: value, 
  }));
}

useEffect(()=>{
  setSortField("");
  setSortOrder("");
  setActiveSortPopup(null);
  setSearchValue("")
  setFilters({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
  });
},[currentView])
 

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
    setSearchValue("");
  };

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleArchitechRevisionSelection = (event) => {
    setArchitectSelectedRevision(event.target.value);
  };
  const [architectselectedRevision, setArchitectSelectedRevision] =
    useState("");
  const validateForm = (formData, currentView) => {
    const newErrors = {};
    if (currentView === "upload") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "Drawing Number  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
      if (!formData.drawingPDFFileName) {
        newErrors.drawingPDFFileName = "Drawing PDF File is required";
      } else if (formData.drawingPDFFileName instanceof File) {
        // Check if the file name ends with '.dwg'
        if (!formData.drawingPDFFileName.name.endsWith(".pdf")) {
          newErrors.drawingPDFFileName = "Drawing File must be a .pdf file";
        }
      } else if (typeof formData.drawingPDFFileName === "string") {
        // Check if the string ends with '.dwg'
        if (!formData.drawingPDFFileName.endsWith(".pdf")) {
          newErrors.drawingPDFFileName = "Drawing File must be a .pdf file";
        }
      }
      // if (!formData.remarks) errors.remarks = 'This field is required.';
    } else if (currentView === "received") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      if (!formData.revisions) {
        newErrors.revisions = "revision is required";
      }
      if (!formData.receivedCopies) {
        newErrors.receivedCopies = "receivedCopies are required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
    }
    return newErrors;
  };

  const handleFormSubmit = async () => {
    // Validate the form data
    const errors = validateForm(formData, currentView);
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    // Perform API call based on the active tab
    if (currentView === "upload") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitUpload(formData.registerId);
    }
    if (currentView === "received") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReceived(formData.registerId);
    }
  };

  const submitUpload = async (registerId) => {
    console.log("registerId", registerId);

    try {
      // Basic form data
      const ApprovalFormData = new FormData();
      ApprovalFormData.append("softCopySubmittedDate", todayDate());
      ApprovalFormData.append("changes", formData.note);

      // Conditionally add files if they exist
      if (formData.drawingDWGFileName) {
        ApprovalFormData.append("drawingFileName", formData.drawingDWGFileName);
      }

      if (formData.drawingPDFFileName) {
        ApprovalFormData.append(
          "pdfDrawingFileName",
          formData.drawingPDFFileName
        );
      }

      console.log("Submitting Approval data:", ApprovalFormData);

      // Submit the approval form data
      const response = await webApi.imagePut(
        `api/architectureToRoRegister/revisions/${registerId}?revisionType=acceptedRORevisions`,
        ApprovalFormData
      );
      if (response.status === 200) {
        // Clear loading state and close the form
        setApiProcessing({ loader: false, message: "" });
        handleClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      alert("Failed to submit the form. Please try again later.");
    }
  };

  const submitReceived = async (drawingItemId) => {
    try {
      const ApprovalFormData = {
        acceptedROHardCopyRevisions: [
          {
            revision: formData.revision,
            hardCopySubmittedDate: todayDate(),
            receivedCopies: formData.receivedCopies,
          },
        ],
      };
      console.log("Submitting Approval data:", ApprovalFormData);

      // const response = await webApi.put(`api/architectureToRoRequested/updateRevision/${drawingItemId}`, ApprovalFormData);
      // const id = response.data.data._id;

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("drawingFileName", formData.drawingFileName);
        const uploadResponse = await webApi.imagePut(
          `api/roToSiteLevelRequested/drawing/${drawingItemId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      fetchData();
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };

  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
      }
    });
  };

  useEffect(() => {
    fetchInData();
  }, []);

  useEffect(() => {
    setReloadData(true);
  }, [drawingList]);

  const handleEdit = (drawingItem) => {
    console.log(drawingItem, "drawing in Pending upload");
    console.log(
      drawingItem.acceptedArchitectRevisions.length - 1,
      "revisions length"
    );
    if (Object.keys(drawingItem).length) {
      setFormData({
        registerId: drawingItem.drawingId,
        folder: drawingItem.folderId?.folderName || "NA",
        drawingNumber: drawingItem.drawingNo,
        drawingName: drawingItem.drawingTitle,
        category: drawingItem.category?.category,
        designDrawingConsultant: drawingItem.designDrawingConsultant?.role,
        acceptedArchitectDate: "",
        submittedDate: "",
        issuedSoftCopy: "",
        receivedSoftCopy: "",
        drawingFileName: null,
        receivedCopies: "",
        acceptedArchitectRevisions: [],
        note: "",
      });
    }

    setOpen(true);
  };
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const calculateDueDays = (acceptedRoDate, submittedDate = null) => {
    if (!acceptedRoDate) return "-";

    const dateToCompare = submittedDate ? new Date(submittedDate) : new Date();
    const acceptedDate = new Date(acceptedRoDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  };

  const searchResult = (e) => {
    const searchValue = e.target.value ? e.target.value.toLowerCase() : "";
    setSearchValue(e.target.value);
  };
  useEffect(() => {
    const fetchDrawingTableData = async (site) => {
      try {
        const response = await webApi.get(
          `api/architect/pendingRo/${site}?filterType=upload`
        );
        if (response.status === 200) {
          setDrawingTableList([
            ...response.data.data.architectRevisions,
            ...response.data.data.rORevisions,
          ]);
        }
        console.log(response, "RO tabs tableData");
      } catch (error) {
        console.log(error.message);
      }
    };
    if (currentView === "upload") fetchDrawingTableData(site);
  }, [site, currentView]);

  const uniqueConsultant = useMemo(() => {
    const consultant = drawingTableList.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [drawingTableList]);

  useEffect(() => {
    console.log(drawingTableList, "drawingTableList");
  }, [drawingTableList, currentView]);
  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const updatedRows = drawingTableList.filter((item)=>item.designDrawingConsultant?.role.includes(consultantValue)).map((row, index) => {
    const dueDays = calculateDueDays(row.acceptedSiteSubmissionDate, row.submittedDate);
    const dueStatus =
      dueDays === "-"
        ? "-"
        : dueDays >= 0
        ? `${dueDays} days due`
        : `${Math.abs(dueDays)} days overdue`;

    const color = dueDays >= 0 ? "green" : "red";
    return {...row,
    serialNo: index + 1, 
    drawingNo: row.drawingNo,
    consultant: row.designDrawingConsultant?.role ,
    from:row?.for === "architect" ? "RO" : row?.for,
    category: row.category?.category,
    drawingTitle: row?.drawingTitle,
    acceptedSiteSubmissionDate: formatDate(row.acceptedSiteSubmissionDate),
    dueStatus: { text: dueStatus, color },
    revisions: row?.archRevision
}});

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = [
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 1,
      sortable:false,
      filterable:false
    },
    {
      field: "drawingNo",
      headerName: "DRWG.No",
      flex: 1,
      sortable:true,
      filterable:true
    },
    {
      field: "consultant",
      headerName: "Consultant",
      flex: 1,
      sortable:true,
      filterable:true
    },
    {
      field: "from",
      headerName: "From",
      flex: 1,
      sortable:true,
      filterable:true
      
    },
    {
      field: "category",
      headerName: "Drawing Category",
      flex: 1,
      sortable:true,
      filterable:true
    },
    {
      field: "drawingTitle",
      headerName: "Drawing Title",
      flex: 1,
      sortable:true,
      filterable:true
      
    },
    {
      field: "acceptedSiteSubmissionDate",
      headerName: "Accepted Site Submission Date",
      flex: 1,
      sortable:true,
      filterable:true
    },
    {
      field: "dueStatus",
      headerName: "Due/Overdue",
      flex: 1,
      sortable:true,
      filterable:true,
      renderCell: (params) => {
        const dueStatus = params.row.dueStatus || { text: "-", color: "black" };
        return <span style={{ color: dueStatus.color }}>{dueStatus.text}</span>;
    }},
    {
      field: "revisions",
      headerName: "Revisions",
      flex: 1,
      sortable:true,
      filterable:true
    },
  ];
  
  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <Tabs value={currentView} onChange={handleTabViewChange}>
                  <Tab label="Soft Copy" value="upload" />
                  <Tab label="Hard Copy" value="received" />
                </Tabs>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    position: "relative",
                    width: "280px",
                    paddingLeft: "5px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder="Search Drawings"
                    onChange={handleSearchChange}
                    style={{
                      paddingLeft: "20px",

                      border: "1px solid var(--primary-color)",
                      width: "240px",
                      height: "40px",
                    }}
                    value={searchValue}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "35px",
                      top: "40%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              {currentView === "upload" && (
                <div style={{ marginTop: "10px" }}>
                  <Box
                    className="dataGridContainer"
                  >
                    <ThemeProvider theme={theme}>
                    <DataGrid
                      columns={columns}
                      rows={filteredRows}
                      getRowId={(row) => row.serialNo}
                      rowHeight={60}
                      disableColumnMenu={false} // Keep the column menu for filtering
                      pagination={false}
                      hideFooter={true}
                      getRowSpacing={params=>({
                        top:params.isFirstVisible ? 0 : 5,
                        bottom:params.isLastVisible ? 0 : 5
                      })}
                      
                      getRowClassName={(params) =>
                        "customHeader" 
                      }
                      
                    />
                    </ThemeProvider>
                  </Box>
                </div>
              )}
              {currentView === "received" && (
                <div style={{ marginTop: "10px" }}>
                  <PendingRecievedTable
                    selectedFilter={""}
                    searchValue={searchValue}
                    searchActive={searchActive}
                    setSearchValue={setSearchValue}
                    setSearchActive={setSearchActive}
                    reloadData={reloadData}
                    setReloadData={setReloadData}
                  />
                </div>
              )}
            </div>
          </>
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            <button
              onClick={() => {
                setCurrentView("upload");
              }}
              style={{
                backgroundColor:
                  currentView === "upload"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentView === "upload" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
                borderRight: "1px solid var(--primary-color)",
              }}
            >
              Upload
            </button>
          </div>
        </div>
        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <>
            {" "}
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <section
              style={{
                marginLeft: "80px",
                marginRight: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "50px",
                  width: "80%",
                }}
              >
                <div>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing No
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="drawingNumber"
                    name="drawingNumber"
                    value={formData.drawingNumber}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />

                  {errors.drawingNumber && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingNumber}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="drawingName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Title
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="drawingName"
                    name="drawingName"
                    value={formData.drawingName}
                    onChange={handleChange}
                    placeholder="Enter your drawing title"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.drawingName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingName}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Category
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    placeholder="Enter your Drawing Category"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.category && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.category}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="designDrawingConsultant"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Design Consultant
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="designDrawingConsultant"
                    name="designDrawingConsultant"
                    value={formData.designDrawingConsultant}
                    onChange={handleChange}
                    placeholder="Enter your  Design Consultant"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.designDrawingConsultant && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.designDrawingConsultant}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="folder"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Folder
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="folder"
                    name="folder"
                    value={formData.folder}
                    onChange={handleChange}
                    placeholder="Enter your  Folder "
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.folder && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.folder}
                    </p>
                  )}
                </div>
                {currentView !== "received" && (
                  <>
                    <div>
                      <label
                        htmlFor="revisions"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Revisions
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="revisions"
                        name="revisions"
                        value={formData.revisions}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Revision</option>
                        <option value="R0">R0</option>
                      </select>
                      {errors.revisions && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.revisions}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="architectselectedRevision"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Architect Revision Ref
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="architectselectedRevision"
                        name="architectselectedRevision"
                        onChange={handleArchitechRevisionSelection}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Revision</option>{" "}
                        {formData.acceptedArchitectRevisions.length > 0 ? (
                          formData.acceptedArchitectRevisions.map(
                            (revision, index) => (
                              <option key={index} value={revision}>
                                {revision}
                              </option>
                            )
                          )
                        ) : (
                          <option value="" disabled>
                            No revisions available
                          </option>
                        )}
                      </select>
                      {errors.architectselectedRevision && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.architectselectedRevision}
                        </p>
                      )}
                    </div>
                  </>
                )}
                {/* {currentView === "received" && (
                  <>
                    <div>
                      <label
                        htmlFor="revisions"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Revisions
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="revisions"
                        name="revisions"
                        value={formData.revisions}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Revision</option>
                        {formData.revision &&
                          formData.revisions.map((revision, index) => (
                            <option key={index} value={revision.revision}>
                              {revision.revision}
                            </option>
                          ))}
                      </select>
                      {errors.revisions && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.revisions}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="receivedCopies"
                        className="fs-10 fw-bold text-start py-2
              "
                      >
                        No of Copies Received
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        id="receivedCopies"
                        name="receivedCopies"
                        placeholder="Enter No of Received Copies"
                        value={formData.receivedCopies}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </>
                )} */}

                <div>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Upload Your Drawing File
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".dwg"
                    id="drawingFileName"
                    name="drawingFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.name.endsWith(".dwg")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .dwg file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: "43px" }}
                  />
                  {errors.drawingFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingFileName}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="drawingPDFFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload PDF file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    id="drawingPDFFileName"
                    name="drawingPDFFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.name.endsWith(".pdf")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .pdf file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: "43px" }}
                  />
                  {errors.drawingPDFFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingPDFFileName}
                    </p>
                  )}
                </div>
              </div>
            </section>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "45px",
              }}
            >
              <button
                style={{
                  padding: "10px",

                  border: "1px solid lightgray",
                  backgroundColor: "transparent",
                  width: "100px",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "lightgray")
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
                onClose={handleClose}
              >
                Cancel
              </button>
              <button
                style={{
                  marginLeft: "50px",
                  padding: "10px",

                  border: "1px solid var(--primary-color)",
                  width: "100px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </>
        </DialogContent>
      </Dialog>
      {currentView !== "received" && <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue}/>}
    </>
  );
};

export default PendingTable;
