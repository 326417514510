import React, { useState, useEffect, useMemo, useRef } from "react";
import "../Drawing/SiteHead.css";
import * as Icon from "react-bootstrap-icons";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../../Tabs/Tabv1";
import { RxCross1 } from "react-icons/rx";
import TableWrapper from "../../../../Utilities/TableWrapper";
import SiteheadReceivedTable from "./SiteheadReceivedTable";
import Collected from "./Collected";
import { useNavigate } from "react-router-dom";
import UploadToSitelevelModal from "./UploadToSitelevelModal";
import CollectedFromR0Modal from "./CollectedFromR0Modal";
import filter from "../../../../Images/Filter.svg";
import { WebApimanager } from "../../../../WebApiManager";
import { useRecoilState } from "recoil";
import { siteId, userInfo } from "../../../../atoms";
import AddDrawingDialog from "./AddDrawingDialog";
import { processFile } from "../../BackendUtils";
import { Tab1, Tabs1 } from "../../../Tabs/Tabv2";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import {
  calculateDueDays,
  formatDate,
  todayDate,
} from "../../../../Utilities/DateUtils";
import { RiShareForwardLine } from "react-icons/ri";
import { Dialog, DialogContent } from "../../../Dialog/Dialog";

import { FaFilter } from "react-icons/fa";
import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import pen from "../IconsSiteHead/penIcon.svg";

const SiteHeadDrawing = ({forwardAccess, rfiRaisedAccess}) => {
  const webApi = new WebApimanager();
  const navigate = useNavigate();
  const [uploadData, setUploadData] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [currentView, setCurrentView] = useState("Issued From R0");
  const [isSitelevelModalOpen, setSitelevelModalOpen] = useState(false);
  const [isCollectedModalOpen, setCollectedModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false); // Local state for dropdown
  const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category
  const [selectedFolder, setSelectedFolder] = useState(""); // State for selected category
  const [folders, setFolders] = useState([]);
  const [isCategoriesDropdownOpen, setCategoriesDropdownOpen] = useState(false);
  const [isFoldersDropdownOpen, setFoldersDropdownOpen] = useState(false);
  const [userDetails] = useRecoilState(userInfo)
  const [showHardCopy, setShowHardCopy] = useState(false)
  const [consultantNotSubmitHardCopy, setConsultantNotSubmitHardCopy] = useState([])

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
    setFilterDropdownOpen(false); // Close filter dropdown when changing tabs
  };

  const handleClickOpen = () => {
    if (currentView === "Upload to Sitelevel") {
      setSitelevelModalOpen(true);
    } else if (currentView === "Collected from R0") {
      setCollectedModalOpen(true);
    }
  };
  const uniqueCategories = useMemo(() => {
    const categories = uploadData.map((item) => item.category?.category);
    return [...new Set(categories)];
  }, [uploadData]);

  const uniqueFolders = useMemo(() => {
    const folder = uploadData.map((item) => item.folderId?.folderName);
    return [...new Set(folder)];
  }, [uploadData]);

  console.log(uniqueCategories, uniqueFolders, "SITE Head Upload categories");

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
  };
  const fetchUploadData = async () => {
    try {
      const response = await webApi.get(
        `api/architect/drawingSiteHead/${site}?filterType=upload`
      );
      setUploadData(response.data.data);

      console.log(uploadData, "upload to");
    } catch (error) {
      console.error("Error fetching upload data:", error);
    }
  };

  useEffect(() => {
    fetchUploadData();
  }, [site]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setFilterDropdownOpen(false);
  };
  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
    setFilterDropdownOpen(false);
  };

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const handleMouseEnter = (category) => {
    setHoveredCategory(category);
    if (hoverTimeout) clearTimeout(hoverTimeout);
    const timeout = setTimeout(() => {
      setHoveredCategory(null);
    }, 9000);
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) clearTimeout(hoverTimeout);
  };

  const filteredDataAndUploadData = useMemo(() => {
    return [...uploadData].filter((item) => {
      const searchTermLower = searchTerm.toLowerCase();
      const matchesSearchTerm =
        item.drawingNo.toLowerCase().includes(searchTermLower) ||
        item.drawingTitle.toLowerCase().includes(searchTermLower) ||
        item?.designDrawingConsultant?.role
          ?.toLowerCase()
          .includes(searchTermLower) ||
        item?.category?.category?.toLowerCase().includes(searchTermLower);

      // Check for selected category and selected folder (for uploadData)
      const categoryMatches = selectedCategory
        ? item.category?.category === selectedCategory
        : true;
      // const folderMatches = selectedFolder
      //   ? item.folderId?.folderName === selectedFolder
      //   : true;

      // Filter by both conditions for filteredData and filteredUploadData
      return matchesSearchTerm && categoryMatches;
    });
  }, [uploadData, searchTerm, selectedCategory]);

  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    setViewDialogOpen(true);
    if (drawingItem.acceptedSiteHeadRevisions?.length > 0) {
      setSelectedTab(drawingItem.acceptedSiteHeadRevisions?.length - 1);
    }
  };
  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };
  const handleDownload = async (id, revision, from) => {
    console.log(id, revision, from);
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      let apiEndpoint;

      if (from === "architect") {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisions/${revision}`;
      } else if (from === "ro") {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisionsRo/${revision}`;
      } else {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisionsSiteHead/${revision}`;
      }

      const response = await webApi.getImage(apiEndpoint, {
        responseType: "arraybuffer", // Expect binary data
      });
      // Convert the response to a Blob and create a download link
      const blob = new Blob([response.data], {
        type: response.headers["content-type"] || "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `DrawingFile_${revision}.dwg`); // Set the file name with .dwg extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision, from) => {
    setApiProcessing({
      loader: true,
      message: "Viewing...",
    });
    try {
      let apiEndpoint;

      if (from === "architect") {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisions/${revision}`;
      } else if (from === "ro") {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisionsRo/${revision}`;
      } else {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisionsSiteHead/${revision}`;
      }

      const response = await webApi.getImage(apiEndpoint, {
        responseType: "arraybuffer", // Expect binary data
      });

      console.log(response, "View API CALL");

      processFile(response, "view", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleForwardClick = async (singledrawingdata, revisionNo) => {
    console.log(singledrawingdata, revisionNo, "handleForwardClick");
    const registerId = singledrawingdata.drawingId;

    const selectedRevision = singledrawingdata.acceptedSiteHeadRevisions.find(
      (revision) => revision.revision === `R${revisionNo}`
    );
    console.log(selectedRevision, "revision");

    if (!selectedRevision) {
      alert("Revision not found");
      return;
    }
    setApiProcessing({ loader: true, message: "forwarding..." });

    try {
      const ApprovalFormData = new FormData();
      ApprovalFormData.append("softCopySubmittedDate", todayDate());
      ApprovalFormData.append("changes", selectedRevision.changes || "");
      ApprovalFormData.append("issuedSoftCopy", "Yes");
      ApprovalFormData.append("roRef", selectedRevision.revision);
      ApprovalFormData.append(
        "drawingFileName",
        selectedRevision.drawingFileName
      );
      ApprovalFormData.append(
        "pdfDrawingFileName",
        selectedRevision.pdfDrawingFileName
      );
      ApprovalFormData.append("siteTositeType", "Forwarded");
      console.log("Submitting Approval data:", ApprovalFormData);
      const response = await webApi.imagePut(
        `api/architectureToRoRegister/revisions/${registerId}?revisionType=acceptedSiteHeadRevisions`,
        ApprovalFormData
      );

      if (response.status === 200) {
        setApiProcessing({ loader: false, message: "" });
        // handleClose();
        // window.location.reload();
      } else {
        console.error("Failed to submit the form:", response);
        setApiProcessing({ loader: false, message: "" });
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      alert("An error occurred. Please try again later.");
    }
  };

  const handleType = (type) => {
    if (type === "all") {
      setSelectedCategory("");
      setSelectedFolder("");

      setFilterDropdownOpen(false);
      setCategoriesDropdownOpen(false);
      setFoldersDropdownOpen(false);
    } else {
      setSelectedCategory(type);
      setFilterDropdownOpen(false);
      setFoldersDropdownOpen(false);
    }
  };

  const handleCategoryClick = () => {
    setCategoriesDropdownOpen((prev) => !prev);
    setFoldersDropdownOpen(false);
  };

  // const handleFolderClick = () => {
  //   setFoldersDropdownOpen((prev) => !prev);
  //   setCategoriesDropdownOpen(false);
  // };

  const fetchNoOfDays = async() =>{
       try {
         const response = await webApi.get("api/rfiTimeStamp/byCompanyId")
         console.log(response.data.data.customizedView ,"response in the rfi timeStamp")
          if (response.status === 200) {
           const recievedHardCopies = response?.data?.data?.areYouReceivingHardCopiesFromAllConsultants       
           setShowHardCopy(recievedHardCopies)
           if(!recievedHardCopies){
            setConsultantNotSubmitHardCopy(response?.data?.data?.whichConsultantsHaveNotSubmittedHardCopies)
           }
           console.log(recievedHardCopies,"recievedHardCopies")
          } 
       } catch (error) {
         console.log(error.message)
       }
     }
     
     useEffect(()=>{
       fetchNoOfDays()
     },[])

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <TableWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs value={currentView} onChange={handleTabViewChange}>
            <Tab label="Soft Copy" value="Issued From R0" />

            {/* <Tab label="Upload" value="Upload to Sitelevel" /> */}
            {(showHardCopy || !(consultantNotSubmitHardCopy.includes(userDetails?._id))) && <Tab label="Hard Copy" value="Collected from R0" />}
          </Tabs>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "5px",
              gap: "0px",
            }}
          >
            <></>
            {currentView === "Upload to Sitelevel" && (
              <input
                placeholder="Search Drawing Lists"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  paddingLeft: "20px",
                  border: "1px solid var(--primary-color)",
                  width: "250px",
                  height: "40px",
                }}
              />
            )}

            {currentView === "Upload to Sitelevel" && (
              <div ref={dropdownRef} style={{ position: "relative" }}>
                <div onClick={toggleFilterDropdown}>
                  {/* <img src={filter} alt="filter" /> */}
                  <FaFilter
                    size={39}
                    style={{
                      border: "1px solid var(--primary-color)",
                      padding: "5px",
                      marginRight: "-10px",
                    }}
                  />
                </div>
                {isFilterDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      background: "white",
                      borderRadius: "4px",
                      zIndex: 1000,
                      textAlign: "left",
                      marginTop: "20px",
                      marginLeft: "-78px",
                      padding: "15px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          cursor: "pointer",
                          padding: "5px 0",
                          position: "relative",
                          display: "block",
                        }}
                        onClick={() => {
                          handleType("all");
                        }}
                      >
                        All
                      </span>
                    </div>
                    <span
                      style={{
                        cursor: "pointer",
                        padding: "5px 0",
                        position: "relative",
                      }}
                      onClick={handleCategoryClick}
                    >
                      Categories
                      {isCategoriesDropdownOpen && (
                        <span
                          className="categories-dropdown"
                          style={{
                            position: "absolute",
                            background: "white",
                            borderRadius: "4px",
                            marginTop: "0px",
                            zIndex: 1000,
                            marginLeft: "-150px",
                            padding: "15px",
                            left: 0,
                            width: "auto",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {uniqueCategories.map((category) => (
                              <span
                                key={category}
                                onClick={() => handleCategorySelect(category)}
                                onMouseEnter={() => handleMouseEnter(category)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  cursor: "pointer",
                                  padding: "5px 0",
                                  transition: "background-color 0.2s",
                                  backgroundColor:
                                    selectedCategory === category
                                      ? "#d0e1ff"
                                      : hoveredCategory === category
                                      ? "#b0c4ff"
                                      : "white",
                                }}
                              >
                                {category}
                              </span>
                            ))}
                          </div>
                        </span>
                      )}
                    </span>

                    {/* <span style={{ marginTop: "10px", display: "block" }}>
                      <span
                        style={{
                          cursor: "pointer",
                          padding: "5px 0",
                          position: "relative",
                        }}
                        onClick={handleFolderClick}
                      >
                        Folders
                        {isFoldersDropdownOpen && (
                          <span
                            className="folders-dropdown"
                            style={{
                              position: "absolute",
                              background: "white",
                              borderRadius: "4px",
                              zIndex: 1000,
                              marginTop: "10px",
                              marginLeft: "-115px",
                              padding: "15px",
                              left: 0,
                              width: "auto",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {uniqueFolders.map((folder) => (
                                <span
                                  key={folder}
                                  onClick={() => handleFolderSelect(folder)}
                                  onMouseEnter={() => handleMouseEnter(folder)}
                                  onMouseLeave={handleMouseLeave}
                                  style={{
                                    cursor: "pointer",
                                    padding: "5px 0",
                                    transition: "background-color 0.2s",
                                    backgroundColor:
                                      selectedFolder === folder
                                        ? "#d0e1ff"
                                        : hoveredCategory === folder
                                        ? "#b0c4ff"
                                        : "white",
                                  }}
                                >
                                  {folder}
                                </span>
                              ))}
                            </div>
                          </span>
                        )}
                      </span>
                    </span> */}
                  </div>
                )}
              </div>
            )}

            {currentView === "Collected from R0" && (
              <>
                {forwardAccess && (
                  <button
                    style={{
                      height: "40px",
                      marginLeft: "10px",
                      backgroundColor: "var(--primary-color)",
                      border: "1px solid var(--primary-color)",
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      padding: "10px",
                      marginRight:"10px"
                    }}
                    onClick={handleClickOpen}
                  >
                    <Icon.PlusCircle
                      size={20}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Add New
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {/* Table content rendering */}
        <div style={{ marginTop: "10px" }}>
          {currentView === "Upload to Sitelevel" && (
            <DrawingTableWrapper
              tableHead={
                <tr>
                  <th style={{ width: "5%" }}>S.No</th>
                  <th style={{ width: "10%" }}>DRWG.No</th>
                  <th style={{ width: "10%" }}>Consultant</th>
                  {/* <th style={{ width: "5%" }}>Folder</th> */}
                  <th style={{ width: "10%" }}>Drawing category</th>
                  <th style={{ width: "10%" }}>Drawing title</th>
                  <th style={{ width: "13%" }}>R0 Submission Date</th>
                  <th style={{ width: "10%" }}>Due/Overdue</th>
                  <th style={{ width: "8%" }}>Revision</th>
                  <th style={{ width: "8%" }}>Forwarded Revision</th>
                  <th style={{ width: "7%" }}>Action sdac</th>
                </tr>
              }
              tableBody={filteredDataAndUploadData.map((item, index) => (
                <tr
                  key={item._id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#FDF8F4" : "#F3F6F8",
                  }}
                >
                  <td style={{ width: "5%" }}>{index + 1}</td>
                  <td style={{ width: "10%" }}>{item.drawingNo}</td>
                  <td style={{ width: "10%" }}>
                    {item?.designDrawingConsultant?.role}
                  </td>
                  {/* <td style={{ width: "5%" }}>{item.folderId?.folderName}</td> */}
                  <td style={{ width: "10%" }}>{item?.category?.category}</td>
                  {/* <td style={{ width: "10%" }}>
                    {item.drawingTitle}</td> */}
                  <td
                    style={{
                      width: "10%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "default",
                    }}
                  >
                    <span
                      title={item.drawingTitle}
                      style={{
                        display: "inline-block",
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.drawingTitle.split(" ").slice(0, 2).join(" ")}...
                    </span>
                  </td>
                  <td style={{ width: "13%" }}>
                    {new Date(
                      item.acceptedROSubmissionDate
                    ).toLocaleDateString()}
                  </td>
                  <td
                    style={{
                      width: "13%",
                      padding: "15px",
                      color: (() => {
                        const today = new Date();
                        const dueDate = new Date(item.acceptedROSubmissionDate);
                        const daysDifference = Math.ceil(
                          (dueDate - today) / (1000 * 60 * 60 * 24)
                        );

                        return daysDifference >= 0 ? "green" : "red";
                      })(),
                    }}
                  >
                    {(() => {
                      const today = new Date();
                      const dueDate = new Date(item.acceptedROSubmissionDate);
                      const timeDifference = dueDate - today;
                      const daysDifference = Math.ceil(
                        timeDifference / (1000 * 60 * 60 * 24)
                      ); // Convert to days

                      return daysDifference >= 0
                        ? `${daysDifference} days due`
                        : `${Math.abs(daysDifference)} days overdue`;
                    })()}
                  </td>
                  <td style={{ width: "10%" }}>
                    {item?.acceptedSiteHeadRevisions
                      ?.slice(-2)
                      .map((revisionItem) => revisionItem.revision)
                      .join(", ")}
                  </td>
                  <td style={{ width: "10%" }}>
                    {item?.acceptedSiteHeadRevisions
                      ?.slice(-2)
                      .map((revisionItem) => revisionItem.revision)
                      .join(", ")}
                  </td>
                  <td style={{ width: "10%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginLeft: "30px",
                      }}
                    >
                      <img
                        src={Eye}
                        alt="view"
                        onClick={() => handleViewOpen(item)}
                        style={{ cursor: "pointer", marginRight: "5px" }}
                      />

                      <img
                        src={Download}
                        alt="download"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const latestRevision =
                            item.acceptedSiteHeadRevisions.length > 0
                              ? item.acceptedSiteHeadRevisions[
                                  item.acceptedSiteHeadRevisions.length - 1
                                ].revision
                              : null;
                          handleDownload(
                            item.drawingId,
                            latestRevision,
                            item.acceptedSiteHeadRevisions.length > 0
                              ? item.acceptedSiteHeadRevisions[
                                  item.acceptedSiteHeadRevisions.length - 1
                                ].from
                              : null
                          );
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            />
          )}
          {currentView === "Issued From R0" && (
            <SiteheadReceivedTable
              searchTerm={searchTerm}
              forwardAccess={forwardAccess}
              rfiRaisedAccess={rfiRaisedAccess}
            />
          )}
          {currentView === "Collected from R0" && (
            <Collected searchTerm={searchTerm} forwardAccess={forwardAccess}/>
          )}
        </div>
        <AddDrawingDialog
          open={isSitelevelModalOpen}
          site={site}
          handleDialogClose={() => setSitelevelModalOpen(false)}
          filtercondition="Upload To SiteLvel"
        />
        <AddDrawingDialog
          open={isCollectedModalOpen}
          site={site}
          handleDialogClose={() => setCollectedModalOpen(false)}
          filtercondition="Collected From RO"
        />
        <Dialog
          open={viewDialogOpen}
          onClose={handleViewClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              borderRadius: 10,
              fontFamily: "Poppins",
              background: "#FFF",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: " #4D4D4D",
              margin: "0px",
              height: "50px",
            }}
          >
            <p
              style={{
                width: "100%",
                textAlign: "center",
                color: "white",
                marginTop: "15px",
              }}
            >
              View
            </p>
            <RxCross1
              size={20}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "20px",
                color: "white",
              }}
              onClick={handleViewClose}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                overflowX: "auto",
                maxWidth: "230px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "0",
                  border: "1px solid var(--primary-color)",
                }}
              >
                {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 ? (
                  <Tabs1 value={selectedTab} onChange={handleTabChange}>
                    {singleDrawingData.acceptedSiteHeadRevisions.map(
                      (revisionItem, index) => (
                        <Tab1
                          key={index}
                          value={index}
                          label={`${revisionItem.revision}`}
                          style={{
                            backgroundColor:
                              singleDrawingData.acceptedSiteHeadRevisions
                                .length === 1 || selectedTab === index
                                ? "var(--primary-color)"
                                : "transparent",
                            color:
                              singleDrawingData.acceptedSiteHeadRevisions
                                .length === 1 || selectedTab === index
                                ? "white"
                                : "var(--primary-color)",
                            width: "150px",
                            height: "35px",
                            cursor: "pointer",
                            borderRight:
                              index <
                              singleDrawingData.acceptedSiteHeadRevisions
                                .length -
                                1
                                ? "1px solid var(--primary-color)"
                                : "none",
                            // borderRadius: index === 0 && !isSingleRevision ? '5px 0 0 5px' : (index === singleDrawingData.acceptedRORevisions.length - 1 && !isSingleRevision ? '0 5px 5px 0' : 'none'),
                          }}
                        />
                      )
                    )}
                  </Tabs1>
                ) : (
                  <div>No Revisions</div>
                )}
              </div>
            </div>
          </div>
          {/* {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    {selectedTab ===
                    singleDrawingData.acceptedSiteHeadRevisions.length - 1 ? (
                      <div style={{ color: "green" }}>
                        Good For Construction
                      </div>
                    ) : (
                      <div style={{ color: "red" }}>Superseeded</div>
                    )}
                  </div>
                )}
              </div>
            </>
          )} */}
          {singleDrawingData?.acceptedRORevisions?.length > 0 &&
            selectedTab <= singleDrawingData.acceptedRORevisions.length - 1 && (
              <>
                {(() => {
                  const selectedRevision =
                    singleDrawingData.acceptedRORevisions[selectedTab];
                  const hardCopyRevision =
                    singleDrawingData.acceptedSiteHeadHardCopyRevisions
                      ?.revision;

                  if (selectedRevision?.revision === hardCopyRevision) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                          color: "green",
                        }}
                      >
                        Good For Construction
                      </div>
                    );
                  } else if (
                    selectedRevision?.currentRevisionRfiStatus === "Raised"
                  ) {
                    if (!hardCopyRevision) {
                      return (
                        <p
                          style={{
                            color: "#FFB200",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          RFI has been raised. Hardcopy not received
                        </p>
                      );
                    } else {
                      return (
                        <p
                          style={{
                            color: "#FFB200",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          RFI has been raised
                        </p>
                      );
                    }
                  } else if (!hardCopyRevision) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                          color: "red",
                        }}
                      >
                        No hardcopy file received
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                          color: "red",
                        }}
                      >
                        Superseded
                      </div>
                    );
                  }
                })()}
              </>
            )}

          <DialogContent
            style={{
              padding: "25px",
              minWidth: "300px",
              minHeight: "300px",
              maxHeight: "70vh",
              overflowY: "auto",
            }}
          >
            <>
              <Loader
                open={apiProcessing.loader}
                message={apiProcessing.message}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "20px",
                  padding: "20px",
                  color: "#4D4D4D",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "28px",
                  justifyContent: "center",
                  marginLeft: "80px",
                }}
              >
                <div>Drawing Number</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.drawingNo || "NA"}
                </div>
                <div>Design Consultant</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.designDrawingConsultant?.role || "NA"}
                </div>
                <div>Category</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.category?.category || "NA"}
                </div>
                <div>Drawing Title</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.drawingTitle || "NA"}
                </div>
                <div>Folder</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.folderId?.folderName || "-"}
                </div>
                <div>Submission date</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.acceptedSiteSubmissionDate
                    ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                        .toISOString()
                        .split("T")[0]
                    : "NA"}
                </div>

                {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
                  <>
                    {singleDrawingData.acceptedSiteHeadRevisions[
                      selectedTab
                    ] && (
                      <>
                        <div>Soft Copy Submitted Date</div>
                        <div>:</div>
                        <div style={{ marginLeft: "-120px" }}>
                          {singleDrawingData.acceptedSiteHeadRevisions[
                            selectedTab
                          ].softCopySubmittedDate
                            ? formatDate(
                                singleDrawingData.acceptedSiteHeadRevisions[
                                  selectedTab
                                ].softCopySubmittedDate
                              )
                            : "No Submitted Date"}
                        </div>
                      </>
                    )}
                  </>
                )}
                {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
                  <>
                    {singleDrawingData.acceptedSiteHeadRevisions[
                      selectedTab
                    ] && (
                      <>
                        <div>RO Forwarded Revision</div>
                        <div>:</div>
                        <div style={{ marginLeft: "-130px" }}>
                          {singleDrawingData.acceptedSiteHeadRevisions[
                            selectedTab
                          ].revision || "NA"}
                        </div>
                      </>
                    )}
                  </>
                )}
                {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
                  <>
                    {singleDrawingData.acceptedSiteHeadRevisions[
                      selectedTab
                    ] && (
                      <>
                        <div>Revision</div>
                        <div>:</div>
                        <div style={{ marginLeft: "-130px" }}>
                          {singleDrawingData.acceptedSiteHeadRevisions[
                            selectedTab
                          ].revision || "NA"}
                        </div>

                        <div>Forwarded Revision</div>
                        <div>:</div>
                        <div style={{ marginLeft: "-130px" }}>
                          {singleDrawingData.acceptedSiteHeadRevisions[
                            selectedTab
                          ].revision || "NA"}
                        </div>
                      </>
                    )}
                  </>
                )}
                {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
                  <>
                    <div>Drawing File</div>
                    <div>:</div>
                    <div style={{ display: "flex", marginLeft: "-130px" }}>
                      <Icon.Image
                        size={20}
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() =>
                          handleViewFile(
                            singleDrawingData.drawingId,
                            singleDrawingData.acceptedSiteHeadRevisions[
                              selectedTab
                            ]?.revision,
                            singleDrawingData.acceptedSiteHeadRevisions[
                              selectedTab
                            ].from
                          )
                        }
                      />
                      <Icon.Download
                        size={20}
                        style={{ marginRight: "5px", cursor: "pointer" }}
                        onClick={() =>
                          handleDownload(
                            singleDrawingData.drawingId,
                            singleDrawingData.acceptedSiteHeadRevisions[
                              selectedTab
                            ]?.revision,
                            singleDrawingData.acceptedSiteHeadRevisions[
                              selectedTab
                            ].from
                          )
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          </DialogContent>
        </Dialog>
      </TableWrapper>
    </>
  );
};

export default SiteHeadDrawing;
