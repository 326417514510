/* eslint-disable react/no-children-prop */
import React, { useState, useEffect, useMemo } from "react";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import AddDrawingDialog from "./Components/AddDrawingDialog";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId, userInfo } from "../../../atoms";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import TableWrapper from "../../../Utilities/TableWrapper";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import ReceivedTable from "./ReceivedTable";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import { processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import { Tab1, Tabs1 } from "../../Tabs/Tabv2";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import Gallery from "../../../Images/Gallery.svg";
import Eye from "./IconsSiteHead/Eye.svg";
import Download from "../SITEHEAD/IconsSiteHead/Download.svg";
import { canShowFolder,canrfiRaised } from "../ShowFolder";
import ConsultantFilter from "../ConsultantFilter";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import styles from "./ApprovalTable.css"
import "./ApprovalTable.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", // Optional
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", // Removes the row borders
          },
        },
      },
    },
  },
});
const ApprovedTable = () => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();

  const [open, setOpen] = useState(false);
  const [currentUserInfo] = useRecoilState(userInfo);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [site, setSite] = useRecoilState(siteId);
  const [error, setError] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [registerList, setRegisterList] = useState([]);
  const [consultantValue, setConsultantValue] = useState('')
  const [editData, setEditData] = useState({
    drawingId: "",
    drawingNumber: "",
    expectedDate: "",
    requestedDate: todayDate(),
    remarks: "",
    drawingFileName: null,
    revision: [],
    natureofrequestedInfo: "",
  });
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [selectedRevision, setSelectedRevision] = useState("");

  // const handleSiteChange = (e) => {
  //   setSiteId(e.target.value);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    // fetchData();
  };
  const handleEditOpen = (drawingId) => {
    const drawingItem = drawingList.find((item) => item._id === drawingId);
    if (drawingItem) {
      const combinedRevisions = drawingItem.acceptedSiteRevisions.flatMap(
        (rev) => rev.revision
      );
      setEditData({
        drawingId: drawingId,
        drawingNumber: drawingItem.drawingNo,
        expectedDate: "", // Consider initializing this with a valid date if needed
        requestedDate: todayDate(),
        remarks: "",
        drawingFileName: null,
        selectedRevision: "", // Make sure this is included
        revision: combinedRevisions, // Ensure this is set as an array
        natureofrequestedInfo: "", // Ensure this key is initialized if used elsewhere
      });

      setEditDialogOpen(true);
    }
  };
  const handleRevisionSelection = (event) => {
    setSelectedRevision(event.target.value);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditData({
      drawingId: "",
      drawingNumber: "",
      expectedDate: "",
      remarks: "",
      drawingFileName: null,
      revision: [],
      natureofrequestedInfo: [],
    });
  };
  const [selectedTab, setSelectedTab] = useState(0);

  // const handleChevronClick = (index) => {
  //   setExpandedRevision(expandedRevision === index ? null : index);
  // };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    console.log(singleDrawingData, "singleDrawingData");
    setViewDialogOpen(true);
    if (drawingItem.acceptedSiteHeadRevisions?.length > 0) {
      setSelectedTab(drawingItem.acceptedSiteHeadRevisions?.length - 1);
    }
  };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  // const fetchInData = async () => {
  //   if (registerInfo && registerInfo.length > 0) {
  //     setDrawingList(registerInfo);
  //     setInitialDrawingList(registerInfo);
  //     console.log("fetched from recoil", registerInfo);
  //   } else {
  //     await updateRegister(setRegisterInfo, site).then((response) => {
  //       if (response.status === SUCESS_STATUS) {
  //         setDrawingList(response.registers);
  //         setInitialDrawingList(response.registers);
  //       }
  //     });
  //   }
  // };

  // const fetchData = async () => {
  //   await updateRegister(setRegisterInfo, site).then((response) => {
  //     if (response.status === SUCESS_STATUS) {
  //       setDrawingList(response.registers);
  //       setInitialDrawingList(response.registers);
  //     }
  //   });
  // };
  const fetchInData = async (site) => {
    try {
      const response = await webApi.get(
       `api/architect/drawingSiteLevel/${site}?filterType=upload`
      );
      console.log(response, "respponsedfdsfsdf");
      if (response.status === 200) {
        setDrawingList(response.data.data);
        setInitialDrawingList(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchInData(site);
  }, []);

  // const handleDownload = async (id, revision) => {
  //   setApiProcessing({
  //     loader: true,
  //     message: "Downloading...",
  //   });
  //   try {
  //     // Request the file with binary response handling
  //     //TODO: need to check this path correct or not
  //     const response = await webApi.getImage(
  //       api/architectureToRoRegister/${id}/revisionsSite/${revision},
  //       {
  //         responseType: "arraybuffer", // Expect binary data
  //       }
  //     );

  //     processFile(response, "download", navigate); // Process file for viewing
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   } finally {
  //     setApiProcessing({ loader: false, message: "" });
  //   }
  // };

  const handleDownload = async (id, revision, from) => {
    console.log(id, revision, from);
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      let apiEndpoint;

      if (from === "architect") {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisions/${revision}`;
      } else if (from === "ro") {
        apiEndpoint =`api/architectureToRoRegister/${id}/revisionsRo/${revision}`;
      } else {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisionsSiteHead/${revision}`;
      }

      const response = await webApi.getImage(apiEndpoint, {
        responseType: "arraybuffer", // Expect binary data
      });
      // Convert the response to a Blob and create a download link
      const blob = new Blob([response.data], {
        type: response.headers["content-type"] || "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `DrawingFile_${revision}.dwg`); // Set the file name with .dwg extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision, from,data) => {
    setApiProcessing({
      loader: true,
      message: "Viewing...",
    });
    try {
      let apiEndpoint;

      if (from === "architect") {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisions/${revision}`;
      } else if (from === "ro") {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisionsRo/${revision}`;
      } else {
        apiEndpoint = `api/architectureToRoRegister/${id}/revisionsSiteHead/${revision}`;
      }

      const response = await webApi.getImage(apiEndpoint, {
        responseType: "arraybuffer", // Expect binary data
      });

      console.log(response, "View API CALL");

      processFile(response,"view",navigate,revision,data,"SiteLevelApproval"); // Process file for downloading
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const listItems = [
    { id: "A - Missing Information", name: "A - Missing Information" },
    { id: "B - Clarification", name: "B - Clarification" },
    { id: "C - Additional Information", name: "C - Additional Information" },
    { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
  ];

  // Function to get the name from id
  const getNameFromId = (id) => {
    const item = listItems.find((item) => item.id === id);
    return item ? item.name : "";
  };

  const [currentView, setCurrentView] = useState("upload");

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const uniqueConsultant = useMemo(() => {
    const consultant = drawingList.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [drawingList]);

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase(); // Convert search input to lowercase and trim any extra spaces
    setSearchValue(searchInput);
    setSearchActive(true);

    if (!searchInput) {
      // fetchData(); // Fetch all drawings again if the search input is empty
      setSearchActive(false);
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        // Convert each field to lowercase for case-insensitive comparison
        const drawingNumber = drawingItem.drawingNo?.toLowerCase() || "";
        const drawingName = drawingItem.drawingTitle?.toLowerCase() || "";
        const consultant =
          drawingItem.designDrawingConsultant?.role.toLowerCase() || "";
        const category = drawingItem.category?.category?.toLowerCase() || "";
        const acceptedROSubmissionDate = drawingItem.acceptedROSubmissionDate
          ? new Date(drawingItem.acceptedROSubmissionDate)
              .toLocaleDateString()
              .toLowerCase()
          : "";
        const submittedROSubmissionDate = drawingItem.submittedROSubmissionDate
          ? new Date(drawingItem.submittedROSubmissionDate)
              .toLocaleDateString()
              .toLowerCase()
          : "";
        const dueDays = calculateDueDays(drawingItem.acceptedArchitectDate)
          .toString()
          .toLowerCase(); // Convert dueDays to lowercase for comparison
        const dueStatus =
          dueDays >= 0
            ? `${dueDays} days`
            : `${Math.abs(dueDays)} days overdue`;
        const revisions =
          drawingItem.acceptedSiteRevisions
            ?.map((revisionItem) => revisionItem.revision.toLowerCase())
            .join(" ") || "";

        // Filter based on the selected filter criteria
        switch (selectedFilter) {
          case "Drawing No":
            return drawingNumber.includes(searchInput);
          case "Drawing Consultant":
            return consultant.includes(searchInput);
          case "Drawing Category":
            return category.includes(searchInput);
          case "Drawing Title":
            return drawingName.includes(searchInput);
          case "Accepted RO Submission Date":
            return acceptedROSubmissionDate.includes(searchInput);
          case "Final Submission Date":
            return submittedROSubmissionDate.includes(searchInput);
          case "Due/Overdue":
            return (
              dueStatus.includes(searchInput) || dueDays.includes(searchInput)
            );
          case "Revision":
            return revisions.includes(searchInput);
          default:
            return (
              drawingNumber.includes(searchInput) ||
              drawingName.includes(searchInput) ||
              consultant.includes(searchInput) ||
              category.includes(searchInput) ||
              acceptedROSubmissionDate.includes(searchInput) ||
              submittedROSubmissionDate.includes(searchInput) ||
              dueStatus.includes(searchInput) ||
              dueDays.includes(searchInput) ||
              revisions.includes(searchInput)
            );
        }
      });

      setDrawingList(filteredDrawings); 
    }
  };
  // useEffect(()=>{
  //   const filterData = drawingList.filter((item)=>item.designDrawingConsultant?.role.includes(consultantValue))
  //   setDrawingList(filterData)
  // },[consultantValue])

  const handleDropdownClick = (filter) => {
    setSelectedFilter(filter);
    setShowDropdown(false); // Close dropdown after selection
  };
  const placeholderText = selectedFilter
    ? `Search by ${selectedFilter}`
    : "Search Drawing Lists";

  const calculateDueDays = (
    acceptedROSubmissionDate,
    softCopySubmittedDate = null
  ) => {
    if (!acceptedROSubmissionDate) return "-";

    const dateToCompare = softCopySubmittedDate
      ? new Date(softCopySubmittedDate)
      : new Date();
    const acceptedDate = new Date(acceptedROSubmissionDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // convert time difference to days

    return differenceInDays;
  };
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showEdit,setShowEdit] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
    console.log(showAddFolder,"rfiRaisedAccess111 && ");
    const showEdit = canrfiRaised(currentUserInfo, site);
    setShowEdit(showEdit);
    console.log(showEdit,"rfiRaisedAccess && 222");
  }, [currentUserInfo, site]);

  const updatedRows = useMemo(() => {
    return drawingList
      .filter((item) => item?.designDrawingConsultant?.role.includes(consultantValue))
      .map((row, index) => {
        const today = new Date();
        const dueDate = new Date(row.acceptedSiteSubmissionDate);
        const daysDifference = Math.ceil((dueDate - today) / (1000 * 60 * 60 * 24));
  
        const dueOverdue = {
          text: daysDifference >= 0 ? `${daysDifference} days due` : `${Math.abs(daysDifference)} days overdue`,
          color: daysDifference >= 0 ? "green" : "red",
        };
  
        return {
          ...row,
          serialNo: index + 1,
          drawingNo: row.drawingNo,
          consultant: row?.designDrawingConsultant?.role || "-",
          category: row.category?.category || "-",
          revision: row.acceptedSiteHeadRevisions?.length > 0
            ? row.acceptedSiteHeadRevisions
                .slice(-1)
                .map((revisionItem) => revisionItem.revision || "NA")
                .join(", ")
            : "-",
          dueOverdue,
        };
      });
  }, [drawingList, consultantValue]);
  
  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.consultant?.toLowerCase().includes(searchTermLower) ||
      row.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });
  


  const columns = useMemo(() => [
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 0.5,
      filterable: false, 
      sortable: false, 
    },
    {
      field: "drawingNo",
      headerName: "DRWG.No",
      flex: 1,
      filterable: true,
      sortable: true 
    },
    {
      field: "consultant",
      headerName: "Consultant",
      flex: 1,
      filterable: true,
      sortable: true
    },
    {
      field: "category",
      headerName: "Drawing Category",
      flex: 1.5,
      filterable: true,
      sortable: true
    },
    {
      field: "drawingTitle",
      headerName: "Drawing Title",
      flex: 2,
      filterable: true,
      sortable: true
    },
    {
      field: "dueOverdue",
      headerName: "Due/Overdue",
      flex: 1.5,
      filterable: false, 
      renderCell: (params) => (
        <span style={{ color: params.row.dueOverdue.color }}>
          {params.row.dueOverdue.text}
        </span>
      ),
    },
    {
      field: "revision",
      headerName: "Revision",
      flex: 1,
      filterable: true,
      sortable: true
      
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 0.5,
      filterable: false, 
      renderCell: (params) => (
        <img
          src={Eye}
          alt="view"
          onClick={() => handleViewOpen(params.row)}
          style={{
            marginTop:"24px",
            cursor: "pointer",
          }}
        />
      ),
    },
  ], [drawingList]);
  
  

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <h3 style={{ color: "var(--primary-color)", fontSize: "20px" }}>
                  Drawing Lists
                </h3>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    position: "relative",
                    width: "280px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder={placeholderText}
                    onChange={(e)=>setSearchValue(e.target.value)}
                    style={{
                      border: "1px solid var(--primary-color)",
                      width: "250px",
                      height: "40px",
                      paddingLeft: "15px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "35px",
                      top: "40%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />
                </div>
                {/* <Button
                  style={{
                    height: "40px",
                    marginLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "var(--primary-color)",
                    border: "1px solid var(--primary-color)",
                    borderRadius: "0px",
                  }}
                  onClick={handleClickOpen}
                >
                  <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
                  Add New
                </Button> */}
              </div>
            </div>

                <div style={{ marginTop: "10px" }}>
                  
                <Box
                  className="dataGridContainer"
                >
                  <ThemeProvider theme={theme}>
                  <DataGrid
                    columns={columns}
                    rows={filteredRows}
                    getRowId={(row) => row.drawingId}
                    rowHeight={60}
                    disableColumnMenu={false} // Keep the column menu for filtering
                    pagination={false}
                    hideFooter={true}
                    getRowSpacing={params=>({
                      top:params.isFirstVisible ? 0 : 5,
                      bottom:params.isLastVisible ? 0 : 5
                    })}
                    
                    getRowClassName={(params) =>
                      "customHeader" 
                    }
                    
                  />
                  </ThemeProvider>
                </Box>


                </div>
             
            
          </>
        }
      />
      <AddDrawingDialog
        handleDialogClose={handleDialogClose}
        open={open}
        site={site}
      />
      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 10,
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:'relative',
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "white",
              width:'100%',
              marginTop:'15px'
            }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              position:'absolute',
              cursor: "pointer",
             right:'10px',
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
        
            <div
              style={{
                display: "flex",
                gap: "0",
                border: "1px solid var(--primary-color)",
              }}
            >
                {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 ? (
                <Tabs1 value={selectedTab} onChange={handleTabChange}>
                  {singleDrawingData.acceptedSiteHeadRevisions.map(
                    (revisionItem, index) => (
                      <Tab1
                        key={index}
                        value={index}
                        label={`${revisionItem.revision}`}
                        style={{
                          backgroundColor:
                            singleDrawingData.acceptedSiteHeadRevisions.length ===
                              1 || selectedTab === index
                              ? "var(--primary-color)"
                              : "transparent",
                          color:
                            singleDrawingData.acceptedSiteHeadRevisions.length ===
                              1 || selectedTab === index
                              ? "white"
                              : "var(--primary-color)",
                          width: "150px",
                          height: "35px",
                          cursor: "pointer",
                          borderRight:
                            index <
                            singleDrawingData.acceptedSiteHeadRevisions.length - 1
                              ? "1px solid var(--primary-color)"
                              : "none",
                        
                        }}
                      />
                    )
                  )}
                </Tabs1>
              ) : (
                <div>No Revisions</div>
              )}
            </div>
        
        </div>
        {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
  <>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {selectedTab ===
        singleDrawingData.acceptedSiteHeadRevisions.length - 1 ? (
          singleDrawingData.acceptedSiteHeadRevisions[selectedTab]
            ?.rfiStatus === "Raised" ||
          singleDrawingData.acceptedSiteHeadRevisions[selectedTab]
            ?.currentRevisionRfiStatus === "Raised" ? (
              <div style={{ color: "orange" }}>
                RFI has been raised, Don't use in the site
              </div>
            ) : (
              <div style={{ color: "green" }}>Good For Construction</div>
            )
          ) : (
            <div style={{ color: "red" }}>Superseeded</div>
          )}
        </div>
      )}
    </div>
  </>
)}

        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "70vh",
            overflowY: "auto",
          }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>
              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.designDrawingConsultant?.role || "NA"}
              </div>
              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>
              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>
              <>
                {currentUserInfo.permittedSites.some(
                  (site) => site.enableModules.drawingAddFolder
                ) ? (
                  <>
                    
                    <div>Folder</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-130px" }}>
                      {singleDrawingData?.folderId?.folderName || "NA"}
                    </div>
                  </>
                ) : (""
                )}
              </>
              <div>Submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.acceptedSiteSubmissionDate
                  ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedSiteHeadRevisions[selectedTab] && (
                    <>
                      <div>Soft Copy Submitted Date</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-130px" }}>
                        {singleDrawingData.acceptedSiteHeadRevisions[
                          selectedTab
                        ].softCopySubmittedDate
                          ? formatDate(
                              singleDrawingData.acceptedSiteHeadRevisions[
                                selectedTab
                              ].softCopySubmittedDate
                            )
                          : "No Submitted Date"}
                      </div>
                    </>
                  )}
                </>
              )}
              {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedSiteHeadRevisions[selectedTab] && (
                    <>
                      <div>RO Forwarded Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-130px" }}>
                        {singleDrawingData.acceptedSiteHeadRevisions[
                          selectedTab
                        ].revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}
              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedRORevisions[selectedTab] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-130px" }}>
                        {singleDrawingData.acceptedRORevisions[selectedTab]
                          .revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}
              {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
                <>
                  <div>Drawing File</div>
                  <div>:</div>
                  <div style={{ display: "flex", marginLeft: "-130px" }}>
                    <img
                    src={Gallery}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        handleViewFile(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedSiteHeadRevisions[
                            selectedTab
                          ]?.revision,
                          singleDrawingData.acceptedSiteHeadRevisions[
                            selectedTab
                          ].from,singleDrawingData
                        )
                      }
                    />
                    {/* <img
                    src={Download}
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() =>
                        handleDownload(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedSiteHeadRevisions[
                            selectedTab
                          ]?.revision,
                          singleDrawingData.acceptedSiteHeadRevisions[
                            selectedTab
                          ].from
                        )
                      }
                    /> */}
                  </div>
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>
      <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue} />
    </>
  );
};

export default ApprovedTable;