/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
// SiteheadReceivedTable.js
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import pen from "../IconsSiteHead/penIcon.svg";
import Gallery from "../../../../Images/Gallery.svg";
import { WebApimanager } from "../../../../WebApiManager";
import { siteId, userInfo } from "../../../../atoms";
import { useRecoilState } from "recoil";
import ViewDrawingModal from "./ViewDrawingModal";
import RFIPopUp from "./RFIPopUp";
import { FaGalacticSenate } from "react-icons/fa6";
import { RxCross1 } from "react-icons/rx";
import { Tab1, Tabs1 } from "../../../Tabs/Tabv2";
import { RiShareForwardLine } from "react-icons/ri";
import {
  calculateDueDays,
  formatDate,
  todayDate,
} from "../../../../Utilities/DateUtils";
import { processFile } from "../../BackendUtils";
import { Loader } from "../../../../Widgets/notificationFeedbacks";

import { Dialog, DialogContent } from "../../../Dialog/Dialog";
import { FaFilter } from "react-icons/fa";


import { canrfiRaised, canShowFolder } from "../../ShowFolder";
import { MdCancel } from "react-icons/md";
import ConsultantFilter from "../../ConsultantFilter";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../../Drawing/Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", // Optional
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", // Removes the row borders
          },
        },
      },
    },
  },
});

const SiteheadReceivedTable = ({
  // searchTerm,
  forwardAccess,
  rfiRaisedAccess,
}) => {
  const webApi = new WebApimanager();
  const [issess, setIssuss] = useState([]);
  const [site] = useRecoilState(siteId);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const navigate = useNavigate();
  const [userDetails] = useRecoilState(userInfo);
  const companyID = userDetails[0]?.companyId;
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [roHardCopyRevisionList, setRoHardCopyRevisionList] = useState([]);
  const [consultantValue, setConsultantValue] = useState('')
  useEffect(() => {
    console.log(userDetails, "userDetails");
  }, [userDetails]);
  useEffect(() => {
    const fetchIssuedData = async () => {
      try {
        const response = await webApi.get(
          `api/architect/drawingSiteHead/${site}?filterType=upload`
        );
        console.log("Issued Data:vcsdhsdg", response.data.data);
        setIssuss(response.data.data);
      } catch (error) {
        console.error("Error fetching issued data:", error);
      }
    };

    fetchIssuedData();
  }, [site]);
  const handleClose = () => {
    setEditDialogOpen(false);
  };
  const filteredData = issess.filter((item) => {
    return (
      item.drawingNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.drawingTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.designDrawingConsultant?.role
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.category?.category?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleViewOpen = (drawingItem) => {
    console.log(drawingItem, "drawingItem in stehead Issued");
    if (drawingItem.acceptedRORevisions?.length > 0) {
      setSelectedTab(drawingItem.acceptedRORevisions?.length - 1);
    }
    const allHardCopyRev = [];
    if (drawingItem?.acceptedSiteHeadHardCopyRevisions?.length) {
      drawingItem?.acceptedSiteHeadHardCopyRevisions?.forEach((item) => {
        if (!allHardCopyRev.includes(item?.revision)) {
          allHardCopyRev.push(item.revision);
        }
      });
    }
    console.log(allHardCopyRev, "allllllllllHardCopyRev");
    setRoHardCopyRevisionList(allHardCopyRev);

    setViewDialogOpen(true);
    setOpen(false);
    setEditDialogOpen(false);
    setSingleDrawingData(drawingItem);
  };

  const handleViewClose = () => {
    setViewDialogOpen(false);
    setSelectedDrawing(null);
  };
  const handleEditOpen = (drawingDatas) => {
    setEditData(drawingDatas);
    setEditDialogOpen(true);
    setViewDialogOpen(false);
  };
  const handleDownload = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/revisions/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const handleViewFile = async (id,revision,data) => {
    setApiProcessing({
      loader: true,
      message: "opening...",
    });
    try {
      // Make the request to get the response based on the updated endpoint
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/revisions/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "view",navigate,revision,data,"siteHeadApproval"); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const handlePDFDownload = async (filename) => {
    console.log(filename, "fiename");
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      //TODO: need to check this path correct or not
      const response = await webApi.getImage(
        `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
        // `api/architectureToRoRequested/${id}?revision=${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewPDFFile = async (filename) => {
    console.log(filename, "getting inside");
    try {
      setApiProcessing({ loader: true, message: "Viewing" });

      // Make the request without explicitly setting the responseType
      const response = await webApi.getImage(
        `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );
      console.log("Image fot APPROVED", response);

      processFile(response, "view", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const handleForwardClick = async (singledrawingdata, revisionNo) => {
    const registerId = singledrawingdata.drawingId;

    // Find the specific revision data in acceptedRORevisions
    const selectedRevision = singledrawingdata.acceptedRORevisions.find(
      (revision) => revision?.currentRevision === `R${revisionNo}`
    );
    console.log(selectedRevision, "revision");

    if (!selectedRevision) {
      console.log("Revision not found");
      return;
    }
    setApiProcessing({ loader: true, message: "forwarding..." });

    try {
      const ApprovalFormData = new FormData();
      // ApprovalFormData.append("softCopySubmittedDate", todayDate());
      // ApprovalFormData.append("changes", selectedRevision.changes || "");
      // ApprovalFormData.append("issuedSoftCopy", "Yes");
      ApprovalFormData.append("roRef", selectedRevision.currentRevisionId);
      // ApprovalFormData.append("drawingFileName", selectedRevision.drawingFileName);
      // ApprovalFormData.append("pdfDrawingFileName", selectedRevision.pdfDrawingFileName);
      ApprovalFormData.append("siteHeadType", "Forwarded");
      console.log("Submitting Approval data:", ApprovalFormData);
      const response = await webApi.imagePut(
        `api/architectureToRoRegister/revisions/${registerId}?revisionType=acceptedSiteHeadRevisions`,
        ApprovalFormData
      );

      if (response.status === 200) {
        setApiProcessing({ loader: false, message: "" });
        handleClose();
        // fetchIssuedData();
        window.location.reload();
      } else {
        console.error("Failed to submit the form:", response);
        setApiProcessing({ loader: false, message: "" });
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      alert("An error occurred. Please try again later.");
    }
  };

  const dropdownRef = useRef(null);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isCategoriesDropdownOpen, setCategoriesDropdownOpen] = useState(false);
  const [isFoldersDropdownOpen, setFoldersDropdownOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectedConsultant, setSelectedConsultant] = useState("");
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
    
  };

  const handleType = (type) => {
    if (type === "all") {
      setSelectedCategory("");

      setFilterDropdownOpen(false);
      setCategoriesDropdownOpen(false);
      setFoldersDropdownOpen(false);
    } else {
      setSelectedCategory(type);
      setFilterDropdownOpen(false);
      setFoldersDropdownOpen(false);
    }
  };

  const uniqueFolders = useMemo(() => {
    const folder = issess.map((item) => item.folderId?.folderName);
    return [...new Set(folder)];
  }, [issess]);

  const uniqueCategories = useMemo(() => {
    const categories = issess.map((item) => item.category?.category);
    return [...new Set(categories)];
  }, [issess]);

  const uniqueConsultant = useMemo(() => {
    const consultant = issess.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [issess]);

  console.log(uniqueCategories, uniqueFolders, "SITE Head Upload categories");

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [filteredGoData, setFilteredGoData] = useState(null); // For displaying the result

  const [showFilteredData, setShowFilteredData] = useState(false); // State to control the display

  const handleMouseEnter = (category) => {
    setHoveredCategory(category);
    if (hoverTimeout) clearTimeout(hoverTimeout);
    const timeout = setTimeout(() => {
      setHoveredCategory(null);
    }, 9000);
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) clearTimeout(hoverTimeout);
  };


  const handleConsultantSelect = (consultant) => {
    setSelectedConsultant(consultant)
    setShowFilteredData(false);
      
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(
      (prevSelected) =>
        prevSelected.includes(category)
          ? prevSelected.filter((c) => c !== category) // Remove if already selected
          : [...prevSelected, category] // Add if not selected
    );
    setShowFilteredData(false);
  };

  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
    setShowFilteredData(false);
  };

  const handleGoClick = () => {
    console.log("Selected Consultants:", selectedConsultant);
    console.log("Selected Categories:", selectedCategory);
    console.log("Selected Folders:", selectedFolder);
    if(selectedConsultant || selectedCategory.length || selectedFolder){
    setShowFilteredData(true);
    toggleFilterDropdown();
    }
  };

  useEffect(() => {
    console.log("Selected showFilteredData:", showFilteredData);
  });

  // const filteredDataAndUploadData = useMemo(() => {
  //   if (!showFilteredData) {
  //   return issess;
  // }
  //   return [...issess].filter((item) =>{ 
  //     const matchesConsultant = selectedConsultant === "" ||  item?.designDrawingConsultant?.role === selectedConsultant;

  //   const matchesCategory = selectedCategory.length === 0 ||  selectedCategory.includes(item?.category?.category);

  //   const matchesFolder = 
  //     !selectedFolder || item.folderId?.folderName === selectedFolder;

  //   return matchesConsultant && matchesCategory && matchesFolder; 
  // });
  // }, [showFilteredData, selectedConsultant, selectedCategory, selectedFolder, issess]);
  useEffect(()=>{console.log(selectedCategory,"selectedCategory")},[selectedConsultant,selectedCategory,selectedFolder])

  const handleClearAll = () =>{
    setSelectedCategory([])
    setSelectedFolder("")
    setSelectedConsultant("")
  }
  const handleFilterCancel = () =>{
    toggleFilterDropdown()
    setShowFilteredData(false)

  }
  const handleRemove = (type, value) => {
    setShowFilteredData(false)

    if (type === "consultant") {
      setSelectedConsultant("");
    } else if (type === "category") {
      setSelectedCategory(selectedCategory.filter((cat) => cat !== value));
    } else if (type === "folder") {
      setSelectedFolder("");
    }
  };
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showEdit,setShowEdit] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(userDetails, site);
    setShowAddFolder(showFolder);
    console.log(showAddFolder,"rfiRaisedAccess111 && ");
    const showEdit = canrfiRaised(userDetails, site);
    setShowEdit(showEdit);
    console.log(showEdit,"rfiRaisedAccess && 222");
  }, [userDetails, site]);
  console.log(userDetails,"checking details");

  const updatedRows = issess.filter((item)=>item?.designDrawingConsultant?.role.includes(consultantValue)).map((row, index) => ({
    ...row,
    serialNo: index + 1, 
    drawingNo: row.drawingNo || "NA",
    consultant: row?.designDrawingConsultant?.role || "-",
    category: row?.category?.category || "-",
    drawingTitle: row.drawingTitle || "NA",
    softCopyDate: row.acceptedRORevisions?.length > 0
    ? formatDate(
        row.acceptedRORevisions[
          row.acceptedRORevisions.length - 1
        ]?.softCopySubmittedDate
      )
    : "-",
    revision: row.acceptedRORevisions?.length > 0
    ? row.acceptedRORevisions
        .slice(-1)
        .map((rev) => rev.revision)
        .join(", ")
    : "N/A",
    forwardedRevision:  row.acceptedSiteHeadRevisions?.length > 0
    ? row.acceptedSiteHeadRevisions
        .slice(-1)
        .map((rev) => rev.revision)
        .join(", ")
    : "-",
  }));

  const filteredRows = useMemo(() => {
    if (!searchTerm) return updatedRows;
    return updatedRows.filter((row) => {
      return Object.values(row).some((value) =>
        value ? value.toString().toLowerCase().includes(searchTerm.toLowerCase()) : false
      );
    });
  }, [searchTerm, updatedRows]);

  const columns = useMemo(() => [
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 0.5,
      filterable: false, 
      sortable: false, 
    },
    {
      field: "drawingNo",
      headerName: "DRWG No",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "consultant",
      headerName: "Consultant",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "category",
      headerName: "Drawing Category",
      flex: 1.5,
      filterable: true,
      sortable: true,
    },
    {
      field: "drawingTitle",
      headerName: "Drawing Title",
      flex: 2,
      filterable: true,
      sortable: true,
      
    },
    {
      field: "softCopyDate",
      headerName: "Soft Copy Submission Date",
      flex: 1,
      filterable: true,
      sortable: true,
      
    },
    {
      field: "revision",
      headerName: "Revision",
      flex: 1,
      filterable: true,
      sortable: true,
      
    },
    {
      field: "forwardedRevision",
      headerName: "Forwarded Revision",
      flex: 1,
      filterable: true,
      sortable: true,
      
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1.5,
      filterable: false, // Disable filtering
      renderCell: (params) => {
        const item = params.row;
    
        const latestRevision = item.acceptedRORevisions?.length > 0
          ? item.acceptedRORevisions[item.acceptedRORevisions.length - 1]
          : null;
    
        const softCopyReceived = latestRevision?.issuedSoftCopy === "Yes";
        const hardCopyReceived = item.acceptedSiteHeadHardCopyRevisions.some(hardCopyRevision => 
          hardCopyRevision.revision === latestRevision?.revision
        );
    
        const softCopySubmittedDate = new Date(latestRevision?.softCopySubmittedDate);
        const currentDate = new Date();
        const siteHeadRfiTimeStampDays = item.siteHeadRfiTimeStampDays || 0;
        const diffInTime = currentDate - softCopySubmittedDate;
        const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));
    
        const isFilesReceived = softCopyReceived && hardCopyReceived;
    
        // Check whether the pen icon should be displayed
        const showPenIcon =
          (rfiRaisedAccess && item.acceptedRORevisions.length > 0 && isFilesReceived && diffInDays >= siteHeadRfiTimeStampDays) ||
          (!rfiRaisedAccess && diffInDays >= siteHeadRfiTimeStampDays);
    
        return (
          <div style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: "24px" }}>
            {/* Conditionally render the pen icon */}
            {showPenIcon && (
              <img
                src={pen}
                alt="edit"
                style={{ cursor: "pointer" }}
                onClick={() => handleEditOpen(item)}
              />
            )}
            {/* View and Download Icons */}
            <img
              src={Eye}
              alt="view"
              onClick={() => handleViewOpen(item)}
              style={{ cursor: "pointer" }}
            />
            <img
              src={Download}
              alt="download"
              style={{ cursor: "pointer" }}
              onClick={() => {
                const latestRevision = item.acceptedRORevisions.length > 0
                  ? item.acceptedRORevisions[item.acceptedRORevisions.length - 1]
                  : null;
                handleDownload(item.drawingId, latestRevision);
              }}
            />
          </div>
        );
      },
    }
  ], [issess]); 
  

  
  
  return (
    <>
      <div
        style={{
          display: "flex",
          float: "right",
          margin: "-50px 9px",
          gap: "0px",
        }}
      >
        <input
          placeholder="Search Drawing Lists"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            paddingLeft: "20px",
            border: "1px solid var(--primary-color)",
            width: "250px",
            height: "40px",
            marginRight: "10px",
          }}
        />
        {/* <div ref={dropdownRef} style={{ position: "relative" }}>
          <div onClick={toggleFilterDropdown}>
            <FaFilter
              size={39}
              style={{
                border: "1px solid var(--primary-color)",
                padding: "5px",
                marginRight: "-10px",
              }}
            />
          </div>
          {isFilterDropdownOpen && (
            <div
              style={{
                position: "absolute",
                background: "white",
                borderRadius: "4px",
                zIndex: 1000,
                textAlign: "left",
                marginTop: "20px",
                marginLeft: "-558px",
                padding: "15px",
                width: "500px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: selectedConsultant || selectedCategory.length || selectedFolder?"space-between":"flex-end",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginTop:"10px"
                }}
              >
                
                <MdCancel style={{position:"absolute",fontSize:"23px" ,right:"-10px",top:"-24px",cursor:"pointer"}}onClick={handleFilterCancel} />
                {(selectedConsultant || selectedCategory.length || selectedFolder) && (
                    <button
                      style={{
                        padding: "6px 10px",
                        backgroundColor: "crimson",
                        color: "white",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearAll}
                    >
                      Clear All
                    </button>
                  )}
                  <button
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                      fontSize: "16px",
                      padding: "5px 15px",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginBottom: "5px",
                    }}
                    onClick={handleGoClick}
                  >
                    Go
                  </button>
                  
                
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginBottom: "15px",
                }}
              >
                {selectedConsultant && (
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("consultant", selectedConsultant)}
                  >
                    {selectedConsultant} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                )}

                {selectedCategory.map((category, index) => (
                  <button
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("category", category)}
                  >
                    {category} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                ))}

                {selectedFolder && (
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("folder", selectedFolder)}
                  >
                    {selectedFolder} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                )}
              </div>

              <div style={{ flex: 3 }}>
                <span style={{ cursor: "pointer", position: "relative" }}>
                  <p style={{ color: "black", fontSize: "20px" }}>Consultant</p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "8px",
                    }}
                  >
                    {uniqueConsultant.map((consultant, index) => (
                      <div
                        key={index}
                        style={{
                          flex: "1 1 calc(25.33% - 15px)",
                          backgroundColor:
                            selectedConsultant === consultant ? "#ffd700" : "#e0f7fa",
                          fontWeight:
                            selectedConsultant === consultant ? "700" : "500",
                          borderRadius: "4px",
                          textAlign: "center",
                          fontSize: "14px",
                          padding: "5px",
                          color: selectedConsultant === consultant ? "white" : "#333",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() => handleConsultantSelect(consultant)}
                      >
                        {consultant}
                      </div>
                    ))}
                  </div>
                </span>

                        <span style={{ cursor: "pointer", position: "relative" }}>
                          <p style={{ color: "black", fontSize: "20px" }}>Categories</p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                            }}
                          >
                            {uniqueCategories.map((category, index) => (
                              <div
                                key={index}
                                style={{
                                  flex: "1 1 calc(25.33% - 15px)",
                                  backgroundColor:  selectedCategory.includes(category)?"#ffd700":"#e0f7fa",
                                  fontWeight: selectedCategory.includes(category)?"700":"500",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: "5px",
                                  color: selectedCategory.includes(category)?"white":"#333",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() => handleCategorySelect(category)}
                              >
                                {category}
                              </div>
                            ))}
                          </div>
                        </span>

                        {showAddFolder?<span style={{ cursor: "pointer", position: "relative" }}>
                          <p
                            style={{
                              color: "black",
                              fontSize: "20px",
                              marginTop: "10px",
                            }}
                          >
                            Folders
                          </p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                            }}
                          >
                            {uniqueFolders.map((folder, index) => (
                              <div
                                key={index}
                                style={{
                                  flex: "1 1 calc(25.33% - 15px)",
                                  backgroundColor: "#e0f7fa",
                                  fontWeight: selectedFolder === folder ? "700" : "500",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: "5px",
                                  color: "#333",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() => handleFolderSelect(folder)}
                              >
                                {folder}
                              </div>
                            ))}
                          </div>
                        </span>:""}
                        <>
                          
                        </>
                      </div>
                    </div>
                  )}
        </div> */}
      </div>

      <div style={{ marginTop: "10px" }}>
                        
        <Box
          className="dataGridContainer"
        >
          <ThemeProvider theme={theme}>
          <DataGrid
            columns={columns}
            rows={filteredRows}
            getRowId={(row) => row.drawingId}
            rowHeight={60}
            disableColumnMenu={false} // Keep the column menu for filtering
            pagination={false}
            hideFooter={true}
            getRowSpacing={params=>({
              top:params.isFirstVisible ? 0 : 5,
              bottom:params.isLastVisible ? 0 : 5
            })}
            
            getRowClassName={(params) =>
              "customHeader" 
            }
            
          />
          </ThemeProvider>
        </Box>


      </div>

      {/* <ViewDrawingModal
        open={viewDialogOpen}
        onClose={handleViewClose}
        drawingData={selectedDrawing}
        forwardAccess={forwardAccess}
      /> */}
      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 10,
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <p style={{ width:"100%",textAlign:"center",marginTop:"15px", color: "white" }}>View</p>
          <RxCross1
            size={20}
            style={{
              cursor: "pointer",
              color: "white",
              position:"absolute",
              right:"10px"
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {singleDrawingData?.acceptedRORevisions?.length > 0 ? (
              <Tabs1 value={selectedTab} onChange={handleTabChange}>
                {singleDrawingData.acceptedRORevisions.map(
                  (revisionItem, index) => (
                    <Tab1
                      key={index}
                      value={index}
                      label={`${revisionItem.revision}`}
                      style={{
                        backgroundColor:
                          singleDrawingData.acceptedRORevisions.length === 1 ||
                          selectedTab === index
                            ? "var(--primary-color)"
                            : "transparent",
                        color:
                          singleDrawingData.acceptedRORevisions.length === 1 ||
                          selectedTab === index
                            ? "white"
                            : "var(--primary-color)",
                        width: "150px",
                        height: "35px",
                        cursor: "pointer",
                        borderRight:
                          index <
                          singleDrawingData.acceptedRORevisions.length - 1
                            ? "1px solid var(--primary-color)"
                            : "none",
                      }}
                    />
                  )
                )}
              </Tabs1>
            ) : (
              <div>No Revisions</div>
            )}
          </div>
        </div>
        {singleDrawingData?.acceptedRORevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {/* {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedTab ===
                  singleDrawingData.acceptedRORevisions.length - 1 ? (
                    <div style={{ color: "green" }}>Good For Construction</div>
                  ) : (
                    <div style={{ color: "red" }}>Superseeded</div>
                  )}
                </div>
              )} */}

              {/* {singleDrawingData?.acceptedRORevisions?.length > 0 &&
                selectedTab <=
                  singleDrawingData.acceptedRORevisions.length - 1 && (
                  <>
                    {(() => {
                      const selectedRevision =
                        singleDrawingData.acceptedRORevisions[selectedTab];
                      const hardCopyRevision =
                        singleDrawingData.acceptedSiteHeadHardCopyRevisions?.[0]
                          ?.revision;

                      if (selectedRevision?.revision === hardCopyRevision) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              color: "green",
                            }}
                          >
                            Good For Construction
                          </div>
                        );
                      } else if (
                        selectedRevision?.currentRevisionRfiStatus === "Raised"
                      ) {
                        if (!hardCopyRevision) {
                          return (
                            <p
                              style={{
                                color: "#FFB200",
                                textAlign: "center",
                                fontSize: "16px",
                              }}
                            >
                              RFI has been raised. Hardcopy not received
                            </p>
                          );
                        } else {
                          return (
                            <p
                              style={{
                                color: "#FFB200",
                                textAlign: "center",
                                fontSize: "16px",
                              }}
                            >
                              RFI has been raised
                            </p>
                          );
                        }
                      } else if (!hardCopyRevision) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              color: "red",
                            }}
                          >
                            No hardcopy file received
                          </div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              color: "red",
                            }}
                          >
                            Superseded
                          </div>
                        );
                      }
                    })()}
                  </>
                )} */}

              {singleDrawingData?.acceptedRORevisions?.length > 0 &&
                selectedTab <=
                  singleDrawingData.acceptedRORevisions.length - 1 && (
                  <>
                    {(() => {
                      const selectedRevision =
                        singleDrawingData.acceptedRORevisions[selectedTab];
                      const hardCopyRevision =
                        singleDrawingData?.acceptedSiteHeadHardCopyRevisions[
                          singleDrawingData?.acceptedSiteHeadHardCopyRevisions
                            ?.length - 1
                        ]?.revision;

                      console.log(hardCopyRevision, "hardCopyRevisionsssss");

                      if (
                        singleDrawingData?.acceptedRORevisions[
                          singleDrawingData?.acceptedRORevisions?.length - 1
                        ]?.revision === hardCopyRevision
                      ) {
                        if (
                          selectedRevision?.currentRevisionRfiStatus ===
                          "Not Raised"
                        ) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                                color: "green",
                              }}
                            >
                              Good For Construction
                            </div>
                          );
                        } else {
                          return (
                            <p
                              style={{
                                color: "#FFB200",
                                textAlign: "center",
                                fontSize: "16px",
                              }}
                            >
                              RFI has been raised
                            </p>
                          );
                        }
                      } else if (
                        selectedRevision?.currentRevisionRfiStatus === "Raised"
                      ) {
                        return (
                          <p
                            style={{
                              color: "#FFB200",
                              textAlign: "center",
                              fontSize: "16px",
                            }}
                          >
                            RFI has been raised. Hardcopy file not received
                          </p>
                        );
                      } else if (
                        hardCopyRevision === selectedRevision?.revision
                      ) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              color: "red",
                            }}
                          >
                            Superseeded
                          </div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              color: "red",
                            }}
                          >
                            No hardcopy file received
                          </div>
                        );
                      }
                    })()}
                  </>
                )}
            </div>
          </>
        )}
        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "70vh",
            overflowY: "auto",
          }}
        >
          <>
            {/* {  forwardAccess &&
      singleDrawingData?.acceptedRORevisions?.length > 0 &&
      (selectedTab === singleDrawingData.acceptedRORevisions.length - 1) && (
    <>
          {singleDrawingData.acceptedRORevisions[selectedTab].rfiStatus==="Raised" && 
          <>
<p style={{color:'#FFB200',textAlign:'center',fontSize:'16px'}}>RFI has been raised. Don't use on the site</p>
          </>}
          </>
      )} */}
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.designDrawingConsultant?.role || "NA"}
              </div>
              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>
              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>
              <>
                { showAddFolder ? (
                  <>
                    <div>Folder</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-130px" }}>
                      {singleDrawingData?.folderId?.folderName || "NA"}
                    </div>
                  </>
                ) : (
                  console.log("Add Folder Name is hidden.")
                )}
              </>
              <div>Submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.acceptedSiteSubmissionDate
                  ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedRORevisions[selectedTab] && (
                    <>
                      <div>Soft Copy Submitted Date</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-130px" }}>
                        {singleDrawingData.acceptedRORevisions[selectedTab]
                          .softCopySubmittedDate
                          ? formatDate(
                              singleDrawingData.acceptedRORevisions[selectedTab]
                                .softCopySubmittedDate
                            )
                          : "No Submitted Date"}
                      </div>
                    </>
                  )}
                </>
              )}

              {forwardAccess &&
              singleDrawingData?.acceptedRORevisions?.length > 0 &&
              singleDrawingData?.acceptedRORevisions[selectedTab]
                .currentRevisionRfiStatus !== "Raised" &&
              roHardCopyRevisionList.length &&
              roHardCopyRevisionList.includes(
                singleDrawingData?.acceptedRORevisions[selectedTab]?.revision
              ) ? (
                <>
                  <div>Issued Soft Copy</div>
                  <div>:</div>
                  <div style={{ marginLeft: "-130px" }}>
                    {singleDrawingData?.acceptedRORevisions?.length &&
                    singleDrawingData?.acceptedRORevisions[
                      singleDrawingData.acceptedRORevisions?.length - 1
                    ].roRevisionStatus === "Not Forwarded" ? (
                      <button
                        style={{
                          display: "flex",
                          backgroundColor: "#F3F3F3",
                          border: "1px solid #97979740",
                          color: "#4d4d4d",
                          padding: "6px 10px",
                          alignItems: "center",
                          gap: "10px",
                        }}
                        onClick={() =>
                          handleForwardClick(
                            singleDrawingData,
                            singleDrawingData?.acceptedRORevisions?.length - 1
                          )
                        }
                      >
                        Forward <RiShareForwardLine />
                      </button>
                    ) : (
                      <p>Forwarded</p>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {singleDrawingData?.acceptedSiteHeadRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedSiteHeadRevisions[selectedTab] && (
                    <>
                      <div>Architect Forwarded Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-130px" }}>
                        {singleDrawingData.acceptedSiteHeadRevisions[
                          selectedTab
                        ].revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}
              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedRORevisions[selectedTab] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-130px" }}>
                        {singleDrawingData.acceptedRORevisions[selectedTab]
                          .revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}

              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  <div>Drawing File</div>
                  <div>:</div>
                  <div style={{ display: "flex", marginLeft: "-130px" }}>
                    <img src={Gallery}
                      // src={gallery}
                      // alt="gallery"
                      size={20}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        handleViewFile(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedRORevisions[selectedTab]?.revision,
                          singleDrawingData
                        )
                      }
                    />
                    <img src={Download}
                      size={20}
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() =>
                        handleDownload(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedRORevisions[selectedTab]?.revision
                        )
                      }
                    />
                  </div>
                </>
              )}
              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedRORevisions[selectedTab] && (
                    <>
                      <div>PDF File</div>
                      <div>:</div>
                      <div style={{ display: "flex", marginLeft: "-130px" }}>
                        <img src={Gallery}
                          size={20}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewPDFFile(
                              singleDrawingData.acceptedRORevisions[selectedTab]
                                ?.pdfDrawingFileName
                            )
                          }
                        />
                        <img src={Download}
                          size={20}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handlePDFDownload(
                              singleDrawingData.acceptedRORevisions[selectedTab]?.pdfDrawingFileName
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>

      {editDialogOpen && (
        <RFIPopUp
          open={editDialogOpen}
          onClose={handleClose}
          tableRowData={editData}
          apiData={issess}
          filter="raised"
        />
      )}
      <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue}/>
    </>
  );
};

export default SiteheadReceivedTable;
