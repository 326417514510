/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import DrawingStatus from "./DrawingStatus";
import RfiStatus from "./RfiStatus";
import DrawingChart from "./DrawingChart";
import RfiChart from "./RfiChart";
import { usePDF } from "react-to-pdf";
import TablePage from "./TablePage";
import PdfIcon from "../../icons/pdf-icon";
import XlsIcon from "../../icons/xls-icon";
import CsvIcon from "../../icons/csv-icon";
import { siteId, userInfo } from "../../../atoms";
import { useRecoilState } from "recoil";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";
import "./Analysis.css";

const Analysis = (props) => {
  const webApi = new WebApimanager();
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [existingConsultants, setExistingConsultants] = useState([]);
  const userDetails = useRecoilState(userInfo);
  const userrole = userDetails[0].role;
  console.log(userDetails, "analyasis userdetails");
  console.log(userrole, "analyasis userdetails role");
  console.log("Analysis Props", props);

  // const loginUserRoleId = xyz;
  const [formData, setFormData] = useState({
    reportType: "",
    fromtoType: "",
    designConsultant: "",
    timePeriod: "",
    fromDate: "",
    toDate: "",
    month: "",
    year: "",
    selectViewDownload: "",
    fileFormat: "",
    consultantRole: "",
  });
  const [error, setError] = useState({});
  const [popupcontent, setPopupContent] = useState();
  const [site, setSite] = useRecoilState(siteId);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // const getAll = () => {
  //   webApi
  //     .get(`api/designDrawingConsultant/getAll?siteId=${site}`)
  //     .then((data) => {
  //       if (data) {
  //         // if(type="architectToRo")
  //         setExistingConsultants(data.data.data.users);
  //       }
  //     });
  // };
  const getAll = () => {
    webApi
      .get(`api/designDrawingConsultant/getAll?siteId=${site}`)
      .then((data) => {
        if (data) {
          if (props.type === "architect") {
            console.log(props.type, "propsTYpe");

            const filteredConsultants = data.data.data.users.filter(
              (user) => user.role === userrole
            );
            setExistingConsultants(filteredConsultants);
            console.log(existingConsultants, "filter,propsTYpe", props.type);
          } else {
            setExistingConsultants(data.data.data.users); // If no filter is needed
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching consultants", error);
      });
  };

  useEffect(() => {
    if (existingConsultants.length === 0) {
      getAll();
    }
    console.log(existingConsultants, "existing consultants", props.type);
  }, [existingConsultants]);

  useEffect(() => {
    const today = new Date();
    if (formData.timePeriod === "Last 6 Months") {
      const sixMonthsAgo = new Date(today);
      sixMonthsAgo.setMonth(today.getMonth() - 6);
      setFormData((prevData) => ({
        ...prevData,
        fromDate: formatDate(sixMonthsAgo),
        toDate: formatDate(today),
      }));
    } else if (formData.timePeriod === "fromBeginningToTillDate") {
      setFormData((prevData) => ({
        ...prevData,
        fromDate: "",
        toDate: "",
      }));
    }
  }, [formData.timePeriod]);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const resetForm = () => {
    setFormData({
      reportType: "",
      fromtoType: "",
      designConsultant: "",
      timePeriod: "",
      fromDate: "",
      toDate: "",
      month: "",
      year: "",
      selectViewDownload: "",
      fileFormat: "",
    });
  };

  const formValidate = () => {
    let errorType = {};

    if (formData.reportType === "") {
      errorType.reportType = "Please select a Report Type";
    }
//     if(props.type === "SiteHead" || "RO"){
//   if(formData.reportType === 'drawing' || 'pending'){
//     if (formData.fromtoType === "") {
//       errorType.fromtoType = "Please select a Report Type";
//     }
//   }
// }
    if (formData.designConsultant === "") {
      errorType.designConsultant = "Please select a Design Consultant";
    }
    if (formData.timePeriod === "") {
      errorType.timePeriod = "Please select a Time Period";
    }
    if (
      formData.timePeriod === "By Date" &&
      (formData.fromDate === "" || formData.toDate === "")
    ) {
      errorType.date = "Please select both From and To dates";
    }
    if (
      formData.timePeriod === "By Month" &&
      (formData.month === "" || formData.year === "")
    ) {
      errorType.date = "Please select both Month and Year";
    }
    if (formData.selectViewDownload === "") {
      errorType.selectViewDownload =
        "Please select an option to View or Download the report";
    }
    if (
      formData.selectViewDownload === "Download" &&
      formData.fileFormat === ""
    ) {
      errorType.fileFormat = "Please select file format";
    }

    setError(errorType);
    return Object.keys(errorType).length === 0;
  };

  const handleGoClick = () => {
    console.log(" from Data Download Brfore ", setPopupContent(formData));
    console.log(" from Data Download AFTER", formData);

    if (formValidate()) {
      setOpen(true);
      setPopupContent(formData);
    }

    if (formData.selectViewDownload === "Download" && !formData.fileFormat) {
      setError((prevError) => ({
        ...prevError,
        fileFormat: "Please select a file format",
      }));
      return;
    }
  };

  // const handleGoClick = () => {
  //   if (formValidate()) {
  //     setOpen(true);
  //     setPopupContent(formData);
  //   }

  //   if (formData.selectViewDownload === "download" && !formData.fileFormat) {
  //     setError((prevError) => ({
  //       ...prevError,
  //       fileFormat: "Please select a file format",
  //     }));
  //     return;
  //   }
  // };

  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const currentYear = new Date().getFullYear();
  const yearRange = Array.from(
    { length: 11 },
    (_, index) => currentYear - 5 + index
  );
  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
    setSelectedMonth("");
    setSelectedYear("");
  };

  const selectStyles = {
    base: {
      width: selectedPeriod === "yearly" ? "40%" : "20%",
      marginRight: "10px",
    },
    extended: {
      width: "40%",
      marginRight: "10px",
    },
  };
  useEffect(() => {
    console.log(
      selectedPeriod,
      selectedMonth,
      selectedYear,
      "drawing analysis data"
    );
  }, [site, selectedPeriod, selectedMonth, selectedYear]);
  return (
    <>
      <div
        style={{
          boxShadow: "rgb(243, 243, 243) 0px 0px 10px",
          borderRadius: "12px",
          backgroundColor: "white",
          fontFamily: "Poppins",
          fontWeight: "400",
          fontSize: "18px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 30px",
          }}
          onClick={toggleVisibility}
        >
          <p
            style={{
              color: "var(--primary-color)",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Drawing Report Analysis{" "}
          </p>
          {isVisible !== true ? (
            <FaAngleDown
              size={25}
              style={{
                color: "var(--primary-color)",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : (
            <FaAngleUp
              size={25}
              style={{
                color: "var(--primary-color)",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
        </div>
        {isVisible && (
          <>
            <div style={{ display: "flex", padding: "20px 30px", gap: "20px" }}>
              <div style={{ flex: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    htmlFor="reportType"
                    style={{
                      marginRight: "10px",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Report Type:
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    id="reportType"
                    name="reportType"
                    placeholder="Enter your report type"
                    className="form-control"
                    value={formData.reportType}
                    onChange={handleChange}
                    style={{ flex: 1 }}
                  >
                    <option value="">Select Report Type</option>
                    <option value="drawing">Drawing</option>
                    <option value="RFI">Rfi</option>
                    <option value="pending">Pending</option>
                    <option value="register">Register</option>
                  </select>
                </div>
                {error.reportType && (
                  <div className="text-danger">{error.reportType}</div>
                )}
              </div>
              {props.type === "RO" && (formData.reportType === "drawing" || formData.reportType === "pending" || formData.reportType === "RFI") && (
                <div style={{ flex: 1 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                  {  (formData.reportType === "drawing" || formData.reportType === "pending") && (
                    <>
                    <label
                      htmlFor="reportType"
                      style={{
                        marginRight: "10px",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Select From/To:
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="fromtoType"
                      name="fromtoType"
                      placeholder="Enter your report type"
                      className="form-control"
                      value={formData.fromtoType}
                      onChange={handleChange}
                      style={{ flex: 1 }}
                    >
                      <option value="">Select From/To Type</option>
                      <option value="architect">From Architect</option>
                      <option value="siteHead">To SiteHead</option>
                      </select>
                      </>
                      )}
                      { formData.reportType === "RFI"&& (
                        <>
                        <label
                        htmlFor="reportType"
                        style={{
                          marginRight: "10px",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Select From/To:
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                      id="fromtoType"
                      name="fromtoType"
                      placeholder="Enter your report type"
                      className="form-control"
                      value={formData.fromtoType}
                      onChange={handleChange}
                      style={{ flex: 1 }}
                    >
                        <option value="">Select Raised/Requested Type</option>
                        <option value="architect">Raised to Architect</option>
                        <option value="siteHead">Requested from SiteHead</option>
                        </select>
                        </>
                      )}
                    
                  </div>
                  {error.fromtoType && (
                    <div className="text-danger">{error.fromtoType}</div>
                  )}
                </div>
              )}

              {props.type === "SiteHead" &&  (formData.reportType === "drawing" || formData.reportType === "pending") && (
                <div style={{ flex: 1 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                      htmlFor="reportType"
                      style={{
                        marginRight: "10px",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Select From/To:
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="fromtoType"
                      name="fromtoType"
                      placeholder="Enter your report type"
                      className="form-control"
                      value={formData.fromtoType}
                      onChange={handleChange}
                      style={{ flex: 1 }}
                    >
                      <option value="">Select From/To Type</option>
                      <option value="ro">From RO </option>
                      <option value="siteLevel">To siteLevel</option>
                     
                    </select>
                  </div>
                  {error.fromtoType && (
                    <div className="text-danger">{error.fromtoType}</div>
                  )}
                </div>
              )}
              <div style={{ flex: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    htmlFor="designConsultant"
                    style={{
                      marginRight: "10px",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Design Consultant:
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    id="designConsultant"
                    name="designConsultant"
                    value={formData.designConsultant}
                    onChange={(event) => {
                      const selectedConsultantId = event.target.value;
                      const selectedConsultant = existingConsultants.find(
                        (consultant) => consultant._id === selectedConsultantId
                      );
                      setFormData((prevData) => ({
                        ...prevData,
                        designConsultant: selectedConsultantId,
                        consultantRole: selectedConsultant
                          ? selectedConsultant.role
                          : "",
                      }));
                    }}
                    className="form-control"
                    style={{ flex: 1 }}
                  >
                    <option value="">Select Consultant</option>
                   {props.type !== "architect" &&  <option value="All">All</option>}
                    {existingConsultants &&
                      existingConsultants.map((consultant) => (
                        <option key={consultant._id} value={consultant._id}>
                          {consultant?.role}
                        </option>
                      ))}
                  </select>
                </div>
                {error.designConsultant && (
                  <div className="text-danger">{error.designConsultant}</div>
                )}
              </div>
            </div>
            <div style={{ padding: "20px 30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  marginBottom: "10px",
                }}
              >
                <label
                  htmlFor="selectTimePeriod"
                  style={{
                    marginRight: "10px",
                    fontWeight: 500,
                    fontSize: "18px",
                  }}
                >
                  Select Time Period:
                  <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  gap: "30px",
                }}
              >
                <div style={{ flex: 1, textAlign: "start" }}>
                  <input
                    type="radio"
                    id="byDate"
                    name="timePeriod"
                    value="By Date"
                    checked={formData.timePeriod === "By Date"}
                    onChange={handleChange}
                    className="no-focus-border"
                    style={{ marginRight: "3px" }}
                  />
                  <label style={{ fontSize: "16px" }} htmlFor="byDate">
                    By Date
                  </label>
                </div>
                <div style={{ flex: 1, textAlign: "start" }}>
                  <input
                    type="radio"
                    id="byMonth"
                    name="timePeriod"
                    value="By Month"
                    checked={formData.timePeriod === "By Month"}
                    onChange={handleChange}
                    className="no-focus-border"
                    style={{ marginRight: "3px" }}
                  />
                  <label htmlFor="byMonth" style={{ fontSize: "16px" }}>
                    By Month
                  </label>
                </div>
                <div style={{ flex: 1, textAlign: "start" }}>
                  <input
                    type="radio"
                    id="last6Months"
                    name="timePeriod"
                    value="Last 6 Months"
                    checked={formData.timePeriod === "Last 6 Months"}
                    onChange={handleChange}
                    className="no-focus-border"
                    style={{ marginRight: "3px" }}
                  />
                  <label style={{ fontSize: "16px" }} htmlFor="last6Months">
                    Last 6 Months
                  </label>
                </div>
                <div style={{ flex: 1, textAlign: "start" }}>
                  <input
                    type="radio"
                    id="fromBeginningToTillDate"
                    name="timePeriod"
                    value="fromBeginningToTillDate"
                    checked={formData.timePeriod === "fromBeginningToTillDate"}
                    onChange={handleChange}
                    className="no-focus-border"
                    style={{ marginRight: "3px" }}
                  />
                  <label
                    style={{ fontSize: "16px" }}
                    htmlFor="fromBeginningToTillDate"
                  >
                    From Beginning to till date
                  </label>
                </div>
              </div>
              {error.timePeriod && (
                <div className="text-danger">{error.timePeriod}</div>
              )}
              {formData.timePeriod === "By Date" && (
                <div
                  style={{ display: "flex", padding: "20px 30px", gap: "20px" }}
                >
                  <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        htmlFor="fromDate"
                        style={{
                          marginRight: "10px",
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                      >
                        From:
                      </label>
                      <input
                        type="date"
                        id="fromDate"
                        value={formData.fromDate}
                        onChange={handleDateChange}
                        className="form-control"
                        style={{ flex: 1 }}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        htmlFor="toDate"
                        style={{
                          marginRight: "10px",
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                      >
                        To:
                      </label>
                      <input
                        type="date"
                        id="toDate"
                        value={formData.toDate}
                        onChange={handleDateChange}
                        className="form-control"
                        style={{ flex: 1 }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {formData.timePeriod === "By Month" && (
                <div
                  style={{ display: "flex", padding: "20px 30px", gap: "20px" }}
                >
                  <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        htmlFor="month"
                        style={{
                          marginRight: "10px",
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                      >
                        Month:
                      </label>
                      <select
                        id="month"
                        name="month"
                        value={formData.month}
                        onChange={handleChange}
                        className="form-control"
                        style={{ flex: 1 }}
                      >
                        <option value="">Select Month</option>
                        {Array.from({ length: 12 }, (_, i) => (
                          <option key={i} value={i + 1}>
                            {new Date(0, i).toLocaleString("default", {
                              month: "long",
                            })}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        htmlFor="year"
                        style={{
                          marginRight: "10px",
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                      >
                        Year:
                      </label>
                      <select
                        id="year"
                        name="year"
                        value={formData.year}
                        onChange={handleChange}
                        className="form-control"
                        style={{ flex: 1 }}
                      >
                        <option value="">Select Year</option>
                        {Array.from({ length: 10 }, (_, i) => (
                          <option key={i} value={new Date().getFullYear() - i}>
                            {new Date().getFullYear() - i}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {formData.timePeriod === "Last 6 Months" && (
                <div
                  style={{ display: "flex", padding: "20px 30px", gap: "20px" }}
                >
                  <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        htmlFor="fromDate"
                        style={{
                          marginRight: "10px",
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                      >
                        From:
                      </label>
                      <input
                        type="date"
                        id="fromDate"
                        value={formData.fromDate}
                        readOnly
                        className="form-control"
                        style={{ flex: 1 }}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        htmlFor="toDate"
                        style={{
                          marginRight: "10px",
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                      >
                        To:
                      </label>
                      <input
                        type="date"
                        id="toDate"
                        value={formData.toDate}
                        readOnly
                        className="form-control"
                        style={{ flex: 1 }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {error.date && <div className="text-danger">{error.date}</div>}
            <div style={{ padding: "20px 30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <label
                  htmlFor="selectViewDownload"
                  style={{
                    marginRight: "10px",
                    fontWeight: 500,
                    fontSize: "18px",
                  }}
                >
                  Select appropriate options to View / Download the Report :
                  <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "15px",
                }}
              >
                <div style={{ flex: 1, textAlign: "start" }}>
                  <input
                    type="radio"
                    id="download"
                    name="selectViewDownload"
                    value="Download"
                    checked={formData.selectViewDownload === "Download"}
                    onChange={handleChange}
                    className="no-focus-border"
                    style={{ marginRight: "5px" }}
                  />
                  <label style={{ fontSize: "16px" }} htmlFor="download">
                    Download
                  </label>
                </div>
                <div style={{ flex: 1, textAlign: "start" }}>
                  <input
                    type="radio"
                    id="view"
                    name="selectViewDownload"
                    value="View"
                    checked={formData.selectViewDownload === "View"}
                    onChange={handleChange}
                    style={{ marginRight: "5px" }}
                    className="no-focus-border"
                  />
                  <label style={{ fontSize: "16px" }} htmlFor="view">
                    View
                  </label>
                </div>
              </div>

              {error.selectViewDownload && (
                <div className="text-danger">{error.selectViewDownload}</div>
              )}
              {formData.selectViewDownload === "Download" && (
                <div style={{ padding: "20px 30px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <label
                      htmlFor="fileFormat"
                      style={{
                        marginRight: "10px",
                        fontWeight: 500,
                        fontSize: "18px",
                      }}
                    >
                      File Format:
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "60px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        id="pdf"
                        name="fileFormat"
                        value="PDF"
                        checked={formData.fileFormat === "PDF"}
                        onChange={handleChange}
                        className="no-focus-border"
                        style={{ marginRight: "10px" }}
                      />
                      {/* <Icon.FiletypePdf size={25} style={{ marginRight: '5px' }}/> */}
                      <label style={{ fontSize: "15px" }} htmlFor="pdf">
                        <div className="d-flex mt-2">
                          <div>
                            <PdfIcon />
                          </div>
                          <p className="mx-2 mt-1"> Pdf </p>
                        </div>
                      </label>
                    </div>
                    {/* <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        id="xls"
                        name="fileFormat"
                        value="XLS"
                        checked={formData.fileFormat === "XLS"}
                        onChange={handleChange}
                        className="no-focus-border"
                        style={{ marginRight: "10px" }}
                      />
                     
                      <label style={{ fontSize: "15px" }} htmlFor="xls">
                        <div className="d-flex mt-2">
                          <div>
                            <XlsIcon />
                          </div>
                          <p className="mx-2 mt-1"> excel </p>
                        </div>{" "}
                      </label>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        id="csv"
                        name="fileFormat"
                        value="CSV"
                        checked={formData.fileFormat === "CSV"}
                        onChange={handleChange}
                        className="no-focus-border"
                        style={{ marginRight: "10px" }}
                      />
                     
                      <label style={{ fontSize: "15px" }} htmlFor="csv">
                        <div className="d-flex mt-2">
                          <div>
                            <CsvIcon />
                          </div>
                          <p className="mx-2 mt-1"> Csv </p>
                        </div>{" "}
                      </label>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
            {error.fileFormat && (
              <div className="text-danger">{error.fileFormat}</div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 30px",
              }}
            >
              <button
                style={{
                  marginLeft: "50px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid var(--primary-color)",
                  width: "100px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                onClick={handleGoClick}
              >
                GO
              </button>
              <button
                className="btn btn-primary"
                style={{
                  marginLeft: "50px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid var(--primary-color)",
                  width: "100px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                onClick={resetForm}
              >
                RESET
              </button>
            </div>
          </>
        )}
        {/* <>
<div>
         <button onClick={() => toPDF()}>Download PDF</button>
         <div ref={targetRef}>
            Content to be generated to PDF
         </div>
      </div>
</> */}
      </div>
      {open && (
        <TablePage
          handleDialogClose={handleDialogClose}
          isOpen={open}
          popupcontent={popupcontent}
          type={props.type}
        />
      )}

      <div
        style={{
          marginTop: "50px",
          boxShadow: "rgb(243, 243, 243) 0px 0px 10px",
          borderRadius: "12px",
          backgroundColor: "white",
          fontFamily: "Poppins",
          fontWeight: "400",
          fontSize: "18px",
          width: "100%",
        }}
      >
        <h6
          style={{
            paddingTop: "20px",
            color: "var(--primary-color)",
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          {/* {props.type === "RO" ? "Drawing Summary To Consultant" : "Drawing Summary"} */}
          Drawing Summary
        </h6>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            textAlign: "left",
            margin: "20px",
          }}
        >
          <label style={{ fontSize: "14px" }}>Select Time Period</label>
          <select
            value={selectedPeriod}
            onChange={handlePeriodChange}
            style={{ ...selectStyles.base }}
          >
            <option>All</option>
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="halfYearly">Half Yearly</option>
            <option value="yearly">Yearly</option>
          </select>

          {(selectedPeriod === "monthly" ||
            selectedPeriod === "quarterly" ||
            selectedPeriod === "halfYearly") && (
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <label style={{ fontSize: "14px" }}>Select Month:</label>
              <select
                style={{ ...selectStyles.extended }}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <label style={{ fontSize: "14px" }}>Select Year:</label>
              <select
                style={{ ...selectStyles.extended }}
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {yearRange.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          )}

          {selectedPeriod === "yearly" && (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <label
                style={{ fontSize: "14px" }}
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                Select Year:
              </label>
              <select style={{ width: "170%" }}>
                <option value="">Select Year</option>
                {yearRange.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div
            style={{
              marginTop: "50px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              backgroundColor: "white",
              padding: "15px",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              width: "46%",
              height: "auto",
            }}
          >
            <DrawingStatus
              type={props.type}
              selctedTimeperiod={selectedPeriod}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
            />
          </div>

          <div
            style={{
              marginTop: "50px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              backgroundColor: "white",
              padding: "15px",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              width: "46%",
              height: "auto",
            }}
          >
            <RfiStatus
              type={props.type}
              selctedTimeperiod={selectedPeriod}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div
            style={{
              marginTop: "50px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              backgroundColor: "white",
              padding: "15px",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              width: "46%",
              height: "auto",
            }}
          >
            <DrawingChart
              type={props.type}
              selctedTimeperiod={selectedPeriod}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
            />
          </div>

          <div
            style={{
              marginTop: "50px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              backgroundColor: "white",
              padding: "15px",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              width: "46%",
              height: "auto",
            }}
          >
            <RfiChart
              type={props.type}
              selctedTimeperiod={selectedPeriod}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
            />
          </div>
        </div>
      </div>
      {/* {props.type === "RO" &&
       <div
       style={{
         marginTop: "50px",
         boxShadow: "rgb(243, 243, 243) 0px 0px 10px",
         borderRadius: "12px",
         backgroundColor: "white",
         fontFamily: "Poppins",
         fontWeight: "400",
         fontSize: "18px",
         width: "100%",
       }}
     >
       <h6
         style={{
           paddingTop: "20px",
           color: "var(--primary-color)",
           fontSize: "20px",
           fontWeight: "500",
         }}
       >
         Drawing Summary From SiteHead
       </h6>

       <div
         style={{
           display: "flex",
           alignItems: "center",
           gap: "20px",
           textAlign: "left",
           margin: "20px",
         }}
       >
         <label style={{ fontSize: "14px" }}>Select Time Period</label>
         <select
           value={selectedPeriod}
           onChange={handlePeriodChange}
           style={{ ...selectStyles.base }}
         >
           <option>All</option>
           <option value="monthly">Monthly</option>
           <option value="quarterly">Quarterly</option>
           <option value="halfYearly">Half Yearly</option>
           <option value="yearly">Yearly</option>
         </select>

         {(selectedPeriod === "monthly" ||
           selectedPeriod === "quarterly" ||
           selectedPeriod === "halfYearly") && (
           <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
             <label style={{ fontSize: "14px" }}>Select Month:</label>
             <select
               style={{ ...selectStyles.extended }}
               value={selectedMonth}
               onChange={(e) => setSelectedMonth(e.target.value)}
             >
               <option value="">Select Month</option>
               <option value="1">January</option>
               <option value="2">February</option>
               <option value="3">March</option>
               <option value="4">April</option>
               <option value="5">May</option>
               <option value="6">June</option>
               <option value="7">July</option>
               <option value="8">August</option>
               <option value="9">September</option>
               <option value="10">October</option>
               <option value="11">November</option>
               <option value="12">December</option>
             </select>

             <label style={{ fontSize: "14px" }}>Select Year:</label>
             <select
               style={{ ...selectStyles.extended }}
               value={selectedYear}
               onChange={(e) => setSelectedYear(e.target.value)}
             >
               <option value="">Select Year</option>
               {yearRange.map((year) => (
                 <option key={year} value={year}>
                   {year}
                 </option>
               ))}
             </select>
           </div>
         )}

         {selectedPeriod === "yearly" && (
           <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
             <label
               style={{ fontSize: "14px" }}
               value={selectedYear}
               onChange={(e) => setSelectedYear(e.target.value)}
             >
               Select Year:
             </label>
             <select style={{ width: "170%" }}>
               <option value="">Select Year</option>
               {yearRange.map((year) => (
                 <option key={year} value={year}>
                   {year}
                 </option>
               ))}
             </select>
           </div>
         )}
       </div>
       <div
         style={{
           display: "flex",
           justifyContent: "space-around",
           width: "100%",
         }}
       >
         <div
           style={{
             marginTop: "50px",
             boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
             borderRadius: "12px",
             backgroundColor: "white",
             padding: "15px",
             fontFamily: "Poppins",
             fontWeight: "400",
             fontSize: "18px",
             width: "46%",
             height: "auto",
           }}
         >
           <DrawingStatus
             type={props.type}
             selctedTimeperiod={selectedPeriod}
             selectedMonth={selectedMonth}
             selectedYear={selectedYear}
           />
         </div>

         <div
           style={{
             marginTop: "50px",
             boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
             borderRadius: "12px",
             backgroundColor: "white",
             padding: "15px",
             fontFamily: "Poppins",
             fontWeight: "400",
             fontSize: "18px",
             width: "46%",
             height: "auto",
           }}
         >
           <RfiStatus
             type={props.type}
             selctedTimeperiod={selectedPeriod}
             selectedMonth={selectedMonth}
             selectedYear={selectedYear}
           />
         </div>
       </div>
       <div
         style={{
           display: "flex",
           justifyContent: "space-around",
           width: "100%",
         }}
       >
         <div
           style={{
             marginTop: "50px",
             boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
             borderRadius: "12px",
             backgroundColor: "white",
             padding: "15px",
             fontFamily: "Poppins",
             fontWeight: "400",
             fontSize: "18px",
             width: "46%",
             height: "auto",
           }}
         >
           <DrawingChart
             type={props.type}
             selctedTimeperiod={selectedPeriod}
             selectedMonth={selectedMonth}
             selectedYear={selectedYear}
           />
         </div>

         <div
           style={{
             marginTop: "50px",
             boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
             borderRadius: "12px",
             backgroundColor: "white",
             padding: "15px",
             fontFamily: "Poppins",
             fontWeight: "400",
             fontSize: "18px",
             width: "46%",
             height: "auto",
           }}
         >
           <RfiChart
             type={props.type}
             selctedTimeperiod={selectedPeriod}
             selectedMonth={selectedMonth}
             selectedYear={selectedYear}
           />
         </div>
       </div>
     </div>} */}
    </>
  );
};

export default Analysis;

// import React, { useState, useEffect } from "react";
// import * as Icon from "react-bootstrap-icons";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { WebApimanager } from "../../../WebApiManager";
// import DrawingStatus from "./DrawingStatus";
// import RfiStatus from "./RfiStatus";
// import DrawingChart from "./DrawingChart";
// import RfiChart from "./RfiChart";
// import { usePDF } from "react-to-pdf";
// import TablePage from "./TablePage";
// import PdfIcon from "../../icons/pdf-icon";
// import XlsIcon from "../../icons/xls-icon";
// import CsvIcon from "../../icons/csv-icon";
// import { siteId, userInfo } from "../../../atoms";
// import { useRecoilState } from "recoil";
// import { FaAngleDown } from "react-icons/fa6";
// import { FaAngleUp } from "react-icons/fa6";
// import "./Analysis.css";

// const Analysis = (props) => {
//   const webApi = new WebApimanager();
//   const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
//   const [open, setOpen] = useState(false);
//   const [isVisible, setIsVisible] = useState(false);
//   const [existingConsultants, setExistingConsultants] = useState([]);
//   const userDetails = useRecoilState(userInfo);
//   const userrole = userDetails[0].role;
//   console.log(userDetails, "analyasis userdetails");
//   console.log(userrole, "analyasis userdetails role");
//   console.log("Analysis Props", props);

//   // const loginUserRoleId = xyz;
//   const [formData, setFormData] = useState({
//     reportType: "",
//     fromtoType: "",
//     designConsultant: "",
//     timePeriod: "",
//     fromDate: "",
//     toDate: "",
//     month: "",
//     year: "",
//     selectViewDownload: "",
//     fileFormat: "",
//     consultantRole: "",
//   });
//   const [error, setError] = useState({});
//   const [popupcontent, setPopupContent] = useState();
//   const [site, setSite] = useRecoilState(siteId);

//   const handleDialogClose = () => {
//     setOpen(false);
//   };

//   const toggleVisibility = () => {
//     setIsVisible(!isVisible);
//   };

//   // const getAll = () => {
//   //   webApi
//   //     .get(`api/designDrawingConsultant/getAll?siteId=${site}`)
//   //     .then((data) => {
//   //       if (data) {
//   //         // if(type="architectToRo")
//   //         setExistingConsultants(data.data.data.users);
//   //       }
//   //     });
//   // };
//   const getAll = () => {
//     webApi
//       .get(`api/designDrawingConsultant/getAll?siteId=${site}`)
//       .then((data) => {
//         if (data) {
//           if (props.type === "architect") {
//             console.log(props.type, "propsTYpe");

//             const filteredConsultants = data.data.data.users.filter(
//               (user) => user.role === userrole
//             );
//             setExistingConsultants(filteredConsultants);
//             console.log(existingConsultants, "filter,propsTYpe", props.type);
//           } else {
//             setExistingConsultants(data.data.data.users); // If no filter is needed
//           }
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching consultants", error);
//       });
//   };

//   useEffect(() => {
//     if (existingConsultants.length === 0) {
//       getAll();
//     }
//     console.log(existingConsultants, "existing consultants", props.type);
//   }, [existingConsultants]);

//   useEffect(() => {
//     const today = new Date();
//     if (formData.timePeriod === "Last 6 Months") {
//       const sixMonthsAgo = new Date(today);
//       sixMonthsAgo.setMonth(today.getMonth() - 6);
//       setFormData((prevData) => ({
//         ...prevData,
//         fromDate: formatDate(sixMonthsAgo),
//         toDate: formatDate(today),
//       }));
//     } else if (formData.timePeriod === "fromBeginningToTillDate") {
//       setFormData((prevData) => ({
//         ...prevData,
//         fromDate: "",
//         toDate: "",
//       }));
//     }
//   }, [formData.timePeriod]);

//   const formatDate = (date) => {
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     return `${year}-${month}-${day}`;
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleDateChange = (event) => {
//     const { id, value } = event.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [id]: value,
//     }));
//   };

//   const resetForm = () => {
//     setFormData({
//       reportType: "",
//       fromtoType: "",
//       designConsultant: "",
//       timePeriod: "",
//       fromDate: "",
//       toDate: "",
//       month: "",
//       year: "",
//       selectViewDownload: "",
//       fileFormat: "",
//     });
//   };

//   const formValidate = () => {
//     let errorType = {};

//     if (formData.reportType === "") {
//       errorType.reportType = "Please select a Report Type";
//     }
//     if (formData.fromtoType === "") {
//       errorType.fromtoType = "Please select a Report Type";
//     }
//     if (formData.designConsultant === "") {
//       errorType.designConsultant = "Please select a Design Consultant";
//     }
//     if (formData.timePeriod === "") {
//       errorType.timePeriod = "Please select a Time Period";
//     }
//     if (
//       formData.timePeriod === "By Date" &&
//       (formData.fromDate === "" || formData.toDate === "")
//     ) {
//       errorType.date = "Please select both From and To dates";
//     }
//     if (
//       formData.timePeriod === "By Month" &&
//       (formData.month === "" || formData.year === "")
//     ) {
//       errorType.date = "Please select both Month and Year";
//     }
//     if (formData.selectViewDownload === "") {
//       errorType.selectViewDownload =
//         "Please select an option to View or Download the report";
//     }
//     if (
//       formData.selectViewDownload === "Download" &&
//       formData.fileFormat === ""
//     ) {
//       errorType.fileFormat = "Please select file format";
//     }

//     setError(errorType);
//     return Object.keys(errorType).length === 0;
//   };

//   // const handleGoClick = () => {
//   //   console.log(" from Data Download Brfore ", setPopupContent(formData));
//   //   console.log(" from Data Download AFTER", formData);

//   //   if (formValidate()) {
//   //     setOpen(true);
//   //     setPopupContent(formData);
//   //   }

//   //   if (formData.selectViewDownload === "Download" && !formData.fileFormat) {
//   //     setError((prevError) => ({
//   //       ...prevError,
//   //       fileFormat: "Please select a file format",
//   //     }));
//   //     return;
//   //   }
//   // };

//   const handleGoClick = () => {
//     if (formValidate()) {
//       setOpen(true);
//       setPopupContent(formData);
//     }

//     if (formData.selectViewDownload === "download" && !formData.fileFormat) {
//       setError((prevError) => ({
//         ...prevError,
//         fileFormat: "Please select a file format",
//       }));
//       return;
//     }
//   };

//   const [selectedPeriod, setSelectedPeriod] = useState("");
//   const [selectedMonth, setSelectedMonth] = useState("");
//   const [selectedYear, setSelectedYear] = useState("");
//   const currentYear = new Date().getFullYear();
//   const yearRange = Array.from(
//     { length: 11 },
//     (_, index) => currentYear - 5 + index
//   );
//   const handlePeriodChange = (e) => {
//     setSelectedPeriod(e.target.value);
//     setSelectedMonth("");
//     setSelectedYear("");
//   };

//   const selectStyles = {
//     base: {
//       width: selectedPeriod === "yearly" ? "40%" : "20%",
//       marginRight: "10px",
//     },
//     extended: {
//       width: "40%",
//       marginRight: "10px",
//     },
//   };
//   useEffect(() => {
//     console.log(
//       selectedPeriod,
//       selectedMonth,
//       selectedYear,
//       "drawing analysis data"
//     );
//   }, [site, selectedPeriod, selectedMonth, selectedYear]);
//   return (
//     <>
//       <div
//         style={{
//           boxShadow: "rgb(243, 243, 243) 0px 0px 10px",
//           borderRadius: "12px",
//           backgroundColor: "white",
//           fontFamily: "Poppins",
//           fontWeight: "400",
//           fontSize: "18px",
//           width: "100%",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             padding: "20px 30px",
//           }}
//           onClick={toggleVisibility}
//         >
//           <p
//             style={{
//               color: "var(--primary-color)",
//               fontSize: "20px",
//               fontWeight: "500",
//             }}
//           >
//             Drawing Report Analysis{" "}
//           </p>
//           {isVisible !== true ? (
//             <FaAngleDown
//               size={25}
//               style={{
//                 color: "var(--primary-color)",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             />
//           ) : (
//             <FaAngleUp
//               size={25}
//               style={{
//                 color: "var(--primary-color)",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             />
//           )}
//         </div>
//         {isVisible && (
//           <>
//             <div style={{ display: "flex", padding: "20px 30px", gap: "20px" }}>
//               <div style={{ flex: 1 }}>
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   <label
//                     htmlFor="reportType"
//                     style={{
//                       marginRight: "10px",
//                       fontWeight: 500,
//                       fontSize: "14px",
//                     }}
//                   >
//                     Report Type:
//                     <span style={{ color: "red" }}>*</span>
//                   </label>
//                   <select
//                     id="reportType"
//                     name="reportType"
//                     placeholder="Enter your report type"
//                     className="form-control"
//                     value={formData.reportType}
//                     onChange={handleChange}
//                     style={{ flex: 1 }}
//                   >
//                     <option value="">Select Report Type</option>
//                     <option value="drawing">Drawing</option>
//                     <option value="RFI">Rfi</option>
//                     <option value="pending">Pending</option>
//                     <option value="register">Register</option>
//                   </select>
//                 </div>
//                 {error.reportType && (
//                   <div className="text-danger">{error.reportType}</div>
//                 )}
//               </div>
//               {props.type === "RO" && (
//                 <div style={{ flex: 1 }}>
//                   <div style={{ display: "flex", alignItems: "center" }}>
//                     <label
//                       htmlFor="reportType"
//                       style={{
//                         marginRight: "10px",
//                         fontWeight: 500,
//                         fontSize: "14px",
//                       }}
//                     >
//                       Select From/To:
//                       <span style={{ color: "red" }}>*</span>
//                     </label>
//                     <select
//                       id="fromtoType"
//                       name="fromtoType"
//                       placeholder="Enter your report type"
//                       className="form-control"
//                       value={formData.fromtoType}
//                       onChange={handleChange}
//                       style={{ flex: 1 }}
//                     >
//                       <option value="">Select From/To Type</option>
//                       <option value="architect">From Architect</option>
//                       <option value="siteHead">To SiteHead</option>
//                     </select>
//                   </div>
//                   {error.fromtoType && (
//                     <div className="text-danger">{error.fromtoType}</div>
//                   )}
//                 </div>
//               )}

//               {props.type === "SiteHead" && (
//                 <div style={{ flex: 1 }}>
//                   <div style={{ display: "flex", alignItems: "center" }}>
//                     <label
//                       htmlFor="reportType"
//                       style={{
//                         marginRight: "10px",
//                         fontWeight: 500,
//                         fontSize: "14px",
//                       }}
//                     >
//                       Select From/To:
//                       <span style={{ color: "red" }}>*</span>
//                     </label>
//                     <select
//                       id="fromtoType"
//                       name="fromtoType"
//                       placeholder="Enter your report type"
//                       className="form-control"
//                       value={formData.fromtoType}
//                       onChange={handleChange}
//                       style={{ flex: 1 }}
//                     >
//                       <option value="">Select From/To Type</option>
//                       <option value="ro">From RO </option>
//                       <option value="siteLevel">To siteLevel</option>
//                     </select>
//                   </div>
//                   {error.fromtoType && (
//                     <div className="text-danger">{error.fromtoType}</div>
//                   )}
//                 </div>
//               )}
//               <div style={{ flex: 1 }}>
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   <label
//                     htmlFor="designConsultant"
//                     style={{
//                       marginRight: "10px",
//                       fontWeight: 500,
//                       fontSize: "14px",
//                     }}
//                   >
//                     Design Consultant:
//                     <span style={{ color: "red" }}>*</span>
//                   </label>
//                   <select
//                     id="designConsultant"
//                     name="designConsultant"
//                     value={formData.designConsultant}
//                     onChange={(event) => {
//                       const selectedConsultantId = event.target.value;
//                       const selectedConsultant = existingConsultants.find(
//                         (consultant) => consultant._id === selectedConsultantId
//                       );
//                       setFormData((prevData) => ({
//                         ...prevData,
//                         designConsultant: selectedConsultantId,
//                         consultantRole: selectedConsultant
//                           ? selectedConsultant.role
//                           : "",
//                       }));
//                     }}
//                     className="form-control"
//                     style={{ flex: 1 }}
//                   >
//                     <option value="">Select Consultant</option>
//                     <option value="All">All</option>
//                     {existingConsultants &&
//                       existingConsultants.map((consultant) => (
//                         <option key={consultant._id} value={consultant._id}>
//                           {consultant?.role}
//                         </option>
//                       ))}
//                   </select>
//                 </div>
//                 {error.designConsultant && (
//                   <div className="text-danger">{error.designConsultant}</div>
//                 )}
//               </div>
//             </div>
//             <div style={{ padding: "20px 30px" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "start",
//                   marginBottom: "10px",
//                 }}
//               >
//                 <label
//                   htmlFor="selectTimePeriod"
//                   style={{
//                     marginRight: "10px",
//                     fontWeight: 500,
//                     fontSize: "18px",
//                   }}
//                 >
//                   Select Time Period:
//                   <span style={{ color: "red" }}>*</span>
//                 </label>
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   // justifyContent: "space-between",
//                   gap: "30px",
//                 }}
//               >
//                 <div style={{ flex: 1, textAlign: "start" }}>
//                   <input
//                     type="radio"
//                     id="byDate"
//                     name="timePeriod"
//                     value="By Date"
//                     checked={formData.timePeriod === "By Date"}
//                     onChange={handleChange}
//                     className="no-focus-border"
//                     style={{ marginRight: "3px" }}
//                   />
//                   <label style={{ fontSize: "16px" }} htmlFor="byDate">
//                     By Date
//                   </label>
//                 </div>
//                 <div style={{ flex: 1, textAlign: "start" }}>
//                   <input
//                     type="radio"
//                     id="byMonth"
//                     name="timePeriod"
//                     value="By Month"
//                     checked={formData.timePeriod === "By Month"}
//                     onChange={handleChange}
//                     className="no-focus-border"
//                     style={{ marginRight: "3px" }}
//                   />
//                   <label htmlFor="byMonth" style={{ fontSize: "16px" }}>
//                     By Month
//                   </label>
//                 </div>
//                 <div style={{ flex: 1, textAlign: "start" }}>
//                   <input
//                     type="radio"
//                     id="last6Months"
//                     name="timePeriod"
//                     value="Last 6 Months"
//                     checked={formData.timePeriod === "Last 6 Months"}
//                     onChange={handleChange}
//                     className="no-focus-border"
//                     style={{ marginRight: "3px" }}
//                   />
//                   <label style={{ fontSize: "16px" }} htmlFor="last6Months">
//                     Last 6 Months
//                   </label>
//                 </div>
//                 <div style={{ flex: 1, textAlign: "start" }}>
//                   <input
//                     type="radio"
//                     id="fromBeginningToTillDate"
//                     name="timePeriod"
//                     value="fromBeginningToTillDate"
//                     checked={formData.timePeriod === "fromBeginningToTillDate"}
//                     onChange={handleChange}
//                     className="no-focus-border"
//                     style={{ marginRight: "3px" }}
//                   />
//                   <label
//                     style={{ fontSize: "16px" }}
//                     htmlFor="fromBeginningToTillDate"
//                   >
//                     From Beginning to till date
//                   </label>
//                 </div>
//               </div>
//               {error.timePeriod && (
//                 <div className="text-danger">{error.timePeriod}</div>
//               )}
//               {formData.timePeriod === "By Date" && (
//                 <div
//                   style={{ display: "flex", padding: "20px 30px", gap: "20px" }}
//                 >
//                   <div style={{ flex: 1 }}>
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <label
//                         htmlFor="fromDate"
//                         style={{
//                           marginRight: "10px",
//                           fontWeight: 500,
//                           fontSize: "16px",
//                         }}
//                       >
//                         From:
//                       </label>
//                       <input
//                         type="date"
//                         id="fromDate"
//                         value={formData.fromDate}
//                         onChange={handleDateChange}
//                         className="form-control"
//                         style={{ flex: 1 }}
//                       />
//                     </div>
//                   </div>
//                   <div style={{ flex: 1 }}>
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <label
//                         htmlFor="toDate"
//                         style={{
//                           marginRight: "10px",
//                           fontWeight: 500,
//                           fontSize: "16px",
//                         }}
//                       >
//                         To:
//                       </label>
//                       <input
//                         type="date"
//                         id="toDate"
//                         value={formData.toDate}
//                         onChange={handleDateChange}
//                         className="form-control"
//                         style={{ flex: 1 }}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               )}
//               {formData.timePeriod === "By Month" && (
//                 <div
//                   style={{ display: "flex", padding: "20px 30px", gap: "20px" }}
//                 >
//                   <div style={{ flex: 1 }}>
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <label
//                         htmlFor="month"
//                         style={{
//                           marginRight: "10px",
//                           fontWeight: 500,
//                           fontSize: "16px",
//                         }}
//                       >
//                         Month:
//                       </label>
//                       <select
//                         id="month"
//                         name="month"
//                         value={formData.month}
//                         onChange={handleChange}
//                         className="form-control"
//                         style={{ flex: 1 }}
//                       >
//                         <option value="">Select Month</option>
//                         {Array.from({ length: 12 }, (_, i) => (
//                           <option key={i} value={i + 1}>
//                             {new Date(0, i).toLocaleString("default", {
//                               month: "long",
//                             })}
//                           </option>
//                         ))}
//                       </select>
//                     </div>
//                   </div>
//                   <div style={{ flex: 1 }}>
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <label
//                         htmlFor="year"
//                         style={{
//                           marginRight: "10px",
//                           fontWeight: 500,
//                           fontSize: "16px",
//                         }}
//                       >
//                         Year:
//                       </label>
//                       <select
//                         id="year"
//                         name="year"
//                         value={formData.year}
//                         onChange={handleChange}
//                         className="form-control"
//                         style={{ flex: 1 }}
//                       >
//                         <option value="">Select Year</option>
//                         {Array.from({ length: 10 }, (_, i) => (
//                           <option key={i} value={new Date().getFullYear() - i}>
//                             {new Date().getFullYear() - i}
//                           </option>
//                         ))}
//                       </select>
//                     </div>
//                   </div>
//                 </div>
//               )}
//               {formData.timePeriod === "Last 6 Months" && (
//                 <div
//                   style={{ display: "flex", padding: "20px 30px", gap: "20px" }}
//                 >
//                   <div style={{ flex: 1 }}>
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <label
//                         htmlFor="fromDate"
//                         style={{
//                           marginRight: "10px",
//                           fontWeight: 500,
//                           fontSize: "16px",
//                         }}
//                       >
//                         From:
//                       </label>
//                       <input
//                         type="date"
//                         id="fromDate"
//                         value={formData.fromDate}
//                         readOnly
//                         className="form-control"
//                         style={{ flex: 1 }}
//                       />
//                     </div>
//                   </div>
//                   <div style={{ flex: 1 }}>
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <label
//                         htmlFor="toDate"
//                         style={{
//                           marginRight: "10px",
//                           fontWeight: 500,
//                           fontSize: "16px",
//                         }}
//                       >
//                         To:
//                       </label>
//                       <input
//                         type="date"
//                         id="toDate"
//                         value={formData.toDate}
//                         readOnly
//                         className="form-control"
//                         style={{ flex: 1 }}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>
//             {error.date && <div className="text-danger">{error.date}</div>}
//             <div style={{ padding: "20px 30px" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   marginBottom: "10px",
//                 }}
//               >
//                 <label
//                   htmlFor="selectViewDownload"
//                   style={{
//                     marginRight: "10px",
//                     fontWeight: 500,
//                     fontSize: "18px",
//                   }}
//                 >
//                   Select appropriate options to View / Download the Report :
//                   <span style={{ color: "red" }}>*</span>
//                 </label>
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   gap: "15px",
//                 }}
//               >
//                 <div style={{ flex: 1, textAlign: "start" }}>
//                   <input
//                     type="radio"
//                     id="download"
//                     name="selectViewDownload"
//                     value="Download"
//                     checked={formData.selectViewDownload === "Download"}
//                     onChange={handleChange}
//                     className="no-focus-border"
//                     style={{ marginRight: "5px" }}
//                   />
//                   <label style={{ fontSize: "16px" }} htmlFor="download">
//                     Download
//                   </label>
//                 </div>
//                 <div style={{ flex: 1, textAlign: "start" }}>
//                   <input
//                     type="radio"
//                     id="view"
//                     name="selectViewDownload"
//                     value="View"
//                     checked={formData.selectViewDownload === "View"}
//                     onChange={handleChange}
//                     style={{ marginRight: "5px" }}
//                     className="no-focus-border"
//                   />
//                   <label style={{ fontSize: "16px" }} htmlFor="view">
//                     View
//                   </label>
//                 </div>
//               </div>

//               {error.selectViewDownload && (
//                 <div className="text-danger">{error.selectViewDownload}</div>
//               )}
//               {formData.selectViewDownload === "Download" && (
//                 <div style={{ padding: "20px 30px" }}>
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       marginBottom: "8px",
//                     }}
//                   >
//                     <label
//                       htmlFor="fileFormat"
//                       style={{
//                         marginRight: "10px",
//                         fontWeight: 500,
//                         fontSize: "18px",
//                       }}
//                     >
//                       File Format:
//                     </label>
//                   </div>
//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "flex-start",
//                       gap: "60px",
//                     }}
//                   >
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <input
//                         type="radio"
//                         id="pdf"
//                         name="fileFormat"
//                         value="PDF"
//                         checked={formData.fileFormat === "PDF"}
//                         onChange={handleChange}
//                         className="no-focus-border"
//                         style={{ marginRight: "10px" }}
//                       />
//                       {/* <Icon.FiletypePdf size={25} style={{ marginRight: '5px' }}/> */}
//                       <label style={{ fontSize: "15px" }} htmlFor="pdf">
//                         <div className="d-flex mt-2">
//                           <div>
//                             <PdfIcon />
//                           </div>
//                           <p className="mx-2 mt-1"> Pdf </p>
//                         </div>
//                       </label>
//                     </div>
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <input
//                         type="radio"
//                         id="xls"
//                         name="fileFormat"
//                         value="XLS"
//                         checked={formData.fileFormat === "XLS"}
//                         onChange={handleChange}
//                         className="no-focus-border"
//                         style={{ marginRight: "10px" }}
//                       />
//                       {/* <Icon.FiletypeXls size={25} style={{ marginRight: '5px' }}/> */}
//                       <label style={{ fontSize: "15px" }} htmlFor="xls">
//                         <div className="d-flex mt-2">
//                           <div>
//                             <XlsIcon />
//                           </div>
//                           <p className="mx-2 mt-1"> excel </p>
//                         </div>{" "}
//                       </label>
//                     </div>
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <input
//                         type="radio"
//                         id="csv"
//                         name="fileFormat"
//                         value="CSV"
//                         checked={formData.fileFormat === "CSV"}
//                         onChange={handleChange}
//                         className="no-focus-border"
//                         style={{ marginRight: "10px" }}
//                       />
//                       {/* <Icon.FiletypeCsv size={25} style={{ marginRight: '5px' }}/> */}
//                       <label style={{ fontSize: "15px" }} htmlFor="csv">
//                         <div className="d-flex mt-2">
//                           <div>
//                             <CsvIcon />
//                           </div>
//                           <p className="mx-2 mt-1"> Csv </p>
//                         </div>{" "}
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>
//             {error.fileFormat && (
//               <div className="text-danger">{error.fileFormat}</div>
//             )}
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 padding: "20px 30px",
//               }}
//             >
//               <button
//                 style={{
//                   marginLeft: "50px",
//                   padding: "10px",
//                   borderRadius: "5px",
//                   border: "1px solid var(--primary-color)",
//                   width: "100px",
//                   backgroundColor: "var(--primary-color)",
//                   color: "white",
//                 }}
//                 onClick={handleGoClick}
//               >
//                 GO
//               </button>
//               <button
//                 className="btn btn-primary"
//                 style={{
//                   marginLeft: "50px",
//                   padding: "10px",
//                   borderRadius: "5px",
//                   border: "1px solid var(--primary-color)",
//                   width: "100px",
//                   backgroundColor: "var(--primary-color)",
//                   color: "white",
//                 }}
//                 onClick={resetForm}
//               >
//                 RESET
//               </button>
//             </div>
//           </>
//         )}
//         {/* <>
// <div>
//          <button onClick={() => toPDF()}>Download PDF</button>
//          <div ref={targetRef}>
//             Content to be generated to PDF
//          </div>
//       </div>
// </> */}
//       </div>
//       {open && (
//         <TablePage
//           handleDialogClose={handleDialogClose}
//           isOpen={open}
//           popupcontent={popupcontent}
//           type={props.type}
//         />
//       )}

//       <div
//         style={{
//           marginTop: "50px",
//           boxShadow: "rgb(243, 243, 243) 0px 0px 10px",
//           borderRadius: "12px",
//           backgroundColor: "white",
//           fontFamily: "Poppins",
//           fontWeight: "400",
//           fontSize: "18px",
//           width: "100%",
//         }}
//       >
//         <h6
//           style={{
//             paddingTop: "20px",
//             color: "var(--primary-color)",
//             fontSize: "20px",
//             fontWeight: "500",
//           }}
//         >
//           Drawing Summary
//         </h6>

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             gap: "20px",
//             textAlign: "left",
//             margin: "20px",
//           }}
//         >
//           <label style={{ fontSize: "14px" }}>Select Time Period</label>
//           <select
//             value={selectedPeriod}
//             onChange={handlePeriodChange}
//             style={{ ...selectStyles.base }}
//           >
//             <option>All</option>
//             <option value="monthly">Monthly</option>
//             <option value="quarterly">Quarterly</option>
//             <option value="halfYearly">Half Yearly</option>
//             <option value="yearly">Yearly</option>
//           </select>

//           {(selectedPeriod === "monthly" ||
//             selectedPeriod === "quarterly" ||
//             selectedPeriod === "halfYearly") && (
//             <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
//               <label style={{ fontSize: "14px" }}>Select Month:</label>
//               <select
//                 style={{ ...selectStyles.extended }}
//                 value={selectedMonth}
//                 onChange={(e) => setSelectedMonth(e.target.value)}
//               >
//                 <option value="">Select Month</option>
//                 <option value="1">January</option>
//                 <option value="2">February</option>
//                 <option value="3">March</option>
//                 <option value="4">April</option>
//                 <option value="5">May</option>
//                 <option value="6">June</option>
//                 <option value="7">July</option>
//                 <option value="8">August</option>
//                 <option value="9">September</option>
//                 <option value="10">October</option>
//                 <option value="11">November</option>
//                 <option value="12">December</option>
//               </select>

//               <label style={{ fontSize: "14px" }}>Select Year:</label>
//               <select
//                 style={{ ...selectStyles.extended }}
//                 value={selectedYear}
//                 onChange={(e) => setSelectedYear(e.target.value)}
//               >
//                 <option value="">Select Year</option>
//                 {yearRange.map((year) => (
//                   <option key={year} value={year}>
//                     {year}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           )}

//           {selectedPeriod === "yearly" && (
//             <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//               <label
//                 style={{ fontSize: "14px" }}
//                 value={selectedYear}
//                 onChange={(e) => setSelectedYear(e.target.value)}
//               >
//                 Select Year:
//               </label>
//               <select style={{ width: "170%" }}>
//                 <option value="">Select Year</option>
//                 {yearRange.map((year) => (
//                   <option key={year} value={year}>
//                     {year}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           )}
//         </div>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-around",
//             width: "100%",
//           }}
//         >
//           <div
//             style={{
//               marginTop: "50px",
//               boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
//               borderRadius: "12px",
//               backgroundColor: "white",
//               padding: "15px",
//               fontFamily: "Poppins",
//               fontWeight: "400",
//               fontSize: "18px",
//               width: "46%",
//               height: "auto",
//             }}
//           >
//             <DrawingStatus
//               type={props.type}
//               selctedTimeperiod={selectedPeriod}
//               selectedMonth={selectedMonth}
//               selectedYear={selectedYear}
//             />
//           </div>

//           <div
//             style={{
//               marginTop: "50px",
//               boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
//               borderRadius: "12px",
//               backgroundColor: "white",
//               padding: "15px",
//               fontFamily: "Poppins",
//               fontWeight: "400",
//               fontSize: "18px",
//               width: "46%",
//               height: "auto",
//             }}
//           >
//             <RfiStatus
//               type={props.type}
//               selctedTimeperiod={selectedPeriod}
//               selectedMonth={selectedMonth}
//               selectedYear={selectedYear}
//             />
//           </div>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-around",
//             width: "100%",
//           }}
//         >
//           <div
//             style={{
//               marginTop: "50px",
//               boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
//               borderRadius: "12px",
//               backgroundColor: "white",
//               padding: "15px",
//               fontFamily: "Poppins",
//               fontWeight: "400",
//               fontSize: "18px",
//               width: "46%",
//               height: "auto",
//             }}
//           >
//             <DrawingChart
//               type={props.type}
//               selctedTimeperiod={selectedPeriod}
//               selectedMonth={selectedMonth}
//               selectedYear={selectedYear}
//             />
//           </div>

//           <div
//             style={{
//               marginTop: "50px",
//               boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
//               borderRadius: "12px",
//               backgroundColor: "white",
//               padding: "15px",
//               fontFamily: "Poppins",
//               fontWeight: "400",
//               fontSize: "18px",
//               width: "46%",
//               height: "auto",
//             }}
//           >
//             <RfiChart
//               type={props.type}
//               selctedTimeperiod={selectedPeriod}
//               selectedMonth={selectedMonth}
//               selectedYear={selectedYear}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Analysis;
