/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "../../Dialog/Dialog";

const AddFolderDialog = (props) => {
  const { open, close } = props;
  const webApi = new WebApimanager();
  const [folderName, setFolderName] = useState("");
  const [folderNameError, setFolderNameError] = useState("");
  const [imageError, setImageError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [folderImage, setFolderImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "image/svg+xml") {
        setImageError("SVG images are not allowed.");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setImageError("File size should be less than 5MB.");
        return;
      }

      if (!file.type.startsWith("image/")) {
        setImageError("Only image files are allowed.");
        return;
      }

      setImageError("");

      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }

      const newImagePreview = URL.createObjectURL(file);
      setFolderImage(file);
      setImagePreview(newImagePreview);
    }
  };

  const handleSave = async () => {
    let valid = true;

    if (folderName.trim() === "") {
      setFolderNameError("Folder name is required");
      valid = false;
    } else {
      setFolderNameError("");
    }

    if (!imagePreview) {
      setImageError("An image is required");
      valid = false;
    } else {
      setImageError("");
    }

    if (valid) {
      try {
        const existingFoldersResponse = await webApi.get("api/isCode");

        console.log("Existing folders response:", existingFoldersResponse);

        const existingFolders =
          existingFoldersResponse.data?.data?.isCodes || [];
        console.log("Extracted existing folders data:", existingFolders);

        const folderExists = existingFolders.some(
          (folder) => folder.fName.toLowerCase() === folderName.toLowerCase()
        );
        console.log("Folder exists check:", folderExists);

        if (folderExists) {
          setFolderNameError("Folder name already exists.");
          return;
        }

        setApiProcessing({
          loader: true,
          message: "Submitting...",
        });

        const fName = { fName: folderName };
        const response = await webApi.post("api/isCode", fName);
        console.log("Folder creation response:", response);

        if (response.data && response.data.data && response.data.data.isCode) {
          const responseId = response.data.data.isCode._id;
          console.log("Response ID:", responseId);
          if (folderImage) {
            const fImage = new FormData();
            fImage.append("fImage", folderImage);

            await webApi.imagePut(`api/isCode/fImage/${responseId}`, fImage);
            console.log("Image upload response:", response);
          }

          setApiProcessing({ loader: false, message: "" });
          close();
          window.location.reload();
        } else {
          console.error("Unexpected response structure:", response);
          setErrorMessage("Unexpected response structure.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setApiProcessing({ loader: false, message: "" });
        const errorMsg =
          error.response?.data?.message ||
          "Failed to submit the form. Please try again later.";
        setErrorMessage(errorMsg);
      }
    }
  };

  const handleClose = () => {
    setFolderName("");
    setImageError("");
    setFolderNameError("");
    setFolderImage(null);
    setImagePreview(null);
    setErrorMessage("");
    close();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: " #4D4D4D",
          margin: "0px",
        }}
      >
        <p style={{ margin: "5px 230px", textAlign: "center", color: "white" }}>
          Add Folder
        </p>
        <RxCross1
          size={20}
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            marginRight: "20px",
            color: "white",
          }}
          onClick={handleClose}
        />
      </div>
      <DialogContent
        style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
      >
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <div style={{ textAlign: "center", padding: "20px" }}>
          <div
            style={{
              position: "relative",
              width: "125px",
              height: "125px",
              margin: "0 auto",
              borderRadius: "50%",
              backgroundColor: imagePreview ? "transparent" : "#f2f2f2",
              backgroundImage: imagePreview ? `url(${imagePreview})` : "none",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() =>
              imagePreview &&
              document.getElementById("folder-image-input").click()
            }
          >
            {!imagePreview && (
              <div
                style={{
                  position: "relative",
                  bottom: "-40px",
                  right: "-50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  document.getElementById("folder-image-input").click()
                }
              >
                <Icon.PlusCircle size={25} />
              </div>
            )}
          </div>
        </div>
        <div>
          <input
            id="folder-image-input"
            type="file"
            name="folder-image-input"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none", height: '43px'  }}
          />
          {imageError && (
            <div style={{ color: "red", marginTop: "5px" }}>{imageError}</div>
          )}
        </div>
        <div style={{ margin: "50px" }}>
          <label
            htmlFor="enterfolderName"
            className="fs-10 fw-bold text-start py-2"
          >
            Enter Your Folder Name <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            name="enterfolderName"
            value={folderName}
            placeholder="Enter folder name here"
            className="form-control fs-12"
            style={{ width: "100%" }}
            onChange={(e) => setFolderName(e.target.value)}
          />
          {folderNameError && (
            <div style={{ color: "red", marginTop: "5px" }}>
              {folderNameError}
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <button
            style={{
              width: "175px",
              height: "50px",
              marginLeft: "50px",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid var(--primary-color)",
              backgroundColor: "var(--primary-color)",
              color: "white",
            }}
            onClick={handleSave}
          >
            UPLOAD
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default AddFolderDialog;
