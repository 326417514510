import React, { useEffect, useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import "./TransferPermanentWork.css"
import { FaCirclePlus } from "react-icons/fa6";
import { FaCircleMinus } from "react-icons/fa6";
import { useRecoilState } from "recoil"
import {siteId} from "../../../atoms";
import { WebApimanager } from '../../../WebApiManager';
import { Dialog , DialogContent } from '../../Dialog/Dialog';

const TransferTemporaryWork = ({showTemporaryForm,handleClose}) => {
  let webApi=new  WebApimanager()
  const [site,setSite ] = useRecoilState(siteId)
    const [formData,setFormData] = useState({
        user:"",
        fromDate:"",
        endDate:"",
      })
    const [allocatedEmployee,setAllocatedEmployee] = useState([{
      user:"",fromDate:"",toDate:"",tasks:[""]
    }])
    const [assignedTask,setAssignedTask] = useState([])
    const [pnmUsers,setPnmUsers] = useState([])
    const [errors, setErrors] = useState({});

    const handleAddEmployee = () =>{
      setAllocatedEmployee((prev)=>[...prev,{user:"",fromDate:"",toDate:"",tasks:[""]}])
    }

    const handleRemoveEmployee = (index) => {
      setAllocatedEmployee(prev => prev.filter((_, idx) => idx !== index));
    };

    const handleAddTask = (employeeIndex) => {
      const updatedEmployees = [...allocatedEmployee];
      updatedEmployees[employeeIndex].tasks.push(""); 
      setAllocatedEmployee(updatedEmployees);
    };
  
    const handleRemoveTask = (employeeIndex, taskIndex) => {
      const updatedEmployees = [...allocatedEmployee];
      updatedEmployees[employeeIndex].tasks = updatedEmployees[employeeIndex].tasks.filter((_, idx) => idx !== taskIndex);
      setAllocatedEmployee(updatedEmployees);
    };

    const handleFormChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
  
    const handleEmployeeChange = (e, empIndex) => {
      const { name, value } = e.target;
      const updatedEmployees = [...allocatedEmployee];
      updatedEmployees[empIndex][name] = value;
      setAllocatedEmployee(updatedEmployees);
    };
  
    const handleTaskChange = (e, empIndex, taskIndex) => {
      const { value } = e.target;
      const updatedEmployees = [...allocatedEmployee];
      updatedEmployees[empIndex].tasks[taskIndex] = value;
      setAllocatedEmployee(updatedEmployees);
    };

    const validateForm = () => {
      const newErrors = {};
      if (!formData.user) newErrors.user = 'User is required';
      if (!formData.fromDate) newErrors.fromDate = 'From date is required';
      if (!formData.toDate) newErrors.toDate = 'To date is required';
  
      allocatedEmployee.forEach((employee, idx) => {
        if (!employee.user) newErrors[`employeeUser_${idx}`] = 'User is required';
        if (!employee.fromDate)
          newErrors[`employeeFromDate_${idx}`] = 'From date is required';
        if (!employee.toDate)
          newErrors[`employeeToDate_${idx}`] = 'To date is required';
        employee.tasks.forEach((task, tIdx) => {
          if (!task) newErrors[`employeeTask_${idx}_${tIdx}`] = 'Task is required';
        });
      });
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleSubmit = async(e) => {
      e.preventDefault();
      if (validateForm()) {
        console.log('Form is valid');
        const payload = {
            user:formData.user,
            fromDate:formData.fromDate,
            toDate:formData.toDate,
            assignNewPnmTasks:assignedTask.map((tasks)=>tasks.assignnewPnmTasksForUser.map((task)=>task.assignNewPnmTasks._id))[0],
            assignToAnotherUser:
              allocatedEmployee.map((emp)=>({assignedToUser:emp.user,fromDate:emp.fromDate,toDate:formData.toDate,assignNewPnmTasks:emp.tasks}))     

             
            
          }
          console.log(payload,"payload post call")
          console.log(assignedTask.forEach((tasks)=>tasks.assignnewPnmTasksForUser.map((task)=>task.assignNewPnmTasks._id)),'asdsfds')
          console.log(allocatedEmployee.tasks)
          try {
            const response = await webApi.post(`api/newPnmTransfer/temp`,payload)
            console.log(response,"post response in pnm")
          } catch (error) {
            
          }
      }
    };

    useEffect(()=>{
        const fetchPnMUsers = async() =>{
        try {
          const response= await webApi.get(`api/checklistDesign/users?department=PNM`)
          console.log(response,"Add New Click")
          if(response.status === 200){
            setPnmUsers(response.data.data.users)
          }
        } catch (error) {
          console.log(error.message)
        }
      }

        fetchPnMUsers()

      
      },[formData])

    useEffect(()=>{
      const fetchAllTasks= async() =>{
        if(formData.user && site){
          try {
            const response= await webApi.get(`api/Task/allNewPnmTasks/user/${formData.user}/siteId/${site}`)
            console.log(response,"response in permanent work")
            if(response.status === 200 ){
              setAssignedTask(response.data.data.tasks)
            }
          } catch (error) {
            console.log(error.message)
          }
        }
        
      }



        fetchAllTasks()
      },[formData])

      useEffect(()=>{
        console.log(allocatedEmployee,"allocatedEmployee")
      },[allocatedEmployee])

  return (
    <Dialog
        open={showTemporaryForm}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: '50px',
          }}
        >
          <p
            style={{width:"100%",marginTop:"8px", textAlign: "center", color: "white" }}
          >
            Create
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "300px" }}
        >
          <form onSubmit={handleSubmit}>
            <p>Q : Who is the Person taking Leave?</p>
            <div className="TPWMainContainer">

              
              <div className="TPWTempMainInputFieldContainer">
                <p>User :</p>
                <div className="TPWtempInputContainer" style={{position:"relative"}}>
                <select className="AWInputField" onChange={handleFormChange}
                name="user"
                value={formData.user} >
                    
                    <option value="">Select</option>
                    {
                      pnmUsers.length ? pnmUsers.map((user)=>(
                        <option key={user._id} value={user._id}>{user.firstName+" "+user.lastName}</option>
                      ))
                    :""}
                  </select>                   
                  {errors.user && <p style={{ color: 'red' ,position:"absolute",textWrap:"nowrap",fontSize:"14px"}}>{errors.user}</p>}
                </div>
              </div>
            </div>
            <p>Q : Cite the duration of the leave?</p>
            <div className="TPWMainContainer">

              <div className="TPWTempMainInputFieldContainer">
                <p>fromDate :</p>
                <div className="TPWtempInputContainer" style={{position:"relative"}}>
                  <input type="date" name="fromDate" value={formData.fromDate} onChange={handleFormChange}/>
                  {errors.fromDate && <p style={{ color: 'red' ,position:"absolute",textWrap:"nowrap",fontSize:"14px"}}>{errors.fromDate}</p>}
                </div>
              </div>
              <div className="TPWTempMainInputFieldContainer">
                <p>ToDate :</p>
                <div className="TPWtempInputContainer" style={{position:"relative"}}>
                  <input type="date" name="toDate" value={formData.toDate} onChange={handleFormChange}/>
                  {errors.toDate && <p style={{ color: 'red' ,position:"absolute",textWrap:"nowrap",fontSize:"14px"}}>{errors.toDate}</p>}
                </div>
              </div>
            </div>

            <p style={{fontWeight:"600"}}>Tasks:</p>
            {assignedTask.map((tasks)=>(tasks?.assignnewPnmTasksForUser?.map((task,index)=>(<p key={index}>{index+1}. &nbsp;&nbsp; {task?.title}</p>))))}

            
            <p style={{display:"flex"}}>Allocate this work to other employees: <span><FaCirclePlus style={{fontSize:"20px",marginLeft:"15px"}} onClick={handleAddEmployee}/></span></p>

            {allocatedEmployee.map((employee,empIndex)=>(
              <div className="TTWMainContainer" style={{paddingRight:"80px",marginBottom:"40px"}} key={empIndex}>
                <div className="TTWInputContainer" style={{position:"relative"}}>
                    <label>User :</label>
                    <select name="user" 
                      value={employee.user}
                      onChange={(e) => handleEmployeeChange(e, empIndex)}
                      >
                        <option value="">Select Employee</option>
                    {
                      pnmUsers.length ? pnmUsers.filter((user)=>user._id!==formData.user).map((user)=>(
                        <option key={user._id} value={user._id}>{user.firstName+" "+user.lastName}</option>
                      ))
                    :""}
                    </select>
                    {empIndex>0 && <FaCircleMinus  style={{position:"absolute",fontSize:"24px",top:"10px",left:"100%",marginLeft:"10px"}} onClick={()=>handleRemoveEmployee(empIndex) }/>}
                    {errors[`employeeUser_${empIndex}`] && (
                  <p style={{ color: 'red' ,position:"absolute",textWrap:"nowrap",fontSize:"14px",top:"100%",left:"126px"}}>{errors[`employeeUser_${empIndex}`]}</p>
                )}
                </div>
                <div className="TPWMainContainer">

                <div className="TPWTempMainInputFieldContainer">
                    <p>fromDate :</p>
                    <div className="TPWtempInputContainer" style={{position:"relative"}}>
                    <input type="date" name="fromDate"
                      value={employee.fromDate}
                      onChange={(e) => handleEmployeeChange(e, empIndex)} />
                      {errors[`employeeFromDate_${empIndex}`] && (
                      <p style={{ color: 'red' ,position:"absolute",textWrap:"nowrap",fontSize:"14px"}}>{errors[`employeeFromDate_${empIndex}`]}</p>
                    )}
                    </div>
                </div>
                <div className="TPWTempMainInputFieldContainer">
                    <p>ToDate :</p>
                    <div className="TPWtempInputContainer" style={{position:"relative"}}>
                    <input type="date" name="toDate"
                      value={employee.toDate}
                      onChange={(e) => handleEmployeeChange(e, empIndex)}
                    />
                    {errors[`employeeToDate_${empIndex}`] && (
                      <p style={{ color: 'red' ,position:"absolute",textWrap:"nowrap",fontSize:"14px"}}>{errors[`employeeToDate_${empIndex}`]}</p>
                    )}
                    </div>
                </div>
                </div>

                {employee.tasks.map((task,taskIndex)=>(<div className="TTWInputContainer" style={{position:"relative"}} key={taskIndex}>
                        <label>Task :</label>
                        <select value={task}
                          onChange={(e) => handleTaskChange(e, empIndex, taskIndex)}>
                            <option value="">Select Task</option>
                            {assignedTask.length && assignedTask.map((task)=>(
                      task.assignnewPnmTasksForUser.length && task.assignnewPnmTasksForUser.map((taskAssigned)=>(
                        // console.log(taskAssigned.assignNewPnmTasks._id,"taskAsigned")
                      <option value={taskAssigned?.assignNewPnmTasks?._id}>{taskAssigned.title}</option>

                      ))
                    ))}
                        </select>
                        {errors[`employeeTask_${empIndex}_${taskIndex}`] && (
                    <p style={{ color: 'red' ,position:"absolute",textWrap:"nowrap",fontSize:"14px",top:"100%",left:"126px"}}>{errors[`employeeTask_${empIndex}_${taskIndex}`]}</p>
                  )}
                        {taskIndex === 0 ?<FaCirclePlus style={{position:"absolute",fontSize:"24px",top:"10px",left:"100%",marginLeft:"10px"}} onClick={()=>handleAddTask(empIndex,taskIndex)}/>:<FaCircleMinus style={{position:"absolute",fontSize:"24px",top:"10px",left:"100%",marginLeft:"10px"}} onClick={()=>handleRemoveTask(empIndex,taskIndex)}/>}
                </div>))}
                
                
                
            </div>))}

            <div className="TPWtempBtnContainer">
              <button>SUBMIT</button>
            </div>
          </form>
         
        </DialogContent>
      </Dialog>
  )
}

export default TransferTemporaryWork