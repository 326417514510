import React from 'react'
import { RxCross1 } from 'react-icons/rx'
import { Loader } from '../../../Widgets/notificationFeedbacks'
import * as Icon from "react-bootstrap-icons";
import Styles from "../../Checklist/Design/Design.module.css"
import styles from "./AddnewPnm.module.css"
import Filter from "../../../Images/Filter.svg"
import { Button } from "react-bootstrap";
import TableWrapper from '../../../Utilities/TableWrapper';
import DrawingTableWrapper from '../../Drawing/Components/DrawingTableWrapper';
import { Dialog, DialogContent } from '../../Dialog/Dialog';

const AddNewPnMTableContent = ({setFormData,setDocumentsData,setShowForm,setOpen,setQuery,setShowFilter,setFilterType,showFilter,trashopen,handleTrashClose,handleDelete,tableData={},filterType,handleEditOpen,handleViewOpen,handleTrash,apiProcessing,formId,query}) => {
  return (
    <>
    <Dialog
        open={trashopen}
        onClose={handleTrashClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 180px", textAlign: "center", color: "white" }}
          >
            Delete
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleTrashClose}
          />
        </div>
        <DialogContent>
          <div>
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <p className={Styles.question}>Are you sure want to Delete ? </p>
        <div className={Styles.buttons}>
        <button className={Styles.noButton}   onClick={handleTrashClose}>No</button>
        <button className={Styles.submitButton}   onClick={() => handleDelete(formId)}>Yes</button>
        </div>
        </div>
        </DialogContent>
      </Dialog>
      <TableWrapper
        children={
          <>
            <div
          style={{
            display: "flex",
            // margin:  "0px 32px 0px 50px" ,
            marginBottom:"16px",
            justifyContent: "space-between",
            alignItems: "center",
            color: "black",
            backgroundColor: "white",
            position: "relative",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            marginInline:"auto"
          }}
        >
          <div  style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"50px"}}>
            <h3
              style={{
                color:  "var(--primary-color)",
                fontSize: "20px",
                margin: 0,
              }}
            >
              Add New P&M
            </h3>
          </div>

          <div
            
            style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"50px",position: "relative"}}
            
          >
           
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "250px",
                    paddingRight: "0px",
                  }}
                >
                  <input
                    placeholder="Search Asset Code List"
                    style={{
                      paddingLeft: "20px",
                      fontSize: "12px",
                      border: "1px solid var(--primary-color)",
                      width: "250px",
                      height: "40px",
                    }}
                    value={query}
                    onChange={(e)=>setQuery(e.target.value)}
                  />
                  <Icon.Search
                    style={{
                      size: "16px",
                      position: "absolute",
                      right: "6px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
                <div style={{width:"40px",height:"40px",position:"relative" }}>
                <img
                    src={Filter}
                    style={{
                      width: "40px",
                      height: "40px",
                      marginLeft: "10px",
                      padding: "5px",
                      border: "1px solid var(--primary-color)",
                      cursor: "pointer",
                    }}
                    onClick={(e)=>setShowFilter((prev)=>!prev)}
                  />
                  {showFilter && <select className={styles.selectInput} style={{position:"absolute",left:"10px"}}
                    onChange={(e)=>{
                      setFilterType(e.target.value)
                      setShowFilter(false)
                      
                    }}
                  >
                    <option value="" hidden>Select type</option>
                    <option value="type">Type</option>
                    <option value="assetCode">Asset</option>
                    <option value="name">Name</option>

                  </select>}

                </div>
                <Button
                  className="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "150px",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "var(--primary-color)",
                    border: "none",
                    marginLeft: "20px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius:"0px"
                  }}
                  onClick={() => {
                    setShowForm(true);
                    setOpen(true);
                    setFormData({})
                    setDocumentsData([])
                  }}
                >
                  <Icon.PlusCircle
                    size={16}
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Add New
                </Button>
              </div>
           
          </div>
            </div>
      <DrawingTableWrapper 
        tableHead={
          <tr>
              <th
                style={{
                  width: "2%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              ></th>
              <th
                style={{
                  width: "5%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              >
                S.No
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              >
                Type
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              >
                AssetCode
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              >
                Sub Code
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              >
                Name
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              >
                Make Name
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              >
                Fuel/Not
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              >
                Own/Hire
              </th>
              <th
                style={{
                  width: "15%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              >
                Next Inspection
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign:"center"
                }}
              >
                Actions
              </th>
            </tr>
        }
        tableBody={
          (tableData.length && filterType === "name") ? tableData.filter((tabData)=>tabData.assetCode?.name.toLowerCase().includes(query.toLowerCase())).map((tabData,index)=>(
            <tr key={tabData._id}  >
                <td
              style={{
                width: "2%",
                fontSize: "14px",
                fontWeight: "500",
              }}
            ></td>
                <td style={{
                width: "2%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{index+1}</td>
                < td style={{
                width: "5%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.type}</td>
                <td  style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.assetCode}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.subCode}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.name}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.makeName}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.fuelRnot}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.type}</td>
                <td style={{
                width: "15%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.inspections}</td>
                <td 
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                    textAlign:"center"
                  }}

              >
                <Icon.Pen
                    size={20}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => handleEditOpen(tabData)}
                  />
                  <Icon.EyeFill
                    size={20}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => handleViewOpen(tabData)}
                  />
                  <Icon.Trash
                    size={20}
                    style={{  cursor: "pointer" }}
                    onClick={() => handleTrash(tabData._id)}
                  />
                </td>
            </tr>
          )):(tableData.length && filterType === "assetCode") ? tableData.filter((tabData)=>tabData.assetCode?.assetCode.toLowerCase().includes(query.toLowerCase())).map((tabData,index)=>(
            <tr key={tabData._id}>
                <td
              style={{
                width: "2%",
                fontSize: "14px",
                fontWeight: "500",
              }}
            ></td>
                <td style={{
                width: "2%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{index+1}</td>
                < td style={{
                width: "5%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.type}</td>
                <td  style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.assetCode}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.subCode}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.name}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.makeName}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.fuelRnot}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.type}</td>
                <td style={{
                width: "15%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.inspections}</td>
                <td 
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                    textAlign:"center"
                  }}

              >
                <Icon.Pen
                    size={20}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => handleEditOpen(tabData)}
                  />
                  <Icon.EyeFill
                    size={20}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => handleViewOpen(tabData)}
                  />
                  <Icon.Trash
                    size={20}
                    style={{  cursor: "pointer" }}
                    // onClick={() => handleTrash(assetCode._id)}
                    onClick={() => handleTrash(tabData._id)}
                  />
                </td>
            </tr>
          )):(tableData.length && filterType === "type") ? tableData.filter((tabData)=>tabData.assetCode?.type.toLowerCase().includes(query.toLowerCase())).map((tabData,index)=>(
            <tr key={tabData._id}>
                <td
              style={{
                width: "2%",
                fontSize: "14px",
                fontWeight: "500",
              }}
            ></td>
                <td style={{
                width: "2%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{index+1}</td>
                < td style={{
                width: "5%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.type}</td>
                <td  style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.assetCode}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.subCode}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.assetCode?.name}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.makeName}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.fuelRnot}</td>
                <td style={{
                width: "10%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.type}</td>
                <td style={{
                width: "15%",
                fontSize: "14px",
                fontWeight: "500",
                textAlign:"center"
              }}>{tabData.inspections}</td>
                <td 
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                    textAlign:"center"
                  }}

              >
                <Icon.Pen
                    size={20}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => handleEditOpen(tabData)}
                  />
                  <Icon.EyeFill
                    size={20}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => handleViewOpen(tabData)}
                  />
                  <Icon.Trash
                    size={20}
                    style={{  cursor: "pointer" }}
                    onClick={() => handleTrash(tabData._id)}
                  />
                </td>
            </tr>
          )):"" 
        }
      />
          </>
        }
      />
      
       
    </>
  )
}

export default AddNewPnMTableContent