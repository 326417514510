// /* eslint-disable react/no-unescaped-entities */
// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import Viewer from "./viewer";
// import CloseIcon from "@mui/icons-material/Close";
// import { userInfo } from "../../atoms";
// import { useRecoilState } from "recoil";
// import SignIn from "../SignIn/SignIn";
// import { WebApimanager } from "../../WebApiManager";
// import { processFile } from "../Drawing/BackendUtils";
// import { Loader } from "../../Widgets/notificationFeedbacks";
// import ApprovalDropdown from "./ApprovalDropdown";
// const DwgViewer = () => {
//   let webApi = new WebApimanager();
//   const [camera, setCamera] = useState(null);
//   const [selectedIds, setSelectedIds] = useState([]);
//   const [urn, setUrn] = useState("");
//   const [token, setToken] = useState("");
//   const [rowdata, setRowData] = useState([]);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [apiProcessing, setApiProcessing] = useState({
//     loader: false,
//     message: "Loading...",
//   });
//   const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
//   const [selectedRevision, setSelectedRevision] = useState("");
//   const [type, setType] = useState("");
//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const urnFromParams = queryParams.get("urn");
//     const tokenFromParams = queryParams.get("token");
//     const { state } = location;
//     const data = state?.data || [];
//     const filtertype = state?.filter;
//     const revision = state?.revision;
//     console.log(filtertype, "checking filtertype");
//     setType(filtertype);
//     setRowData(data);
//     setUrn(urnFromParams);
//     setToken(tokenFromParams);

//     if (!selectedRevision) {
//       setSelectedRevision(revision);
//       console.log(revision, "selcted revision");
//     }
//   }, [location, selectedRevision]);
//   const handleCameraChange = ({ camera }) => {
//     setCamera(camera.getWorldPosition());
//   };

//   const handleSelectionChange = ({ ids }) => {
//     setSelectedIds(ids);
//   };

//   const handleBackClick = () => {
//     navigate("/drawing");
//   };
//   useEffect(() => {
//     console.log("Updated Row Data:", rowdata);
//   }, [rowdata]);

//   const handleRevisionChange = async (selectedValue) => {
//     setSelectedRevision(selectedValue);
//     if (!selectedValue) return;

//     setApiProcessing({ loader: true, message: "Viewing" });

//     try {
//       let apiEndpoint = "";
//       switch (type) {
//         case "Approval":
//           apiEndpoint = `api/architectureToRoRegister/${rowdata.drawingId}/revisions/${selectedValue}`;
//           break;
//         case "SiteLevelApproval":
//           if (rowdata.from === "architect") {
//             apiEndpoint = `api/architectureToRoRegister/${rowdata.drawingId}/revisions/${selectedValue}`;
//           } else if (rowdata.from === "ro") {
//             apiEndpoint = `api/architectureToRoRegister/${rowdata.drawingId}/revisionsRo/${selectedValue}`;
//           } else {
//             apiEndpoint = `api/architectureToRoRegister/${rowdata.drawingId}/revisionsSiteHead/${selectedValue}`;
//           }
//           break;
//         default:
//           throw new Error("Invalid type provided");
//       }

//       const response = await webApi.getImage(apiEndpoint, {
//         responseType: "arraybuffer",
//       });

//       console.log(`Image for type "${type}" retrieved successfully`, response);

//       processFile(response, "view", navigate, selectedValue, rowdata, type);
//     } catch (error) {
//       console.error(`Error viewing file for type "${type}":`, error);
//     } finally {
//       setApiProcessing({ loader: false, message: "" });
//     }
//   };
//   // const getDropdownData = () => {
//   //   const truncateText = (text) =>
//   //     text.length > 50 ? `${text.substring(0, 50)}...` : text; 
//   //   switch (type) {
//   //     case "Approval":
//   //       return {
//   //         label: rowdata?.drawingNo || "",
//   //         options: rowdata?.acceptedArchitectRevisions?.map((item) => ({
//   //           revision: item.revision,
//   //           additionalContent: truncateText(
//   //             `${rowdata?.drawingTitle || ""} ${item.softCopySubmittedDate}`
//   //           ),
//   //         })),
//   //       };
//   //     case "siteHeadApproval":
//   //       return {
//   //         label: rowdata?.drawingNo || "",
//   //         options: rowdata?.acceptedRORevisions?.map((item) => ({
//   //           revision: item.revision,
//   //           additionalContent: truncateText(
//   //             `${rowdata?.drawingTitle || ""} ${item.softCopySubmittedDate}`
//   //           ),
//   //         })),
//   //       };
//   //     case "SiteLevelApproval":
//   //       return {
//   //         label: rowdata?.drawingNo || "",
//   //         options: rowdata?.acceptedSiteHeadRevisions?.map((item) => ({
//   //           revision: item.revision,
//   //           additionalContent: truncateText(
//   //             `${rowdata?.drawingTitle || ""} ${item.softCopySubmittedDate}`
//   //           ),
//   //         })),
//   //       };
//   //     default:
//   //       return { label: "", options: [] };
//   //   }
//   // };
//   const getDropdownData = () => {
//     switch (type) {
//       case "Approval":
//         return {
//           label: rowdata?.drawingNo || "",
//           options: rowdata?.acceptedArchitectRevisions?.map((item) => ({
//             revision: item.revision,
//             additionalContent: `${rowdata?.drawingNo || ""} ${
//               item.softCopySubmittedDate
//             }`,
//           })),
//         };
//       case "siteHeadApproval":
//         return {
//           label: rowdata?.drawingNo || "",
//           options: rowdata?.acceptedRORevisions?.map((item) => ({
//             revision: item.revision,
//             additionalContent:  `${rowdata?.drawingNo || ""} ${
//               item.softCopySubmittedDate
//             }`,
//           })),
//         };
//       case "SiteLevelApproval":
//         return {
//           label: rowdata?.drawingNo || "",
//           options: rowdata?.acceptedSiteHeadRevisions?.map((item) => ({
//             revision: item.revision,
//             additionalContent:  `${rowdata?.drawingNo || ""} ${
//               item.softCopySubmittedDate
//             }`,
//           })),
//         };
//       default:
//         return { label: "", options: [] };
//     }
//   };

//   const dropdownData = getDropdownData();
//   return (
//     <div>
      
//       {/*  TODO: after checking remove
//       {!currentUserInfo._id ? (
//         <p style={{ textAlign: "center", marginTop: "10px" }}>
//           Please login the page <SignIn />
//         </p>
//       ) : ( */}
//         <>
//           <Loader open={apiProcessing.loader} message={apiProcessing.message} />
//           <div
//             style={{
//               padding: "25px",
//               backgroundColor: "#4d4d4d",
//               position: "absolute",
//               top: "0",
//               width: "100%",
//             }}
//           >
//             <div style={{ display: "flex", gap: "10px" }}>
//               {type === "Approval" && (
//                 <>
//                   <label style={{marginTop:'7px',color: "white"}}>{rowdata?.drawingNo || ""}</label>
//                   <ApprovalDropdown
//                     label={dropdownData.label}
//                     options={dropdownData.options}
//                     selectedValue={selectedRevision}
//                     onChange={handleRevisionChange}
//                   />
//                   {rowdata?.acceptedArchitectRevisions?.length > 0 && (
//                     <div
//                       style={{
//                         justifyContent: "center",
//                         marginTop:'7px'
//                       }}
//                     >
//                       {(() => {
//                         const hardCopyRevision =
//                           rowdata?.acceptedROHardCopyRevisions[
//                             rowdata?.acceptedROHardCopyRevisions?.length - 1
//                           ]?.revision;

//                         const currentRevision =
//                           rowdata?.acceptedArchitectRevisions[
//                             rowdata?.acceptedArchitectRevisions?.length - 1
//                           ]?.revision;

//                         const selectedRevisionItem =
//                           rowdata?.acceptedArchitectRevisions.find(
//                             (rev) => rev.revision === selectedRevision
//                           );

//                         if (!selectedRevisionItem) {
//                           return (
//                             <div style={{ color: "red" }}>
//                               Please select a revision
//                             </div>
//                           );
//                         }
//                         if (currentRevision === hardCopyRevision) {
//                           if (
//                             selectedRevisionItem?.rfiStatus === "Not Raised"
//                           ) {
//                             return (
//                               <div
//                                 style={{
//                                   display: "flex",
//                                   justifyContent: "center",
//                                   fontWeight:'500',
//                                   color: "green",
//                                 }}
//                               >
//                                 Good For Construction
//                               </div>
//                             );
//                           } else {
//                             return (
//                               <p
//                                 style={{
//                                   color: "#FFB200",
//                                   textAlign: "center",
//                                   fontSize: "16px",
//                                   fontWeight:'500',

//                                 }}
//                               >
//                                 RFI has been raised
//                               </p>
//                             );
//                           }
//                         }
//                         if (selectedRevisionItem?.rfiStatus === "Raised") {
//                           return (
//                             <p
//                               style={{
//                                 color: "#FFB200",
//                                 textAlign: "center",
//                                 fontSize: "16px",
//                                 fontWeight:'500',
//                               }}
//                             >
//                               RFI has been raised, Hardcopy file not received
//                             </p>
//                           );
//                         }
//                         if (
//                           hardCopyRevision === selectedRevisionItem?.revision
//                         ) {
//                           return (
//                             <div
//                               style={{
//                                 display: "flex",
//                                 justifyContent: "center",
//                                 marginTop: "10px",
//                                 color: "red",
//                                 fontWeight:'500',
//                               }}
//                             >
//                               Superseded
//                             </div>
//                           );
//                         }

//                         // Fallback for no hard copy
//                         return (
//                           <div
//                             style={{
//                               display: "flex",
//                               justifyContent: "center",
//                               marginTop: "10px",
//                               color: "red",
//                               fontWeight:'500',
//                             }}
//                           >
//                             No hardcopy file received
//                           </div>
//                         );
//                       })()}
//                     </div>
//                   )}
//                 </>
//               )}
//               {type === "siteHeadApproval" && (
//                 <>
//                   <label style={{marginTop:'7px',color: "white"}}>{rowdata?.drawingNo || ""}</label>
//                   <ApprovalDropdown
//                     label={dropdownData.label}
//                     options={dropdownData.options}
//                     selectedValue={selectedRevision}
//                     onChange={handleRevisionChange}
//                   />
//                   {rowdata?.acceptedRORevisions?.length > 0 && (
//                     <div
//                       style={{
//                         justifyContent: "center",
//                         marginTop:'7px'
//                       }}
//                     >
//                       {(() => {
//                         const hardCopyRevision =
//                           rowdata?.acceptedRORevisions[
//                             rowdata?.acceptedRORevisions?.length - 1
//                           ]?.revision;

//                         const currentRevision =
//                           rowdata?.acceptedRORevisions[
//                             rowdata?.acceptedRORevisions?.length - 1
//                           ]?.revision;

//                         const selectedRevisionItem =
//                           rowdata?.acceptedRORevisions.find(
//                             (rev) => rev.revision === selectedRevision
//                           );

//                         if (!selectedRevisionItem) {
//                           return (
//                             <div style={{ color: "red" }}>
//                               Please select a revision
//                             </div>
//                           );
//                         }

//                         // Latest revision matches hard copy and no RFI raised
//                         if (currentRevision === hardCopyRevision) {
//                           if (
//                             selectedRevisionItem?.currentRevisionRfiStatus ===
//                             "Not Raised"
//                           ) {
//                             return (
//                               <div
//                                 style={{
//                                   display: "flex",
//                                   justifyContent: "center",
//                                   color: "green",
//                                   fontWeight:'500',
//                                 }}
//                               >
//                                 Good For Construction
//                               </div>
//                             );
//                           } else {
//                             return (
//                               <p
//                                 style={{
//                                   color: "#FFB200",
//                                   textAlign: "center",
//                                   fontSize: "16px",
//                                   fontWeight:'500',
//                                 }}
//                               >
//                                 RFI has been raised
//                               </p>
//                             );
//                           }
//                         }

//                         // RFI raised but hard copy not received
//                         if (
//                           selectedRevisionItem?.currentRevisionRfiStatus ===
//                           "Raised"
//                         ) {
//                           return (
//                             <p
//                               style={{
//                                 color: "#FFB200",
//                                 textAlign: "center",
//                                 fontSize: "16px",
//                                 fontWeight:'500',
//                               }}
//                             >
//                               RFI has been raised, Hardcopy file not received
//                             </p>
//                           );
//                         }

//                         // Revision matches hard copy but is not the latest
//                         if (
//                           hardCopyRevision === selectedRevisionItem?.revision
//                         ) {
//                           return (
//                             <div
//                               style={{
//                                 display: "flex",
//                                 justifyContent: "center",
//                                 marginTop: "10px",
//                                 color: "red",
//                                 fontWeight:'500',
//                               }}
//                             >
//                               Superseded
//                             </div>
//                           );
//                         }
//                         return (
//                           <div
//                             style={{
//                               display: "flex",
//                               justifyContent: "center",
//                               marginTop: "10px",
//                               color: "red",
//                               fontWeight:'500',
//                             }}
//                           >
//                             No hardcopy file received
//                           </div>
//                         );
//                       })()}
//                     </div>
//                   )}
//                 </>
//               )}
//               {type === "SiteLevelApproval" && (
//                 <>
//                   <label style={{marginTop:'7px',color: "white"}}>{rowdata?.drawingNo || ""}</label>
//                   <ApprovalDropdown
//                     label={dropdownData.label}
//                     options={dropdownData.options}
//                     selectedValue={selectedRevision}
//                     onChange={handleRevisionChange}
//                   />

//                   {rowdata?.acceptedSiteHeadRevisions?.length > 0 && (
//                     <div
//                     style={{
//                       justifyContent: "center",
//                       marginTop:'7px'
//                     }}
//                     >
//                       <div
//                         style={{
//                           display: "flex",
//                           justifyContent: "center",
//                         }}
//                       >
//                         {(() => {
//                           const selectedRevisionItem =
//                             rowdata?.acceptedSiteHeadRevisions.find(
//                               (rev) => rev.revision === selectedRevision
//                             );
//                           if (!selectedRevisionItem) {
//                             return (
//                               <div style={{ color: "red" }}>
//                                 Please select a revision
//                               </div>
//                             );
//                           }

//                           const latestRevision =
//                             rowdata?.acceptedSiteHeadRevisions[
//                               rowdata?.acceptedSiteHeadRevisions.length - 1
//                             ]?.revision;
//                           if (selectedRevision === latestRevision) {
//                             if (
//                               selectedRevisionItem?.rfiStatus === "Raised" ||
//                               selectedRevisionItem?.currentRevisionRfiStatus ===
//                                 "Raised"
//                             ) {
//                               return (
//                                 <div style={{ color: "orange", fontWeight:'500', }}>
//                                   RFI has been raised, Don't use in the site
//                                 </div>
//                               );
//                             } else {
//                               return (
//                                 <div style={{ color: "green", fontWeight:'500', display: "flex",
//                                   justifyContent: "center", }}>
//                                   Good For Construction
//                                 </div>
//                               );
//                             }
//                           } else {
//                             return (
//                               <div style={{ color: "red", fontWeight:'500', }}>Superseded</div>
//                             );
//                           }
//                         })()}
//                       </div>
//                     </div>
//                   )}
//                 </>
//               )}
//               {type === "RFI" && (
//                 <label style={{ color: "white", fontWeight:'500', }}>
//                   RFI Revision: {selectedRevision}
//                 </label>
//               )}
//             </div>
//             <div>
//               <CloseIcon
//                 onClick={handleBackClick}
//                 style={{
//                   cursor: "pointer",
//                   position: "absolute",
//                   right: "0",
//                   marginTop: "-55px",
//                   color: "white"
//                 }}
//               />
//             </div>
//           </div>
//           <div
//             style={{
//               marginTop: "30px",
//               width: "100%",
//               height: "93vh",
//               position: "relative",
//               overflow: "hidden",
//             }}
//           >
//             {urn && token ? (
//               <Viewer
//                 urn={urn}
//                 token={token}
//                 onCameraChange={handleCameraChange}
//                 onSelectionChange={handleSelectionChange}
//               />
//             ) : (
//               <div>No URN or token provided</div>
//             )}
//           </div>
//         </>
//       {/* // )} */}
//     </div>
//   );
// };

// export default DwgViewer;


import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Viewer from "./viewer";
import CloseIcon from "@mui/icons-material/Close";
import { userInfo } from "../../atoms";
import { useRecoilState } from "recoil";
import SignIn from "../SignIn/SignIn";
import { WebApimanager } from "../../WebApiManager";
import { processFile } from "../Drawing/BackendUtils";
import { Loader } from "../../Widgets/notificationFeedbacks";
import ApprovalDropdown from "./ApprovalDropdown";

const DwgViewer = () => {
  const webApi = new WebApimanager();
  const [camera, setCamera] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [urn, setUrn] = useState("");
  const [token, setToken] = useState("");
  const [rowdata, setRowData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [selectedRevision, setSelectedRevision] = useState("");
  const [type, setType] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

useEffect(() => {
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener("resize", handleResize);
}, []);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const urnFromParams = queryParams.get("urn");
    const tokenFromParams = queryParams.get("token");
    const { state } = location;
    const data = state?.data || [];
    const filtertype = state?.filter;
    const revision = state?.revision;
    console.log(filtertype, "checking filtertype");
    setType(filtertype);
    setRowData(data);
    setUrn(urnFromParams);
    setToken(tokenFromParams);

    if (!selectedRevision) {
      setSelectedRevision(revision);
      console.log(revision, "selcted revision");
    }
  }, [location, selectedRevision]);

  const handleCameraChange = ({ camera }) => {
    setCamera(camera.getWorldPosition());
  };

  const handleSelectionChange = ({ ids }) => {
    setSelectedIds(ids);
  };

  const handleBackClick = () => {
    navigate("/drawing");
  };

  useEffect(() => {
    console.log("Updated Row Data:", rowdata);
  }, [rowdata]);

  const handleRevisionChange = async (selectedValue) => {
    setSelectedRevision(selectedValue);
    if (!selectedValue) return;

    setApiProcessing({ loader: true, message: "Viewing" });

    try {
      let apiEndpoint = "";
      switch (type) {
        case "Approval":
          apiEndpoint = `api/architectureToRoRegister/${rowdata.drawingId}/revisions/${selectedValue}`;
          break;
        case "SiteLevelApproval":
          if (rowdata.from === "architect") {
            apiEndpoint = `api/architectureToRoRegister/${rowdata.drawingId}/revisions/${selectedValue}`;
          } else if (rowdata.from === "ro") {
            apiEndpoint = `api/architectureToRoRegister/${rowdata.drawingId}/revisionsRo/${selectedValue}`;
          } else {
            apiEndpoint = `api/architectureToRoRegister/${rowdata.drawingId}/revisionsSiteHead/${selectedValue}`;
          }
          break;
        default:
          throw new Error("Invalid type provided");
      }

      const response = await webApi.getImage(apiEndpoint, {
        responseType: "arraybuffer",
      });

      console.log(`Image for type "${type}" retrieved successfully`, response);

      processFile(response, "view", navigate, selectedValue, rowdata, type);
    } catch (error) {
      console.error(`Error viewing file for type "${type}":`, error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const getDropdownData = () => {
    switch (type) {
      case "Approval":
        return {
          label: rowdata?.drawingNo || "",
          options: rowdata?.acceptedArchitectRevisions?.map((item) => ({
            revision: item.revision,
            additionalContent: `${rowdata?.drawingNo || ""} ${item.softCopySubmittedDate}`,
          })),
        };
      case "siteHeadApproval":
        return {
          label: rowdata?.drawingNo || "",
          options: rowdata?.acceptedRORevisions?.map((item) => ({
            revision: item.revision,
            additionalContent: `${rowdata?.drawingNo || ""} ${item.softCopySubmittedDate}`,
          })),
        };
      case "SiteLevelApproval":
        return {
          label: rowdata?.drawingNo || "",
          options: rowdata?.acceptedSiteHeadRevisions?.map((item) => ({
            revision: item.revision,
            additionalContent: `${rowdata?.drawingNo || ""} ${item.softCopySubmittedDate}`,
          })),
        };
      default:
        return { label: "", options: [] };
    }
  };

  const dropdownData = getDropdownData();

  const styles = {
    container: {
      paddingTop: "25px",
      backgroundColor:!isSmallScreen? "#4d4d4d":"transparent",
      position: "absolute",
      top: "0",
      width: "100%",
      display: "flex",
      gap: "10px",
      flexDirection: "column",
    },
    label: {
      marginTop: '7px',
      color: "white",
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
    },
    viewerContainer: {
      marginTop: "30px",
      width: "100%",
      height: "93vh",
      position: "relative",
      overflow: "hidden",
    },
    closeIcon: {
      cursor: "pointer",
      position: "absolute",
      right: "0",
      marginTop: "-55px",
      color: "white",
    },
    smallScreen: {
      fontSize: "12px",
      padding: "10px",
    },
    mediumScreen: {
      fontSize: "14px",
      padding: "15px",
    },
    largeScreen: {
      fontSize: "16px",
      padding: "20px",
    },
    
  };

  // Media Queries Handling
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 600) {
        document.body.style.fontSize = "12px";
      } else if (width <= 1200) {
        document.body.style.fontSize = "14px";
      } else {
        document.body.style.fontSize = "16px";
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it on initial load

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={styles.container}>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      {!isSmallScreen ? <div>
      {type === "Approval" && (
                <>
                  <label style={{marginTop:'7px',color: "white"}}>{rowdata?.drawingNo || ""}</label>
                  <ApprovalDropdown
                    label={dropdownData.label}
                    options={dropdownData.options}
                    selectedValue={selectedRevision}
                    onChange={handleRevisionChange}
                  />
                  {rowdata?.acceptedArchitectRevisions?.length > 0 && (
                    <div
                      style={{
                        justifyContent: "center",
                        marginTop:'7px'
                      }}
                    >
                      {(() => {
                        const hardCopyRevision =
                          rowdata?.acceptedROHardCopyRevisions[
                            rowdata?.acceptedROHardCopyRevisions?.length - 1
                          ]?.revision;

                        const currentRevision =
                          rowdata?.acceptedArchitectRevisions[
                            rowdata?.acceptedArchitectRevisions?.length - 1
                          ]?.revision;

                        const selectedRevisionItem =
                          rowdata?.acceptedArchitectRevisions.find(
                            (rev) => rev.revision === selectedRevision
                          );

                        if (!selectedRevisionItem) {
                          return (
                            <div style={{ color: "red" }}>
                              Please select a revision
                            </div>
                          );
                        }
                        if (currentRevision === hardCopyRevision) {
                          if (
                            selectedRevisionItem?.rfiStatus === "Not Raised"
                          ) {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontWeight:'500',
                                  color: "green",
                                }}
                              >
                                Good For Construction
                              </div>
                            );
                          } else {
                            return (
                              <p
                                style={{
                                  color: "#FFB200",
                                  textAlign: "center",
                                  fontSize: "16px",
                                  fontWeight:'500',

                                }}
                              >
                                RFI has been raised
                              </p>
                            );
                          }
                        }
                        if (selectedRevisionItem?.rfiStatus === "Raised") {
                          return (
                            <p
                              style={{
                                color: "#FFB200",
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight:'500',
                              }}
                            >
                              RFI has been raised, Hardcopy file not received
                            </p>
                          );
                        }
                        if (
                          hardCopyRevision === selectedRevisionItem?.revision
                        ) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                                color: "red",
                                fontWeight:'500',
                              }}
                            >
                              Superseded
                            </div>
                          );
                        }

                        // Fallback for no hard copy
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              color: "red",
                              fontWeight:'500',
                            }}
                          >
                            No hardcopy file received
                          </div>
                        );
                      })()}
                    </div>
                  )}
                </>
              )}
              {type === "siteHeadApproval" && (
                <>
                  <label style={{marginTop:'7px',color: "white"}}>{rowdata?.drawingNo || ""}</label>
                  <ApprovalDropdown
                    label={dropdownData.label}
                    options={dropdownData.options}
                    selectedValue={selectedRevision}
                    onChange={handleRevisionChange}
                  />
                  {rowdata?.acceptedRORevisions?.length > 0 && (
                    <div
                      style={{
                        justifyContent: "center",
                        marginTop:'7px'
                      }}
                    >
                      {(() => {
                        const hardCopyRevision =
                          rowdata?.acceptedRORevisions[
                            rowdata?.acceptedRORevisions?.length - 1
                          ]?.revision;

                        const currentRevision =
                          rowdata?.acceptedRORevisions[
                            rowdata?.acceptedRORevisions?.length - 1
                          ]?.revision;

                        const selectedRevisionItem =
                          rowdata?.acceptedRORevisions.find(
                            (rev) => rev.revision === selectedRevision
                          );

                        if (!selectedRevisionItem) {
                          return (
                            <div style={{ color: "red" }}>
                              Please select a revision
                            </div>
                          );
                        }

                        // Latest revision matches hard copy and no RFI raised
                        if (currentRevision === hardCopyRevision) {
                          if (
                            selectedRevisionItem?.currentRevisionRfiStatus ===
                            "Not Raised"
                          ) {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  color: "green",
                                  fontWeight:'500',
                                }}
                              >
                                Good For Construction
                              </div>
                            );
                          } else {
                            return (
                              <p
                                style={{
                                  color: "#FFB200",
                                  textAlign: "center",
                                  fontSize: "16px",
                                  fontWeight:'500',
                                }}
                              >
                                RFI has been raised
                              </p>
                            );
                          }
                        }

                        // RFI raised but hard copy not received
                        if (
                          selectedRevisionItem?.currentRevisionRfiStatus ===
                          "Raised"
                        ) {
                          return (
                            <p
                              style={{
                                color: "#FFB200",
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight:'500',
                              }}
                            >
                              RFI has been raised, Hardcopy file not received
                            </p>
                          );
                        }

                        // Revision matches hard copy but is not the latest
                        if (
                          hardCopyRevision === selectedRevisionItem?.revision
                        ) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                                color: "red",
                                fontWeight:'500',
                              }}
                            >
                              Superseded
                            </div>
                          );
                        }
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              color: "red",
                              fontWeight:'500',
                            }}
                          >
                            No hardcopy file received
                          </div>
                        );
                      })()}
                    </div>
                  )}
                </>
              )}
              {type === "SiteLevelApproval" && (
                <>
                  <label style={{marginTop:'7px',color: "white"}}>{rowdata?.drawingNo || ""}</label>
                  <ApprovalDropdown
                    label={dropdownData.label}
                    options={dropdownData.options}
                    selectedValue={selectedRevision}
                    onChange={handleRevisionChange}
                  />

                  {rowdata?.acceptedSiteHeadRevisions?.length > 0 && (
                    <div
                    style={{
                      justifyContent: "center",
                      marginTop:'7px'
                    }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {(() => {
                          const selectedRevisionItem =
                            rowdata?.acceptedSiteHeadRevisions.find(
                              (rev) => rev.revision === selectedRevision
                            );
                          if (!selectedRevisionItem) {
                            return (
                              <div style={{ color: "red" }}>
                                Please select a revision
                              </div>
                            );
                          }

                          const latestRevision =
                            rowdata?.acceptedSiteHeadRevisions[
                              rowdata?.acceptedSiteHeadRevisions.length - 1
                            ]?.revision;
                          if (selectedRevision === latestRevision) {
                            if (
                              selectedRevisionItem?.rfiStatus === "Raised" ||
                              selectedRevisionItem?.currentRevisionRfiStatus ===
                                "Raised"
                            ) {
                              return (
                                <div style={{ color: "orange", fontWeight:'500', }}>
                                  RFI has been raised, Don't use in the site
                                </div>
                              );
                            } else {
                              return (
                                <div style={{ color: "green", fontWeight:'500', display: "flex",
                                  justifyContent: "center", }}>
                                  Good For Construction
                                </div>
                              );
                            }
                          } else {
                            return (
                              <div style={{ color: "red", fontWeight:'500', }}>Superseded</div>
                            );
                          }
                        })()}
                      </div>
                    </div>
                  )}
                </>
              )}
              {type === "RFI" && (
                <label style={{ color: "white", fontWeight:'500', }}>
                  RFI Revision: {selectedRevision}
                </label>
              )}
            </div>:""}
      <div>
              <CloseIcon
                onClick={handleBackClick}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "0",
                  marginTop: "-55px",
                  color: "white"
                }}
              />
      </div>
      <div style={styles.viewerContainer}>
        {urn && token ? (
          <Viewer
            urn={urn}
            token={token}
            onCameraChange={handleCameraChange}
            onSelectionChange={handleSelectionChange}
          />
        ) : (
          <div>No URN or token provided</div>
        )}
      </div>
    </div>
  );
};

export default DwgViewer;
