/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../WebApiManager";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { Dialog , DialogContent } from "../Dialog/Dialog";

const UploadLogoDialog = (props) => {
  const { open, close, id, type } = props;
  const webApi = new WebApimanager();
  const [imageError, setImageError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  // const handleImageChange = (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //         // Validate the file (e.g., check file type, size, etc.)
  //         if (file.size > 5 * 1024 * 1024) { // Example: limit to 5MB
  //             setImageError("File size should be less than 5MB.");
  //             return;
  //         }

  //         if (!file.type.startsWith("image/")) {
  //             setImageError("Only image files are allowed.");
  //             return;
  //         }

  //         // Clear any previous image error
  //         setImageError("");

  //         // Revoke the old object URL if there is one
  //         if (imagePreview) {
  //             URL.revokeObjectURL(imagePreview);
  //         }

  //         // Create new object URL and set state
  //         const newImagePreview = URL.createObjectURL(file);
  //         setLogoImage(file);
  //         setImagePreview(newImagePreview); // Set the preview URL
  //     }
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageError("");

      const validationImage = ["image/png", "image/jpeg", "image/jpg"];
      if (!validationImage.includes(file.type)) {
        setImageError("Only PNG, JPG, and JPEG files are allowed.");
        return;
      }

      const newImagePreview = URL.createObjectURL(file);
      setLogoImage(file);
      setImagePreview(newImagePreview);
    }
  };

  const handleSave = async () => {
    let valid = true;
    let endpoint = "";

    if (!imagePreview) {
      setImageError("An image is required");
      valid = false;
    } else {
      setImageError("");
    }

    if (valid) {
      try {
        if (type === "company") {
          endpoint = `api/company/logo/${id}`;
        } else {
          endpoint = `api/sites/siteImage/${id}`;
        }

        const formData = new FormData();
        formData.append(type === "company" ? "logo" : "siteImage", logoImage);

        const response = await webApi.imagePut(endpoint, formData);
        if (response.status === 200) {
          setApiProcessing({ loader: false, message: "" });
          close();
          window.location.reload();
        } else {
          setErrorMessage("Failed to upload the image. Please try again.");
        }
      } catch (error) {
        setApiProcessing({ loader: false, message: "" });
        const errorMsg =
          error.response?.data?.message ||
          "Failed to submit the form. Please try again later.";
        setErrorMessage(errorMsg);
      }
    }
  };

  const handleClose = () => {
    setImageError("");
    setLogoImage(null);
    setImagePreview(null);
    setErrorMessage("");
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#4D4D4D",
          margin: "0px",
        }}
      >
        <p style={{ margin: "5px 200px", textAlign: "center", color: "white" }}>
          Image Upload
        </p>
        <RxCross1
          size={20}
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            marginRight: "20px",
            color: "white",
          }}
          onClick={handleClose}
        />
      </div>
      <DialogContent
        style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
      >
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <div style={{ textAlign: "center", padding: "20px" }}>
          <div
            style={{
              position: "relative",
              width: "500px",
              height: "200px",
              margin: "0 auto",
              backgroundColor: "#F3F3F3",
              backgroundImage: imagePreview ? `url(${imagePreview})` : "none",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          ></div>
        </div>
        <div style={{ margin: "50px" }}>
          <label
            htmlFor="uploadlogo"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "start",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
          >
            Upload Your File<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="file"
            name="uploadlogo"
            id="uploadlogo"
            className="form-control "
            style={{ width: "100%", fontSize: "12px", height: "43px" }}
            onChange={handleImageChange}
          />

          {imageError && (
            <div style={{ color: "red", marginTop: "5px" }}>{imageError}</div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <button
            style={{
              width: "175px",
              height: "50px",
              marginLeft: "50px",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid var(--primary-color)",
              backgroundColor: "var(--primary-color)",
              color: "white",
            }}
            onClick={handleSave}
          >
            UPLOAD
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploadLogoDialog;
