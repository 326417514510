/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import * as Icon from "react-bootstrap-icons";
import { useRecoilState } from "recoil";
import { addRegister, folderId, siteId, userInfo } from "../../../atoms";
import { todayDate } from "../../../Utilities/DateUtils";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import TableWrapper from "../../../Utilities/TableWrapper";
import SelectSite from "../../../Utilities/SelectSite";
import SitesDropdown from "../../../Utilities/SitesDropdown";
import { Button } from "react-bootstrap";
import { BsDashCircleFill } from "react-icons/bs";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import RegisterPage from "../RegisterPage";
import FolderDropdown from "../../../Utilities/FolderDropdown";
import AddCategory from "./AddCategory";
import "./AddRegister.css";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import { RxCross1 } from "react-icons/rx";

const fixedPattern = `^[A-Z0-9]{3}-[A-Z0-9]{3}-[A-Z0-9]{3}-GFC-T\\d+F\\d+-`;
  
const regex = new RegExp(`${fixedPattern}[A-Z0-9]{0,3}$`, "i"); 

const AddRegister = (props) => {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const todayDateString = today.toISOString().split("T")[0];
  const maxYear = "9999-12-31";

  let webApi = new WebApimanager();
  const [open, setOpen] = useState(false);
  const [existingConsultants, setExistingConsultants] = useState([]);
  const [existingCategories, setExistingCategories] = useState([]);
  const [existingFolders, setExistingFolders] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [sites, setDatasites] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [numDrawings, setNumDrawings] = useState(0);
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [siteHeadHardCopy, setsiteHeadHardCopy] = useState("");
  const [roHardCopy, setroHardCopy] = useState("");
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [site, setSite] = useRecoilState(siteId);
  const [folder, setFolder] = useRecoilState(folderId);
  const [folders, setDatafolders] = useState([]);
  const[folderid,setFolderId] = useState("");
  const [drawingConsultant, setDrawingConsultant] = useState("")
  const [consultantRole, setConsultantRole] = useState('')
  const [consultantError, setConsutantError] = useState("")
  const [showHardCopy, setShowHardCopy]  = useState(false)
  const [showFolderTab, setShowFolderTab] = useState(false)
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [showAddFolder,setShowAddFolder ] = useState(false)
  const [hardCopyErrors, setHardCopyErrors] = useState({})
  const [selectedRows, setSelectedRows] = useState([]);
  const [inputErrors, setInputErrors] = useState({});
  const [towersAndFloors, setTowersAndFloors] = useState([])
  const [towersfloors, setTowerFloors]= useState('')
  const [companyKeyword,setCompanyKeyword] = useState("")
  const [projectId, setProjectId] = useState("")
  const handleTextFieldChange = (val, index, field) => {
    const today = todayDate();
    const updatedErrors = [...errors];
    const updatedFormData = formData.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [field]: val,
        };
      }
      return item;
    });

    // Initialize errors for the current index
    updatedErrors[index] = updatedErrors[index] || {};
    if (
      field === "drawingNumber" ||
      field === "drawingTitle" ||
      field === "consultant" ||
      field === "category"
    ) {
      if (!val.trim()) {
        updatedErrors[index][field] = `${field.replace(
          /([A-Z])/g,
          " $1"
        )} is required`;
      } else {
        updatedErrors[index][field] = "";
      }
    }

    if (field === "R0date" || field === "sitedate") {
      // Validate that dates are in the future
      if (val && val < today) {
        updatedErrors[index][field] = "Date must be in the future";
      } else {
        updatedErrors[index][field] = "";
      }

      // Check if R0date is less than sitedate
      const R0date = updatedFormData[index]?.R0date;
      const sitedate = updatedFormData[index]?.sitedate;

      if (R0date && sitedate) {
        if (new Date(R0date) >= new Date(sitedate)) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "R0 submission date must be less than site submission date",
            sitedate:
              "Site submission date must be greater than R0 submission date",
          };
        } else {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "",
            sitedate: "",
          };
        }
      }
    } else {
      // Clear error for other fields
      updatedErrors[index][field] = "";
    }

    setFormData(updatedFormData);
    setErrors(updatedErrors);
  };
  const handleHardCopyChange = (e, field) => {
    if (field === "roHardCopy") {
      setroHardCopy(e.target.value);
    } else {
      setsiteHeadHardCopy(e.target.value);
    }
  };

  const handleConsultantTowerChange = (e) => {
    const {value,name} = e.target
    if(name === "towersfloors"){
      setTowerFloors(value)
    }
    if(name === "drawingConsultant"){
      setDrawingConsultant(value)
    }

  }

  const handleChange = (e, index, field) => {
    const { value } = e.target;
    const today = todayDate();
    const updatedErrors = [...errors];
    const updatedFormData = formData.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });

    // Initialize errors for the current index
    updatedErrors[index] = updatedErrors[index] || {};
    if (
      field === "drawingNumber" ||
      field === "drawingTitle" ||
      field === "consultant" ||
      field === "category"
    ) {
      if (!value.trim()) {
        updatedErrors[index][field] = `${field.replace(
          /([A-Z])/g,
          " $1"
        )} is required`;
      } else {
        updatedErrors[index][field] = "";
      }
    }

    if (field === "R0date" || field === "sitedate") {
      // Validate that dates are in the future
      if (value && value < today) {
        updatedErrors[index][field] = "Date must be in the future";
      } else {
        updatedErrors[index][field] = "";
      }

      // Check if R0date is less than sitedate
      const R0date = updatedFormData[index]?.R0date;
      const sitedate = updatedFormData[index]?.sitedate;

      if (R0date && sitedate) {
        if (new Date(R0date) >= new Date(sitedate)) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "R0 submission date must be less than site submission date",
            sitedate:
              "Site submission date must be greater than R0 submission date",
          };
        } else {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "",
            sitedate: "",
          };
        }
      }
    } else {
      // Clear error for other fields
      updatedErrors[index][field] = "";
    }
    setFormData(updatedFormData);
    setErrors(updatedErrors);
  };

  // const handleChange = (e, index, field) => {
  //   const { value } = e.target;
  //   const today = todayDate();
  //   const updatedErrors = [...errors];
  //   const updatedFormData = formData.map((item, idx) => {
  //     if (idx === index) {
  //       return {
  //         ...item,
  //         [field]: value,
  //       };
  //     }
  //     return item;
  //   });

  //   // Initialize errors for the current index
  //   updatedErrors[index] = updatedErrors[index] || {};

  //   // Define the required format regex
  //   const fixedPattern = `^[A-Z0-9]{3}-[A-Z0-9]{3}-[A-Z0-9]{3}-GFC-T\\d+F\\d+-[A-Z0-9]{3}$`;
  //   const regex = new RegExp(fixedPattern, "i"); // Case insensitive

  //   if (field === "drawingNumber") {
  //     console.log(regex.test(value.toUpperCase()),"aaa")
  //     if (!value.trim()) {
  //       updatedErrors[index][field] = "Drawing Number is required";
  //     } else if (!regex.test(value.toUpperCase())) {
  //       updatedErrors[index][field] = "Invalid Drawing Number format";
  //     } else {
  //       updatedErrors[index][field] = "";
  //     }
  //   }

  //   if (
  //     field === "drawingTitle" ||
  //     field === "consultant" ||
  //     field === "category"
  //   ) {
  //     if (!value.trim()) {
  //       updatedErrors[index][field] = `${field.replace(/([A-Z])/g, " $1")} is required`;
  //     } else {
  //       updatedErrors[index][field] = "";
  //     }
  //   }

  //   if (field === "R0date" || field === "sitedate") {
  //     // Validate that dates are in the future
  //     if (value && value < today) {
  //       updatedErrors[index][field] = "Date must be in the future";
  //     } else {
  //       updatedErrors[index][field] = "";
  //     }

  //     // Check if R0date is less than sitedate
  //     const R0date = updatedFormData[index]?.R0date;
  //     const sitedate = updatedFormData[index]?.sitedate;

  //     if (R0date && sitedate) {
  //       if (new Date(R0date) >= new Date(sitedate)) {
  //         updatedErrors[index] = {
  //           ...updatedErrors[index],
  //           R0date: "R0 submission date must be less than site submission date",
  //           sitedate: "Site submission date must be greater than R0 submission date",
  //         };
  //       } else {
  //         updatedErrors[index] = {
  //           ...updatedErrors[index],
  //           R0date: "",
  //           sitedate: "",
  //         };
  //       }
  //     }
  //   } else {
  //     // Clear error for other fields
  //     updatedErrors[index][field] = "";
  //   }

  //   setFormData(updatedFormData);
  //   setErrors(updatedErrors);
  // };


  const handleNumDrawingChange = (e) => {
    const newNum = parseInt(e.target.value, 10);
    if (e.target.value === "") {
      setNumDrawings(newNum);
      setFormData([]);
      setErrors([]);
    } else {
      if (newNum > 100) {
        setNumDrawings(100);
        // if(companyKeyword && consultantRole && projectId && towersAndFloors){
          setFormData(Array.from({length:100},()=>({id: generateUniqueId(),drawingNumber:`${companyKeyword?companyKeyword+"-":""}${projectId?projectId+"-":""}${consultantRole?consultantRole+"-":""}GFC-${towersfloors?towersfloors+"-":""}`,R0date:"",drawingTitle:"",sitedate:"" })))
        // }
        //  else {
        //   setFormData(Array.from({ length: 100 }, () => ({ id: generateUniqueId(),drawingNumber:"",R0date:"",drawingTitle:"",sitedate:"" })))
        // }
      } else {
        setNumDrawings(newNum);
        setFormData(Array.from({ length: newNum }, () => ({ id: generateUniqueId(),drawingNumber:`${companyKeyword?companyKeyword+"-":""}${projectId?projectId+"-":""}${consultantRole?consultantRole+"-":""}GFC-${towersfloors?towersfloors+"-":""}`,R0date:"",drawingTitle:"",sitedate:"" })));
        setErrors(Array(newNum).fill({}));
      }
    }
  };
  const handleDisableBackspace = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault(); // Disable backspace key
    }
  };
  const [currentView, setCurrentView] = useRecoilState(addRegister);

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };
  const handleSubmit = async () => {
    let valid = true;
    console.log("omclicking");
    console.log(formData, roHardCopy, siteHeadHardCopy, "checking data");
    const newErrors = [];
    // const hardCopyError={}
    const tempErrors ={}
    
    setErrorMessage("");
    const today = new Date().toISOString().split("T")[0];
    // if (showAddFolder && !folder) {
    //   setErrorMessage("Please Select The Folder");
    //   return;
    // }
    // if(!drawingConsultant){
    //   setConsutantError("Please select drawing Consultant")
    //   valid=false
    //   return
    // }else {
    //   setConsutantError("")
    // }
    // if (!roHardCopy) {
    //   hardCopyError.roHardCopy = "Please enter number of copies";
    // } else if(roHardCopy && roHardCopy<4){
    //   hardCopyError.roHardCopy = "Please enter more than 3 copies";
    // }
    // if (!siteHeadHardCopy) {
    //   hardCopyError.siteHeadHardCopy = "Please enter number of copies";
    // } else if(siteHeadHardCopy && siteHeadHardCopy<4){
    //   hardCopyError.siteHeadHardCopy = "Please enter more than 3 copies";
    // }
    // if(Object.keys(hardCopyError).length){
    //   setHardCopyErrors(hardCopyError)
    //   valid=false
    //   return
    // } else{
    //   setHardCopyErrors(hardCopyError)
    // }
    if (!drawingConsultant) {
      tempErrors.consultantError = "Please select drawing Consultant";
      valid = false;
    }
    if(!towersfloors){
      tempErrors.towersfloors = "Please select towers and floor"
    }
  
    // Hard Copy validation
    if(showHardCopy){
      if (!roHardCopy) {
        tempErrors.roHardCopy = "Please enter number of copies";
        valid = false;
      } else if (roHardCopy < 4) {
        tempErrors.roHardCopy = "Please enter more than 3 copies";
        valid = false;
      }
    
      if (!siteHeadHardCopy) {
        tempErrors.siteHeadHardCopy = "Please enter number of copies";
        valid = false;
      } else if (siteHeadHardCopy < 4) {
        tempErrors.siteHeadHardCopy = "Please enter more than 3 copies";
        valid = false;
      }
    }
    
    setInputErrors(tempErrors);
    if(Object.keys(tempErrors).length){
      return 
    }
    const fixedPattern = /^[A-Z0-9]{3}-[A-Z0-9]{3}-[A-Z0-9]{3}-GFC-T\d+F\d+-[A-Z0-9]{3}$/i;
    formData.forEach((drawing, index) => {
      const drawingErrors = {};
      if (!drawing.drawingNumber?.trim()) {
        drawingErrors.drawingNumber = "Drawing Number is required";
        valid = false;
      } else if (!fixedPattern.test(drawing.drawingNumber.toUpperCase())) {
        drawingErrors.drawingNumber = "Invalid Drawing Number format";
        valid = false;
      }
      if (!drawing.drawingTitle?.trim()) {
        drawingErrors.drawingTitle = "Drawing Title is required";
        valid = false;
      }
      // if (!drawing.consultant?.trim()) {
      //   drawingErrors.consultant = "Consultant is required";
      //   valid = false;
      // }
      if (!drawing.category?.trim()) {
        drawingErrors.category = "Category is required";
        valid = false;
      }
      if (!drawing.R0date) {
        drawingErrors.R0date = "R0 Date is required";
        valid = false;
      } else if (drawing.R0date < today) {
        drawingErrors.R0date = "R0 Date must be in the future";
        valid = false;
      }

      if (!drawing.sitedate) {
        drawingErrors.sitedate = "Site Date is required";
        valid = false;
      } else if (drawing.sitedate < today) {
        drawingErrors.sitedate = "Site Date must be in the future";
        valid = false;
      }

      if (
        drawing.R0date &&
        drawing.sitedate &&
        new Date(drawing.R0date) >= new Date(drawing.sitedate)
      ) {
        drawingErrors.R0date =
          "R0 submission date must be less than site submission date";
        drawingErrors.sitedate =
          "Site submission date must be greater than R0 submission date";
        valid = false;
      }

      newErrors[index] = drawingErrors;
    });

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });

    const tempFormData = {
      siteId: site,
      drawings: formData.map((drawing) => ({
      ...(showAddFolder && {folderId: folder}) ,
        noOfRoHardCopyRevisions: roHardCopy,
        noOfSiteHeadHardCopyRevisions: siteHeadHardCopy,
        drawingNo: drawing.drawingNumber,
        drawingTitle: drawing.drawingTitle,
        designDrawingConsultant: drawingConsultant,
        category: drawing.category,
        acceptedROSubmissionDate: drawing.R0date,
        acceptedSiteSubmissionDate: drawing.sitedate,
      })),
    };
    console.log(tempFormData, "tempFormData");

    try {
      const response = await webApi.post(
        "api/architectureToRoRegister/create",
        tempFormData
      );
      if (response.status === 201) {
        setApiProcessing({ loader: false, message: "" });
        setNumDrawings(0);
        setFormData([]);
        setErrors([]);
        setroHardCopy("");
        setsiteHeadHardCopy("");
        window.location.reload();
      } else if (response.status === 400 || response.status === 200) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        }

        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        return;
      }
    }
  };

  const fetchConsultant = async() =>{
    try {
      const response = await webApi.get(`api/designDrawingConsultant/getAll?siteId=${site}`)
      if(response.data.data.users){
        setExistingConsultants(response.data.data.users);
        const categories = response.data.data.users.find((user) =>user._id === drawingConsultant)
          console.log(categories?.categories,"categories")
          setFilteredCategories(categories?.categories);
      }
      
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    fetchConsultant()
  }, [site,drawingConsultant]);

  const handleConsultantSelect = (consultantId, index) => {
    const updatedFormData = [...formData];

    // Update consultant for the specific row
    updatedFormData[index] = {
      ...updatedFormData[index],
      consultant: consultantId,
      category: "", // Reset category when consultant is changed
    };
    setFormData(updatedFormData);

    // Find the selected consultant's categories
    const consultant = existingConsultants.find((c) => c._id === consultantId);
    const categoriesForConsultant = consultant ? consultant.categories : [];

    // Update filtered categories only for the specific row
    const updatedFilteredCategories = [...filteredCategories];
    updatedFilteredCategories[index] = categoriesForConsultant;
    setFilteredCategories(updatedFilteredCategories);
  };

  const handleNumDrawingIncrease = () => {
    setNumDrawings((prevNum) => {
      if (prevNum < 100) {
        return prevNum + 1;
      }
      return prevNum;
    });
  
    setFormData((prevFormData) => [
      ...prevFormData,
      { id: generateUniqueId(),drawingNumber:`${companyKeyword?companyKeyword+"-":""}${projectId?projectId+"-":""}${consultantRole?consultantRole+"-":""}GFC-${towersfloors?towersfloors+"-":""}`,R0date:"",drawingTitle:"",sitedate:""  },
    ]);
  };
  

  const handleClearAll = () => {
    setNumDrawings(0);
    setFormData([])
    setErrors("");
  };

  const handleDeleteRow = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
    setNumDrawings(updatedFormData.length);
  };

  const numColumns = 6;
  const handleKeyDown = (e, index, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputs = Array.from(document.querySelectorAll("input"));
      const currentIndex = inputs.indexOf(e.target);
      const nextInput =
        inputs[currentIndex + 1] ||
        document.querySelector('button[type="submit"]');
      if (nextInput) nextInput.focus();
    }

    if (
      ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)
    ) {
      return;
    }
  };

  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  };
  const handleFolderChange = async (e) => {
    setFolder(e.target.value);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  };

  const fetchAllSitesDetails = async() =>{
    try {
      const response = await  webApi.get("api/sites/getAllSitesInfo")
      console.log(response.data.sites,"res")
        if (response.data && Array.isArray(response.data.sites)) {
          const filtSite = response.data.sites.find((siteDetails)=>siteDetails._id === site)
          console.log(filtSite,"filtSite")
          setCompanyKeyword(filtSite.companyKeyWord)
          setProjectId(filtSite.projectId)
          const towerList = []
          if(filtSite?.ventureType === "Building or Office"){
            filtSite?.buildingsDetails?.towers?.floors.forEach((item,index)=>{
              towerList.push(`T1F${index+1}`)
            })
            console.log(towerList,"towerList")
            setTowersAndFloors(towerList)
          }
          else if(filtSite?.ventureType === "Apartments"){
            filtSite?.apartmentsDetails?.towers.forEach((tow,index)=>{
              tow.floors.forEach((flo,floorIndex)=>{
                towerList.push(`T${index+1}F${floorIndex+1}`)
              })
            })
            setTowersAndFloors(towerList)
          }
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }
  useEffect(() => {
      fetchAllSitesDetails()
  }, []);
  useEffect(() => {
    webApi
      .get(`api/drawingFolder/bySiteId?siteId=${site}`)
      .then((data) => {
        if (data.data.data.folders && Array.isArray(data.data.data.folders)) {
          setDatafolders(data.data.data.folders);
        } else {
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  useEffect(() => {}, [site, folder]);

  const [currentUserInfo] = useRecoilState(userInfo);
  
  useEffect(()=>{
    let isAddFolderTrue = false
      currentUserInfo?.permittedSites.forEach((item)=>{
        if(item?.enableModules?.drawingAddFolder){
           isAddFolderTrue = true 
        }})
    setShowAddFolder(isAddFolderTrue)
  },[])


  useEffect(()=>{console.log(formData,"formDatass")},[formData])

  const generateUniqueId = () => `id-${Date.now()}-${Math.random()}`;
  useEffect(()=>{
},[selectedRows])
const handleMoveOpen = () => {
    setMoveDialogOpen(true);
  };

const handleMoveClose = () => {
  setMoveDialogOpen(false);
};

useEffect(() => {
 
    webApi.get(`api/drawingFolder/bySiteId?siteId=${site}`).then((data) => {
      if (data) {
        setExistingFolders(data.data.data.folders);
        console.log()
      }
    });
  },[site]);
  const handleFolderSelect = (folderId) => {
    setFolderId(folderId); 
  };
  ;
  const handleFolderSubmit = async () => {
    let valid = true;
    const newErrors = [];
    const hardCopyError={}
  
    // if (!folderid) {
    //   newErrors.folderid = "Please Select Folder Name";
    // }
    if (!valid) {
      setErrors(newErrors);
      return;
    }

    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });

    const tempFormData = {
    ids: selectedRows
    };
    console.log(tempFormData, "tempFormData");
    console.log(folderid, "tempFormData folderid");

    try {
      const response = await webApi.post(
        `api/architectureToRoRegister/updateFolderId?folderId=${folderid}`,
        tempFormData
      );
      if (response.status === 201) {
        setApiProcessing({ loader: false, message: "" });
        setFolderId("");
        handleMoveClose();
        window.location.reload();
      } else if (response.status === 400 || response.status === 200) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        }

        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        return;
      }
    }
  };
  useEffect(()=>{
    console.log(towersAndFloors,"towersAndFloors")
  },[towersAndFloors])

  useEffect(()=>{
    if(drawingConsultant){
      const findConsutant = existingConsultants.find((item)=>item._id === drawingConsultant)
      setConsultantRole(findConsutant?.role)
    }
  },[drawingConsultant])

  useEffect(()=>{
    if(formData.length){
      console.log(formData,"formDataaaa")
      setFormData((prev)=>prev.map((item)=>({...item,drawingNumber:`${companyKeyword?companyKeyword+"-":""}${projectId?projectId+"-":""}${consultantRole?consultantRole+"-":""}GFC-${towersfloors?towersfloors+"-":""}`})))
    }
  },[companyKeyword,consultantRole,projectId,towersfloors])

  const fetchNoOfDays = async() =>{
       try {
         const response = await webApi.get("api/rfiTimeStamp/byCompanyId")
         console.log(response.data.data.customizedView ,"response in the rfi timeStamp")
          if (response.status === 200) {
           const recievedHardCopies = response?.data?.data?.areYouReceivingHardCopiesFromAllConsultants       
           setShowHardCopy(recievedHardCopies)
           setShowFolderTab(response?.data?.data?.isDrawingAddFolder)
           setCurrentView(response?.data?.data?.isDrawingAddFolder === "Yes"?"addfolder":"addregister")
           console.log(recievedHardCopies,"recievedHardCopies")
          } 
       } catch (error) {
         console.log(error.message)
       }
     }
     
     useEffect(()=>{
       fetchNoOfDays()
     },[])

  useEffect(()=>{console.log(filteredCategories,"filteredCategories")},[filteredCategories])
 

  return (
    <>
      <TableWrapper
        children={ 
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                
                <Tabs value={currentView} onChange={handleTabViewChange}>
                  
                  {showFolderTab === "Yes" && <Tab label="Add Folder" value="addfolder" />}
                  <Tab label="Add Register" value="addregister" />
                  <Tab label="Drawing Registers" value="drawingregisters" />
                </Tabs>
              </div>

              {/* <div style={{ display: "flex" }}>
              {currentView === "drawingregisters" && showAddFolder && (
                  <button style={{
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    fontSize: "16px",
                    padding: "5px 15px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={handleMoveOpen}
                  >Move</button>
                 
                )}
                {currentView != "addfolder"&& showAddFolder && (
                  <FolderDropdown
                    folderId={folder}
                    handleFolderChange={handleFolderChange}
                    folders={folders}
                    defaultValue={"Select Folder"}
                  />
                )}
               
              </div> */}
             
            </div>
            {currentView === "addfolder" && (
              <>
                <AddCategory filterCondition="addregister" />
              </>
            )}
            {currentView === "addregister" && (
              <>
                <div style={{display:"flex",width:"100%",flexDirection:"column",gap:"20px",marginTop:"20px"}}>
                  <div style={{display:"flex",width:"100%",justifyContent:showHardCopy?"space-between":undefined,alignItems:"center",marginBottom:"20px",gap:"10px"}}>
                    <div style={{width:"23%",display:"flex",position:"relative"}} >
                      <select name="drawingConsultant" value={drawingConsultant}  style={{width:"100%", paddingLeft: "15px",
                            borderRadius: "6px",
                            border: "1px solid var(--primary-color) !important",height:"40px",color:"#ACACAC"}} 
                            onChange={handleConsultantTowerChange}
                            >
                        <option value="">Select Consultant</option>
                        {existingConsultants.map((consultant)=>(
                          <option value={consultant?._id}>{consultant?.role}</option>
                        ))}
                      </select>
                      {inputErrors.consultantError && <p style={{color:"red",position:"absolute",top:"100%"}}>{inputErrors.consultantError}</p>}
                    </div>
                    <div style={{width:"23%",display:"flex",position:"relative"}} >
                      <select name="towersfloors" value={towersfloors}  style={{width:"100%", paddingLeft: "15px",
                            borderRadius: "6px",
                            border: "1px solid var(--primary-color) !important",height:"40px",color:"#ACACAC"}} onChange={(e)=>handleConsultantTowerChange(e)}>
                        <option value="">Select Towers & floors</option>
                        {towersAndFloors.map((item)=>(
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                      {inputErrors.towersfloors && <p style={{color:"red",position:"absolute",top:"100%"}}>{inputErrors.towersfloors}</p>}
                    </div>
                    {showHardCopy && <div style={{width:"23%",display:"flex",position:"relative"}} >
                    <input
                          placeholder="No Of Hard Copies RO"
                          type="number"
                          min="0"
                          onChange={(e) => handleHardCopyChange(e, "roHardCopy")}
                          value={roHardCopy}
                          style={{
                            paddingLeft: "15px",
                            borderRadius: "6px",
                            border: "1px solid var(--primary-color) !important",
                            width: "100%",
                            height: "40px",
                          }}
                        />
                        {inputErrors?.roHardCopy && (
                          <div style={{ color: "red" ,position:"absolute",top:"100%" }}>
                            {inputErrors.roHardCopy}
                          </div>
                        )}
                    </div>}
                    {showHardCopy && <div style={{width:"23%",display:"flex",position:"relative"}} >
                    <input
                          placeholder="No Of Hard Copies SiteHead"
                          type="number"
                          min="0"
                          onChange={(e) =>
                            handleHardCopyChange(e, "", "siteHeadHardCopy")
                          }
                          value={siteHeadHardCopy}
                          style={{
                            paddingLeft: "15px",
                            borderRadius: "6px",
                            border: "1px solid var(--primary-color) !important",
                            width: "100%",
                            height: "40px",
                          }}
                        />
                      {inputErrors?.siteHeadHardCopy && (
                          <div style={{ color: "red",position:"absolute",top:"100%"}}>
                            {inputErrors.siteHeadHardCopy}
                          </div>
                      )}
                    </div>}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1rem",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" ,width:"100%"}}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "10px",
                          width:"23%"
                        }}
                      >
                        <input
                          placeholder="No. of Drawings do you want to add"
                          type="number"
                          value={numDrawings > 0 ? numDrawings : ""}
                          onChange={(e)=>{ 
                            handleNumDrawingChange(e)
                          }}
                          onKeyDown={handleDisableBackspace}
                          min="0"
                          style={{
                            paddingLeft: "15px",
                            borderRadius: "6px",
                            border: "1px solid var(--primary-color) !important",
                            width: "100%",
                            height: "40px",
                          }}
                        />
                        {errors && (
                          <p
                            style={{
                              color: "gray",
                              fontSize: "12px",
                              marginTop: "5px",
                            }}
                          >
                            Note:- You can add a maximum of 100 drawings only.
                          </p>
                        )}
                      </div>
                      <div style={{ position: "relative", marginTop: "10px" }}>
                        {" "}
                        <Button
                          style={{
                            height: "40px",
                            marginLeft: "10px",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "var(--primary-color)",
                            border: "1px solid var(--primary-color)",
                          }}
                          onClick={handleNumDrawingIncrease}
                        >
                          <Icon.PlusCircle
                            size={20}
                            style={{
                              marginRight: "5px",
                              color: "var(--primary-color)",
                              backgroundColor: "white",
                              borderRadius: "50%",
                              padding: "1px",
                            }}
                          />
                          Add
                        </Button>
                      </div>
                      <p
                        style={{
                          color: "4D4D4D",
                          textDecoration: "underline",
                          fontSize: "14px",
                          marginTop: "18px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={handleClearAll}
                      >
                        Clear all
                      </p>
                      {/* <div style={{ marginLeft: "15px", fontSize: "10px",position:"relative",width:"380px" }}>
                        <label style={{textAlign:"left",width:"100%",fontSize:'14px'}}>No Of Hard Copies For RO</label>
                        <input
                          placeholder="No Of Hard Copies RO"
                          type="number"
                          min="0"
                          onChange={(e) => handleHardCopyChange(e, "roHardCopy")}
                          value={roHardCopy}
                          style={{
                            paddingLeft: "15px",
                            borderRadius: "6px",
                            border: "1px solid var(--primary-color) !important",
                            width: "100%",
                            height: "40px",
                          }}
                        />
                        {hardCopyErrors?.roHardCopy && (
                          <div style={{ color: "red", fontSize: "0.8rem" ,position:"absolute",left:"0" }}>
                            {hardCopyErrors.roHardCopy}
                          </div>
                        )}
                      </div>
                      <div style={{ marginLeft: "15px", fontSize: "10px" ,position:"relative",width:"380px"}}>
                        <label style={{textAlign:"left",width:"100%",fontSize:'14px'}}>No Of Hard Copies For Sitehead</label>
                        <input
                          placeholder="No Of Hard Copies SiteHead"
                          type="number"
                          min="0"
                          onChange={(e) =>
                            handleHardCopyChange(e, "", "siteHeadHardCopy")
                          }
                          value={siteHeadHardCopy}
                          style={{
                            paddingLeft: "15px",
                            borderRadius: "6px",
                            border: "1px solid var(--primary-color) !important",
                            width: "100%",
                            height: "40px",
                          }}
                        />
                        {hardCopyErrors?.siteHeadHardCopy && (
                          <div style={{ color: "red", fontSize: "0.8rem" ,position:"absolute",left:"0"}}>
                            {hardCopyErrors.siteHeadHardCopy}
                          </div>
                        )}
                      </div> */}
                    </div>
                  </div>
                  {/* {errorMessage && (
                    <div style={{ color: "red", fontSize: "15px" }}>
                      {errorMessage}
                    </div>
                  )} */}
                  <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
                    {formData.length > 0 && (
                      <table
                        style={{
                          border: "1px solid #DCDCDC",
                          borderCollapse: "collapse",
                          width: "100%",
                        }}
                      >
                        <thead
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          <tr>
                            <th>S.No</th>
                            <th>Drawing Number</th>
                            {/* <th>Design Consultant</th> */}
                            <th>Drawing Category</th>
                            <th>Drawing Title</th>
                            <th>Accepted RO Submission Date</th>
                            <th>Accepted Site Submission Date</th>
                            <th style={{ padding: "5px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody style={{ marginTop: "10px" }}>
                          {formData.map((item, index) => (
                            <tr key={item.id} style={{ width: "100%" }}>
                              <td>
                                <p
                                  className="form-control ARFC"
                                  style={{
                                    width: "90%",
                                    marginLeft: "5px",
                                    height: "45px",
                                    // marginTop:'16px',
                                    backgroundColor: "var(--table-evenrow-color)",
                                    padding: "10px",
                                  }}
                                >
                                  {index + 1}
                                </p>
                              </td>
                              <td>
                              <input
                                type="text"
                                className="form-control ARFC"
                                name="drawingNumber"
                                value={formData[index]?.drawingNumber || ""}
                                placeholder="Enter The Drawing"
                                style={{
                                  width: "95%",
                                  marginLeft: "5px",
                                  textTransform: "uppercase",
                                  backgroundColor: "var(--table-oddrow-color)",
                                }}
                                onChange={(e) => {
                                  let drawingNumber = e.target.value.toUpperCase();
                          
                                  if (regex.test(drawingNumber) || drawingNumber.length < (formData[index]?.drawingNumber?.length || 0)) {
                                    handleChange(e, index, "drawingNumber");
                                  }
                                }}
                                onKeyDown={(e) => {
                                  const drawingNumber = formData[index]?.drawingNumber || "";
                                  const isFormatValid = new RegExp(fixedPattern, "i").test(drawingNumber);
                          
                                  // Prevent Backspace if the predefined structure is intact
                                  if (e.key === "Backspace" && isFormatValid) {
                                    const userInput = drawingNumber.replace(new RegExp(fixedPattern, "i"), ""); // Extract user part
                                    if (userInput.length === 0) {
                                      e.preventDefault(); // Stop deletion beyond structure
                                    }
                                  }
                          
                                  // Restrict user input to 3 characters after "T1F1-"
                                  const userEnteredValues = drawingNumber.replace(new RegExp(fixedPattern, "i"), "").split("-");
                                  if (userEnteredValues[0]?.length >= 3 && e.key !== "Backspace") {
                                    e.preventDefault();
                                  }
                                }}
                                
                                tabIndex={index * numColumns + 1}
                              />


                                {errors[index]?.drawingNumber && (
                                  <div
                                    style={{ color: "red", fontSize: "0.8rem" }}
                                  >
                                    {errors[index].drawingNumber}
                                  </div>
                                )}
                              </td>

                              {/* <td
                                style={{
                                  padding: "5px",
                                }}
                              >
                                <SearchDropdownIdVal
                                  placeholder={"ENTER CONSULTANT"}
                                  backgroundColor={"var(--table-evenrow-color)"}
                                  options={
                                    (existingConsultants &&
                                      existingConsultants?.map((consultant) => ({
                                        name: consultant.role,
                                        value: consultant._id,
                                      }))) ||
                                    []
                                  }
                                  onOptionSelect={(option) =>
                                    handleConsultantSelect(option.value, index)
                                  }
                                  onKeyDown={(e) =>
                                    handleKeyDown(e, index, "consultant")
                                  }
                                  tabIndex={index * numColumns + 2}
                                />
                                {errors[index]?.consultant && (
                                  <div
                                    style={{ color: "red", fontSize: "0.8rem" }}
                                  >
                                    {errors[index].consultant}
                                  </div>
                                )}
                              </td> */}
 
                              <td>
                                <SearchDropdownIdVal
                                  placeholder={"ENTER CATEGORY"}
                                  backgroundColor={"var(--table-oddrow-color)"}
                                  options={
                                    filteredCategories &&
                                    Array.isArray(filteredCategories)
                                      ? filteredCategories.map((item) => {
                                          console.log("Item:", item); 
                                          return {
                                            name: item.category,
                                            value: item._id,
                                          };
                                        })
                                      : []
                                  }
                                  onOptionSelect={(option) => {
                                    handleTextFieldChange(
                                      option.value,
                                      index,
                                      "category"
                                    );
                                  }}
                                  onKeyDown={(e) =>
                                    handleKeyDown(e, index, "category")
                                  }
                                  tabIndex={index * numColumns + 3}
                                />

                                {errors[index]?.category && (
                                  <div
                                    style={{ color: "red", fontSize: "0.8rem" }}
                                  >
                                    {errors[index].category}
                                  </div>
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter The Drawing Title"
                                  style={{
                                    width: "95%",
                                    marginLeft: "5px",
                                    backgroundColor: "var(--table-evenrow-color)",
                                  }}
                                  onChange={(e) =>
                                    handleChange(e, index, "drawingTitle")
                                  }
                                  onKeyDown={(e) =>
                                    handleKeyDown(e, index, "drawingTitle")
                                  }
                                  tabIndex={index * numColumns + 4}
                                />
                                {errors[index]?.drawingTitle && (
                                  <div
                                    style={{ color: "red", fontSize: "0.8rem" }}
                                  >
                                    {errors[index].drawingTitle}
                                  </div>
                                )}
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="form-control ARFC"
                                  max={maxYear}
                                  min={todayDateString}
                                  style={{
                                    width: "95%",
                                    marginLeft: "5px",
                                    backgroundColor: "var(--table-oddrow-color)",
                                    padding: "8px",
                                  }}
                                  onChange={(e) =>
                                    handleChange(e, index, "R0date")
                                  }
                                  onKeyDown={(e) =>
                                    handleKeyDown(e, index, "R0date")
                                  }
                                  tabIndex={index * numColumns + 5}
                                />
                                {errors[index]?.R0date && (
                                  <div
                                    style={{ color: "red", fontSize: "0.8rem" }}
                                  >
                                    {errors[index].R0date}
                                  </div>
                                )}
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="form-control ARFC"
                                  max={maxYear}
                                  min={todayDateString}
                                  style={{
                                    width: "95%",
                                    marginLeft: "5px",
                                    backgroundColor: "var(--table-evenrow-color)",
                                    padding: "8px",
                                  }}
                                  onChange={(e) =>
                                    handleChange(e, index, "sitedate")
                                  }
                                  onKeyDown={(e) =>
                                    handleKeyDown(e, index, "sitedate")
                                  }
                                  tabIndex={index * numColumns + 6}
                                />
                                {errors[index]?.sitedate && (
                                  <div
                                    style={{ color: "red", fontSize: "0.8rem" }}
                                  >
                                    {errors[index].sitedate}
                                  </div>
                                )}
                              </td>
                              <td
                                className="form-control ARFC"
                                style={{
                                  width: "90%",
                                  backgroundColor: "var(--table-oddrow-color)",
                                  marginTop: "5px",
                                  padding: "8px",
                                  marginLeft: "3px",
                                }}
                              >
                                <BsDashCircleFill
                                  size={30}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                    padding: "5px",
                                  }}
                                  onClick={() => handleDeleteRow(index)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    <div style={{ marginTop: "8px", textAlign: "center" }}>
                      {numDrawings > 0 && (
                        <button
                          style={{
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid  var(--primary-color)",
                            backgroundColor: " var(--primary-color)",
                            color: "white",
                            width: "100px",
                          }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
              
            )}
            {currentView === "drawingregisters" && (
              <RegisterPage filter="AddRegister" selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
            )}
          </>
        }
      />
 <Dialog
        open={moveDialogOpen}
        onClose={handleMoveClose}
        maxWidth="sm"
        PaperProps={{ style: { fontFamily: "Poppins" } }}
      >
        <div
          style={{
            position:'relative',
            display: "flex",
            alignItems: "center",
            justifyContent:'center',
            backgroundColor: " #4D4D4D",
            height: "50px",
            width:'100%'
          }}
        >
          <p
            style={{
              color: "white",
              marginTop:'10px',
            }}

          >
            Move Folder
          </p>
          <RxCross1
            size={20}
            style={{
              position:'absolute',
              cursor: "pointer",
              color: "white",
              right:'10px'
            }}
            onClick={handleMoveClose}
          />
        </div>
        
      <DialogContent style={{ padding: "25px", minWidth: "250px", minHeight: "250px" }}>
        <>
        <label>Folder Name</label>
        <SearchDropdownIdVal
                                placeholder={"ENTER Folder"}
                                backgroundColor={"var(--table-evenrow-color)"}
                                options={
                                  (existingFolders &&
                                    existingFolders?.map((folder) => ({
                                      name: folder.folderName,
                                      value: folder._id,
                                    }))) ||
                                  []
                                }
                                onOptionSelect={(option) =>
                                  handleFolderSelect(option.value)
                                }
                              />
 {errors?.folderid && (
                                <div
                                  style={{ color: "red", fontSize: "0.8rem" }}
                                >
                                  {errors.folderid}
                                </div>
                              )}
                               <div style={{ marginTop: "20px", textAlign: "center" }}>
                   
                      <button
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid  var(--primary-color)",
                          backgroundColor: " var(--primary-color)",
                          color: "white",
                          width: "100px",
                        }}
                        onClick={handleFolderSubmit}
                      >
                        Submit
                      </button>
                  </div>
        </>
      </DialogContent>
      </Dialog>
    </>
  );
};

export default AddRegister;
