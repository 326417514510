import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "recoil-persist", // this key is using to store data in local storage
  storage: sessionStorage, // configurate which stroage will be used to store the data
});

export const userInfo = atom({
  key: "userInfo",
  default: {
    firstName: "",
    lastName: "",
    contactNumber: "",
    ID: "",
    role: "",
    department: "",
    permittedSites: [],
  },
  effects_UNSTABLE: [persistAtom],
});

// Setter function to update userInfo atom
export const setUserInfoData = (newUserData) => {
  userInfo.set(newUserData);
};
export const allUsersInformations = atom({
  key: "allUsersInformations",
  default: []
})

export const setAllUserDetails = (allDetails) =>{
  allUsersInformations.set(allDetails)
}

export const userId = atom({
  key: "userId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
// Selector for isAuthenticated
export const isAuthenticated = selector({
  key: "isAuthenticated",
  get: ({ get }) => {
    const userData = get(userInfo);
    return Boolean(userData && userData.ID);
  },
});

const jwtToken = atom({
  key: "jwtToken",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
const idToken = atom({
  key: "idToken",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export { jwtToken, idToken };

export const refreshToken = atom({
  key: "refreshToken",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const checkList = atom({
  key: "checkList",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const drawing = atom({
  key: "drawing",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const addRegister = atom({
  key: "addRegister",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

//siteId
export const siteId = atom({
  key: "siteId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
export const folderId = atom({
  key: "folderId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
export const siteName = atom({
  key: "",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const registerAtom = atom({
  key: "registerAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
export const drawingTab = atom({
  key: "drawingTab",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const architectureTab = atom({
  key: "architectureTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const roTab = atom({
  key: "roTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const atororegisterTab = atom({
  key: "atororegisterTab",
  default: "drawingregister",
  effects_UNSTABLE: [persistAtom],
});
export const drawingoptionsTab = atom({
  key: "drawingoptionsTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const architectDrawing = atom({
  key: "architectDrawing",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const architectPending = atom({
  key: "architectPending",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const roDrawing = atom({
  key: "roDrawing",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const roPending = atom({
  key: "roPending",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const siteHeadDrawing = atom({
  key: "siteHeadDrawing",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const siteHeadPending = atom({
  key: "siteHeadPending",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const ChecklistTab = atom({
  key: "checklistTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const designTab = atom({
  key: "designTab",
  default:"designform" ,
  effects_UNSTABLE: [persistAtom],
});

export const siteTab = atom({
  key: "siteTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const spaceTab = atom({
  key: "spaceTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const planner = atom({
  key: "planner",
  default: {
    workOrderId: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const pnm = atom({
  key: "pnm",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const dailyLogReportTab = atom({
  key: "dailyLogReportTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const breakdownReportTab = atom({
  key: "breakdownReportTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const miscellaneousTab = atom({
  key: "miscellaneousTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const reportsTab = atom({
  key: "reportsTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const optionsTab = atom({
  key: "optionsTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const workflowTab = atom({
  key: "workflowTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const profileImageUrl = atom({
  key: "profileImageUrl",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const TotalEnableModulesCount = atom({
  key: "TotalEnableModulesCount",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const isRoForwardAccessTrue = atom({
  key: "isForwardAccessTrue",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const isRoRfiRaisedAccess = atom({
  key: "rfiRaisedAccess",
  default: false,
  effects_UNSTABLE: [persistAtom],
});





// export const users = atom({
//   key: "prePayInfo",
//   default: {
//     rentalBalance:"",
//     leaseEndDate: "",
//     monthlyRent:"",
//   },
//   effects_UNSTABLE: [persistAtom],
// });

// export const docFolderName = atom({
//   key: "docFolderName",
//   default: "",
//   effects_UNSTABLE: [persistAtom],
// });

// export const conAcc = atom({
//   key: "conAcc",
//   default: false,
//   effects_UNSTABLE: [persistAtom],
// });
