/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { FaSearch, FaEdit } from "react-icons/fa";
import "./users.css";
import { WebApimanager } from "../../WebApiManager";
import ToggleSwitch from "../Sites/ToggleSwitch";
import ProfileModal from "./ProfileUpdate/ProfileModal";
import ToggleSwitchWithModules from "../Sites/ToggleSwitchWithModules";
import { LuPlusCircle } from "react-icons/lu";
import AddUserModal from "./AddUserModal";

const Profile = () => {
  const webApi = new WebApimanager();
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const moduleLabelsTop = {
    pAndM: "P & M",
    qaAndQc: "QA & QC",
    ehs: "EHS",
    qs: "QS",
    planner: "PLANNER",
    hr: "HR",
    store: "STORE",
    admin: "ADMIN",
    site: "SITE",
    dashBoard: "DASHBOARD",
    company: "COMPANY",
    task: "TASK",
    workStatus: "WORKSTATUS",
  };
  const moduleLabelsBottom = {
    drawings: "Drawing",
    user: "USER",
    space: "SPACE",
    checkList: "CHECKLIST",
    communication: "COMMUNICATION",
  };

  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [formData, setFormData] = useState({
    drawings: false,
    pAndM: false,
    qaAndQc: false,
    ehs: false,
    qs: false,
    planner: false,
    hr: false,
    user: false,
    store: false,
    admin: false,
    space: false,
    site: false,
    checkList: false,
    communication: false,
    dashBoard: false,
    company: false,
    task: false,
    workStatus: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [sites, setSites] = useState([]);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const responce = await webApi.get("api/users");

      if (responce.data && responce.data.data && responce.data.data.allUsers) {
        setUsers(responce.data.data.allUsers);
      } else {
        console.log("No users found in response");
      }
    } catch (error) {
      console.error("Error fetching users data", error);
      setError("fail to fetch data for users");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleAddUser = () => {
    setShowAddUserModal(true);
    console.log("sdfv sdhfgvsjdcbhjsbch");
  };

  const handleCloseUserModal = () => {
    setShowAddUserModal(false);
  };

  const fetchUserDetails = async (userId) => {
    try {
      const response = await webApi.get(`api/users/enableModules/${userId}`);
      console.log("Selected response:", response);

      if (response.data && response.data.data) {
        console.log("Full response data:", response.data.data);
        fetchUserData();

        const { newUser, enabledModules } = response.data.data;

        const user = {
          _id: newUser._id,
        };
        const siteData = Object.entries(enabledModules).map(
          ([siteId, site]) => ({
            siteId: siteId,
            siteName: site.siteName,
            modules: site.modules,
          })
        );
        setSites(siteData);

        if (siteData.length > 0) {
          setSelectedSiteId(siteData[0].siteId);
          setSelectedUser({
            ...user,
            siteName: siteData[0].siteName,
            modules: siteData[0].modules,
          });
        }
      } else {
        console.log("No data available in the response");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleSiteIdChange = (event) => {
    const siteId = event.target.value;
    const selectedSite = sites.find((site) => site.siteId === siteId);
    setSelectedSiteId(siteId);
    setSelectedUser({
      siteName: selectedSite ? selectedSite.siteName : "N/A",
      modules: selectedSite ? selectedSite.modules : {},
    });
  };

  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (searchQuery.trim().length > 0) {
      const results = users.filter((user) => {
        const firstName = user.firstName?.toLowerCase() || "";
        const lastName = user.lastName?.toLowerCase() || "";
        const empId = user.empId?.toLowerCase() || "";

        return (
          firstName.includes(searchQuery.toLowerCase()) ||
          empId.includes(searchQuery.toLowerCase()) ||
          lastName.includes(searchQuery.toLowerCase())
        );
      });

      setSearchSuggestions(results);
      setShowDropdown(true);
      console.log("dropdown show", showDropdown);
      console.log("Search Suggestions:", results);
    } else {
      setSearchSuggestions([]);
      setShowDropdown(false);
    }
  }, [searchQuery, users]);

  const handleDropdownItemClick = (user) => {
    console.log("Selected User ID:", user._id);
    setSelectedUser("");
    setUserInfo(user);
    setShowDropdown(false);
    fetchUserDetails(user._id);
    setSearchQuery("");
    console.log("User Info:", user);
  };

  const handleToggle = (key) => {
    setSelectedUser((prevData) => {
      const updatedModules = {
        ...prevData.modules,
        [key]: !prevData.modules[key],
      };

      return {
        ...prevData,

        modules: updatedModules,
      };
    });
  };

  useEffect(() => {
    const updateUserModules = async () => {
      if (selectedUser && userInfo) {
        try {
          console.log("Updating user with data:", {
            userId: userInfo._id,
            siteId: selectedSiteId,
            modules: selectedUser.modules,
          });
          console.log("Updating user with data:", {
            userId: userInfo._id,
            siteId: selectedSiteId,
            modules: selectedUser.modules,
          });

          const response = await webApi.put(
            `api/users/updateDetails/${userInfo._id}`,
            {
              siteId: selectedSiteId,
              modules: selectedUser.modules,
            }
          );

          if (response.status === 200) {
            console.log("User modules updated successfully", response.data);
          } else {
            console.error("Failed to update user modules", response);
          }
        } catch (error) {
          console.error("Error updating user modules:", error);
        }
      }
    };

    updateUserModules();
  }, [selectedUser, userInfo]);

  const handleEdit = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container profileContainer">
      <div className="d-flex justify-content-between">
        <div>
          <p className="profileName">Profile</p>
        </div>
        <div
          className="search-container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ position: "relative", width: "300px" }}>
            <input
              type="text"
              placeholder="Search users"
              className="searchProfile"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onFocus={() => setShowDropdown(true)}
              style={{
                width: "100%",
                padding: "10px 35px 10px 15px",
                border: "1px solid #2874f0",
                borderRadius: "4px",
                outline: "none",
                fontSize: "14px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
              }}
            />
            <FaSearch
              className="search-icon"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#2874f0",
                cursor: "pointer",
              }}
            />
            {showDropdown &&
              searchSuggestions &&
              searchSuggestions.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    backgroundColor: "#fff",
                    textAlign: "start",
                    border: "1px solid #ddd",
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    zIndex: 1,
                    borderRadius: "4px",
                    overflowY: "auto",
                    maxHeight: "300px",
                  }}
                >
                  {searchSuggestions.map((user) => (
                    <div
                      key={user._id}
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDropdownItemClick(user)}
                    >
                      {user.firstName} {user.lastName} - {user.empId}
                    </div>
                  ))}
                </div>
              )}
          </div>

          <button
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              border: "none",
              backgroundColor: "#e47331",
              color: "#fff",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={handleAddUser}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#e47331")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "#e47331")
            }
          >
            <LuPlusCircle size={20} style={{ marginRight: "5px" }} />
            Add New
          </button>
        </div>
      </div>

      {userInfo ? (
        <div>
          {userInfo && (
            <>
              <div className="user-details mt-4">
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="userDetails">User Details</p>
                  </div>
                  <div>
                    <button className="profileBtn" onClick={handleEdit}>
                      <FaEdit className="IconBtn" size={20} />
                    </button>
                  </div>
                </div>
                <div className="user-item">
                  <section className="grid grid-cols-2 gap-x-16 gap-y-6 mb-4 md:gap-x-8 md:w-11/12 mt-4 w-100">
                    <div>
                      <div className="row">
                        <div className="col-5 userLable">Full Name</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{`${userInfo.firstName} ${userInfo.lastName}`}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Contact Number</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.contactNumber}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Email ID</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.email}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Emergency Contact</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.emergencyContact}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Blood Group</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.bloodGroup}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-5 userLable">
                          Reporting Employee ID / Name
                        </div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          {userInfo.reportingEmpIdName}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-5 userLable">Employee ID</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.empId}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Site</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          {userInfo.permittedSites ? (
                            <p>
                              {userInfo.permittedSites.length} site selected
                            </p>
                          ) : (
                            "No sites permitted"
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Department</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.department}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Role</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.role}</p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              <div>
                <div className="profilePermissions">
                  <p>Permissions</p>
                  <button className="dropdown-toggle-btn PermissionsBtnToggleUser">
                    <select
                      name="siteid"
                      value={selectedSiteId}
                      onChange={handleSiteIdChange}
                      placeholder="Select siteId"
                      onFocus={(e) =>
                        (e.target.style.border = "1px solid black")
                      }
                      onBlur={(e) =>
                        (e.target.style.border =
                          "1px solid rgba(151, 151, 151, 0.25)")
                      }
                      style={{
                        backgroundColor: "#F3F3F3",
                        width: "425px",
                        height: "40px",
                        float: "right",
                      }}
                    >
                      {sites.map((site) => (
                        <option key={site.siteId} value={site.siteId}>
                          {site.siteName}
                        </option>
                      ))}
                    </select>
                  </button>
                </div>

                <div
                  className="formData-grid mt-3"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "30px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <ToggleSwitch
                    label="Locations :"
                    isChecked={formData.location}
                    onToggle={() => handleToggle("location")}
                  />
                  <ToggleSwitch
                    label="Authentication :"
                    isChecked={formData.authentication}
                    onToggle={() => handleToggle("authentication")}
                  />
                </div>
              </div>

              <div>
                <div className="enable-modules-container">
                  <div>
                    <p className="enableModuleTitleProfile">Enable Modules</p>
                  </div>
                </div>

                {selectedUser && (
                  <div
                    className="formData-grid mt-3"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gap: "30px",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    {Object.entries(moduleLabelsTop).map(
                      ([title, value], index) => {
                        if (
                          selectedUser &&
                          selectedUser.modules &&
                          selectedUser.modules.hasOwnProperty(title)
                        ) {
                          const backgroundColor =
                            index % 2 === 0 ? "#FDF8F4" : "#F3F6F8";
                          return (
                            <div
                              key={title}
                              style={{
                                backgroundColor,
                                borderRadius: "10px",
                                padding: "10px",
                                width: "100%",
                                boxSizing: "border-box",
                              }}
                            >
                              <ToggleSwitchWithModules
                                key={title}
                                selectedSiteId={selectedSiteId}
                                id={title}
                                label={value}
                                isChecked={selectedUser.modules[title]}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                onToggle={() => handleToggle(title)}
                                style={{ backgroundColor: "transparent" }}
                              />
                            </div>
                          );
                        }
                        return null;
                      }
                    )}
                  </div>
                )}
                {selectedUser && (
                  <div
                    className="formData-grid mt-3"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gap: "30px",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    {Object.entries(moduleLabelsBottom).map(
                      ([title, value], index) => {
                        if (
                          selectedUser &&
                          selectedUser.modules &&
                          selectedUser.modules.hasOwnProperty(title)
                        ) {
                          const backgroundColor =
                            index % 2 === 1 ? "#FDF8F4" : "#F3F6F8";
                          return (
                            <div
                              key={title}
                              style={{
                                backgroundColor,
                                borderRadius: "10px",
                                padding: "10px",
                                width: "100%",
                                boxSizing: "border-box",
                              }}
                            >
                              <ToggleSwitchWithModules
                                key={title}
                                id={title}
                                label={value}
                                isChecked={selectedUser.modules[title]}
                                selectedSiteId={selectedSiteId}
                                user={userInfo}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                onToggle={() => handleToggle(title)}
                                style={{ backgroundColor: "transparent" }}
                              />
                            </div>
                          );
                        }
                        return null;
                      }
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div style={{ marginTop: "60px" }}>
            <p style={{ margin: "0px auto", paddingBottom: "5px" }}>
              No user found
            </p>
            <img
              src="https://img.freepik.com/premium-vector/default-avatar-profile-icon-social-media-user-image-gray-avatar-icon-blank-profile-silhouette-vector-illustration_561158-3383.jpg"
              alt="User Not Found"
              style={{
                width: "200px",
                height: "200px",
                objectFit: "cover",
                margin: "50px auto",
              }}
            />
          </div>
        </>
      )}

      <ProfileModal
        show={showModal}
        onHide={handleCloseModal}
        user={userInfo}
        refetchUserData={fetchUserData}
      />

      {showAddUserModal && (
        <AddUserModal show={showAddUserModal} onClose={handleCloseUserModal} />
      )}
    </div>
  );
};

export default Profile;
