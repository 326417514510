
export const canShowFolder = (userInfo, site) => {
  if (!userInfo || !site) return false;

  const permittedSite = userInfo?.permittedSites?.find((item) => item?.siteId === site);
  return permittedSite?.enableModules?.drawingAddFolder || false;
  
};

export const canrfiRaised = (userInfo, site) => {
  if (!userInfo || !site) return false;

  const permittedSite = userInfo?.permittedSites?.find((item) => item?.siteId === site);
  return permittedSite?.enableModules?.rfiRaised  || false;
  
};
