/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId, userInfo } from "../../../atoms";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import { Tab1, Tabs1 } from "../../Tabs/Tabv2";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import filter from "../../../Images/Filter.svg";
import { FaFilter } from "react-icons/fa";
import Pen from "../SITEHEAD/IconsSiteHead/penIcon.svg"
import Eye from "../SITEHEAD/IconsSiteHead/Eye.svg"
import Download from "../SITEHEAD/IconsSiteHead/Download.svg"
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import Gallery from "../../../Images/Gallery.svg";
import { canShowFolder } from "../ShowFolder";
import { MdCancel } from "react-icons/md";
import ConsultantFilter from "../ConsultantFilter";
import ArrowToggle from "../ArrowToggle";
import SortByPopup from "../SortByPopup";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", // Optional
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", // Removes the row borders
          },
        },
      },
    },
  },
});

const ReceivedTable = ({ filterCondition, reloadData, setReloadData ,forwardAccess}) => {
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();

  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedDrawing, setSelectedDrawing] = useState({});
  const [consultantValue, setConsultantValue] = useState("") 
  const [searchValue, setSearchValue] = useState("")
  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    acceptedArchitectDate: "",
    folder:"",
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingFileName: null,
    receivedCopies: "",
    revisions: [],
    selectedRevision: "",
  });
  const [filters, setFilters] = useState({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
  });

  // sort by start
  const [sortField, setSortField] = useState(""); // Field to sort by
  const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
  const [activeSortPopup, setActiveSortPopup] = useState(null);

  const handleSortClick = (field) => {
    setFilters({
      drawingNo: "",
      consultant: "",
      category: "",
      drawingTitle: "",
      scheduledDate: "",
      submissionDate: "",
      revision: "",
    });
    setSearchValue("");
    setActiveSortPopup((prev) => (prev === field ? null : field));
  };

  const handleSortSelection = (order) => {
    if (activeSortPopup) {
      setSortField(activeSortPopup);
      setSortOrder(order);
      setActiveSortPopup(null); // Close the popup after selection
    }
  };

  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
    
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    })
    setSortField("");
    setSortOrder("");
    setActiveSortPopup(null);
  }, []);
  
  const handleFilterChange = (column, value) => {
    setSearchValue("")
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
    setFilters((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = ""; 
          return acc;
      }, {}),
      [column]: value, 
  }));
}

  const getNextRevision = (revisions) => {
    const lastRevision = revisions[revisions.length - 1];
    const numberPartStr = lastRevision.replace("R", "");
    const numberPart = parseInt(numberPartStr, 10);

    if (!isNaN(numberPart)) {
      return `R${numberPart + 1}`;
    } else {
      console.error(
        `Invalid revision format or parsing error: ${lastRevision}`
      );
      return lastRevision; // Return the last valid revision if there's a parsing error
    }
  };

  const [drawingTableList, setDrawingTableList] = useState([]);
  const [filteredDrawingOptions, setFilteredDrawingOptions] = useState([]);

  
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [showAddFolder, setShowAddFolder] = useState(false)
  const [currentUserInfo] = useRecoilState(userInfo);
  const [searchActive, setSearchActive] = useState(false)

  console.log(currentUserInfo,"currentUserInfo in Arch")
  
  const handleClose = () => {
    setOpen(false);
    setFormData({
      registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    acceptedArchitectDate: "",
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingFileName: null,
    receivedCopies: "",
    revisions: [],
    selectedRevision: "",
    })
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    // fetchData();
  };

  const [currentTab, setCurrentTab] = useState("received");

  // const handleTabChange = (tabKey) => {
  //   setCurrentTab(tabKey);
  // };
  // const currentTab = 'received';

  // Example validation function
  const validateForm = (formData, currentTab) => {
    const newErrors = {};
    // if (currentTab === "upload") {
    //   if (!formData.drawingNumber.trim()) {
    //     newErrors.drawingNumber = "DrawingNumber  is required";
    //   }
    //   if (!formData.drawingName.trim()) {
    //     newErrors.drawingName = "Drawing Title is required";
    //   }
    //   if (!formData.designDrawingConsultant.trim()) {
    //     newErrors.designDrawingConsultant =
    //       "DesignDrawingConsultant is required";
    //   }
    //   if (!formData.category.trim()) {
    //     newErrors.category = "Category is required";
    //   }
    //   if (!formData.submittedDate.trim()) {
    //     newErrors.submittedDate = "Submitted Date is required";
    //   }

    //   if (!formData.drawingFileName) {
    //     newErrors.drawingFileName = "Drawing File is required";
    //   }
    //   // if (!formData.remarks) errors.remarks = 'This field is required.';
    // } else 
    if (currentTab === "received") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      if (showAddFolder && !formData?.folder?.trim()) {
        newErrors.folder =
          "Folder Name is required";
      }
      if (!formData.selectedRevision) {
        newErrors.selectedRevision = "revision is required";
      }
      if (!formData.receivedCopies) {
        newErrors.receivedCopies = "receivedCopies are required";
      } else if(selectedDrawing?.noOfRoHardCopyRevisions && formData.receivedCopies>selectedDrawing?.noOfRoHardCopyRevisions-1){
        newErrors.receivedCopies = `recieved copies should be less than ${selectedDrawing?.noOfRoHardCopyRevisions}`
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
    }
    return newErrors;
  };

  const handleFormSubmit = async () => {
    // Validate the form data
    const errors = validateForm(formData, currentTab);
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    if (currentTab === "received") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReceived(formData.registerId);
    }
  };

  const submitReceived = async (drawingItemId) => {
    try {
      const ApprovalFormData = {
        acceptedROHardCopyRevisions: [
          {
            hardCopySubmittedDate: todayDate(),
            revision: formData.selectedRevision,
            receivedCopies: formData.receivedCopies,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/HardCopyRevision/${drawingItemId}`,
        ApprovalFormData
      );
      const id = response.data.data._id;

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const hardCopyFile = new FormData();
        hardCopyFile.append("hardCopyFile", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/RoHardCopyFile/${drawingItemId}`,
          hardCopyFile
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      window.location.reload();
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };

  const handleEdit = (drawingId) => {
    const selected = drawingList.find((drawing) => drawing._id === drawingId);
    if (selected) {
      const combinedRevisions = [
        // ...selected.acceptedArchitectRevisions,
        ...(selected.acceptedRORevisions || []),
      ];

      setSelectedDrawing(selected);
      setFormData({
        registerId: selected._id,
        drawingNumber: selected.drawingNo || "",
        drawingName: selected.drawingTitle || "",
        category: selected.category.category || "", // Ensure the category field is properly accessed
        designDrawingConsultant: `${selected.designDrawingConsultant?.role}`,
        acceptedArchitectDate: selected.acceptedArchitectDate
          ? new Date(selected.acceptedArchitectDate).toLocaleDateString()
          : "",
        revisions: combinedRevisions,
        submittedDate: todayDate(),
        // dueDays: 0,
        // issuedSoftCopy: selected.issuedSoftCopy,
        // receivedSoftCopy: selected.receivedSoftCopy,
        // drawingFileName: selected.drawingFileName
      });
      setOpen(true);
    }
  };
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    console.log(name,"name")
    console.log(value,"value")

    if (type === "file") {
      // const file = files[0];
      setFormData({
        ...formData,
        [name]: files[0], // Assuming you are only uploading one file
      });
      
    } else if (name === "receivedCopies" && /^\d*$/.test(value)) {

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    if (name === "designDrawingConsultant") {
      const selectedConsultant = value;

      // Filter drawing numbers based on the selected consultant
      const filteredDrawings = drawingList.filter(
        (register) =>
          register.designDrawingConsultant?.role === selectedConsultant
      );

      setFilteredDrawingOptions(filteredDrawings);

      // Clear the selected drawing number, drawing name, category, and revisions when the consultant changes
      setFormData((prevFormData) => ({
        ...prevFormData,
        designDrawingConsultant: selectedConsultant,
        drawingNumber: "",
        drawingName: "",
        category: "",
        folder:"",
        revisions: [],
        submittedDate: todayDate(),
        receivedCopies: "",
        note: "",
        selectedRevision: "",
      }));
    }

    if (name === "drawingNumber") {
      const selectedDrawing = drawingList.find(
        (register) => register.drawingNo === value
      );
console.log(selectedDrawing,"selected drawing")
      setSelectedDrawing(selectedDrawing)
      if (selectedDrawing) {
        const combinedRevisions = Array.isArray(
          selectedDrawing.acceptedArchitectRevisions
        )
          ? selectedDrawing.acceptedArchitectRevisions.flatMap(
              (rev) => rev.revision || []
            )
          : [];

        setFormData((prevFormData) => ({
          ...prevFormData,
          registerId: selectedDrawing._id,
          drawingNumber: selectedDrawing.drawingNo,
          drawingName: selectedDrawing.drawingTitle,
          folder:selectedDrawing.folderId?.folderName || "",
          category: selectedDrawing.category?.category || "",
          revisions: combinedRevisions,
          submittedDate: todayDate(),
          receivedCopies: "",
          note: "",
          selectedRevision: "",
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          registerId: "",
          drawingName: "",
          category: "",
          revisions: [],
          submittedDate: todayDate(),
          receivedCopies: "",
          note: "",
          selectedRevision: "",
        }));
      }
    }

    if (name === "selectedRevision" || name === "note") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    if (drawingItem.acceptedROHardCopyRevisions.length > 0) {
      setSelectedTab(drawingItem.acceptedROHardCopyRevisions.length - 1);
    }
    setViewDialogOpen(true);
  };

  // const handleViewOpen = (drawingItem) => {
  //   setSingleDrawingData(drawingItem);
  //   setViewDialogOpen(true);
  // };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };


  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
        
      }
    });
  };

  useEffect(() => {
    fetchInData();
  }, []);

  useEffect(() => {
    fetchData();
    setReloadData(false);
  }, [reloadData === true]);

  const handleDownload = async (id, revision,view) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      const response = await webApi.getImage(
        view === "Ro" ?
        `api/architectureToRoRegister/${id}/RoHardCopy/${revision}`:
        `api/architectureToRoRegister/${id}/hardCopy/${revision}`,
        {
          responseType: "arraybuffer", 
        }
      );

      processFile(response, "download", navigate); 
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision,view) => {
    setApiProcessing({
      loader: true,
      message: "opening...",
    });
    try {
      const response = await webApi.getImage(
        view === "Ro" ?
        `api/architectureToRoRegister/${id}/RoHardCopy/${revision}`:
        `api/architectureToRoRegister/${id}/hardCopy/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "view", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  useEffect(() => {
    const fetchDrawingTableData = async (site) => {
      try {
        const response = await webApi.get(
          `api/architect/drawingRo/${site}?filterType=received`
        );
        if (response.status === 200) {
          setDrawingTableList(response.data.data);
        }
        console.log(response, "RO tabs tableData");
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchDrawingTableData(site);
  }, [site]);

  useEffect(() => {
    console.log(drawingTableList, "drawingTableList");
  }, [drawingTableList]);

  useEffect(()=>{console.log(drawingList,"drawingResgisterList")},[drawingList])

  
  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);

    
  };

  const handleTypeSelect = (type, selectedType) => {
    setDrawingTableList((data) =>
      data.filter((drawingItem) => {
        const category = drawingItem?.category?.category?.toLowerCase() || "";
        const folderName =
          drawingItem?.folderId?.folderName?.toLowerCase() || "";

        let matchesCategory = true;
        if (type === "category") {
          matchesCategory = selectedType
            ? category === selectedType.toLowerCase()
            : true;
        }

        let matchesFolder = true;
        if (type === "folder") {
          matchesFolder = selectedType
            ? folderName === selectedType.toLowerCase()
            : true;
        }

        return matchesCategory && matchesFolder;
      })
    );
  };

  const calculateDueDays = (
    acceptedSiteSubmissionDate,
    softCopySubmittedDate = null
  ) => {
    if (!acceptedSiteSubmissionDate) return "-";

    const dateToCompare = softCopySubmittedDate
      ? new Date(softCopySubmittedDate)
      : new Date();
    const acceptedDate = new Date(acceptedSiteSubmissionDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  };
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder); 
  }, [currentUserInfo, site]);

  const dropdownRef = useRef(null);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isCategoriesDropdownOpen, setCategoriesDropdownOpen] = useState(false);
  const [isFoldersDropdownOpen, setFoldersDropdownOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectedConsultant, setSelectedConsultant] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
    
  };

  const handleType = (type) => {
    if (type === "all") {
      setSelectedCategory("");

      setFilterDropdownOpen(false);
      setCategoriesDropdownOpen(false);
      setFoldersDropdownOpen(false);
    } else {
      setSelectedCategory(type);
      setFilterDropdownOpen(false);
      setFoldersDropdownOpen(false);
    }
  };

  const uniqueFolders = useMemo(() => {
    const folder = drawingTableList.map((item) => item.folderId?.folderName);
    return [...new Set(folder)];
  }, [drawingTableList]);

  const uniqueCategories = useMemo(() => {
    const categories = drawingTableList.map((item) => item.category?.category);
    return [...new Set(categories)];
  }, [drawingTableList]);

  const uniqueConsultant = useMemo(() => {
    const consultant = drawingTableList.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [drawingTableList]);

  console.log(uniqueConsultant, uniqueFolders, "SITE Head Upload categories");

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [filteredGoData, setFilteredGoData] = useState(null); 

  const [showFilteredData, setShowFilteredData] = useState(false); 

  const handleConsultantSelect = (consultant) => {
    setSelectedConsultant(consultant)
    setShowFilteredData(false);
      
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(
      (prevSelected) =>
        prevSelected.includes(category)
          ? prevSelected.filter((c) => c !== category) 
          : [...prevSelected, category] 
    );
    setShowFilteredData(false);
  };

  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
    setShowFilteredData(false);
  };

  const handleGoClick = () => {
    console.log("Selected Consultants:", selectedConsultant);
    console.log("Selected Categories:", selectedCategory);
    console.log("Selected Folders:", selectedFolder);
    if(selectedConsultant || selectedCategory.length || selectedFolder){
      setShowFilteredData(true);
      toggleFilterDropdown();
      }
  };

  const filteredDataAndUploadData = useMemo(() => {
    if (!showFilteredData) {
    return drawingTableList;
  }
    return drawingTableList .filter((item) =>{ 
      const matchesConsultant = selectedConsultant === "" ||  item?.designDrawingConsultant?.role === selectedConsultant;

    const matchesCategory = selectedCategory.length === 0 ||  selectedCategory.includes(item?.category?.category);

    const matchesFolder = 
      !selectedFolder || item.folderId?.folderName === selectedFolder;

    return matchesConsultant && matchesCategory && matchesFolder; 
  });
  }, [showFilteredData, selectedConsultant, selectedCategory, selectedFolder, drawingTableList]);

  const handleClearAll = () =>{
    setSelectedCategory([])
    setSelectedFolder("")
    setSelectedConsultant("")
  }
  const handleFilterCancel = () =>{
    toggleFilterDropdown()
    setShowFilteredData(false)

  } 

  const handleRemove = (type, value) => {
    setShowFilteredData(false)

    if (type === "consultant") {
      setSelectedConsultant("");
    } else if (type === "category") {
      setSelectedCategory(selectedCategory.filter((cat) => cat !== value));
    } else if (type === "folder") {
      setSelectedFolder("");
    }
  };

  const updatedRows = drawingTableList.filter((item)=>item.designDrawingConsultant?.role.includes(consultantValue)).map((row, index) => ({
    ...row,
    serialNo: index + 1, 
    drawingNo: row.drawingNo,
    consultant: row.designDrawingConsultant?.role ,
    drawingCategory: row.category?.category ,
    drawingTitle: row.drawingTitle ,
    revision:row.acceptedROHardCopyRevisions?.length > 0
    ? row.acceptedROHardCopyRevisions
        .slice(-1)
        .map((rev) => rev.revision)
        .join(", ")
    : "-",
    siteHeadReceivedRevision:row.acceptedSiteHeadHardCopyRevisions?.length > 0
    ? row.acceptedSiteHeadHardCopyRevisions
        .slice(-1)
        .map((rev) => rev.revision)
        .join(", ")
    : "-",
  }));

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = useMemo(
    () => [
      {
        field: "serialNo",
        headerName: "S.No",
        flex: 0.5,
        filterable: false,
        sortable: false,
      },
      {
        field: "drawingNo",
        headerName: "DRWG.No",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "consultant",
        headerName: "Consultant",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "drawingCategory",
        headerName: "Drawing Category",
        flex: 1.5,
        filterable: true,
        sortable: true,
      },
      {
        field: "drawingTitle",
        headerName: "Drawing Title",
        flex: 2,
        filterable: true,
        sortable: true,
       
      },
      {
        field: "revision",
        headerName: "RO Revision",
        flex: 1.5,
        filterable: true,
        sortable: true,
        
      },
      {
        field: "siteHeadReceivedRevision",
        headerName: "Site Head Received Revision",
        flex: 1.5,
        filterable: true,
        sortable: true,
        
      },
      {
        field: "action",
        headerName: "Action",
        flex: 1,
        filterable: false,
        sortable: false,
        renderCell: (params) => (
          <div style={{ display: "flex", gap: "10px", alignItems: "center",marginTop:"24px" }}>
            <img
              src={Eye}
              alt="view"
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() => handleViewOpen(params.row)}
            />
          </div>
        ),
      },
    ],
    [drawingTableList]
  );
  

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          position:"relative"
        }}
      >
        <div
          style={{
            // position: "absolute",
            // right:"170px",
            position:"relative",
            width: "280px",
            paddingLeft: "20px",
            height: "50px",
            display: "flex",
            marginTop: "-52px",
          }}
        >
          <input
            placeholder="Search Drawing Lists"
            onChange={handleSearchChange}
            style={{
              paddingLeft: "20px",
              border: "1px solid var(--primary-color)",
              width: "250px",
              height: "40px",
            }}
          />
          <Icon.Search
            style={{
              position: "absolute",
              right: "30px",
              top: "40%",
              transform: "translateY(-50%)",
              color: "#ACACAC",
            }}
          />
          

        </div>

        

        <div
          style={{
            // position: "absolute",
            // right:"170px",
            position:"relative",
            // width: "250px",
            // paddingLeft: "20px",
            height: "50px",
            display: "flex",
            marginTop: "-52px",
          }}
        >
          <>

          {/* <div ref={dropdownRef} style={{ position: "relative" }}>
          <div onClick={toggleFilterDropdown}>
            <FaFilter
              size={40}
              style={{
                border: "1px solid var(--primary-color)",
                padding: "5px",
                marginLeft:"5px"
              }}
            />
          </div>
          {isFilterDropdownOpen && (
            <div
              style={{
                position: "absolute",
                top:"32px",
                right:"0px",
                background: "white",
                borderRadius: "4px",
                zIndex: 1000,
                textAlign: "left",
                marginTop: "20px",
                marginLeft: "-558px",
                padding: "15px",
                width: "500px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: selectedConsultant || selectedCategory.length || selectedFolder?"space-between":"flex-end",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginTop:"10px"
                }}
              >
                
                <MdCancel style={{position:"absolute",fontSize:"23px" ,right:"-10px",top:"-24px",cursor:"pointer"}}onClick={handleFilterCancel} />
                {(selectedConsultant || selectedCategory.length || selectedFolder) && (
                    <button
                      style={{
                        padding: "6px 10px",
                        backgroundColor: "crimson",
                        color: "white",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearAll}
                    >
                      Clear All
                    </button>
                  )}
                  <button
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                      fontSize: "16px",
                      padding: "5px 15px",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginBottom: "5px",
                    }}
                    onClick={handleGoClick}
                  >
                    Go
                  </button>
                  
                
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginBottom: "15px",
                }}
              >
                {selectedConsultant && (
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("consultant", selectedConsultant)}
                  >
                    {selectedConsultant} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                )}

                {selectedCategory.map((category, index) => (
                  <button
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("category", category)}
                  >
                    {category} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                ))}

                {selectedFolder && (
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("folder", selectedFolder)}
                  >
                    {selectedFolder} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                )}
              </div>

              <div style={{ flex: 3 }}>
                <span style={{ cursor: "pointer", position: "relative" }}>
                  <p style={{ color: "black", fontSize: "20px" }}>Consultant</p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "8px",
                    }}
                  >
                    {uniqueConsultant.map((consultant, index) => (
                      <div
                        key={index}
                        style={{
                          flex: "1 1 calc(25.33% - 15px)",
                          backgroundColor:
                            selectedConsultant === consultant ? "#ffd700" : "#e0f7fa",
                          fontWeight:
                            selectedConsultant === consultant ? "700" : "500",
                          borderRadius: "4px",
                          textAlign: "center",
                          fontSize: "14px",
                          padding: "5px",
                          color: selectedConsultant === consultant ? "white" : "#333",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() => handleConsultantSelect(consultant)}
                      >
                        {consultant}
                      </div>
                    ))}
                  </div>
                </span>

                        <span style={{ cursor: "pointer", position: "relative" }}>
                          <p style={{ color: "black", fontSize: "20px" }}>Categories</p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                            }}
                          >
                            {uniqueCategories.map((category, index) => (
                              <div
                                key={index}
                                style={{
                                  flex: "1 1 calc(25.33% - 15px)",
                                  backgroundColor:  selectedCategory.includes(category)?"#ffd700":"#e0f7fa",
                                  fontWeight: selectedCategory.includes(category)?"700":"500",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: "5px",
                                  color: selectedCategory.includes(category)?"white":"#333",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() => handleCategorySelect(category)}
                              >
                                {category}
                              </div>
                            ))}
                          </div>
                        </span>

                        {showAddFolder?<span style={{ cursor: "pointer", position: "relative" }}>
                          <p
                            style={{
                              color: "black",
                              fontSize: "20px",
                              marginTop: "10px",
                            }}
                          >
                            Folders
                          </p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                            }}
                          >
                            {uniqueFolders.map((folder, index) => (
                              <div
                                key={index}
                                style={{
                                  flex: "1 1 calc(25.33% - 15px)",
                                  backgroundColor: "#e0f7fa",
                                  fontWeight: selectedFolder === folder ? "700" : "500",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: "5px",
                                  color: "#333",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() => handleFolderSelect(folder)}
                              >
                                {folder}
                              </div>
                            ))}
                          </div>
                        </span>:""}
                        <>
                          
                        </>
                      </div>
                    </div>
                  )}
        </div> */}
            {forwardAccess && (
              <Button
                style={{
                  height: "40px",
                  marginRight:
                      "15px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "var(--primary-color)",
                  border: "1px solid var(--primary-color)",
                  borderRadius: "0px",
                  paddingInline:
                      "6px",
                  marginTop:
                      "0px",
                }}
                onClick={handleClickOpen}
              >
                <Icon.PlusCircle
                  size={20}
                  style={{ marginRight: "5px" }}
                />{" "}
                Add New
              </Button>
            )}
          </>
        </div>        
                
      </div>

      <div style={{ marginTop: "10px" }}>
        <Box
          className="dataGridContainer"
        >
          <ThemeProvider theme={theme}>
          <DataGrid
            columns={columns}
            rows={filteredRows}
            getRowId={(row) => row.drawingId}
            rowHeight={60}
            disableColumnMenu={false} // Keep the column menu for filtering
            pagination={false}
            hideFooter={true}
            getRowSpacing={params=>({
              top:params.isFirstVisible ? 0 : 5,
              bottom:params.isLastVisible ? 0 : 5
            })}
            
            getRowClassName={(params) =>
              "customHeader" 
            }
            
          />
          </ThemeProvider>
        </Box>
      </div>
      <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: " #4D4D4D",
              margin: "0px",
              position:"relative"
            }}
          >
            <p
              style={{
                width:"100%",
                textAlign: "center",
                color: "white",
                marginTop:"15px"
              }}
            >
              Drawing
            </p>
            <RxCross1
              size={20}
              style={{
                position:"absolute",
                right:"10px",
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "15px",
                color: "white",
              }}
              onClick={handleClose}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0",
                border: "1px solid var(--primary-color)",
              }}
            >
              {/* <button
            onClick={() => {
              setCurrentTab('upload')
            }}
            style={{
              backgroundColor: currentTab === 'upload' ? 'var(--primary-color)': 'transparent',
              color: currentTab === 'upload' ? 'white' : 'var(--primary-color)',
              width: '150px',
              height: '35px',
              cursor: 'pointer',
              borderRight: '1px solid var(--primary-color)', 
             
            }}
          >
            Upload
          </button> */}

              <button
                onClick={() => setCurrentTab("received")}
                style={{
                  background:
                    currentTab === "received"
                      ? "var(--primary-color)"
                      : "transparent",
                  color:
                    currentTab === "received"
                      ? "white"
                      : "var(--primary-color)",
                  width: "150px",
                  height: "35px",
                  cursor: "pointer",
                }}
              >
                Received
              </button>
            </div>
          </div>
          <DialogContent
            style={{ padding: "25px", minWidth: "300px", minHeight: "300px",maxHeight:"70vh",overflowY:"auto"}}
          >
            <>
              <Loader
                open={apiProcessing.loader}
                message={apiProcessing.message}
              />
              <section
                style={{
                  marginLeft: "80px",
                  marginRight: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "50px",
                    width: "80%",
                  }}
                >
                  <div>
                    <label
                      htmlFor="designDrawingConsultant"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Design Consultant
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <SearchDropdownIdVal
                  value={formData.designDrawingConsultant}
                  placeholder={"Select Design Consultant"}
                  options={Array.from(
                    new Set(
                      drawingList.map(
                        (register) =>
                          `${register.designDrawingConsultant?.role}`
                      )
                    )
                  )
                    .filter(Boolean)
                    .map((consultant, index) => ({
                      name: consultant,
                      value: consultant,
                    }))}
                  onOptionSelect={(option) => {
                    handleChange({
                      target: {
                        name: "designDrawingConsultant",
                        value: option.value,
                      },
                    });
                  }}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                    {errors.designDrawingConsultant && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.designDrawingConsultant}
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="drawingNumber"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Drawing No
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <SearchDropdownIdVal
                  placeholder={"Select Drawing No"}
                  value={formData.drawingNumber}
                  options={filteredDrawingOptions.map((register) => ({
                    name: register.drawingNo,
                    value: register.drawingNo,
                  }))}
                  onOptionSelect={(option) =>
                    handleChange({
                      target: { name: "drawingNumber", value: option.value },
                    })
                  }
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />

                    {errors.drawingNumber && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.drawingNumber}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="drawingName"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Drawing Title
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="drawingName"
                      name="drawingName"
                      value={formData.drawingName}
                      onChange={handleChange}
                      placeholder="Enter your drawing title"
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                      readOnly
                    />
                    {errors.drawingName && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.drawingName}
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="category"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Drawing Category
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="category"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      placeholder="Enter your Drawing Category"
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                      readOnly
                    />
                    {errors.category && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.category}
                      </p>
                    )}
                  </div>

                  {showAddFolder?<div>
                      <label
                        htmlFor="folder"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Folder
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                    type="text"
                    id="folder"
                    name="folder"
                    value={formData.folder}
                    onChange={handleChange}
                    placeholder="Enter your  Folder "
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                      {errors.folder && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.folder}
                        </p>
                      )}
                    </div>:""}

                  <div>
                    <label
                      htmlFor="revisions"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Revisions
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <SearchDropdownIdVal
                  placeholder={"Select Revision"}
                  backgroundColor={"var(--table-evenrow-color)"}
                  value={formData.selectedRevision}
                  options={
                    currentTab === "received"
                      ? formData.revisions.length > 0
                        ? [
                            // { name: "Select Revision", value: "" },
                            ...(Array.isArray(formData.revisions)
                              ? formData.revisions.map((rev) => ({
                                  name: rev,
                                  value: rev,
                                }))
                              : []),
                          ]
                        : []
                      : [
                          {
                            name:
                              formData.revisions.length === 0
                                ? "R0"
                                : getNextRevision(formData.revisions),
                            value:
                              formData.revisions.length === 0
                                ? "R0"
                                : getNextRevision(formData.revisions),
                          },
                        ]
                  }
                  onOptionSelect={(option) =>
                    handleChange({
                      target: { name: "selectedRevision", value: option.value },
                    })
                  }
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly={currentTab !== "received"}
                />
                    {errors.selectedRevision && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.selectedRevision}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="receivedCopies"
                      className="fs-10 fw-bold text-start py-2
              "
                    >
                      No of Copies Received
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="receivedCopies"
                      name="receivedCopies"
                      placeholder="Enter No of Received Copies"
                      value={formData.receivedCopies}
                      onChange={(e)=>{
                        if(formData.drawingNumber && formData.designDrawingConsultant){
                          handleChange(e)
                        }
                        }}
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                    />
                    {errors.receivedCopies && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.receivedCopies}
                    </p>
                  )}
                  </div>


                  <div>
                    <label
                      htmlFor="drawingFileName"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Upload File
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      id="drawingFileName"
                      name="drawingFileName"
                      onChange={handleChange}
                      placeholder="Select your drawing file"
                      className="form-control fs-12"
                      style={{ width: "100%", height: "43px" }}
                    />
                    {errors.drawingFileName && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.drawingFileName}
                      </p>
                    )}
                  </div>
                </div>
              </section>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "45px",
                }}
              >
                <button
                  style={{
                    padding: "10px",

                    border: "1px solid lightgray",
                    backgroundColor: "transparent",
                    width: "100px",
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "lightgray")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "transparent")
                  }
                  onClose={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  style={{
                    marginLeft: "50px",
                    padding: "10px",

                    border: "1px solid var(--primary-color)",
                    width: "100px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                  }}
                  onClick={handleFormSubmit}
                >
                  Submit
                </button>
              </div>
            </>
          </DialogContent>
        </Dialog>

        <Dialog
          open={viewDialogOpen}
          onClose={handleViewClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              borderRadius: 10,
              fontFamily: "Poppins",
              background: "#FFF",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: " #4D4D4D",
              margin: "0px",
              height: "50px",
              position:"relative"
            }}
          >
            <p
              style={{
                width:"100%",
                marginTop:"15px",
                textAlign: "center",
                color: "white",
              }}
            >
              View
            </p>
            <RxCross1
              size={20}
              style={{
                position:"absolute",
                right:"10px",
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "20px",
                color: "white",
              }}
              onClick={handleViewClose}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                overflowX: "auto",
                maxWidth: "230px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "0",
                  border: "1px solid var(--primary-color)",
                }}
              >
                {singleDrawingData?.acceptedROHardCopyRevisions?.length >
                0 ? (
                  <Tabs1 value={selectedTab} onChange={handleTabChange}>
                    {singleDrawingData.acceptedROHardCopyRevisions.map(
                      (revisionItem, index) => (
                        <Tab1
                          key={index}
                          value={index}
                          label={`${revisionItem.revision}`}
                          style={{
                            backgroundColor:
                              singleDrawingData
                                .acceptedROHardCopyRevisions.length ===
                                1 || selectedTab === index
                                ? "var(--primary-color)"
                                : "transparent",
                            color:
                              singleDrawingData
                                .acceptedROHardCopyRevisions.length ===
                                1 || selectedTab === index
                                ? "white"
                                : "var(--primary-color)",
                            width: "150px",
                            height: "35px",
                            cursor: "pointer",
                            borderRight:
                              index <
                              singleDrawingData
                                .acceptedROHardCopyRevisions.length -
                                1
                                ? "1px solid var(--primary-color)"
                                : "none",
                            // borderRadius: index === 0 && !isSingleRevision ? '5px 0 0 5px' : (index === singleDrawingData.acceptedRORevisions.length - 1 && !isSingleRevision ? '0 5px 5px 0' : 'none'),
                          }}
                        />
                      )
                    )}
                  </Tabs1>
                ) : (
                  <div>No Revisions</div>
                )}
              </div>
            </div>
          </div>

          <DialogContent
            style={{
              padding: "25px",
              minWidth: "300px",
              minHeight: "300px",
              maxHeight: "70vh",
              overflowY: "auto",
            }}
          >
            <>
              <Loader
                open={apiProcessing.loader}
                message={apiProcessing.message}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "20px",
                  padding: "20px",
                  color: "#4D4D4D",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "28px",

                  justifyContent: "center",
                  marginLeft: "80px",
                }}
              >
                <div>Drawing Number</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.drawingNo || "NA"}
                </div>

                <div>Design Consultant</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.designDrawingConsultant?.role || "NA"}
                </div>

                <div>Category</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.category?.category || "NA"}
                </div>
                {showAddFolder &&
                <>
                <div>Folder</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.folderId?.folderName || "NA"}
                </div>
                </>
                }
                <div>Drawing Title</div>
                <div>:</div>
                <div style={{ marginLeft: "-130px" }}>
                  {singleDrawingData?.drawingTitle || "NA"}
                </div>


                {singleDrawingData?.acceptedROHardCopyRevisions?.[selectedTab] && (
    <>
      <div><strong>Collected From Architect</strong></div>
      <div></div>
      <div></div>
      <div>Revision</div>
      <div>:</div>
      <div style={{ marginLeft: "-130px" }}>
        {singleDrawingData.acceptedROHardCopyRevisions[selectedTab].revision || "NA"}
      </div>

      <div>Hard Copy Submitted Date</div>
      <div>:</div>
      <div style={{ marginLeft: "-130px" }}>
        {singleDrawingData.acceptedROHardCopyRevisions[selectedTab]
          .hardCopySubmittedDate
          ? new Date(
              singleDrawingData.acceptedROHardCopyRevisions[selectedTab]
                .hardCopySubmittedDate
            ).toISOString()
            .split("T")[0]
          : "-"}
      </div>

      <div>Received Copies</div>
      <div>:</div>
      <div style={{ marginLeft: "-130px" }}>
        {singleDrawingData.acceptedROHardCopyRevisions[selectedTab].receivedCopies !==
        undefined
          ? singleDrawingData.acceptedROHardCopyRevisions[selectedTab]
              .receivedCopies
          : "-"}
      </div>

      <div>Hard Copy File</div>
      <div>:</div>
      <div style={{ display: "flex", marginLeft: "-130px" }}>
        <img
          src={Gallery}
          style={{ marginRight: "10px", cursor: "pointer" }}
          onClick={() =>
            handleViewFile(
              singleDrawingData.drawingId,
              singleDrawingData.acceptedROHardCopyRevisions[selectedTab]
                .revision,
               "Ro"
            )
          }
        />
        <img
          src={Download}
          style={{ marginRight: "5px", cursor: "pointer" }}
          onClick={() =>
            handleDownload(
              singleDrawingData.drawingId,
              singleDrawingData.acceptedROHardCopyRevisions[selectedTab]
                .revision,
                "Ro"
            )
          }
        />
      </div>
    </>
  )}

  {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.[selectedTab] && (
    <>
      <div><strong>Received From SiteHead</strong></div>
      <div></div>
      <div></div>
      <div>Revision</div>
      <div>:</div>
      <div style={{ marginLeft: "-130px" }}>
        {singleDrawingData.acceptedSiteHeadHardCopyRevisions[selectedTab].revision || "NA"}
      </div>

      <div>Hard Copy Submitted Date</div>
      <div>:</div>
      <div style={{ marginLeft: "-130px" }}>
        {singleDrawingData.acceptedSiteHeadHardCopyRevisions[selectedTab]
          .hardCopySubmittedDate
          ? new Date(
              singleDrawingData.acceptedSiteHeadHardCopyRevisions[selectedTab]
                .hardCopySubmittedDate
            ).toISOString()
            .split("T")[0]
          : "-"}
      </div>

      <div>Received Copies</div>
      <div>:</div>
      <div style={{ marginLeft: "-130px" }}>
        {singleDrawingData.acceptedSiteHeadHardCopyRevisions[selectedTab]
          .receivedCopies !== undefined
          ? singleDrawingData.acceptedSiteHeadHardCopyRevisions[selectedTab]
              .receivedCopies
          : "-"}
      </div>

      <div>Hard Copy File</div>
      <div>:</div>
      <div style={{ display: "flex", marginLeft: "-130px" }}>
        <img
          src={Gallery}
          style={{ marginRight: "10px", cursor: "pointer" }}
          onClick={() =>
            handleViewFile(
              singleDrawingData.drawingId,
              singleDrawingData.acceptedSiteHeadHardCopyRevisions[selectedTab]
                .revision,
                 "siteHead"
            )
          }
        />
        <img
          src={Download}
          style={{ marginRight: "5px", cursor: "pointer" }}
          onClick={() =>
            handleDownload(
              singleDrawingData.drawingId,
              singleDrawingData.acceptedSiteHeadHardCopyRevisions[selectedTab]
                .revision,
                 "siteHead"
            )
          }
        />
      </div>
    </>
  )}
</div>
            </>
          </DialogContent>
        </Dialog>
        <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue}/>
    </>
  );
};

export default ReceivedTable;
