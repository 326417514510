import React from "react";
import PagesHeroSection from "../Components/constants-comp/pagesHerosection";
import Header from "../Components/Navbar/Header";
import FloatingImageContainer from "../Widgets/FloatingImageContainer";

function WhyUs({ imageSrc, altText, text ,fromAboutus, isPhone, ShowBtn, heading, display, top}) {
  return (
    <>
      <Header />
      <PagesHeroSection
        imageUrl={
          "https://cdn.builder.io/api/v1/image/assets/TEMP/4e265835733c8d6a6eea6998a16b66ad872c731fcb9c8299c01f76cb0d5bfde9?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e"
        }
        title={"Why us"}
      />
      <FloatingImageContainer top={top} title={"Why Us"} description={`
          We, at RCONSPACE stand out as the premier platform for all your construction needs, bringing innovation and expertise to every project. Our comprehensive services cater to architects, contractors, and project managers, providing seamless collaboration across all levels—from design to execution. With our user-friendly interface, you can easily access essential tools, share documents, and track progress in real time. Choose RCON Space to transform your construction experience into one that is efficient, transparent, and successful, empowering you to build better, faster, and smarter.
        `}/>
      <div className="container">
        
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h1>How do we support?
            </h1>
            <p>
            Our platform provides a space for collaborative planning, progress tracking, and resource management, ensuring that your project stays on time and within budget.

            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h1>What Makes Us Different?
            </h1>
            <p>
            We prioritize transparent communication, timely project completion, and personalized service, making the construction process smooth and stress-free for you.

            </p>
          </div>
        </div>
        <div className="row">
          <div className=" col-12 text-center mb-4">
            <h1>How Do We Ensure Client Satisfaction?
            </h1>
            <p>
            Our integrated approach ensures that all project stages are covered, resulting in faster communication, reduced errors, and a cohesive workflow from start to finish.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyUs;
