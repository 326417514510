/* eslint-disable react/no-children-prop */
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { siteId, userInfo } from "../../../atoms";
import { RxCross1 } from "react-icons/rx";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import TableWrapper from "../../../Utilities/TableWrapper";
import { processFile } from "../BackendUtils";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import { FaFilter } from "react-icons/fa";
import Eye from "./IconsSiteHead/Eye.svg";
import Download from "./IconsSiteHead/Download.svg";
import Pen from "./IconsSiteHead/penIcon.svg";
import { canShowFolder } from "../ShowFolder";
import Gallery from "../../../Images/Gallery.svg";
import ArrowToggle from "../ArrowToggle";
import SortByPopup from "../SortByPopup";
import { BiSortAlt2 } from "react-icons/bi";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", 
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", 
          },
        },
      },
    },
  },
});

const RequestedTable = () => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  let webApi = new WebApimanager();
  const navigate = useNavigate();
  const [currentUserInfo] = useRecoilState(userInfo);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [selectedDrawingId, setSelectedDrawingId] = useState(null);
  const [site, setSite] = useRecoilState(siteId);
  const [formData, setFormData] = useState({
    response: "",
    issuedSoftCopy: "",
    issuedHardCopy: "",
    submittedDate: todayDate(),
    reason: "",
    drawingFileName: null,
  });
  const [filters, setFilters] = useState({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    dueStatus:""
  });
  
  // sort by start
  const [sortField, setSortField] = useState(""); // Field to sort by
  const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
  const [activeSortPopup, setActiveSortPopup] = useState(null);

 

  const handleSortClick = (field) => {
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    dueStatus:""
    })
    setSearchValue("");
    setActiveSortPopup((prev) => (prev === field ? null : field)); // Toggle the popup
    
  };

  const handleSortSelection = (order) => {
    if (activeSortPopup) {
      setSortField(activeSortPopup);
      setSortOrder(order);
      setActiveSortPopup(null); // Close the popup after selection
    }
  };
  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    dueStatus:""
    })
    setSortField("");
    setSortOrder("");
    setActiveSortPopup(null);
  }, []);
  
  const handleFilterChange = (column, value) => {
    setSearchValue("")
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
    setFilters((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = ""; 
          return acc;
      }, {}),
      [column]: value, 
  }));
    
  };
  // sort by end

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [currentTab, setCurrentTab] = useState("");

  const handleTabChange = (tabKey) => {
    setCurrentTab(tabKey);
  };
  const validateForm = (formData, currentTab) => {
    let errors = {};
    if (currentTab === "accepted") {
      if (!formData.response) errors.response = "This field is required.";
    } else if (currentTab === "submitted") {
      if (!formData.issuedSoftCopy)
        errors.issuedSoftCopy = "This field is required.";
      if (!formData.receivedHardCopy)
        errors.receivedHardCopy = "This field is required.";
      if (!formData.drawingFileName)
        errors.drawingFileName = "File upload is required.";
      if (!formData.submittedDate)
        errors.submittedDate = "submitted date is required";
    } else if (currentTab === "reject") {
      if (!formData.reason) errors.reason = "This field is required.";
      if (!formData.drawingFileName)
        errors.drawingFileName = "File upload is required.";
    }
    return errors;
  };

  const handleFormSubmit = async () => {
    if (currentTab !== "accepted") {
      const errors = validateForm(formData, currentTab);
      setErrors(errors);
      if (Object.keys(errors).length > 0) return;
    }
    if (currentTab === "accepted") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitAaccept(selectedDrawingId);
    }
    if (currentTab === "submitted") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitApproval(selectedDrawingId);
    } else if (currentTab === "reject") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReject(selectedDrawingId);
    }
  };

  const submitAaccept = async (drawingItemId) => {
    try {
      const response = await webApi.put(
        `api/architectureToRoRequested/accept/${drawingItemId}`
      );
      setApiProcessing({ loader: false, message: "" });
      handleEditClose();
      fetchData();
      window.location.reload();
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);
    }
  };
  const submitApproval = async (drawingItemId) => {
    try {
      const ApprovalFormData = {
        remarks: formData.remarks,
        issuedSoftCopy: formData.issuedSoftCopy,
        receivedHardCopy: formData.receivedHardCopy,
        submittedDate: todayDate(),
      };
      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRequested/updateRevision/${drawingItemId}`,
        ApprovalFormData
      );
      const id = response.data.data._id;
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("drawingFileName", formData.drawingFileName);
        const uploadResponse = await webApi.imagePut(
          `api/architectureToRoRequested/drawing/${drawingItemId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleEditClose();
      fetchData();
      window.location.reload();
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);
    }
  };

  const submitReject = async (drawingItemId) => {
    try {
      const RejectFormData = {
        reason: formData.reason,
        siteId: site,
      };
      console.log("Submitting Reject data:", RejectFormData);

      const response = await webApi.put(
        `api/architectureToRoRequested/reject/${drawingItemId}`,
        RejectFormData
      );
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("rejectedFile", formData.drawingFileName);
        const uploadResponse = await webApi.imagePut(
          `api/architectureToRoRequested/rejectFile/${drawingItemId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleEditClose();
      fetchData();
      window.location.reload();
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);
    }
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const handleConfirmDialogOpen = (drawingItem) => {
    setSelectedItem(drawingItem);
    setSelectedDrawingId(drawingItem._id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmSubmit = () => {
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    if (selectedDrawingId) {
      console.log("Selected Drawing ID:", selectedDrawingId);
      webApi
        .put(`api/architectureToRoRequested/closed/${selectedDrawingId}`)
        .then((response) => {
          const data = response.data;
          console.log("Server Response:", response);
          console.log("Data:", data);
          setApiProcessing({ loader: false, message: "" });
          setConfirmDialogOpen(false);
          fetchData();
          window.location.reload();
        })
        .catch((error) => {
          setApiProcessing({ loader: false, message: "" });
          console.error("There was an error submitting the form!", error);
        });
    }
  };

  const [errors, setErrors] = useState({});
  const [drawingList, setDrawingList] = useState([]);
  const [sortedDrawingList, setSortedDrawingList] = useState([drawingList]);
  const [ initialDrawingList,setInitialDrawingList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleEditOpen = (drawingItem) => {
    setSelectedItem(drawingItem);
    setSelectedDrawingId(drawingItem._id);
    setEditDialogOpen(true);
    setErrors({});
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setFormData({});
    fetchData();
    setCurrentTab("");
  };

  const fetchData = async () => {
    let response = await webApi.get(
      `api/architectureToRoRequested/architect?siteId=${site}`
    );
    console.log("requesteddata", response.data.data);
    setDrawingList(response.data.data);
    setInitialDrawingList(response.data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setSortedDrawingList(drawingList);
  }, [drawingList]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleViewFile = async (id, status,revision) => {
    setApiProcessing({
      loader: true,
      message: "Opening...",
    });

    try {
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/architectureToRoRequested/rejectFile/${id}`
          : `api/architectureToRoRequested/request/${id}`,
        {
          responseType: "arraybuffer",
        }
      );

      processFile(response,"view",navigate,revision,"","RFI");
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const handleDownload = async (id, status) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });

    try {
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/architectureToRoRequested/rejectFile/${id}`
          : `api/architectureToRoRequested/request/${id}`,
        {
          responseType: "arraybuffer",
        }
      );

      processFile(response, "download", navigate);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const latestRevisionedit =
    selectedItem?.drawingId?.acceptedArchitectRevisions?.length > 0
      ? selectedItem.drawingId.acceptedArchitectRevisions[
          selectedItem.drawingId.acceptedArchitectRevisions.length - 1
        ]
      : null;

  const latestRevision =
    singleDrawingData?.drawingId?.acceptedArchitectRevisions?.length > 0
      ? singleDrawingData.drawingId.acceptedArchitectRevisions[
          singleDrawingData.drawingId.acceptedArchitectRevisions.length - 1
        ]
      : null;

  console.log(singleDrawingData, "RIF view Data");

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase().replace(/\s+/g, ""); 
    setSearchValue(searchInput);
    setSearchActive(true);

    // if (!searchInput) {
    //   setSortedDrawingList(drawingList);
    //   setSearchActive(false);
    // } else {
    //   const filteredDrawings = sortedDrawingList.filter((drawingItem) => {
    //     const drawingNumber = getString(drawingItem.drawingId?.drawingNo)
    //       .trim()
    //       .toLowerCase()
    //       .replace(/\s+/g, "");
    //     const drawingName = getString(drawingItem.drawingId?.drawingTitle)
    //       .trim()
    //       .toLowerCase()
    //       .replace(/\s+/g, "");
    //     const consultant = getString(
    //       drawingItem.drawingId?.designDrawingConsultant?.role
    //         ?.designDrawingConsultant ||
    //         drawingItem.drawingId?.designDrawingConsultant?.role ||
    //         drawingItem.drawingId?.designDrawingConsultant
    //     )
    //       .trim()
    //       .toLowerCase()
    //       .replace(/\s+/g, "");
    //     const category = getString(
    //       drawingItem.drawingId?.category?.category ||
    //         drawingItem.drawingId?.category
    //     )
    //       .trim()
    //       .toLowerCase()
    //       .replace(/\s+/g, "");
    //     const requestedDate = drawingItem.requestedDate
    //       ? new Date(drawingItem.requestedDate)
    //           .toLocaleDateString()
    //           .trim()
    //           .toLowerCase()
    //           .replace(/\s+/g, "")
    //       : "";
    //     const expectedDate = drawingItem.expectedDate
    //       ? new Date(drawingItem.expectedDate)
    //           .toLocaleDateString()
    //           .trim()
    //           .toLowerCase()
    //           .replace(/\s+/g, "")
    //       : "";
    //     const status = getString(drawingItem.status)
    //       .trim()
    //       .toLowerCase()
    //       .replace(/\s+/g, "");
    //     const revisions = drawingItem.drawingId?.acceptedArchitectRevisions
    //       ? drawingItem.drawingId.acceptedArchitectRevisions
    //           .map((revision) =>
    //             getString(revision.revision)
    //               .trim()
    //               .toLowerCase()
    //               .replace(/\s+/g, "")
    //           )
    //           .join(" ")
    //       : "";
    //     const searchPattern = new RegExp(searchInput.split("").join(".*"), "i");
    //     return (
    //       searchPattern.test(drawingNumber) ||
    //       searchPattern.test(drawingName) ||
    //       searchPattern.test(consultant) ||
    //       searchPattern.test(category) ||
    //       searchPattern.test(requestedDate) ||
    //       searchPattern.test(expectedDate) ||
    //       searchPattern.test(status) ||
    //       searchPattern.test(revisions)
    //     );
    //   });
    //   setSortedDrawingList(filteredDrawings);
    // }
  };

  const getString = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
    if (typeof value === "object") {
      return value.toString && typeof value.toString === "function"
        ? value.toString().toLowerCase()
        : "";
    }
    return typeof value === "string" ? value.toLowerCase() : "";
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const handleStatusChange =  (status) => {
    if(status && status === "All")
      setSelectedStatus('')
    else{
      setSelectedStatus(status)
    }
      setShowDropdown(false);

    }
  
  const calculateDueDays = (startDate, endDate) => {
    if (!startDate || !endDate) return "-";
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDiff = end - start; 
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
    return dayDiff; 
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const updatedRows = drawingList.map((row, index) => ({
    ...row,
    serialNo: index + 1, 
    drawingNo: row.drawingId?.drawingNo,
    consultant: row.drawingId?.designDrawingConsultant?.role,
    category: row.drawingId?.category?.category,
    drawingTitle: row.drawingId?.drawingTitle,
    requestedDate: row.requestedDate || null, 
    expectedDate: row.expectedDate || null, 
    dueStatus: calculateDueDays(new Date(), row.expectedDate), 
    revision: row.drawingId?.acceptedArchitectRevisions?.length > 0
      ? row.drawingId.acceptedArchitectRevisions[row.drawingId.acceptedArchitectRevisions.length - 1]?.revision
      : "-", 
    status: row.status || "NA", 
  }));

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingId?.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.drawingId?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.drawingId?.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingId?.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

const columns = useMemo(
  () => [
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 0.5,
      filterable: false,
      sortable: false,
    },
    {
      field: "drawingNo",
      headerName: "DRWG.No",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "consultant",
      headerName: "Consultant",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "category",
      headerName: "Drawing Category",
      flex: 1.5,
      filterable: true,
      sortable: true,
    },
    {
      field: "drawingTitle",
      headerName: "Drawing Title",
      flex: 2,
      filterable: true,
      sortable: true,
    },
    {
      field: "requestedDate",
      headerName: "Requested Date",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "expectedDate",
      headerName: "Expected Date",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "dueStatus",
      headerName: "Due/Overdue",
      flex: 1.5,
      filterable: true,
      sortable: true,
      renderCell: (params) => {
        const dueDays = calculateDueDays(new Date(), params.row.expectedDate);
        return (
          <span
            style={{
              color: dueDays >= 0 ? "green" : "red",
            }}
          >
            {dueDays === "-" ? "-" : dueDays >= 0 ? `${dueDays} days due `: `${Math.abs(dueDays)} days overdue`}
          </span>
        );
      },
    },
    {
      field: "revision",
      headerName: "Revision",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1.5,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" ,marginTop:"24px"}}>
          {params.row.status === "Requested" || params.row.status === "ReOpened" ? (
            <>
              <img
                src={Pen}
                alt="Edit"
                style={{ cursor: "pointer", marginRight: "5px" }}
                onClick={() => handleEditOpen(params.row)}
              />
              <img
                src={Eye}
                alt="View"
                style={{ cursor: "pointer", marginRight: "5px" }}
                onClick={() => handleViewOpen(params.row)}
              />
              <img
                src={Download}
                alt="Download"
                style={{ cursor: "pointer", marginRight: "5px" }}
                onClick={() => handleDownload(params.row._id)}
              />
            </>
          ) : (
            <>
              <img
                src={Eye}
                alt="View"
                style={{ cursor: "pointer", marginRight: "5px" }}
                onClick={() => handleViewOpen(params.row)}
              />
              <img
                src={Download}
                alt="Download"
                style={{ cursor: "pointer", marginRight: "5px" }}
                onClick={() => handleDownload(params.row._id)}
              />
            </>
          )}
        </div>
      ),
    },
  ],
  [drawingList]
);


  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#131313",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                Requested
              </p>
              <div style={{ display: "flex", flexDirection: "row" ,height:"50px"}}>
                <div
                  style={{
                    position: "relative",
                    width: "280px",
                    paddingLeft: "5px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder="Search Drawings"
                    onChange={handleSearchChange}
                    style={{
                      paddingLeft: "20px",
                      border: "1px solid var(--primary-color)",
                      width: "245px",
                      height: "40px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "40%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />
                </div>
                {/* <div style={{ position: "relative" }}>
                  <FaFilter
                    size={39}
                    style={{
                      border: "1px solid var(--primary-color)",
                      padding: "5px",
                      marginLeft: "10px",
                    }}
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                  <div style={{ display: "inline-block" }}>
                    <button
                      onClick={() => setShowDropdown(!showDropdown)}
                      style={{
                        padding: "10px",
                        border: "1px solid var(--primary-color)",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        cursor: "pointer",
                        color: "var(--bs-dropdown-link-color)",
                        display: "none",
                      }}
                    >
                      {selectedStatus}
                    </button>
                    {showDropdown && (
                      <div
                        ref={dropdownRef}
                        style={{
                          position: "absolute",
                          top: "40px",
                          right: "0",
                          borderRadius: "6px",
                          padding: "5px",
                          zIndex: 1000,
                          width: "150px",
                          background: "white",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {[
                          "All",
                          "Requested",
                          "Rejected",
                          "Accepted",
                          "Closed",
                          "Reopened",
                        ].map((status) => (
                          <button
                            key={status}
                            onClick={() => handleStatusChange(status)}
                            style={{
                              display: "block",
                              width: "100%",
                              padding: "10px",
                              border: "none",
                              background: "white",
                              textAlign: "left",
                              color:
                                selectedStatus === status
                                  ? "var(--primary-color)"
                                  : "var(--bs-dropdown-link-color)",
                            }}
                            className="DrawingFilterStatus"
                          >
                            {status}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Box
                className="dataGridContainer"
              >
                <ThemeProvider theme={theme}>
                <DataGrid
                  columns={columns}
                  rows={filteredRows}
                  getRowId={(row) => row.serialNo}
                  rowHeight={60}
                  disableColumnMenu={false} 
                  pagination={false}
                  hideFooter={true}
                  getRowSpacing={params=>({
                    top:params.isFirstVisible ? 0 : 5,
                    bottom:params.isLastVisible ? 0 : 5
                  })}
                  
                  getRowClassName={(params) =>
                    "customHeader" 
                  }
                  
                />
                </ThemeProvider>
              </Box>
            </div>
          </>
        }
      />
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            fontFamily: "Poppins",
            borderRadius: "10px",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <p
            style={{ width:"100%",marginTop:"15px", textAlign: "center", color: "white" }}
          >
            Approval/Reject Request
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleEditClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {selectedItem && selectedItem.status !== "Accepted" && (
              <button
                onClick={() => {
                  setCurrentTab("accepted");
                }}
                style={{
                  backgroundColor:
                    currentTab === "accepted"
                      ? "var(--primary-color)"
                      : "transparent",
                  color:
                    currentTab === "accepted"
                      ? "white"
                      : "var(--primary-color)",
                  width: "100px",
                  height: "35px",
                  cursor: "pointer",
                  borderRight: "1px solid var(--primary-color)",
                }}
              >
                Accept
              </button>
            )}
            {/* <button
          onClick={() => setCurrentTab('submitted')}
          style={{
            backgroundColor: currentTab === 'submitted' ? 'var(--primary-color)': 'transparent',
            color: currentTab === 'submitted' ? 'white' : 'var(--primary-color)',
            width: '100px',
            height: '35px',
            cursor: 'pointer',
            borderRight: '1px solid var(--primary-color)',
    
          }}
        >
          Submit
        </button> */}
            <button
              onClick={() => setCurrentTab("reject")}
              style={{
                background:
                  currentTab === "reject"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "reject" ? "white" : "var(--primary-color)",
                width: "100px",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Reject
            </button>
          </div>
        </div>
        {currentTab === "accepted" && (
          <DialogContent
            style={{
              padding: "25px",
              minWidth: "300px",
              minHeight: "300px",
              maxHeight: "70vh",
              overflowY: "auto",
            }}
          >
            <>
              <Loader
                open={apiProcessing.loader}
                message={apiProcessing.message}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  rowGap: "20px",
                  columnGap: "10px",
                  padding: "0 30px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <div>Request Reason</div>
                <div>:</div>
                <div>{selectedItem ? selectedItem.remarks : "-"}</div> */}

                {/* <div>Nature of Request Info</div>
                <div>:</div>
                <div>
                  {selectedItem
                    ? selectedItem.natureOfRequestedInformation &&
                      selectedItem.natureOfRequestedInformation.length > 0
                      ? selectedItem.natureOfRequestedInformation.map(
                          (item, index) => (
                            <div key={index} style={{ display: "inline" }}>
                              {item}
                              {index <
                                selectedItem.natureOfRequestedInformation
                                  .length -
                                  1 && ", "}
                              <br />
                            </div>
                          )
                        )
                      : "-"
                    : "-"}
                </div> */}
                <div>Nature of Requested Information Reasons</div>
                <div>:</div>
                <div>
                  {selectedItem?.natureOfRequestedInformationReasons &&
                  selectedItem.natureOfRequestedInformationReasons.length > 0
                    ? selectedItem.natureOfRequestedInformationReasons.map(
                        (item, index) => (
                          <div key={index}>
                            <span>{item.natureOfRequest}</span>-{item.reason}
                            <br />
                          </div>
                        )
                      )
                    : "-"}
                </div>
                <div>Revision</div>
                <div>:</div>
                <div>
                  {latestRevisionedit ? latestRevisionedit.revision : "-"}
                </div>

                <div>Requested Date</div>
                <div>:</div>
                <div>
                  {selectedItem?.requestedDate
                    ? formatDate(selectedItem.requestedDate)
                    : "-"}
                </div>

                <div>Expected Date</div>
                <div>:</div>
                <div>
                  {selectedItem?.expectedDate
                    ? formatDate(selectedItem.expectedDate)
                    : "-"}
                </div>
              </div>

              <div style={{ padding: "0 30px" }}>
                {/* <div style={{flex:'1'}}>
                <label htmlFor="response" className="fs-10 fw-bold text-start py-2">
                What issues are you going to resolve?
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  name="response"
                  value={formData.response}
                  onChange={handleChange}
                  placeholder="Enter your What issues are you going to resolve?"
                  className="form-control fs-12"
                  style={{ width: '100%' }}
                />
                {errors.response && <span style={{ color: 'red', fontSize: '12px' }}>{errors.response}</span>}
                </div> */}
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={handleFormSubmit}
                    style={{
                      background: "var(--primary-color)",
                      color: "white",
                      width: "100px",
                      height: "35px",
                      border: "none",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          </DialogContent>
        )}
        {currentTab === "submitted" && (
          <DialogContent
            style={{
              padding: "25px",
              minWidth: "300px",
              minHeight: "300px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <>
              <Loader
                open={apiProcessing.loader}
                message={apiProcessing.message}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <div>
                  <div>
                    <label
                      htmlFor="issuedSoftCopy"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Issued Soft Copy
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      name="issuedSoftCopy"
                      value={formData.issuedSoftCopy}
                      onChange={handleChange}
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                    >
                      <option value="">Select Yes/No</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {errors.issuedSoftCopy && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.issuedSoftCopy}
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="receivedHardCopy"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Received Hard Copy
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      name="receivedHardCopy"
                      value={formData.receivedHardCopy}
                      onChange={handleChange}
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                    >
                      <option value="">Select Yes/No</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {errors.receivedHardCopy && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.receivedHardCopy}
                      </p>
                    )}
                  </div>
                </div>
                {/* <div>
                <label htmlFor="remarks" className="fs-10 fw-bold text-start py-2">
                  Enter your Remarks
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  placeholder="Enter your Remarks"
                  className="form-control fs-12"
                  style={{ width: '100%' }}
                />
                {errors.remarks && <span style={{ color: 'red', fontSize: '12px' }}>{errors.remarks}</span>}
              </div> */}
                <div>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Upload Your File
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    name="drawingFileName"
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%", height: "43px" }}
                  />
                  {errors.drawingFileName && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingFileName}
                    </span>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={handleFormSubmit}
                    style={{
                      background: "var(--primary-color)",
                      color: "white",
                      width: "100px",
                      height: "35px",
                      border: "none",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          </DialogContent>
        )}

        {currentTab === "reject" && (
          <DialogContent
            style={{
              padding: "25px",
              minWidth: "300px",
              minHeight: "300px",
              maxHeight: "70vh",
              overflowY: "auto",
            }}
          >
            <>
              <Loader
                open={apiProcessing.loader}
                message={apiProcessing.message}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  rowGap: "20px",
                  columnGap: "10px",
                  padding: "0 30px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <div>Request Reason</div>
                <div>:</div>
                <div>{selectedItem ? selectedItem.remarks : "-"}</div>

                <div>Nature of Request Info</div>
                <div>:</div>
                <div>
                  {selectedItem
                    ? selectedItem.natureOfRequestedInformation &&
                      selectedItem.natureOfRequestedInformation.length > 0
                      ? selectedItem.natureOfRequestedInformation.map(
                          (item, index) => (
                            <div key={index} style={{ display: "inline" }}>
                              {item}
                              {index <
                                selectedItem.natureOfRequestedInformation
                                  .length -
                                  1 && ", "}
                              <br />
                            </div>
                          )
                        )
                      : "-"
                    : "-"}
                </div> */}
                <div>Nature of Requested Information Reasons</div>
                <div>:</div>
                <div>
                  {selectedItem?.natureOfRequestedInformationReasons &&
                  selectedItem.natureOfRequestedInformationReasons.length > 0
                    ? selectedItem.natureOfRequestedInformationReasons.map(
                        (item, index) => (
                          <div key={index}>
                            <span>{item.natureOfRequest}</span>- {item.reason}
                            <br />
                          </div>
                        )
                      )
                    : "-"}
                </div>
              </div>
              <div style={{ padding: "10px 30px" }}>
                <div style={{ flex: "1" }}>
                  <label
                    htmlFor="reason"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Enter your Reason
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                    placeholder="Could you please provide the reason for rejecting this request?"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                  {errors.reason && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.reason}
                    </span>
                  )}
                </div>
                <div style={{ flex: "1" }}>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Upload Your File
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,"
                    name="drawingFileName"
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%", height: "43px" }}
                  />
                  {errors.drawingFileName && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingFileName}
                    </span>
                  )}
                </div>
              </div>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={handleFormSubmit}
                    style={{
                      background: "var(--primary-color)",
                      color: "white",
                      width: "100px",
                      height: "35px",
                      border: "none",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          </DialogContent>
        )}
      </Dialog>

      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogContent style={{ minHeight: "100px" }}>
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <p style={{ textAlign: "center" }}> Are you sure want to close ?</p>
          </>
        </DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <button
            style={{
              background: "var(--primary-color)",
              color: "white",
              width: "100px",
              height: "35px",
              border: "none",
            }}
            onClick={handleConfirmDialogClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "var(--primary-color)",
              color: "white",
              width: "100px",
              height: "35px",
              border: "none",
            }}
            onClick={handleConfirmSubmit}
          >
            Submit
          </button>
        </div>
      </Dialog>

      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 10,
            fontFamily: "Poppins",
            // borderRadius: "10px",
            background: "#FFF",
            // boxShadow: "0px 0px 10px 0px #F3F3F3",
            // width: '922px',height: '1060px'
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <p
            style={{width:"100%",marginTop:"0px", textAlign: "center", color: "white" ,fontSize:"20px"}}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingId?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingId?.designDrawingConsultant?.role ||
                  "NA"}
              </div>

              <div>Design Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingId?.category?.category || "NA"}
              </div>

              <>
                {showAddFolder ? (
                  <>
                    <div>Folder</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData?.drawingId?.folderId?.folderName ||
                        "NA"}
                    </div>
                  </>
                ) : (
                  console.log("Add Folder Name is hidden. consultant RFI")
                )}
              </>

              <div>Drawingn Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingId?.drawingTitle || "NA"}
              </div>

              <div>Requested Date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.requestedDate
                  ? new Date(singleDrawingData.requestedDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              <div>Expected Date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.expectedDate
                  ? new Date(singleDrawingData.expectedDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              <div>Nature of Request Info</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData
                  ? singleDrawingData.natureOfRequestedInformation &&
                    singleDrawingData.natureOfRequestedInformation.length > 0
                    ? singleDrawingData.natureOfRequestedInformation.map(
                        (item, index) => (
                          <div key={index} style={{ display: "inline" }}>
                            {item}
                            {index <
                              singleDrawingData.natureOfRequestedInformation
                                .length -
                                1 && ", "}
                            <br />
                          </div>
                        )
                      )
                    : "-"
                  : "-"}
              </div>
              {singleDrawingData?.remarks && (
                <>
                  <div>Reason</div>
                  <div>:</div>
                  <div style={{ marginLeft: "-120px" }}>
                    {singleDrawingData?.remarks || "NA"}
                  </div>
                </>
              )}
              <div>Status</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.status || "NA"}
              </div>

              {singleDrawingData &&
                singleDrawingData?.status === "Rejected" && (
                  <>
                    <div>Rejected Reason</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData?.reason || "NA"}
                    </div>
                  </>
                )}

              <div>Revision</div>
              <div>:</div>
              {latestRevision ? (
                <div style={{ marginLeft: "-120px" }}>
                  {latestRevision.revision}
                </div>
              ) : (
                <div>-</div>
              )}

              <div>Requested Drawing File </div>
              <div>:</div>
              <div style={{ display: "flex", marginLeft: "-120px" }}>
              <img src={Gallery}
                  size={20}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={() => handleViewFile(singleDrawingData?._id, "",latestRevision.revision)}
                />
                <img src={Download}
                  size={20}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                  onClick={() => handleDownload(singleDrawingData?._id, "")}
                />
              </div>
              {singleDrawingData &&
                singleDrawingData?.status === "Rejected" && (
                  <>
                    <div>Rejected Drawing File </div>
                    <div>:</div>
                    <div style={{ display: "flex", marginLeft: "-120px" }}>
                      <img src={Gallery}
                        size={20}
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() =>
                          handleViewFile(singleDrawingData?._id, "Rejected",latestRevision.revision)
                        }
                      />
                      <img src={Download}
                        size={20}
                        style={{ marginRight: "5px", cursor: "pointer" }}
                        onClick={() =>
                          handleDownload(singleDrawingData?._id, "Rejected")
                        }
                      />
                    </div>
                  </>
                )}
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RequestedTable;
