import React, { useState, useEffect  } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { RxCross1 } from "react-icons/rx";
import './AddLevel.css';
import { FaCirclePlus } from "react-icons/fa6";
import { AiFillMinusCircle } from "react-icons/ai";
import { Dialog, DialogContent } from "../../Dialog/Dialog";

const AddLevel = (props) => {
  const { open, handleDialogClose, existingData,levelEdit} = props;
  console.log(existingData,"existing Data in add LLevel")
  let webApi = new WebApimanager();
  const [noOfChecklist, setNoOfChecklist] = useState(''); 
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const departmentData = {
    Management: {
      roles: [
        "Company Managing Director",
        "Director",
        "Technical President",
        "Senior Vice President",
        "Vice President",
        "Assistant Vice President",
      ],
    },
    "Design Consultant": {
      roles: [
        "Archiectural",
        "Structural",
        "MEP",
        "Facade",
        "Landscape and Lighting",
        "Vertical Transportation",
        "Traffic and Parking",
      ],

    },

    Execution: {
      roles: [
        "Deputy Project Manager",
        "Assistant Project Manager",
        "Assistant Manager",
        "Sr.Project Engineer",
        "Project Engineer",
        "Jr.Project Engineer",
        "Senior Engineer",
        "Engineer",
        "Junior Engineer",
        "GTE",
      ],
  
    },
    Planning: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],

    },
    SiteManagement: {
      role: [
        "General Manager",
        "Assistant General Manager",
        "Senior Project Manager",
        "Project Manager",
      ],

    },
    QualitySurveyorBilling: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],

    },
    MEP: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Supervisors",
      ],

    },
    EHS: {
      roles: [
        "Head",
        "Manager",
        "Assistant Manager",
        "Safety Incharge",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Steve Ward",
        "Male Nurse",
      ],
  
    },
    CRMTECH: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],

    },
    Store: {
      roles: [
        "Head",
        "Store Manager",
        "Assistant Store Manager",
        "Store Officer",
        "Store Incharge",
        "Sr.Store Keeper",
        "Store Keeper/ Executive",
        "Sr.Store Asst",
        "Store Asst",
        "Trainee Store",
      ],
   
    },
    Survey: {
      roles: ["Sr.Engineer", "Engineer", "Jr.Engineer", "GTE", "Helper"],
    },
    Accounts: {
      roles: [
        "Head",
        "Manager",
        "Sr.Accountant",
        "Accountant",
        "Jr.Accountant",
      ],
   
    },
    Admin: {
      roles: [
        "Head",
        "Project Admin",
        "Ass Admin",
        "Time Keeper",
        "Colony Admin",
      ],
    },
  };
  const [levels, setLevels] = useState([]);
  const [errors, setErrors] = useState([]);
  const [globalErrors, setGlobalErrors] = useState({
    noOfChecklist: '',
    
  });
  

  const handleClose = () => {
    handleDialogClose();
    setNoOfChecklist('');
    setLevels([]);
    setErrors([]);
    setGlobalErrors({ noOfChecklist: '' });
  };
  // const handleLevelChange = (index, field, value) => {
  //   const updatedLevels = [...levels];
  //   updatedLevels[index] = {
  //     ...updatedLevels[index],
  //     [field]: value,
  //   };
  //   setLevels(updatedLevels);

  //   validateLevel(index, field, value);
  // };
  const handleLevelChange = (index, field, value) => {
    // Create a copy of the current levels state
    const updatedLevels = [...levels];
    

    // Clear the role if the department is being changed
    if (field === "department") {
      updatedLevels.forEach((level, i) => {
        updatedLevels[i] = {
            ...level,
            ["department"]: value,
            ["role"]: "", // Clear the role when department changes
        };
    });
    } else {
        // Update the specific field
        updatedLevels[index] = {
            ...updatedLevels[index],
            [field]: value,
        };
    }

    // Log the updatedLevels after making changes
    console.log(updatedLevels, "updatedLevels111");
    

    // Update the state with the new levels
    setLevels(updatedLevels);
    
    
    // Validate after setting the updated levels
    validateLevel(index, field, value);
    
    
};
  
  const validateLevel = (index, field, value) => {
    const updatedErrors = [...errors];

    if (!value) {
      updatedErrors[index] = {
        ...updatedErrors[index],
        [field]: `${field} is required`,
      };
    } else {
      if (updatedErrors[index]) {
        updatedErrors[index][field] = '';
      }
    }

    setErrors(updatedErrors);
  };


  const validateGlobalFields = () => {
    let valid = true;
    const globalErrors = {};

    if (!noOfChecklist || noOfChecklist <= 0) {
      globalErrors.noOfChecklist = "Number of Checklist is required";
      valid = false;
    }

    

    setGlobalErrors(globalErrors);
    return valid;
  };

  

  const handleSubmit = async(e) => {
    e.preventDefault();

    let formIsValid = true;
    const newErrors = [];
    levels.forEach((level, index) => {
      const levelErrors = {};
      if (!level?.department) {
        formIsValid = false;
        levelErrors.department = "Department is required";
      }
      if (!level?.role) {
        formIsValid = false;
        levelErrors.role = "Role is required";
      }
      if (!level?.user) {
        formIsValid = false;
        levelErrors.user = "User is required";
      }
      newErrors[index] = levelErrors;
    });

    setErrors(newErrors);

    const globalFieldsValid = validateGlobalFields();

    if (formIsValid && globalFieldsValid) {
      console.log("Form is valid", levels);
      const tempFormData = {
        degreeOfAuthorityForChecklistApproval:noOfChecklist,
        levelsInfo: levels.map((lev, index) => ({
          level: `L${index + 1}`,
          department: lev.department,
          role: lev.role,
          user: lev.user
      }))
    }
    console.log(tempFormData,"tempFormData")
      try{
        const response = await webApi.post(`api/checklistWorkFlow`,tempFormData)

      } catch(error){
        console.log("error post call",error.message)
      }
    } else {
      console.log("Form has errors");
      console.log(errors,"errors")
    }
  };

  // Handle adding a new level
  const addLevel = () => {
    setLevels([...levels, { department: "", role: "", user: "" }]);
    setNoOfChecklist((prev) => (prev === '' ? 1 : Number(prev) + 1)); // Increment or set to 1
  };

  const removeLevel = (index) => {
    const updatedLevels = levels.filter((_, i) => i !== index);
    const updatedErrors = errors.filter((_, i) => i !== index);

    setLevels(updatedLevels);
    setErrors(updatedErrors);
    setNoOfChecklist((prev) => (prev > 0 ? Number(prev) - 1 : ''));
  };
  useEffect(() => {
    if (levelEdit === true) {
      console.log(existingData,)
      setNoOfChecklist(existingData.degreeOfAuthorityForChecklistApproval)
      setLevels(existingData.levelsInfo.map(level => ({
        level: level.level,
        department: level.department,
        role: level.role,
        user: level.user._id, 
        _id: level._id
      })));
    }
  }, [levelEdit]);
  
  useEffect(() => {
    levels.forEach((level, index) => {
      console.log(`Level ${index + 1} department: ${level.department}`);
      console.log(`Roles for ${level.department}:`, departmentData[level.department]?.roles);
      console.log(`levelUser${index}`,level.user)
      
      const fetchUsers = async(department,role,index)=>{

        try {
          const response = await webApi.get(`api/checklistWorkFlow/users?department=${department}&role=${role}`);
          console.log("responsesss",response)
          console.log("firstName",response.data.data.users[0].firstName)
          
          const updatedLevels = [...levels];
          updatedLevels[index] = {
            ...updatedLevels[index],
            fetchedUsers: response.data.data.users,  
          };
          console.log(updatedLevels, 'updatedLevels')
          setLevels(updatedLevels);
        } catch(error) {
          console.log("error::AddLevel",error.message)
        }
    }
    console.log('level?.fetchedUsers', level?.fetchedUsers)
      if(!(level?.fetchedUsers) && level.department && level.role ){
        fetchUsers(level.department,level.role,index);
      }
    });
  }, [levels]);
  
 
  //   const fetchUsers = async (department, role, index) => {
  //     try {
  //       const response = await webApi.get(`api/checklistWorkFlow/users?department=${department}&role=${role}`);
  //       console.log("response:", response);
  
  //       // Prevent unnecessary state updates
  //       setLevels((prevLevels) => {
  //         const updatedLevels = [...prevLevels];
  //         if (updatedLevels[index].fetchedUsers !== response.data.users) {
  //           updatedLevels[index] = {
  //             ...updatedLevels[index],
  //             fetchedUsers: response.data.users,
  //           };
  //         }
  //         return updatedLevels;
  //       });
  //     } catch (error) {
  //       console.log("error::AddLevel", error.message);
  //     }
  //   };
  
  //   levels.forEach((level, index) => {
  //     if (level.department && level.role && !level.fetchedUsers) {
  //       // Only fetch users if they have not been fetched already
  //       console.log(`Level ${index + 1} department: ${level.department}`);
  //       console.log(`Roles for ${level.department}:`, departmentData[level.department]?.roles);
  //       fetchUsers(level.department, level.role, index);
  //     }
  //   });
  // }, [levels]);
  
  return (
   

    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 500px", textAlign: "center", color: "white" }}
          >
            Create
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "300px" }}
        >
          <form onSubmit={handleSubmit}>
            <p>Q: Choose the degree for authority for Checklist Approval</p>
            <div className="ChecklistInputContainer">
              <input
                type="text"
                name="noOfChecklist"
                value={noOfChecklist}
                placeholder="Please Enter Number"
                className="ChecklistInput"
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setNoOfChecklist(value === '' ? '' : Number(value));
                    setLevels(
                      Array.from({ length: Number(value) }, () => ({
                        department: "",
                        role: "",
                        user: "",
                      }))
                    );
                    setErrors(Array.from({ length: Number(value) }, () => ({})));
                  }
                }}
              />
              <FaCirclePlus
                style={{ color: "#e47331", fontSize: "30px", cursor: "pointer" }}
                onClick={addLevel}
              />
              {globalErrors.noOfChecklist && (
                <span className="error">{globalErrors.noOfChecklist}</span>
              )}
            </div>

            {noOfChecklist > 0 && (
              <div className="levelsMainContainer">
                <div className="levelsHeadContainer">
                  <p style={{ width: "100px" }}></p>
                  <p style={{ textAlign: "left", width: "25%" }}>Department</p>
                  <p style={{ textAlign: "left", width: "25%" }}>Role</p>
                  <p style={{ textAlign: "left", width: "25%" }}>User</p>
                </div>
                {levels.map((level, index) => (
                  <div className="levelsHeadContainer" key={index}>
                    <p style={{ width: "100px" }}>Level {index + 1}:</p>
                    <div className="InputContainer">
                  <select
                    value={level.department }
                    onChange={(e) => {
                      const selectedDepartment = e.target.value;
                      handleLevelChange(index, "department", selectedDepartment);
                    }}
                    style={{width:'100%'}}
                  >
                    <option value="">Select Department</option>
                    {Object.keys(departmentData).map((department) => (
                      <option key={department} value={department}>
                        {department}
                      </option>
                    ))}
                  </select>
                  {errors[index]?.department && (
                    <span className="error">{errors[index].department}</span>
                  )}
                </div>
                    {/* <div className="InputContainer">
                    
                      <select
                    value={level.role}
                    onChange={(e) =>
                      handleLevelChange(index, "role", e.target.value)
                    }
                    disabled={!level.department}
                    style={{width:'100%'}}
                  >
                    <option value="">Select Role</option>
                    {level.department &&
                      departmentData[level.department]?.roles.map((role) => (
                        <option key={role} value={role}>
                          {role}
                        </option>
                      ))}
                  </select>
                      {errors[index]?.role && (
                        <span className="error">{errors[index].role}</span>
                      )}
                    </div> */}
                       <div className="InputContainer">
                       <select
                        value={level.role}
                        onChange={(e) => handleLevelChange(index, "role", e.target.value)}
                        disabled={!level.department}
                        style={{ width: '100%' }}
                      >
                        <option value="">Select Role</option>
                        {level.department && console.log(departmentData[level.department]?.roles)} {/* Debugging */}
                        {level.department &&
                          departmentData[level.department]?.roles.map((role) => (
                            <option key={role} value={role}>
                              {role}
                            </option>
                          ))}
                      </select>

                      {errors[index]?.role && (
                        <span className="error">{errors[index].role}</span>
                      )}
                    </div>

                    <div className="InputContainer">
                    <select
                      value={level.user}
                      onChange={(e) => handleLevelChange(index, "user", e.target.value)}
                      disabled={!level.role}  
                      style={{ width: '100%' }}
                    >
                      <option value="">Select User</option>
                      {level?.fetchedUsers && level.fetchedUsers?.map((user) => (
                        <option key={user} value={user._id}>
                          {user.firstName +" "+ user.lastName}
                        </option>
                      ))}
                    </select>
                      {errors[index]?.user && (
                        <span className="error">{errors[index].user}</span>
                      )}
                    </div>
                    <AiFillMinusCircle
                      style={{ fontSize: "30px", cursor: "pointer" }}
                      onClick={() => removeLevel(index)}
                    />
                  </div>
                ))}
              </div>
            )}

            {/* <p style={{ marginTop: "30px" }}>Q: Timeline</p>
            <p>Note: How long does it take to reassign an approval task to another person if the primary person is unavailable?</p>
            <p>Enter the number of days:</p>
            <div className="ChecklistInputContainer">
              <input
                type="text"
                name="noOfDays"
                value={noOfDays}
                placeholder="Please Enter Number"
                className="ChecklistInput"
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setNoOfDays(value);
                  }
                }}
              />
              {globalErrors.noOfDays && (
                <span className="error">{globalErrors.noOfDays}</span>
              )}
            </div> */}

            <div className="BtnContainer">
              <button type="submit">Submit</button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddLevel;
