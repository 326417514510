import React, { useEffect, useState } from 'react'
import TableWrapper from '../../../Utilities/TableWrapper'
import "./WorkFlow.css"
import { Tab, Tabs } from '../../Tabs/Tabv1';
import * as Icon from "react-bootstrap-icons";
import Filter from "../../../Images/Filter.svg";
import ToggleSubModuleSwitch from '../../Sites/ToggleSubModuleSwitch';
import { WebApimanager } from '../../../WebApiManager';
import { allUsersInformations, siteId, userInfo } from '../../../atoms';
import { useRecoilState } from "recoil";
import { RiDeleteBin5Fill } from "react-icons/ri";

const WorkFlowSiteHead = ({customizedView}) => {

  let webApi = new WebApimanager();

  const [site, setSite] = useRecoilState(siteId);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false)
  const [architectRoles,setArchitectRoles] = useState([])
  const [department,setDepartment] = useState('')
  const [datas,setDatas] = useState([])
  const [existingConsultants,setExistingConsultants] = useState([])
  const [existingCategories, setExistingCategories] = useState([])
  const [selectedConsultant, setSelectedConsultant] = useState([]);
  const [selectedRole,setSelectedRole] = useState([])
  const [filteredRole,setFilteredRole] = useState([])
  const [errors, setErrors] = useState({})
  const [deptError,setDeptError] = useState('')
  const [formData, setFormData] = useState({
    designConsultant: "",
    DrawingCategory: [],
  });
  const [currentuserInfo,setCurrentUserInfo] = useRecoilState(userInfo);
  const [allUserInfo, setAllUsersInfo] = useRecoilState(allUsersInformations)

  const [sites, setDatasites] = useState([]);
  const [isCheckedRFI,setIsCheckedRFI] = useState(false)
  const [isCheckedFrwd,setIsCheckedFrwd] = useState(false)
  const [isCheckedAny,setIsCheckedAny]= useState(false)
  const [assignedData,setAssignedData] = useState([])
  const [deptId,setDeptId] = useState('')

  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    
  }

  useEffect(()=>{
    setDepartment("")
    setDatas([])
    setExistingConsultants([])
    setExistingCategories([])
    setSelectedConsultant([])
    setSelectedRole([])
    setFormData({designConsultant: "",
      DrawingCategory: [],})
      setIsCheckedRFI(false)
      setIsCheckedFrwd(false)
      setIsCheckedAny(false)
      setAssignedData([])
    setErrors({})
    setDeptError('')
  },[site])

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  

 
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear categories and set consultant
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "designConsultant" && { DrawingCategory: [] }), // Clear categories if consultant changes
    }));

    // Fetch new categories based on the selected consultant
    if (name === "designConsultant") {
      setSelectedConsultant(value);
    }
  };
  const handleCategoryChange = (index, value) => {
    setSelectedRole((prev)=>[...prev,value])
    const newCategories = [...formData.DrawingCategory];
    newCategories[index] = value;
    setFormData({ ...formData, DrawingCategory: newCategories });
  };

  const fetchRoUserDepartment = async(site)=>{
    try {
      const response = await webApi.get(`api/drawingWorkFlow/siteHeadTrue?siteId=${site}`)
      console.log(response,'response for RO department user')
      if(response.status === 200)
        setDatas(response.data.data.users)
        setExistingConsultants([...new Set(response.data.data.users.filter((item) => item.department !== "Design Consultant").map((item)=>item.department))])
        if(response.data.data.users.length && department){
          console.log(response.data.data.users,"SiteHeadUserInfo")
          response.data.data.users.forEach((user) => {
            user.permittedSites.forEach((siteItem) => {
              if (siteItem.siteId === site) {
                const { siteHeadDetails } = siteItem.enableModules.drawingDetails || {};
                if (siteHeadDetails) {
                  if (siteHeadDetails.rfiRaisedAccess) setIsCheckedRFI(true);
                  if (siteHeadDetails.forwardAccess) setIsCheckedFrwd(true);
                }
              }
            });
          });
        } else {
          setIsCheckedRFI(false);
          setIsCheckedFrwd(false);
        }
    } catch (error) {
      console.log(error.message)
    }
  }

  
  useEffect(()=>{
    
      fetchRoUserDepartment(site);
    
    
  },[site,department])


 

  

  

  const handleForm1Submit =(e)=>{
    e.preventDefault();
    let isValid = true;
    const newErrors = {};

    if (!department) {
      newErrors.department = "Department is required";
      isValid = false;
    }

    setDeptError(newErrors.department || '');
    if (!isValid) {
      setErrors(newErrors);
      return;
    }
    

  }


  const handleForm2Submit = async(e) => {
    e.preventDefault();
console.log("jdfskjdn ")
    // Validation for department
    let valid = true;
    const newErrors = {};

    if (!formData.designConsultant?.trim()) {
      newErrors.designConsultant = "Department is required";
      valid = false;
    }
    if (!formData.DrawingCategory.length) {
      newErrors.DrawingCategory = "At least one Drawing Category must be selected";
      valid = false;
    } else {
      const hasEmptyCategory = formData.DrawingCategory.some((cat) => cat === "");
    if (hasEmptyCategory) {
      newErrors.DrawingCategory = "Each Drawing Category must have a valid value";
      valid = false;
    }
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    try {
      const payload = {
        siteId:site,
        department:formData.designConsultant,
        module:"siteHead",
        designConsultants:formData.DrawingCategory,
      }
      const response = await webApi.post(`api/assignDesignConsultants`,payload)
      if(response.status === 200){
        console.log(response,"response for the post call")
        window.location.reload()
      }
      
    } catch (error) {
      console.log(error.message)
    }

    
  }
  const handleAddField = () => {
    setFormData({
      ...formData,
      DrawingCategory: [...formData.DrawingCategory, ""],
    });
  };
  const handleRemoveField = (index) => {
    const newCategories = formData.DrawingCategory.filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, DrawingCategory: newCategories });
  };

  const onDelete = (categoryId,site) => {
    const apiEndpoint = `api/assignDesignConsultants/designConsultant?assignDesignConsultantsToDepartmentId=${deptId}&designConsultantId=${categoryId}`;

    webApi
      .delete(apiEndpoint)
      .then((response) => {
        console.log(" deleted successfully:", response.data);
        // getAll();
        // fetchCategories();
        fetchViewConsultantData(site)
        fetchViewCategories()
        fetchRoUserDepartment(site)
        
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  };

  const fetchViewConsultantData = async(site)=>{
    try {
      const response = await webApi.get(`api/designDrawingConsultant/getAll?siteId=${site}`)
      console.log(response.data.data.users,"response for view")
      if(response.status === 200){
        const allRoles = response.data.data.users.map((user)=>[user._id,user.role])

        console.log(assignedData,"assignedData")
        const assignedRoles = assignedData?.map(item => item._id);
        const filteredData =  allRoles.filter(user => !assignedRoles.includes(user[0]))
        
        console.log(filteredData,"filteredData")
        // setExistingCategories(filteredData)
        // setExistingCategories(response.data.data.users)
        setExistingCategories(filteredData)
      }
    } catch (error) {
      console.log(error.message)
      
    }
  }

  useEffect(()=>{
    if(formData.designConsultant ){
      fetchViewConsultantData(site)
      fetchViewCategories()
    }
    
  },[formData,site,])

  // useEffect(()=>{
  //   const filteredData = existingCategories.length && existingCategories.filter((cat)=>!(selectedRole.includes(cat.role)))
  //   setFilteredRole(filteredData)
  // },[selectedRole,existingCategories])
  const fetchViewCategories = async () =>{
    try {
      const response = await webApi.get(`api/assignDesignConsultants/designConsultantsByDepartment?department=${formData.designConsultant}&module=siteHead`)
      console.log(response,"response in the view cat")
      if(response.status === 200){
        console.log(response.data.data.assignments,"response.data.data.assignments")
        setDeptId(response.data.data?.assignments[0]?._id)
        const datas = response.data.data.assignments?.filter((data)=>data.module === "siteHead")
        console.log(datas,"datas")
        setAssignedData(datas[0].designConsultants)
      }

    } catch (error) {
      console.log(error.message)
      
    }
  }

  // useEffect(()=>{
  //   if(currentView === "RO" && formData.designConsultant)
  //   fetchViewCategories(site)
  // },[site,selectedRole,formData])
  useEffect(()=>{
    console.log(existingCategories,"existingCategories")
    console.log(assignedData,"assignedData")
  },[existingCategories])

  
  const handleToggle = (val) => {
    if(department){
    if (val === 0) {
      setIsCheckedAny((prev) => {
        const newState = !prev;
        if (newState) {
          setIsCheckedRFI(true);
          setIsCheckedFrwd(true);
        } else {
          setIsCheckedRFI(false);
          setIsCheckedFrwd(false);
        }
        return newState;
      });
    }
    
    if (val === 1) {
      setIsCheckedRFI((prev) => {
        const newState = !prev;
        if (newState) {
          setIsCheckedAny(true);
        } else if (!isCheckedFrwd) {
          setIsCheckedAny(false);
        }
        return newState;
      });
    }
  
    if (val === 2) {
      setIsCheckedFrwd((prev) => {
        const newState = !prev;
        if (newState) {
          setIsCheckedAny(true);
        } else if (!isCheckedRFI) {
          setIsCheckedAny(false);
        }
        return newState;
      });
    }
  }
  };
  


  useEffect(() => {
    if (isCheckedRFI || isCheckedFrwd) {
      setIsCheckedAny(true);
    } else {
      setIsCheckedAny(false);
    }
  }, [isCheckedRFI, isCheckedFrwd]);

  const updateDetails =  async (datas,site)=>{
    const payload={rfiRaisedAccess:isCheckedRFI,forwardAccess:isCheckedFrwd}
      const response = await webApi.put(`api/drawingWorkFlow/updateModules/${datas[0]._id}?siteId=${site}&module=siteHeadDetails`,payload)
      if(response.status === 200){
        // window.location.reload()
        const info = allUserInfo.map((item)=>{
          if(item._id === datas[0]._id){
            return {
              ...item,
          permittedSites: item?.permittedSites?.map((siteItem) => {
            if (siteItem.siteId?._id === site) {
              const updatedSiteHeadDetails = {
                ...siteItem.enableModules?.drawingDetails?.siteHeadDetails,
                rfiRaisedAccess: isCheckedRFI, 
                forwardAccess: isCheckedFrwd,  
              };
        
              return {
                ...siteItem,
                enableModules: {
                  ...siteItem.enableModules,
                  drawingDetails: {
                    ...siteItem.enableModules?.drawingDetails,
                    siteHeadDetails: updatedSiteHeadDetails
                    ,
                  },
                },
              };
            }
            return siteItem; 
          })
            }
          } else {
            return item
          }
        })
         
        setAllUsersInfo(info)
        console.log(info,"updatedUserInfo in siteHead")
      }
  }
  // useEffect(()=>{
  //   if(department ){
  //     const updatedUserInfo = produce(currentuserInfo, (draft) => {
  //       const siteItem = draft.permittedSites.find((item) => item.siteId === site);
  //       if (siteItem) {
  //         siteItem.enableModules.drawingDetails.siteHeadDetails.rfiRaisedAccess = isCheckedRFI;
  //         siteItem.enableModules.drawingDetails.siteHeadDetails.forwardAccess = isCheckedFrwd;
  //       }
  //     });
      
  //     setCurrentUserInfo(updatedUserInfo);
  //   }

  // },[isCheckedRFI, isCheckedFrwd])
  useEffect(()=>{
    if(department)
    updateDetails(datas,site)

  },[isCheckedRFI, isCheckedFrwd])



  return (
    <div className="DWFMainBoxesContainer" >
    <div className="DWFBoxContainer">

      <form style={{width:"100%",border:"1px solid #979797",paddingBottom:"40px",height:"max-content"}} onSubmit={handleForm1Submit} >
          <div className="DWFBoxHeadingContainer">
              <p>View Access To Department</p>
          </div>

          <div className="DWFCatMainContent">
              <label>Select Department &nbsp; &nbsp; *</label>
              <select name="department" value={department} onChange={(e)=>setDepartment(e.target.value)}>
              <option value="">Select Department</option>
                  {existingConsultants.map((dept)=>(<option value={dept}>{dept}</option>))}
              </select>
              {deptError &&  <p style={{ textAlign:"left",  color: "red", fontSize: "14px" }}>{deptError}</p>}
              <div className="DWFAccessMainContainer">
                  <label>Are you giving access for the Department</label>
                  
                  <ToggleSubModuleSwitch isChecked={isCheckedAny} setWidth={true}
                        onToggle={() =>
                          handleToggle(0)
                        }
                      />
                  
              </div>
             

          </div>
          <div style={{width:"100%",display:"flex",padding:" 0px 20px",height:"30px",alignItems:"center",marginTop:"10px"}}>
                  <label style={{fontSize:"16px"}}>RFI Raised Access</label>
                  <ToggleSubModuleSwitch isChecked={isCheckedRFI} showArrow={false} onToggle={() =>
                          handleToggle(1)
                        }/>
          </div>
          <div style={{width:"100%",display:"flex",padding:" 0px 20px",height:"30px",alignItems:"center",marginTop:"10px"}}>
                  <label style={{fontSize:"16px"}}>Forward Access &nbsp; &nbsp;</label>
                  <ToggleSubModuleSwitch isChecked={isCheckedFrwd} showArrow={false} onToggle={() =>
                          handleToggle(2)
                        }/>
          </div>
          {/* <div style={{width:"100%",display:"flex",justifyContent:"center",marginTop:"50px"}}>
              <button style={{padding:"6px 10px",color:"white",backgroundColor:"#e47331"}}>SUBMIT</button>
          </div> */}

      </form>
     {isCheckedRFI && <div style={{marginTop:"30px",padding:"20px 0px",width:"100%",border:"1px solid #979797",height:"max-content"}}>
        <label style={{padding:"0px 30px",textAlign:"start",width:"100%"}}>RFI Rasied Access</label>
        <div style={{marginInline:"20px",border:"1px dotted #979797"}}></div>
        <div style={{marginTop:"30px" ,width:"100%",display:"flex",justifyContent:"space-between",padding:"0px 20px"}}>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>UserName</p>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>Role</p>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>Employee ID</p>
        </div>
        <div style={{marginInline:"20px",border:"1px dotted #979797"}}></div>
        { datas.length && datas.filter((data)=>data.department === department).map((item)=>(<div key={item._id} style={{marginTop:"30px" ,width:"100%",display:"flex",justifyContent:"space-between",padding:"0px 20px"}}>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.firstName}</p>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.role}</p>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.empId}</p>
        </div>))}

      </div>}

       {isCheckedFrwd && <div style={{marginTop:"30px",padding:"20px 0px",width:"100%",border:"1px solid #979797",height:"max-content"}}>
        <label style={{padding:"0px 30px",textAlign:"start",width:"100%"}}>Forward Access</label>
        <div style={{marginInline:"20px",border:"1px dotted #979797"}}></div>
        <div style={{marginTop:"30px" ,width:"100%",display:"flex",justifyContent:"space-between",padding:"0px 20px"}}>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>UserName</p>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>Role</p>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>Employee ID</p>
        </div>
        <div style={{marginInline:"20px",border:"1px dotted #979797"}}></div>


        { datas.length && datas.filter((data)=>data.department === department).map((item)=>(<div key={item._id} style={{marginTop:"30px" ,width:"100%",display:"flex",justifyContent:"space-between",padding:"0px 20px"}}>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.firstName}</p>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.role}</p>
          <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.empId}</p>
        </div>))}
      </div>}
    </div>
    {customizedView &&
    <div className="DWFBoxContainer">
      <form style={{width:"100%",border:"1px solid #979797",paddingBottom:"40px",height:"max-content"}} className="DWFBoxContainer" onSubmit={handleForm2Submit}>
        <   div className="DWFBoxHeadingContainer">
                <p>Add Consultant to the Department</p>
            </div>
            <div style={{width:"100%",marginTop:"30px",padding:"0px 20px",display:"flex",justifyContent:"space-between"}}>
              <label style={{fontSize:"16px",marginTop:"4px"}}>Select Department</label>
              <button style={{padding:"6px 10px",backgroundColor:"#e47331",color:"white",display:"flex",alignItems:"center",borderRadius:"5px"}} onClick={handleAddField}> <Icon.PlusCircle size={14} style={{ marginRight: "5px" }} />{" "}Add </button>
            </div>
            <div className="DWFSecondBoxInputContainer">
              <select name="designConsultant" value={formData.designConsultant} onChange={handleChange}>
                  <option value="">Select Department</option>
                    {existingConsultants.map((dept)=>(<option value={dept}>{dept}</option>))}
              </select>
              {errors.designConsultant ? <p style={{ textAlign:"left",  color: "red", fontSize: "14px" }}>{errors.designConsultant}</p>:(errors.DrawingCategory && formData.DrawingCategory.length ===0) ? <p style={{ textAlign:"left",  color: "red", fontSize: "14px" }}>{errors.DrawingCategory}</p>:""}
            </div>
            {formData.DrawingCategory && formData.DrawingCategory.map((category,index)=>(<div key={category.id} style={{ width: "100%", marginTop: "30px", padding: "0px 20px", display: "flex", justifyContent: "space-between",position:"relative" }}>
              <div style={{ width: "5%", height: "45px", backgroundColor: "#f3f3f3", outline: "none", border: "1px solid #97979740", color: "black", paddingTop: "10px",position:"relative" }}>{index+1}</div>
              <select 
                className="DWFinputFeild" 
                placeholder="Enter category" 
                type="text" 
                value={category}
                onChange={(e) =>
                handleCategoryChange(index, e.target.value)

      }
              >
                <option value="">Select Category</option>
                {existingCategories &&
                  existingCategories.map((cat) => (
                    <option key={cat[0]} value={cat[0]}>
                      {cat[1]}
                    </option>
                  ))}
              </select>
              {(errors.DrawingCategory && formData.DrawingCategory.length) && <p style={{ color: "red", fontSize: "14px",position:"absolute",top:"100%" }}>{errors.DrawingCategory}</p>}
              <Icon.DashCircleFill
                size={20}
                style={{
                  cursor: "pointer",
                  color: "red",
                  marginLeft: "5px",
                  position:"absolute",
                  right:"0px",
                  top:"10px"
                }}
                onClick={() => handleRemoveField(index)}
              />
            </div>))}
          
            {formData.DrawingCategory.length? <div style={{width:"100%",display:"flex",justifyContent:"center",marginTop:"50px"}}>
                <button style={{padding:"6px 10px",color:"white",backgroundColor:"#e47331"}}>SUBMIT</button>
            </div>:""}
        
      </form>

      <div style={{width:"100%",border:"1px solid #979797",paddingBottom:"40px",height:"max-content",marginTop:"20px"}} className="DWFBoxContainer" onSubmit={handleForm2Submit}>
        <   div className="DWFBoxHeadingContainer">
                <p>View Consultant to the Department</p>
            </div>
            {assignedData.length ? assignedData?.map((assData)=>(<div style={{width:"100%"}}>
              <div style={{display:"flex",justifyContent:"space-between",width:"100%",marginTop:"10px",padding:"0px 20px"}}>
                <p>{assData.role}</p>
                <RiDeleteBin5Fill style={{fontSize:"20px",cursor:"pointer"}} onClick={()=>onDelete(assData._id,site)}/>
              </div>
              <div style={{marginInline:"20px",border:"1px dotted #979797"}}></div>
              
              
            </div>)):""}
        
      </div>
    </div>
}
  </div>
  )
}

export default WorkFlowSiteHead