/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Stepper, StepLabel, Step } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../WebApiManager";
import { Loader } from "../../Widgets/notificationFeedbacks";
import ToggleSwitch from "./ToggleSwitch";
import "./Dialog.module.css";
import { siteId, userInfo } from "../../atoms";
import { useRecoilState } from "recoil";
import "./sites.css";
import { Dialog, DialogContent } from "../Dialog/Dialog";
import styles from "./AddCompanyDialog.module.css"

const AddCompanyDialog = (props) => {
  const { open, close, companyData } = props;
  const webApi = new WebApimanager();
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);

  useEffect(() => {
    console.log("User Info State company dialouge:", currentUserInfo);
    console.log("companyData company dialouge:", companyData);
  }, []);

  const indiaStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Andaman and Nicobar Islands",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman & Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Jammu & Kashmir",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [company, setCompany] = useState([]);
  const [siteID, setSite] = useRecoilState(siteId);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [formData, setFormData] = useState({
    companyName: "",
    ownerName: "",
    phoneNumber: "",
    email: "",
    gstNo: "",
    panNo: "",
    name: "",
    empId: "",
    abbr:"",
    designation: "",
    mailId: "",
    cphoneNumber: "",
    officeAddress: "",
    country: "",
    cityState: "",
    pinCode: "",
    industry: "",
    websiteURL: "",
    description: "",
    GSTNO: null,
    CPAN: null,
    CTAN: null,
    agreementDocument: null,
    drawing: false,
    pm: false,
    qaqc: false,
    ehs: false,
    qs: false,
    planner: false,
    hr: false,
    user: false,
    store: false,
    admin: false,
    dashboard: true,
    workStatus: false,
    checkList: false,
    space: false,
    task: false,
    company: true,
    communication: false,
    drawingAddFolder: false,
    folder: "",
    checklistProcess: "",
    hrProcess: "",
    firstName: "",
    lastName: "",
    adminemail: "",
    adminphoneNumber: "",
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (companyData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        companyName: companyData?.companyDetails?.companyName || "",
        ownerName: companyData?.companyDetails.ownerName || "",
        phoneNumber: companyData?.companyDetails.phoneNo || "",
        email: companyData?.companyDetails.companyMailingaddress || "",
        gstNo: companyData?.companyDetails.gstNo || "",
        panNo: companyData?.companyDetails.panNo || "",
        name: companyData?.personalInfo.name || "",
        designation: companyData?.personalInfo.designation || "",
        mailId: companyData?.personalInfo.emailId || "",
        cphoneNumber: companyData?.personalInfo.phNo || "",
        officeAddress: companyData?.companyAddress.officeAddress || "",
        country: companyData?.companyAddress.country || "",
        cityState: companyData?.companyAddress.cityOrState || "",
        pinCode: companyData?.companyAddress.pinCode || "",
        industry: companyData?.companyAddress.industry || "",
        websiteURL: companyData?.companyAddress.webSiteUrl || "",
        description: companyData?.companyAddress.description || "",
        GSTNO: companyData?.GSTNO?.gstNo || null,
        CPAN: companyData?.CPAN?.companyPanNo || null,
        CTAN: companyData?.CTAN?.companyTanNo || null,
        agreementDocument: companyData?.agreementDocument || null,
        drawing: companyData?.companyEnableModules?.drawings || false,
        dashBoard: companyData?.companyEnableModules?.dashBoard || false,
        pm: companyData?.companyEnableModules?.pAndM || false,
        qaqc: companyData?.companyEnableModules?.qaAndQc || false,
        ehs: companyData?.companyEnableModules?.ehs || false,
        qs: companyData?.companyEnableModules?.qs || false,
        planner: companyData?.companyEnableModules?.planner || false,
        hr: companyData?.companyEnableModules?.hr || false,
        user: companyData?.companyEnableModules?.user || false,
        store: companyData?.companyEnableModules?.store || false,
        admin: companyData?.companyEnableModules?.admin || false,
        dashboard: companyData?.companyEnableModules?.dashboard || false,
        drawingAddFolder:
          companyData?.companyEnableModules?.drawingAddFolder || false,
        workStatus: companyData?.companyEnableModules?.workStatus || false,
        checkList: companyData?.companyEnableModules?.checkList || false,
        space: companyData?.companyEnableModules?.space || false,
        task: companyData?.companyEnableModules?.task || false,
        company: companyData?.companyEnableModules?.company || false,
        communication:
          companyData?.companyEnableModules?.communication || false,
        folder: companyData.folder || "",
        checklistProcess: companyData.checklistProcess || "",
        hrProcess: companyData.hrProcess || "",
        firstName: companyData?.firstName || "",
        lastName: companyData?.lastName || "",
        adminemail: companyData?.adminemail || "",
        adminphoneNumber: companyData?.adminphoneNumber || "",
      }));
    }
  }, [companyData]);
  const validateField = (name, value) => {
    let errorMessage = "";

    switch (name) {
      case "companyName":
      case "ownerName":
      case "name":
        // case "firstName":
        // case "lastName":
        const isValidName = /^[A-Za-z\s]+$/.test(value);
        errorMessage = value.trim()
          ? isValidName
            ? ""
            : `${name
                .replace(/([A-Z])/g, " $1")
                .trim()} should contain letters only.`
          : `Please enter ${name.replace(/([A-Z])/g, " $1").trim()}.`;
        break;
      case "email":
      case "mailId":
        // case "adminemail":
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        errorMessage = value.trim()
          ? isValidEmail
            ? ""
            : "Please enter a valid email address."
          : "Please enter an email address.";
        break;
      case "phoneNumber":
      case "cphoneNumber":
        // case "adminphoneNumber":
        const isValidPhoneNumber = /^\d{10}$/.test(value);
        errorMessage = value.trim()
          ? isValidPhoneNumber
            ? ""
            : "Phone number must be exactly 10 digits."
          : "Please enter a phone number.";
        break;
      case "pinCode":
        const isValidPinCode = /^\d{6}$/.test(value);
        errorMessage = value.trim()
          ? isValidPinCode
            ? ""
            : "PinCode must be exactly 6 digits."
          : "Please enter a PinCode.";
        break;
      case "gstNo":
        const isValidGSTNo =
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
            value
          );
        errorMessage = value.trim()
          ? isValidGSTNo
            ? ""
            : "Please enter a valid GST Number."
          : "Please enter a GST Number.";
        break;
      case "panNo":
        const isValidPAN = /^[A-Z]{5}\d{4}[A-Z]$/.test(value);
        errorMessage = value.trim()
          ? isValidPAN
            ? ""
            : "PAN enter valied PAN Number."
          : "Please enter a PAN No.";
        break;
      // case "websiteURL":
      //   errorMessage = value.trim() ? "" : "Please enter a website URL.";
      //   break;
      case "designation":
      case "officeAddress":
      case "country":
      case "cityState":
      case "industry":
      case "description":
        errorMessage = value.trim()
          ? ""
          : `Please enter ${name.replace(/([A-Z])/g, " $1").trim()}.`;
        break;
      default:
        break;
    }

    return errorMessage;
  };
  const validateFields = () => {
    const newErrors = {};

    if (activeStep === 0) {
      Object.keys(formData).forEach((key) => {
        const errorMessage = validateField(key, formData[key]);
        if (errorMessage) {
          newErrors[key] = errorMessage;
        }
      });

      // Additional validations outside the loop
      if (!formData.websiteURL) {
        newErrors.websiteURL = "Please enter a website URL.";
      }
      if (!formData.empId) {
        newErrors.empId = "Please enter a Company ID";
      }
      if(!formData.abbr){
        newErrors.abbr =  "enter Abbrevation in 3 words "
      }
    } else if (activeStep === 1) {
      if (!formData.GSTNO) newErrors.GSTNO = "Please upload GST No File.";
      if (!formData.CPAN) newErrors.CPAN = "Please upload Company PAN File.";
      if (!formData.CTAN) newErrors.CTAN = "Please upload Company TAN No File.";
      if (!formData.agreementDocument)
        newErrors.agreementDocument = "Please upload Agreement Document.";
    } else if (activeStep === 2) {
      if (formData.checkList && !formData.checklistProcess) {
        newErrors.checklistProcess =
          "Please select whether the checklist process is Manual or Automatic.";
      }
      if (formData.hr && !formData.hrProcess) {
        newErrors.hrProcess =
          "Please select whether the HR process is Manual or Automatic.";
      }

      setErrors(newErrors);
    } else if (activeStep === 3) {
      const nameRegex = /^[A-Za-z\s-]+$/;
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const phoneRegex = /^\d{10}$/;
      if (!nameRegex.test(formData.firstName)) {
        newErrors.firstName = "Please enter a valid first name";
      }

      if (!nameRegex.test(formData.lastName)) {
        newErrors.lastName = "Please enter a valid last name";
      }
      if (!formData.adminemail) {
        newErrors.adminemail = "Please enter the email";
      } else if (!emailRegex.test(formData.adminemail)) {
        newErrors.adminemail = "Please enter a valid email address";
      }
      if (!formData.adminphoneNumber) {
        newErrors.adminphoneNumber = "Please enter the phone number";
      } else if (!phoneRegex.test(formData.adminphoneNumber)) {
        newErrors.adminphoneNumber =
          "Please enter a valid 10-digit phone number";
      }
    }

    console.log("Form Data:", formData);

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const errorMessage = validateField(name, value);

    if (name === "cphoneNumber") {
      if (/^\d{0,10}$/.test(value)) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (name === "pinCode") {
      if (/^\d{0,6}$/.test(value)) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleToggle = (key) => {
    setFormData((prevData) => {
      let newData = { ...prevData, [key]: !prevData[key] };
      if (key === "planner" && !prevData.qaqc) {
        newData.qaqc = true;
      }
      if (key === "planner" && prevData.qaqc) {
        newData.qaqc = false;
      }

      return newData;
    });
  };
  const handleFolderProcessChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      folder: event.target.value,
    }));
  };
  const handleChecklistProcessChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      checklistProcess: event.target.value,
    }));
  };
  const handleHRProcessChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      hrProcess: event.target.value,
    }));
  };
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    let errorMessage = "";
    if (files.length > 0) {
      const file = files[0];

      if (file.type === "application/pdf") {
        setFormData((prevValues) => ({
          ...prevValues,
          [name]: file,
        }));
      } else {
        errorMessage = `Please upload a PDF file.`;
      }
    } else {
      errorMessage = `Please upload ${
        name.charAt(0).toUpperCase() + name.slice(1)
      }.`;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const handleNext = () => {
    const isValid = validateFields();

    if (!isValid) {
      return;
    }

    if (activeStep === 4) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setIsBackButtonClicked(true);
    validateFields();
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    setApiProcessing({
      loader: true,
      message: "Submitting data...",
    });

    try {
      const response = await webApi.post("api/users/company/user", {
        companyKeyWord:formData.abbr,

        companyDetails: {
          companyName: formData.companyName,
          ownerName: formData.ownerName,
          companyMailingaddress: formData.email,
          phoneNo: formData.phoneNumber,
          gstNo: formData.gstNo,
          panNo: formData.panNo,
        },
        personalInfo: {
          name: formData.name,
          designation: formData.designation,
          emailId: formData.mailId,
          phNo: formData.cphoneNumber,
        },
        companyEnableModules: {
          drawings: formData.drawing,
          pAndM: formData.pm,
          qaAndQc: formData.qaqc,
          checkList: formData.checkList,
          ehs: formData.ehs,
          qs: formData.qs,
          planner: formData.planner,
          hr: formData.hr,
          user: formData.user,
          store: formData.store,
          admin: formData.admin,
          space: formData.space,
          task: formData.task,
          company: formData.company,
          dashBoard: formData.dashboard,
          drawingAddFolder: formData.drawingAddFolder,
          communication: formData.communication,
          workStatus: formData.workStatus,
          ...(formData.checkList && {
            checklistProcess: formData.checklistProcess,
          }),
          ...(formData.hr && { hrProcess: formData.hrProcess }),
        },
        userDetails: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          contactNumber: formData.adminphoneNumber,
          email: formData.adminemail,
          role: "Admin",
          department: "Company Admin",
          empId: formData.empId,
          permittedSites: [],
        },

        companyAddress: {
          officeAddress: formData.officeAddress,
          country: formData.country,
          cityOrState: formData.cityState,
          pinCode: formData.pinCode,
          industry: formData.industry,
          webSiteUrl: formData.websiteURL,
          description: formData.description,
        },
      });

      console.log("responseData", response);

      if (response.status === 201 && response.data.data.company._id) {
        const id = response.data.data.company._id;
        console.log("Company ID:", id);

        const documentData = {
          gstNo: formData.GSTNO,
          companyPanNo: formData.CPAN,
          companyTanNo: formData.CTAN,
          agreementDocument: formData.agreementDocument,
        };

        const uploadResponses = await Promise.all(
          Object.entries(documentData).map(([key, value]) => {
            return webApi.imagePut(`api/company/documents/${id}`, {
              [key]: value,
            });
          })
        );

        const allUploadsSuccessful = uploadResponses.every(
          (uploadResponse) => uploadResponse.status === 200
        );

        if (allUploadsSuccessful) {
          console.log("All documents uploaded successfully.");
          handleClose();
          window.location.reload();
        } else {
          setErrorMessage("Failed to upload some files. Please try again.");
          console.log("Upload error:", uploadResponses);
        }
      } else if (response.status === 200) {
        const responseData = response.data;
        setErrorMessage(responseData.message || "Validation error occurred.");
        console.log("Validation error:", responseData.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
        console.log("Unexpected response:", response);
      }
    } catch (error) {
      if (error.response) {
        const errorResponse = error.response.data;
        setErrorMessage(errorResponse.message || "Error occurred.");
        console.log("Error:", errorResponse.message);
      } else {
        setErrorMessage("Failed to submit data.");
        console.error("Submission error:", error.message);
      }
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleClose = () => {
    close();
    setFormData({});
    setErrorMessage();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={false}
      PaperProps={{
        style: {
          width: "90vw", 
          maxWidth: "90vw",
          borderRadius: 10,
          fontFamily: "Poppins",  
          backgroundColor: "white",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: " #4D4D4D",
          margin: "0px",
        }}
      >
        <p style={{ margin: "5px 600px", textAlign: "center", color: "white" }}>
          Company Details
        </p>
        <RxCross1
          size={20}
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            marginRight: "10px",
            color: "white",
          }}
          onClick={handleClose}
        />
      </div>

      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{
          borderBottom: "1px dotted lightgray",
          paddingBottom: "20px",
          margin: "10px 0px",
          marginTop: "16px",
          marginBottom: "16px",
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <Step
          style={{
            fontSize: "40px",
            minWidth: "80px",
            backgroundColor: "white",
          }}
        >
          <StepLabel
            StepIconComponent={() => (
              <div
                style={{
                  width: "25px",
                  // height: "25px",
                  borderRadius: "50%",
                  border: `2px solid #4C4C4C`,
                  display: "flex",
                  justifyContent: "center",
                  border: `2px solid ${
                    activeStep === 0 || activeStep === 1 ? "#4C4C4C" : "#979797"
                  }`,
                  alignItems: "center",
                  fontSize: "18px",
                  color: activeStep === 0 ? "#fff" : "#4C4C4C",
                  backgroundColor: activeStep === 0 ? "#4C4C4C" : "transparent",
                }}
              >
                1
              </div>
            )}
          >
            Company Details
          </StepLabel>
        </Step>
        <Step style={{ fontSize: "40px", minWidth: "80px" }}>
          <StepLabel
            StepIconComponent={() => (
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  border: `2px solid ${activeStep < 2 ? "#4C4C4C" : "#979797"}`,
                  alignItems: "center",
                  fontSize: "18px",
                  color: activeStep === 1 ? "#fff" : "#4C4C4C",
                  backgroundColor: activeStep === 1 ? "#4C4C4C" : "transparent",
                }}
              >
                2
              </div>
            )}
          >
            Company Documents
          </StepLabel>
        </Step>
        <Step style={{ fontSize: "40px", minWidth: "80px" }}>
          <StepLabel
            StepIconComponent={() => (
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  border: `2px solid #4C4C4C`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  color: activeStep === 2 ? "#fff" : "#4C4C4C",
                  backgroundColor: activeStep === 2 ? "#4C4C4C" : "transparent",
                }}
              >
                3
              </div>
            )}
          >
            Enable Modules
          </StepLabel>
        </Step>
        <Step style={{ fontSize: "40px", minWidth: "80px" }}>
          <StepLabel
            StepIconComponent={() => (
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  border: `2px solid #4C4C4C`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  color: activeStep === 3 ? "#fff" : "#4C4C4C",
                  backgroundColor: activeStep === 3 ? "#4C4C4C" : "transparent",
                }}
              >
                4
              </div>
            )}
          >
            Admin User Details
          </StepLabel>
        </Step>
        <Step style={{ fontSize: "40px", minWidth: "80px" }}>
          <StepLabel
            StepIconComponent={() => (
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  border: `2px solid #4C4C4C`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  color: activeStep === 4 ? "#fff" : "#4C4C4C",
                  backgroundColor: activeStep === 4 ? "#4C4C4C" : "transparent",
                }}
              >
                5
              </div>
            )}
          >
            Confirm Details
          </StepLabel>
        </Step>
      </Stepper>

      <DialogContent
        style={{
          padding: "5px",
          minWidth: "300px",
          minHeight: "300px",
          maxHeight: "65vh",
          overflowY: "auto",
        }}
      >
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            justifyItems: "center",
            margin: "0 80px",
          }}
        >
          {activeStep === 0 ? (
            <>
              <div className={styles["comp-main-container"]}>
                <div className={styles["comp-main-container"]}>
                  <h3>Company Details</h3>
                  <div
                    style={{
                      borderBottom: "1px dotted lightgray",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: "20px",
                      paddingBottom: "40px",
                      width:"100%"
                    }}
                  >
                    <div style={{ display: "flex",justifyContent:"space-between",width:"100%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                          width:"49%"
                        }}
                      >
                        <label>
                          Company Name<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="companyName"
                          placeholder="Enter Company Name"
                          value={formData.companyName}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.companyName && (
                          <p style={{ color: "red" }}>{errors.companyName}</p>
                        )}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column",width:"49%" }}>
                        <label>
                          Owner Name<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="ownerName"
                          placeholder="Enter Owner Name"
                          value={formData.ownerName}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                          pattern="[A-Za-z\s]+"
                          onKeyPress={(e) => {
                            if (!/[A-Za-z\s]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors.ownerName && (
                          <p style={{ color: "red" }}>{errors.ownerName}</p>
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "16px",justifyContent:"space-between",width:"100%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                          width:"49%"
                        }}
                      >
                        <label>
                          Company Mailing Address
                          <a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter Company Email"
                          value={formData.email}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.email && (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width:"49%"
                        }}
                      >
                        <label>
                          Phone Number<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="phoneNumber"
                          placeholder="Enter Phone Number"
                          maxLength={10}
                          value={formData.phoneNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              /^\d{0,10}$/.test(value) &&
                              value.length <= 10
                            ) {
                              handleChange(e);
                            }
                          }}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.phoneNumber && (
                          <p style={{ color: "red" }}>{errors.phoneNumber}</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "16px",
                        justifyContent:"space-between",
                        width:"100%"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                          width:"49%"
                        }}
                      >
                        <label>
                          GST No.<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="gstNo"
                          placeholder="Enter GST No"
                          value={
                            formData.gstNo ? formData.gstNo.toUpperCase() : ""
                          }
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.gstNo && (
                          <p style={{ color: "red" }}>{errors.gstNo}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width:"49%"
                        }}
                      >
                        <label>
                          PAN No<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="panNo"
                          placeholder="Enter PAN No"
                          value={
                            formData.panNo ? formData.panNo.toUpperCase() : ""
                          }
                          onChange={(e) => {
                            const input = e.target.value.toUpperCase();

                            // Allow only letters in the first 5 characters
                            const lettersPart1 = input
                              .slice(0, 5)
                              .replace(/[^A-Z]/g, "");

                            // Allow only digits in the next 4 characters
                            const digitsPart = input
                              .slice(5, 9)
                              .replace(/[^0-9]/g, "");

                            // Allow only a letter in the last position
                            const lettersPart2 = input
                              .slice(9, 10)
                              .replace(/[^A-Z]/g, "");

                            // Combine all parts and limit the total length to 10 characters
                            const sanitizedInput = (
                              lettersPart1 +
                              digitsPart +
                              lettersPart2
                            ).slice(0, 10);

                            handleChange({
                              target: { name: "panNo", value: sanitizedInput },
                            });
                          }}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.panNo && (
                          <p style={{ color: "red" }}>{errors.panNo}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "20px" ,width:"100%"}}>
                  <h3>Contact Person Details</h3>
                  <div
                    style={{
                      borderBottom: "1px dotted lightgray",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: "20px",
                      paddingBottom: "40px",
                      width:"100%"
                    }}
                  >
                    <div style={{ display: "flex",width:"100%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                          width:"49%"
                        }}
                      >
                        <label>Name</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter Your Name"
                          value={formData.name}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                          pattern="[A-Za-z\s]+"
                          onKeyPress={(e) => {
                            if (!/[A-Za-z\s]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors.name && (
                          <p style={{ color: "red" }}>{errors.name}</p>
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width:"49%"
                        }}
                      >
                        <label>
                          Designation<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="designation"
                          placeholder="Enter Your Designation"
                          value={formData.designation}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.designation && (
                          <p style={{ color: "red" }}>{errors.designation}</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "16px",
                        width:"100%"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                          width:"49%"
                        }}
                      >
                        <label>
                          Mail id<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="email"
                          name="mailId"
                          placeholder="Enter Your Mail Id"
                          value={formData.mailId}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.mailId && (
                          <p style={{ color: "red" }}>{errors.mailId}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width:"49%"
                        }}
                      >
                        <label>
                          Phone Number<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="cphoneNumber"
                          maxLength={10}
                          placeholder="Enter Your Contact Phone Number"
                          value={formData.cphoneNumber}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.cphoneNumber && (
                          <p style={{ color: "red" }}>{errors.cphoneNumber}</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "16px",
                        width:"100%"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                          width:"49%"
                        }}
                      >
                        <label>
                        Company ID<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                        type="text"
                        name="empId"
                        placeholder="Enter Your Company ID"
                        value={formData.empId}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "100%",
                          height: "50px",
                          marginTop: "10px",
                        }}
                      />
                        {errors.empId && (
                        <p style={{ color: "red" }}>{errors.empId}</p>
                      )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width:"49%"
                        }}
                      >
                        <label>
                          Short Keyword for Company<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="abbr"
                          placeholder="Enter Your Contact Phone Number"
                          value={formData.abbr}
                          onChange={(e)=>{
                            if(e.target.value.length<=3){
                              handleChange(e)
                            }
                          }}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.abbr && (
                          <p style={{ color: "red" }}>{errors.abbr}</p>
                        )}
                      </div>
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "80px",
                        marginTop: "10px",
                      }}
                    >
                      <label>Company ID</label>
                      <input
                        type="text"
                        name="empId"
                        placeholder="Enter Your Company ID"
                        value={formData.empId}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "100%",
                          height: "50px",
                          marginTop: "10px",
                        }}
                      />
                      {errors.empId && (
                        <p style={{ color: "red" }}>{errors.empId}</p>
                      )}
                    </div> */}
                  </div>
                </div>

                <div style={{ marginTop: "20px",width:"100%" }}>
                  <h3>Address</h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "20px",
                      paddingBottom: "40px",
                      width:"100%"
                    }}
                  >
                    <label style={{ textAlign: "left" }}>
                      Office Address<a style={{ color: "red" }}>*</a>
                    </label>
                    <textarea
                      type="text"
                      name="officeAddress"
                      placeholder="Enter Your Office Address"
                      value={formData.officeAddress}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        backgroundColor: "#f3f3f3",
                        marginTop: "10px",
                      }}
                    />
                    {errors.officeAddress && (
                      <p style={{ color: "red" }}>{errors.officeAddress}</p>
                    )}
                    <div style={{ display: "flex", marginTop: "16px",justifyContent:"space-between" ,width:"100%"}}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                          width:"49%"
                        }}
                      >
                        <label>
                          Country<a style={{ color: "red" }}>*</a>
                        </label>
                        <select
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        >
                          <option value="">Select Country</option>
                          <option valie="India">India</option>
                        </select>
                        {errors.country && (
                          <p style={{ color: "red" }}>{errors.country}</p>
                        )}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" ,width:"49%"}}>
                        <label>
                          City / State<a style={{ color: "red" }}>*</a>
                        </label>
                        <select
                          name="cityState"
                          value={formData.cityState}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        >
                          {formData.country !== "" && (
                            <>
                              <option value="">Select City / State</option>
                              {indiaStates.map((state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                        {errors.cityState && (
                          <p style={{ color: "red" }}>{errors.cityState}</p>
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "16px",justifyContent:"space-between" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                          width:"49%"
                        }}
                      >
                        <label>
                          PinCode<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="pinCode"
                          placeholder="Enter Your PinCode"
                          maxLength={6}
                          value={formData.pinCode}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.pinCode && (
                          <p style={{ color: "red" }}>{errors.pinCode}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width:"49%"
                        }}
                      >
                        <label>
                          Industry<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="industry"
                          placeholder="Enter Your Industry Type"
                          value={formData.industry}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.industry && (
                          <p style={{ color: "red" }}>{errors.industry}</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "16px",
                        width:"100%",
                        justifyContent:"space-between"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                          width:"49%"
                        }}
                      >
                        <label>
                          Website URL<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="websiteURL"
                          placeholder="Enter Your Website URL"
                          value={formData.websiteURL}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.websiteURL && (
                          <p style={{ color: "red" }}>{errors.websiteURL}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width:"49%"
                        }}
                      >
                        {" "}
                        <label>
                          Description<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="description"
                          placeholder="Enter Your Description"
                          value={formData.description}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "#f3f3f3",
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                        {errors.description && (
                          <p style={{ color: "red" }}>{errors.description}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : activeStep === 1 ? (
            <>
              <section
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  columnGap: "100px",
                  rowGap: "16px",
                  width: "100%",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              >
                <div style={{width:"100%"}}>
                  <label
                    htmlFor="GSTNO"
                    style={{
                      textAlign: "start",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    GST No :<a style={{ color: "red" }}>*</a>
                  </label>
                  <br />
                  <input
                    type="file"
                    id="GSTNO"
                    name="GSTNO"
                    accept=".pdf"
                    className="form-control"
                    style={{
                      width: "100%",
                      height: "42px",
                      fontSize: "12px",
                      backgroundColor: "#f3f3f3",
                      marginTop: "10px",
                    }}
                    onChange={handleFileChange}
                  />
                  {errors.GSTNO && (
                    <p style={{ color: "red" }}>{errors.GSTNO}</p>
                  )}
                </div>
                <div style={{width:"100%"}}>
                  <label
                    htmlFor="CPAN"
                    style={{
                      textAlign: "start",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    Company PAN Number :<a style={{ color: "red" }}>*</a>
                  </label>
                  <br />
                  <input
                    type="file"
                    id="CPAN"
                    name="CPAN"
                    accept=".pdf"
                    className="form-control "
                    style={{
                      width: "100%",
                      height: "42px",
                      fontSize: "12px",
                      backgroundColor: "#f3f3f3",
                      height: "43px",
                      marginTop: "10px",
                    }}
                    onChange={handleFileChange}
                  />
                  {errors.CPAN && <p style={{ color: "red" }}>{errors.CPAN}</p>}
                </div>
                <div style={{width:"100%"}}>
                  <label
                    htmlFor="CTAN"
                    style={{
                      textAlign: "start",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    Company TAN Number :<a style={{ color: "red" }}>*</a>
                  </label>{" "}
                  <br />
                  <input
                    type="file"
                    id="CTAN"
                    name="CTAN"
                    accept=".pdf"
                    className="form-control "
                    style={{
                      width: "100%",
                      height: "42px",
                      fontSize: "12px",
                      marginTop: "10px",
                    }}
                    onChange={handleFileChange}
                  />
                  {errors.CTAN && <p style={{ color: "red" }}>{errors.CTAN}</p>}
                </div>
                <div style={{width:"100%"}}>
                  <label
                    htmlFor="agreementDocument"
                    style={{
                      textAlign: "start",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      height: "43px",
                      marginTop: "10px",
                    }}
                  >
                    Agreement Document :<a style={{ color: "red" }}>*</a>
                  </label>
                  <br />
                  <input
                    type="file"
                    id="agreementDocument"
                    name="agreementDocument"
                    accept=".pdf"
                    className="form-control "
                    style={{
                      width: "100%",
                      height: "42px",
                      fontSize: "12px",
                      backgroundColor: "#f3f3f3",
                      marginTop: "10px",
                    }}
                    onChange={handleFileChange}
                  />
                  {errors.agreementDocument && (
                    <p style={{ color: "red" }}>{errors.agreementDocument}</p>
                  )}
                </div>
                {formData.GSTNO !== null &&
                  formData.CPAN !== null &&
                  formData.CTAN !== null &&
                  formData.agreementDocument !== null &&
                  isBackButtonClicked && (
                    <p style={{ color: "gray", fontSize: "12px" }}>
                      "Note: Files have already been added. If you want to
                      change the files, you can upload new ones."
                    </p>
                  )}
              </section>
            </>
          ) : activeStep === 2 ? (
            <div style={{ marginTop: "20px" }}>
              <h3>Enable Modules</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  paddingBottom: "40px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "10px",
                    alignItems: "center",
                    // justifyItems: "center",
                  }}
                >
                  {[
                    { label: "Drawing", key: "drawing" },
                    { label: "P & M", key: "pm" },
                    { label: "QA/QC", key: "qaqc" },
                    { label: "EHS", key: "ehs" },
                    { label: "QS", key: "qs" },
                    { label: "PLANNER", key: "planner" },
                    { label: "HR", key: "hr" },
                    { label: "USER", key: "user" },
                    { label: "STORE", key: "store" },
                    { label: "ADMIN", key: "admin" },
                    { label: "WorkStatus", key: "workStatus" },
                    { label: "CHECKLIST", key: "checkList" },
                    { label: "SPACE", key: "space" },
                    { label: "COMMUNICATION", key: "communication" },
                    { label: "TASK", key: "task" },
                  ].map((item, index) => (
                    <div
                      key={item.key}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FDF8F4" : "#F3F6F8",
                        padding: "8px",
                        borderRadius: "8px",
                        marginRight: "90px",
                      }}
                    >
                      <ToggleSwitch
                        label={item.label}
                        isChecked={formData[item.key]}
                        onToggle={() => handleToggle(item.key)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {/* {formData.drawing && (
                <div>
                  <label>
                    Q) Is the drawing register being saved in a folder-based
                    structure?
                  </label>
                  <select
                    value={
                      formData.drawing && formData.drawingAddFolder
                        ? "yes"
                        : formData.folder 
                    }
                    onChange={handleFolderProcessChange}
                    style={{ marginLeft: "20px" }}
                  >
                    <option value="">Select Yes/No</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  {errors.checklistProcess && (
                    <p style={{ color: "red" }}>{errors.checklistProcess}</p>
                  )}
                </div>
              )} */}

              {formData.checkList && (
                <div style={{ marginTop: "10px" }}>
                  <label>
                    Q) Is the checklist process Manual or Automatic?
                  </label>
                  <select
                    value={formData.checklistProcess}
                    onChange={handleChecklistProcessChange}
                    style={{ marginLeft: "200px" }}
                  >
                    <option value="">Select Manual/Automatic</option>
                    <option value="manual">Manual</option>
                    <option value="automatic">Automatic</option>
                  </select>
                  {errors.checklistProcess && (
                    <p style={{ color: "red" }}>{errors.checklistProcess}</p>
                  )}
                </div>
              )}
              {formData.hr && (
                <div style={{ marginTop: "10px" }}>
                  <label>Q) Is the HR process Manual or Automatic?</label>
                  <select
                    value={formData.hrProcess}
                    onChange={handleHRProcessChange}
                    style={{ marginLeft: "260px" }}
                  >
                    <option value="">Select Manual/Automatic</option>
                    <option value="manual">Manual</option>
                    <option value="automatic">Automatic</option>
                  </select>
                  {errors.hrProcess && (
                    <p style={{ color: "red" }}>{errors.hrProcess}</p>
                  )}
                </div>
              )}
            </div>
          ) : activeStep === 3 ? (
            <>
              <div style={{width:"100%"}}>
                <h3>Admin User Details</h3>
                <div
                  style={{
                    borderBottom: "1px dotted lightgray",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "20px",
                    paddingBottom: "40px",
                    width:"100%"
                  }}
                >
                  <div style={{ display: "flex",justifyContent:"space-between",width:"100%"}}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "80px",
                        width:"49%"
                      }}
                    >
                      <label>
                        First Name <a style={{ color: "red" }}>*</a>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        placeholder="Enter first Name"
                        value={formData.firstName}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "100%",
                          height: "50px",
                          marginTop: "15px",
                        }}
                        pattern="[A-Za-z\s-]+"
                        title="Only alphabets, spaces, and hyphens are allowed"
                        onKeyDown={(e) => {
                          const regex = /^[A-Za-z\s-]$/;
                          if (!regex.test(e.key) && e.key !== "Backspace") {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.firstName && (
                        <p style={{ color: "red" }}>{errors.firstName}</p>
                      )}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column",width:"49%" }}>
                      <label>
                        Last Name<a style={{ color: "red" }}>*</a>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Enter last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "100%",
                          height: "50px",
                          marginTop: "15px",
                        }}
                        pattern="[A-Za-z\s-]+"
                        title="Only alphabets, spaces, and hyphens are allowed"
                        onKeyDown={(e) => {
                          const regex = /^[A-Za-z\s-]$/;
                          if (!regex.test(e.key) && e.key !== "Backspace") {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.lastName && (
                        <p style={{ color: "red" }}>{errors.lastName}</p>
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex", marginTop: "16px",justifyContent:"space-between",width:"100%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "80px",
                        width:"49%"
                      }}
                    >
                      <label>
                        Admin Mailing Address
                        <a style={{ color: "red" }}>*</a>
                      </label>
                      <input
                        type="email"
                        name="adminemail"
                        placeholder="Enter Admin Email"
                        value={formData.adminemail}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "100%",
                          height: "50px",
                          marginTop: "15px",
                        }}
                      />
                      {errors.adminemail && (
                        <p style={{ color: "red" }}>{errors.adminemail}</p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width:"49%"
                      }}
                    >
                      <label>
                        Phone Number<a style={{ color: "red" }}>*</a>
                      </label>
                      <input
                        type="text"
                        name="adminphoneNumber"
                        placeholder="Enter Phone Number"
                        maxLength={10}
                        value={formData.adminphoneNumber}
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "100%",
                          height: "50px",
                          marginTop: "15px",
                        }}
                      />
                      {errors.adminphoneNumber && (
                        <p style={{ color: "red" }}>
                          {errors.adminphoneNumber}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : activeStep === 4 ? (
            <>
              <div style={{ marginLeft: "10px", marginRight: "80px" }}>
                <div
                  style={{
                    borderBottom: "1px dotted lightgray",
                    paddingBottom: "32px",
                    marginBottom: "16px",
                  }}
                >
                  <h3>Company Details</h3>
                  <div style={{ marginTop: "16px" }}>
                    {[
                      { label: "Company Name", value: formData.companyName },
                      { label: "Owner Name", value: formData.ownerName },
                      {
                        label: "Company Mailing Address",
                        value: formData.email,
                      },
                      { label: "Phone Number", value: formData.phoneNumber },
                      { label: "GST No", value: formData.gstNo },
                      { label: "PAN No", value: formData.panNo },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "600", flex: "1" }}>
                          {field.label}
                        </span>
                        <span style={{ margin: "0 8px" }}>:</span>
                        <span style={{ flex: "2" }}>{field.value}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    borderBottom: "1px dotted lightgray",
                    paddingBottom: "32px",
                    marginBottom: "16px",
                  }}
                >
                  <h3>Contact Person Details</h3>
                  <div style={{ marginTop: "16px" }}>
                    {[
                      { label: "Name", value: formData.name },
                      { label: "Designation", value: formData.designation },
                      { label: "Mail ID", value: formData.mailId },
                      { label: "Phone Number", value: formData.cphoneNumber },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "600", flex: "1" }}>
                          {field.label}
                        </span>
                        <span style={{ margin: "0 8px" }}>:</span>
                        <span style={{ flex: "2" }}>{field.value}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    borderBottom: "1px dotted lightgray",
                    paddingBottom: "32px",
                    marginBottom: "16px",
                  }}
                >
                  <h3>Address</h3>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "16px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontWeight: "600", flex: "1" }}>
                      Office Address
                    </span>
                    <span style={{ margin: "0 8px" }}>:</span>
                    <span style={{ flex: "2" }}>{formData.officeAddress}</span>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    {[
                      { label: "Country", value: formData.country },
                      { label: "City / State", value: formData.cityState },
                      { label: "PinCode", value: formData.pinCode },
                      { label: "Industry", value: formData.industry },
                      { label: "Website URL", value: formData.websiteURL },
                      { label: "Description", value: formData.description },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "600", flex: "1" }}>
                          {field.label}
                        </span>
                        <span style={{ margin: "0 8px" }}>:</span>
                        <span style={{ flex: "2" }}>{field.value}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    borderBottom: "1px dotted lightgray",
                    paddingBottom: "32px",
                    marginBottom: "16px",
                  }}
                >
                  <h3>Company Documents</h3>
                  <div style={{ marginTop: "16px" }}>
                    {[
                      {
                        label: "GST No",
                        value: formData.GSTNO
                          ? formData.GSTNO.name
                          : "Not Uploaded",
                      },
                      {
                        label: "Company PAN Number",
                        value: formData.CPAN
                          ? formData.CPAN.name
                          : "Not Uploaded",
                      },
                      {
                        label: "Company TAN Number",
                        value: formData.CTAN
                          ? formData.CTAN.name
                          : "Not Uploaded",
                      },
                      {
                        label: "Agreement Document",
                        value: formData.agreementDocument
                          ? formData.agreementDocument.name
                          : "Not Uploaded",
                      },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "600", flex: "1" }}>
                          {field.label}
                        </span>
                        <span style={{ margin: "0 8px" }}>:</span>
                        <span style={{ flex: "2" }}>{field.value}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    borderBottom: "1px dotted lightgray",
                    paddingBottom: "32px",
                    marginBottom: "16px",
                  }}
                >
                  <h3>Enable Modules</h3>
                  <div style={{ marginTop: "16px" }}>
                    {[
                      {
                        label: "Drawing",
                        value: formData.drawing ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "P&M",
                        value: formData.pm ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "QA/QC",
                        value: formData.qaqc ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "EHS",
                        value: formData.ehs ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "QS",
                        value: formData.qs ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "Planner",
                        value: formData.planner ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "HR",
                        value: formData.hr ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "User",
                        value: formData.user ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "Store",
                        value: formData.store ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "Admin",
                        value: formData.admin ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "Dashboard",
                        value: formData.dashboard ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "WorkStatus",
                        value: formData.workStatus ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "Checklist",
                        value: formData.checkList ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "Sites",
                        value: formData.site ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "Space",
                        value: formData.space ? "Enabled" : "Not Enabled",
                      },
                      {
                        label: "checklistProcess",
                        value: formData.checklistProcess
                          ? "Enabled"
                          : "Not Enabled",
                      },
                      {
                        label: "hrProcess",
                        value: formData.hrProcess ? "Enabled" : "Not Enabled",
                      },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "600", flex: "1" }}>
                          {field.label}
                        </span>
                        <span style={{ margin: "0 8px" }}>:</span>
                        <span style={{ flex: "2" }}>{field.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div style={{ paddingBottom: "32px", marginBottom: "16px" }}>
                  <h3>Admin User Details</h3>
                  <div style={{ marginTop: "16px" }}>
                    {[
                      {
                        label: "First Name",
                        value: formData.firstName,
                      },
                      {
                        label: "Last Name",
                        value: formData.lastName,
                      },
                      {
                        label: "Mail Id",
                        value: formData.adminemail,
                      },
                      {
                        label: "PhoneNumber",
                        value: formData.adminphoneNumber,
                      },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "600", flex: "1" }}>
                          {field.label}
                        </span>
                        <span style={{ margin: "0 8px" }}>:</span>
                        <span style={{ flex: "2" }}>{field.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="flex justify-between items-center w-full mt-8">
            {activeStep !== 0 && (
              <button
                type="button"
                onClick={handleBack}
                style={{
                  backgroundColor: "#f97316",
                  color: "white",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  borderRadius: "0.25rem",
                  cursor: "pointer",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "#c2410c")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "#f97316")
                }
              >
                BACK
              </button>
            )}

            <button
              type="button"
              onClick={handleNext}
              className="ml-auto"
              style={{
                backgroundColor: "#f97316",
                color: "white",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "0.25rem",
                cursor: "pointer",
              }}
            >
              {activeStep === 4 ? "FINISH" : "NEXT"}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default AddCompanyDialog;
