/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useMemo } from "react";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import * as Icon from "react-bootstrap-icons";
import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import Gallery from "../../../../Images/Gallery.svg"
import { useNavigate } from "react-router-dom";
import pen from "../IconsSiteHead/penIcon.svg";
import { WebApimanager } from "../../../../WebApiManager";
import { siteId, userInfo } from "../../../../atoms";
import { useRecoilState } from "recoil";
import { RxCross1 } from "react-icons/rx";
import { Tab1, Tabs1 } from "../../../Tabs/Tabv2";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import { formatDate } from "../../../../Utilities/DateUtils";
import { processFile } from "../../BackendUtils";
import { Dialog, DialogContent } from "../../../Dialog/Dialog";

import { FaFilter } from "react-icons/fa";
import { canShowFolder } from "../../ShowFolder";
import { MdCancel } from "react-icons/md";
import ConsultantFilter from "../../ConsultantFilter";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", // Optional
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", // Removes the row borders
          },
        },
      },
    },
  },
});

const Collected = ({forwardAccess}) => {
  const webApi = new WebApimanager();
  const [currentUserInfo] = useRecoilState(userInfo);
  const navigate = useNavigate();
  const [issess, setIssuss] = useState([]);
  const [site] = useRecoilState(siteId);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [consultantValue, setConsultantValue] = useState('')
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    if (drawingItem.acceptedSiteHeadHardCopyRevisions.length > 0) {
      setSelectedTab(drawingItem.acceptedSiteHeadHardCopyRevisions.length - 1);
    }
    setViewDialogOpen(true);
  };
  const handleDownload = async (id, revision) => {
    console.log(id, "id of hard copy");
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/hardCopy/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "opening...",
    });
    try {
      // Make the request to get the response based on the updated endpoint
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/hardCopy/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "view", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  useEffect(() => {
    const fetchIssuedData = async () => {
      try {
        console.log("Fetching issued data for site:", site);
        const response = await webApi.get(
          `api/architect/drawingSiteHead/${site}?filterType=collected`
        );

        console.log("Received full response:", response);

        console.log("Status Code:", response.status);
        console.log("Response Headers:", response.headers);
        console.log("Received Data:", response.data);

        if (response.data && response.data.data) {
          console.log("Received Data for Collected File:", response.data.data);
          setIssuss(response.data.data);
        } else {
          console.warn("No 'data' field in the response:", response);
        }
      } catch (error) {
        console.error("Error fetching issued data:", error);

        if (error.response) {
          console.error("Error Response Data:", error.response.data);
          console.error("Error Status Code:", error.response.status);
          console.error("Error Headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error Message:", error.message);
        }
      }
    };

    fetchIssuedData();
  }, [site]);

  const filteredData = issess.filter((item) => {
    return (
      item.drawingNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.drawingTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.designDrawingConsultant?.role
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.category?.category?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const dropdownRef = useRef(null);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isCategoriesDropdownOpen, setCategoriesDropdownOpen] = useState(false);
  const [isFoldersDropdownOpen, setFoldersDropdownOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectedConsultant, setSelectedConsultant] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
    
  };

  const handleType = (type) => {
    if (type === "all") {
      setSelectedCategory("");

      setFilterDropdownOpen(false);
      setCategoriesDropdownOpen(false);
      setFoldersDropdownOpen(false);
    } else {
      setSelectedCategory(type);
      setFilterDropdownOpen(false);
      setFoldersDropdownOpen(false);
    }
  };

  const uniqueFolders = useMemo(() => {
    const folder = issess.map((item) => item.folderId?.folderName);
    return [...new Set(folder)];
  }, [issess]);

  const uniqueCategories = useMemo(() => {
    const categories = issess.map((item) => item.category?.category);
    return [...new Set(categories)];
  }, [issess]);

  const uniqueConsultant = useMemo(() => {
    const consultant = issess.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [issess]);

  console.log(uniqueCategories, uniqueFolders, "SITE Head Upload categories");

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [filteredGoData, setFilteredGoData] = useState(null); // For displaying the result

  const [showFilteredData, setShowFilteredData] = useState(false); // State to control the display

  const handleMouseEnter = (category) => {
    setHoveredCategory(category);
    if (hoverTimeout) clearTimeout(hoverTimeout);
    const timeout = setTimeout(() => {
      setHoveredCategory(null);
    }, 9000);
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) clearTimeout(hoverTimeout);
  };


  const handleConsultantSelect = (consultant) => {
    setSelectedConsultant(consultant)
    setShowFilteredData(false);
      
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(
      (prevSelected) =>
        prevSelected.includes(category)
          ? prevSelected.filter((c) => c !== category) // Remove if already selected
          : [...prevSelected, category] // Add if not selected
    );
    setShowFilteredData(false);
  };

  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
    setShowFilteredData(false);
  };

  const handleGoClick = () => {
    console.log("Selected Consultants:", selectedConsultant);
    console.log("Selected Categories:", selectedCategory);
    console.log("Selected Folders:", selectedFolder);
    if(selectedConsultant || selectedCategory.length || selectedFolder){
      setShowFilteredData(true);
      toggleFilterDropdown();
      }
    };

  useEffect(() => {
    console.log("Selected showFilteredData:", showFilteredData);
  });

  const filteredDataAndUploadData = useMemo(() => {
    if (!showFilteredData) {
    return issess;
  }
    return [...issess].filter((item) =>{ 
      const matchesConsultant = selectedConsultant === "" ||  item?.designDrawingConsultant?.role === selectedConsultant;

    const matchesCategory = selectedCategory.length === 0 ||  selectedCategory.includes(item?.category?.category);

    const matchesFolder = 
      !selectedFolder || item.folderId?.folderName === selectedFolder;

    return matchesConsultant && matchesCategory && matchesFolder; 
  });
  }, [showFilteredData, selectedConsultant, selectedCategory, selectedFolder, issess]);

  const handleClearAll = () =>{
    setSelectedCategory([])
    setSelectedFolder("")
    setSelectedConsultant("")
  }
  const handleFilterCancel = () =>{
    toggleFilterDropdown()
    setShowFilteredData(false)

  }
  const handleRemove = (type, value) => {
    setShowFilteredData(false)

    if (type === "consultant") {
      setSelectedConsultant("");
    } else if (type === "category") {
      setSelectedCategory(selectedCategory.filter((cat) => cat !== value));
    } else if (type === "folder") {
      setSelectedFolder("");
    }
  };

  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const updatedRows = issess.filter((item)=>item.designDrawingConsultant?.role.includes(consultantValue)).map((row, index) => ({
    ...row,
    serialNo: index + 1, 
    drawingNo: row.drawingNo || "-",
    designConsultant: row?.designDrawingConsultant?.role || "-",
    drawingCategory: row.category?.category || "-",
    drawingTitle: row.drawingTitle || "-",
    roHardCopySubmissionDate: row.acceptedROSubmissionDate
    ? new Date(row.acceptedROSubmissionDate).toLocaleDateString()
    : "N/A",
    noOfHardcopyReceived: row.acceptedSiteHeadHardCopyRevisions?.length > 0
    ? row.acceptedSiteHeadHardCopyRevisions[
        row.acceptedSiteHeadHardCopyRevisions.length - 1
      ].receivedCopies
    : "N/A",
    revision: row.acceptedSiteHeadHardCopyRevisions?.length > 0
    ? row.acceptedSiteHeadHardCopyRevisions
        .slice(-1)
        .map((rev) => rev.revision)
        .join(", ")
    : "N/A",
  }));

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = useMemo(() => [
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 0.5,
      filterable: false, 
      sortable: false, 
    },
    {
      field: "drawingNo",
      headerName: "Drawing No",
      flex: 1,
      filterable: true,
          sortable: true,
    },
    {
      field: "designConsultant",
      headerName: "Design Consultant",
      flex: 1,
      filterable: true,
          sortable: true,
    },
    {
      field: "drawingCategory",
      headerName: "Drawing Category",
      flex: 1.5,
      filterable: true,
          sortable: true,
    },
    {
      field: "drawingTitle",
      headerName: "Drawing Title",
      flex: 2,
      filterable: true,
          sortable: true,
    },
    {
      field: "roHardCopySubmissionDate",
      headerName: "R0 Hard Copy Submission Date",
      flex: 1.5,
      filterable: true,
          sortable: true,
      
    },
    {
      field: "noOfHardcopyReceived",
      headerName: "No Of Hardcopy Received",
      flex: 1,
      filterable: true,
          sortable: true,
      
    },
    {
      field: "revision",
      headerName: "Revision",
      flex: 1,
      filterable: true,
          sortable: true,
     
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 0.5,
      filterable: false, // Disable filtering for actions
      renderCell: (params) => (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <img
            src={Eye}
            alt="view"
            onClick={() => handleViewOpen(params.row)}
            style={{ cursor: "pointer", marginTop: "24px" }}
          />
          <img
            src={Download}
            alt="download"
            onClick={() => {
              const latestRevision =
                params.row.acceptedSiteHeadHardCopyRevisions.length > 0
                  ? params.row.acceptedSiteHeadHardCopyRevisions[
                      params.row.acceptedSiteHeadHardCopyRevisions.length - 1
                    ].revision
                  : null;
              handleDownload(params.row.drawingId, latestRevision);
            }}
            style={{ cursor: "pointer", marginTop: "24px" }}
          />
        </div>
      ),
    },
  ], [issess]);
  


  return (
    <>
      <div
        style={{
          display: "flex",
          float: "right",
          margin: forwardAccess?"-54px 140px":"-54px 10px",
          gap: "0px",
        }}
      >
        <input
          placeholder="Search Drawing Lists"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            paddingLeft: "20px",
            border: "1px solid var(--primary-color)",
            width: "250px",
            height: "40px",
            marginRight: "10px",
          }}
        />
        {/* <div ref={dropdownRef} style={{ position: "relative" }}>
          <div onClick={toggleFilterDropdown}>
            <FaFilter
              size={39}
              style={{
                border: "1px solid var(--primary-color)",
                padding: "5px",
                marginRight: "-10px",
              }}
            />
          </div>
          {isFilterDropdownOpen && (
            <div
              style={{
                position: "absolute",
                background: "white",
                borderRadius: "4px",
                zIndex: 1000,
                textAlign: "left",
                marginTop: "20px",
                marginLeft: "-558px",
                padding: "15px",
                width: "500px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: selectedConsultant || selectedCategory.length || selectedFolder?"space-between":"flex-end",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginTop:"10px"
                }}
              >
                
                <MdCancel style={{position:"absolute",fontSize:"23px" ,right:"-10px",top:"-24px",cursor:"pointer"}}onClick={handleFilterCancel} />
                {(selectedConsultant || selectedCategory.length || selectedFolder) && (
                    <button
                      style={{
                        padding: "6px 10px",
                        backgroundColor: "crimson",
                        color: "white",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearAll}
                    >
                      Clear All
                    </button>
                  )}
                  <button
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                      fontSize: "16px",
                      padding: "5px 15px",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginBottom: "5px",
                    }}
                    onClick={handleGoClick}
                  >
                    Go
                  </button>
                  
                
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginBottom: "15px",
                }}
              >
                {selectedConsultant && (
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("consultant", selectedConsultant)}
                  >
                    {selectedConsultant} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                )}

                {selectedCategory.map((category, index) => (
                  <button
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("category", category)}
                  >
                    {category} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                ))}

                {selectedFolder && (
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffd700",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove("folder", selectedFolder)}
                  >
                    {selectedFolder} <MdCancel style={{ marginLeft: "5px" }} />
                  </button>
                )}
              </div>

              <div style={{ flex: 3 }}>
                <span style={{ cursor: "pointer", position: "relative" }}>
                  <p style={{ color: "black", fontSize: "20px" }}>Consultant</p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "8px",
                    }}
                  >
                    {uniqueConsultant.map((consultant, index) => (
                      <div
                        key={index}
                        style={{
                          flex: "1 1 calc(25.33% - 15px)",
                          backgroundColor:
                            selectedConsultant === consultant ? "#ffd700" : "#e0f7fa",
                          fontWeight:
                            selectedConsultant === consultant ? "700" : "500",
                          borderRadius: "4px",
                          textAlign: "center",
                          fontSize: "14px",
                          padding: "5px",
                          color: selectedConsultant === consultant ? "white" : "#333",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() => handleConsultantSelect(consultant)}
                      >
                        {consultant}
                      </div>
                    ))}
                  </div>
                </span>

                        <span style={{ cursor: "pointer", position: "relative" }}>
                          <p style={{ color: "black", fontSize: "20px" }}>Categories</p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                            }}
                          >
                            {uniqueCategories.map((category, index) => (
                              <div
                                key={index}
                                style={{
                                  flex: "1 1 calc(25.33% - 15px)",
                                  backgroundColor:  selectedCategory.includes(category)?"#ffd700":"#e0f7fa",
                                  fontWeight: selectedCategory.includes(category)?"700":"500",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: "5px",
                                  color: selectedCategory.includes(category)?"white":"#333",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() => handleCategorySelect(category)}
                              >
                                {category}
                              </div>
                            ))}
                          </div>
                        </span>

                        {showAddFolder?<span style={{ cursor: "pointer", position: "relative" }}>
                          <p
                            style={{
                              color: "black",
                              fontSize: "20px",
                              marginTop: "10px",
                            }}
                          >
                            Folders
                          </p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                            }}
                          >
                            {uniqueFolders.map((folder, index) => (
                              <div
                                key={index}
                                style={{
                                  flex: "1 1 calc(25.33% - 15px)",
                                  backgroundColor: "#e0f7fa",
                                  fontWeight: selectedFolder === folder ? "700" : "500",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: "5px",
                                  color: "#333",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() => handleFolderSelect(folder)}
                              >
                                {folder}
                              </div>
                            ))}
                          </div>
                        </span>:""}
                        <>
                          
                        </>
                      </div>
                    </div>
                  )}
        </div> */}
      </div>

      <div style={{ marginTop: "10px" }}>
                       
        <Box
          className="dataGridContainer"
        >
          <ThemeProvider theme={theme}>
          <DataGrid
            columns={columns}
            rows={filteredRows}
            getRowId={(row) => row.drawingId}
            rowHeight={60}
            disableColumnMenu={false} // Keep the column menu for filtering
            pagination={false}
            hideFooter={true}
            getRowSpacing={params=>({
              top:params.isFirstVisible ? 0 : 5,
              bottom:params.isLastVisible ? 0 : 5
            })}
            
            getRowClassName={(params) =>
              "customHeader" 
            }
            
          />
          </ThemeProvider>
        </Box>


      </div>

      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <p
            style={{
              width: "100%",
              textAlign: "center",
              color: "white",
              marginTop: "15px",
              fontSize: "18px",
            }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              overflowX: "auto",
              maxWidth: "230px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0",
                border: "1px solid var(--primary-color)",
              }}
            >
              {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length >
              0 ? (
                <Tabs1 value={selectedTab} onChange={handleTabChange}>
                  {singleDrawingData.acceptedSiteHeadHardCopyRevisions.map(
                    (revisionItem, index) => (
                      <Tab1
                        key={index}
                        value={index}
                        label={`${revisionItem.revision}`}
                        style={{
                          backgroundColor:
                            singleDrawingData.acceptedSiteHeadHardCopyRevisions
                              .length === 1 || selectedTab === index
                              ? "var(--primary-color)"
                              : "transparent",
                          color:
                            singleDrawingData.acceptedSiteHeadHardCopyRevisions
                              .length === 1 || selectedTab === index
                              ? "white"
                              : "var(--primary-color)",
                          width: "150px",
                          height: "35px",
                          cursor: "pointer",
                          borderRight:
                            index <
                            singleDrawingData.acceptedSiteHeadHardCopyRevisions
                              .length -
                              1
                              ? "1px solid var(--primary-color)"
                              : "none",
                          // borderRadius: index === 0 && !isSingleRevision ? '5px 0 0 5px' : (index === singleDrawingData.acceptedRORevisions.length - 1 && !isSingleRevision ? '0 5px 5px 0' : 'none'),
                        }}
                      />
                    )
                  )}
                </Tabs1>
              ) : (
                <div>No Revisions</div>
              )}
            </div>
          </div>
        </div>
        {/* {singleDrawingData?.acceptedROHardCopyRevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {singleDrawingData?.acceptedROHardCopyRevisions?.length >
                0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedTab ===
                  singleDrawingData.acceptedROHardCopyRevisions.length -
                    1 ? (
                    <div style={{ color: "green" }}>Good For Construction</div>
                  ) : (
                    <div style={{ color: "red" }}>Superseeded</div>
                  )}
                </div>
              )}
            </div>
          </>
        )} */}

        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "70vh",
            overflowY: "auto",
          }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.designDrawingConsultant?.role || "NA"}
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>

              <>
               {showAddFolder? (
                  <>
                   
                    <div>Folder</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData?.folderId?.folderName || "NA"}
                    </div>
                  </>
                ) : (
                  console.log("Add Folder Name is hidden. site head ")
                )}
              </>

              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>

              <div>Site Submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedSiteSubmissionDate
                  ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              <div>Hard Copy Submitted Date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length >
                0
                  ? new Date(
                      singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]
                        ?.hardCopySubmittedDate
                    ).toISOString()
                    .split("T")[0] || "-"
                  : "-"}
              </div>

              <div>Received Copies</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length >
                0
                  ? singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]
                      ?.receivedCopies || "-"
                  : "-"}
              </div>

              {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length >
                0 && (
                <>
                  {singleDrawingData.acceptedSiteHeadHardCopyRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedSiteHeadHardCopyRevisions[
                          selectedTab
                        ].revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}

              {singleDrawingData?.acceptedSiteHeadHardCopyRevisions?.length >
                0 && (
                <>
                  <div>Drawing File</div>
                  <div>:</div>
                  <div style={{ display: "flex", marginLeft: "-120px" }}>
                    <img src={Gallery}
                      size={20}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        handleViewFile(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]
                            ?.revision
                        )
                      }
                    />
                    <img src={Download}
                      size={20}
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() =>
                        handleDownload(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedSiteHeadHardCopyRevisions[0]
                            ?.revision
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>
      <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue}/>
    </>
  );
};

export default Collected;
