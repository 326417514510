import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { siteId } from "../../../atoms";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const calculateDueDays = (
  acceptedROSubmissionDate,
  softCopySubmittedDate = null
) => {
  if (!acceptedROSubmissionDate) return "-";

  if (!softCopySubmittedDate) return -1; // Use -1 to indicate delay

  const dateToCompare = new Date(softCopySubmittedDate);
  const acceptedDate = new Date(acceptedROSubmissionDate);
  const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
};

const DrawingChart = (props) => {
  let webApi = new WebApimanager();
  const [site] = useRecoilState(siteId);
  const [drawingList, setDrawingList] = useState([]);

  // const fetchData = async () => {
  //   try {
  //     const response = await webApi.get(
  //       `api/architectureToRoRegister/getRegisterBySiteId?siteId=${site}`
  //     );
  //     setDrawingList(response.data.data.registers);
  //   } catch (error) {
  //     console.error("Error fetching drawing data:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [site]);
  const fetchData = async () => {
    try {
        // Define the endpoint based on the type and time period
        let endpoint;

        if (props.type === "architect") {
          if (props.selctedTimeperiod === "yearly") {
            endpoint = `api/analysis/report/${site}?selectTimePeriod=${props.selctedTimeperiod}&year=${props.selectedYear}`;
        } else if (props.selectedMonth && props.selectedYear) {
            endpoint = `api/analysis/report/${site}?selectTimePeriod=${props.selctedTimeperiod}&month=${props.selectedMonth}&year=${props.selectedYear}`;
        } else {
            endpoint = `api/architect/register/${site}`;
        }
            
        } else if (props.type === "RO") {
            if (props.selctedTimeperiod === "yearly") {
                // Yearly API call
                endpoint = `api/analysis/roReport/${site}?selectTimePeriod=${props.selctedTimeperiod}&year=${props.selectedYear}`;
            } else if (props.selectedMonth && props.selectedYear) {
                // Monthly API call
                endpoint = `api/analysis/report/${site}?selectTimePeriod=${props.selctedTimeperiod}&month=${props.selectedMonth}&year=${props.selectedYear}`;
            } else {
                endpoint = `api/architect/registerRo/${site}`;
            }
        }else{
          if (props.selctedTimeperiod === "yearly") {
            // Yearly API call
            endpoint = `api/analysis/report/${site}?selectTimePeriod=${props.selctedTimeperiod}&year=${props.selectedYear}`;
        } else if (props.selectedMonth && props.selectedYear) {
            // Monthly API call
            endpoint = `api/analysis/report/${site}?selectTimePeriod=${props.selctedTimeperiod}&month=${props.selectedMonth}&year=${props.selectedYear}`;
        } else {
            endpoint = `api/architect/registerSiteHead/${site}`;
        }
        }

        const response = await webApi.get(endpoint);
        // console.log("Fetched drawing data based on site:", response.data.data.registers);
        // // setDrawingList(response.data.data || []);
        // setDrawingList(response.data.data.registers ? response.data.data.registers : []);
        if(props.selctedTimeperiod==="" &&props.selectedMonth === "" && props.selectedYear ===""){
          if(props.type === "architect"){
          setDrawingList(Array.isArray(response.data.data.filteredData) ? response.data.data.filteredData : []);
          }else if(props.type === "RO" || props.type === "SiteHead"){
            setDrawingList(Array.isArray(response.data.registers) ? response.data.registers : []);
          }
        }else{
        setDrawingList(Array.isArray(response.data.data) ? response.data.data : []);
        }
    } catch (error) {
        console.error("Error fetching drawing data:", error);
    }
};

  useEffect(() => {
    fetchData();
  }, [site,props.selctedTimeperiod, props.selectedMonth, props.selectedYear]);
  const consultantCounts = {};
  const totalCounts = { onTime: 0, delay: 0 };

  drawingList.forEach((register) => {
    if (register?.designDrawingConsultant) {
      const { role } = register.designDrawingConsultant;
      const consultant = `${role}`;

      if (!consultantCounts[consultant]) {
        consultantCounts[consultant] = { onTime: 0, delay: 0 };
      }

      const revisions =
        props.type === "architect"
          ? register.acceptedArchitectRevisions
          : register.acceptedRORevisions;

      if (revisions.length === 0) {
        // No revisions, count as delay
        consultantCounts[consultant].delay++;
        totalCounts.delay++;
      } else {
        revisions.forEach((revision) => {
          const dueDays = calculateDueDays(
            register.acceptedROSubmissionDate,
            revision.softCopySubmittedDate
          );
          if (dueDays > 0) {
            consultantCounts[consultant].onTime++;
            totalCounts.onTime++;
          } else {
            consultantCounts[consultant].delay++;
            totalCounts.delay++;
          }
        });
      }
    }
  });

  // Calculate percentages
  const total = totalCounts.onTime + totalCounts.delay;
  const chartData = {
    labels: Object.keys(consultantCounts),
    datasets: [
      {
        label: "On Time",
        data: Object.values(consultantCounts).map(
          (counts) => (counts.onTime / total) * 100
        ),
        backgroundColor: "#0075A4",
        barThickness: 15, // Adjust the bar thickness
        maxBarThickness: 20, // Maximum bar thickness
        categoryPercentage: 0.5, // Adjust this to control spacing between bars
        barPercentage: 0.8, // Adjust this to control the width of the bars
      },
      {
        label: "Delay",
        data: Object.values(consultantCounts).map(
          (counts) => (counts.delay / total) * 100
        ),
        backgroundColor: "#72ADCF",
        barThickness: 15, // Adjust the bar thickness
        maxBarThickness: 20, // Maximum bar thickness
        categoryPercentage: 0.5, // Adjust this to control spacing between bars
        barPercentage: 0.8, // Adjust this to control the width of the bars
      },
    ],
  };

  return (
    <div style={{ height: "400px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          height: "300px", // Increased height for the chart
        }}
      >
        <div style={{ color: "var(--primary-color)", marginBottom: "10px" }}>
          Drawing Receivables on Time
        </div>
        <Bar
          data={chartData}
          options={{
            indexAxis: "y", // Interchange X and Y axes
            responsive: true,
            maintainAspectRatio: false, // Ensures the chart uses the specified height
            plugins: {
              legend: {
                display: false,
                position: "top",
                labels: {
                  usePointStyle: true,
                },
              },
              tooltip: {
                callbacks: {
                  // Display the actual counts instead of percentages
                  label: function (tooltipItem) {
                    const consultant = tooltipItem.label;
                    const datasetLabel = tooltipItem.dataset.label;
                    const counts = consultantCounts[consultant]; // Get the consultant counts
                    const value =
                      datasetLabel === "On Time" ? counts.onTime : counts.delay; // Get the respective value (onTime or delay)

                    return `${datasetLabel}: ${value} drawings`; // Show the actual count
                  },
                },
              },
            },
            scales: {
              x: {
                stacked: false, // Set to false to display bars side by side
                title: {
                  display: true,
                  text: "Percentage of Drawings",
                },
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 10,
                  callback: function (value) {
                    return `${value}%`;
                  },
                },
              },
              y: {
                stacked: false, // Set to false to display bars side by side
                title: {
                  display: true,
                  text: "Consultants",
                },
                grid: {
                  drawBorder: false, // Optional: hides grid lines to improve appearance
                },
              },
            },
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                backgroundColor: "#0075A4",
                marginRight: "5px",
              }}
            ></div>
            <span>On Time</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                backgroundColor: "#72ADCF",
                marginRight: "5px",
              }}
            ></div>
            <span>Delay</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawingChart;
