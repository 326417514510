import autoMechine from "./ourProductImages/autoMechanic.png";
import drawingBuilding from "./ourProductImages/drawingBuilding.png";
import uorProducts3 from "./ourProductImages/ourProducts3.png";

// const BACKEND_URL = "https://api.chromacheer.com/";
const BACKEND_URL = "https://dev-api.rconspace.com/";
// const BACKEND_URL = "http://localhost:8000/";
const BG_COLOUR = "linear-gradient(to right, #E7C97E 0%, #C55C9B 100%)";
const Button_COLOUR = "#7C1034";
const Field_COLOUR = "#F3E9D7";
const SUCESS_STATUS = "success";
const FAILED_STATUS = "failed";

const OUR_PRODUCTS = [
  {
    image: autoMechine,
    title: "P & M Management System",
    desc: `Proper maintenance, timely data analysis, and informed equipment selection are crucial for maximizing productivity and reducing costs in construction projects. Regular maintenance cycles, accurate usage tracking, and performance analysis are essential for optimizing equipment efficiency and preventing costly breakdowns and delays.`,
    readMorelk: "/products",
  },
  {
    image: drawingBuilding,
    title: "Drawing Management System",
    desc: "Effective drawing management demands seamless coordination between site, head office and Design consultants, with timely issue resolution to maintain project timelines.",
    readMorelk: "/products",
  },
  {
    image: uorProducts3,
    title: "Drive Management System",
    desc: "Our ERP solution offers a centralized drive management system that stores worksheets, departmental data, technical guides, FAQs, and reference materials, ensuring fast and organized access to all essential documents. By streamlining document retrieval and storage, it saves time, enhances productivity, and ensures your team always has the right information at their fingertips for efficient project execution.",
    readMorelk: "/products",
  },
];

const UPCOMING_PRODUCTS = [
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/67d75a8bb049c56d4e56e60f54b089da7dd6a1901dab588d417804a0c57e3046?apiKey=3e99c58a56f84e4cb0d84873c390b13e&",
    title: "QA / QC Management System",
    desc: "An ERP solution streamlines the integration of Quality Assurance (QA) and Quality Control (QC) processes in the construction industry by centralizing data and automating workflows, ensuring consistent adherence to quality protocols and enabling real-time monitoring. This enhances communication, minimizes errors, and optimizes resource allocation, leading to improved project efficiency, reduced delays, and cost savings throughout the project lifecycle.",
    readMorelk: "/products",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/20be375433b560f43c10b19efe336969a68b959a6ca39a3376bd7b70632bd268?apiKey=3e99c58a56f84e4cb0d84873c390b13e&",
    title: "HR / Admin Management System",
    desc: "Our HR/Admin Management System simplifies personnel management on the construction platform, efficiently tracking employee details, attendance, and schedules. It supports payroll, timesheet tracking, and leave approvals, ensuring accurate records and timely payments. With performance tracking and easy reporting, the system boosts efficiency, reduces administrative work, and aligns the workforce with project goals.",
    readMorelk: "/products",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/8791cbcb4465ec747bddbf33165ecab34074b2e099e29bd0b4357cad8074bf2e?apiKey=3e99c58a56f84e4cb0d84873c390b13e&",
    title: "EHS Management System",
    desc: "Many construction companies struggle with a lack of infrastructure and knowledge regarding EHS standards, which can lead to unsafe working conditions, increased accident risks, and non-compliance with regulations. Our ERP solution provides the essential tools and resources to maintain a safe, clean, and compliant work environment, helping you monitor daily safety practices, organise training, and track environmental performance.",
    readMorelk: "/products",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F50230da391844affbffaade64040852d",
    title: "Planning Management System",
    desc: "Our Planning Management System streamlines scheduling and resource allocation, ensuring efficient execution. It offers task tracking, milestone management, and real-time updates, helping teams stay organized. The system creates detailed timelines, assigns responsibilities, and optimizes resource use, ensuring projects stay on track and meet deadlines for successful outcomes.",
    readMorelk: "/products",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F942afa69d2644986a5325314e5694b20",
    title: "Store Management System",
    desc: "Store management in construction often struggles with dis organized stock records, product expiry issues, and poor tracking of materials due to a lack of centralized, easy-to-use systems. Our ERP solution addresses these challenges by offering seamless tracking, data protection, and standardised methods to manage store inventories effectively.",
    readMorelk: "/products",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F942afa69d2644986a5325314e5694b20",
    title: "Communication System",
    desc: "Our platform features a strong communication system with email and chat options. Email enables formal document sharing, while chat allows real-time updates and support. This combination ensures efficient collaboration between clients, contractors, and teams, keeping project details clear, accessible, and easily tracked.",
    readMorelk: "/products",
  },
];

export {
  BACKEND_URL,
  BG_COLOUR,
  Button_COLOUR,
  Field_COLOUR,
  SUCESS_STATUS,
  FAILED_STATUS,
  OUR_PRODUCTS,
  UPCOMING_PRODUCTS,
};
