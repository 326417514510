import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "../../../Dialog/Dialog";
import RFIEditReopenDialog from "./RFIEditReopenDialog";
import { processFile } from "../../BackendUtils";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../../../WebApiManager";
import { canShowFolder } from "../../ShowFolder";
import { useRecoilState } from "recoil";
import { siteId, userInfo } from "../../../../atoms";
import Download from "../IconsSiteHead/Download.svg"
import pen from "../IconsSiteHead/penIcon.svg"
import Gallery from "../../../../Images/Gallery.svg";

const RfiViewModal = ({ open, onClose, drawingData,rfiRaisedAccess,selectedStatus }) => {
  const webApi = new WebApimanager();
  console.log(rfiRaisedAccess,"rfi access for the sitehead")
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [tableRowData, setTableRowData] = useState([]);
  const [currentUserInfo] = useRecoilState(userInfo);
  const [site] = useRecoilState(siteId);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
console.log(drawingData,"view drawing Data");
console.log(tableRowData,"view of tabe drawing Data");
  useEffect(() => {
    console.log("ViewTabs for issues", drawingData);
  }, [drawingData]);

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const handleViewClose = () => {
    onClose();
   
  };

  const handleEditPopup = () => {
    setShowEditPopup(true);
    if (
      drawingData.status === "Requested" ||
      drawingData.status === "Rejected"
    ) {
      setTableRowData(drawingData);
    }
    onClose();
    
  };

  const singleDrawingData = drawingData || {};

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const calculateDueDays = (submissionDate, submittedDate) => {
    if (!submissionDate || !submittedDate) return "NA";
    const submission = new Date(submissionDate);
    const submitted = new Date(submittedDate);
    const diffTime = submission - submitted;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays >= 0 ? diffDays : "Overdue";
  };

  const handleDownload = async (id, status) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/roToSiteLevelRequested/rejectrejectFile/${id}`
          : `api/roToSiteLevelRequested/request/${id}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const handleViewFile = async (id, status,revision) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/roToSiteLevelRequested/rejectFile/${id}`
          : `api/roToSiteLevelRequested/request/${id}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response,"view",navigate,revision,"","RFI"); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);
  return (
    <>
      {showEditPopup && (
        <RFIEditReopenDialog
          showEditPopup={showEditPopup}
          setShowEditPopup={setShowEditPopup}
          tableRowData={tableRowData}
        />
      )}

      <Dialog
        open={open}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            fontFamily: "Poppins",
            borderRadius: "10px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#4D4D4D",
            height: "50px",
            position:"relative"
          }}
        >
          <p style={{ width:"100%", textAlign: "center", color: "white",marginTop:"15px" }}>
            View
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px",maxHeight: "80vh", overflowY: "auto",position:"relative" }}
        >
          {singleDrawingData?.status === "Requested" && (
            <>{rfiRaisedAccess &&
            <button
              style={{
                backgroundColor: "#e47331",
                color: "white",
                padding: "6px 20px",
                position: "absolute",
                right: "60px",
              }}
              onClick={() => handleEditPopup()}
            >
              Edit
            </button>
}
</>
          )}
          {singleDrawingData?.status === "Rejected" && (
            <>{rfiRaisedAccess &&
            <button
              style={{
                backgroundColor: "#e47331",
                color: "white",
                padding: "6px 20px",
                position: "absolute",
                right: "60px",
              }}
              onClick={() => handleEditPopup()}
            >
              ReOpen
            </button>
            }
</>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "20px",
              padding: "20px",
              color: "#4D4D4D",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "28px",
              justifyContent: "center",
              marginLeft:"80px"
            }}
          >
            {/* Map over the fields as needed */}
            <div>Drawing Number</div>
            <div>:</div>
            <div>{singleDrawingData?.drawingNo || "NA"}</div>

            <div>Design Consultant</div>
            <div>:</div>
            <div>
              {singleDrawingData?.drawingId?.designDrawingConsultant
                ?.role || "NA"}
            </div>
            {showAddFolder &&
            <>
            <div>Folder</div>
            <div>:</div>
            <div >
              {singleDrawingData?.drawingId?.folderId?.folderName || "NA"}
            </div>
            </>
            }
            <div>Design Category</div>
            <div>:</div>
            <div>{singleDrawingData?.drawingId?.category?.category || "NA"}</div>

            <div>Drawing Title</div>
            <div>:</div>
            <div>{singleDrawingData?.drawingId?.drawingTitle || "NA"}</div>

            <div>Requested Date</div>
            <div>:</div>
            <div>
              {singleDrawingData?.requestedDate
                ? formatDate(singleDrawingData.requestedDate)
                : "NA"}
            </div>

            <div>Expected Date</div>
            <div>:</div>
            <div>
              {singleDrawingData?.expectedDate
                ? formatDate(singleDrawingData.expectedDate)
                : "NA"}
            </div>

            {/* <div>Nature of Request Info</div>
            <div>:</div>
            <div>
              {singleDrawingData?.natureOfRequestedInformation?.length > 0
                ? singleDrawingData.natureOfRequestedInformation.join(", ")
                : "-"}
            </div>

            <div>Reason</div>
            <div>:</div>
            <div>{singleDrawingData?.remarks || "NA"}</div> */}
             
            <div>Status</div>
            <div>:</div>
            <div>{singleDrawingData?.status || "NA"}</div>

            {singleDrawingData?.reason && (
              <>
                <div>Rejected Reason</div>
                <div>:</div>
                <div>{singleDrawingData?.reason || "NA"}</div>
              </>
            )}
            <div>Revision</div>
            <div>:</div>
            <div>
              {/* {console.log(selectedTab,"selectedTab")}
              {Array.isArray(singleDrawingData?.drawingId?.acceptedRORevisions) &&
              singleDrawingData?.drawingId?.acceptedRORevisions[selectedTab]
                ? singleDrawingData?.drawingId?.acceptedRORevisions[selectedTab].revision
                : "-"} */}
              {singleDrawingData?.revision?singleDrawingData.revision:"-"}
            </div>
       
      <div >Nature of Request Information</div>
      <div>:</div>
      <div>
        {singleDrawingData?.natureOfRequestedInformationReasons?.length > 0 ? (
          singleDrawingData.natureOfRequestedInformationReasons.map((item,index) => (
            <div key={item._id} style={{ marginTop: "10px" }}>
              <div >{item.natureOfRequest} -   {item.reason} </div>
            </div>
          ))
        ) : (
          <div>No nature of requested information available.</div>
        )}
      </div>

            <div>Requested Drawing File</div>
            <div>:</div>
            <div style={{ display: "flex" }}>
              <img src={Gallery}
                size={20}
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => handleViewFile(singleDrawingData?._id, "",singleDrawingData?.revision)}
              />
              <img src={Download}
                size={20}
                style={{ marginRight: "5px", cursor: "pointer" }}
                onClick={() => handleDownload(singleDrawingData?._id, "")}
              />
            </div>

            {singleDrawingData?.status === "Rejected" && (
              <>
                <div>Rejected Drawing File</div>
                <div>:</div>
                <div style={{ display: "flex" }}>
                  <img src={Gallery}
                    size={20}
                    style={{ marginRight: "10px", cursor: "pointer" }}

                    onClick={() =>
                      handleViewFile(singleDrawingData?._id, "Rejected",singleDrawingData?.revision)
                    }
                  />
                  <img src={Download}
                    size={20}
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    onClick={() =>
                      handleDownload(singleDrawingData?._id, "Rejected")
                    }
                  />
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RfiViewModal;
