import React, { useState, useEffect } from 'react';
import * as Icon from "react-bootstrap-icons";
import { WebApimanager } from '../../WebApiManager';
import { Button } from 'react-bootstrap';
import { Dialog, DialogContent } from '../Dialog/Dialog';

const AddPlannerCategory = (props) => {
    const webApi = new WebApimanager();
    const { openCreateTaskDialog, onClose } = props;
    const [numCategories, setNumCategories] = useState(0); // Default to 3 categories
    const [categories, setCategories] = useState(Array(0).fill(''));

    const [existingCategories, setExistingCategories] = useState([]);

    const getAll = () =>{
        webApi.get('api/plannerCategory/getAll').then((data)=>{
            if(data){
                setExistingCategories(data.data.data.categories)
            }
        })
    }

    useEffect(()=>{
        if(openCreateTaskDialog && existingCategories.length===0){
            getAll()
        }
    },[openCreateTaskDialog])



    const handleNumCategoriesChange = (e) => {
        const newNum = parseInt(e.target.value);
        setNumCategories(newNum);
        if(newNum){
          setCategories(Array(newNum).fill(''));
        }
    };

    const handleCategoryChange = (index, value) => {
        const newCategories = [...categories];
        newCategories[index] = value;
        setCategories(newCategories);
    };

    const handleSubmit = () => {
        // Handle form submission
        console.log('Categories:', categories);

        const body = categories.map(name => ({ name }));
      
        webApi.post(`api/plannerCategory/create`, body)
        .then((response) => {
            // Handle success response if needed
            console.log("Category updated successfully:", response.data);
            setExistingCategories([]);
            setNumCategories(0);
            setCategories([]);
            onClose();
        })
        .catch((error) => {
            console.error("Error updating task:", error);
        });
    };

    const onDelete = (categoryId) =>{

        webApi.delete(`api/plannerCategory/delete/${categoryId}`)
        .then((response) => {
            // Handle success response if needed
            console.log("Category dele successfully:", response.data);
            getAll()
        })
        .catch((error) => {
            console.error("Error updating task:", error);
        });

    }

    return (
        <Dialog open={openCreateTaskDialog} onClose={()=>{
            setNumCategories(0);
            setCategories([]);
            onClose();
        }} maxWidth="xl" fullWidth>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#7C1034', margin: '0px' }}>
                <p style={{ color: 'white', textAlign: 'center', margin: '5px 200px', alignItems: 'center' }}>Create Categories</p>
                <Icon.XCircle size={20} style={{ marginLeft: 'auto', cursor: 'pointer', color: 'white', marginRight: '10px' }} onClick={()=>{
                    setNumCategories(0);
                    setCategories([]);
                    onClose();
                }} />
            </div>
            <DialogContent style={{ padding: '25px', minWidth: '300px', minHeight: '300px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <h4>Categories:</h4>
                    {existingCategories.map((category)=>{
                        return <li key={category._id} style={{ display: 'flex' }}>
                                <p style={{ width: '200px' }}>{category.name}</p><Icon.Trash size={20} style={{ cursor: 'pointer', color: 'red' }} 
                                        onClick={() => onDelete(category._id)}/>
                            </li>
                    })}
                    <label>How many categories do you want to add:</label>
                    <input 
                        type="number" 
                        value={numCategories} 
                        onChange={handleNumCategoriesChange} 
                        min="1" 
                        style={{ marginLeft: '10px', width: '200px' }} 
                    />
                </div>
                {numCategories!=undefined && Array.from({ length: numCategories }).map((_, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <input 
                            type="text" 
                            value={categories[index]} 
                            onChange={(e) => handleCategoryChange(index, e.target.value)} 
                            style={{ width: '100%', padding: '5px' }} 
                        />
                    </div>
                ))}
                <Button onClick={handleSubmit} style={{ marginTop: '20px', padding: '10px 20px' }}>Submit</Button>
            </DialogContent>
        </Dialog>
    );
};

export default AddPlannerCategory;
