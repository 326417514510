import React, { useEffect, useState } from 'react'
import { WebApimanager } from '../../../WebApiManager';
import { siteId } from '../../../atoms';
import {useRecoilState} from "recoil";
const allowedExtensions = [
  "rvt", "rfa", "rte", "rft", "dwg", "dxf", "dws", "dwt", "max", "fbx", 
  "nwc", "nwd", "nwf", "dwf", "ipt", "iam", "idw", "ipn", "f3d", "f3z", 
  "adsk", "svf", "svf2", "rcs", "rcp", "stl", "obj", "dae", "3ds", "step", 
  "iges", "sat", "glb", "gltf", "e57", "pts", "xyz", "rpf", "rla", "hdr", 
  "exr", "ifc", "bim", "dgn", "pdf", "png", "jpeg", "tif", "cfd", "cfz", 
  "nc", "gcode", "tap", "3dxml", "prt", "skp", "pln", "lsp", "mxl", "py", 
  "bak", "zip", "shp", "kml", "geojson", "csv", "xls", "txt", "dwfx", "blend", 
  "safe", "tekla", "staad"
];

const AssignFileFormat = ({selectedOptions,setSelectedOptions,site,existingConsultants=[], setSelectedPayload}) => {
    console.log(existingConsultants,selectedOptions,"sss")
    let webApi = new WebApimanager()

    useEffect(() => {
      if (existingConsultants.length > 0) {
        const initialOptions = existingConsultants.map(() =>
          allowedExtensions.reduce((acc, extension) => {
            acc[extension] = false;
            return acc;
          }, {})
        );
        setSelectedOptions(initialOptions);
      }
    }, [existingConsultants]);
    
      const handleCheckboxChange = (consultantIndex, extension) => {
        setSelectedOptions((prevState) =>
          prevState.map((consultantOptions, index) => {
            if (index === consultantIndex) {
              return {
                ...consultantOptions,
                [extension]: !consultantOptions[extension],
              };
            }
            return consultantOptions;
          })
        );
      };

      useEffect(() => {

        const updatedPayload = existingConsultants.map((consultant, index) => {
          const selectedFormats = Object.keys(selectedOptions[index] || {}).filter(
            (ext) => selectedOptions[index][ext]
          );
    
          return {
            siteId: site,
            formats: selectedFormats,
            userId: consultant._id,
          };
        });
        console.log(updatedPayload,"update")
        setSelectedPayload(updatedPayload);
      }, [selectedOptions, existingConsultants, site]);
  

  return (
    <div style={{display:"flex",flexDirection:"column",width:"100%",overflowX:"auto"}}>
        <div style={{display:"flex",flexDirection:"column",width:"max-content",height:"max-content",gap:"20px"}}>
            <div style={{display:"flex",width:"100%",height:"80px",gap:"30px",alignItems:"center",backgroundColor:"#4d4d4d",paddingInline:"10px"}}>
            <p style={{color:"white",fontSize:"18px",width:"200px",margin:"0px"}}>Consultant</p>
                {allowedExtensions.map((extension,index)=>(
                  <p style={{color:"white",fontSize:"18px",width:"60px",margin:"0px"}} key={index}>{extension}</p>
                ))}
            </div>
            {existingConsultants.length && existingConsultants.map((consultant,index)=>(
              <div style={{display:"flex",width:"100%",gap:"30px",alignItems:"center",backgroundColor:index%2===0?"#Fdf8f4":"#f3f6f8",height:"80px",paddingInline:"10px"}} key={consultant?._id}>
                <p style={{color:"black",fontSize:"18px",width:"200px",margin:"0px"}}>{consultant.role}</p>
                {allowedExtensions.map((extension,ind)=>(
                  <div style={{width:"60px"}} key={ind}>
                    <input type="checkbox" checked={selectedOptions[index]?.[extension] || false}
                  onChange={() => handleCheckboxChange(index, extension)}/>
                  </div>
                ))}
              </div>
            ))}
        </div>
      
      </div>
  )
}

export default AssignFileFormat