import React, { useState, useEffect, useMemo, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import {  siteId, userInfo } from "../../../atoms";
import SelectSite from "../../../Utilities/SelectSite";
import "bootstrap/dist/css/bootstrap.min.css";
import TableWrapper from "../../../Utilities/TableWrapper";
import { updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { canShowFolder } from "../ShowFolder";
import ConsultantFilter from "../ConsultantFilter";
import ArrowToggle from "../ArrowToggle";
import SortByPopup from "../SortByPopup";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", 
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", 
          },
        },
      },
    },
  },
});

const RegisterTable = () => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  const webApi = new WebApimanager();
  const [drawingList, setDrawingList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [site, setSite] = useRecoilState(siteId);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [currentUserInfo] = useRecoilState(userInfo);
  const [consultantValue, setConsultantValue] = useState('')

  const [filters, setFilters] = useState({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
  });

  // sort by start
  const [sortField, setSortField] = useState(""); // Field to sort by
  const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
  const [activeSortPopup, setActiveSortPopup] = useState(null);

  const handleSortClick = (field) => {
    setFilters({
      drawingNo: "",
      consultant: "",
      category: "",
      drawingTitle: "",
      scheduledDate: "",
      submissionDate: "",
      revision: "",
    });
    setSearchValue("");
    setActiveSortPopup((prev) => (prev === field ? null : field));
  };

  const handleSortSelection = (order) => {
    if (activeSortPopup) {
      setSortField(activeSortPopup);
      setSortOrder(order);
      setActiveSortPopup(null); // Close the popup after selection
    }
  };

  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
    
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    })
    setSortField("");
    setSortOrder("");
    setActiveSortPopup(null);
  }, []);
  
  const handleFilterChange = (column, value) => {
    setSearchValue("")
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
    setFilters((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = ""; 
          return acc;
      }, {}),
      [column]: value, 
  }));
}

  useEffect(() => {
      webApi.get( `api/architect/registerRo/${site}`)
      .then((response) => {
        
        if (response && response.data && response.data.registers) {
          setDrawingList(response.data.registers);
          setInitialDrawingList(response.data.registers);
          console.log("registers Data of ro",drawingList);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  
    
   
  }, [site]);
  // useEffect(() => {
  //   if (!searchActive) {
  //     drawingList();
  //   }
  // }, [searchActive]);
  const uniqueConsultant = useMemo(() => {
    const consultant = initialDrawingList.map((item) => item.designDrawingConsultant?.role);
    return [...new Set(consultant)];
  }, [initialDrawingList]);

  useEffect(() => {
    if (searchValue === "" && consultantValue === "") {
      setDrawingList(initialDrawingList)
      return
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        if (drawingItem) {
          const drawingNumber = drawingItem.drawingNo
            ? drawingItem.drawingNo.toLowerCase()
            : "";
          const drawingName = drawingItem.drawingTitle
            ? drawingItem.drawingTitle.toLowerCase()
            : "";
          const consultant = drawingItem.designDrawingConsultant
            ? `${drawingItem.designDrawingConsultant.role.toLowerCase()}`
            : "";
          const category =
            drawingItem.category && drawingItem.category.category
              ? drawingItem.category.category.toLowerCase()
              : "";

          const acceptedSiteSubmissionDate =
            drawingItem.acceptedSiteSubmissionDate
              ? new Date(drawingItem.acceptedSiteSubmissionDate).toDateString()
              : "";

          return (
            drawingNumber.includes(searchValue) ||
            drawingName.includes(searchValue) ||
            consultant.includes(searchValue) ||
            category.includes(searchValue) ||
            acceptedSiteSubmissionDate.includes(searchValue)
          ) && consultantValue === drawingItem.designDrawingConsultant.role;
        } else {
          return false;
        }
      });
      setDrawingList(filteredDrawings);
    }
  }, [searchValue,consultantValue]);

  

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);
  };
  const [showAddFolder, setShowAddFolder] = useState(false);
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const updatedRows = drawingList.filter((item)=>item.designDrawingConsultant?.role.includes(consultantValue)).map((row, index) => ({
    ...row,
    serialNo: index + 1, 
    drawingNo: row.drawingNo,
    consultant: row.designDrawingConsultant?.role || "",
    category: row.category?.category || "",
    drawingTitle: row?.drawingTitle || "",
    createdDate: formatDate(row.creationDate),
    scheduledDate: formatDate(row.acceptedROSubmissionDate),
    acceptedSiteSubmissionDate: formatDate(row.acceptedSiteSubmissionDate),
  }));

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });


  const columns =useMemo(()=>[
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 1,
      filterable: false,
      sortable: false,
    },
    {
      field: "drawingNo",
      headerName: "DRWG.No",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "consultant",
      headerName: "Consultant",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "category",
      headerName: "Drawing Category",
      flex: 1,
      filterable: true,
      sortable: true,
      
    },
    {
      field: "drawingTitle",
      headerName: "Drawing Title",
      flex: 1,
      filterable: true,
      sortable: true,
      
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "scheduledDate",
      headerName: "Scheduled Date",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "acceptedSiteSubmissionDate",
      headerName: "Accepted site submission date",
      flex: 1,
      filterable: true,
      sortable: true,
    },
  ],[drawingList]) ;
  
  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#131313",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                Drawing Register
              </p>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "280px",
                      paddingLeft: "5px",
                      height: "50px",
                    }}
                  >
                    <input
                      placeholder="Search Drawings"
                      onChange={handleSearchChange}
                      value={searchValue}
                      style={{
                        paddingLeft: "20px",
              
                        border: "1px solid var(--primary-color)",
                        width: "250px",
                        height: "40px",
                      }}
                    />
                    <Icon.Search
                      style={{
                        position: "absolute",
                        right: "35px",
                        top: "40%",
                        transform: "translateY(-50%)",
                        color:'#ACACAC'
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Box
                className="dataGridContainer"
              >
                <ThemeProvider theme={theme}>
                <DataGrid
                  columns={columns}
                  rows={filteredRows}
                  getRowId={(row) => row._id}
                  rowHeight={60}
                  disableColumnMenu={false} // Keep the column menu for filtering
                  pagination={false}
                  hideFooter={true}
                  getRowSpacing={params=>({
                    top:params.isFirstVisible ? 0 : 5,
                    bottom:params.isLastVisible ? 0 : 5
                  })}
                  
                  getRowClassName={(params) =>
                    "customHeader" 
                  }
                  
                />
                </ThemeProvider>
              </Box>
            </div>
   
          
          </>
        }
      />
      <ConsultantFilter uniqueConsultant={["ALL",...uniqueConsultant]} setConsultantValue={setConsultantValue} />
    </>
  );
};

export default RegisterTable;
