import React, { useEffect, useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import "./AssignWork.css"
import { WebApimanager } from '../../../WebApiManager'
import { Dialog, DialogContent } from '../../Dialog/Dialog'

const EditAssignTask = ({showEditForm,handleClose,tableRowData}) => {
  console.log(tableRowData,"tableRowData")
  const [formData,setFormData] = useState({assignTo:""})
  const [errors,setErrors] = useState({})
  const [pnmUsers,setPnmUsers] = useState([])

  let webApi=new  WebApimanager();

  useEffect(()=>{
    const fetchPnMUsers = async() =>{
    try {
      const response= await webApi.get(`api/checklistDesign/users?department=PNM`)
      console.log(response,"Add New Click")
      if(response.status === 200){
        setPnmUsers(response.data.data.users)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

    fetchPnMUsers()

  },[])
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.assignTo) {
      formErrors.assignTo = "Please select a user to assign the task.";
      isValid = false;
    }

    // Add other field validations as needed here

    setErrors(formErrors);
    return isValid;
  };

  // Handle form submit
  const handleSubmit = async(e) => {
    e.preventDefault();
    const isValid = validateForm();
    
    if (isValid) {
      // Proceed with form submission (API call, etc.)
      
      try {
        const payload = {
          assignTOUser:formData.assignTo,
          assignNewPnmTasks:[tableRowData?._id]
        }
        const response= await webApi.post(`api/newPnmWorkFlow`,payload)
        console.log(response,"response of assignTaskApi")
      } catch (error) {
        console.log(error.message,"assign work Api fail")
      }
      console.log("Form is valid. Proceed with form submission.", formData);
    } else {
      console.log("Form validation failed.");
    }
  }
  return (
    <Dialog
        open={showEditForm}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 500px", textAlign: "center", color: "white" }}
          >
            Assign Task
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "300px" }}
        >
         <form className="AWFormContainer" onSubmit={handleSubmit}>
            <div className="AWMainInputContainer">
                <div className="ATShowContainer">
                    <p className="ATFieldName">Type</p>
                    <p>:</p>
                    <p>{tableRowData.equipmentType}</p>
                </div>
                <div className="ATShowContainer">
                    <p className="ATFieldName">Asset Code</p>
                    <p>:</p>
                    <p>{tableRowData?.assetCode}</p>
                </div>
                <div className="ATShowContainer">
                    <p className="ATFieldName">Name</p>
                    <p>:</p>
                    <p>{tableRowData.makeName}</p>
                </div>

                <div className="AWInputContainer">
                    <label className="AWLabel">Assign To</label>
                    <select className="AWInputField" onChange={handleChange}
                name="assignTo"
                value={formData.assignTo} >
                    <option value="">Select</option>
                    {
                      pnmUsers.length ? pnmUsers.map((user)=>(
                        <option key={user._id} value={user._id}>{user.firstName+" "+user.lastName}</option>
                      ))
                    :""}
                  </select> 
                  {errors.assignTo && <p style={{position:"absolute",top:"100%",color:"red"}}>{errors.assignTo}</p>}
                </div>
            </div>
            <div className="AWbtnContainer">
                <button>SUBMIT</button>
            </div>
            
        </form> 
        </DialogContent>
      </Dialog>
  )
}

export default EditAssignTask