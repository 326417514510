import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { todayDate } from "../../../../Utilities/DateUtils";
import { WebApimanager } from "../../../../WebApiManager";
import SitesDropdown from "../../../../Utilities/SitesDropdown";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import SearchDropdownIdVal from "../../../../Widgets/SearchDropdownIdVal";
import { registerAtom, userInfo } from "../../../../atoms";
import { useRecoilState } from "recoil";
import { SUCESS_STATUS } from "../../../../Utilities/Constants";
import { Dialog, DialogContent } from "../../../Dialog/Dialog";

const allowedExtensions = [
  ".rvt", ".rfa", ".rte", ".rft", ".dwg", ".dxf", ".dws", ".dwt", ".max", ".fbx", 
  ".nwc", ".nwd", ".nwf", ".dwf", ".ipt", ".iam", ".idw", ".ipn", ".f3d", ".f3z", 
  ".adsk", ".svf", ".svf2", ".rcs", ".rcp", ".stl", ".obj", ".dae", ".3ds", ".step", 
  ".iges", ".sat", ".glb", ".gltf", ".e57", ".pts", ".xyz", ".rpf", ".rla", ".hdr", 
  ".exr", ".ifc", ".bim", ".dgn", ".pdf", ".png", ".jpeg", ".tif", ".cfd", ".cfz", 
  ".nc", ".gcode", ".tap", ".3dxml", ".prt", ".skp", ".pln", ".lsp", ".mxl", ".py", 
  ".bak", ".zip", ".shp", ".kml", ".geojson", ".csv", ".xls", ".txt", ".dwfx", ".blend", 
  ".safe", ".tekla", ".staad"
];

const AddDrawingDialog = (props) => {
  const { open, handleDialogClose, site } = props;

  // setRegisterListBy fetching from .. ( status condition not required siteid query required )
  const [registerList, setRegisterList] = useState([]);
  const [filteredDrawingOptions, setFilteredDrawingOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  let webApi = new WebApimanager();

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    // acceptedScheduledsubmissionDate: '',
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    folder:"",
    submittedDate: "",
    // issuedSoftCopy: '',
    // receivedHardCopy: '',
    drawingDWGFileName: null,
    drawingPDFFileName: null,
    receivedCopies: "",
    revisions: [],
    note: "",
  });

  const [errors, setErrors] = useState({});
  const [showAddFolder, setShowAddFolder] = useState(false)
  const [fileFormats, setFileFormats] = useState([])
  const [currentUserInfo] = useRecoilState(userInfo);
  console.log(currentUserInfo,"currentUserInfo in Arch")
  console.log(site,"siteID")
  
  useEffect(()=>{
      const filterModuleBasedOnSiteId = currentUserInfo?.permittedSites.filter((item)=>item?.siteId === site )
      console.log(filterModuleBasedOnSiteId,"filterModuleBasedOnSiteId")
      if(filterModuleBasedOnSiteId?.length ){
        filterModuleBasedOnSiteId.forEach((item)=>{
          if(item?.enableModules?.drawingAddFolder){
            setShowAddFolder(true)
          }
        })
      }
  },[currentUserInfo,site])



  const handleClose = () => {
    setFormData({
      regiterId: "",
      drawingNumber: "",
      drawingName: "",
      category: "",
      folder:"",
      designDrawingConsultant: "",
      // acceptedScheduledsubmissionDate: '',
      // actualSubmissionDate: '',
      // dueDays: '',
      // remarks:'',
      submittedDate: "",
      // issuedSoftCopy: '',
      // receivedHardCopy: '',
      drawingDWGFileName: null,
      drawingPDFFileName:null,
      revisions: "",
      note: "",
    });
    handleDialogClose();
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0], // Assuming you are only uploading one file
      });
    } else if (name === "receivedCopies" && /^\d*$/.test(value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    if (name === "designDrawingConsultant") {
      const selectedConsultant = value;

      // Filter drawing numbers based on the selected consultant
      const filteredDrawings = registerList.filter(
        (register) =>
          register.designDrawingConsultant?.role === selectedConsultant
      );

      setFilteredDrawingOptions(filteredDrawings);

      // Clear the selected drawing number, drawing name, category, and revisions when the consultant changes
      setFormData((prevFormData) => ({
        ...prevFormData,
        designDrawingConsultant: selectedConsultant,
        drawingNumber: "",
        drawingName: "",
        category: "",
        folder:"",
        revisions: [],
        submittedDate: todayDate(),
        receivedCopies: "",
        note: "",
        selectedRevision: "",
      }));
    }

    if (name === "drawingNumber") {
      const selectedDrawing = registerList.find(
        (register) => register.drawingNo === value
      );
console.log(selectedDrawing,"selected drawing")
      if (selectedDrawing) {
        const combinedRevisions = Array.isArray(
          selectedDrawing.acceptedArchitectRevisions
        )
          ? selectedDrawing.acceptedArchitectRevisions.flatMap(
              (rev) => rev.revision || []
            )
          : [];

        setFormData((prevFormData) => ({
          ...prevFormData,
          registerId: selectedDrawing.drawingId,
          drawingNumber: selectedDrawing.drawingNo,
          drawingName: selectedDrawing.drawingTitle,
          folder:selectedDrawing.folderId?.folderName || "",
          category: selectedDrawing.category?.category || "",
          revisions: combinedRevisions,
          submittedDate: todayDate(),
          receivedCopies: "",
          note: "",
          selectedRevision: "",
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          registerId: "",
          drawingName: "",
          category: "",
          revisions: [],
          submittedDate: todayDate(),
          receivedCopies: "",
          note: "",
          selectedRevision: "",
        }));
      }
    }

    if (name === "selectedRevision" || name === "note") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [currentTab, setCurrentTab] = useState("upload");

  const handleTabChange = (tab) => {
    setCurrentTab(tab);

    if (tab === "upload") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        registerId: "",
        drawingNumber: "",
        folder:"",
        drawingName: "",
        category: "",
        designDrawingConsultant: "",
        submittedDate: "",
        drawingPDFFileName:null,
        drawingDWGFileName: null,
        receivedCopies: "",
        revisions: [],
        note: "",
        selectedRevision: "", // Add this if you're using it in the received tab
      }));
    }

    // Reset form data when switching to the 'received' tab
    if (tab === "received") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        registerId: "",
        drawingNumber: "",
        folder:"",
        drawingName: "",
        category: "",
        designDrawingConsultant: "",
        submittedDate: "",
        drawingDWGFileName: null,
        receivedCopies: "",
        revisions: [],
        note: "",
        selectedRevision: "", // Add this if you're using it in the received tab
      }));
    }
  };

  // Example validation function
  const validateForm = (formData, currentTab) => {
    const newErrors = {};
    if (currentTab === "upload") {
      console.log(formData);
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (showAddFolder && !formData.folder.trim()) {
        newErrors.folder =
          "Folder Name is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }

      


      
      newErrors.drawingDWGFileName = Object.keys(formData.drawingDWGFileName).reduce((acc, key) => {
        const file = formData.drawingDWGFileName[key]; // Get the file name for each format (dwg, dxf, etc.)
      
        if (!file) {
          acc[key] = `${key.toUpperCase()} file is required`; // Store error with the format key (e.g., "dwg: DWG file is required")
        } else {
          const extension = typeof file.name === "string" ? file.name.split('.').pop().toLowerCase() : "";
          if (!allowedExtensions.includes(`.${extension}`)) {
            acc[key] = `Invalid ${key.toUpperCase()} file format. Please upload a valid file.`; // Error for invalid format
          }
        }
      
        return acc; // Return the accumulator to keep building the error object
      }, {});
      if(Object.keys(newErrors.drawingDWGFileName).length === 0){
        delete newErrors.drawingDWGFileName
      }
      
      
      // if (!formData.drawingPDFFileName) {
      //   newErrors.drawingPDFFileName = "Drawing PDF File is required";
      // } else if (formData.drawingPDFFileName instanceof File) {
      //   // Check if the file name ends with '.dwg'
      //   if (!formData.drawingPDFFileName.name.endsWith(".pdf")) {
      //     newErrors.drawingPDFFileName = "Drawing File must be a .pdf file";
      //   }
      // } else if (typeof formData.drawingPDFFileName === "string") {
      //   // Check if the string ends with '.dwg'
      //   if (!formData.drawingPDFFileName.endsWith(".pdf")) {
      //     newErrors.drawingPDFFileName = "Drawing File must be a .pdf file";
      //   }
      // }
      if (!formData.selectedRevision) {
        newErrors.selectedRevision = "revision is required";
      }
      // if (!formData.remarks) errors.remarks = 'This field is required.';
    } 
    // else if (currentTab === "received") {
    //   if (!formData.drawingNumber.trim()) {
    //     newErrors.drawingNumber = "DrawingNumber  is required";
    //   }
    //   if (!formData.drawingName.trim()) {
    //     newErrors.drawingName = "Drawing Title is required";
    //   }
    //   if (!formData.designDrawingConsultant.trim()) {
    //     newErrors.designDrawingConsultant =
    //       "DesignDrawingConsultant is required";
    //   }
    //   if (!formData.category.trim()) {
    //     newErrors.category = "Category is required";
    //   }
    //   if (!formData.submittedDate.trim()) {
    //     newErrors.submittedDate = "Submitted Date is required";
    //   }
      // if (!formData.selectedRevision.trim()) {
      //   newErrors.selectedRevision = "revision is required";
      // }

    //   if (!formData.drawingDWGFileName) {
    //     newErrors.drawingDWGFileName = "Drawing File is required";
    //   }
    //   if (!formData.receivedCopies) {
    //     newErrors.receivedCopies = "Received Copies is required";
    //   }
    // }
    return newErrors;
  };

  const handleFormSubmit = async () => {
    // Validate the form data
    const errors = validateForm(formData, currentTab);
    console.log(errors,"errors")
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    // Perform API call based on the active tab
    if (currentTab === "upload") {
      console.log("uploadclicking");
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitUpload(formData.registerId);
    }
    // if (currentTab === "received") {
    //   setApiProcessing({
    //     loader: true,
    //     message: "Submitting...",
    //   });
    //   await submitReceived(formData.registerId);
    // }
  };

  // const submitUpload = async (registerId) => {
  //   console.log("registerId", registerId);
  //   try {
  //     const ApprovalFormData = {
  //       acceptedArchitectRevisions: [
  //         {
  //           softCopySubmittedDate: todayDate(),
  //           changes: formData.note,
  //         },
  //       ],
  //     };

  //     console.log("Submitting Approval data:", ApprovalFormData);

  //     const response = await webApi.put(
  //       `api/architectureToRoRegister/revisions/${registerId}`,
  //       ApprovalFormData
  //     );

  //     // If there's a file to upload, initiate PUT request to upload file
  //     if (formData.drawingDWGFileName) {
  //       const drawingDWGFileName = new FormData();
  //       drawingDWGFileName.append("drawingFileName", formData.drawingDWGFileName);
  //       await webApi.imagePut(
  //         `api/architectureToRoRegister/${registerId}`,
  //         drawingDWGFileName
  //       );
  //     }
  //     if (formData.drawingPDFFileName) {
  //       const pdfDrawingFileName = new FormData();
  //       pdfDrawingFileName.append("pdfDrawingFileName", formData.drawingPDFFileName);
  //       await webApi.imagePut(
  //         `api/architectureToRoRegister/pdf/${registerId}?revisionType=Architect`,
  //         pdfDrawingFileName
  //       );
  //     }
  //     setApiProcessing({ loader: false, message: "" });
  //     handleClose();
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     setApiProcessing({ loader: false, message: "" });
  //     // Handle error, show error message, etc.
  //     alert("Failed to submit the form. Please try again later.");
  //   }
  // };
  // const submitUpload = async (registerId) => {
  //   console.log("registerId", registerId);
  //   try {
  //     const ApprovalFormData = {
  //       acceptedArchitectRevisions: [
  //         {
  //           softCopySubmittedDate: todayDate(),
  //           changes: formData.note,
  //         },
  //       ],
  //     };
  
  //     console.log("Submitting Approval data:", ApprovalFormData);
  
  //     // Submit the approval form data
  //     const response = await webApi.put(
  //       `api/architectureToRoRegister/revisions/${registerId}`,
  //       ApprovalFormData
  //     );
  
  //     // If there's a DWG file to upload, do it
  //     if (formData.drawingDWGFileName) {
  //       const drawingDWGFileName = new FormData();
  //       drawingDWGFileName.append("drawingFileName", formData.drawingDWGFileName);
  
  //       await webApi.imagePut(
  //         `api/architectureToRoRegister/${registerId}`,
  //         drawingDWGFileName
  //       );
  //     }
  
  //     // If there's a PDF file to upload, do it
  //     if (formData.drawingPDFFileName) {
  //       const pdfDrawingFileName = new FormData();
  //       pdfDrawingFileName.append("pdfDrawingFileName", formData.drawingPDFFileName);
  
  //       // Log FormData entries for debugging
  //       for (let [key, value] of pdfDrawingFileName.entries()) {
  //         console.log("FormData Key:", key, "Value:", value);
  //       }
  
  //       await webApi.imagePut(
  //         `api/architectureToRoRegister/pdf/${registerId}?revisionType=Architect`,
  //         pdfDrawingFileName
  //       );
  //     }
  
  //     // Clear loading state and close the form
  //     setApiProcessing({ loader: false, message: "" });
  //     // handleClose();
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     setApiProcessing({ loader: false, message: "" });
  //     alert("Failed to submit the form. Please try again later.");
  //   }
  // };
  
  const submitUpload = async (registerId) => {
    console.log("registerId", registerId);
  
    try {
      // Basic form data
      const ApprovalFormData = new FormData();
      ApprovalFormData.append("softCopySubmittedDate", todayDate());
      ApprovalFormData.append("changes", formData.note);
  
      
    if (formData.drawingDWGFileName && typeof formData.drawingDWGFileName === "object") {
      Object.keys(formData.drawingDWGFileName).forEach((key, index) => {
        const file = formData.drawingDWGFileName[key]; // Get the file for each format (e.g., "dwg", "dxf")
    
        if (file) {
          // If a file is present for the current key (like dwg, dxf), append it as binary
          ApprovalFormData.append(`drawingFileName_${key}`, file);
        } else {
          console.error(`No file provided for ${key}.`);
        }
      });
    } else {
      console.error("No valid drawingDWGFileName files provided.");
    }
    
      if (formData.drawingPDFFileName) {
        ApprovalFormData.append("pdfDrawingFileName", formData.drawingPDFFileName);
      }
  
      console.log("Submitting Approval data:", ApprovalFormData);
  
      // Submit the approval form data
      const response = await webApi.imagePut(
        `api/architectureToRoRegister/revisions/${registerId}?revisionType=acceptedArchitectRevisions`,
        ApprovalFormData,
      );
      console.log(response,"response of the data")
      console.log(response.data,"response of the data only")
      console.log(response.data.status,"response of the data with status")
   
      if (response.data.status === "success" && response.data.success === true) {
        // Clear loading state, close the form, and reload
        setApiProcessing({ loader: false, message: "" });
        handleClose();
        window.location.reload();
      } else if (response.data.status === "fail" || response.data.success === false) {
        // Handle specific failure messages
        setErrorMessage(response.data.message );
        setApiProcessing({ loader: false, message: "" });
      }
     else {
      // Handle other status codes
      setErrorMessage(`Error: ${response.message || "Unexpected error occurred"}`);
      setApiProcessing({ loader: false, message: "" });
    }
    
      } catch (error) {
        console.error("Error submitting form:", error);
        setApiProcessing({ loader: false, message: "" });
        alert("Failed to submit the form. Please try again later.");
      }
    };
  
  const submitReceived = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedArchitectHardCopyRevisions: [
          {
            hardCopySubmittedDate: todayDate(),
            receivedCopies: formData.receivedCopies,
            revision: formData.selectedRevision,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/HardCopyRevision/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingDWGFileName) {
        const hardCopyFile = new FormData();
        hardCopyFile.append("hardCopyFile", formData.drawingDWGFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/hardCopyFile/${registerId}`,
          hardCopyFile
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      // Check if the error response has a message
      const errorMsg =
        error.response?.data?.message ||
        "Failed to submit the form. Please try again later.";
      setErrorMessage(errorMsg);
    }
  };

  const fetchRegisterData = async () => {
    try {
      let response = await webApi.get(
        `api/architect/upload/${site}?filterType=all`

      );
      setRegisterList(response.data.data);
      console.log(registerList,"responsedata of architect upload")
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };
  
  useEffect(() => {
  
      fetchRegisterData();
  
  }, []);

  // const fetchRegisterData = async () => {
  //   await updateRegister(setRegisterInfo, site).then((response) => {
  //     console.log("updating");
  //     if (response.status === SUCESS_STATUS) {
  //       setRegisterList(response.registers);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   fetchInRegisterData();
  // }, []);

  const getNextRevision = (revisions) => {
    const lastRevision = revisions[revisions.length - 1];
    const numberPartStr = lastRevision.replace("R", "");
    const numberPart = parseInt(numberPartStr, 10);

    if (!isNaN(numberPart)) {
      return `R${numberPart + 1}`;
    } else {
      console.error(
        `Invalid revision format or parsing error: ${lastRevision}`
      );
      return lastRevision; // Return the last valid revision if there's a parsing error
    }
  };

  // const handleFileChange = (e, index, expectedFormat) => {
  //   const file = e.target.files[0];
  
  //   if (file) {
  //     const extension = file.name.split('.').pop();
      
  //     if (extension.toLowerCase() === expectedFormat.toLowerCase()) {
  //       // setFormData((prev) => {
  //       //   const updatedFiles = [...prev.drawingDWGFileName];
  //       //   updatedFiles[index] = file.name; // Store only the file name
  
  //       //   return { ...prev, drawingDWGFileName: updatedFiles };
  //       // });
  //       setFormData((prev) => {
  //         const updatedFiles = Array.isArray(prev.drawingDWGFileName)
  //           ? [...prev.drawingDWGFileName]
  //           : []; // If it's not an array, initialize it as an empty array
        
  //         updatedFiles[index] = file.name; // Store only the file name
        
  //         return { ...prev, drawingDWGFileName: updatedFiles };
  //       });
        
  //     } else {
  //       alert("Please select a valid file format.");
  //       e.target.value = null;
  //     }
  //   }
  // };

  const handleFileChange = (e, index, expectedFormat) => {
    const file = e.target.files[0];
  
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
  
      if (extension === expectedFormat.toLowerCase()) {
        setFormData((prev) => {
          const updatedFiles = prev.drawingDWGFileName || {};
  
          updatedFiles[expectedFormat] = file;
  
          return { ...prev, drawingDWGFileName: updatedFiles };
        });
      } else {
        alert("Please select a valid file format.");
        e.target.value = null; // Reset the file input
      }
    }
  };
  

  const fetchFilesToUpload = async() => {
    try {
      const res = await webApi.get(`api/architectureToRoSelectionRegister/formats/${currentUserInfo._id}?siteId=${site}`)
      if(res?.data?.status === "success"){
        setFileFormats(res?.data?.data)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(()=>{
    fetchFilesToUpload()
  },[])

  useEffect(()=>{
    
      if (fileFormats.length) {
        const fileObject = fileFormats.reduce((acc, format) => {
          // For each format, set the corresponding key with an empty string value
          acc[format] = "";
          return acc;
        }, {});
    
        setFormData((prev) => ({
          ...prev,
          drawingDWGFileName: fileObject, // Set the object with the formats as keys
        }));
      }
    
  },[fileFormats])

  useEffect(()=>{console.log(formData,"formdata")},[formData])
  useEffect(()=>{console.log(errors,"errors")},[errors])
  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height:'50px',
            position:"relative"
          }}
        >
          <p
            style={{ width:"100%",marginTop:"15px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              position:"absolute",
              right:"10px",
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            <button
              onClick={() => handleTabChange("upload")}
              style={{
                backgroundColor:
                  currentTab === "upload"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "upload" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
                borderRight: "1px solid var(--primary-color)",
              }}
            >
              Upload
            </button>

            {/* <button
              onClick={() => handleTabChange("received")}
              style={{
                background:
                  currentTab === "received"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "received" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Received
            </button> */}
          </div>
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px",maxHeight: "70vh", overflowY: "auto", }}
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
                width: "80%",
              }}
            >
              <div>
                <label
                  htmlFor="designDrawingConsultant"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Design Consultant
                  <span style={{ color: "red" }}>*</span>
                </label>

                <SearchDropdownIdVal
                  value={formData.designDrawingConsultant}
                  placeholder={"Select Design Consultant"}
                  options={Array.from(
                    new Set(
                      registerList.map(
                        (register) =>
                          `${register.designDrawingConsultant?.role}`
                      )
                    )
                  )
                    .filter(Boolean)
                    .map((consultant, index) => ({
                      name: consultant,
                      value: consultant,
                    }))}
                  onOptionSelect={(option) => {
                    handleChange({
                      target: {
                        name: "designDrawingConsultant",
                        value: option.value,
                      },
                    });
                  }}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                {errors.designDrawingConsultant && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.designDrawingConsultant}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingNumber"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing No
                  <span style={{ color: "red" }}>*</span>
                </label>
                <SearchDropdownIdVal
                  placeholder={"Select Drawing No"}
                  value={formData.drawingNumber}
                  options={filteredDrawingOptions.map((register) => ({
                    name: register.drawingNo,
                    value: register.drawingNo,
                  }))}
                  onOptionSelect={(option) =>
                    handleChange({
                      target: { name: "drawingNumber", value: option.value },
                    })
                  }
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                {errors.drawingNumber && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingNumber}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Title
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="drawingName"
                  name="drawingName"
                  value={formData.drawingName}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.drawingName && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingName}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="category"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Category
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.category && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.category}
                  </p>
                )}
              </div>
              {currentTab === "received" && (
                <div>
                  <label
                    htmlFor="receivedCopies"
                    className="fs-10 fw-bold text-start py-2
              "
                  >
                    No of Copies Received
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="receivedCopies"
                    name="receivedCopies"
                    placeholder="Enter No of Received Copies"
                    value={formData.receivedCopies}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                  {errors.receivedCopies && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.receivedCopies}
                    </p>
                  )}
                </div>
              )}
                {showAddFolder ? <div>
                      <label
                        htmlFor="folder"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Folder
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                    type="text"
                    id="folder"
                    name="folder"
                    value={formData.folder}
                    onChange={handleChange}
                    placeholder="Enter your  Folder "
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                      {errors.folder && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.folder}
                        </p>
                      )}
                </div>:""}
              <div>
                <label
                  htmlFor="selectedRevision"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Select Revision<span style={{ color: "red" }}>*</span>
                </label>

                <SearchDropdownIdVal
                  placeholder={"Select Revision"}
                  backgroundColor={"var(--table-evenrow-color)"}
                  value={formData.selectedRevision}
                  options={
                    currentTab === "received"
                      ? formData.revisions.length > 0
                        ? [
                            // { name: "Select Revision", value: "" },
                            ...(Array.isArray(formData.revisions)
                              ? formData.revisions.map((rev) => ({
                                  name: rev,
                                  value: rev,
                                }))
                              : []),
                          ]
                        : []
                      : [
                          {
                            name:
                              formData.revisions.length === 0
                                ? "R0"
                                : getNextRevision(formData.revisions),
                            value:
                              formData.revisions.length === 0
                                ? "R0"
                                : getNextRevision(formData.revisions),
                          },
                        ]
                  }
                  onOptionSelect={(option) =>
                    handleChange({
                      target: { name: "selectedRevision", value: option.value },
                    })
                  }
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly={currentTab !== "received"}
                />

                {errors.selectedRevision && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.selectedRevision}
                  </p>
                )}
              </div>

              {currentTab === "upload" && (
                <>
                  {/* {fileFormats.length && fileFormats.map((item,index)=>(<div key={index}>
                    <label
                      htmlFor="drawingDWGFileName"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      {`Upload ${item} File`}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      id={`drawingDWGFileName-${index}`}
                      name="drawingDWGFileName"
                      onChange={(e) => handleFileChange(e, index, item)}
                      className="form-control fs-12"
                      style={{ width: "100%", height: "43px" }}
                    />
                    {errors?.drawingDWGFileName && errors.drawingDWGFileName[index] && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.drawingDWGFileName[index]}
                      </p>
                    )}
                  </div>))} */}
                  {fileFormats.length &&
                  fileFormats.map((item, index) => (
                    <div key={index}>
                      <label
                        htmlFor={`drawingDWGFileName-${index}`}
                        className="fs-10 fw-bold text-start py-2"
                      >
                        {`Upload ${item.toUpperCase()} File`}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        id={`drawingDWGFileName-${index}`}
                        name="drawingDWGFileName"
                        onChange={(e) => handleFileChange(e, index, item)}
                        className="form-control fs-12"
                        style={{ width: "100%", height: "43px" }}
                      />
                      {errors?.drawingDWGFileName && errors.drawingDWGFileName[item] && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.drawingDWGFileName[item]} {/* Error for the specific file type */}
                        </p>
                      )}
                    </div>
                  ))}

                  <div>
                    <label
                      htmlFor="drawingPDFFileName"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Upload File
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      id="drawingPDFFileName"
                      name="drawingPDFFileName"
                      onChange={(e) => 
                          handleChange(e)
                        }
                      placeholder="Select your drawing file"
                      className="form-control fs-12"
                      style={{ width: "100%", height: '43px'  }}
                    />
                    {errors.drawingDWGFileName && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.drawingPDFFileName}
                      </p>
                    )}
                  </div>

                  {formData.revisions.length > 0 && (
                    <div>
                      <label
                        htmlFor="note"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Changes in Revsion
                      </label>
                      <textarea
                        type="text"
                        id="note"
                        name="note"
                        value={formData.note}
                        onChange={handleChange}
                        placeholder="Enter Changes note"
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      />
                      {errors.note && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.note}
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}
              {/* {currentTab === "received" && (
                <div>
                  <label
                    htmlFor="drawingDWGFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload your file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    id="drawingDWGFileName"
                    name="drawingDWGFileName"
                    onChange={handleChange}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: '43px' }}
                  />
                  {errors.drawingDWGFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingDWGFileName}
                    </p>
                  )}
                </div>
              )} */}
            </div>
          </section>
          {/* } */}
          {/* { siteId &&  */}
          {errorMessage && (
            <p style={{textAlign:'center',color:'red',marginTop:'5px'}} onClose={() => setErrorMessage("")}>
              {errorMessage}
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              style={{
                padding: "10px",
                border: "1px solid lightgray",
                backgroundColor: "transparent",
                width: "100px",
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = "lightgray")
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = "transparent")
              }
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
            
              style={{
                marginLeft: "50px",
                padding: "10px",        
                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleFormSubmit}
            >
              Submit
            </button>
          </div>
          {/* } */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddDrawingDialog;
