import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { RxCross1 } from "react-icons/rx";
import { useRecoilState } from "recoil";
import { registerAtom, siteId, userInfo } from "../../../atoms";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import ReceivedTable from "./ReceivedTable";
import TableWrapper from "../../../Utilities/TableWrapper";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import { Dialog, DialogContent } from "../../Dialog/Dialog";
import { canShowFolder } from "../ShowFolder";
import Pen from "./IconsSiteHead/penIcon.svg"
import { BiSortAlt2 } from "react-icons/bi";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";
import ArrowToggle from "../ArrowToggle";
import SortByPopup from "../SortByPopup";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Table.css"
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#4a4a4a",
          color: "white", 
        },
        root: {
          "& .MuiDataGrid-row": {
            borderBottom: "none", 
          },
        },
      },
    },
  },
});

const allowedExtensions = [
  ".rvt", ".rfa", ".rte", ".rft", ".dwg", ".dxf", ".dws", ".dwt", ".max", ".fbx", 
  ".nwc", ".nwd", ".nwf", ".dwf", ".ipt", ".iam", ".idw", ".ipn", ".f3d", ".f3z", 
  ".adsk", ".svf", ".svf2", ".rcs", ".rcp", ".stl", ".obj", ".dae", ".3ds", ".step", 
  ".iges", ".sat", ".glb", ".gltf", ".e57", ".pts", ".xyz", ".rpf", ".rla", ".hdr", 
  ".exr", ".ifc", ".bim", ".dgn", ".pdf", ".png", ".jpeg", ".tif", ".cfd", ".cfz", 
  ".nc", ".gcode", ".tap", ".3dxml", ".prt", ".skp", ".pln", ".lsp", ".mxl", ".py", 
  ".bak", ".zip", ".shp", ".kml", ".geojson", ".csv", ".xls", ".txt", ".dwfx", ".blend", 
  ".safe", ".tekla", ".staad"
];

const PendingTable = () => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  let webApi = new WebApimanager();
  const todayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState({});
  const [site] = useRecoilState(siteId);
  const [fileFormats, setFileFormats] = useState([])
  const [showHardCopy, setShowHardCopy] = useState(false)
  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    folder: "",
    acceptedArchitectDate: "",
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingPDFFileName: null,
    drawingDWGFileName:null,
    revisions: [],
    receivedCopies: "",
    note: "",
  });
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [currentView, setCurrentView] = useState("upload");
  const [reloadData, setReloadData] = useState(false);

  const [filters, setFilters] = useState({
    drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    dueStatus:""
  });
  
  // sort by start
  const [sortField, setSortField] = useState(""); // Field to sort by
  const [sortOrder, setSortOrder] = useState(""); // Order: "asc" or "desc"
  const [activeSortPopup, setActiveSortPopup] = useState(null);

 

  const handleSortClick = (field) => {
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    dueStatus:""
    })
    setSearchValue("");
    setActiveSortPopup((prev) => (prev === field ? null : field)); // Toggle the popup
    
  };

  const handleSortSelection = (order) => {
    if (activeSortPopup) {
      setSortField(activeSortPopup);
      setSortOrder(order);
      setActiveSortPopup(null); // Close the popup after selection
    }
  };
  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
    setFilters({
      drawingNo: "",
    consultant: "",
    category: "",
    drawingTitle: "",
    scheduledDate: "",
    submissionDate: "",
    revision: "",
    dueStatus:""
    })
    setSortField("");
    setSortOrder("");
    setActiveSortPopup(null);
  }, []);
  
  const handleFilterChange = (column, value) => {
    setSearchValue("")
    setSortField("")
    setSortOrder("")
    setActiveSortPopup(null)
    setFilters((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = ""; 
          return acc;
      }, {}),
      [column]: value, 
  }));
    
  };

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [currentTab, setCurrentTab] = useState("upload");
  const [showAddFolder, setShowAddFolder] = useState(false)
  const [currentUserInfo] = useRecoilState(userInfo);
  const validateForm = (formData, currentTab) => {
    const newErrors = {};
    if (currentTab === "upload") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (showAddFolder && !formData.folder.trim()) {
        newErrors.folder = "folder name is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      // if (!formData.drawingDWGFileName) {
      //   newErrors.drawingDWGFileName = "Drawing DWG File is required";
      // }
      // if (!formData.drawingDWGFileName) {
      //   newErrors.drawingDWGFileName = "Drawing file is required";
      // } else if (formData.drawingDWGFileName instanceof File) {
      //   const extension = formData.drawingDWGFileName.name.split('.').pop().toLowerCase();
      //   if (!allowedExtensions.includes(`.${extension}`)) {
      //     newErrors.drawingDWGFileName = "Invalid file format. Please upload a valid file.";
      //   }
      // } else if (typeof formData.drawingDWGFileName === "string") {
      //   const extension = formData.drawingDWGFileName.split('.').pop().toLowerCase();
      //   if (!allowedExtensions.includes(`.${extension}`)) {
      //     newErrors.drawingDWGFileName = "Invalid file format. Please upload a valid file.";
      //   }
      // }
      newErrors.drawingDWGFileName = Object.keys(formData.drawingDWGFileName).reduce((acc, key) => {
        const file = formData.drawingDWGFileName[key]; // Get the file name for each format (dwg, dxf, etc.)
      
        if (!file) {
          acc[key] = `${key.toUpperCase()} file is required`; // Store error with the format key (e.g., "dwg: DWG file is required")
        } else {
          const extension = typeof file.name === "string" ? file.name.split('.').pop().toLowerCase() : "";
          if (!allowedExtensions.includes(`.${extension}`)) {
            acc[key] = `Invalid ${key.toUpperCase()} file format. Please upload a valid file.`; // Error for invalid format
          }
        }
      
        return acc; // Return the accumulator to keep building the error object
      }, {});
      if(Object.keys(newErrors.drawingDWGFileName).length === 0){
        delete newErrors.drawingDWGFileName
      }

      // if (!formData.drawingPDFFileName) {
      //   newErrors.drawingPDFFileName = "Drawing File is required";
      // } 
      // else if (formData.drawingPDFFileName instanceof File) {
      //   if (!formData.drawingPDFFileName.name.endsWith(".pdf")) {
      //     newErrors.drawingPDFFileName = "Drawing File must be a .pdf file";
      //   }
      // } else if (typeof formData.drawingPDFFileName === "string") {
      //   if (!formData.drawingPDFFileName.endsWith(".pdf")) {
      //     newErrors.drawingPDFFileName = "Drawing File must be a .pdf file";
      //   }
      // }
    }
    return newErrors;
  };

  const handleFormSubmit = async () => {
    const errors = validateForm(formData, currentTab);
    console.log(errors,"errors")
    setErrors(errors);
    if (Object.keys(errors).length > 0) return;
      if (currentTab === "upload") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitUpload(formData.registerId);
    }
  };

  const submitUpload = async (registerId) => {
    console.log("registerId", registerId);

    try {
      const ApprovalFormData = new FormData();
      ApprovalFormData.append("softCopySubmittedDate", todayDate());
      ApprovalFormData.append("changes", formData.note);
      if (formData.drawingDWGFileName && typeof formData.drawingDWGFileName === "object") {
        Object.keys(formData.drawingDWGFileName).forEach((key, index) => {
          const file = formData.drawingDWGFileName[key]; // Get the file for each format (e.g., "dwg", "dxf")
      
          if (file) {
            // If a file is present for the current key (like dwg, dxf), append it as binary
            ApprovalFormData.append(`drawingFileName_${key}`, file);
          } else {
            console.error(`No file provided for ${key}.`);
          }
        });
      } else {
        console.error("No valid drawingDWGFileName files provided.");
      }
      if (formData.drawingPDFFileName) {
        ApprovalFormData.append(
          "pdfDrawingFileName",
          formData.drawingPDFFileName
        );
      }
      const response = await webApi.imagePut(
        `api/architectureToRoRegister/revisions/${registerId}?revisionType=acceptedArchitectRevisions`,
        ApprovalFormData
      );
      if (response.status === 200) {
        setApiProcessing({ loader: false, message: "" });
        // handleClose();
        // window.location.reload();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      alert("Failed to submit the form. Please try again later.");
    }
  };
  const fetchFilesToUpload = async() => {
    try {
      const res = await webApi.get(`api/architectureToRoSelectionRegister/formats/${selectedDrawing?.designDrawingConsultant._id}?siteId=${selectedDrawing?.siteId?._id}`)
      if(res?.data?.status === "success"){
        setFileFormats(res?.data?.data)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    setReloadData(true);
  }, [drawingList]);

  const handleFileChange = (e, index, expectedFormat) => {
    const file = e.target.files[0];
  
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
  
      if (extension === expectedFormat.toLowerCase()) {
        setFormData((prev) => {
          const updatedFiles = prev.drawingDWGFileName || {};
  
          updatedFiles[expectedFormat] = file;
  
          return { ...prev, drawingDWGFileName: updatedFiles };
        });
      } else {
        alert("Please select a valid file format.");
        e.target.value = null; // Reset the file input
      }
    }
  };

  const fetchData = async () => {
    try {
      let response = await webApi.get(
        `api/architect/pending/${site}?filterType=upload`
      );
      setDrawingList(response.data.data);
      console.log(drawingList, "responsedata of architect Pending upload");
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchNoOfDays = async() =>{
       try {
         const response = await webApi.get("api/rfiTimeStamp/byCompanyId")
         console.log(response.data.data.customizedView ,"response in the rfi timeStamp")
          if (response.status === 200) {
           const recievedHardCopies = response.data.data.areYouReceivingHardCopiesFromAllConsultants       
           setShowHardCopy(recievedHardCopies)
           console.log(recievedHardCopies,"recievedHardCopies")
          } 
       } catch (error) {
         console.log(error.message)
       }
     }
     
     useEffect(()=>{
       fetchNoOfDays()
     },[])

  useEffect(() => {
  }, [drawingList]);
  const handleEdit = (drawingItem) => {
    const selected = drawingList.find(
      (drawing) => drawing.drawingId === drawingItem.drawingId
    );
    if (selected) {
      const combinedRevisions = [
        ...selected.acceptedArchitectRevisions,
      ];
      console.log(selected, "selected drawing pending");
      setSelectedDrawing(selected);
      setFormData({
        registerId: selected.drawingId,
        drawingNumber: selected.drawingNo || "",
        drawingName: selected.drawingTitle || "",
        folder: selected.folderId?.folderName || "",
        category: selected.category.category || "",
        designDrawingConsultant: `${selected.designDrawingConsultant?.role}`, 
        acceptedArchitectDate: selected.acceptedArchitectDate
          ? new Date(selected.acceptedArchitectDate).toLocaleDateString()
          : "",
        revisions: selected?.archRevision?[`${selected?.archRevision[0]}${parseInt(selected?.archRevision.slice(1),10)+1}`]:["R0"],
        submittedDate: todayDate(),
      });
      console.log(formData, "after selecetd");
      setOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
 

  const calculateDueDays = (acceptedRoDate, submittedDate = null) => {
    if (!acceptedRoDate) return "-";

    const dateToCompare = submittedDate ? new Date(submittedDate) : new Date();
    const acceptedDate = new Date(acceptedRoDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // convert time difference to days

    return differenceInDays;
  };

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);

    
  };
  
 
  useEffect(() => {
    const showFolder = canShowFolder(currentUserInfo, site);
    setShowAddFolder(showFolder);
  }, [currentUserInfo, site]);

  const updatedRows = drawingList.map((row, index) => ({
    ...row,
    serialNo: index + 1, 
    drawingNo: row.drawingNo,
    consultant: row.designDrawingConsultant?.role,
    category: row.category?.category,
    drawingTitle: row.drawingTitle,
    scheduledDate: formatDate(row.acceptedROSubmissionDate) || "-",
    revisions: row.archRevision || "-",
  }));

  const filteredRows = updatedRows.filter((row) => {
    const searchTermLower = searchValue.toLowerCase();
    return (
      row.drawingNo?.toLowerCase().includes(searchTermLower) ||
      row?.designDrawingConsultant?.role?.toLowerCase().includes(searchTermLower) ||
      row.category?.category?.toLowerCase().includes(searchTermLower) ||
      row.drawingTitle?.toLowerCase().includes(searchTermLower)
    );
  });

  const columns = useMemo(
    () => [
      {
        field: "serialNo",
        headerName: "S.No",
        flex: 0.5,
        filterable: false,
        sortable: false,
      },
      {
        field: "drawingNo",
        headerName: "Drawing No",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "consultant",
        headerName: "Consultant",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "category",
        headerName: "Drawing Category",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "drawingTitle",
        headerName: "Drawing Title",
        flex: 2,
        filterable: true,
        sortable: true,
        
      },
      {
        field: "scheduledDate",
        headerName: "Scheduled Date",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "dueStatus",
        headerName: "Due/Overdue",
        flex: 1,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          const dueDays = calculateDueDays(
            params.row.acceptedROSubmissionDate,
            params.row.submittedDate
          );
          const dueText =
            dueDays === "-" ? "-" :
            dueDays >= 0 ? `${dueDays} days` : `${Math.abs(dueDays)} days overdue`;
          return (
            <span
              style={{
                color: dueDays >= 0 ? "green" : "red",
              }}
            >
              {dueText}
            </span>
          );
        },
      },
      {
        field: "revisions",
        headerName: "Revisions",
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: "action",
        headerName: "Action",
        flex: 0.7,
        filterable: false,
        sortable: false,
        renderCell: (params) => (
          <img
            src={Pen}
            size={25}
            style={{ marginLeft: "10px", cursor: "pointer",marginTop:"24px" }}
            onClick={() => handleEdit(params.row)}
          />
        ),
      },
    ],
    [drawingList]
  );

  useEffect(()=>{
    if(Object.keys(selectedDrawing).length)
    fetchFilesToUpload()
  },[selectedDrawing])

  useEffect(()=>{
    if (fileFormats.length) {
      const fileObject = fileFormats.reduce((acc, format) => {
        // For each format, set the corresponding key with an empty string value
        acc[format] = "";
        return acc;
      }, {});
  
      setFormData((prev) => ({
        ...prev,
        drawingDWGFileName: fileObject, // Set the object with the formats as keys
      }));
    }
  },[fileFormats])
  

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <Tabs value={currentView} onChange={handleTabViewChange}>
                  <Tab label="Soft Copy" value="upload" />
                  <Tab label="Hard Copy" value="received" />
                </Tabs>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {currentView === "upload" && (
                  <div
                    style={{
                      position: "relative",
                      width: "280px",
                      paddingLeft: "5px",
                      height: "50px",
                    }}
                  >
                    <input
                      placeholder="Search Drawings"
                      onChange={handleSearchChange}
                      style={{
                        paddingLeft: "20px",
                        border: "1px solid var(--primary-color)",
                        width: "245px",
                        height: "40px",
                      }}
                    />
                    <Icon.Search
                      style={{
                        position: "absolute",
                        right: "30px",
                        top: "40%",
                        transform: "translateY(-50%)",
                        color: "#ACACAC",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              {currentView === "upload" && (
                <div style={{ marginTop: "10px" }}>
                  <Box
                    className="dataGridContainer"
                  >
                    <ThemeProvider theme={theme}>
                    <DataGrid
                      columns={columns}
                      rows={filteredRows}
                      getRowId={(row) => row.drawingId}
                      rowHeight={60}
                      disableColumnMenu={false} // Keep the column menu for filtering
                      pagination={false}
                      hideFooter={true}
                      getRowSpacing={params=>({
                        top:params.isFirstVisible ? 0 : 5,
                        bottom:params.isLastVisible ? 0 : 5
                      })}
                      
                      getRowClassName={(params) =>
                        "customHeader" 
                      }
                      
                    />
                    </ThemeProvider>
                  </Box>
                </div>
              )}
              {currentView === "received" && (
                <div style={{ marginTop: "10px" }}>
                  <ReceivedTable
                    selectedFilter={""}
                    searchValue={searchValue}
                    searchActive={searchActive}
                    setSearchValue={setSearchValue}
                    setSearchActive={setSearchActive}
                    reloadData={reloadData}
                    setReloadData={setReloadData}
                    filterCondition="pending"
                  />
                </div>
              )}
            </div>
          </>
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
            position:"relative"
          }}
        >
          <p
            style={{ marginTop:"15px",width:"100%", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
              position:"absolute",
              right:"10px"
            }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            <button
              onClick={() => {
                setCurrentTab("upload");
              }}
              style={{
                backgroundColor:
                  currentTab === "upload"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "upload" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
                borderRight: "1px solid var(--primary-color)",
              }}
            >
              Upload
            </button>
          </div>
        </div>
        <DialogContent
          style={{
            padding: "25px",
            minWidth: "300px",
            minHeight: "300px",
            maxHeight: "70vh",
            overflowY: "auto",
          }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <section
              style={{
                marginLeft: "80px",
                marginRight: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "50px",
                  width: "80%",
                }}
              >
                <div>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing No
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="drawingNumber"
                    name="drawingNumber"
                    value={formData.drawingNumber}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />

                  {errors.drawingNumber && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingNumber}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="drawingName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Title
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="drawingName"
                    name="drawingName"
                    value={formData.drawingName}
                    onChange={handleChange}
                    placeholder="Enter your drawing title"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.drawingName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingName}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Category
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    placeholder="Enter your Drawing Category"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.category && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.category}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="designDrawingConsultant"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Design Consultant
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="designDrawingConsultant"
                    name="designDrawingConsultant"
                    value={formData.designDrawingConsultant}
                    onChange={handleChange}
                    placeholder="Enter your  Design Consultant"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.designDrawingConsultant && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.designDrawingConsultant}
                    </p>
                  )}
                </div>

                {currentTab !== "received" && (
                  <>
                    {showAddFolder ?<div>
                      <label
                        htmlFor="folder"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Folder
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="folder"
                        name="folder"
                        value={formData.folder}
                        onChange={handleChange}
                        placeholder="Enter your  Folder "
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                        readOnly
                      />
                      {errors.folder && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.folder}
                        </p>
                      )}
                    </div>: ""}
                    <div>
                      <label
                        htmlFor="revisions"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Revisions
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="revisions"
                        name="revisions"
                        value={formData.revisions}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Revision</option>
                        <option value={formData.revisions[0]}>{formData.revisions[0]}</option>
                      </select>
                      {errors.revisions && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.revisions}
                        </p>
                      )}
                    </div>
                  </>
                )}
                {/* {currentTab === "received" && (
                  <>
                    <div>
                      <label
                        htmlFor="revisions"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Revisions
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="revisions"
                        name="revisions"
                        value={formData.revisions}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Revision</option>
                        {formData.revision &&
                          formData.revisions.map((revision, index) => (
                            <option key={index} value={revision.revision}>
                              {revision.revision}
                            </option>
                          ))}
                      </select>
                      {errors.revisions && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.revisions}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="receivedCopies"
                        className="fs-10 fw-bold text-start py-2
              "
                      >
                        No of Copies Received
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        id="receivedCopies"
                        name="receivedCopies"
                        placeholder="Enter No of Received Copies"
                        value={formData.receivedCopies}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </>
                  
                )} */}

                  {fileFormats.length &&
                    fileFormats.map((item, index) => (
                      <div key={index}>
                        <label
                          htmlFor={`drawingDWGFileName-${index}`}
                          className="fs-10 fw-bold text-start py-2"
                        >
                          {`Upload ${item.toUpperCase()} File`}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="file"
                          id={`drawingDWGFileName-${index}`}
                          name="drawingDWGFileName"
                          onChange={(e) => handleFileChange(e, index, item)}
                          className="form-control fs-12"
                          style={{ width: "100%", height: "43px" }}
                        />
                        {errors?.drawingDWGFileName && errors.drawingDWGFileName[item] && (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {errors.drawingDWGFileName[item]} {/* Error for the specific file type */}
                          </p>
                        )}
                      </div>
                    ))}

                <div>
                  <label
                    htmlFor="drawingPDFFileName"
                    className="fs-10 fw-bold text-start py-2"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "17px",
                    }}
                  >
                    Upload File
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    id="drawingPDFFileName"
                    name="drawingPDFFileName"
                    onChange={(e) => 
                        handleChange(e)
                      }
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: "43px" }}
                  />
                  {errors.drawingPDFFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingPDFFileName}
                    </p>
                  )}
                </div>
              </div>
            </section>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "45px",
              }}
            >
              <button
                style={{
                  padding: "10px",
                  border: "1px solid lightgray",
                  backgroundColor: "transparent",
                  width: "100px",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "lightgray")
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
                onClose={handleClose}
              >
                Cancel
              </button>
              <button
                style={{
                  marginLeft: "50px",
                  padding: "10px",
                  border: "1px solid var(--primary-color)",
                  width: "100px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PendingTable;
